import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "./Dropdown";
import { useTrans } from "../../hooks";

function DiscoverDropdownContent({ browseTopics, curatedLists }) {
  const { trans } = useTrans();
  // Only applicable to logged-out users as this component is only used in logged-out view.
  const isEnglish = parseInt(app.get("language"), 10) === 1;
  return (
    <div className="dropdown-menu large" aria-labelledby="browse-dropdown">
      <div className="col-xs-9 discover-categories">
        <ul className="row">
          <li className="title col-xs-12" aria-label="Browse Topics">
            {trans("Browse")}
          </li>
          {browseTopics.map(item => {
            let { name, topic, browseURL } = item;
            const categoryUrl =
              browseURL || wattpad.utils.categoryNameToUrl(name);
            return (
              <li className="col-xs-4" key={name}>
                <a className="on-navigate" href={categoryUrl}>
                  {/* The topics have already been translated in the web repo, so translating a variable is fine */}
                  {/* eslint-disable-next-line wp-lint-rules/translate-string-literals */}
                  {trans(topic)}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {isEnglish && (
        <div className="col-xs-3 wattpad-picks">
          <ul className="row">
            <li className="title col-xs-12" aria-label="Wattpad Picks">
              {trans("Wattpad Picks")}
            </li>
            {curatedLists.map(list => {
              let { name, id } = list;
              return (
                <li className="col-xs-12" key={name}>
                  {/* eslint-disable-next-line wp-lint-rules/translate-string-literals */}
                  <a href={`/featured/${id}`}>{trans(name)}</a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

DiscoverDropdownContent.propTypes = {
  browseTopics: PropTypes.array.isRequired,
  curatedLists: PropTypes.array.isRequired
};

function DiscoverDropdown() {
  const { trans } = useTrans();
  const [browseTopics, setBrowseTopics] = useState([]);
  const [curatedLists, setCuratedLists] = useState([]);

  useEffect(() => {
    let curatedListsCollection = new app.collections.ReadingLists([], {
      username: "WattpadExplorer" // HC for english only right now
    });
    let topicsCollection = new app.collections.BrowseTopics();
    Promise.all([
      curatedListsCollection.fetchNextSet(),
      topicsCollection.fetch()
    ]).then(() => {
      setBrowseTopics(
        wattpad.utils.sortByColumns(topicsCollection.toJSON(), 3)
      ); // reorder the items so they appear in alphabetical order vertically
      setCuratedLists(curatedListsCollection.toJSON());
    });
  }, []);

  return (
    <Dropdown id="discover-dropdown" label={trans("Browse")}>
      <DiscoverDropdownContent
        browseTopics={browseTopics}
        curatedLists={curatedLists}
      />
    </Dropdown>
  );
}

export default DiscoverDropdown;
