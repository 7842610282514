import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../../shared-components";
import LibraryPopover from "../home/components/LibraryPopover";
import { openPopover } from "../../shared-components/popover/reducers";
import {
  getReadingLists,
  getCollectionsForStoryIds
} from "../../../components/views/home/actions";
import showSignupModal from "../../../helpers/client.show-signup-modal";

const LibraryPopoverButton = ({
  className,
  storyId,
  setShowPopover,
  onSignupTriggered,
  readingLists,
  storiesCollections,
  openPopover,
  getReadingLists,
  getCollectionsForStoryIds
}) => {
  const libraryButtonRef = useRef(null);

  const handleLibraryClick = evt => {
    if (!wattpad.utils.currentUser().authenticated()) {
      onSignupTriggered && onSignupTriggered(evt);
      showSignupModal(evt);
      return;
    }

    const component = () => (
      <LibraryPopover
        storyId={storyId}
        readingLists={readingLists}
        storiesCollections={storiesCollections?.[storyId]}
        setShowPopover={setShowPopover}
      />
    );
    component.displayName = "LibraryPopover";

    openPopover({
      component,
      triggerRef: libraryButtonRef,
      passthroughProps: {
        showArrow: "true"
      },
      contentClass: "modal-popover"
    });
  };

  useEffect(
    () => {
      if (wattpad.utils.currentUser().authenticated()) {
        getReadingLists();
        getCollectionsForStoryIds(storyId);
      }
    },
    [wattpad, storyId, getReadingLists, getCollectionsForStoryIds]
  );

  return (
    <div className={classnames("buttons-wrapper", className)}>
      <button
        className="btn-story-lists btn btn-sm btn-inline btn-orange btn-left-align btn-right-align"
        onClick={handleLibraryClick}
        ref={libraryButtonRef}
      >
        <Icon iconName="fa-plus" height="10" color="wp-neutral-5" />
      </button>
    </div>
  );
};

LibraryPopoverButton.defaultProps = {};

LibraryPopoverButton.propTypes = {
  className: PropTypes.string,
  storyId: PropTypes.number.isRequired,
  setShowPopover: PropTypes.func,
  onSignupTriggered: PropTypes.func,
  readingLists: PropTypes.array,
  storiesCollections: PropTypes.object,
  openPopover: PropTypes.func,
  getReadingLists: PropTypes.func,
  getCollectionsForStoryIds: PropTypes.func
};

export default connect(
  state => ({
    readingLists: state.homeSections.readingLists,
    storiesCollections: state.homeSections.storiesCollections
  }),
  { openPopover, getReadingLists, getCollectionsForStoryIds }
)(LibraryPopoverButton);
