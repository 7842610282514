import React, { useState } from "react";
import PropTypes from "prop-types";
import { NewCheckbox } from "../../../../shared-components";

const ReportCheckboxField = ({ id, label }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => setIsChecked(v => !v);

  return (
    <div className="report-checkbox-field">
      <NewCheckbox
        id={id}
        required
        checked={isChecked}
        contentId={`content-${id}`}
        onChange={handleOnChange}
      >
        {label}
        <span>*</span>
      </NewCheckbox>
    </div>
  );
};

ReportCheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default ReportCheckboxField;
