/* 
Input component supporting a float label with an optional placeholder,
Use the classes `empty` and `:focus` on the input to control its styling

Note: the API is pretty minimal. Some useful additions could include:
- Error handling/display
- Custom validation on what it means for a field to have a value
- Props for all fields that <input> supports
*/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class FloatLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasBlurred: false // Keeping track of whether the input has been blurred allows us to only apply invalid styling once the element has been touched at least once
    };
  }

  onChange = event => {
    event.preventDefault();

    this.props.onChange(event.target.value, this.props.id);
  };

  onBlur = event => {
    this.setState({ hasBlurred: true });
    this.props.onBlur(event);
  };

  render() {
    const {
      id,
      labelText,
      type,
      required,
      value,
      pattern,
      onFocus,
      onInvalid,
      classes,
      placeholder,
      autoFocus
    } = this.props;

    const { hasBlurred } = this.state;

    const parentClasses = `float-label-container ${classes}`;
    const inputClasses = classNames({ empty: !value, hasBlurred });

    return (
      <div className={parentClasses}>
        <input
          className={inputClasses}
          id={id}
          onChange={this.onChange}
          placeholder={placeholder}
          pattern={pattern}
          type={type}
          required={required}
          value={value}
          onBlur={this.onBlur}
          onFocus={onFocus}
          onInvalid={onInvalid}
          autoFocus={autoFocus}
        />
        <label htmlFor={id}>{labelText}</label>
      </div>
    );
  }
}

FloatLabel.defaultProps = {
  type: "text",
  required: false,
  onChange: () => {},
  onBlur: () => {},
  onInvalid: () => {},
  onFocus: () => {},
  placeholder: "",
  classes: "",
  autoFocus: false
};

FloatLabel.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,

  type: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInvalid: PropTypes.func,
  onFocus: PropTypes.func,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool
};
