import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Header(props) {
  let text = props.text;
  return <h2 className={classNames("ds-header", props.variant)}>{text}</h2>;
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string
};

Header.defaultProps = {
  variant: ""
};

export { Header };
