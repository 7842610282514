import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../hooks";
import {
  Button,
  ButtonVariant,
  ButtonSize,
  IconNames
} from "@wattpad/web-ui-library";
import PublishButton, {
  PublishButtonProps
} from "../views/writer/PublishButton";

const EditWorksButtons = ({
  draft,
  onPreview,
  onPreviewUnpublished,
  onSave,
  onCancel,
  onShowSettings,
  publishButtonSettings
}) => {
  const { trans } = useTrans();

  return (
    <>
      <div className="button-container">
        {draft ? (
          <PublishButton {...publishButtonSettings} />
        ) : (
          <Button
            onClick={onPreview}
            size={ButtonSize.SMALL}
            label={trans("Publish Changes")}
          />
        )}

        {draft ? (
          <>
            <Button
              onClick={onSave}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.SMALL}
              label={trans("Save")}
            />
            <Button
              onClick={onPreviewUnpublished}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.SMALL}
              label={trans("Preview")}
            />
          </>
        ) : (
          <Button
            onClick={onCancel}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            label={trans("Cancel")}
          />
        )}

        <Button
          className="on-show-settings"
          onClick={onShowSettings}
          variant={ButtonVariant.TERTIARY}
          size={ButtonSize.SMALL}
          leadingIcon={IconNames.WpMore}
        />
      </div>
    </>
  );
};

EditWorksButtons.propTypes = {
  draft: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onPreview: PropTypes.func,
  onPreviewUnpublished: PropTypes.func,
  onCancel: PropTypes.func,
  onShowSettings: PropTypes.func,
  publishButtonSettings: PropTypes.shape(PublishButtonProps)
};

export default EditWorksButtons;
