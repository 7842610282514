import React from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";
import classnames from "classnames";

import { UserBadge } from "../views/user-badges/UserBadge";
import Icon from "./Icon";
import { useTrans } from "../hooks";
import { count } from "../helpers";

const ProfileCard = ({
  username,
  name,
  avatar,
  badges = [],
  numStoriesPublished = 0,
  numLists = 0,
  numFollowers = 0,
  following = null,
  index,
  onProfileCardClick,
  onFollowButtonClick
}) => {
  const { ngettext, trans } = useTrans();

  const handleFollowButtonClick = async e => {
    e.stopPropagation();
    e.preventDefault();

    const result = await onFollowButtonClick(username, index);
    if (!result) {
      const message = following
        ? trans("Unfollow failed. Please try again.")
        : trans("Follow failed. Please try again.");

      wattpad?.utils?.showToast(message, { type: "dismissable" });
    }
  };

  // using on-navigate on <a> tag was not allowing the follow button to work.
  // This handler fixes that issue.
  const handleCardClick = e => {
    e.preventDefault();
    onProfileCardClick(username, index);
  };

  return (
    <div className="profile-card">
      <a
        href={`/user/${username}`}
        onClick={handleCardClick}
        className="profile-card-data"
      >
        <img
          className="display-pic"
          src={avatar ? avatar : `/img/avatar/empty.png`}
          alt={username}
          aria-hidden
        />
        <div className="card-content">
          <div className="sr-only">{`${
            name ? name : username
          } @${username}`}</div>
          <div className="name-and-badges" aria-hidden>
            <h5>{name ? name : username}</h5>
            <div className="badges">
              {badges?.map(badge => (
                <div className="user-badge" key={badge}>
                  <UserBadge name={badge} size="20" />
                </div>
              ))}
            </div>
          </div>
          <p className="username" aria-hidden>
            @{username}
          </p>
          <div className="card-meta">
            <p
              dangerouslySetInnerHTML={{
                __html: sprintf(
                  ngettext("%s Story", "%s Stories", numStoriesPublished),
                  sprintf("<b>%s</b>", count(numStoriesPublished))
                )
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: sprintf(
                  ngettext("%s Reading list", "%s Reading lists", numLists),
                  sprintf("<b>%s</b>", count(numLists))
                )
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: sprintf(
                  ngettext("%s Follower", "%s Followers", numFollowers),
                  sprintf("<b>%s</b>", count(numFollowers))
                )
              }}
            />
          </div>
        </div>
      </a>
      {following !== null && (
        <button
          className={classnames("follow", "btn-load-more", {
            active: following
          })}
          onClick={handleFollowButtonClick}
          aria-label={following ? trans("Following") : trans("Follow")}
        >
          <Icon
            name={following ? "userFollowed" : "userFollow"}
            size="24"
            strokeWidth="0"
            className="follow__icon"
            fill={!following}
            aria-hidden
          />
          <span className="follow__text" aria-hidden>
            {following ? trans("Following") : trans("Follow")}
          </span>
        </button>
      )}
    </div>
  );
};

ProfileCard.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  badges: PropTypes.array,
  numFollowers: PropTypes.number,
  numLists: PropTypes.number,
  numStoriesPublished: PropTypes.number,
  following: PropTypes.bool,
  index: PropTypes.number,
  onProfileCardClick: PropTypes.func.isRequired,
  onFollowButtonClick: PropTypes.func.isRequired
};

export default ProfileCard;
