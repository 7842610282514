/**
 * @param {string} url  //dynamic endpoint to call
 * @returns {items: Array} - Returns the story data for the carousel
 */

const clientAPICall = url => {
  return Promise.resolve(
    $.ajax({
      type: "GET",
      url: url
    })
  );
};

module.exports = function(url) {
  return new Promise(async function(resolve, reject) {
    await clientAPICall(url)
      .then(resp => {
        return resolve(resp.data.items);
      })
      .catch(err => {
        return reject(err);
      });
  });
};
