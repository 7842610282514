import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, StaticRouter, Switch } from "react-router-dom";
import { isClient, setStorageItem } from "../../helpers";
import PropTypes from "prop-types";

import { Threads } from "./Threads";
import { Conversation } from "./Conversation";
import getFeatureFlags from "../../../helpers/get-wattpad-backend-feature-flags";
import { getPrivateMessageFeatureFlags } from "../../../helpers/wattpad-backend-feature-flags-helper";

export const Inbox = ({ username, url, privateMessageFeatureFlags }) => {
  const [privateMessagePermissions, setPrivateMessagePermissions] = useState(
    privateMessageFeatureFlags
  );

  useEffect(
    () => {
      /**
       * If we've been passed privateMessage feature flags context, reuse it.
       */
      if (
        privateMessageFeatureFlags !== undefined &&
        privateMessageFeatureFlags !== null
      ) {
        setPrivateMessagePermissions(privateMessageFeatureFlags);
      } else {
        // We don't have featureflags any of our sources; make a fresh call and update sessionStorage
        getFeatureFlags().then(featureFlags => {
          setStorageItem({
            key: "wattpad-backend-feature-flags",
            value: featureFlags,
            type: "sessionStorage"
          });
          const privateMessageFeatureFlags = getPrivateMessageFeatureFlags(
            featureFlags
          );

          setPrivateMessagePermissions(privateMessageFeatureFlags);
        });
      }
    },
    [privateMessageFeatureFlags]
  );

  const Router = isClient() ? BrowserRouter : StaticRouter;
  return (
    <Router basename="/inbox" location={url} context={{}}>
      <Switch>
        <Route exact path="/">
          <Threads
            username={username}
            privateMessagePermissions={privateMessagePermissions}
          />
        </Route>
        <Route exact path="/:activeThread">
          <Conversation
            username={username}
            privateMessagePermissions={privateMessagePermissions}
          />
        </Route>
      </Switch>
    </Router>
  );
};

Inbox.propTypes = {
  username: PropTypes.string.isRequired,
  privateMessageFeatureFlags: PropTypes.object,
  url: PropTypes.string
};
