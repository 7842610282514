import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const SmallNavigation = ({
  sectionType,
  heading,
  subheading,
  background,
  index,
  isSubsection,
  setPosition,
  link,
  image,
  wasSeen
}) => {
  const el = useRef(null);

  useEffect(
    () => {
      if (!isSubsection) {
        const boundingBox = el.current.getBoundingClientRect();
        setPosition(
          index,
          sectionType,
          boundingBox.top + window.scrollY,
          boundingBox.bottom + window.scrollY
        );
      }
    },
    [isSubsection, index, sectionType, setPosition]
  );

  useEffect(
    () => {
      if (wasSeen) {
        window.te.push("event", "home", "navigation", null, "view", {
          nav_target: heading,
          position: index
        });
      }
    },
    [wasSeen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = () => {
    window.te.push("event", "home", "navigation", null, "click", {
      nav_target: heading,
      position: index
    });
  };

  const hasBackground = !!image;

  // Current usecase for small nav is the convid banner and we do not want the
  // CSR no-navigation listener to handle the click event.
  return (
    <div
      className={classnames("small-nav", background, `${sectionType}-banner`, {
        twoSections: hasBackground,
        oneSection: !hasBackground
      })}
      ref={el}
    >
      <a
        href={link}
        target="_blank"
        onClick={handleClick}
        rel="noopener noreferrer"
        className="banner-content"
      >
        <div className="contest-content">
          <div className="contest-info">
            <h1>{heading}</h1>
            <h2>{subheading}</h2>
          </div>
          {hasBackground && (
            <div
              className="graphic-container"
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </div>
      </a>
    </div>
  );
};

SmallNavigation.propTypes = {
  wasSeen: false
};

SmallNavigation.propTypes = {
  index: PropTypes.number.isRequired,
  isSubsection: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  background: PropTypes.string,
  setPosition: PropTypes.func.isRequired,
  sectionType: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  wasSeen: PropTypes.bool
};

export default SmallNavigation;
