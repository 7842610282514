import { useTrans } from "../../../hooks";

const namespace = "STORY_PLANNER";

export const FORM_REQUEST = `${namespace}/REQUEST`;
export const FORM_FETCH = `${namespace}/FETCH`;
export const FORM_POST = `${namespace}/POST`;
export const FORM_ERROR = `${namespace}/ERROR`;
export const FORM_UPDATE = `${namespace}/UPDATE`;
export const FORM_VISIBILITY = `${namespace}/VISIBILE`;

export const PROTAGONIST = () => {
  const { trans } = useTrans();
  return {
    title: trans("The Protagonist"),
    info: trans(
      "Stories have multiple characters, and many main characters. The Protagonist on the other hand is the leading character in your story. They are the most prominent figure in your story, who drives the plot and makes up most of the narrative. They are the character whose fate is most closely followed by your reader."
    ),
    more: trans("If you’re struggling to figure out who your Protagonist is,"),
    learnMore: trans("Learn More Here"),
    section1: {
      label: trans("Your Protagonist's name"),
      name: "protagonist.name",
      limit: 20,
      placeholder: trans("Enter Name")
    },
    section2: {
      label: trans("Other names for the Protagonist"),
      name: "protagonist.other_names",
      limit: 50,
      placeholder: trans("Separate Names By Commas")
    },
    section3: {
      name: "protagonist.pronoun",
      label: trans("Protagonist's pronoun"),
      options: [
        {
          value: "he",
          name: trans("He")
        },
        {
          value: "she",
          name: trans("She")
        },
        {
          value: "they",
          name: trans("They")
        }
      ],
      placeholder: trans("Select")
    },
    section4: {
      label: trans("Protagonist's attributes"),
      name: "protagonist.attributes",
      limit: 250,
      placeholder: trans("Type and Select"),
      info: trans(
        "Up to 10 attributes. Include attributes like skin tone, ethnicity, gender selection, disabilities, hair, eyes, body type, and sexual orientation."
      )
    },
    section5: {
      label: trans("The Protagonist tends to be:"),
      options: [
        {
          id: "protagonist.extrovert_introvert",
          sliderLabels: [
            trans("Extroverted"), //eslint-disable-line
            trans("Introverted")
          ]
        },
        {
          id: "protagonist.likeable",
          sliderLabels: [
            trans("Unlikable"), //eslint-disable-line
            trans("Likable")
          ]
        },
        {
          id: "protagonist.focus_past_future",
          sliderLabels: [
            trans("Focused on Patterns in the Past"),
            trans("Focused on Future Possibilities")
          ]
        },
        {
          id: "protagonist.thinking_feeling",
          sliderLabels: [
            trans("Uses Mind & Logic"),
            trans("Uses Heart & Emotion")
          ]
        },
        {
          id: "protagonist.decisive_improvise",
          sliderLabels: [
            trans("Organized & Decisive Decision Making"),
            trans("Improvised & Open Decision Making")
          ]
        },
        {
          id: "protagonist.laidback_serious",
          sliderLabels: [
            trans("Light & Laid Back Attitude"),
            trans("Complex & Serious Attitude")
          ]
        }
      ]
    },
    section6: {
      label: trans(
        "How will the Protagonist's personality change by the end of the story?"
      ),
      name: "protagonist.personality_change",
      options: [
        {
          name: trans("Negative Personality Change"),
          value: -1
        },
        {
          name: trans("No/Minimal Personality Change"),
          value: 0
        },
        {
          name: trans("Positive Personality Change"),
          value: 1
        }
      ],
      placeholder: trans("Select")
    }
  };
};

export const PROTAGONIST_ATTRIBUTES = () => {
  const { trans } = useTrans();
  return {
    options: [
      trans("Albino"),
      trans("White skin tone"),
      trans("Pale"),
      trans("Olive skin tone"),
      trans("Light brown skin tone"),
      trans("Brown skin tone"),
      trans("Dark brown skin tone"),
      trans("Black skin tone"),
      trans("Vitiligo"),
      // Attractiveness
      trans("Above Average"),
      trans("Attractive"),
      trans("Average"),
      trans("Beautiful"),
      trans("Cute"),
      trans("Good Looking"),
      trans("Gorgeous"),
      trans("Handsome"),
      trans("Hot"),
      trans("Pretty"),
      trans("Sexy"),
      trans("Stunning"),
      // Intelligence/Drive
      trans("Alpha"),
      trans("Ambitious"),
      trans("Arrogant"),
      trans("Awkward"),
      trans("Bad Boy"),
      trans("Beta"),
      trans("Boy next door"),
      trans("Charming"),
      trans("Clever"),
      trans("Common sense"),
      trans("Creep"),
      trans("Cunning"),
      trans("Dominant"),
      trans("Educated"),
      trans("Emotional"),
      trans("Friendly"),
      trans("Funny"),
      trans("Geek"),
      trans("Genius"),
      trans("Girl next door"),
      trans("Good Girl"),
      trans("Jerk"),
      trans("Kind"),
      trans("Lazy"),
      trans("Nerd"),
      trans("Nice"),
      trans("Not smart"),
      trans("Omega"),
      trans("Popular"),
      trans("Sensitive"),
      trans("Silly"),
      trans("Smart"),
      trans("Submissive"),
      trans("Sweet"),
      trans("Underdog"),
      trans("Unpopular"),
      trans("Wise"),
      trans("Intelligent"),
      trans("Passionate"),
      // Profession
      trans("Actor"),
      trans("Archaeologist"),
      trans("Architect"),
      trans("Artist"),
      trans("Assassin"),
      trans("Astronaut"),
      trans("Athlete"),
      trans("Author"),
      trans("Babysitter"),
      trans("Baker"),
      trans("Barista"),
      trans("Bartender"),
      trans("Billionaire"),
      trans("Bodyguard"),
      trans("Boss"),
      trans("Business person"),
      trans("Butler"),
      trans("Captain"),
      trans("Cashier"),
      trans("Celebrity"),
      trans("CEO"),
      trans("Chef"),
      trans("Con Artist"),
      trans("Criminal"),
      trans("Dentist"),
      trans("Detective"),
      trans("Doctor"),
      trans("Driver"),
      trans("Editor"),
      trans("Engineer"),
      trans("Entrepreneur"),
      trans("Executive"),
      trans("Farmer"),
      trans("Firefighter"),
      trans("Flight Attendant"),
      trans("Gamer"),
      trans("Hunter"),
      trans("Influencer"),
      trans("Intern"),
      trans("Journalist"),
      trans("Knight"),
      trans("Lawyer"),
      trans("Lifeguard"),
      trans("Lumberjack"),
      trans("Magician"),
      trans("Maid"),
      trans("Massage Therapist"),
      trans("Mechanic"),
      trans("Millionaire"),
      trans("Mobster"),
      trans("Model"),
      trans("Musician"),
      trans("Nanny"),
      trans("Novelist"),
      trans("Nurse"),
      trans("Photographer"),
      trans("Pilot"),
      trans("Police Officer"),
      trans("Politician"),
      trans("Popstar"),
      trans("Prince"),
      trans("Princess"),
      trans("Prisoner"),
      trans("Private Eye"),
      trans("Professor"),
      trans("Programmer"),
      trans("Royalty"),
      trans("Scientist"),
      trans("Secretary"),
      trans("Server"),
      trans("Sex worker"),
      trans("Singer"),
      trans("Soldier"),
      trans("Spy"),
      trans("Student"),
      trans("Superhero"),
      trans("Teacher"),
      trans("Therapist"),
      trans("Traveler"),
      trans("Waiter/Waitress"),
      trans("Writer"),
      // Creature Type
      trans("Alien"),
      trans("Angel"),
      trans("Animal"),
      trans("Child"),
      trans("Clown"),
      trans("Cyborg"),
      trans("Devil"),
      trans("Dinosaur"),
      trans("Elderly"),
      trans("Elf"),
      trans("Fairy"),
      trans("Father"),
      trans("Fey"),
      trans("Ghost"),
      trans("Infant"),
      trans("Insect"),
      trans("Machine"),
      trans("Mermaid"),
      trans("Mother"),
      trans("Octopus"),
      trans("Old"),
      trans("Parent"),
      trans("Pirate"),
      trans("Plant"),
      trans("Reaper"),
      trans("Robot"),
      trans("Spirit"),
      trans("Succubus"),
      trans("Supernatural"),
      trans("Teenager"),
      trans("Vampire"),
      trans("Werewolf"),
      trans("Witch"),
      trans("Wizard"),
      trans("Young"),
      trans("Zombie"),
      // Ethnicity
      trans("African American"),
      trans("Anglo Saxon"),
      trans("Arab"),
      trans("Asian"),
      trans("Australasian"),
      trans("Biracial"),
      trans("Caribbean"),
      trans("Caucasian"),
      trans("Central American"),
      trans("Central Asian"),
      trans("East African"),
      trans("East Asian"),
      trans("Eastern European"),
      trans("Eurasian"),
      trans("European"),
      trans("Fictional Ethnicity"),
      trans("First Nations"),
      trans("Hispanic"),
      trans("Indigenous"),
      trans("Jewish"),
      trans("Latinx"),
      trans("Levantine"),
      trans("Mediterranean"),
      trans("Melanesian"),
      trans("Mesopotamian"),
      trans("Middle Eastern"),
      trans("Multiracial"),
      trans("Multiple Ethnic Background"),
      trans("Native"),
      trans("Native American"),
      trans("North African"),
      trans("North American"),
      trans("Northern East African"),
      trans("Northwestern European"),
      trans("Pacific Islander"),
      trans("Scandinavian"),
      trans("South American"),
      trans("South Asian"),
      trans("South East Asian"),
      trans("South Pacific Islander"),
      trans("South East African"),
      trans("Southern European"),
      trans("Sub Saharan African"),
      trans("West African"),
      trans("Western Asian"),
      trans("Western European"),
      // Character Disability
      trans("Visually Impaired"),
      trans("Chronic illness or pain"),
      trans("Hard of Hearing"),
      trans("Mobility impairment"),
      trans("Physical Illness"),
      trans("Disease"),
      trans("Wheelchair Person"),
      trans("Learning disability"),
      trans("Neurodiverse"),
      trans("Autistic"),
      trans("Depression"),
      trans("Anxiety"),
      trans("Mental Illness"),
      trans("Trauma"),
      trans("Paralyzed"),
      trans("Paranoia"),
      trans("Cancer"),
      trans("Eating Disorder"),
      trans("Amputee"),
      trans("Memory Loss"),
      trans("Has an Addiction"),
      trans("Personality Disorder"),
      trans("Deaf"),
      trans("Able-bodied"),
      trans("Neurotypical"),
      // Hair Type
      trans("Straight (Fine/Thin) hair"),
      trans("Straight (Medium) hair"),
      trans("Straight (Coarse) hair"),
      trans("Wavy (Fine/Thin) hair"),
      trans("Wavy (Medium) hair"),
      trans("Wavy (Coarse) hair"),
      trans("Curly (Loose) hair"),
      trans("Curly (Tight) hair"),
      trans("Kinky (Soft) hair"),
      trans("Kinky (Wiry) hair"),
      trans("No hair"),
      trans("Fur"),
      trans("Natural Hair"),
      trans("Afro"),
      trans("Locks"),
      // Hair Length
      trans("Long hair"),
      trans("Medium hair"),
      trans("Short hair"),
      trans("Bald"),
      trans("Buzz cut"),
      // Hair Colour
      trans("Auburn hair"),
      trans("Chestnut brown hair"),
      trans("Copper hair"),
      trans("Dark brown hair"),
      trans("Brown hair"),
      trans("Ginger hair"),
      trans("Golden blonde hair"),
      trans("Grey hair"),
      trans("Light blonde hair"),
      trans("Light brown hair"),
      trans("Light chestnut brown hair"),
      trans("Medium blonde hair"),
      trans("Medium brown hair"),
      trans("Natural black hair"),
      trans("Natural brown hair"),
      trans("Strawberry blonde hair"),
      trans("Titian hair"),
      trans("White hair"),
      trans("Red hair"),
      trans("Orange hair"),
      trans("Yellow hair"),
      trans("Green hair"),
      trans("Blue hair"),
      trans("Purple hair"),
      trans("Honey Blonde hair"),
      trans("Caramel hair"),
      // Eyes
      trans("Brown eyes"),
      trans("Blue eyes"),
      trans("Hazel eyes"),
      trans("Amber eyes"),
      trans("Green eyes"),
      trans("Gray eyes"),
      trans("Red eyes"),
      trans("Heterochromia"),
      trans("White eyes"),
      trans("Black eyes"),
      trans("Violet eyes"),
      trans("Orange eyes"),
      trans("Yellow eyes"),
      // Body Type
      trans("Thin"),
      trans("Athletic"),
      trans("Curvy"),
      trans("Voluptuous"),
      trans("Muscular"),
      trans("Overweight"),
      trans("Underweight"),
      trans("Average weight"),
      // Gender
      trans("Male"),
      trans("Female"),
      trans("Agender"),
      trans("Androgyne"),
      trans("Androgynous"),
      trans("Bigender"),
      trans("Cis"),
      trans("Cisgender"),
      trans("Cisgender Female"),
      trans("Cisgender Male"),
      trans("Cisgender Man"),
      trans("Cisgender Woman"),
      trans("Female to Male"),
      trans("FTM"),
      trans("Gender Fluid"),
      trans("Gender Nonconforming"),
      trans("Gender Questioning"),
      trans("Gender Variant"),
      trans("Genderqueer"),
      trans("Intersex"),
      trans("Male to Female"),
      trans("MTF"),
      trans("Neutrois"),
      trans("Non-binary"),
      trans("Pangender"),
      trans("Trans"),
      trans("Trans Female"),
      trans("Trans Male"),
      trans("Trans Man"),
      trans("Trans Person"),
      trans("Trans Woman"),
      trans("Transfeminine"),
      trans("Transgender"),
      trans("Transgender Female"),
      trans("Transgender Male"),
      trans("Transgender Man"),
      trans("Transgender Person"),
      trans("Transgender Woman"),
      trans("Transmasculine"),
      trans("Two-Spirit"),
      trans("Gender Neutral"),
      // Sexual Orientation
      trans("Lesbian"),
      trans("Gay"),
      trans("Bisexual"),
      trans("Pansexual"),
      trans("Heterosexual"),
      trans("Bicurious"),
      trans("Questioning"),
      trans("Queer"),
      trans("Polysexual"),
      trans("Androsexual"),
      trans("Gynosexual"),
      trans("Asexual")
    ]
  };
};

export const STORY_GOAL = () => {
  const { trans } = useTrans();
  return {
    title: trans("The Story Goal"),
    info: trans(
      "The Protagonist’s goal in the story is what is known as the Story Goal. A Story Goal is the foundation of every plot, which is why it’s critical to understand what your Protagonist’s main objective is in the story. The Story Goal can be physical or emotional."
    ),
    section1: {
      name: "protagonist_goal.description",
      label: trans("What is your Protagonist's ultimate goal in this story?"),
      limit: 75
    },
    section2: {
      label: trans("The goal is primarily:"),
      name: "protagonist_goal.physical_emotional",
      options: [
        {
          label: trans("Physical"),
          value: 0
        },
        {
          label: trans("Emotional"),
          value: 1
        }
      ]
    },
    section3: {
      name: "protagonist_goal.type",
      label: trans("The goal type is to:"),
      options: [
        [
          {
            name: trans("Acquire"),
            value: "acquire"
          },
          {
            name: trans("Explore"),
            value: "explore"
          },
          {
            name: trans("Escape"),
            value: "escape"
          },
          {
            name: trans("Find"),
            value: "find"
          },
          {
            name: trans("Protect"),
            value: "protect"
          },
          {
            name: trans("Survive"),
            value: "survive"
          }
        ],
        [
          {
            name: trans("Accept"),
            value: "accept"
          },
          {
            name: trans("Appreciate"),
            value: "appreciate"
          },
          {
            name: trans("Forget"),
            value: "forget"
          },
          {
            name: trans("Learn"),
            value: "learn"
          },
          {
            name: trans("Love"),
            value: "love"
          },
          {
            name: trans("Self Actualize"),
            value: "self_actualize"
          }
        ]
      ],
      placeholder: trans("Select")
    },
    section4: {
      label: trans("To achieve the story goal the Protagonist has to . . ."),
      options: [
        {
          id: "protagonist_goal.change_themselves_surroundings",
          sliderLabels: [
            trans("Change themselves"),
            trans("Change their surroundings")
          ]
        },
        {
          id: "protagonist_goal.explore_emotions_world",
          sliderLabels: [
            trans("Explore their emotions"),
            trans("Explore their world")
          ]
        },
        {
          id: "protagonist_goal.navigate_familiar_unfamiliar",
          sliderLabels: [
            trans("Navigates a familiar world"),
            trans("Encounters an unfamiliar world")
          ]
        },
        {
          id: "protagonist_goal.recall_past",
          sliderLabels: [
            trans("Ignore the Past"), //eslint-disable-line
            trans("Recall the past")
          ]
        },
        {
          id: "protagonist_goal.find_meaning",
          sliderLabels: [
            trans("Reappreciate the current meaning"),
            trans("Find a new meaning")
          ]
        },
        {
          id: "protagonist_goal.take_keep",
          sliderLabels: [
            trans("Take something away from the antagonist"),
            trans("Keep something away from the antagonist")
          ]
        }
      ]
    }
  };
};

export const STORY_OUTCOME = () => {
  const { trans } = useTrans();
  return {
    title: trans("The Story Outcome"),
    info: trans(
      "Ideally, every story has a planned outcome. This will help you set the arc of your story, and plan accordingly. The Story Outcome relates to the outcome the Protagonist is aiming to achieve. Knowing where you want to go with the story will help you pace your story and keep your narrative as tight as possible."
    ),
    section1: {
      name: "protagonist_goal.outcome",
      label: trans("What happens at the end of the story?"),
      limit: 150
    },
    section2: {
      label: trans(
        "Will the Protagonist succeed in reaching the goal at the end of this story?"
      ),
      name: "protagonist_goal.succeed",
      options: [
        {
          label: trans("Yes"),
          value: 1
        },
        {
          label: trans("No"),
          value: 0
        }
      ]
    },
    section3: {
      label: trans(
        "How will the Protagonist feel about the outcome from achieving the goal?"
      ),
      name: "protagonist_goal.outcome_feeling",
      options: [
        {
          label: trans("Good"),
          value: 1
        },
        {
          label: trans("Bad"),
          value: 0
        }
      ]
    }
  };
};

export const STORY_OPPORTUNITIES = () => {
  const { trans } = useTrans();
  return {
    title: trans("Story Opportunities on Wattpad"),
    info: trans(
      "Interested in submitting your story to the backlog of stories Wattpad reviews for opportunities like being featured, being part of the Paid Stories program, or being considered as a Wattpad Star Writer?"
    ),
    section1: {
      name: "opportunity.interested",
      options: [
        {
          label: trans("Yes"),
          value: 1
        },
        {
          label: trans("No"),
          value: 0
        }
      ]
    },
    section2: {
      label: trans(
        "What specific opportunities on Wattpad are you interested in for this story?"
      ),
      name: "opportunity.types",
      options: [
        {
          label: trans(
            "Wattpad Paid Stories (make money from fans who pay for your story on Wattpad)"
          ),
          value: "paid"
        },
        {
          label: trans(
            "Wattpad Publishing (be considered for publication by a major commercial publisher)"
          ),
          value: "publishing"
        },
        {
          label: trans("Wattpad Books (be published by Wattpad)"),
          value: "books"
        },
        {
          label: trans(
            "Wattpad Studios (be considered for adaptation into a television show or film)"
          ),
          value: "entertain"
        },
        {
          label: trans("All available opportunities with Wattpad"),
          value: "all"
        }
      ]
    },
    section3: {
      label: trans("What is your story’s logline?"),
      name: "opportunity.logline",
      limit: 150,
      about: trans(
        "A logline is a very short “elevator pitch” about your story that reviews the key elements of the story without giving too much away. Loglines aim to hook the reader into wanting to read more."
      )
    },
    section4: {
      label: trans("How much have you written so far?"),
      name: "opportunity.story_progress",
      options: [
        {
          value: "in_progress",
          name: trans("My Story is in Progress")
        },
        {
          value: "halfway",
          name: trans("My Story is Halfway Done")
        },
        {
          value: "editing",
          name: trans("My Story is Complete but being Edited")
        },
        {
          value: "complete",
          name: trans("My Story is Complete")
        }
      ],
      placeholder: trans("Select")
    },
    notComplete: trans(
      "All fields in the Story Notes must be filled before submitting Story to Wattpad.\nYou can change and resubmit your Story Notes to Wattpad again at anytime."
    )
  };
};
