import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";

function NewMessage({ toggleModal }) {
  const { trans } = useTrans();
  const [inputText, setInputText] = useState("");

  const [notFollowers, setNotFollowers] = useState([]);
  const [followers, setFollowers] = useState([]);

  const handleChange = event => {
    const query = event.target.value;
    setInputText(query);
    const url = `api/v3/autocomplete/following_user?term=${query}`;
    const request = Promise.resolve($.get(url));
    request
      .then(data => {
        setNotFollowers(data.filter(user => user.category === "Other People"));
        setFollowers(data.filter(user => user.category === "Your Friends"));
      })

      .catch(() => {
        const toast = new window.app.views.ErrorToast(
          {
            message: trans("Sorry, something went wrong. Please try again.")
          },
          { type: "dismissable" }
        );
        toast.render();
      });
  };
  return (
    <div className="new-msg-modal">
      <div className="new-msg-header">
        <h3>{trans("New Message")}</h3>
        <p>{trans("Send message to") + ":"}</p>
        <input type="text" value={inputText} onChange={handleChange} />
      </div>

      {inputText !== "" && (
        <div className="search-results">
          {followers.length > 0 && <p>{trans("People You Follow")}</p>}
          {followers.map(user => (
            <SearchResult
              toggleModal={toggleModal}
              key={user.label}
              user={user}
            />
          ))}
          {notFollowers.length > 0 && <p>{trans("People on Wattpad")}</p>}
          {notFollowers.map(user => (
            <SearchResult
              key={user.label}
              user={user}
              toggleModal={toggleModal}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function SearchResult({ user, toggleModal }) {
  const onNavigate = () => {
    toggleModal();
    window.app.router.navigate(`/inbox/${user.label}`, { trigger: true });
  };
  return (
    <button className="search-result btn-no-background" onClick={onNavigate}>
      <img
        src={user.image}
        alt={`${user.label}'s avatar`}
        className="avatar avatar-sm3"
      />
      <p>{user.label}</p>
    </button>
  );
}
NewMessage.propTypes = {
  toggleModal: PropTypes.func
};
SearchResult.propTypes = {
  user: PropTypes.object,
  toggleModal: PropTypes.func
};

export { NewMessage, SearchResult };
