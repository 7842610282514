import PropTypes from "prop-types";
import React from "react";

import { useTrans } from "../../../hooks";

function ThreadFilter({ filter, loadThreads }) {
  const { trans } = useTrans();

  function onChangeFilter({ target: { value } }) {
    loadThreads({ filter: value });
  }

  return (
    <div className="threads-filter">
      <select value={filter} onChange={onChangeFilter}>
        <option value="">{trans("All")}</option>
        <option value={"followers"}>{trans("My Followers")}</option>
        <option value={"following"}>{trans("People I'm Following")}</option>
        <option value={"unread"}>{trans("Unread Messages")}</option>
      </select>
    </div>
  );
}

ThreadFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  loadThreads: PropTypes.func.isRequired
};

export default ThreadFilter;
