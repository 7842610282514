import { useDispatch } from "react-redux";

import {
  openPopover,
  closePopover
} from "../shared-components/popover/reducers";

/**
 * A hook to use a popover.
 *
 */
export const usePopover = () => {
  const dispatch = useDispatch();

  /**
   * Closes the Popover.
   *
   * @param triggerRef Reference to the component that triggers the popover.
   *
   */
  const handleClosePopover = triggerRef => {
    triggerRef.current?.focus();

    dispatch(closePopover());
  };

  /**
   * Opens the Popover.
   *
   * @param component Component containing popover's content.
   * @param triggerRef Reference to the component that triggers the popover.
   * @param contentClass Classname for the popover's content wrapper.
   * @param passthroughProps Addtional props for React Popper. See https://popper.js.org/react-popper/v2/render-props/
   *                         for more details.
   *
   */
  const handleOpenPopover = (
    component,
    triggerRef,
    contentClass,
    passthroughProps
  ) => {
    dispatch(
      openPopover({
        component,
        triggerRef,
        passthroughProps,
        contentClass
      })
    );
  };

  return { handleClosePopover, handleOpenPopover };
};
