export const SECTION_INTROS = trans => [
  trans("To get the best Wattpad experience, we want to know a bit about you!"),
  trans("So you like to write! What best describes you as a writer?")
];

export const READER_OR_WRITER_OPTIONS = trans => ({
  reader: {
    message: trans("I like to read"),
    icon: "read"
  },
  writer: {
    message: trans("I like to write"),
    icon: "write"
  },
  reader_writer: {
    message: trans("I like both"),
    icon: "both"
  }
});

export const WRITER_MOTIVATION_OPTIONS = trans => ({
  fun: trans("I mostly write for fun"),
  researching: trans("I'm exploring opportunities for my work(s)"),
  pursuing_career: trans("I'm pursuing a career as a writer"),
  professional: trans("I am a professional writer"),
  none: trans("Not quite any of these...")
});

export const FINISHED_NOVEL_OPTIONS = trans => ({
  yes: trans("Yes"),
  almost: trans("Almost"),
  no: trans("No")
});
