import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../hooks";
import {
  DEFAULT_WATTYS_SITE_LINK,
  MAP_WATTYS_SITE_TO_LINKS
} from "../ContestForm/constants";

const CompleteEligibleBanner = ({ onBtnClick }) => {
  const { trans } = useTrans();
  const wattysSiteLink =
    MAP_WATTYS_SITE_TO_LINKS[parseInt(app.get("language"), 10)] ||
    DEFAULT_WATTYS_SITE_LINK;

  return (
    <>
      <div className="text">
        {/* prettier-ignore */ trans("Congratulations! Your story is eligible for The %s Wattys!", new Date().getFullYear())}
        <a
          className="plain-link bold"
          href={wattysSiteLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans("Find out more about the Wattys")}
        </a>
      </div>
      <button onClick={onBtnClick} className="btn btn-white">
        <div className="btn-text">{trans("Enter this story")}</div>
      </button>
    </>
  );
};

CompleteEligibleBanner.propTypes = {
  onBtnClick: PropTypes.func.isRequired
};

export default CompleteEligibleBanner;
