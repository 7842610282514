// Action Types
const name = "THREADS";
const LOAD_REQUESTED = `${name}/LOAD_REQUESTED`;
const LOAD_MORE_REQUESTED = `${name}/LOAD_MORE_REQUESTED`;
const LOAD = `${name}/LOAD`;
const LOAD_MORE = `${name}/LOAD_MORE`;
const RESET = `${name}/RESET`;

import { MUTE, UNMUTE, ERROR } from "../../profile/reducers";

// Reducer
const initialState = {
  isLoading: true,
  isLoadingMore: false,
  isEmailVerified: false,
  threads: [],
  filter: "",
  total: 0,
  offset: 0
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_REQUESTED:
      return {
        ...initialState,
        filter: action.filter
      };
    case LOAD_MORE_REQUESTED:
      return {
        ...state,
        filter: action.filter,
        isLoadingMore: true
      };
    case LOAD_MORE:
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        threads: [...state.threads, ...action.threads],
        offset: state.offset + action.threads.length,
        total: action.total
      };
    case LOAD:
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        isEmailVerified: wattpad.utils.currentUser().get("verified_email"),
        threads: [...action.threads],
        offset: action.threads.length,
        total: action.total
      };
    case RESET:
      return {
        ...initialState
      };

    case MUTE: {
      const threadsWithMute = state.threads.map(thread => {
        const isMutedUser = thread.user.name === action.name;
        if (isMutedUser) {
          thread.user.isMutedByCurrentUser = true;
        }
        return thread;
      });
      return {
        ...state,
        threads: threadsWithMute
      };
    }

    case UNMUTE: {
      const threadsWithUnmute = state.threads.map(thread => {
        const isUnmutedUser = thread.user.name === action.name;
        if (isUnmutedUser) {
          thread.user.isMutedByCurrentUser = false;
        }
        return thread;
      });
      return {
        ...state,
        threads: threadsWithUnmute
      };
    }
    case ERROR:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}

const username = () => wattpad.utils.currentUser().get("username");
const loadLimit = 10;

// Action Creators
export function loadThreads({ filter = "", offset = 0 }) {
  const threadsUrl = `/api/v3/users/${username()}/inbox?filter=${filter}&limit=${loadLimit}&offset=${offset}`;

  return function(dispatch) {
    const request = Promise.resolve($.get(threadsUrl));

    const isLoadMore = offset > 0;
    if (isLoadMore) {
      dispatch({ type: LOAD_MORE_REQUESTED, filter });
    } else {
      dispatch({ type: LOAD_REQUESTED, filter });
    }

    return request
      .then(data => {
        const threads = data.threads.map(thread => {
          return {
            ...thread,
            user: {
              ...thread.user,
              isMutedByCurrentUser: thread.user.safety.isMuted,
              isBlockedByCurrentUser: thread.user.safety.isBlocked
            }
          };
        });
        dispatch({
          type: isLoadMore ? LOAD_MORE : LOAD,
          threads: threads,
          total: data.total,
          filter
        });
      })
      .catch(() => dispatch({ type: LOAD, threads: [], filter }));
  };
}

export function resetThreads() {
  return function(dispatch) {
    dispatch({ type: RESET });
  };
}
