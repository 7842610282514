import React from "react";
import { useTrans } from "../hooks";
import { ConnectImage } from "./";
// TODO: Make a generic error page component with generic CSS classes.
const StoryError500 = () => {
  const { trans } = useTrans();

  const handleReloadPage = () => {
    window.location.reload(true);
  };

  return (
    <div className="story-500-wrapper">
      <ConnectImage name={"errors/story-500.png"} className="error-500-image" />
      <div className="error-500-info">
        <h3>{trans("Hmmm... something's wrong.")}</h3>
        <p>
          {trans(
            "We can’t seem to load this story right now. Try going back or reloading."
          )}
        </p>
        <div className="buttons-500">
          <button className="btn btn-primary" onClick={handleReloadPage}>
            {trans("Reload")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoryError500;
