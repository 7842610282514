import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../hooks";

const SearchTabs = ({ searchTerm = "", resultType }) => {
  const { trans } = useTrans();

  return (
    <div className="container">
      <nav id="tabs" className="search-tabs">
        <ul>
          <li>
            <a
              href={`/search/${encodeURIComponent(searchTerm)}/stories`}
              data-target="stories"
              className={classNames("on-tab-navigate", {
                active: resultType === "stories"
              })}
            >
              {trans("Stories")}
            </a>
          </li>
          <li>
            <a
              href={`/search/${encodeURIComponent(searchTerm)}/people`}
              data-target="people"
              className={classNames("on-tab-navigate", {
                active: resultType === "people"
              })}
            >
              {trans("Profiles")}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

SearchTabs.propTypes = {
  searchTerm: PropTypes.string,
  resultType: PropTypes.string
};

export default SearchTabs;
