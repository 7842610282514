import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChangeEmail } from "./ChangeEmail";
import { SentEmail } from "./SentEmail";

function VerifyEmail({
  isChangeEmailModal,
  email,
  userId,
  isSuccess,
  hideModal,
  //dismissModal will close the modal when the email has been successfully updated and there is no need to set cookies anymore
  dismissModal,
  onUpdateEmail
}) {
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(
    isChangeEmailModal
  );
  const [isSentEmailSuccess, setIsSentEmailSuccess] = useState(isSuccess);
  const [newEmail, setNewEmail] = useState(null);

  const handleOnClickChangeEmail = event => {
    wattpad.utils.stopEvent(event);
    setShowChangeEmailModal(true);
  };

  const handleOnClickResendEmail = event => {
    wattpad.utils.stopEvent(event);

    $.ajax({
      type: "POST",
      url: "/api/v3/users/validate",
      data: { activation_email: "true" },
      success: function() {
        setIsSentEmailSuccess(true);
        setShowChangeEmailModal(false);
      },
      error: function() {
        setIsSentEmailSuccess(false);
        setShowChangeEmailModal(false);
      }
    });
  };

  const onEmailChanged = newEmail => {
    setNewEmail(newEmail);
    setShowChangeEmailModal(false);
    setIsSentEmailSuccess(true);
  };

  const renderModal = () => {
    if (showChangeEmailModal) {
      return (
        <ChangeEmail
          email={email}
          userId={userId}
          hideModal={hideModal}
          onEmailChanged={onEmailChanged}
          onUpdateEmail={onUpdateEmail}
        />
      );
    } else {
      return (
        <SentEmail
          email={newEmail || email}
          isSuccess={isSentEmailSuccess}
          hideModal={
            dismissModal && isSentEmailSuccess ? dismissModal : hideModal
          }
          onClickResendEmail={handleOnClickResendEmail}
          onClickChangeEmail={handleOnClickChangeEmail}
        />
      );
    }
  };

  return renderModal();
}

VerifyEmail.propTypes = {
  isChangeEmailModal: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  hideModal: PropTypes.func,
  dismissModal: PropTypes.func,
  onUpdateEmail: PropTypes.func
};

export { VerifyEmail };
