import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTrans } from "../hooks";
import { Icon } from "./";

const SearchBar = ({
  searchTerm: defaultSearchTerm,
  handleSearchSubmit,
  handleSaveSearchTerm
}) => {
  const { trans } = useTrans();

  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const [isActiveSearch, setIsActiveSearch] = useState(!!defaultSearchTerm);

  const searchInputRef = useRef(null);

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    searchInputRef?.current?.blur();

    setIsActiveSearch(!!searchTerm);
    handleSaveSearchTerm(searchTerm);
    handleSearchSubmit(searchTerm);
  };

  const handleCancel = event => {
    event.preventDefault();
    setIsActiveSearch(false);
    app.router.navigate("/search", { trigger: true });
  };

  return (
    <form
      role="search"
      aria-label={trans("Stories and people")}
      className="search-bar"
      onSubmit={handleSubmit}
    >
      <Icon className="search-bar__icon" name="search" size="24" />
      <input
        className="search-bar__input"
        type="search"
        value={searchTerm}
        onChange={handleChange}
        placeholder={trans("Search for stories and people")}
        minLength="1"
        spellCheck="false"
        autoComplete="off"
        ref={searchInputRef}
        size="10" // allow for responsive view with Cancel button on small screens
      />
      {isActiveSearch && (
        // eslint-disable-next-line wp-lint-rules/valid-href
        <a href="/search" className="search-bar__cancel" onClick={handleCancel}>
          {trans("Cancel")}
        </a>
      )}
    </form>
  );
};

SearchBar.defaultProps = {
  handleSearchSubmit: () => {},
  handleSaveSearchTerm: () => {}
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string,
  handleSearchSubmit: PropTypes.func.isRequired,
  handleSaveSearchTerm: PropTypes.func
};

export default SearchBar;
