import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSetDocumentTitle, useTrans } from "../../../hooks";
import {
  Icon,
  Loader,
  Countdown,
  MODAL_ID
} from "../../../../components/shared-components";
import {
  Pill,
  PillVariant,
  PillShape,
  Accent,
  Button,
  ButtonVariant,
  IconNames,
  Typography
} from "@wattpad/web-ui-library";
import { isClient } from "../../../../components/helpers";
import BrowseStoryItem, {
  BrowseStoryItemProps
} from "../../../../components/views/story-item/BrowseStoryItem";
import StoryPreviewModal from "../../../../components/views/story-item/StoryPreviewModal";
import PremiumPicksFAQModal from "../PremiumPicksFAQModal";
import { fetchPremiumPicks } from "../reducers/premium-picks-catalog-reducer";

const Tabs = {
  THIS_MONTH: "thismonthpicks",
  NEXT_MONTH: "nextmonthpicks"
};

const PREMIUM_PICKS_FAQ_COOKIE = "seen-premium-picks-faq";

const PremiumPicksCatalog = ({
  className,
  isPremium,
  currentPicks,
  upcomingPicks,
  isLoading,
  loadError,
  fetchPremiumPicks
}) => {
  const { trans } = useTrans();
  const [activeTab, setActiveTab] = useState(Tabs.THIS_MONTH);
  const [modalHasBeenSeen, setModalHasBeenSeen] = useState(true);
  const isMobile = isClient() && window.innerWidth < 575;

  const handleOpenFAQModal = () => {
    window.wattpad.utils.openModal(MODAL_ID.PREMIUM_PICKS_FAQ_MODAL_ID);
  };

  const onPremium = () => {
    window.te.push("event", "subscriptions", "cta", null, "view", {
      cta_type: "premium_picks_subscribe_button"
    });
  };

  useEffect(
    () => {
      if (!wattpad?.utils.getCookie(PREMIUM_PICKS_FAQ_COOKIE)) {
        setModalHasBeenSeen(false);
        handleOpenFAQModal();
      }

      window.te.push("event", "app", "page", null, "view", {
        page: "premium_picks"
      });

      fetchPremiumPicks();
    },
    [fetchPremiumPicks]
  );

  const getStories = useCallback(
    () => {
      switch (activeTab) {
        case Tabs.THIS_MONTH:
          return currentPicks?.stories || [];
        case Tabs.NEXT_MONTH:
          return upcomingPicks?.stories || [];
        default:
          return [];
      }
    },
    [activeTab, currentPicks, upcomingPicks]
  );

  useSetDocumentTitle(trans("Catalog") + " - " + trans("Premium Picks"));

  return (
    <>
      <div className={classNames(className, "premium-picks-catalog")}>
        <div className="catalog-header">
          <div className="container">
            <button className="help-action-icon" onClick={handleOpenFAQModal}>
              <Icon
                name="helpCircle"
                size="24"
                className="help-icon"
                color="wp-neutral-5"
              />
            </button>
            <h1 className={classNames("heading", Typography.HEADING.SMALL)}>
              <Icon
                name="zap"
                size="20"
                className="zap-icon"
                color={"ds-neutral-00-solid"}
                fill
              />
              {trans("Premium Picks")}
            </h1>
            <div
              className={classNames("description", Typography.PARAGRAPH.MEDIUM)}
            >
              {trans(
                "Get a free monthly bundle of 5+ Wattpad Originals with any Premium or Premium+ plan."
              )}{" "}
              <button
                className="learn-more-button"
                onClick={handleOpenFAQModal}
              >
                {trans("Learn more")}
              </button>
            </div>
            <Pill
              className="access-expires-pill"
              variant={PillVariant.LIGHT}
              accent={Accent.DEFAULT}
              shape={PillShape.ROUND}
              leadingIcon={IconNames.WpHistory}
              label={
                <>
                  {activeTab === Tabs.THIS_MONTH
                    ? trans("Expiring in")
                    : trans("Available in")}{" "}
                  {
                    <Countdown
                      date={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 1,
                          1,
                          0,
                          0,
                          0,
                          0
                        )
                      }
                      showDays={true}
                      showHours={true}
                      updateInterval={60}
                    />
                  }
                </>
              }
            />
            {!isPremium && (
              <Button
                className="subscribe-cta"
                href="/premium"
                isExternalLink={true}
                onClick={onPremium}
                variant={ButtonVariant.SECONDARY}
                leadingIcon={IconNames.WpPremium}
                label={trans("Subscribe now")}
              />
            )}
          </div>
        </div>

        <div className="container">
          <div className="catalog-navigation">
            <button
              className={classNames({ active: activeTab === Tabs.THIS_MONTH })}
              onClick={() => setActiveTab(Tabs.THIS_MONTH)}
            >
              {trans("This month")}
            </button>
            <button
              className={classNames({ active: activeTab === Tabs.NEXT_MONTH })}
              onClick={() => setActiveTab(Tabs.NEXT_MONTH)}
            >
              {trans("Next month")}
            </button>
          </div>

          <div className="catalog-stories">
            {isLoading ? (
              <div className="loading-spinner">
                <Loader />
              </div>
            ) : null}
            {!isLoading
              ? getStories().map(item => (
                  <div className="browse-story-item" key={`story-${item.id}`}>
                    <BrowseStoryItem
                      {...item}
                      {...{ trans, app: window?.app, useReactModal: !isMobile }}
                    />
                  </div>
                ))
              : null}
            {loadError ? trans("Failed to load Premium Picks") : null}
          </div>
        </div>
      </div>
      <PremiumPicksFAQModal
        {...{
          onClose: () => {
            if (!modalHasBeenSeen) {
              setModalHasBeenSeen(true);
              window?.wattpad?.utils.setCookie(PREMIUM_PICKS_FAQ_COOKIE, 1);
            }
          }
        }}
      />
      <StoryPreviewModal />
    </>
  );
};

const readingListProps = {
  id: PropTypes.number,
  name: PropTypes.string,
  stories: PropTypes.arrayOf(PropTypes.shape(BrowseStoryItemProps)),
  total: PropTypes.number
};

PremiumPicksCatalog.propTypes = {
  className: PropTypes.string,
  isPremium: PropTypes.bool,
  currentPicks: PropTypes.shape(readingListProps),
  upcomingPicks: PropTypes.shape(readingListProps),
  isLoading: PropTypes.bool,
  loadError: PropTypes.string,
  fetchPremiumPicks: PropTypes.func
};

export default connect(
  state => ({
    currentPicks: state.premiumPicksCatalog.currentPicks,
    upcomingPicks: state.premiumPicksCatalog.upcomingPicks,
    isLoading: state.premiumPicksCatalog.isLoading,
    loadError: state.premiumPicksCatalog.loadError
  }),
  { fetchPremiumPicks }
)(PremiumPicksCatalog);
