import React from "react";
import Icon from "./Icon";
import PropTypes from "prop-types";

export default class MenuOptions extends React.Component {
  render() {
    let headerName = this.props.headerName;
    const options = this.props.options;

    return (
      <ul className="menu-options">
        {options.map(option => {
          return (
            <li key={option.key}>
              <Icon iconName="fa-right" height="16" color="wp-neutral-1" />
              {headerName === "Browse" ? (
                <a href={option.url} className="on-navigate on-topic">
                  {option.name}
                </a>
              ) : (
                <a href={option.url} className="on-navigate">
                  {option.name}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}

MenuOptions.propTypes = {
  headerName: PropTypes.string,
  options: PropTypes.array.isRequired
};
