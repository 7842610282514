import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  DateTimeField,
  Checkbox,
  RadioButtons,
  Modal,
  MODAL_ID
} from "../../shared-components";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";
import { useTrans } from "../../hooks";
import moment from "moment";
import publishPart from "../../../helpers/publishing/client.publish-part";
import schedulePart from "../../../helpers/publishing/client.schedule-part";
import unschedulePart from "../../../helpers/publishing/client.unschedule-part";

const PUBLISH_TYPE = {
  Now: 0,
  Scheduled: 1
};
const PUBLISH_INTERVAL_MINUTES = 15;

export const PublishModalProps = {
  partModel: PropTypes.object,
  showModal: PropTypes.bool,
  onPublish: PropTypes.func,
  onSchedule: PropTypes.func,
  onUnschedule: PropTypes.func,
  onClose: PropTypes.func
};

const PublishModal = ({
  partModel,
  showModal,
  onPublish,
  onSchedule,
  onUnschedule,
  onClose
}) => {
  const modalRef = useRef();
  const { trans } = useTrans();
  const scheduledPublishDatetime = partModel?.get("scheduledPublishDatetime");

  const [publishType, setPublishType] = useState(
    scheduledPublishDatetime ? PUBLISH_TYPE.Scheduled : PUBLISH_TYPE.Now
  );
  const [publishConfirmed, setPublishConfirmed] = useState(
    scheduledPublishDatetime ? true : false
  );
  const [initialDate] = useState(scheduledPublishDatetime);
  const [publishDate, setPublishDate] = useState(scheduledPublishDatetime);
  const [isValidDate, setIsValidDate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const momentLocalized = moment().locale("en");

  const handleTypeSelect = useCallback(
    event => {
      const { value } = event.target;
      setPublishType(parseInt(value));
      setErrorMessage("");
    },
    [setPublishType]
  );

  useEffect(
    () => {
      const minDate = moment()
        .add(PUBLISH_INTERVAL_MINUTES, "minutes")
        .toISOString();
      setIsValidDate(publishDate && minDate <= publishDate);
    },
    [publishDate]
  );

  const handlePublish = event => {
    setIsSaving(true);
    publishPart(partModel)
      .then(() => {
        modalRef.current.hide();
        setIsSaving(false);
        onPublish && onPublish(event);
      })
      .catch(error => {
        setIsSaving(false);
        setErrorMessage(error);
      });
  };

  const handleSchedule = event => {
    setIsSaving(true);
    schedulePart(partModel, publishDate)
      .then(() => {
        modalRef.current.hide();
        setIsSaving(false);
        onSchedule && onSchedule(event);
      })
      .catch(error => {
        setIsSaving(false);
        setErrorMessage(error);
      });
  };

  const handleUnschedule = event => {
    setIsSaving(true);
    unschedulePart(partModel)
      .then(() => {
        modalRef.current.hide();
        setIsSaving(false);
        onUnschedule && onUnschedule(event);
      })
      .catch(error => {
        setIsSaving(false);
        setErrorMessage(error);
      });
  };

  const handleOnClose = () => {
    setIsValidDate(false);
    setErrorMessage("");
    setPublishDate(initialDate);
    setPublishConfirmed(!!initialDate);
    onClose && onClose();
  };

  return (
    <Modal
      id={MODAL_ID.PUBLISH_MODAL_ID}
      {...{ showModal, onClose: handleOnClose }}
      ref={modalRef}
    >
      <div className="publish-modal-content">
        <h2 className="modal-title">{trans("Publish your part")}</h2>

        <RadioButtons
          name="publish_type"
          options={[
            {
              label: trans("Publish now"),
              value: PUBLISH_TYPE.Now
            },
            {
              label: trans("Schedule to publish later"),
              value: PUBLISH_TYPE.Scheduled
            }
          ]}
          value={publishType}
          onChange={handleTypeSelect}
        />

        {publishType == PUBLISH_TYPE.Now && (
          <div className="publish-footer">
            <Button
              fullWidth={true}
              onClick={handlePublish}
              disabled={isSaving}
              label={trans("Publish")}
            />
            {!!errorMessage && (
              <p className="text-alert title-action">{errorMessage}</p>
            )}
          </div>
        )}
        {publishType == PUBLISH_TYPE.Scheduled && (
          <div className="publish-schedule">
            <label className="schedule-date-label">
              {trans("Publish Date & Time")}:
            </label>

            <DateTimeField
              className="schedule-date"
              value={scheduledPublishDatetime}
              timeIncrements={PUBLISH_INTERVAL_MINUTES}
              onChange={date => setPublishDate(date)}
              min={momentLocalized.startOf("day").format("YYYY-MM-DD")}
              max={momentLocalized
                .startOf("day")
                .add(1, "year")
                .format("YYYY-MM-DD")}
            />

            <div className="publish-footer">
              <label className="schedule-confirm">
                <Checkbox
                  defaultValue={publishConfirmed}
                  onChange={value => setPublishConfirmed(value)}
                />
                {trans(
                  "I understand my part will be automatically published to my readers at the scheduled date and time."
                )}
              </label>

              <Button
                fullWidth={true}
                onClick={handleSchedule}
                disabled={!publishConfirmed || !isValidDate}
                label={trans("Schedule")}
              />

              {scheduledPublishDatetime && (
                <Button
                  className="unschedule-button"
                  onClick={handleUnschedule}
                  variant={ButtonVariant.SECONDARY}
                  fullWidth={true}
                  disabled={!publishConfirmed || !isValidDate}
                  label={trans("Unschedule")}
                />
              )}
              {!!errorMessage && (
                <p className="text-alert title-action">{errorMessage}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

PublishModal.propTypes = PublishModalProps;

export default PublishModal;
