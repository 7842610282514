import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function ResizableTextarea({
  className,
  minRows,
  maxRows,
  onChange,
  value,
  textareaLineHeight,
  characterLimit,
  hideCharacterLimit,
  ...rest
}) {
  const [rows, setRows] = useState(minRows);
  const [isFocused, setIsFocused] = useState();
  const refContainer = useRef(null);

  const computeRows = useCallback(
    () => {
      // Save previous number of rows
      const previousRows = refContainer.current.rows;
      // Reset number of rows in textarea to get proper scrollHeight
      refContainer.current.rows = minRows;

      // Compute new number of rows using the scrollHeight
      var scrollHeight = refContainer.current.scrollHeight;
      const currentRows = scrollHeight
        ? Math.floor(scrollHeight / textareaLineHeight)
        : minRows;

      // When typing or other onChange events, we keep the same number of rows
      if (currentRows === previousRows) {
        refContainer.current.rows = currentRows;
      }

      // Limit the rows and start making textarea scrollable.
      if (currentRows >= maxRows) {
        refContainer.current.rows = maxRows;
        refContainer.current.scrollTop = refContainer.current.scrollHeight;
      }
      setRows(Math.min(currentRows, maxRows));
    },
    [minRows, maxRows, textareaLineHeight]
  );

  useEffect(
    () => {
      // Resize the textarea when the content is cleared / on submit.
      if (value === "" && rows !== minRows) {
        setRows(minRows);
      }
    },
    [value, rows, minRows]
  );

  useEffect(
    () => {
      computeRows();
    },
    [computeRows]
  );

  useEffect(() => {
    window.addEventListener("resize", computeRows);

    return () => {
      window.removeEventListener("resize", computeRows);
    };
  });

  const handleChange = event => {
    computeRows();
    onChange(event);
  };

  const countDisplay = characterLimit - value.length;
  const limitExceeded = countDisplay <= 0;
  return (
    <div
      className={classNames(className, "resizable-textarea", {
        error: limitExceeded,
        focus: isFocused
      })}
    >
      <textarea
        ref={refContainer}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        rows={rows}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      {!hideCharacterLimit && <div className="counter">{countDisplay}</div>}
    </div>
  );
}

ResizableTextarea.defaultProps = {
  minRows: 2,
  maxRows: 5,
  textareaLineHeight: 24,
  onChange: () => {},
  characterLimit: 2000
};

ResizableTextarea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  minRows: PropTypes.number.isRequired,
  maxRows: PropTypes.number.isRequired,
  textareaLineHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  characterLimit: PropTypes.number.isRequired,
  hideCharacterLimit: PropTypes.bool
};

export { ResizableTextarea };
