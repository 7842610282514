import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../../hooks";
import { FormValidation } from "../../FormValidation";
import { requiredField } from "../../helpers";
import ContestFormField from "../../ContestFormField";
import ErrorField from "../ErrorField";

const SeriesStandaloneField = ({
  form,
  disableForm,
  onSeriesIdentificationChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = "";

  const subtitle = (
    <div className="subtitle">
      {trans("Can the story be read as a standalone?")}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "isSeriesStandalone" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.isSeriesStandalone === "";

  let dropdownValue =
    form.isSeriesStandalone === ""
      ? ""
      : form.isSeriesStandalone
        ? "yes"
        : "no";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation validationRules={[requiredField(trans)]}>
        {({ ref, errorMessage, className }) => (
          <>
            <select
              id="series-standalone-select"
              ref={ref}
              value={dropdownValue}
              name="isSeriesStandalone"
              disabled={disableForm}
              className={classNames("form-element", className)}
              onChange={onSeriesIdentificationChange}
              aria-label={trans("Select an option")}
            >
              <option value="" disabled>
                {trans("Select an option")}
              </option>
              <option key={"yes"} value={"yes"}>
                {trans("Yes")}
              </option>
              <option key={"no"} value={"no"}>
                {trans("No")}
              </option>
            </select>
            <ErrorField
              errorMessage={errorMessage}
              fieldId={"series-standalone"}
            />
            {!errorMessage && (
              <ErrorField
                fieldId={"series-standalone"}
                errorCondition={errorCondition}
              />
            )}
          </>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

SeriesStandaloneField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onSeriesIdentificationChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default SeriesStandaloneField;
