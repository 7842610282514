import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CommentsList from "../../comments/CommentsList/CommentsList";
import {
  CommentsLocation,
  DEEPLINK_OPENED_EVENT,
  DEEPLINK_ORPHAN_EVENT,
  INIT_COMMENTS_STORY_PART,
  AdsInCommentContextPropType
} from "../../comments/CommentsConstants";
import { toCommentResource } from "../../comments/CommentsUtils";

const StoryPartComments = ({ id: partId, group, deeplinkData, adData }) => {
  const [showComments, setShowComments] = useState(false);
  const [initialComment, setInitialComment] = useState(undefined);

  const sentimentEventData = {
    page: "comment",
    storyid: parseInt(group?.id),
    partid: parseInt(partId),
    entity_type: "comment",
    sentiment_type: "like"
  };

  const showDeeplinkInStoryPart = () => {
    setInitialComment(toCommentResource(deeplinkData.commentId));
    setShowComments(true);
  };

  const initializeComments = () => {
    if (deeplinkData?.paragraphId) {
      // if we have paragraphId we should wait to identify
      // when to show storyPartComments
      window.app.on(DEEPLINK_ORPHAN_EVENT, showDeeplinkInStoryPart);
      window.app.on(DEEPLINK_OPENED_EVENT, () => setShowComments(true));
    } else if (deeplinkData?.commentId) {
      showDeeplinkInStoryPart();
    } else {
      setShowComments(true);
    }
  };

  useEffect(initializeComments, [deeplinkData]);

  return (
    <div id="story-part-comments">
      {showComments && (
        <CommentsList
          partId={partId}
          storyAuthor={group?.user?.username}
          location={CommentsLocation.STORY_PART}
          initialLimit={INIT_COMMENTS_STORY_PART}
          sentimentEventData={sentimentEventData}
          initialComment={initialComment}
          adData={adData?.deviceType === "mobile" ? adData : undefined}
        />
      )}
    </div>
  );
};

StoryPartComments.propTypes = {
  id: PropTypes.number.isRequired,
  group: PropTypes.object,
  deeplinkData: PropTypes.shape({
    commentId: PropTypes.string.isRequired,
    paragraphId: PropTypes.string
  }),
  adData: AdsInCommentContextPropType
};

export default StoryPartComments;
