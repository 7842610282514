import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";

import { removeStoryTag } from "./reducers";
import TagGrid from "../../tags/TagGrid";

function StoryTagsUI(props) {
  const onTagClicked = tagName => props.removeStoryTag(tagName);

  return <TagGrid tagGridTags={props.storyTags} onTagClicked={onTagClicked} />;
}

StoryTagsUI.propTypes = {
  storyTags: PropTypes.object,
  removeStoryTag: PropTypes.func.isRequired
};

const StoryTags = connect(
  state => ({ storyTags: state.storyTags }),
  { removeStoryTag }
)(StoryTagsUI);

export { StoryTags };
