// List of hybe story ids
const HYBE_STORY_IDS = [
  "294965960", // ENGLISH 7FATES: CHAKHO (BTS)
  "294966013", // ENGLISH THE STAR SEEKERS (TOMORROW X TOGETHER)
  "294966028", // ENGLISH DARK MOON: THE BLOOD ALTAR (ENHYPEN)
  "294966049", // ENGLISH PLACEHOLDER IF NEEDED
  "294966539", // SPANISH 7FATES: CHAKHO (BTS)
  "294966588", // SPANISH THE STAR SEEKERS (TOMORROW X TOGETHER)
  "294966611", // SPANISH DARK MOON: THE BLOOD ALTAR (ENHYPEN)
  "294966627" // SPANISH PLACEHOLDER IF NEEDED
];

const isHybeStory = storyId => {
  if (typeof storyId !== "string") {
    throw new TypeError("storyId must be a string");
  }
  return HYBE_STORY_IDS.includes(storyId);
};

export default isHybeStory;
