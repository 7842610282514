import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { toggleModal } from "../../../shared-components/modals/actions";

import { fetchPaidPartConfig } from "./actions";

import ConfigurePaidPartModal from "./ConfigurePaidPartModal";

import { Button, ButtonAccent, ButtonSize } from "@wattpad/web-ui-library";

import { BONUS_CHAPTER_TYPE } from "../../../constants";

import {
  DEFAULT_DATA_VALUES,
  PaidPartDataProps,
  MODAL_BUTTON_CONTENT
} from "./constants";

const ConfigurePaidPartModalButton = ({
  isLoading,
  story,
  partId,
  authorId,
  paidPartData,
  fetchPaidPartConfig
}) => {
  const dispatch = useDispatch();
  const COPY = MODAL_BUTTON_CONTENT();

  useEffect(
    () => {
      fetchPaidPartConfig(story.id, partId);
    },
    [story, partId, fetchPaidPartConfig]
  );

  const createConfigModal = () => (
    <ConfigurePaidPartModal {...{ story, partId, authorId, paidPartData }} />
  );

  const handleOpenModalClick = () => {
    if (isLoading) return;

    dispatch(
      toggleModal({
        hideClose: true,
        disableClose: true,
        disableScroll: true,
        useGlobalModalContainer: true,
        className: "configure-paid-part-modal-container",
        component: () => createConfigModal()
      })
    );
  };

  return (
    <div
      className={classNames(
        "configure-paid-part-modal-preview",
        !paidPartData.is_free_part && "is-paid-bonus-chapter"
      )}
    >
      <div className="configure-paid-part-modal-button">
        <Button
          onClick={handleOpenModalClick}
          accent={ButtonAccent.PREMIUM}
          size={ButtonSize.SMALL}
          isLoading={isLoading}
          label={
            <>
              {paidPartData.is_free_part && COPY.button.default}
              {!paidPartData.is_free_part && (
                <>
                  {COPY.button.text}{" "}
                  {paidPartData.bonus_type ==
                    BONUS_CHAPTER_TYPE.EXCLUSIVE_CHAPTER &&
                    COPY.display.type.exclusiveChapter}
                  {paidPartData.bonus_type ==
                    BONUS_CHAPTER_TYPE.WRITER_REVEAL &&
                    COPY.display.type.writerReveal}
                  {paidPartData.bonus_type == BONUS_CHAPTER_TYPE.STORY_BRANCH &&
                    COPY.display.type.storyBranch}
                </>
              )}
            </>
          }
        />
      </div>
    </div>
  );
};

ConfigurePaidPartModalButton.defaultProps = {
  paidPartData: DEFAULT_DATA_VALUES
};

ConfigurePaidPartModalButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  story: PropTypes.object.isRequired,
  partId: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  fetchPaidPartConfig: PropTypes.func.isRequired,
  paidPartData: PaidPartDataProps
};

const mapStateToProps = state => {
  return {
    paidPartData: state.paidPartConfig.data,
    isLoading: state.paidPartConfig.isLoading
  };
};

export default connect(
  mapStateToProps,
  { fetchPaidPartConfig }
)(ConfigurePaidPartModalButton);
