import React, { Component } from "react";
import PropTypes from "prop-types";
import { HOW_WATTPAD_WORKS_STEPS as steps } from "./constants";
import { injectTrans } from "../../helpers";

class HowWattpadWorks extends Component {
  render() {
    let { trans } = this.props;
    return (
      <div id="HowWattpadWorks">
        <div className="container content">
          <h2>{trans("How Wattpad Works")}</h2>
          <p>
            {trans(
              "Get your story discovered through the power of community and technology on Wattpad."
            )}
          </p>
          <div id="steps">
            {steps(trans).map(step => {
              return <Step {...step} key={step.number} />;
            })}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    mobile: PropTypes.bool,
    trans: PropTypes.func.isRequired
  };
}
export default injectTrans(HowWattpadWorks);

const Step = ({ number, title, description, stat }) => {
  return (
    <div className="stepContainer">
      <div className="step">
        <h2 className="number">{number}</h2>
        <div>
          <h3 className="title">{title}</h3>
          <p className="description">{description}</p>
        </div>
      </div>
      <div className="dots">
        {[...Array(6)].map((e, i) => {
          return <span className="dot" key={i} />;
        })}
      </div>
      <div className="statBubble">
        <div>
          <div
            className="count"
            dangerouslySetInnerHTML={{ __html: stat.count }}
          />
          <div className="title">{stat.title}</div>
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  stat: PropTypes.object
};
