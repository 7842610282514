import NukaCarousel from "nuka-carousel";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Icon } from "../../../shared-components";
import StorySlide from "./StorySlide";
import rtlLanguage from "../../../../helpers/handlebars/rtl-language";
import { simpleShorten } from "../../../helpers";
import { useTrans } from "../../../hooks";
import { pixelRatio } from "../../../helpers/pixelRatio";
import StoryIconBar from "../../../shared-components/StoryIconBar";

/**
 * Display story metadata module
 */
const StoryExpandedMetadata = ({
  heading,
  index,
  isCompleted,
  numParts,
  offset,
  page,
  sectionType,
  sources,
  subheading,
  storyId,
  fromStoryDetails,
  isMature = false
}) => {
  const { trans } = useTrans();

  const handleMoreInfoClick = () => {
    if (fromStoryDetails) {
      window.te.push("event", "story", null, null, "click", {
        storyid: storyId,
        page: "story_details",
        type: "similar_stories",
        source: "story_details"
      });
    } else {
      window.te.push("event", "story", null, null, "click", {
        storyid: storyId,
        page,
        algo_source: sources,
        position: index,
        offset,
        type: sectionType
      });
    }
    app.router.navigate(`/story/${storyId}`, { trigger: true });
  };

  return (
    <div className="story-metadata">
      <div className="meta-header">
        <div className="title-and-badge">
          <div className="story-title">
            <h3>{heading}</h3>
          </div>
        </div>
        <StoryIconBar
          numParts={numParts}
          isMature={isMature}
          isCompleted={isCompleted}
        />
      </div>
      <div className="story-description" aria-hidden="true">
        <h4>{simpleShorten(subheading, 200, false)}</h4>
      </div>
      <div className="more-details-wrapper">
        <button className="more-details" onClick={handleMoreInfoClick}>
          {trans("More details")}
        </button>
        <div className="arrow-icon">
          <Icon name="chevRight" size="18" />
        </div>
      </div>
    </div>
  );
};

StoryExpandedMetadata.propTypes = {
  heading: PropTypes.string.isRequired,
  index: PropTypes.number,
  isCompleted: PropTypes.bool,
  isMature: PropTypes.bool,
  numParts: PropTypes.number,
  offset: PropTypes.number,
  page: PropTypes.string,
  sectionType: PropTypes.string.isRequired,
  sources: PropTypes.array,
  storyId: PropTypes.number.isRequired,
  subheading: PropTypes.string,
  fromStoryDetails: PropTypes.bool
};

/**
 * A carousel containing story items.
 */
const StoryExpandedCarousel = ({
  index,
  onStoryClick,
  page,
  sectionType,
  stories,
  storiesToShow = 5,
  storyWidth = 88,
  wasSeen,
  windowWidth,
  callTrackingUrls,
  clickTrackingUrls,
  userLang,
  fromStoryDetails
}) => {
  let frameOverflowVisible = false;
  const isSmallScreen = windowWidth < 575;
  const isVerySmallScreen = windowWidth <= 360;

  if (windowWidth <= 980) {
    storiesToShow = 2.5;
    frameOverflowVisible = true;
    if (isSmallScreen) {
      storiesToShow = 3.5;
      if (pixelRatio(2.5)) {
        storyWidth = Math.floor(105 / 3);
      } else if (pixelRatio(1.5)) {
        storyWidth = Math.floor(105 / 2);
      }
    }
    if (isVerySmallScreen) {
      storiesToShow = 3;
    }
  }

  const isRTL = rtlLanguage(userLang);

  const [storiesSeen, setStoriesSeen] = useState([]);
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(
    () => {
      if (wasSeen) {
        const numStoriesShown = Math.min(
          Math.round(storiesToShow),
          stories.length
        );
        stories.slice(0, numStoriesShown).map((story, i) => {
          if (fromStoryDetails) {
            // Similar stories event
            window.te.push("event", "story", null, null, "view", {
              storyid: story.id,
              page: "story_details",
              type: "similar_stories",
              source: "story_details"
            });
          } else {
            pushStoryViewEvent(story.id, story.sources, index, i, sectionType);
          }
        });
        setStoriesSeen([
          ...storiesSeen,
          ...stories.slice(0, numStoriesShown).map((_, i) => i)
        ]);
      }
    },
    [wasSeen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const afterSlide = storyIndex => {
    setActiveStoryIndex(storyIndex);

    if (wasSeen) {
      const numStoriesShown = Math.min(
        storyIndex + Math.round(storiesToShow),
        stories.length
      );
      stories.slice(storyIndex, numStoriesShown).map((story, i) => {
        if (storiesSeen.includes(storyIndex + i)) return;

        if (fromStoryDetails) {
          // Similar stories event
          window.te.push("event", "story", null, null, "view", {
            storyid: story.id,
            page: "story_details",
            type: "similar_stories",
            source: "story_details"
          });
        } else {
          pushStoryViewEvent(
            story.id,
            story.sources,
            index,
            storyIndex + i,
            sectionType
          );
        }
      });
      setStoriesSeen([
        ...storiesSeen,
        ...stories
          .slice(storyIndex, numStoriesShown)
          .map((_, i) => storyIndex + i)
      ]);
    }
  };
  const pushStoryViewEvent = (
    storyid,
    algo_source = [],
    position,
    offset,
    type
  ) => {
    window.te.push("event", "story", null, null, "view", {
      storyid,
      page: page,
      algo_source,
      position,
      offset,
      type
    });
  };

  const handleShiftCarousel = slideIndex => {
    setSlideIndex(slideIndex);
  };

  const renderNextBtn = (currentSlide, nextSlide, slideCount) =>
    currentSlide !== slideCount - storiesToShow || isRTL ? (
      <button className="next-btn" onClick={nextSlide}>
        <Icon name="arrowCircleRight" size="40" alt="next" strokeWidth="2.25" />
      </button>
    ) : null;

  const renderPrevBtn = (currentSlide, previousSlide) =>
    currentSlide !== 0 || isRTL ? (
      <button className="prev-btn" onClick={previousSlide}>
        <Icon
          name="arrowCircleLeft"
          size="40"
          alt="previous"
          strokeWidth="2.25"
        />
      </button>
    ) : null;
  return stories !== null && stories.length !== 0 ? (
    <div className="story-expanded-carousel">
      {/* If page is rtl, enforce carousel to be LTR to prevent NukaCarousel from breaking */}
      <div
        {...(isRTL
          ? { dir: "ltr", className: "carousel-container rtl-adjust" }
          : { className: "carousel-container" })}
      >
        {/*
        dragging: mouse interaction with the carousel
        swiping: touch interaction with the carousel
        Both are enabled by default
        */}
        <NukaCarousel
          dragging={false}
          afterSlide={afterSlide}
          enableKeyboardControls
          slideIndex={slideIndex}
          frameOverflow={frameOverflowVisible ? "visible" : "hidden"}
          scrollMode="page"
          slidesToScroll={isSmallScreen ? 1 : storiesToShow}
          heightMode="max"
          cellAlign="left"
          wrapAround={isSmallScreen ? true : isRTL}
          slidesToShow={storiesToShow}
          initialHeight="1"
          renderCenterRightControls={
            storiesToShow === 5 && stories.length > 5
              ? ({ currentSlide, nextSlide, slideCount }) =>
                  renderNextBtn(currentSlide, nextSlide, slideCount)
              : null
          }
          renderCenterLeftControls={
            storiesToShow === 5 && stories.length > 5
              ? ({ currentSlide, previousSlide }) =>
                  renderPrevBtn(currentSlide, previousSlide)
              : null
          }
          renderBottomCenterControls={null}
        >
          {stories.map((storyData, i) => {
            if (storyData.id && storyData.cover) {
              return i === activeStoryIndex ? (
                <div key={`${storyData.id}-${i}`} className="zoom-story-item">
                  <StorySlide
                    {...storyData}
                    sectionType={sectionType}
                    index={index}
                    offset={i}
                    width={storyWidth}
                    shiftCarousel={() => handleShiftCarousel(i)}
                    page={page}
                    onStoryClick={onStoryClick}
                    storyData={storyData}
                    clickTrackingUrls={clickTrackingUrls}
                    callTrackingUrls={callTrackingUrls}
                    isActiveStory
                  />
                </div>
              ) : (
                <StorySlide
                  {...storyData}
                  sectionType={sectionType}
                  index={index}
                  offset={i}
                  width={storyWidth}
                  key={`${storyData.id}-${i}`}
                  page={page}
                  shiftCarousel={() => handleShiftCarousel(i)}
                  onStoryClick={onStoryClick}
                  storyData={storyData}
                  clickTrackingUrls={clickTrackingUrls}
                  callTrackingUrls={callTrackingUrls}
                />
              );
            }
          })}
        </NukaCarousel>
        {stories.map((storyData, i) => {
          if (storyData.id && storyData.cover && i === activeStoryIndex) {
            return (
              <StoryExpandedMetadata
                heading={storyData.title}
                subheading={storyData.description}
                key={storyData.id}
                sectionType={sectionType}
                page={page}
                index={index}
                offset={i}
                storyId={storyData.id}
                isPaid={storyData.isPaid || storyData.isPaywalled} // isPaid on home and isPaywalled elsewhere are equivalent.
                numParts={storyData.numParts}
                isCompleted={storyData.completed}
                isMature={storyData.mature}
                sources={storyData.sources}
                fromStoryDetails={fromStoryDetails}
              />
            );
          }
        })}
      </div>
    </div>
  ) : null;
};

StoryExpandedCarousel.defaultProps = {
  onStoryClick: null
};

StoryExpandedCarousel.propTypes = {
  index: PropTypes.number,
  onStoryClick: PropTypes.func,
  page: PropTypes.string,
  sectionType: PropTypes.string.isRequired,
  stories: PropTypes.array.isRequired,
  storiesToShow: PropTypes.number,
  storyWidth: PropTypes.number,
  wasSeen: PropTypes.bool,
  windowWidth: PropTypes.number,
  callTrackingUrls: PropTypes.func,
  clickTrackingUrls: PropTypes.array,
  userLang: PropTypes.number,
  fromStoryDetails: PropTypes.bool
};

export default StoryExpandedCarousel;
