import React from "react";
import PropTypes from "prop-types";

import RecentSearches from "../../shared-components/RecentSearches";
import BrowseCategories from "../../shared-components/BrowseCategories";

const BlankSearch = ({ recentSearches, browseTopics, searchTerm }) => {
  return (
    !searchTerm && (
      <div className="blank-search">
        <div className="recent-searches">
          <RecentSearches suggestedSearchTerms={recentSearches} />
        </div>
        <div id="browse-topics">
          <BrowseCategories browseTopics={browseTopics} />
        </div>
      </div>
    )
  );
};

BlankSearch.propTypes = {
  recentSearches: PropTypes.array,
  browseTopics: PropTypes.array,
  searchTerm: PropTypes.string
};

export default BlankSearch;
