import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks/";

import {
  Alert,
  Button,
  DSContainer,
  Header
} from "../../design-system/elements";
import { OneColPage } from "../../design-system/layouts";
import { Icon, Loader } from "../../shared-components/";

const optOutCookieName = "ccpa-oo";
const privacyCookieName = "usprivacy";
const optOutCookieExpiryDays = 10 * 365;
const appFlowIndicator = "mode=app";
const appOptOutIndicator = "optOut";
const appCloseIndicator = "closeWebForm=true";

// returns true is this page is being shown to a logged in user
const userIsLoggedIn = () => {
  return wattpad.utils.currentUser().authenticated();
};

const getOptOutURL = username => {
  return `/v5/users/${username}/privacy-settings/ccpa/`;
};

// returns the json response from the privacy-service
const getLoggedInOptOutValue = () => {
  const username = wattpad.utils.getCurrentUserAttr("username");
  return Promise.resolve(
    $.get(getOptOutURL(username)).then(value => value.optout)
  );
};

// load the users current opt out state, returns a hash where the key
// optout has the value we care about
const getOptOutValue = () => {
  if (userIsLoggedIn()) {
    return getLoggedInOptOutValue();
  }
  // for logged out users we refer to the ccpa-oo cookie
  return Promise.resolve(!!window.wattpad.utils.getCookie(optOutCookieName));
};

// returns the json response from the privacy-service
const setLoggedInOptOutValue = value => {
  const username = wattpad.utils.getCurrentUserAttr("username");

  return Promise.resolve(
    $.ajax({
      url: getOptOutURL(username),
      type: "PUT",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ optout: value })
    })
  );
};

// store the users new opt out state, either remotely or locally
const setOptOutValue = value => {
  if (userIsLoggedIn()) {
    return setLoggedInOptOutValue(value);
  }
  return Promise.resolve(
    window.wattpad.utils.setCookie(
      optOutCookieName,
      value,
      optOutCookieExpiryDays
    )
  );
};

// a query string argument will indicate if the page should use the
// app flow
const isApp = () => {
  return window.location.search.indexOf(appFlowIndicator) !== -1;
};

// go back to where you came from
const handleGoBack = () => {
  let dest = "/home";
  if (isApp()) {
    dest = `${
      window.location.pathname
    }?${appFlowIndicator}&${appCloseIndicator}`;
  }
  // go to the backend on this to cause the usprivacy cookie to be generated
  wattpad.utils.redirectToServer(dest);
  return;
};

function PrivacySettings(props) {
  const { trans } = useTrans();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [optedOut, setOptedOut] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(
    props.startWithError ? "startWithError enabled" : null
  );

  React.useEffect(() => {
    getOptOutValue()
      .then(value => {
        setOptedOut(value);
        setLoaded(true);
      })
      .catch(() => {
        // if loading opt out fails, show them the form like they aren't opted out.
        setOptedOut(false);
        setLoaded(true);
      });
  }, []);

  // behaviour when the users toggles the 'in california' checkbox
  const handleRegionConfirmation = event => {
    setButtonDisabled(!event.target.checked);
  };

  // behaviour when the user clicks the 'opt me out' button
  const handleConfirmChange = (event, value) => {
    event.preventDefault();
    if (!buttonDisabled) {
      setOptOutValue(value)
        .then(() => {
          setOptedOut(value);
          setError(null);
          // expiry any existing privacy cookie
          window.wattpad.utils.setCookie(privacyCookieName, "", -1, true);
        })
        .then(() => {
          if (isApp()) {
            app.router.navigate(
              `${
                window.location.pathname
              }?${appFlowIndicator}&${appOptOutIndicator}=${value}`,
              { trigger: true }
            );
          }
        })
        .catch(error => {
          setError(error);
        });
    }
  };

  // render starts here

  // show a spinner until we have a state
  if (!loaded) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  return (
    <OneColPage variant="privacy-settings text-center max-width-and-wp-bg">
      <Header
        variant="large mb"
        text={trans("Do not sell my personal information")}
      />
      {optedOut ? (
        <React.Fragment>
          <div className="icon-holder">
            <Icon iconName="fa-check" color="wp-neutral-5" size={20} />
          </div>
          <p className="fs-14 mb-4 neutral-100" id="opted-out-text">
            {trans(
              "You have successfully opted out. Wattpad will not sell your personal information to third parties."
            )}
          </p>
          {!userIsLoggedIn() && (
            <p
              className="fs-14 mb-3 b neutral-100"
              dangerouslySetInnerHTML={{
                __html: trans('To save this preference permanently, you must <a class="base-2-60" href="%s">log in</a> or <a class="base-2-60" href="%s">create an account</a>.', "/login", "/signup") // prettier-ignore
              }}
            />
          )}
          <Button
            variant="base-2-60 full-width"
            text={trans("Back to Wattpad")}
            onClick={handleGoBack}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DSContainer variant="base-2">
            <p className={"fs-14"}>
              {trans(
                "If you choose to continue sharing your information, Wattpad will be able to provide you with a better reading experience by serving you ads that are more tailored to your interests."
              )}
            </p>
            <Button
              variant="base-2-60 full-width"
              id="continue-sharing-button"
              text={trans("Continue sharing information")}
              onClick={handleGoBack}
            />
          </DSContainer>
          <DSContainer>
            <p className={"fs-14 mb-3"}>
              {trans(
                "If you are a California resident, you have the right to opt out of the sharing of your personal information with third parties (subject to certain exceptions) under the California Consumer Privacy Act (“CCPA”). This is known as the right to opt out of the sale of your personal information."
              )}
            </p>
            <div className="checkbox-holder">
              <input
                type="checkbox"
                name="californian"
                id="californian"
                value="yes"
                onChange={handleRegionConfirmation}
              />
              <label htmlFor="californian" className={"fs-12 b"}>
                {trans("I am a resident of California")}
              </label>
            </div>
            <Button
              variant={"full-width mb-1 neutral-100"}
              disabled={buttonDisabled}
              id="optout-button"
              onClick={e => {
                handleConfirmChange(e, true);
              }}
              text={trans("Do not sell my personal information")}
            />
            {error && (
              <Alert
                variant={"fs-12 text-left mb-1"}
                messageNode={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: trans('There was an error submitting your opt-out request. Please try again or <a class="base-2-60" href="%s">report the issue</a>.', "https://support.wattpad.com/hc/en-us/requests/new") // prettier-ignore
                    }}
                  />
                }
              />
            )}
            <p className={"fs-12"}>
              <em
                dangerouslySetInnerHTML={{
                  __html: trans('You can also opt out of the sale of your personal information by sending us an email at <a class="base-2-60" href="%s">%s</a>.', "mailto:privacy@wattpad.com", "privacy@wattpad.com") // prettier-ignore
                }}
              />
            </p>
          </DSContainer>
        </React.Fragment>
      )}
    </OneColPage>
  );
}

PrivacySettings.propTypes = {
  startWithError: PropTypes.bool
};

PrivacySettings.defaultProps = {
  startWithError: false
};

export { PrivacySettings };
