import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import Toggle from "./MatureToggle.js";
import { toggleModal } from "../../../shared-components/modals/actions.js";
import {
  saveContentSettings,
  getContentSettings,
  openConfirmation
} from "../actions.js";
import TagItem from "../../tags/TagItem.js";
import { Icon } from "../../../shared-components";
import EditBlockedTagsModal from "./EditBlockedTagsModal.js";
import { Loader } from "../../../shared-components/Loader";
import { injectTrans } from "../../../helpers";

class ContentSettings extends React.Component {
  static propTypes = {
    homeLoading: PropTypes.bool,
    contentSettings: PropTypes.object,
    contentSettingsLoading: PropTypes.bool,
    getContentSettings: PropTypes.func,
    openConfirmation: PropTypes.func,
    saveContentSettings: PropTypes.func,
    toggleModal: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
    isSettingsPage: PropTypes.bool
  };

  state = {
    showConfirmation: true,
    matureToggle: null,
    overflowCount: 0,
    showMature: null,
    showBlockedTags: null,
    editBlockedTagsView: false,
    tags: null,
    newTagName: "",
    unblockableTags: null,
    tagLimit: null
  };

  tagRef = React.createRef();

  componentDidMount() {
    // Fetch content settings data
    this.props.getContentSettings();
    document.addEventListener("keydown", this.handleEsc, false);
    // // Calculate number of Blocked Tags not shown
    if (this.state.showBlockedTags) {
      this.calculateOverflow();
    }
  }

  componentWillUnmount() {
    if (
      this.state.showConfirmation &&
      wattpad.utils.getCookie("cs_c") !== "0"
    ) {
      this.props.openConfirmation();
      wattpad.utils.setCookie("cs_c", 0);
    }
    document.removeEventListener("keydown", this.handleEsc, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.showLoader &&
      prevProps.contentSettingsLoading & !this.props.contentSettingsLoading
    ) {
      this.setState({ showLoader: false });
    }
    // Calculate number of Blocked Tags not shown
    if (this.state.showBlockedTags && this.state.tags !== prevState.tags) {
      this.calculateOverflow();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Do nothing when no content settings data from server
    if (!nextProps.contentSettings) return null;
    // Only handle the inital data received after first rendering
    const showMature = nextProps.contentSettings.includeMature !== undefined;
    const newValueFromServer = nextProps.contentSettings.includeMature
      ? "on"
      : "off";
    const blockedTagsFromServer = nextProps.contentSettings.blockedTags;
    const blockedTagItems = blockedTagsFromServer
      ? blockedTagsFromServer.map(t => {
          return { id: t, name: t, active: true };
        })
      : [];
    const showBlockedTags = nextProps.contentSettings.tagLimit !== undefined;
    const tagLimit = nextProps.contentSettings.tagLimit;
    const unblockableTags = nextProps.contentSettings.unblockableTags;
    let derivedState = {};
    if (prevState.showMature === null) {
      derivedState.showMature = showMature;
    }
    if (prevState.showBlockedTags === null) {
      derivedState.showBlockedTags = showBlockedTags;
    }
    if (prevState.matureToggle === null) {
      derivedState.matureToggle = newValueFromServer;
    }
    if (prevState.tags === null) {
      derivedState.tags = blockedTagItems;
    }
    if (prevState.tagLimit === null) {
      derivedState.tagLimit = tagLimit;
    }
    if (prevState.unblockableTags === null) {
      derivedState.unblockableTags = unblockableTags;
    }
    if (Object.keys(derivedState).length === 0) {
      return null;
    } else return derivedState;
  }

  handleEsc = event => {
    if (event.keyCode === 27) {
      if (this.state.editBlockedTagsView) {
        this.toggleView();
      } else {
        this.setState({ showConfirmation: false });
        this.props.toggleModal();
      }
    }
  };

  calculateOverflow = () => {
    const blockedTags = this.tagRef.current.childNodes;
    const blockedTagsCount = blockedTags.length;
    let overflowCount = blockedTagsCount;
    for (let i = 0; i < blockedTagsCount; i++) {
      if (
        blockedTags[i].getBoundingClientRect().top >
        blockedTags[0].getBoundingClientRect().top
      ) {
        break;
      } else {
        overflowCount--;
      }
    }
    this.setState({
      overflowCount
    });
  };

  onToggle = () => {
    const nextToggleState = this.state.matureToggle == "on" ? "off" : "on";
    this.setState({
      ...this.state,
      matureToggle: nextToggleState
    });
  };

  toggleView = () => {
    this.setState({
      ...this.state,
      editBlockedTagsView: !this.state.editBlockedTagsView
    });
  };

  getNewBlockedTags = tags => {
    this.setState(
      {
        ...this.state,
        editBlockedTagsView: !this.state.editBlockedTagsView,
        tags
      },
      () => this.calculateOverflow()
    );
  };

  handleChanges = event => {
    let newTagName = event.target.value === " " ? "" : event.target.value;
    this.setState({
      ...this.state,
      newTagName
    });
  };

  handleApplyChanges = () => {
    let updates = {};
    // Track changes in includeMature and blockedTags
    const matureServerState =
      this.props.contentSettings && this.props.contentSettings.includeMature;
    const blockedTagsServerState = this.props.contentSettings
      ? this.props.contentSettings.blockedTags
      : [];
    const matureClientState = this.state.matureToggle == "on" ? true : false;
    const blockedTagsClientState = this.state.tags.map(tag => {
      return tag["name"];
    });
    if (this.state.showMature && matureServerState !== matureClientState) {
      updates.includeMature = matureClientState;
    }
    if (
      this.state.showBlockedTags &&
      !_.isEqual(blockedTagsServerState, blockedTagsClientState)
    ) {
      updates.blockedTags = blockedTagsClientState;
    }

    this.props.saveContentSettings(updates);
  };

  onCancel = () => {
    this.setState({ showConfirmation: false }, () => {
      this.props.toggleModal();
    });
  };

  render() {
    const { trans, isSettingsPage } = this.props;
    const tags = this.state.tags ? this.state.tags : [];
    return (
      <>
        <div
          className={classnames("content-settings", {
            hidden: this.state.editBlockedTagsView,
            active: !this.state.editBlockedTagsView,
            "is-settings-page": isSettingsPage
          })}
        >
          <div className="content-settings-heading">
            <h2>{trans("Content Preferences")}</h2>
            <hr />
          </div>
          {this.state.showMature && (
            <>
              <div className="section mature">
                <div className="instruction">
                  <h3>{trans("Mature Content")}</h3>
                  <p>
                    {trans(
                      "Mature stories may contain explicit content and adult themes. When this is enabled, mature stories may be shown on Home and Search."
                    )}
                  </p>
                </div>

                <Toggle
                  toggleState={this.state.matureToggle}
                  onToggle={this.onToggle}
                  disabled={
                    this.props.contentSettingsLoading || this.props.homeLoading
                  }
                />
              </div>
              <hr />
            </>
          )}

          {this.state.showBlockedTags && (
            <>
              <div className="section blocked-tags">
                <div className="left-section">
                  <div className="instruction">
                    <h3>{trans("Blocked Tags")}</h3>
                    <p>
                      {trans(
                        "We will remove all stories that have the following tags from your Home recommendations."
                      )}
                    </p>
                  </div>
                  <div className="blocked-tags-list">
                    <div className="tags" ref={this.tagRef}>
                      {tags
                        .slice(0)
                        .reverse()
                        .map(tag => (
                          <TagItem key={tag.id} disableClick={true} {...tag} />
                        ))}
                    </div>
                    {this.state.overflowCount > 0 && (
                      <div className="more">
                        + {this.state.overflowCount} more
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-section">
                  <button
                    className="edit-tags-button right-icon"
                    onClick={this.toggleView}
                  >
                    <p>{trans("Edit Tags")}</p>
                    <Icon name="arrowRight" size="22" />
                  </button>
                </div>
              </div>
              <hr />
            </>
          )}
          <div className="footer">
            {!isSettingsPage && (
              <button className="cancel" onClick={this.onCancel}>
                {trans("Cancel")}
              </button>
            )}
            {this.props.contentSettingsLoading || this.props.homeLoading ? (
              <Loader />
            ) : (
              <button
                className="btn-primary"
                disabled={this.props.contentSettingsLoading ? true : false}
                onClick={this.handleApplyChanges}
              >
                {trans("Apply Changes")}
              </button>
            )}
          </div>
        </div>
        <EditBlockedTagsModal
          removeOnTagClick={false}
          tagLimit={this.state.tagLimit}
          onReturn={this.getNewBlockedTags}
          tags={tags}
          toggleView={this.toggleView}
          visibleState={this.state.editBlockedTagsView}
          unblockableTags={this.state.unblockableTags}
          isSettingsPage
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  toggleModal,
  saveContentSettings,
  getContentSettings,
  openConfirmation
};

const mapStateToProps = state => {
  const {
    homeLoading,
    error,
    contentSettings,
    contentSettingsLoading
  } = state.homeSections;
  return { homeLoading, error, contentSettings, contentSettingsLoading };
};
export default injectTrans(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContentSettings)
);
