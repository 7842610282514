import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { AdsInCommentContextPropType } from "../../comments/CommentsConstants";
import ParagraphCommentsDrawer from "../ParagraphCommentsDrawer/ParagraphCommentsDrawer";
import Icon from "../../../shared-components/Icon";
import { count } from "../../../helpers";
import { useTrapKeyboardNavigation } from "../../../hooks";

const ParagraphCommentsCount = ({
  partId,
  storyId,
  partTitle,
  paragraphId,
  paragraphBody,
  storyAuthor,
  adData,
  initialCommentId,
  commentCount: initialCount
}) => {
  const [drawerRef, setDrawerRef] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [commentCount, setCommentCount] = useState(initialCount);
  const [initialComment, setInitialComment] = useState(initialCommentId);

  const markerButtonRef = useRef();
  const handleDrawerRef = useCallback(node => setDrawerRef(node), []);

  const pushCommentViewEvent = action => {
    window.te.push("event", "comments", null, null, action, {
      page: "comment",
      storyid: parseInt(storyId),
      partid: parseInt(partId),
      paragraph_id: paragraphId
    });
  };

  const handleMarkerClick = () => {
    setShowDrawer(true);
    pushCommentViewEvent("view");
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setInitialComment(undefined);
    markerButtonRef.current?.focus();
    pushCommentViewEvent("close");
  };

  const handleUpdateCount = difference => {
    setCommentCount(count => count + difference);
  };

  const initializeMarker = () => {
    if (initialCommentId) {
      handleMarkerClick();
    }
  };

  useEffect(initializeMarker, [initialCommentId]);
  const { updateTabStops } = useTrapKeyboardNavigation(
    drawerRef,
    markerButtonRef.current,
    handleCloseDrawer
  );

  return (
    <>
      <button
        ref={markerButtonRef}
        onClick={handleMarkerClick}
        className={classNames("btn-no-background comment-marker", {
          "hide-marker": !commentCount
        })}
      >
        <span className="num-comment">
          {commentCount ? count(commentCount) : "+"}
        </span>
        <Icon iconName="fa-comment-count" height="28" color="wp-neutral-2" />
      </button>
      {showDrawer && (
        <ParagraphCommentsDrawer
          partId={partId}
          storyId={storyId}
          partTitle={partTitle}
          paragraphId={paragraphId}
          paragraphBody={paragraphBody}
          storyAuthor={storyAuthor}
          initialCommentId={initialComment}
          onDrawerRef={handleDrawerRef}
          onCloseDrawer={handleCloseDrawer}
          onUpdateCount={handleUpdateCount}
          onCommentsChanged={updateTabStops}
          adData={adData}
        />
      )}
    </>
  );
};

ParagraphCommentsCount.propTypes = {
  partId: PropTypes.number.isRequired,
  storyId: PropTypes.string.isRequired,
  partTitle: PropTypes.string.isRequired,
  paragraphId: PropTypes.string.isRequired,
  paragraphBody: PropTypes.string.isRequired,
  storyAuthor: PropTypes.string.isRequired,
  commentCount: PropTypes.number.isRequired,
  adData: AdsInCommentContextPropType,
  initialCommentId: PropTypes.string
};

export default ParagraphCommentsCount;
