import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function Skeleton({
  height,
  width,
  color,
  animation,
  circle = false,
  count = 1,
  styles,
  className
}) {
  const style = {
    backgroundColor: color,
    borderRadius: circle ? "50%" : 0,
    height: height,
    width: width,
    ...styles
  };

  let skeleton = [];
  for (let i = 0; i < count; i++) {
    skeleton.push(
      <div
        className={classNames("skeleton", className, { pulse: animation })}
        style={style}
        key={i}
      />
    );
  }

  return skeleton;
}

Skeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  animation: PropTypes.bool,
  circle: PropTypes.bool,
  count: PropTypes.number,
  styles: PropTypes.object,
  className: PropTypes.string
};
