"use strict";

module.exports = function(languageId) {
  switch (languageId) {
    case 16: // Arabic
    case 17: // Hebrew
    case 31: // Persian
    case 48: // Urdu
      return true;
    default:
      return false;
  }
};
