import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon, UserSafetyOperations } from "../../shared-components";
import { injectTrans } from "../../helpers";
import classNames from "classnames";

const MutedUsersList = ({ mutedUsers }) => {
  const firstChildRef = useRef(null);

  useEffect(
    () => {
      if (firstChildRef.current) {
        firstChildRef.current.focus();
      }
    },
    [mutedUsers]
  );

  return mutedUsers.map((mutedUser, index) => {
    const { username, avatar } = mutedUser;
    let firstUserRef;
    if (index == 0) {
      firstUserRef = firstChildRef;
    }
    return (
      <li key={username} className="muted-user">
        <img src={avatar} alt={username} align="top" width="64" height="64" />
        <div className="username-wrapper">
          <a
            href={`/user/${username}`}
            className="user-profile on-navigate"
            title={username}
            ref={firstUserRef}
          >
            {username}
          </a>
        </div>
        <div className="button-group inline-block relative ">
          <button
            className="btn btn-white dropdown-toggle more-options unmute-button"
            data-toggle="dropdown"
            aria-controls="more-options"
            aria-expanded="false"
            aria-label="more options"
          >
            <Icon name="more" size="24" />
          </button>
          <div className="triangle" />
          <div className="dropdown-menu align-right">
            <UserSafetyOperations
              username={username}
              showModerationOptions={true}
              isLoading={false}
              isMutedByCurrentUser={true}
              onReportUser={() => {}} // noop just to render the button since the ReportManager mixin handles this for now
            />
          </div>
        </div>
      </li>
    );
  });
};

const totalPages = (usersLength, pageLimit) =>
  Math.ceil(usersLength / pageLimit);

class MuteListUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1
    };

    this.pageLimit = 8;
  }

  moveBackward = e => {
    if (e.key !== "Tab" && !e.shiftKey) {
      const pageNum = this.state.currentPage;
      if (pageNum > 1) {
        this.setState({
          currentPage: pageNum - 1
        });
      }
    }
  };

  moveForward = e => {
    if (e.key !== "Tab" && !e.shiftKey) {
      const pageNum = this.state.currentPage;
      if (pageNum < this.totalPages) {
        this.setState({
          currentPage: pageNum + 1
        });
      }
    }
  };

  render() {
    const { mutedUsers, trans } = this.props;

    const { currentPage } = this.state;
    this.totalPages = totalPages(this.props.mutedUsers.length, this.pageLimit);

    const navigateBackClasses = classNames("", {
      "wp-base-1": currentPage !== 1,
      "wp-neutral-2": currentPage === 1
    });

    const navigateForwardClasses = classNames("", {
      "wp-base-1":
        mutedUsers.length > this.pageLimit && currentPage !== this.totalPages,
      "wp-neutral-2": currentPage >= this.totalPages
    });

    const offset = (currentPage - 1) * this.pageLimit;
    const currentUsers = mutedUsers.slice(offset, offset + this.pageLimit);

    return (
      <div id="mute-list">
        <h5 className="subheading">
          {trans("These are the users you have muted")}
        </h5>
        <div id="muted-users">
          <ul className="user-list">
            <MutedUsersList mutedUsers={currentUsers} />
          </ul>
          <div className="controls">
            <div
              onClick={this.moveBackward}
              onKeyDown={this.moveBackward}
              role="button"
              tabIndex="0"
            >
              <Icon
                iconName="fa-left"
                height="18"
                color={navigateBackClasses}
              />
            </div>
            <span className="current-page">{this.state.currentPage}</span>
            <div
              onClick={this.moveForward}
              onKeyDown={this.moveForward}
              role="button"
              tabIndex="0"
            >
              <Icon
                iconName="fa-right"
                height="18"
                color={navigateForwardClasses}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MuteListUI.propTypes = {
  trans: PropTypes.func.isRequired,
  mutedUsers: PropTypes.array.isRequired
};

const MuteList = connect(state => ({
  mutedUsers: state.profileData.mutedUsers
}))(MuteListUI);

export default injectTrans(MuteList);
