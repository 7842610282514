import _ from "lodash";
import { COVER_SIZES } from "../constants";

/**
 * Returns an adjusted cover url based on the width passed in
 *
 * @param {String} url - cover url
 * @param {Number} width - desired width
 *
 * @returns {String} - adjusted cover url
 */

const getCoverSrc = (url, width) => {
  if (!url || isNaN(width)) return "";
  // regular expression based on story cover url
  const re = /(^.*\/c?cover\/[0-9]+-)([0-9]{1,3})(-*[\w]+\.)(png|jpg|jpeg|gif)$/;

  let newWidth = _.find(COVER_SIZES, function(size) {
    return size >= width;
  });
  return url.replace(re, "$1" + newWidth + "$3$4");
};

/**
 * Returns a string of all cover size urls and their corresponding size for use with srcset
 *
 * @param {String} url - cover url
 *
 * @returns {String} - string of all cover size urls and their corresponding size
 */

const getCoverSrcSet = cover => {
  if (!cover) return "";
  let srcSet = "";
  COVER_SIZES.forEach(size => {
    srcSet += `${getCoverSrc(cover, size)} ${size}w,`;
  });
  return srcSet.slice(0, -1); //removing trailing comma
};

export { getCoverSrc, getCoverSrcSet };
