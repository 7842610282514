import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import Icon from "../../../shared-components/Icon";
import CommentsList from "../../comments/CommentsList/CommentsList";
import { useTrans } from "../../../hooks";
import classNames from "classnames";
import {
  CommentsLocation,
  AdsInCommentContextPropType
} from "../../comments/CommentsConstants";
import { toCommentResource } from "../../comments/CommentsUtils";

const STORY_READING_ID = "story-reading";
const DRAWER_OPEN_CLASS = "paragraph-comments-drawer-open";

const ParagraphCommentsDrawer = ({
  adData,
  partId,
  storyId,
  partTitle,
  paragraphId,
  paragraphBody,
  storyAuthor,
  initialCommentId,
  onDrawerRef,
  onCloseDrawer,
  onUpdateCount,
  onCommentsChanged
}) => {
  const { trans } = useTrans();
  const scrollableRef = useRef();
  const [isFullyOpen, setIsFullyOpen] = useState(false);

  const sentimentEventData = {
    page: "comment",
    storyid: parseInt(storyId),
    partid: parseInt(partId),
    paragraph_id: paragraphId,
    entity_type: "comment",
    sentiment_type: "like"
  };

  const initializeDrawer = () => {
    setIsFullyOpen(true);
    document.body.classList.add(DRAWER_OPEN_CLASS);
    return () => document.body.classList.remove(DRAWER_OPEN_CLASS);
  };

  useEffect(initializeDrawer, []);

  const handleCloseDrawer = () => {
    setIsFullyOpen(false);
    setTimeout(onCloseDrawer, 300);
  };

  const renderDrawer = () => (
    <div className="paragraph-comments-drawer" ref={onDrawerRef}>
      <div
        role="presentation"
        className={classNames("modal-backdrop fade", {
          in: isFullyOpen
        })}
        onClick={handleCloseDrawer}
      />
      <div
        ref={scrollableRef}
        className={classNames("drawer-content", {
          open: isFullyOpen
        })}
      >
        <header className="drawer-header">
          <h1 className="drawer-title">{partTitle}</h1>
          <button
            className="close-btn"
            onClick={handleCloseDrawer}
            aria-label={trans("Close")}
          >
            <Icon iconName="fa-remove" height="14" color="wp-neutral-1" />
          </button>
        </header>
        <div className="drawer-body">
          <div className="paragraph-content">
            <pre dangerouslySetInnerHTML={{ __html: paragraphBody }} />
          </div>
          <CommentsList
            adData={adData}
            partId={partId}
            paragraphId={paragraphId}
            storyAuthor={storyAuthor}
            scrollableRef={scrollableRef}
            initialComment={
              initialCommentId && toCommentResource(initialCommentId)
            }
            location={CommentsLocation.PARAGRAPH}
            sentimentEventData={sentimentEventData}
            onUpdateCount={onUpdateCount}
            onCommentsChanged={onCommentsChanged}
          />
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    renderDrawer(),
    document.getElementById(STORY_READING_ID)
  );
};

ParagraphCommentsDrawer.propTypes = {
  adData: AdsInCommentContextPropType,
  partId: PropTypes.number.isRequired,
  storyId: PropTypes.string.isRequired,
  partTitle: PropTypes.string.isRequired,
  paragraphId: PropTypes.string.isRequired,
  paragraphBody: PropTypes.string.isRequired,
  storyAuthor: PropTypes.string.isRequired,
  initialCommentId: PropTypes.string,
  onDrawerRef: PropTypes.func,
  onCloseDrawer: PropTypes.func.isRequired,
  onUpdateCount: PropTypes.func.isRequired,
  onCommentsChanged: PropTypes.func
};

export default ParagraphCommentsDrawer;
