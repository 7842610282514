import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import { requiredField } from "../helpers";
import { MAP_MATURITY_RATINGS } from "../constants";
import ContestFormField from "../ContestFormField";
import ErrorField from "./ErrorField";

const StoryMaturityField = ({
  form,
  disableForm,
  onFormChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Story Maturity");

  const subtitle = (
    <div className="subtitle">
      {/* prettier-ignore */ trans('Select one of the story maturity ratings. Explicit refers to content that describes sexual activity in detail. "Inexplicit" refers to content that does not describe sexual activity in detail. Stories with any level of sexual content are eligible to win, as long as they abide by our content guidelines.')}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "storyMaturity" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.storyMaturity === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation validationRules={[requiredField(trans)]}>
        {({ ref, errorMessage, className }) => (
          <React.Fragment>
            <select
              ref={ref}
              id="story-maturity-select"
              value={form.storyMaturity}
              name="storyMaturity"
              disabled={disableForm}
              className={classNames("form-element story-maturity", className)}
              onChange={onFormChange}
              aria-label={trans("Select a maturity level")}
            >
              <option value="" disabled>
                {trans("Select a maturity level")}
              </option>
              {MAP_MATURITY_RATINGS(trans).map(rating => (
                <option key={rating.key} value={rating.key}>
                  {rating.value}
                </option>
              ))}
            </select>
            <ErrorField
              errorMessage={errorMessage}
              errorCondition={errorCondition}
              fieldId={"story-maturity"}
            />
          </React.Fragment>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

StoryMaturityField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default StoryMaturityField;
