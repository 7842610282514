import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ASSET_SERVER } from "../constants";

const SCRIPT_ID = "lottie-script";

function loadLottieScript() {
  const script = document.createElement("script");
  script.src = `${ASSET_SERVER}/js/lottie.js`;
  script.id = SCRIPT_ID;
  document.head.appendChild(script);

  return script;
}

const useMountEffect = func => useEffect(func, []);
export default function LottieAnimation({
  animationId,
  animationOptions,
  onAnimationLoad,
  ...spreadProps
}) {
  const renderAnimation = () => {
    const animationContainer = document.getElementById(animationId);
    const animation = window.bodymovin.loadAnimation({
      wrapper: animationContainer,
      ...animationOptions
    });
    animation.addEventListener("DOMLoaded", () => {
      onAnimationLoad && onAnimationLoad(animation);
      animation.removeEventListener("DOMLoaded");
    });
  };

  useMountEffect(() => {
    const scriptIsAlreadyLoaded = !!window.bodymovin;

    if (scriptIsAlreadyLoaded) {
      renderAnimation();
      return;
    }
    // The library isn't loaded, but another component might already
    // be fetching the script. To avoid a race condition, make sure
    // that the script is only fetched once.
    let lottieScriptTag = document.getElementById(SCRIPT_ID);
    const scriptIsBeingFetched = !!lottieScriptTag;
    if (!scriptIsBeingFetched) {
      lottieScriptTag = loadLottieScript();
    }

    const onScriptFetch = () => {
      renderAnimation();
      lottieScriptTag.removeEventListener("load", onScriptFetch, false);
    };

    lottieScriptTag.addEventListener("load", onScriptFetch);
  });

  return <div id={animationId} {...spreadProps} />;
}

LottieAnimation.propTypes = {
  animationId: PropTypes.string.isRequired,
  animationOptions: PropTypes.object.isRequired,
  onAnimationLoad: PropTypes.func
};
