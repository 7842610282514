import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import Icon from "../../Icon";
import { canUseDom } from "../../../helpers";

export const buildLabel = ({ label, labelClassName, tooltip, onClickIcon }) => {
  if (!label || label.match(/^ *$/) !== null) {
    return null;
  }
  return (
    <div className={classNames("form-field-label", labelClassName)}>
      <label>{label}</label>

      {tooltip && <InfoTooltip tooltip={tooltip} />}
      {onClickIcon && <InfoButton onClick={onClickIcon} />}
    </div>
  );
};

buildLabel.defaultProps = {
  label: null,
  labelClassName: null,
  tooltip: null,
  onClickIcon: null
};

buildLabel.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  tooltip: PropTypes.string,
  onClickIcon: PropTypes.func
};

export const InfoTooltip = ({
  value,
  tooltip,
  showTooltip = true,
  ariaHidden = false
}) => {
  return (
    <div className={"icon-container"} aria-hidden={ariaHidden}>
      <div data-tip={tooltip} className="tool-tip">
        <span className="sr-only">{tooltip}</span>
        {/* Value tooltip currently only used for StoryDetails StoryStats component */}
        {value !== null ? (
          <span className="stats-value" aria-hidden>
            {value}
          </span>
        ) : (
          <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
        )}
      </div>
      {canUseDom &&
        showTooltip && <ReactTooltip place="top" type="dark" effect="solid" />}
    </div>
  );
};

InfoTooltip.defaultProps = {
  tooltip: "",
  value: null
};

InfoTooltip.propTypes = {
  tooltip: PropTypes.string,
  value: PropTypes.any,
  showTooltip: PropTypes.bool,
  ariaHidden: PropTypes.bool
};

const InfoButton = ({ onClick }) => {
  return (
    <div className={"icon-container"}>
      <button onClick={onClick} className="btn btn-no-style">
        <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
      </button>
    </div>
  );
};

InfoButton.defaultProps = {
  onClick: e => e.preventDefault()
};

InfoButton.propTypes = {
  onClick: PropTypes.func
};

export const buildAbout = (about = "") => {
  if (about === null || about.match(/^ *$/) !== null) {
    return null;
  }
  return <div className={"about-form"}>{about}</div>;
};
