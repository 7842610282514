import {
  FORM_POST,
  FORM_ERROR,
  FORM_UPDATE,
  FORM_FETCH,
  FORM_REQUEST,
  FORM_VISIBILITY,
  DEFAULT_DATA_VALUES
} from "./constants";

function setDefaults(action) {
  return {
    story_id: setDefaultInteger(action, "story_id"),
    part_id: setDefaultInteger(action, "part_id"),
    author_id: setDefaultInteger(action, "author_id"),
    author_note: setDefaultValue(action, "author_note"),
    chapter_description: setDefaultValue(action, "chapter_description"),
    paid_model: setDefaultValue(action, "paid_model"),
    bonus_type: setDefaultInteger(action, "bonus_type"),
    is_free_part: setDefaultValue(action, "is_free_part")
  };
}

function setDefaultValue(action, property) {
  return action.data?.[property] || DEFAULT_DATA_VALUES.data[property];
}

function setDefaultInteger(action, property) {
  return (
    parseInt(action.data?.[property]) || DEFAULT_DATA_VALUES.data[property]
  );
}

export default function reducer(state = DEFAULT_DATA_VALUES, action = {}) {
  switch (action.type) {
    case FORM_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FORM_FETCH: {
      return {
        ...state,
        isLoading: false,
        data: setDefaults(action)
      };
    }
    case FORM_POST: {
      return {
        ...state,
        ...state.data,
        isLoading: false
      };
    }
    case FORM_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    case FORM_UPDATE: {
      return {
        ...state,
        data: setDefaults(action)
      };
    }
    case FORM_VISIBILITY: {
      return {
        ...state,
        isFormVisible: true
      };
    }
    default:
      return state;
  }
}
