import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const ReportInputType = {
  INPUT: "input",
  TEXT_AREA: "textArea"
};

const ReportInputField = ({
  id,
  label,
  initialValue,
  validations,
  hasError,
  placeholder,
  inputType = ReportInputType.INPUT
}) => (
  <div className="report-input-field">
    <label htmlFor={id}>
      {label}
      {validations?.required && <span>*</span>}:
    </label>
    {inputType === ReportInputType.TEXT_AREA ? (
      <textarea
        id={id}
        rows={5}
        placeholder={placeholder}
        defaultValue={initialValue}
        {...validations}
        className={classNames({ error: hasError })}
      />
    ) : (
      <input
        id={id}
        placeholder={placeholder}
        defaultValue={initialValue}
        {...validations}
        className={classNames({ error: hasError })}
      />
    )}
  </div>
);

ReportInputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  validations: PropTypes.object,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  inputType: PropTypes.string
};

export default ReportInputField;
