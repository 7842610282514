import React, { Component } from "react";
import PropTypes from "prop-types";

const withValidation = WrappedComponent => {
  return class ValidatedComponent extends Component {
    state = {
      isValid: null,
      errorMessage: ""
    };

    componentDidMount = () => {
      if (this.props.validationModel) {
        this.props.validationModel.on("attr:invalid", this.onInvalid, this);
        this.props.validationModel.on("attr:valid", this.onValid, this);
      }
    };

    componentWillUnmount = () => {
      if (this.props.validationModel) {
        this.props.validationModel.off("attr:invalid");
        this.props.validationModel.off("attr:valid");
      }
    };

    setAndValidate = e => {
      let model = this.props.validationModel;
      model.set(this.props.name, e.target.value);
      model.validate(this.props.name);
    };

    onInvalid = (key, errorMessage) => {
      if (key === this.props.name) {
        this.setState({
          isValid: false,
          errorMessage
        });
        if (this.props.onInvalid) {
          this.props.onInvalid(errorMessage, key);
        }
      }
    };

    onValid = key => {
      if (key === this.props.name) {
        this.setState({
          isValid: true
        });
        if (this.props.onValid) {
          this.props.onValid(key);
        }
      }
    };

    render = () => {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            hasValidation={true}
            isValid={this.state.isValid}
            onBlur={e => {
              this.setAndValidate(e);
              this.props.onBlur?.();
            }}
            onChange={e => {
              if (this.props.validateOnChange) {
                this.setAndValidate(e);
              }
              this.props.onChange?.();
            }}
            errorMessage={this.state.errorMessage}
          />
        </React.Fragment>
      );
    };

    static propTypes = {
      name: PropTypes.string,
      validateOnChange: PropTypes.bool,
      validationModel: PropTypes.shape({
        on: PropTypes.func,
        off: PropTypes.func,
        set: PropTypes.func,
        validate: PropTypes.func
      }),
      onInvalid: PropTypes.func,
      onValid: PropTypes.func,
      onChange: PropTypes.func,
      onBlur: PropTypes.func
    };
  };
};

export default withValidation;
