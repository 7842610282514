import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Expandable = ({ children, isExpandable, onClick, className, ...rest }) =>
  isExpandable ? (
    <div
      onClick={onClick}
      role="button"
      tabIndex="0"
      className={classnames("clickable", className)}
      {...rest}
    >
      {children}
    </div>
  ) : className ? (
    <div className={className} {...rest}>
      {children}
    </div>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
Expandable.propTypes = {
  children: PropTypes.any,
  isExpandable: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.any
};

const Linkable = ({ type, children, link, isLinkable }) => {
  const isInternalLink = type === "action";
  const isExternalLink = type === "link";
  const extraProps = isExternalLink
    ? {
        target: "_blank",
        rel: "noopener noreferrer"
      }
    : {
        className: "on-navigate"
      };
  isLinkable = isLinkable && link && (isInternalLink || isExternalLink);

  return isLinkable ? (
    <a href={link} {...extraProps}>
      {children}
    </a>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
Linkable.propTypes = {
  type: PropTypes.string,
  isLinkable: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.any
};

export { Expandable, Linkable };
