import React from "react";

import { Checkbox } from "../../shared-components";

import { useTrans } from "../../hooks";

const Acknowledge = () => {
  const { trans } = useTrans();

  return (
    <div className="acknowledge">
      <Checkbox defaultValue={false} id="acknowledge-checkbox" />

      <label htmlFor="acknowledge-checkbox">
        {trans(
          "I acknowledge this is my own original work, or I have the permission from the original author to post this work. Copyright infringement may result in story deletion or account closure."
        )}
      </label>
    </div>
  );
};

export default Acknowledge;
