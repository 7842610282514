// Action Types
const name = "POPOVER";

const OPEN = `${name}/OPEN`;
const CLOSE = `${name}/CLOSE`;

// Reducer
const initialState = {
  popoverProps: {}
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        popoverProps: action.popoverProps
      };
    case CLOSE:
      return {
        ...state,
        popoverProps: {}
      };
    default:
      return state;
  }
}

export function openPopover(popoverProps) {
  return function(dispatch, getState) {
    const { triggerRef } = getState().popover.popoverProps;

    if (triggerRef !== popoverProps.triggerRef) {
      dispatch({ type: OPEN, popoverProps });
    } else {
      // Clicked on trigger again. Toggle
      dispatch({ type: CLOSE });
    }
  };
}

export function closePopover() {
  return function(dispatch) {
    dispatch({ type: CLOSE });
  };
}
