// The following component is currently rolled out only to ambassadors and translation is not needed

import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../../../hooks";
import Icon from "../../../Icon";

const TimeoutModal = ({ toggleModal }) => {
  const { trans } = useTrans();

  return (
    <section className="profile-modal" id="userTimeout" aria-hidden="true">
      <header className="modal-header">
        <div className="pull-right top-right close" data-dismiss="modal">
          <button className="close-button" onClick={toggleModal}>
            <Icon iconName="fa-remove" size="16" />
          </button>
        </div>
        <h3 className="modal-title">User Time-Out</h3>
      </header>
      <div className="modal-body">
        <p>Duration: 2 days</p>
        <p>Reason:</p>
        <textarea
          className="form-control"
          aria-multiline="true"
          id="timeout-reason"
          name="timeout-reason"
          rows="10"
          cols="46"
        />
      </div>
      <footer className="modal-footer">
        <button type="button" className="btn btn-orange on-timeout-user">
          {trans("Save")}
        </button>
      </footer>
    </section>
  );
};
TimeoutModal.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

export default TimeoutModal;
