import React from "react";

import { useTrans } from "../../../hooks";
import {
  CURRENT_WATTYS_YEAR,
  DEFAULT_WATTYS_SITE_LINK,
  MAP_WATTYS_SITE_TO_LINKS
} from "../ContestForm/constants";

const SuccessBanner = () => {
  const { trans } = useTrans();
  const wattysSiteLink =
    MAP_WATTYS_SITE_TO_LINKS[parseInt(app.get("language"), 10)] ||
    DEFAULT_WATTYS_SITE_LINK;

  return (
    <div className="text">
      <div
        className="success-text"
        dangerouslySetInnerHTML={{
          __html: /* prettier-ignore */ trans("Your story has been submitted to The %s Watty Awards!", CURRENT_WATTYS_YEAR)
        }}
      />
      <a
        className="plain-link bold"
        href={wattysSiteLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {trans("When will winners be announced?")}
      </a>
    </div>
  );
};

export default SuccessBanner;
