import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../../hooks";

export default function ThanksForTheInput({ handleConfirmation }) {
  const { trans } = useTrans();
  return (
    <div className="thanks-for-the-input">
      <div className="image">
        <img src="/img/homepages/robot.svg" alt="" />
      </div>
      <div className="text">
        <h3>{trans("Thanks for the input!")}</h3>
        <p>
          {trans(
            "Our ingenious robots are busy plotting world domination, er, working on new story recommendations for you. Check back soon."
          )}
        </p>
      </div>
      <div className="confirmation">
        <button className="btn-primary" onClick={handleConfirmation}>
          {trans("Got it")}
        </button>
      </div>
    </div>
  );
}

ThanksForTheInput.defaultProps = {
  handleConfirmation: () => {}
};

ThanksForTheInput.propTypes = {
  handleConfirmation: PropTypes.func
};
