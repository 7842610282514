import { ASSET_SERVER } from "../../constants";

// We use only one currency right now, so hardcode the ID to avoid
// having to make a call to `/virtual-currencies/`.
export const CURRENCY_ID = "wp1";

// Minimum time the wallet animation needs to complete
export const DEDUCTING_ANIMATION_DELAY = 2000;
export const SUBTRACTING_ANIMATION_DELAY = 2200;
export const COIN_TRANSITION_DELAY = 400;

// Action types
export const SET_WALLET_BALANCE = "set_wallet_balance";
export const BUYING_CONTENT = "buying_content";
export const BOUGHT_CONTENT = "bought_content";

export const WALLET_ANIMATION_STEP = "animation_step";

export const WALLET_STATES = {
  DEFAULT: "default",
  COIN: "coin",
  DEDUCTING: "deducting",
  SUBTRACTING: "subtracting"
};

export const WO_ONBOARDING_SLIDES = trans => [
  {
    title: trans("Introducing Wattpad Originals"),
    content: trans(
      "Wattpad's top creators sharing their best work and your next obsession."
    ),
    imageUrl: "paid-content/onboarding-slide-1.png"
  },
  {
    title: trans("Big hits, big on quality"),
    content: trans(
      "Power through completed hits or snack on ongoing stories—quality guaranteed."
    ),
    imageUrl: "paid-content/onboarding-slide-2.png"
  },
  {
    title: trans("Curated for you by our editors"),
    content: trans("Spend more time reading, and less time searching."),
    imageUrl: "paid-content/onboarding-slide-3.png"
  },
  {
    title: trans("More ways to directly support creators"),
    content: trans(
      "Unlock with Coins, your Wattpad Premium+ plan, or wait to unlock for free."
    ),
    imageUrl: "paid-content/onboarding-slide-4.png"
  }
];

// TODO: fetch this from backend instead of hardcoding once backend is
// ready to serve SKUs.
export const HARDCODED_NONPREMIUM_COIN_PACKS = {
  Wattpad_Coins_9: {
    value: 9,
    premium_value: 15,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_1_240x240.png`,
    price: "0.99"
  },
  Wattpad_Coin_74: {
    value: 74,
    premium_value: 99,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_2_240x240.png`,
    featured: true,
    price: "3.99"
  },
  Wattpad_Coin_244: {
    value: 244,
    premium_value: 325,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_3_240x240.png`,
    price: "8.99"
  },
  Wattpad_Coin_400: {
    value: 400,
    premium_value: 533,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_4_240x240.png`,
    price: "13.99"
  }
};

export const HARDCODED_PREMIUM_COIN_PACKS = {
  Premium_BonusCoins_15Pack: {
    value: 15,
    non_premium_value: 9,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_1_240x240.png`,
    price: "0.99"
  },
  Premium_BonusCoins_99Pack: {
    value: 99,
    non_premium_value: 74,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_2_240x240.png`,
    featured: true,
    price: "3.99"
  },
  Premium_BonusCoins_325Pack: {
    value: 325,
    non_premium_value: 244,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_3_240x240.png`,
    price: "8.99"
  },
  Premium_BonusCoins_533Pack: {
    value: 533,
    non_premium_value: 400,
    illustration_url: `${ASSET_SERVER}/image/money_coin_package_4_240x240.png`,
    price: "13.99"
  }
};

export const MAP_EARN_COINS_LINKS = {
  1: "https://support.wattpad.com/hc/en-us/articles/18999845281556", //English
  2: "https://support.wattpad.com/hc/fr/articles/18999845281556-Wattpad-Originals-Foire-Aux-Questions", //French
  3: "https://support.wattpad.com/hc/it/articles/18999845281556-Wattpad-Originals-Domande-frequenti", // Italian
  4: "https://support.wattpad.com/hc/de/articles/18999845281556-Wattpad-Originals-H%C3%A4ufig-gestellte-Fragen", //German
  5: "https://support.wattpad.com/hc/es/articles/18999845281556-Wattpad-Originals-Preguntas-Frecuentes", //Spanish
  6: "https://support.wattpad.com/hc/pt/articles/18999845281556-Wattpad-Originals-Perguntas-frequentes", //Portuguese
  18: "https://support.wattpad.com/hc/fil/articles/18999845281556-Wattpad-Originals-Mga-Madalas-Itanong", //Filipino
  20: "https://support.wattpad.com/hc/id/articles/18999845281556-Wattpad-Originals-Pertanyaan-yang-Sering-Diajukan", //Indonesian
  23: "https://support.wattpad.com/hc/tr/articles/18999845281556-Wattpad-Originals-S%C4%B1k%C3%A7a-Sorulan-Sorular" //Turkish
};

export const DEFAULT_EARN_COINS_LINK =
  "https://support.wattpad.com/hc/articles/18999845281556";

export const DEFAULT_BONUS_COINS_LINK =
  "https://support.wattpad.com/hc/en-us/articles/360034232392-Bonus-Coins-Frequently-Asked-Questions";
