// Action Types
const name = "QUESTS";
export const LOAD_ALL = `${name}/LOAD_ALL`;
export const LOAD_EMBEDDED = `${name}/LOAD_EMBEDDED`;

// Reducer
const initialState = {
  isLoading: true,
  mapById: {},
  mapByEmbeddedType: {} // Embedded type <-> [quests] mapping
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        mapById: action.questMap
      };
    case LOAD_EMBEDDED:
      return {
        ...state,
        mapByEmbeddedType: {
          ...state.mapByEmbeddedType,
          ...action.embeddedQuestMap
        }
      };
    default:
      return state;
  }
}

// Action Creators
export function loadQuests() {
  const questsHubUrl = `/v5/users/${wattpad.utils
    .currentUser()
    .get("username")}/quests/`;

  return async function(dispatch) {
    try {
      const hub_data = await Promise.resolve($.get(questsHubUrl));

      const questMap = {};
      await Promise.all(
        hub_data.quests.map(async quest => {
          const questUrl = questsHubUrl.concat(quest.id, "/");
          const quest_data = await Promise.resolve($.get(questUrl));
          questMap[quest.id] = quest_data.quest;
        })
      );

      dispatch({
        type: LOAD_ALL,
        questMap
      });
    } catch (err) {
      wattpad?.utils?.showToast();
    }
  };
}

export const loadEmbeddedQuests = embeddedType => async dispatch => {
  try {
    const embeddedQuestsUrl = `/v5/users/${wattpad.utils
      .currentUser()
      .get("username")}/quests/embedded/${embeddedType}/`;

    const data = await Promise.resolve($.get(embeddedQuestsUrl));

    const embeddedQuestMap = {
      [embeddedType]: data.quests
    };

    dispatch({ type: LOAD_EMBEDDED, embeddedQuestMap });
  } catch (err) {
    wattpad?.utils?.showToast();
  }
};
