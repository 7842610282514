import React, { Component } from "react";
import PropTypes from "prop-types";

class LanguageDropdown extends Component {
  changeLanguage = langCode => {
    wattpad.utils.setCookie("locale", langCode, 10 * 365, true);
    window.location.reload();
  };

  render() {
    let { translatedLangs } = this.props;
    return (
      <ul id="language-options" className="dropdown-menu align-right columns">
        {translatedLangs.map(lang => {
          return (
            <li key={lang.id} className="col-xs-4 col-sm-3">
              {/* eslint-disable-next-line wp-lint-rules/valid-href */}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.changeLanguage(lang.locale);
                }}
              >
                {lang.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  static propTypes = {
    translatedLangs: PropTypes.array.isRequired
  };
}

export default LanguageDropdown;
