import classNames from "classnames";
import Downshift from "downshift";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";

import { useTrans, useDefaultValueOnce } from "../hooks";
import Icon from "./Icon";

function MultiDownshift({
  children,
  defaultSelectedItems,
  onChange,
  isLoading,
  ...rest
}) {
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);
  useDefaultValueOnce({
    isLoading,
    onSetDefault: () => {
      setSelectedItems(defaultSelectedItems);
    }
  });

  function stateReducer(state, changes) {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: ""
        };
      default:
        return changes;
    }
  }

  function handleSelection(selectedItem) {
    if (selectedItems.includes(selectedItem)) {
      removeItem(selectedItem);
    } else {
      addSelectedItem(selectedItem);
    }
  }

  function removeItem(item) {
    const newState = selectedItems.filter(i => i !== item);
    setSelectedItems(newState);
    onChange && onChange(newState);
  }

  function addSelectedItem(item) {
    const newState = [...selectedItems, item];
    setSelectedItems([...selectedItems, item]);
    onChange && onChange(newState);
  }

  function getStateAndHelpers(downshift) {
    return {
      removeItem,
      selectedItems,
      ...downshift
    };
  }
  return (
    <Downshift
      {...rest}
      stateReducer={stateReducer}
      onChange={handleSelection}
      selectedItem={null}
    >
      {downshift => children(getStateAndHelpers(downshift))}
    </Downshift>
  );
}

MultiDownshift.propTypes = {
  children: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultSelectedItems: PropTypes.array
};

function MultiSelect({
  placeholder,
  itemToString,
  handleChange,
  getItems,
  defaultSelectedItems,
  disabled,
  isLoading,
  className,
  maxLimit
}) {
  const { trans } = useTrans();
  const inputRef = useRef();
  return (
    <MultiDownshift
      isLoading={isLoading}
      defaultSelectedItems={defaultSelectedItems}
      onChange={handleChange}
      itemToString={itemToString}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        removeItem,

        isOpen,
        inputValue,
        selectedItems,
        getItemProps,
        toggleMenu
      }) => (
        <div
          className={classNames(
            "multi-select-container",
            { disabled },
            className
          )}
        >
          <div
            className={classNames("multi-select", {
              disabled: disabled || selectedItems.length > maxLimit
            })}
            role="button"
            tabIndex="0"
            style={{
              borderBottomRightRadius: isOpen ? 0 : 6,
              borderBottomLeftRadius: isOpen ? 0 : 6
            }}
            onClick={() => {
              toggleMenu();
              !isOpen && inputRef.current.focus();
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                flex: 1
              }}
            >
              {selectedItems.length > 0
                ? selectedItems.map(item => (
                    <div key={item.id} className="multi-select-item-container">
                      <div className="multi-select-grid">
                        <span>{item.name}</span>
                        <button
                          type="button"
                          className="multi-select-remove-btn"
                          onClick={event => {
                            event.stopPropagation();
                            removeItem(item);
                            inputRef.current.focus();
                            !isOpen && toggleMenu();
                          }}
                        >
                          <Icon
                            iconName="fa-remove"
                            height="12"
                            color="wp-neutral-2"
                          />
                        </button>
                      </div>
                    </div>
                  ))
                : isOpen
                  ? ""
                  : placeholder || trans("Select items")}
              <input
                {...getInputProps({
                  ref: inputRef,
                  onKeyDown: event => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      event.stopPropagation();
                      return;
                    }
                    if (event.key === "Backspace" && !inputValue) {
                      removeItem(selectedItems[selectedItems.length - 1]);
                      !isOpen && toggleMenu();
                    }
                  },
                  className: "multi-select-search-input"
                })}
              />
            </div>
            <div
              className="multi-select-toggle-btn"
              {...getToggleButtonProps({
                // prevents the menu from immediately toggling
                // closed (due to our custom click handler above).
                onClick(event) {
                  event.stopPropagation();
                }
              })}
            >
              <Icon
                iconName={isOpen ? "fa-up" : "fa-down"}
                height="12"
                color="wp-neutral-2"
              />
            </div>
          </div>
          <div
            className={classNames(
              "multi-select-menu",
              { "is-open": isOpen },
              {
                disabled: disabled || selectedItems.length > maxLimit
              }
            )}
          >
            {isOpen
              ? (() => {
                  const items = getItems(inputValue);

                  // Filter out selected items
                  const filteredItems = items.filter(
                    currentItem =>
                      !selectedItems.find(
                        selectedItem => selectedItem.id === currentItem.id
                      )
                  );

                  if (filteredItems.length === 0) {
                    return (
                      <div className="multi-select-menu-item disabled">
                        {trans("No options")}
                      </div>
                    );
                  }
                  return filteredItems.map((item, index) => (
                    <div
                      key={item.id}
                      className="multi-select-menu-item"
                      {...getItemProps({
                        item,
                        index
                      })}
                    >
                      {item.name}
                    </div>
                  ));
                })()
              : null}
          </div>
        </div>
      )}
    </MultiDownshift>
  );
}

MultiSelect.defaultProps = {
  itemToString: item => (item ? item.name : ""),
  defaultSelectedItems: []
};

MultiSelect.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  itemToString: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxLimit: PropTypes.number,
  getItems: PropTypes.func.isRequired,
  defaultSelectedItems: PropTypes.array
};

export { MultiSelect };
