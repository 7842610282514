import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Icon } from "../../../shared-components";
import {
  PREMIUM_COOKIE,
  DEFAULT_THEME,
  DEFAULT_PREMIUM_THEME,
  THEME_ID_NAME,
  NUM_PREMIUM_THEMES
} from "../../../constants";

export default class ThemeSettings extends React.Component {
  static propTypes = {
    isPremium: PropTypes.bool.isRequired,
    reqCookies: PropTypes.object,
    showSubscriptionModal: PropTypes.func
  };

  constructor(props) {
    super(props);

    let selectedTheme = this.isPremium()
      ? this.getSelectedPremiumTheme()
      : DEFAULT_THEME;
    this.state = {
      selectedTheme: selectedTheme
    };
  }

  getSelectedPremiumTheme = () => {
    let theme = utils
      ? utils.getCookie(PREMIUM_COOKIE)
      : this.props.reqCookies[PREMIUM_COOKIE];
    return parseInt(theme || DEFAULT_PREMIUM_THEME);
  };

  selectTheme = themeId => {
    if (themeId === this.state.selectedTheme) {
      return;
    }

    const isPremium = this.isPremium();
    if (isPremium) {
      utils.setPremiumTheme(themeId);
      this.setState({ selectedTheme: themeId });
    } else if (themeId !== DEFAULT_THEME) {
      // Only pop up subscription modal if the user selects a premium theme
      this.props.showSubscriptionModal(); // call in the parent Monaco view
    }

    let themeName = THEME_ID_NAME[themeId];
    window.te.push("event", "profile", "theme", null, "change", {
      premium: +isPremium, // cast to int to match event format
      theme: themeName
    });
  };

  isPremium() {
    return this.props.isPremium;
  }

  render() {
    const themes = _.range(1, NUM_PREMIUM_THEMES + 1);

    return (
      <div role="listbox" aria-labelledby="panel-body-title">
        {themes.map(themeId => (
          <div key={themeId} className="theme-option-wrapper">
            <ThemeOption
              themeId={themeId}
              selected={themeId === this.state.selectedTheme}
              premium={this.isPremium()}
              selectTheme={this.selectTheme}
            />
          </div>
        ))}
      </div>
    );
  }
}

const ThemeOption = ({ themeId, selected, premium, selectTheme }) => {
  const unavailableTheme =
    !premium && themeId !== DEFAULT_THEME ? "unavailable" : "";
  return (
    <div
      className={classnames("theme-option", `theme-${themeId}`, {
        selected: selected
      })}
    >
      <div
        className={classnames(
          "theme-option-inner",
          `theme-${themeId}`,
          unavailableTheme
        )}
        onClick={() => selectTheme(themeId)}
        onKeyDown={() => selectTheme(themeId)}
        role="option"
        tabIndex="0"
        aria-selected={selected}
        aria-label={`theme-${themeId}`}
      >
        <ThemeIcon selected={selected} />
      </div>
    </div>
  );
};

ThemeOption.propTypes = {
  themeId: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  premium: PropTypes.bool.isRequired,
  selectTheme: PropTypes.func.isRequired
};

const ThemeIcon = ({ selected }) => {
  let icon = <div />; // no icon as fallthrough for unselected theme
  if (selected) {
    icon = <Icon iconName="fa-check" height="24" color="wp-neutral-5" />;
  }
  return icon;
};

ThemeIcon.propTypes = {
  selected: PropTypes.bool.isRequired
};

const window = window || global;
const utils = _.get(window, "wattpad.utils");
