import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Icon } from "./";
import { useTrans, useTrapKeyboardNavigation } from "../hooks";

import {
  getReadingLists,
  getCollectionsForStoryIds,
  updateCollection,
  removeStoriesFromCollection
} from "../views/home/actions";

import { closeModal } from "./modals/actions";

import { useDispatch, useSelector } from "react-redux";

export default function ReadingListModal({ beforeModalRef, storyId }) {
  const dispatch = useDispatch();
  const lists = useSelector(state => state.homeSections.readingLists) || [];
  const collections =
    useSelector(state => state.homeSections.storiesCollections)[storyId] || {};
  const { trans } = useTrans();
  const [listName, setListName] = useState("");
  const listRef = useRef({});
  const listEndRef = useRef(null);
  const [libraryError, setLibraryError] = useState(null);
  let modalRef = useRef();

  useTrapKeyboardNavigation(modalRef?.current, beforeModalRef);

  const addStoryToReadingList = readingListId => {
    sendLibraryAddEvent(storyId);
    dispatch(updateCollection(readingListId, storyId));
  };

  const sendLibraryAddEvent = storyId => {
    window.te.push("event", "app", "story", "library", "add", {
      storyid: storyId,
      page: "story_details"
    });
  };

  const sendLibraryRemoveEvent = storyId => {
    window.te.push("event", "app", "story", "library", "remove", {
      storyid: storyId,
      page: "story_details"
    });
  };

  const handleListItemClick = readingListId => {
    setLibraryError(null);
    if (collections[readingListId]) {
      sendLibraryRemoveEvent(storyId);
      dispatch(removeStoriesFromCollection(readingListId, storyId));
    } else {
      addStoryToReadingList(readingListId);
    }
  };

  const handleAddNewListClick = () => {
    setLibraryError(null);
    const newListName = listName;
    setListName("");

    // Intent was to add story. Add story to list if it already exists.
    const existingReadingList = lists.find(
      list => list.name.toLowerCase() === newListName.toLowerCase()
    );

    // Story already exists in existing list. Scroll to reading list li and exit flow.
    if (existingReadingList && collections[existingReadingList.id]) {
      listRef.current[existingReadingList.id].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
      return;
    }

    if (existingReadingList) {
      // Add story to existing list
      addStoryToReadingList(existingReadingList.id);
      listRef.current[existingReadingList.id].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    } else {
      // Create reading list and add story
      const url = "/api/v3/lists/";
      const payload = {
        id: null,
        name: newListName,
        numStories: 0,
        cover: 0,
        tags: [],
        featured: false,
        stories: storyId
      };
      sendLibraryAddEvent(storyId);
      Promise.resolve($.post(url, payload))
        .then(() => {
          dispatch(getReadingLists());
          dispatch(getCollectionsForStoryIds(storyId));
          listEndRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        })
        .catch(error => {
          const message = error.responseText
            ? error.responseText
            : error.statusText;
          console.log(message || error); // eslint-disable-line no-console
          const libraryError = new Error(
            trans(
              "We are unable to add this story to your reading list at this time"
            )
          );
          setLibraryError(libraryError);
        });
    }
  };

  const handleDoneClick = () => {
    beforeModalRef && beforeModalRef.focus();
    dispatch(closeModal());
  };

  return (
    <div className="reading-list-modal" ref={modalRef}>
      <span className="reading-list-header">
        {/* Label to indicate the modal is opened  */}
        <span className="sr-only">{trans("Add to modal open")}</span>
        <div aria-hidden="true" className="reading-list-title">
          {trans("Add to")}
        </div>
        <button className="reading-list-close-btn" onClick={handleDoneClick}>
          {trans("Done")}
        </button>
      </span>
      <ul className="reading-list-items">
        <li key={"library"} className="reading-list-item">
          <button
            onClick={() => {
              handleListItemClick("library");
            }}
            ref={el => (listRef.current["library"] = el)}
            tabIndex="0"
          >
            <Icon iconName="fa-library" height="22" color="wp-neutral-1" />
            <span className="reading-list-item-name">{"Library"}</span>
            {collections.library && (
              <Icon
                name="check"
                size="22"
                color="wp-base-1"
                fill={true}
                strokeWidth="1"
              />
            )}
          </button>
        </li>
        {lists &&
          lists.map(list => {
            return (
              <li key={list.id} className={"reading-list-item"}>
                <button
                  onClick={() => {
                    handleListItemClick(list.id);
                  }}
                  tabIndex="0"
                  ref={el => (listRef.current[list.id] = el)}
                >
                  <Icon
                    iconName="fa-reading-list"
                    height="22"
                    color="wp-neutral-1"
                  />
                  <span className="reading-list-item-name">{list.name}</span>
                  {collections[list.id] && (
                    <Icon
                      name="check"
                      size="22"
                      color="wp-base-1"
                      fill={true}
                      strokeWidth="1"
                    />
                  )}
                </button>
              </li>
            );
          })}
        <div ref={listEndRef} />
      </ul>
      <form>
        <div className="reading-list-input-row">
          <input
            type="text"
            className="form-control input-text"
            placeholder={trans("Add new reading list...")}
            onChange={event => setListName(event.target.value)}
            value={listName}
          />
          <button
            aria-label={trans("Add new reading list...")}
            tabIndex="0"
            className="btn-icon"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              handleAddNewListClick();
            }}
          >
            <Icon
              name="add"
              height="12"
              color="wp-neutral-1"
              strokeWidth="2.5"
            />
          </button>
        </div>
      </form>
      {libraryError && (
        <div className="reading-list-error">{libraryError.message}</div>
      )}
    </div>
  );
}

ReadingListModal.propTypes = {
  beforeModalRef: PropTypes.object,
  storyId: PropTypes.number.isRequired,
  readingLists: PropTypes.array,
  storiesCollections: PropTypes.object
};
