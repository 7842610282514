import {
  FORM_POST,
  FORM_ERROR,
  FORM_UPDATE,
  FORM_FETCH,
  FORM_REQUEST,
  FORM_VISIBILITY
} from "./constants";

const initialState = {
  isLoading: true,
  hasNotesBeenVisible: false,
  data: {
    id: null,
    protagonist: {
      name: null,
      other_names: "",
      pronoun: null,
      attributes: "",
      extrovert_introvert: null,
      likeable: null,
      focus_past_future: null,
      thinking_feeling: null,
      decisive_improvise: null,
      laidback_serious: null,
      personality_change: null
    },
    protagonist_goal: {
      description: "",
      physical_emotional: null,
      type: null,
      change_themselves_surroundings: null,
      explore_emotions_world: null,
      navigate_familiar_unfamiliar: null,
      recall_past: null,
      find_meaning: null,
      take_keep: null,
      outcome: "",
      succeed: null,
      outcome_feeling: null
    },
    opportunity: {
      interested: null,
      logline: "",
      story_progress: null,
      types: null
    }
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FORM_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FORM_FETCH: {
      return {
        ...state,
        isLoading: false,
        data: {
          id: parseInt(action.data?.id) || initialState.data.id,
          protagonist:
            action.data?.protagonist || initialState.data.protagonist,
          protagonist_goal:
            action.data?.protagonist_goal || initialState.data.protagonist_goal,
          opportunity: action.data?.opportunity || initialState.data.opportunity
        }
      };
    }
    case FORM_POST: {
      return {
        ...state,
        ...state.data,
        isLoading: false
      };
    }
    case FORM_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    case FORM_UPDATE: {
      return {
        ...state,
        data: {
          id: parseInt(action.data.id) || initialState.data.id,
          protagonist: action.data.protagonist || initialState.data.protagonist,
          protagonist_goal:
            action.data.protagonist_goal || initialState.data.protagonist_goal,
          opportunity: action.data.opportunity || initialState.data.opportunity
        }
      };
    }
    case FORM_VISIBILITY: {
      return {
        ...state,
        hasNotesBeenVisible: true
      };
    }
    default:
      return state;
  }
}
