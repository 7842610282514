import React from "react";
import PropTypes from "prop-types";
import Skeleton from "./Skeleton";

export default function StoryCardSkeleton({ coverColor }) {
  return (
    <div className="story-card-skeleton">
      <div className="top-section">
        <div className="cover-skeleton">
          <Skeleton
            width={"100%"}
            height={"100%"}
            animation
            color={coverColor}
            styles={{ borderRadius: "4px" }}
          />
        </div>
        <div className="title-skeleton">
          <Skeleton width={"100%"} height={"28px"} animation />
        </div>
      </div>
      <div className="bottom-section">
        <Skeleton width={"100%"} height={"100%"} animation />
      </div>
    </div>
  );
}

StoryCardSkeleton.propTypes = {
  coverColor: PropTypes.string
};
