import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import PromotedIndicator from "./PromotedIndicator";
import { ExpandPromptButton } from "./ExpandPromptButton";
import {
  Pill,
  PillVariant,
  PillShape,
  Accent,
  Typography,
  IconNames
} from "@wattpad/web-ui-library";
import { Countdown } from "../../../shared-components";

export const HeadingPillType = {
  COUNTDOWN: "countdown"
};

export const HeadingPillProps = {
  type: PropTypes.oneOf(Object.values(HeadingPillType)).isRequired,
  date: PropTypes.string
};

/**
 * Render a header for home modules
 */
const SectionHeader = ({
  expandPrompt,
  heading,
  headingPill,
  subheading,
  isPromoted,
  sectionType
}) => {
  const isPaid = sectionType === "paid" || sectionType === "paidMultiRow";

  return (
    <div className="module-header">
      <div className="left-section">
        <div className="heading">
          <h3 className={classnames(Typography.HEADING.SMALL)}>{heading}</h3>
          {headingPill?.type == HeadingPillType.COUNTDOWN &&
            headingPill?.date && (
              <Pill
                className="access-expires-pill"
                variant={PillVariant.SOLID}
                accent={Accent.DEFAULT}
                shape={PillShape.ROUND}
                leadingIcon={IconNames.WpHistory}
                label={
                  <Countdown
                    date={new Date(headingPill.date)}
                    showDays={true}
                    showHours={true}
                    updateInterval={60}
                  />
                }
              />
            )}
        </div>
        <div className="subheading">
          <h4 className={classnames(Typography.PARAGRAPH.LARGE)}>
            {subheading}
          </h4>
        </div>
      </div>
      {expandPrompt && (
        <ExpandPromptButton expandPrompt={expandPrompt} isPaid={isPaid} />
      )}
      {isPromoted === true && <PromotedIndicator />}
    </div>
  );
};

SectionHeader.propTypes = {
  expandPrompt: PropTypes.object,
  heading: PropTypes.string.isRequired,
  headingPill: PropTypes.shape(HeadingPillProps),
  subheading: PropTypes.string,
  isPromoted: PropTypes.bool,
  sectionType: PropTypes.string
};

export default SectionHeader;
