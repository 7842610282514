import React from "react";

import { Icon } from "../../../shared-components";
import { useTrans } from "../../../hooks";

const PromotedIndicator = () => {
  const { trans } = useTrans();
  return (
    <div className="promoted-indicator">
      <Icon
        name="promoted"
        className="promoted-icon"
        size="25"
        color="wp-base-1"
        boxX="-5"
        boxY="-5"
      />
      <p>{trans("Promoted")}</p>
    </div>
  );
};

export default PromotedIndicator;
