import { useEffect, useRef } from "react";
import useEventListener from "./useEventListener";

function getDocHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight || 0
  );
}

// This function is a helper function that returns the direction of the scroll
function getScrollDirection(lastScrollTop) {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var direction;
  if (scrollTop > lastScrollTop.current) {
    direction = "down";
  } else {
    direction = "up";
  }
  lastScrollTop.current = scrollTop > 0 ? scrollTop : 0;
  return direction;
}

function useScrollDetection({
  onThreshold,
  direction = "down",
  threshold = 0
}) {
  const savedCallback = useRef();
  const lastScrollTop = useRef(0);
  useEffect(
    () => {
      savedCallback.current = onThreshold;
    },
    [onThreshold]
  );
  useEventListener("scroll", () => {
    const scrollDirection = getScrollDirection(lastScrollTop);
    if (
      direction === "down" &&
      scrollDirection === "down" &&
      window.innerHeight + window.scrollY >= getDocHeight() - threshold
    ) {
      savedCallback.current();
    } else if (
      direction === "up" &&
      scrollDirection === "up" &&
      window.pageYOffset <= threshold
    ) {
      savedCallback.current();
    }
  });
}

export default useScrollDetection;
