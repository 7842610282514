import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../hooks";

/**
 * The story description.
 */
const Description = ({ description, license, className, isMobile }) => {
  const { trans } = useTrans();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const readMoreText = trans("Read more");
  const readLessTest = trans("Read less");

  return (
    <div className={classNames(isMobile ? "mobile-styling" : "")}>
      <div
        className={classNames("xxs-container-padding description", className)}
      >
        <pre
          className={classNames(
            "description-text",
            isReadMore ? "collapsed" : ""
          )}
        >
          {description}
          {license &&
            (!isReadMore || !isMobile) && (
              <div className="story-copyright">
                <span
                  className="fa fa-copyright fa-wp-neutral-8"
                  aria-hidden="true"
                />
                {license.name}
              </div>
            )}
        </pre>
        {isMobile && (
          <div className="read-or-hide-wrapper">
            <button onClick={toggleReadMore} className="read-or-hide">
              {isReadMore ? readMoreText : readLessTest}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

Description.propTypes = {
  description: PropTypes.string,
  className: PropTypes.string,
  license: PropTypes.object,
  isMobile: PropTypes.bool
};

export default Description;
