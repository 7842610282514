import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { linkify } from "../../../helpers";
import { ConnectImage } from "../../../shared-components";

const Message = ({
  message,
  username,
  isActive,
  differentSender,
  scrollToMessageId,
  setRef
}) => {
  const fromMe = message.from.name === username;
  const msgBody = linkify(message.body, { sanitization: "sanitize" });
  return (
    <div
      ref={scrollToMessageId === message.id ? setRef : null}
      className={classNames(
        "conversation-row",
        fromMe ? "from-me" : "from-other",
        { "different-sender": differentSender }
      )}
    >
      <a
        className="on-navigate avatar-link"
        href={`/user/${message.from.name}`}
      >
        {isActive || fromMe ? (
          <img
            src={message.from.avatar}
            alt={`${username}'s avatar`}
            className="avatar avatar-sm3"
          />
        ) : (
          <ConnectImage name="avatar/empty.png" className="avatar avatar-sm3" />
        )}
      </a>
      <div className="conversation-message">
        <span
          // Make sure linkify properly sanitizes the html
          dangerouslySetInnerHTML={{
            __html: msgBody
          }}
        />
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    body: PropTypes.string,
    createDate: PropTypes.string,
    from: PropTypes.object,
    id: PropTypes.number,
    latestReplies: PropTypes.array,
    numReplies: PropTypes.number,
    unread: PropTypes.bool
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  differentSender: PropTypes.bool.isRequired,
  scrollToMessageId: PropTypes.number.isRequired,
  setRef: PropTypes.object.isRequired
};

export default Message;
