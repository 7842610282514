import React from "react";
import PropTypes from "prop-types";

import EducationalBanner from "../../shared-components/EducationalBanner";
import SearchBar from "../../shared-components/SearchBar";

const SearchHeader = ({
  bannerMessages,
  isMobile,
  searchTerm,
  handleSearchSubmit,
  handleSaveSearchTerm
}) => {
  return (
    <>
      {isMobile && (
        <SearchBar
          searchTerm={searchTerm}
          handleSaveSearchTerm={handleSaveSearchTerm}
          handleSearchSubmit={handleSearchSubmit}
        />
      )}
      {bannerMessages?.length > 0 && (
        <EducationalBanner bannerMessages={bannerMessages} />
      )}
    </>
  );
};

SearchHeader.defaultProps = {
  isMobile: false,
  bannerMessages: [],
  handleSearchSubmit: () => {},
  handleSaveSearchTerm: () => {}
};

SearchHeader.propTypes = {
  bannerMessages: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  searchTerm: PropTypes.string.isRequired,
  handleSearchSubmit: PropTypes.func,
  handleSaveSearchTerm: PropTypes.func
};

export default SearchHeader;
