// Action Types
const name = "STORY_ITEM";
const LOAD_READING_POSITION = `${name}/LOAD_READING_POSITION`;
const IS_LOADING_READING_POSITION = `${name}/IS_LOADING_READING_POSITION`;
const CLEAR_READING_POSITION = `${name}/CLEAR_READING_POSITION`;

// Reducer
const initialState = {
  isLoading: true,
  loadError: null,
  readingPosition: ""
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_READING_POSITION:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        readingPosition: action.readingPosition
      };
    case CLEAR_READING_POSITION:
      return {
        ...state,
        isLoading: true
      };
    case IS_LOADING_READING_POSITION:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}

// Actions
export const fetchReadingPosition = storyFirstPartId => {
  return async function(dispatch) {
    dispatch({ type: IS_LOADING_READING_POSITION });

    // retrieves currently synced part & position
    // returns{ part: story_part_id, position: 0.0 }
    Promise.resolve(
      $.get("/apiv2/syncreadingposition", {
        story_id: storyFirstPartId
      })
    )
      .then(positionData => {
        if (!positionData || positionData.part === storyFirstPartId) {
          dispatch({
            type: LOAD_READING_POSITION,
            readingPosition: { part: storyFirstPartId, position: "0.0" }
          });
          return;
        }

        dispatch({
          type: LOAD_READING_POSITION,
          readingPosition: positionData
        });
      })
      .catch(err => {
        dispatch({ type: LOAD_READING_POSITION, loadError: err });
      });
  };
};

export const clearReadingPosition = () => {
  return async function(dispatch) {
    dispatch({ type: CLEAR_READING_POSITION });
  };
};
