const buildDmcaUrl = (reportId, language) => {
  const queryParams = new URLSearchParams();
  if (reportId.location) {
    queryParams.append("storyId", reportId.location);
  }
  if (reportId.deepLink) {
    queryParams.append("partId", reportId.deepLink);
  }
  if (reportId.name) {
    queryParams.append("reportedUser", reportId.name);
  }
  queryParams.append("language", language);
  return `/help/dmca?${queryParams.toString()}`;
};

export const buildUserOptionUrl = (reportId, language) => baseUrl => {
  if (baseUrl.toLowerCase().includes("help/dmca")) {
    return buildDmcaUrl(reportId, language);
  } else {
    return baseUrl;
  }
};
