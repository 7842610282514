import { useEffect, useState } from "react";

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState(new URLSearchParams());

  const createCSRQueryParams = () => {
    setQueryParams(new URLSearchParams(window.location.search));
  };

  useEffect(createCSRQueryParams, []);

  return queryParams;
};
