/**
 * Publishes a part
 *
 * @param {StoryPartCreateModel} partModel
 * @returns {String[]|Object} Returns an array of errors, or a result object
 */

module.exports = partModel => {
  return new Promise((resolve, reject) => {
    const defaultError = wattpad.utils.trans("Failed to publish part");

    partModel.publish().then(result => {
      if (result?.error_count > 0) {
        if (result?.error) {
          return reject(result.error);
        }
        if (result?.errors.length > 0) {
          return reject(result.errors[0]);
        }
        return reject(defaultError);
      }

      const partId = parseInt(partModel.get("id"), 10);
      const currentUsername = wattpad.utils.currentUser().get("username");

      wattpad.utils.cacheBust(
        "StoryPartCreateModel",
        { id: partId },
        null,
        true
      );
      wattpad.utils.cacheBust(["Works"], [{ username: currentUsername }]);
      wattpad.utils.cacheBust(
        ["Works"],
        [{ username: currentUsername, published: true }]
      );

      return resolve(result);
    });
  });
};
