import PropTypes from "prop-types";
import React from "react";
import ReactTooltip from "react-tooltip";

import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";

const AccountSocialMedia = ({ facebook }) => {
  const { trans } = useTrans();
  const getFacebook = () => {
    let facebookUrl, facebookNode;

    if (facebook) {
      facebookUrl = "https://www.facebook.com/" + facebook;
      facebookNode = (
        <div className="unlink-facebook-wrapper">
          <a className="facebook-url" href={facebookUrl} target="_new">
            {facebookUrl}
          </a>
          {/* eslint-disable-next-line wp-lint-rules/valid-href */}

          {/* On unlink success, you're going to be 302'd back to the profile page  */}
          <form
            className="unlink-facebook-form"
            method="POST"
            action="/apiv2/linksocialmedia"
          >
            <input type="hidden" name="action" value="unlinkfb" />
            <button type="submit" className={"btn-no-background"}>
              <img
                src="/image/v2/unlink.png"
                width="16"
                height="16"
                alt="unlink.png"
              />
            </button>
          </form>
        </div>
      );
    } else {
      facebookNode = (
        <div className="connect-facebook-wrapper">
          <button className="btn-no-style">
            <img
              className="btn-facebook-link"
              alt="Facebook"
              border="0"
              width="89"
              height="21"
              src="/image/connect_light_medium_short.gif"
            />
          </button>
          <div
            className="facebook-tooltip"
            role="tooltip"
            aria-label="Facebook"
            data-tip={trans(
              "Connecting with Facebook makes it really easy to share everything you do on Wattpad with your Facebook friends. It also allows you to login to Wattpad with one click!"
            )}
          >
            <Icon
              iconName="fa-info"
              height="16"
              color="wp-neutral-2"
              className="tooltip-icon"
            />
          </div>
          <ReactTooltip className="tool-tip" effect="solid" />
        </div>
      );
    }

    return facebookNode;
  };

  return (
    <div>
      <div className="row form-group facebook">
        <label htmlFor="update-facebook" className="control-label col-sm-3">
          {"Facebook "}
          <img
            className="label-icon"
            src="/image/icon22_fb.png?"
            alt="icon22_fb.png"
            border="0"
            align="absmiddle"
          />
        </label>
        <div className="col-sm-6">{getFacebook()}</div>
      </div>
    </div>
  );
};

AccountSocialMedia.propTypes = {
  facebook: PropTypes.string.isRequired
};
export default AccountSocialMedia;
