import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../hooks";

const TableOfContents = ({ children, className }) => {
  const { trans } = useTrans();

  return (
    <div className={classNames("table-of-contents", className)}>
      <div className="table-of-contents__header">
        {trans("Table of contents")}
      </div>
      {children}
    </div>
  );
};

TableOfContents.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  lastUpdated: PropTypes.string
};

export default TableOfContents;
