import React from "react";

import { useTrans } from "../../hooks";
import { ConnectImage } from "../../shared-components";

export default function CoinIcon({ ...rest }) {
  const { trans } = useTrans();
  return (
    <ConnectImage
      name="/paid-content/coin-icon.svg"
      className="coin-icon"
      alt={trans("Coin")}
      {...rest}
    />
  );
}
