"use strict";

const _ = require("lodash");

const NEW_PART_DAYS = 14;

/**
 * Formats response object from API endpoint /v5/story/${storyId}/paid-content/metadata
 *
 * @param {Array<Parts>} parts Parts from the StoryModel
 * @param {DEFAULT_API_RESPONSE} responseMetadata Response object
 * @returns {String|Object} Returns an error, or a result object
 */

const DEFAULT_API_RESPONSE = {
  parts: [],
  story: { price: [] },
  has_full_access: undefined
};

const getBonusTypeName = function(type, trans) {
  switch (type) {
    case 0:
      return trans("Exclusive Chapter");
    case 1:
      return trans("Story Branch");
    case 2:
      return trans("Writer Reveal");
    default:
      return "";
  }
};

const formatPaidContentMetadata = function(parts, responseMetadata, trans) {
  // Set default story-level props
  responseMetadata.story.hasBonusParts = false;
  responseMetadata.story.isPaidPreview = false;
  responseMetadata.story.numNewParts = 0;
  responseMetadata.story.numNewBonusParts = 0;
  responseMetadata.story.premiumPicks = responseMetadata.story.premium_picks
    ? {
        liveness: responseMetadata.story.premium_picks.liveness,
        endsAt: new Date(responseMetadata.story.premium_picks.ends_at),
        startsAt: new Date(responseMetadata.story.premium_picks.starts_at)
      }
    : null;

  let newPartDayLimit = new Date();
  newPartDayLimit.setDate(newPartDayLimit.getDate() - NEW_PART_DAYS);

  _.each(parts, part => {
    const partMetadata = _.find(responseMetadata.parts, {
      id: part.id.toString()
    });

    const createDate = new Date(part?.createDate);

    part.id = parseInt(part.id, 10);

    part.paywalled = !!partMetadata?.paywalled;
    part.isBlocked = part.paywalled && !partMetadata?.has_access;
    part.blockedMessage = partMetadata?.author_message || "";
    part.isBonusPart = partMetadata?.paid_model === "paid_bonus";

    // Bonus part metadata
    part.bonusType = partMetadata?.bonus_type;
    part.authorNote = partMetadata?.author_note;
    part.chapterDescription = partMetadata?.chapter_description;
    part.bonusTypeName = getBonusTypeName(partMetadata?.bonus_type, trans);

    if (partMetadata) {
      // Could include multiple currencies - default to first
      const priceObj = _.first(partMetadata.price);
      if (priceObj) {
        part.price = priceObj.amount;
      }
    }

    // Process data to set story-level props in metadata
    if (!responseMetadata.story.hasBonusParts) {
      responseMetadata.story.hasBonusParts = part.isBonusPart;
    }

    // We consider paid stories a "preview", so users are
    // aware they will have to pay. Once the user has paid
    // at least once, they know the story isn't free, so
    // it's no longer a preview
    if (!responseMetadata.story.isPaidPreview) {
      responseMetadata.story.isPaidPreview = !(
        part.paywalled && !part.isBlocked
      );
    }

    // Count and mark new parts
    if (!part.draft && createDate >= newPartDayLimit) {
      part.new = true;

      if (part.isBonusPart) {
        responseMetadata.story.numNewBonusParts++;
      } else {
        responseMetadata.story.numNewParts++;
      }
    } else {
      part.new = false;
    }
  });

  responseMetadata.parts = parts;

  return responseMetadata;
};

module.exports = { DEFAULT_API_RESPONSE, formatPaidContentMetadata };
