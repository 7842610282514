export const SingleStoryInfo = {
  id: 1,
  title: "The Ugly Barnacle",
  description: "A story about an ugly barnacle",
  cover: "https://img.wattpad.com/cover/197712604-512-k40d0e0.jpg",
  isPaid: true,
  tags: ["shortstory", "sad", "tragedy"],
  sources: ["a", "b", "c"],
  width: 200,
  index: 1,
  numParts: 10,
  completed: true,
  mature: false,
  firstPartId: 123
};

export const HeaderInfo = {
  heading: "hello",
  subheading: "world",
  isPromoted: true
};

export const MultiStoryInfo = Array(10).fill(SingleStoryInfo);

export const GreetingInfo = {
  sectionType: "greeting",
  greeting: "Welcome home, user!"
};

export const mockFeatured = {
  type: "featured",
  data: {
    items: []
  }
};

export const mockGreeting = {
  type: "greeting",
  data: {
    greeting: GreetingInfo.greeting
  }
};
export const mockExpandPrompt = {
  appLink: "fake-app-link",
  prompt: "See all",
  weblink: "fake-web-link"
};

export const mockPaidMultiRow = {
  type: "paidMultiRow",
  data: {
    expandPrompt: mockExpandPrompt,
    heading: "Paid Stories multi row",
    heroItem: SingleStoryInfo,
    rows: [
      {
        heading: "row 1",
        item: MultiStoryInfo
      },
      {
        heading: "row 2",
        items: MultiStoryInfo
      }
    ],
    subheading: "Paid multi row subheading"
  }
};

export const mockPaidRow = {
  type: "paid",
  data: {
    expandPrompt: mockExpandPrompt,
    heading: "Paid Stories row",
    heroItem: SingleStoryInfo,
    listItems: MultiStoryInfo,
    subheading: "Paid row subheading"
  }
};

export const mockStoryHeroGroup = {
  type: "storyHeroGroup",
  data: {
    heading: "story hero group",
    heroItem: SingleStoryInfo,
    listItems: MultiStoryInfo,
    subheading: "story hero group subheading"
  }
};

export const mockStoryList = {
  type: "storyList",
  data: {
    heading: "story list",
    listItems: MultiStoryInfo,
    subheading: "story list subheading"
  }
};
