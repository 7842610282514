import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function useClickOutside(ref, handleClickOutside = () => {}) {
  // Keep track of a ref to the latest callback so the fresh callback
  // is always used, but the ref passed to the effect never changes.
  const savedCallback = useRef();
  useEffect(
    () => {
      savedCallback.current = handleClickOutside;
    },
    [handleClickOutside]
  );

  useEffect(
    () => {
      if (!ref) return;

      const handleClick = e => {
        if (!ref || !ref.contains(e.target)) {
          savedCallback.current(e.target);
        }
      };

      document.addEventListener("mousedown", handleClick);

      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    },
    [ref]
  );
}

useClickOutside.propTypes = {
  handleClickOutside: PropTypes.func.isRequired,
  ref: PropTypes.object.isRequired
};

export default useClickOutside;
