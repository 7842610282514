import _ from "lodash";
import publishPart from "./client.publish-part";
import { MODAL_ID } from "../../components/shared-components";

/**
 * Initializes the publishing process; initialize the PublishModal
 *
 * @param {StoryModel} storyModel
 * @param {StoryPartCreateModel} partModel
 * @param {Boolean} isPreview
 * @returns {String[]|Object} Returns an array of errors, or a result object
 */

export default (storyModel, partModel, isPreview = false) => {
  return new Promise((resolve, reject) => {
    const utils = window.wattpad.utils;

    //Check that user's email is verified
    if (!wattpad.user.verified_email) {
      utils.showPleaseVerifyModal();
      return reject();
    }

    // Set textids
    if (!storyModel?.textids) {
      storyModel.textids = _.pluck(storyModel.parts, "id").join(",");
    }

    const storyLabelsModel = new app.models.StoryLabelsModel({
      storyId: parseInt(storyModel.id)
    });

    let labelsFetch = [];
    labelsFetch = Promise.resolve(storyLabelsModel.fetch()).catch(
      function() {}
    );

    Promise.all([
      $.get(`/apiv2/storytext?id=${partModel.get("id")}&output=json`),
      labelsFetch
    ]).then(function(results) {
      // When dealing with a publish initialization from the preview page we need to explicitly set the text and sometimes the text_hash to sync the value into the model before publishing
      if (isPreview) {
        results[0]?.text && partModel.set("text", results[0]?.text);
        results[0]?.text_hash &&
          partModel.set("last_text_hash", results[0]?.text_hash);
      }

      // Check that necessary story props are set for publishing
      if (
        storyModel.title === "" ||
        storyModel.title === utils.trans("Untitled Story") ||
        storyModel.description === "" ||
        storyModel.tags.length === 0 ||
        (isPreview && !storyModel.category) ||
        (!isPreview && (!storyModel.categoryObject || !storyModel.published))
      ) {
        // Launch story publish settings modal
        var publishView = new app.views.WorksItemDetails({
          model: new app.models.StoryCreateModel(storyModel),
          storyLabelsModel: storyLabelsModel || null,
          isPublish: true,
          partModel,
          isPreview
        });
        $("#generic-modal")
          .find(".modal-content .modal-body")
          .html(publishView.render().$el);
        $("#generic-modal")
          .addClass("publish-modal")
          .modal("show");
        publishView.setElement($(".publish-modal"));
        return reject([]);
      } else {
        // Launch the publish modal
        utils.openModal && utils.openModal(MODAL_ID.PUBLISH_MODAL_ID);
      }
    });
  });
};
