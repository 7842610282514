import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";

export default function TryPremium({ ctaType }) {
  const { trans } = useTrans();

  const trackClick = () => {
    window.te.push("event", "subscriptions", "cta", null, "view", {
      cta_type: ctaType
    });
  };

  return (
    <a
      href="https://www.wattpad.com/premium"
      className="try-premium on-premium btn-primary"
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackClick}
    >
      <Icon
        name="zap"
        size="20"
        className="zap-icon"
        color="ds-base-4-accent"
        fill
      />
      <div className="button-copy">{trans("Try Premium")}</div>
    </a>
  );
}

TryPremium.propTypes = { ctaType: PropTypes.string };
