/**
 * Adds a signup form to the generic modal
 */

module.exports = evt => {
  var view = new app.views.SignUpPrompt({
    model: new app.models.Authsignup(),
    formType: "modal",
    form: "signup"
  });

  $("#generic-modal .modal-dialog").addClass("signup-modal-wrapper");
  $("#generic-modal .modal-content").addClass("auth-modal");
  $("#generic-modal .modal-body").html(view.render().$el);
  wattpad.utils.openModal("generic-modal");
};
