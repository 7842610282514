import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { fetchContestForm, setContestForm } from "./reducers";

import { SubmissionSuccess } from "./SubmissionSuccess";
import WattysForm from "./WattysForm";

const ContestFormUI = ({
  success,
  isStandalonePage,
  closeModal,
  storyId,
  formState,
  fetchContestForm,
  setContestForm
}) => {
  const [view, setView] = useState("form");
  let component;

  // Fetch contest form from server
  useEffect(
    () => {
      if (storyId) {
        fetchContestForm(storyId);
      }
    },
    [storyId, fetchContestForm]
  );

  function onSubmit(hasAddedWattysTag) {
    if (isStandalonePage) {
      // Standalone page: we maintain the state via url. We modify href so the clients can detect this change in url.
      window.location.href = `/contests/${
        window.wattpad.wattysActiveKey
      }?storyId=${storyId}&success=true&hasAddedWattysTag=${hasAddedWattysTag}`;
    } else {
      // Inside the modal: we maintain page state via the "view" param
      setView("success");
    }
  }

  if (view === "success" || success) {
    component = (
      <SubmissionSuccess
        isStandalonePage={isStandalonePage}
        formState={formState}
        closeModal={closeModal}
      />
    );
  } else {
    component = (
      <WattysForm
        storyId={storyId}
        closeModal={closeModal}
        formState={formState}
        setContestForm={setContestForm}
        isStandalonePage={isStandalonePage}
        submitForm={onSubmit}
      />
    );
  }
  return component;
};

ContestFormUI.defaultProps = {
  success: false,
  isStandalonePage: false,
  closeModal: () => {},
  storyId: ""
};

ContestFormUI.propTypes = {
  formState: PropTypes.object,
  fetchContestForm: PropTypes.func,
  setContestForm: PropTypes.func,
  closeModal: PropTypes.func,
  success: PropTypes.bool,
  hasAddedWattysTag: PropTypes.bool,
  isStandalonePage: PropTypes.bool,
  storyId: PropTypes.string
};

const ContestForm = connect(
  state => ({ formState: { ...state.contestForm } }),
  { fetchContestForm, setContestForm }
)(ContestFormUI);

export { ContestForm, ContestFormUI };
