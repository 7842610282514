import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans, usePopover } from "../../hooks";
import { Icon } from "../../shared-components";
import InfoPopover from "./InfoPopover";

const InfoPopoverConstants = {
  CLASS: "writer-analytics-info-popover",
  PROPS: {
    placement: "right",
    showArrow: "true",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 12]
        }
      }
    ]
  }
};

/**
 * An info icon button which opens an information popover when clicked.
 *
 */
const InfoButton = ({ popoverContent, className, color = "wp-neutral-1" }) => {
  const triggerRef = useRef();
  const { trans } = useTrans();
  const { handleClosePopover, handleOpenPopover } = usePopover();

  const PopoverContent = () => {
    return (
      <InfoPopover
        title={popoverContent.title}
        content={popoverContent.content}
        closePopover={() => handleClosePopover(triggerRef)}
      />
    );
  };

  const handleClick = () => {
    handleOpenPopover(
      PopoverContent,
      triggerRef,
      InfoPopoverConstants.CLASS,
      InfoPopoverConstants.PROPS
    );
  };

  return (
    <button
      ref={triggerRef}
      onClick={handleClick}
      className={classNames("info-btn", className)}
      aria-label={trans("Click for more information")}
    >
      <Icon iconName="fa-info" height="14" color={color} />
    </button>
  );
};

InfoButton.propTypes = {
  popoverContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
  color: PropTypes.string
};

export default InfoButton;
