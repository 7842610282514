import React from "react";
import PropTypes from "prop-types";

const ReportDropdownField = ({ id, label, options }) => (
  <div className="report-dropdown-field">
    <label htmlFor={id}>
      {label}
      <span>*</span>:
    </label>
    <select id={id} name={id} defaultValue="" required={true}>
      {options.map(option => (
        <option
          key={option.key || "empty"}
          value={option.key}
          disabled={option.disabled}
        >
          {option.value}
        </option>
      ))}
    </select>
  </div>
);

ReportDropdownField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default ReportDropdownField;
