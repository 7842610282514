import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../hooks";
import { ConnectImage } from "./";

const SearchNoResults = ({ resultType }) => {
  const { trans } = useTrans();

  const message =
    resultType === "stories"
      ? trans(
          "Try a different search to see if we can find you some stories to read"
        )
      : trans(
          "Try a different search to see if we can find you the user you’re looking for"
        );

  return (
    <div className="empty-search-results">
      <ConnectImage name={"search/no-results.svg"} title="" />
      <div className="message">
        <h4>{trans("Hmmm...there are no results")}</h4>
        <p className="message">{message}</p>
      </div>
    </div>
  );
};

SearchNoResults.propTypes = {
  resultType: PropTypes.string.isRequired
};

export default SearchNoResults;
