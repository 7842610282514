import React, { useMemo, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { FormField, withValidation } from "../../shared-components";
import { useTrans } from "../../hooks";

function BirthdayFields({
  validationModel,
  onValid,
  onInvalid,
  inputRef,
  errorMessageOnSubmit
}) {
  const { trans } = useTrans();
  const [isValidDate, setIsValidDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const ValidatedFormField = useMemo(() => withValidation(FormField), []);

  const handleInvalidBirthday = errorMessage => {
    setIsValidDate(false);
    onInvalid();
    setErrorMessage(errorMessage);
  };

  const handleValidBirthday = () => {
    setIsValidDate(true);
    setErrorMessage(undefined);
    onValid();
  };

  let groupClasses = classNames("has-feedback birthday-container", {
    valid: isValidDate === true,
    invalid: isValidDate === false,
    invalidDate: errorMessage || errorMessageOnSubmit
  });

  return (
    <div className={groupClasses}>
      <div className="birthday-fields">
        <ValidatedFormField
          name="month"
          validateOnChange={true}
          label={trans("Month")}
          form="signup"
          inputType="select"
          alt_msg={isValidDate ? "Select Month" : errorMessage}
          validationModel={validationModel}
          onInvalid={handleInvalidBirthday}
          onValid={handleValidBirthday}
        />
        <ValidatedFormField
          name="day"
          validateOnChange={true}
          label={trans("Day")}
          form="signup"
          inputType="select"
          alt_msg={isValidDate ? "Select Day" : errorMessage}
          validationModel={validationModel}
          onInvalid={handleInvalidBirthday}
          onValid={handleValidBirthday}
        />
        <ValidatedFormField
          name="year"
          validateOnChange={true}
          label={trans("Year")}
          form="signup"
          inputType="select"
          alt_msg={isValidDate ? "Select Year" : errorMessage}
          validationModel={validationModel}
          onInvalid={handleInvalidBirthday}
          onValid={handleValidBirthday}
          inputRef={inputRef}
        />
      </div>

      {!isValidDate && (
        <div
          aria-describedby="birthday_error"
          aria-live="assertive"
          role="alert"
          className="error-msg"
        >
          {errorMessage}
        </div>
      )}

      {errorMessageOnSubmit && (
        <div
          aria-describedby="birthday_error"
          aria-live="assertive"
          role="alert"
          className="error-msg"
        >
          {errorMessageOnSubmit}
        </div>
      )}
    </div>
  );
}

BirthdayFields.propTypes = {
  validationModel: PropTypes.shape({
    on: PropTypes.func,
    off: PropTypes.func
  }),
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  inputRef: PropTypes.object,
  errorMessageOnSubmit: PropTypes.string
};
export default BirthdayFields;
