import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectImage } from "../../shared-components";
import { injectTrans } from "../../helpers";

import Dropdown from "./Dropdown";
import CommunityDropdown from "./CommunityDropdown";
import WriterOpportunitiesDropdown from "./WriterOpportunitiesDropdown";
import DiscoverDropdown from "./DiscoverDropdown";
import Search from "./Search";
import LanguageDropdown from "./LanguageDropdown";

const isEnglish = currentLang => {
  return currentLang.code === "en";
};

class NavBar extends Component {
  render() {
    const { trans, translatedLangs, locale } = this.props;
    const currentLang = translatedLangs.find(language => {
      return language.locale === locale;
    });
    return (
      <nav id="WPNavbar" className="container">
        <div id="header-item-logo">
          <a
            href="/"
            className="on-navigate"
            title="Wattpad Home"
            aria-label="Home"
          >
            <ConnectImage
              name="/logos/wp-logo-orange.png"
              title="Wattpad"
              width="123"
              className="wp-logo hidden-xs"
            />
          </a>
        </div>
        <DiscoverDropdown />
        {isEnglish(currentLang) && (
          <Dropdown
            id="writer-opportunities-dropdown"
            label={trans("Writer Opportunities")}
          >
            <WriterOpportunitiesDropdown />
          </Dropdown>
        )}
        <Dropdown id="community-dropdown" label={trans("Community")}>
          <CommunityDropdown trans={trans} />
        </Dropdown>
        <Search trans={trans} />
        <div id="authentication">
          <div>
            <button
              className="btn btn-sm"
              onClick={() => {
                this.props.toggleModal({
                  type: "login"
                });
              }}
            >
              {trans("Log in")}
            </button>
          </div>
          <Dropdown id="language-dropdown" label={currentLang.name}>
            <LanguageDropdown translatedLangs={translatedLangs} />
          </Dropdown>
        </div>
      </nav>
    );
  }

  static propTypes = {
    toggleModal: PropTypes.func,
    trans: PropTypes.func.isRequired,
    translatedLangs: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired
  };
}

const mapStateToProps = state => {
  return {
    translatedLangs: state.appData.translatedLangs,
    locale: state.userData.locale
  };
};

export default connect(mapStateToProps)(injectTrans(NavBar));
