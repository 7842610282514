import React, { useState } from "react";
import { useFeatureFlags, useTrans } from "../../hooks";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  generateDefaultPronouns,
  generateDropdownItems as generateSelectOptions
} from "./helpers";
import { Icon } from "../";

const WPInput = ({
  inputType,
  name,
  id,
  onBlur,
  onFocus,
  onChange,
  autofocus,
  alt,
  inputRef,
  placeholder,
  showVisibilityToggle,
  addClassName,
  isValid
}) => {
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();

  let inputClasses = classNames(
    "form-control",
    addClassName,
    "enable",
    "input-lg",
    {
      "custom-select": inputType === "select",
      "custom-select-lg": inputType === "select"
    }
  );
  const [passwordType, setPasswordType] = useState("password");
  const togglePasswordVisibility = e => {
    e.preventDefault();
    e.stopPropagation();
    setPasswordType(passwordType === "text" ? "password" : "text");
  };

  switch (inputType) {
    case "select":
      return (
        <div className="birthday-dropdowns">
          <select
            id={id}
            className={inputClasses}
            name={name}
            defaultValue=""
            onBlur={onBlur}
            onChange={onChange}
            aria-label={alt}
            ref={inputRef}
            required
          >
            {generateSelectOptions(name)}
          </select>
        </div>
      );
    case "pronouns":
      return (
        <select
          id="pronouns"
          className={inputClasses}
          name={name}
          defaultValue=""
          onBlur={onBlur}
          onChange={onChange}
          aria-label={alt}
          ref={inputRef}
        >
          {generateDefaultPronouns(trans).map(option => {
            return (
              <option key={option.value} value={option.key}>
                {option.value}
              </option>
            );
          })}
        </select>
      );
    case "password":
      return (
        <div className="password-container">
          <input
            type={passwordType}
            id={id}
            className={inputClasses}
            name={name}
            placeholder={placeholder}
            autoFocus={autofocus}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            ref={inputRef}
            aria-label={alt}
            required
          />
          {featureFlags.NEW_ONBOARDING_1 &&
            isValid && (
              <Icon
                name="check"
                size="24"
                height="24"
                color="ds-base-7-60"
                className="field-valid-check"
                strokeWidth="0.5"
                fill={true}
              />
            )}
          {showVisibilityToggle && (
            <button
              type="button"
              className={classNames("btn-no-background", "password-show", {
                "old-password-show": !featureFlags.NEW_ONBOARDING_1 // TODO: remove after new onboarding rollout
              })}
              aria-label={
                passwordType === "text"
                  ? trans("Hide password")
                  : trans("Show password")
              }
              onClick={togglePasswordVisibility}
            >
              {passwordType === "text" ? trans("Hide") : trans("Show")}
            </button>
          )}
        </div>
      );
    default:
      return (
        <div className="field-container" key={id}>
          <input
            type={inputType}
            id={id}
            className={inputClasses}
            name={name}
            placeholder={placeholder}
            autoFocus={autofocus}
            onBlur={onBlur}
            aria-label={alt}
            ref={inputRef}
            required
          />
          {featureFlags.NEW_ONBOARDING_1 &&
            isValid && (
              <Icon
                name="check"
                size="24"
                height="24"
                color="ds-base-7-60"
                className="field-valid-check"
                strokeWidth="0.5"
                fill={true}
              />
            )}
        </div>
      );
  }
};

WPInput.propTypes = {
  inputType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
  showVisibilityToggle: PropTypes.bool,
  alt: PropTypes.string,
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  addClassName: PropTypes.string,
  isValid: PropTypes.bool
};

export default WPInput;
