import { useEffect, useRef } from "react";

export const focusableElementsString =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

/**
 * A hook that allows you trap the keyboard focus.
 *
 * @param modalElement The modal's DOM element
 * @param buttonElement The DOM element that triggered the modal. This element will be focused after the modal is closed.
 * @param onEscapePressed Function to be called when the escape key is pressed.
 */
export const useTrapKeyboardNavigation = (
  modalElement,
  buttonElement,
  onEscapePressed
) => {
  // Refs for navigating tab in the modal
  const lastTabStop = useRef();
  const firstTabStop = useRef();

  /*
  * This function can be call everytime the modal content change, to make sure
  * the proper first and last tab stops are updated.
  *  */
  const updateTabStops = () => {
    if (!modalElement) return;

    // Find all focusable children in order to grab the first and last
    // interactive elements to navigate tab focus when the component is mounted.
    const focusableElements = modalElement.querySelectorAll(
      focusableElementsString
    );

    if (focusableElements.length > 0) {
      firstTabStop.current = focusableElements[0];
      lastTabStop.current = focusableElements[focusableElements.length - 1];
    }
  };

  const handleTabPressed = (e, fromTapStop, toTabStop) => {
    if (document.activeElement === fromTapStop.current) {
      e.preventDefault();
      toTabStop.current?.focus();
    }
  };

  const trapTabKey = e => {
    // Check for TAB key press
    if (e.key === "Tab" && firstTabStop.current && lastTabStop.current) {
      // SHIFT + TAB: previous element
      if (e.shiftKey) {
        handleTabPressed(e, firstTabStop, lastTabStop);
        // TAB: next element
      } else {
        handleTabPressed(e, lastTabStop, firstTabStop);
      }
    }
    //Escape the modal
    if (e.key === "Escape" && buttonElement) {
      e.preventDefault();
      onEscapePressed?.();
      buttonElement?.focus();
    }
  };

  const initializeTabEventListeners = () => {
    if (!modalElement) return;

    updateTabStops();
    firstTabStop.current?.focus();

    // Listen for and trap the keyboard in order to maintain the focus
    // inside the modal until ESC key is pressed. This was an exception
    // to the rule for allowing people with accessibility to  navigate
    // one interaction at a time.
    modalElement.addEventListener("tap", trapTabKey);
    modalElement.addEventListener("keydown", trapTabKey);

    return () => {
      modalElement.removeEventListener("tap", trapTabKey);
      modalElement.removeEventListener("keydown", trapTabKey);
    };
  };

  useEffect(initializeTabEventListeners, [modalElement]);

  return { updateTabStops };
};
