import React, { Component } from "react";
import NukaCarousel from "nuka-carousel";
import PropTypes from "prop-types";
import classNames from "classnames";

import { linkClicked } from "./helpers";
import { CAROUSEL_SLIDES as slides } from "./constants";

import { ConnectImage } from "../../shared-components";

export default class Carousel extends Component {
  render() {
    return (
      <div id="Carousel">
        <div className="container">
          <NukaCarousel
            autoplay
            wrapAround
            heightMode="max"
            renderCenterLeftControls={null}
            renderBottomCenterControls={null}
            renderCenterRightControls={({ nextSlide }) => {
              return (
                <div
                  onClick={nextSlide}
                  onKeyDown={nextSlide}
                  role="button"
                  tabIndex="0"
                >
                  <ConnectImage name="/landing/next-btn.svg" />
                </div>
              );
            }}
            renderBottomRightControls={({
              currentSlide,
              slideCount,
              goToSlide
            }) => {
              return (
                <div className="controls">
                  {[...Array(slideCount)].map((e, i) => {
                    let classes = classNames("control", {
                      active: i === currentSlide
                    });
                    return (
                      <div
                        className={classes}
                        key={i}
                        onClick={() => {
                          goToSlide(i);
                        }}
                        onKeyDown={() => {
                          goToSlide(i);
                        }}
                        role="tab"
                        tabIndex="0"
                      />
                    );
                  })}
                </div>
              );
            }}
          >
            {slides.map(slide => <Slide {...slide} key={slide.id} />)}
          </NukaCarousel>
        </div>
      </div>
    );
  }

  static propTypes = {
    mobile: PropTypes.bool
  };
}

const Slide = ({ quoteText, author, about, cover, url, id }) => {
  return (
    <div className="slide">
      <div
        className="cover-container"
        onClick={() => {
          linkClicked(id, url);
        }}
        onKeyDown={() => {
          linkClicked(id, url);
        }}
        role="link"
        aria-roledescription="slide"
        tabIndex="0"
      >
        <ConnectImage name={cover} title={cover} />
      </div>
      <div className="details">
        <div className="quote">
          <p className="text">{quoteText}</p>
          <p className="author">{author}</p>
        </div>
        <p className="about">{about}</p>
        <button
          id="startReading"
          className="btn btn-md btn-teal"
          onClick={() => {
            linkClicked(id, url);
          }}
        >
          Start Reading
        </button>
      </div>
    </div>
  );
};

Slide.propTypes = {
  quoteText: PropTypes.string,
  author: PropTypes.string,
  about: PropTypes.string,
  cover: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  mobile: PropTypes.bool
};
