import React from "react";
import PropTypes from "prop-types";
import { fromNow, count, injectTrans } from "../../helpers";
import { Icon } from "../../shared-components";

import classNames from "classnames";

class WorksStoryItem extends React.Component {
  render() {
    const { story, trans } = this.props;

    story.publishedPartsCount = story.parts.filter(part => !part.draft).length;
    story.draftPartsCount = story.parts.filter(part => part.draft).length;

    var publishedPartString = trans(
      "%s Published part",
      story.publishedPartsCount
    );
    if (story.publishedPartsCount > 1) {
      publishedPartString = trans(
        "%s Published parts",
        story.publishedPartsCount
      );
    }

    var draftPartString = trans("%s - draft", story.draftPartsCount);
    if (story.draftPartsCount > 1) {
      draftPartString = trans("%s - drafts", story.draftPartsCount);
    }

    return (
      <div
        className={classNames("story-list-item", this.props.className)}
        key={story.id}
        onClick={() => this.props.onSelectStory(story)}
        onKeyDown={() => this.props.onSelectStory(story)}
        role="row"
        tabIndex="0"
      >
        <img className="story-cover" src={story.cover} alt={`${story} cover`} />
        <div className="story-item-details">
          <h4 className="story-title">{story.title}</h4>
          <div>
            {story.publishedPartsCount ? (
              <span className="publish-count">{publishedPartString}</span>
            ) : (
              ""
            )}
            {story.draftPartsCount ? (
              <span className="draft-count">
                &nbsp;
                {draftPartString}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="updated-date">
            {trans("Updated %s", fromNow(story.modifyDate))}
          </div>
          <div className="meta-wrapper">
            <span className="story-meta">
              <Icon iconName="fa-view" height="12" color="wp-neutral-3" />
              {count(story.readCount).toString()}
            </span>
            <span className="story-meta">
              <Icon iconName="fa-vote" height="12" color="wp-neutral-3" />
              {count(story.voteCount).toString()}
            </span>
            <span className="story-meta">
              <Icon iconName="fa-comment" height="12" color="wp-neutral-3" />
              {count(story.commentCount).toString()}
            </span>
          </div>
        </div>
      </div>
    );
  }
  static propTypes = {
    story: PropTypes.object.isRequired,
    className: PropTypes.string,
    trans: PropTypes.func.isRequired,
    onSelectStory: PropTypes.func
  };
}

export default injectTrans(WorksStoryItem);
