import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import { CatalogHeader } from "./CatalogHeader";
import { PaidStoriesCatalog } from "./catalogs/PaidStoriesCatalog";
import PremiumPicksCatalog from "./catalogs/PremiumPicksCatalog";

function Catalog({ catalog, isMobile, isPremium }) {
  const { trans } = useTrans();

  switch (catalog) {
    case "paidstories":
    case "wattpadoriginals":
      return (
        <div className="catalog-container">
          <CatalogHeader
            title={trans("Wattpad Originals")}
            catalog={catalog}
            isMobile={isMobile}
            isPremium={isPremium}
          />
          <PaidStoriesCatalog catalog={catalog} />
        </div>
      );
    case "premiumpicks":
      return <PremiumPicksCatalog isPremium={isPremium} />;
  }

  return null;
}

Catalog.propTypes = {
  catalog: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isPremium: PropTypes.bool
};

export { Catalog };
