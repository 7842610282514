import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadQuests } from "./reducers";
import classnames from "classnames";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";

import { Linkable, Expandable } from "./HelperComponents";
import { useTrans } from "../../hooks";
import {
  Checkbox,
  LottieAnimation,
  ConnectImage
} from "../../shared-components";

function QuestProgress() {
  // TODO: get these 2 values from the server
  const completedTasks = 20;
  const totalTasks = 50;

  const completionPercentage = completedTasks / totalTasks;
  const { trans } = useTrans();

  const showPlaceholder = true;

  const renderAnimation = () => (
    <React.Fragment>
      <div className="header-container">
        <h6>
          {trans(
            "Achieve all 30 goals to be eligible to appear on a special Featured List"
          )}
        </h6>
      </div>
      <div className="content">
        <LottieAnimation
          animationId="quests-reward"
          animationOptions={{
            animType: "svg",
            loop: false,
            path: "/img/lottie-animations/insights-pending.json"
          }}
          onAnimationLoad={animation => {
            const animateTo = animation.totalFrames * completionPercentage;
            animation.playSegments([0, animateTo], true);
          }}
        />

        <div className="count">
          {completedTasks}/{totalTasks}
        </div>
        <div className="count-subtitle">{trans("Until you get featured")}</div>
      </div>
    </React.Fragment>
  );

  const renderPlaceholder = () => (
    <div className="placeholder-container">
      <ConnectImage name="quests/incoming-quests.svg" />
      <div className="title">{trans("You're on your way")}</div>
      <div>
        {trans(
          "Keep an eye out for more Quests. The more you do, the more eligible you become to have your story featured!"
        )}
      </div>
    </div>
  );

  return (
    <div className="quest-progress">
      {showPlaceholder ? renderPlaceholder() : renderAnimation()}
    </div>
  );
}

function QuestTask({
  title,
  description,
  is_complete,
  body: { click_url },
  type
}) {
  return (
    <div className="quest-task">
      <Checkbox disabled defaultValue={is_complete} />
      <div className="quest-task-right-content">
        <Linkable type={type} link={click_url} isLinkable={!is_complete}>
          <h6
            className={classnames("quest-task-title", {
              complete: is_complete
            })}
          >
            {title}
          </h6>

          <div
            className={classnames("quest-task-description", {
              complete: is_complete
            })}
          >
            {description}
          </div>
        </Linkable>
      </div>
    </div>
  );
}
QuestTask.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  is_complete: PropTypes.bool,
  body: PropTypes.object,
  type: PropTypes.string
};
QuestTask.defaultProps = {
  body: {
    click_url: "",
    tracking_url: ""
  },
  type: ""
};

function QuestCard({
  style,
  description,
  tasks_total,
  tasks_completed,
  title,
  tasks,
  badges
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { ngettext } = useTrans();

  const onExpandToggle = () => setIsExpanded(!isExpanded);
  const tasksLeft = tasks_total - tasks_completed || 0;
  return (
    <div className={classnames("quest-card", { expanded: isExpanded })}>
      <div className="left-content" style={{ background: style.banner_color }}>
        <img src={style.avatar_url} alt="Quest avatar" />
      </div>

      <Expandable
        isExpandable={!isExpanded}
        onClick={onExpandToggle}
        className="right-content"
      >
        <Expandable isExpandable={isExpanded} onClick={onExpandToggle}>
          <h3>{title}</h3>
          {isExpanded && (description && <div>{description}</div>)}
        </Expandable>

        {isExpanded && (
          <React.Fragment>
            <div>
              <hr />
            </div>

            {tasks && tasks.map(task => <QuestTask key={task.id} {...task} />)}
          </React.Fragment>
        )}

        <div className="tasks-container" />
        <div className="tasks-count">
          {/* prettier-ignore */ sprintf(ngettext("%s task left to complete", "%s tasks left to complete", tasksLeft), tasksLeft)}
        </div>
        {badges.length > 0 && (
          <div className="quest-badges-container">
            {badges.map(badge => (
              <div key={badge.title} className="quest-badge">
                {badge.title}
              </div>
            ))}
          </div>
        )}
      </Expandable>
    </div>
  );
}
QuestCard.propTypes = {
  id: PropTypes.number,
  description: PropTypes.string,
  tasks_total: PropTypes.number,
  tasks_completed: PropTypes.number,
  title: PropTypes.string,
  style: PropTypes.object,
  tasks: PropTypes.array,
  badges: PropTypes.array
};
QuestCard.defaultProps = {
  tasks_total: 0,
  style: {},
  badges: []
};

function QuestsUI({ loadQuests, questMapById }) {
  const { trans } = useTrans();
  useEffect(
    () => {
      loadQuests();
    },
    [loadQuests]
  );

  const quests = Object.values(questMapById);
  return (
    <div id="quests">
      <h3>{trans("Do these quests to become a better Wattpader!")}</h3>

      <div className="split-view">
        <div className="quest-cards">
          {quests.map(quest => <QuestCard key={quest.id} {...quest} />)}
        </div>

        {quests.length > 0 && <QuestProgress />}
      </div>
    </div>
  );
}
QuestsUI.propTypes = {
  loadQuests: PropTypes.func,
  questMapById: PropTypes.object
};

const Quests = connect(
  state => ({ questMapById: state.quests.mapById }),
  { loadQuests }
)(QuestsUI);

export { Quests };
