import React from "react";

const WriterOpportunitiesDropdown = () => {
  return (
    <div
      className="dropdown-menu large"
      aria-labelledby="writer-opportunities-dropdown"
    >
      <ul
        aria-label="Writer-Opportunities"
        className="writer-opportunities-dropdown-list"
      >
        <li>
          <a
            href="https://creators.wattpad.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Writer Resources
          </a>
        </li>
        <li>
          <a
            href="https://creators.wattpad.com/programs-and-opportunities/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Writer Programs
          </a>
        </li>
        <li>
          <a
            href="https://www.wattpad.com/go/writing-contests/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Writer Contests
          </a>
        </li>
      </ul>
    </div>
  );
};

export default WriterOpportunitiesDropdown;
