import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useTrans } from "../../../hooks";

/*
 * Suggested Tags component renders a list of suggested tags
 * Props:
 *     excludedSuggestions <array>: array of strings to not suggest
 *     handleClick <func>: onclick handler for tags
 *     inputTerm <string>: string to get suggestions for
 */
export default function SuggestedTags({
  excludedSuggestions,
  handleClick,
  inputTerm
}) {
  const { trans } = useTrans();

  const [suggestedTags, setSuggestedTags] = useState([]);

  useEffect(
    () => {
      const getSuggestedTags = () => {
        const suggestedTagsURI = `/api/v3/autocomplete/tag?term=${inputTerm}`;
        Promise.resolve(
          $.ajax({
            url: suggestedTagsURI,
            type: "GET"
          })
        )
          .then(suggestedTags => {
            // filter out suggested tags that are to be excluded
            const tagsToShow = suggestedTags.filter(tag => {
              return !excludedSuggestions.includes(tag);
            });
            setSuggestedTags(tagsToShow);
          })
          .catch(setSuggestedTags([]));
      };
      getSuggestedTags();
    },
    [inputTerm, excludedSuggestions]
  );

  return (
    <div className="suggested-tags">
      <div className="title">{trans("Suggested Tags")}</div>
      <div className="tags">
        {suggestedTags.map(tag => (
          <button key={tag} onClick={() => handleClick(tag)}>
            #{tag}
          </button>
        ))}
      </div>
    </div>
  );
}

SuggestedTags.defaultProps = {
  excludedSuggestions: [],
  handleClick: () => {},
  inputTerm: ""
};

SuggestedTags.propTypes = {
  excludedSuggestions: propTypes.arrayOf(propTypes.string),
  handleClick: propTypes.func,
  inputTerm: propTypes.string
};
