import React, { useState } from "react";
import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import PropTypes from "prop-types";
import classNames from "classnames";

function ChangeEmail({
  email,
  userId,
  hideModal,
  onEmailChanged,
  onUpdateEmail
}) {
  const { trans } = useTrans();

  const [passwordType, setPasswordType] = useState("password");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [validEmailIcon, setValidEmailIcon] = useState("pending");
  const [isNewEmailEmpty, setIsNewEmailEmpty] = useState(true);
  const [isConfirmEmailEmpty, setIsConfirmEmailEmpty] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState("");
  const [newEmailError, setNewEmailError] = useState("");

  // needs to be set to true at beginning because user hasn't had time to input email when modal first loads
  const [isNewEmailValid, setIsNewEmailValid] = useState(true);

  const togglePasswordVisibility = event => {
    event.preventDefault();
    event.stopPropagation();
    setPasswordType(passwordType === "text" ? "password" : "text");
  };

  const removeErrors = event => {
    event.preventDefault();

    setIsNewEmailValid(true);
    setNewEmailError("");
    setConfirmEmailError("");
    setValidEmailIcon("pending");
  };

  const checkFieldEmpty = event => {
    wattpad.utils.stopEvent(event);

    const inputField = event.target.name;
    const isEmptyState = !event.target.value;

    if (inputField == "email") {
      setIsNewEmailEmpty(isEmptyState);
      if (isEmptyState) {
        setNewEmail("");
      }
    } else if (inputField == "confirm_email") {
      setIsConfirmEmailEmpty(isEmptyState);
    } else if (inputField == "password") {
      setIsPasswordEmpty(isEmptyState);
    }
  };

  const checkEmailValid = event => {
    wattpad.utils.stopEvent(event);
    const email = event.target.value;

    // empty field
    if (!email) {
      setIsNewEmailValid(false);
      setNewEmailError(trans("Email is required."));
      setValidEmailIcon("warning");
    }
    // check if email is valid and/or is registered with another account
    else {
      Promise.resolve(
        $.ajax("/api/v3/users/validate", {
          data: { email: email }
        })
      )
        .then(function() {
          setIsNewEmailValid(true);
          setNewEmailError("");
          setValidEmailIcon("check");
          setNewEmail(email);
        })
        .catch(function(error) {
          setIsNewEmailValid(false);
          setNewEmailError(error.responseJSON.message);
          setValidEmailIcon("warning");
        });
    }

    return;
  };

  const checkEmailFieldsMatch = event => {
    const confirmEmail = event.target.value;
    if (newEmail != "" && newEmail != confirmEmail) {
      setConfirmEmailError(trans("Please ensure the email fields match."));
    } else {
      setConfirmEmailError("");
    }
  };

  const onUpdateEmailFormSubmit = event => {
    wattpad.utils.stopEvent(event);
    setServerErrorMessage("");

    if (newEmailError) {
      return;
    }

    const id = event.target.id.value,
      newEmail = event.target.email.value,
      confirmEmail = event.target.confirm_email.value,
      password = event.target.password.value;

    /*checkEmailFieldsMatch (onBlur) does not include the usecase below
     1) Enter email
     2) Confirm email
     3) modify email
     4) Navigate to password without clicking on confirm email field
     We will check if both email fields match here
     */
    if (newEmail != confirmEmail) {
      setServerErrorMessage(trans("Please ensure the email fields match."));
      return;
    }

    Promise.resolve(
      $.ajax({
        type: "POST",
        url: "/apiv2/updateuseremail",
        data: {
          id: id,
          email: newEmail,
          confirm_email: confirmEmail,
          password: password
        }
      })
    )
      .then(function() {
        onEmailChanged(newEmail);
        if (onUpdateEmail) {
          onUpdateEmail(newEmail);
        }
      })
      .catch(function(error) {
        setServerErrorMessage(error.responseJSON.message);
      });
  };

  const onClose = event => {
    wattpad.utils.stopEvent(event);
    hideModal();
  };

  const modalMessage = trans("You registered your account with %s. If this is incorrect, you can change your email here.", email); /* prettier-ignore */
  const newEmailClassName = classNames(
    "form-control input-bg enable change-email-input",
    { invalid: !isNewEmailValid }
  );

  return (
    <div id="change-email-modal" className="change-email-modal-content">
      <button
        className="close on-close"
        onClick={onClose}
        aria-label={trans("Close")}
        tabIndex="0"
      >
        <Icon iconName="fa-remove" height="16" color="wp-neutral-2" />
      </button>
      <h1>{trans("Change email")}</h1>
      <p className="modal-message">
        {modalMessage.split(email)[0]}
        <strong>{email}</strong>
        {modalMessage.split(email)[1]}
      </p>
      <form
        className="on-form-submit"
        onSubmit={onUpdateEmailFormSubmit}
        onChange={checkFieldEmpty}
      >
        <input type="hidden" name="id" value={userId} />
        <div className="input-group-vertical">
          <div className="form-group has-feedback">
            <label className="control-label" htmlFor="new_email">
              {trans("New Email")}
            </label>
            <input
              id="new_email"
              className={newEmailClassName}
              name="email"
              type="email"
              defaultValue=""
              onBlur={checkEmailValid}
              onFocus={removeErrors}
            />
            {validEmailIcon == "check" ? (
              <span tabIndex="-1">
                <Icon
                  iconName="fa-check"
                  height="18"
                  className="form-control-feedback"
                />
              </span>
            ) : (
              validEmailIcon == "warning" && (
                <span tabIndex="-1">
                  <Icon
                    iconName="fa-warning"
                    height="18"
                    className="form-control-feedback"
                  />
                </span>
              )
            )}
            {newEmailError && (
              <p className="user-error-message">{newEmailError}</p>
            )}
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="confirm_email">
              {trans("Confirm New Email")}
            </label>
            <input
              id="confirm_email"
              className="form-control input-bg enable change-email-input"
              name="confirm_email"
              type="text"
              defaultValue=""
              onBlur={checkEmailFieldsMatch}
            />
            {confirmEmailError && (
              <p className="user-error-message">{confirmEmailError}</p>
            )}
          </div>
          <div className="form-group has-feedback password">
            <label className="control-label" htmlFor="password">
              {trans("Confirm Password")}
            </label>
            <input
              id="password"
              className="form-control input-bg enable change-email-input"
              name="password"
              defaultValue=""
              type={passwordType}
            />
            <span
              onClick={togglePasswordVisibility}
              onKeyDown={togglePasswordVisibility}
              role="button"
              tabIndex="-1"
            >
              <Icon
                iconName="fa-view"
                height="24"
                color="wp-neutral-2"
                className="form-control-feedback interact"
              />
            </span>
          </div>
        </div>
        {/* eslint-disable-next-line wp-lint-rules/valid-href*/}
        <a className="forgot-password" href="/forgot?nextUrl=settings">
          {trans("Forgot your password?")}
        </a>
        {serverErrorMessage && (
          <div className="server-error-message">
            <Icon iconName="fa-warning" height="22" className="warning-icon" />
            <p className="server-error-text">{serverErrorMessage}</p>
          </div>
        )}
        <input
          type="submit"
          className="btn-primary btn-md btn-save"
          value={trans("Change email")}
          disabled={
            isNewEmailEmpty ||
            isConfirmEmailEmpty ||
            isPasswordEmpty ||
            !isNewEmailValid ||
            confirmEmailError != ""
          }
        />
        <button className="btn-secondary btn-md btn-cancel" onClick={onClose}>
          {trans("Cancel")}
        </button>
      </form>
    </div>
  );
}

ChangeEmail.propTypes = {
  email: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  hideModal: PropTypes.func,
  onEmailChanged: PropTypes.func.isRequired,
  onUpdateEmail: PropTypes.func
};

export { ChangeEmail };
