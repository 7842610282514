import React from "react";
import PropTypes from "prop-types";
import Slider from "../../../shared-components/input/Slider";
import { buildLabel } from "../../../shared-components/input/helpers/input-labels";

function SliderGroup({
  label,
  options,
  info,
  onChange,
  values,
  labelClassName,
  ...rest
}) {
  const translateValue = v => {
    if (v == null) {
      return 0;
    }
    return v;
  };

  const sliderOptions = options.map(function(data, i) {
    return (
      <Slider
        labels={data.sliderLabels}
        key={i}
        name={data.id}
        onChange={event => onChange(event, parseInt(event.target.value, 10))}
        range={[-5, 5]}
        disabledUntilClick={values[i] == null}
        value={translateValue(values[i])}
        {...rest}
      />
    );
  });

  return (
    <div className={"slider-group form-field"}>
      {buildLabel({ label, labelClassName, tooltip: info })}
      <div className={"form-slider-group"}>{sliderOptions}</div>
    </div>
  );
}

SliderGroup.defaultProps = {
  onChange: () => {},
  values: []
};

SliderGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  labelClassName: PropTypes.string
};

export default SliderGroup;
