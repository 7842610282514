// Action Types
const name = "PREMIUM_PICKS_CATALOG";
const LOAD_PREMIUM_PICKS_LIST = `${name}/LOAD_PREMIUM_PICKS_LIST`;
const IS_LOADING_PREMIUM_PICKS = `${name}/IS_LOADING_PREMIUM_PICKS`;

const resultStructure = {
  period: {
    year: null,
    month: null,
    expiresAt: null,
    stories: []
  }
};

// Reducer
const initialState = {
  currentPicks: {},
  upcomingPicks: {},
  current: resultStructure,
  next: resultStructure,
  isLoading: true,
  loadError: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PREMIUM_PICKS_LIST:
      return {
        ...state,
        currentPicks: action.currentPicks,
        upcomingPicks: action.upcomingPicks,
        current: action.current,
        next: action.next,
        isLoading: false,
        loadError: null
      };
    case IS_LOADING_PREMIUM_PICKS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}

// Actions
export const fetchPremiumPicks = (hideMature = false) => {
  return function(dispatch) {
    dispatch({ type: IS_LOADING_PREMIUM_PICKS });

    let promises = [$.get(`${wattpad?.apiDomain}/reading/v1/premiumPicks`)];

    if (hideMature) {
      const username = wattpad.utils.currentUser().get("username");
      promises.push($.get(`/v5/users/${username}/content-settings`));
    }

    Promise.all(promises)
      .then(([premiumPicks, contentSettings]) => {
        let currentPicks = premiumPicks.current;
        let upcomingPicks = premiumPicks.next;

        if (hideMature && !contentSettings?.includeMature) {
          currentPicks.stories = currentPicks.stories.filter(
            story => !story.mature
          );
          upcomingPicks.stories = upcomingPicks.stories.filter(
            story => !story.mature
          );
        }

        dispatch({
          type: LOAD_PREMIUM_PICKS_LIST,
          currentPicks,
          upcomingPicks
        });
      })
      .catch(err => {
        dispatch({ type: LOAD_PREMIUM_PICKS_LIST, loadError: err });
      });
  };
};
