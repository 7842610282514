import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Icon } from "../";
import { buildLabel } from "./helpers/input-labels";

function CheckboxGroup({
  info,
  label,
  options,
  onChange,
  className,
  labelClassName,
  values,
  ...rest
}) {
  const [isFocused, setIsFocused] = useState();

  const handleChange = event => {
    onChange(event);
  };

  const button_options = options.map(function(data) {
    return (
      <div key={data.value} className={classNames(className, "checkbox")}>
        <input
          type={"checkbox"}
          id={data.label}
          value={data.value}
          className={classNames({ focus: isFocused })}
          checked={values.includes(data.value)}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...rest}
        />
        <span className={"checkmark"}>
          <Icon iconName="fa-check" height="14" color="wp-base-1" />
        </span>
        <label id={"checkbox-label"} htmlFor={data.label}>
          {data.label}
        </label>
      </div>
    );
  });

  return (
    <div className={"form-field checkbox-container"}>
      {buildLabel({ label, labelClassName, tooltip: info })}
      <div className={"checkbox-group"}>{button_options}</div>
    </div>
  );
}

CheckboxGroup.defaultProps = {
  values: [],
  onChange: () => {}
};

CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  info: PropTypes.string
};

export default CheckboxGroup;
