import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import StorySwimlane from "../home/sections/StorySwimlane";
import { MultiStoryInfo } from "../home/testing_data/mock_data";
import { useTrans } from "../../hooks";

const apiFields = `stories(id,title,tags,cover)`;

let options = {
  sectionType: "swimlane",
  index: 0,
  setPosition: () => {},
  windowWidth: 1920,
  wasSeen: false
};

const TagpagePaidStoriesContainer = props => {
  const [stories, setStories] = useState([]);
  const { trans } = useTrans();

  useEffect(
    () => {
      async function fetchData() {
        if (!stories.length && props.rootTopic) {
          $.get(
            `/v4/paid_stories/tags/${props.rootTopic.get(
              "name"
            )}?fields=${apiFields}`
          )
            .then(response => {
              return response.stories
                .filter(story => {
                  if (!_.isObject(story)) {
                    // eslint-disable-next-line no-console
                    console.error(
                      `TagpagePaidStoriesContainer: ${props.rootTopic.get(
                        "name"
                      )} given invalid value: ${story}`
                    );
                    return false;
                  } else {
                    return true;
                  }
                })
                .map(story => {
                  story.isPaid = true;
                  return story;
                });
            })
            .then(stories => setStories(stories));
        }
      }

      fetchData();
    },
    [props.rootTopic, stories.length]
  );

  const showRow = !!(stories.length && props.rootTopic);

  return (
    <React.Fragment>
      {showRow && (
        <StorySwimlane
          {...options}
          page="tags"
          wasSeen={true}
          sectionType="tags_paid_carousel"
          heading={trans("Hottest Wattpad Originals")}
          subheading={trans("Read the stories we love")}
          stories={stories.length ? stories : MultiStoryInfo.slice(0, 7)}
          isPromoted={false}
          storiesToShow={7}
          storyWidth={112}
        />
      )}
    </React.Fragment>
  );
};

TagpagePaidStoriesContainer.propTypes = {
  rootTopic: PropTypes.object
};

export default TagpagePaidStoriesContainer;
