/**
 * 2022 Password Policy Strength
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} username
 * @param {string} password
 * @returns {{strength: string, suggestions: Array }} - Returns the currently in-effect password strength policies
 */

const clientAPICall = (url, data) => {
  return Promise.resolve(
    $.ajax({
      type: "post",
      url,
      data,
      contentType: "application/json; charset=utf-8",
      dataType: "json"
    })
  );
};

module.exports = function(firstName, lastName, username, password) {
  if (!password) {
    throw new Error("Must provide a password!");
  }

  return new Promise(async function(resolve, reject) {
    const url = "https://www.wattpad.com/v5/password-strength/check";

    const data = {
      password,
      requester: {
        username,
        firstName,
        lastName
      }
    };

    await clientAPICall(url, JSON.stringify(data))
      .then(pwStrength => {
        return resolve(pwStrength);
      })
      .catch(err => {
        return reject(err);
      });
  });
};
