import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadEmbeddedQuests } from "./reducers";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import { Icon } from "../../shared-components";
import { Linkable } from "./HelperComponents";

function EmbeddedQuestsUI({
  loadEmbeddedQuests,
  embeddedType,
  embeddedQuests,
  username
}) {
  const { trans } = useTrans();
  useEffect(
    () => {
      loadEmbeddedQuests(embeddedType);
    },
    [loadEmbeddedQuests, embeddedType]
  );

  // If embedded quests contain more than 1 quest in the future, update design accordingly.
  const currentQuest = embeddedQuests && embeddedQuests[0];

  if (!currentQuest) {
    return null;
  }

  // Find first task that is not completed
  const firstUncompletedTask =
    currentQuest.tasks && currentQuest.tasks.find(task => !task.is_complete);

  let banner;
  if (firstUncompletedTask) {
    banner = (
      <Linkable
        type={firstUncompletedTask.type}
        link={firstUncompletedTask.body && firstUncompletedTask.body.click_url}
        isLinkable
      >
        <div className="panel panel-default2 panel-embedded-quest">
          <img
            src={currentQuest.style && currentQuest.style.avatar_url}
            alt={`${embeddedType} avatar`}
          />
          <div className="right-content">
            <div className="banner-title">{firstUncompletedTask.title}</div>
            <div>{firstUncompletedTask.description}</div>
          </div>
        </div>
      </Linkable>
    );
  } else {
    // All tasks are completed
    banner = (
      <div className="panel panel-default2 panel-embedded-quest completed-banner">
        <img
          src={currentQuest.style && currentQuest.style.avatar_url}
          alt={`${embeddedType} avatar`}
        />
        <div className="right-content">
          <div className="banner-title">
            {trans("Congrats, you’ve completed all recommended tasks.")}
          </div>
          <div>
            {trans(
              "You’re well on your way to becoming a superstar writer. Keep the momentum up by completing more tasks. The more you do the closer you are to getting featured on a special Featured List."
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div id="embedded-quests">
      <div className="header">
        <span className="title">{currentQuest.title}: </span>
        <span
          dangerouslySetInnerHTML={{
            __html: /* prettier-ignore */ trans("<strong>%s</strong> of %s completed", currentQuest.tasks_completed, currentQuest.tasks_total)
          }}
        />
      </div>
      {banner}
      <a className="link-quests right-icon" href={`/user/${username}/quests`}>
        <Icon name="arrowRight" size={20} />
        {trans("Complete more tasks and get featured")}{" "}
      </a>
    </div>
  );
}
EmbeddedQuestsUI.propTypes = {
  loadEmbeddedQuests: PropTypes.func,
  embeddedType: PropTypes.string.isRequired,
  embeddedQuests: PropTypes.array,
  username: PropTypes.string
};

const EmbeddedQuests = connect(
  (state, ownProps) => ({
    embeddedQuests: state.quests.mapByEmbeddedType[ownProps.embeddedType]
  }),
  { loadEmbeddedQuests }
)(EmbeddedQuestsUI);

EmbeddedQuests.defaultProps = {
  embeddedType: "profile-about",
  embeddedQuests: []
};

export { EmbeddedQuests };
