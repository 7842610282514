import React from "react";
import PropTypes from "prop-types";

export default class NotificationCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.value
    };
  }

  onCheckboxChange = () => {
    let checked = !this.state.checked;
    this.setState({ checked });
    this.props.onCheckboxChange(this.props.name, checked);
  };

  render() {
    const { name, value, children } = this.props;
    const { checked } = this.state;
    return (
      <div>
        <div className="col-xs-2">
          <input
            type="checkbox"
            name={name}
            value={value}
            defaultChecked={checked}
            onChange={this.onCheckboxChange}
          />
        </div>
        <div className="col-xs-10">{children}</div>
      </div>
    );
  }
}

NotificationCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onCheckboxChange: PropTypes.func
};
