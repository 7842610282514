import { useReducer } from "react";
import PropTypes from "prop-types";

/**
 * A hook that handles selection logic with the
 * following behaviour:
 *
 * If a user selects a custom option, the default option is automatically de-selected
 * If a user de-selects all custom filter options, the default option is automatically selected
 * The user can not manually de-select the default option
 * At least one option, must always be selected
 * Multiple custom filter options can be selected at any time.
 *
 * TODO: Ideally, we shouldn't be needing to make a distinction between default and initial states.
 *       This is a temporary fix to address a state mangement issue that arises due to react <-> backbone
 *       communication in filters. More here: https://github.com/Wattpad/frontend/pull/7466#discussion_r731241721
 *
 *
 * @param  {Array} optionsList The list of options
 * @param  {String} defaultOption The default option
 * @param {Object} initialState An initial state, if different than default state
 */
const useReducerWithDefault = (
  optionsList,
  defaultOption,
  initialState = {}
) => {
  if (!optionsList.includes(defaultOption)) {
    throw new Error("Default option is not in options list");
  }

  // setup default state
  let defaultState = {};
  optionsList.forEach(
    option => (defaultState[option] = option === defaultOption)
  );

  // setup initial state
  let initState = {};
  if (Object.keys(initialState).length === 0) {
    initState = { ...defaultState };
  } else {
    initState = { ...initialState };
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "APPLY": {
        delete state["error"];
        // checking default option, revert to default state.
        if (action.value === defaultOption) return defaultState;

        // checking custom option, ensure default option is unchecked.
        return {
          ...state,
          [action.value]: true,
          [defaultOption]: false
        };
      }
      case "REMOVE": {
        delete state["error"];
        // You can't uncheck default option, ever.
        if (action.value === defaultOption) return state;

        const updateState = { ...state, [action.value]: false };

        // if state update results in zero checked options, revert to default state
        return Object.values(updateState).includes(true)
          ? updateState
          : defaultState;
      }
      default:
        return {
          ...state,
          error: "Error in default reducer"
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);

  const handleUpdate = (type, value) => {
    dispatch({
      type: type,
      value: value
    });
  };

  return [state, handleUpdate];
};

useReducerWithDefault.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultOption: PropTypes.string.isRequired,
  initialState: PropTypes.object
};

export default useReducerWithDefault;
