import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTrans, useFeatureFlags } from "../../hooks";
import EmailLogin from "./EmailLogin";
import ThirdPartyLogin from "./ThirdPartyLogin";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";
import classNames from "classnames";

const LoginForm = ({
  focus = true,
  msg,
  nextUrl = "/home",
  notice,
  toggleForm
}) => {
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  const [isEmailFormView, setIsEmailFormView] = useState(false);

  const toggleFormView = () => setIsEmailFormView(!isEmailFormView);
  return (
    //NOTE: This is a temporary div, we can add CSS to login-form after rollout
    <div
      className={classNames({
        "login-form-position": !featureFlags.NEW_ONBOARDING_1,
        "auth-form-padding": featureFlags.NEW_ONBOARDING_1,
        "auth-form-modal-padding":
          featureFlags.NEW_ONBOARDING_1 && nextUrl && !nextUrl.includes("home")
      })}
    >
      <div
        id="authentication-panel"
        className={classNames("login-form", {
          ["old-margins"]: !featureFlags.NEW_ONBOARDING_1
        })}
      >
        {featureFlags.NEW_ONBOARDING_1 &&
          !isEmailFormView && (
            <p className="title">{trans("Log in to Wattpad")}</p>
          )}
        {!featureFlags.NEW_ONBOARDING_1 && (
          <p className="title">{trans("Log in to Wattpad")}</p>
        )}
        <div className="panel-body">
          {isEmailFormView ? (
            <EmailLogin
              msg={msg}
              nextUrl={nextUrl}
              notice={notice}
              toggleFormView={toggleFormView}
            />
          ) : (
            <ThirdPartyLogin
              focus={focus}
              msg={msg}
              nextUrl={nextUrl}
              notice={notice}
              toggleForm={toggleForm}
              toggleFormView={toggleFormView}
            />
          )}
        </div>
        {featureFlags.NEW_ONBOARDING_1 ? (
          !isEmailFormView && (
            <Button
              variant={ButtonVariant.TERTIARY}
              className={"footer-button-margin-signup"}
              onClick={toggleForm}
              label={trans("Don't have an account? Sign up")}
              fullWidth
            />
          )
        ) : (
          <footer className="signup-link">
            <span>
              {trans("Don't have an account? ")}
              <button className="signup-link__btn" onClick={toggleForm}>
                {trans("Sign up")}
              </button>
            </span>
          </footer>
        )}
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  focus: PropTypes.bool,
  msg: PropTypes.string,
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  toggleForm: PropTypes.func
};

export default LoginForm;
