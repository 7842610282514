import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "./Icon";

class SocialSharing extends Component {
  formatSocialShareVisible(visibleLinks) {
    if (visibleLinks) {
      return visibleLinks.map(link => (
        <FormattedSharingLink {...link} isVisible key={link.name} />
      ));
    }
    return null;
  }

  formatSocialShareHidden(hiddenLinks) {
    if (hiddenLinks) {
      return (
        <div className="button-group relative inline-block more">
          <button
            className="btn btn-no-background dropdown-toggle btn-left-align"
            data-toggle="dropdown"
          >
            <Icon iconName="fa-more" height="24" color="wp-neutral-3" />
          </button>
          <div className="triangle" />
          <ul className="dropdown-menu">
            {hiddenLinks.map(link => (
              <li key={link.name}>
                <FormattedSharingLink
                  {...link}
                  isVisible={false}
                  key={link.name}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }

  render() {
    const visibleLinks =
      this.props.socialShareVisible || this.props.socialLinks;
    const hiddenLinks = this.props.socialShareHidden;
    return (
      <div className="SocialSharing">
        {this.formatSocialShareVisible(visibleLinks)}
        {this.formatSocialShareHidden(hiddenLinks)}
      </div>
    );
  }
}

SocialSharing.propTypes = {
  socialShareVisible: PropTypes.arrayOf(PropTypes.object),
  socialLinks: PropTypes.arrayOf(PropTypes.object),
  socialShareHidden: PropTypes.arrayOf(PropTypes.object)
};

const FormattedSharingLink = ({
  name,
  href,
  icon,
  isVisible,
  nopopup,
  label,
  content,
  text,
  url,
  urlTemplate
}) => (
  <a
    data-gtm-action={`share:${name}`}
    rel="nofollow noreferrer"
    className={classnames(`share-${name}`, { "social-share": !nopopup })}
    target={nopopup ? "" : "_blank"}
    href={href}
    data-share-channel={name}
    data-share-url={urlTemplate}
    data-url={url}
    data-share-text={text}
    data-share-content={content}
  >
    <Icon
      iconName={icon}
      height={isVisible ? "24" : "20"}
      color={isVisible ? "wp-neutral-5" : "wp-neutral-2"}
      className={classnames({ "pull-left": !isVisible })}
    />
    {isVisible ? "" : label}
  </a>
);

FormattedSharingLink.defaultProps = {
  nopopup: false,
  content: "",
  text: "",
  url: "",
  urlTemplate: ""
};

FormattedSharingLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  nopopup: PropTypes.bool,
  content: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  urlTemplate: PropTypes.string
};

export default SocialSharing;
