import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans, useTrapKeyboardNavigation } from "../../../../hooks";

const BlockModal = ({ username, toggleModal, blockUser }) => {
  const { trans } = useTrans();

  const confirmationText = trans("This account will not be able to follow you, send you messages, post on your profile, or read or comment on your stories. This action can take 30 minutes to take effect."); // prettier-ignore

  let blockClasses = classNames("btn-primary", {
    "on-block-user": blockUser === undefined
  });

  let modalRef;
  let buttonRef;

  // Check window in case document is not planted
  if (typeof window !== undefined) {
    modalRef = document.querySelector(".block-modal");
    buttonRef = document.querySelector(".dropdown-toggle");
  }

  useTrapKeyboardNavigation(modalRef, buttonRef, toggleModal);

  return (
    <div className="block-modal-content">
      <h3 className="block-modal-title">{trans("Block %s", username)}</h3>
      <p>{confirmationText}</p>
      <div className="confirm-buttons">
        <button className="cancel-block" onClick={toggleModal}>
          {trans("Cancel")}
        </button>
        <button
          className={blockClasses}
          onClick={() => {
            toggleModal();
            if (blockUser) {
              blockUser(username);
            }
          }}
        >
          {trans("Block")}
        </button>
      </div>
    </div>
  );
};

BlockModal.propTypes = {
  username: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  blockUser: PropTypes.func
};

export default BlockModal;
