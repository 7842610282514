import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Button({ text, variant, onClick, ...props }) {
  return (
    <button
      className={classNames("ds-button", variant)}
      onClick={onClick}
      {...props}
    >
      {text}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func
};

Button.defaultProps = {
  variant: "",
  onClick: () => {}
};

export { Button };
