import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Slider({
  labels,
  range,
  name,
  value,
  disabledUntilClick,
  onChange,
  step,
  className,
  ...rest
}) {
  const [isFocused, setIsFocused] = useState();
  const [isDisabled, setIsDisabled] = useState(disabledUntilClick);

  useEffect(
    () => {
      setIsDisabled(disabledUntilClick);
    },
    [disabledUntilClick, setIsDisabled]
  );

  const onEnableSlider = () => {
    setIsDisabled(false);
  };

  const onFocusSlider = event => {
    setIsFocused(true);
    setIsDisabled(false);
    onChange(event);
  };

  const onInputChange = event => {
    onEnableSlider();
    onChange(event);
  };

  return (
    <div className={classNames("slider-container", className)}>
      <div className="left-label slider-labels">{labels[0]}</div>
      <input
        type={"range"}
        min={range[0]}
        max={range[1]}
        step={step}
        value={value}
        name={name}
        onChange={onInputChange}
        onClick={onEnableSlider}
        onFocus={onFocusSlider}
        onBlur={() => setIsFocused(false)}
        className={classNames({ focus: isFocused, disabled: isDisabled })}
        {...rest}
      />
      <div className="slider-labels">{labels[1]}</div>
    </div>
  );
}

Slider.defaultProps = {
  range: [0, 100],
  onChange: () => {},
  disabledUntilClick: false,
  name: "slider",
  step: 1
};

Slider.propTypes = {
  labels: PropTypes.array.isRequired,
  name: PropTypes.string,
  range: PropTypes.array,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabledUntilClick: PropTypes.bool.isRequired,
  className: PropTypes.string,
  step: PropTypes.number
};

export default Slider;
