import React from "react";
import { useDispatch } from "react-redux";

import ConfirmModal from "../shared-components/modals/ConfirmModal";
import { toggleModal } from "../shared-components/modals/actions";

export const useConfirmModal = () => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(toggleModal());

  const createConfirmModal = (
    title,
    contentText,
    confirmLabel,
    declineLabel,
    onConfirmClick,
    onDeclineClick,
    triggerRef
  ) => {
    return (
      <ConfirmModal
        title={title}
        contentText={contentText}
        confirmLabel={confirmLabel}
        declineLabel={declineLabel}
        onConfirmClick={onConfirmClick}
        onDeclineClick={onDeclineClick}
        triggerRef={triggerRef}
      />
    );
  };

  const openConfirmModal = ({
    title,
    contentText,
    confirmLabel,
    declineLabel,
    onConfirmClick,
    openTriggerRef
  }) => {
    const handleConfirm = () => {
      closeModal();
      onConfirmClick();
    };

    const handleDecline = () => {
      closeModal();
      openTriggerRef.current?.focus();
    };

    dispatch(
      toggleModal({
        hideClose: true,
        useGlobalModalContainer: true,
        className: "confirm-modal-container",
        component: () =>
          createConfirmModal(
            title,
            contentText,
            confirmLabel,
            declineLabel,
            handleConfirm,
            handleDecline,
            openTriggerRef
          )
      })
    );
  };

  return {
    openConfirmModal
  };
};
