import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Icon } from "../shared-components";
import { InfoTooltip } from "../shared-components/input/helpers/input-labels";
import { count } from "../helpers";

const getReadTimeText = (hours, mins) => {
  let readTimeString = "";
  if (hours === 0 && mins < 5) {
    readTimeString = "<5 mins";
  }
  if (hours > 0) {
    readTimeString = `${hours}h `;
  }
  if (hours > 0 || mins >= 5) {
    readTimeString += `${mins}m`;
  }

  return readTimeString;
};

const StatsItem = ({
  icon,
  label,
  size,
  viewBox,
  value,
  hoursVal,
  minutesVal,
  showTooltip
}) => {
  // TODO: Componentize value prop to account for the variance in ReadTime display
  return (
    <li className="stats-item">
      <div className="stats-label">
        <Icon
          className="stats-label__icon"
          name={icon}
          size={size}
          strokeWidth="0"
          fillColor="ds-neutral-80"
          viewBox={viewBox}
          aria-hidden
        />
        <span className="stats-label__text" aria-hidden>
          {label}
        </span>
      </div>
      {icon !== "readTime" ? (
        value !== null && (
          <>
            <span className="sr-only">
              {`${label} ${parseInt(value).toLocaleString()}`}
            </span>
            {InfoTooltip({
              value: count(value),
              tooltip: parseInt(value).toLocaleString(),
              showTooltip: showTooltip,
              ariaHidden: true
            })}
          </>
        )
      ) : (
        <>
          <span className="sr-only">
            {`${label} ${getReadTimeText(hoursVal, minutesVal)}`}
          </span>
          {InfoTooltip({
            value: getReadTimeText(hoursVal, minutesVal),
            tooltip: `${hoursVal} hours, ${minutesVal} minutes`,
            showTooltip: showTooltip,
            ariaHidden: true
          })}
        </>
      )}
    </li>
  );
};

StatsItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.number,
  hoursVal: PropTypes.number,
  minutesVal: PropTypes.number,
  showTooltip: PropTypes.bool
};

/**
 * The story stats.
 * Displays the number of reads, votes, parts and reading time.
 */
const StoryStats = ({ className, statsItems, showTooltip }) => {
  return (
    statsItems &&
    statsItems.length > 0 && (
      <ul className={classNames("new-story-stats", className)}>
        {statsItems.map(statsItem => {
          return (
            <StatsItem
              {...statsItem}
              key={`${statsItem.label}`}
              showTooltip={showTooltip}
            />
          );
        })}
      </ul>
    )
  );
};

StoryStats.defaultProps = {
  statsItems: []
};

StoryStats.propTypes = {
  className: PropTypes.string,
  statsItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.any,
      hoursVal: PropTypes.number,
      minutesVal: PropTypes.number
    })
  ),
  showTooltip: PropTypes.bool
};

export default StoryStats;
