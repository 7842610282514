"use strict";

var isServer = require("./is-server");

module.exports = function(utcDateTime, serverRequest) {
  let desiredLocale;
  let dateStringConfig = {
    month: "long",
    year: "numeric",
    weekday: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short"
  };
  let onServer = isServer();

  if (!utcDateTime) {
    return "";
  }

  if (onServer && serverRequest && serverRequest.cookies) {
    let localeCookie, tzCookie;
    localeCookie = serverRequest.cookies["locale"].replace("_", "-");
    tzCookie = serverRequest.cookies["X-Time-Zone"];

    if (localeCookie) {
      desiredLocale = localeCookie;
    }
    if (tzCookie) {
      dateStringConfig.timeZone = tzCookie;
    }
  } else if (!onServer) {
    desiredLocale = wattpad?.utils.getCookie("locale").replace("_", "-");
    dateStringConfig.timeZone =
      Intl && Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  let scheduledDate = new Date(utcDateTime);

  return scheduledDate.toLocaleDateString(desiredLocale, dateStringConfig);
};
