import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../shared-components";

/**
 * The report story card.
 */
const StoryRanksCard = ({ storyRankings, storyId }) => {
  if (!storyRankings || storyRankings.length === 0) {
    return null;
  }
  return (
    <a
      className={"card"}
      href={`/story/${storyId}/rankings`}
      aria-label={`Number ${storyRankings[0].rank} in ${
        storyRankings[0].name
      }. Tap for all tag rankings for this story.`}
    >
      <Icon
        className="card__action-icon"
        size="32"
        name="rankingBadge"
        fillColor="wp-base-1"
        viewBox="32"
        strokeWidth="0"
      />
      <div className="card__label">
        <span className={"card__title"}>
          {`#${storyRankings[0].rank}`}
          <span className={"title__name"}>{`${storyRankings[0].name}`}</span>
        </span>
      </div>
      <Icon className="card__nav-icon" name="chevRight" size="24" />
    </a>
  );
};

StoryRanksCard.propTypes = {
  storyRankings: PropTypes.array,
  storyId: PropTypes.number
};

export default StoryRanksCard;
