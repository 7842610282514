import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import classNames from "classnames";

// subcomponent to ReportModal
// will render if user tries to submit a report, but is not signed in
function ReportFormContactInfo({
  username,
  email,
  setPayloadUsername,
  setPayloadEmail,
  submitReport
}) {
  const { trans } = useTrans();

  const [validNameIcon, setValidNameIcon] = useState("pending");
  const [validEmailIcon, setValidEmailIcon] = useState("pending");
  const [contactInfoNameError, setContactInfoNameError] = useState("");
  const [contactInfoEmailError, setContactInfoEmailError] = useState("");
  // set to true so email error message doesn't appear immediately
  const [isValidEmail, setIsValidEmail] = useState(true);

  const onChangeContactInfo = event => {
    wattpad.utils.stopEvent(event);
    const userInput = event.target.value;

    if (event.target.name == "name") {
      setPayloadUsername(userInput);
    } else if (event.target.name == "email") {
      /* eslint-disable no-useless-escape */
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if email is valid after change, set isValidEmail to true to enable submit button
      if (regex.test(userInput) && userInput) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
      setPayloadEmail(userInput);
    }
    return;
  };

  const setErrorMessageAndIcon = (inputType, message, iconType) => {
    if (inputType == "name") {
      setContactInfoNameError(message);
      setValidNameIcon(iconType);
    } else {
      setContactInfoEmailError(message);
      setValidEmailIcon(iconType);
    }
    return;
  };

  const checkValidInput = event => {
    wattpad.utils.stopEvent(event);
    const userInput = event.target.value;
    const inputType = event.target.name;
    /* eslint-disable no-useless-escape */
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // empty field
    if (!userInput) {
      const message =
        inputType == "name"
          ? trans("Name is required.")
          : trans("Email is required.");
      setErrorMessageAndIcon(inputType, message, "warning");
    }
    // check if email format is valid
    else if (inputType == "email" && !regex.test(userInput)) {
      setErrorMessageAndIcon(
        inputType,
        trans("The email address is invalid."),
        "warning"
      );
    } else {
      setErrorMessageAndIcon(inputType, "", "check");
    }

    return;
  };

  const removeWarnings = event => {
    wattpad.utils.stopEvent(event);
    const inputField = event.target.name;

    if (inputField == "name") {
      setValidNameIcon("pending");
      setContactInfoNameError("");
    } else {
      setValidEmailIcon("pending");
      setContactInfoEmailError("");
    }
  };

  // should be either fa-check or fa-warning
  const nameIconType = classNames({
    "fa-warning": validNameIcon == "warning",
    "fa-check": validNameIcon != "warning"
  });
  const emailIconType = classNames({
    "fa-warning": validEmailIcon == "warning",
    "fa-check": validEmailIcon != "warning"
  });

  const renderReportFormContactInfo = () => {
    return (
      <>
        <div className="modal-body contact-info-body">
          <p>
            {trans("Please leave your contact information so that we may reach you.") /* prettier-ignore */}
          </p>
          <form>
            <ContactInfoInputField
              payloadInput={username}
              inputId="contact-name"
              inputName="name"
              placeholder={trans("Name")}
              onChange={onChangeContactInfo}
              onBlur={checkValidInput}
              onFocus={removeWarnings}
              validIcon={validNameIcon}
              iconClassName={nameIconType}
              contactInfoError={contactInfoNameError}
            />
            <ContactInfoInputField
              payloadInput={email}
              inputId="contact-email"
              inputName="email"
              placeholder={trans("Email")}
              onChange={onChangeContactInfo}
              onBlur={checkValidInput}
              onFocus={removeWarnings}
              validIcon={validEmailIcon}
              iconClassName={emailIconType}
              contactInfoError={contactInfoEmailError}
            />
          </form>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-orange"
            onClick={submitReport}
            disabled={!username || !email || !isValidEmail}
          >
            {trans("Submit Report")}
          </button>
        </div>
      </>
    );
  };

  return renderReportFormContactInfo();
}

function ContactInfoInputField({
  payloadInput,
  inputId,
  inputName,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  validIcon,
  iconClassName,
  contactInfoError
}) {
  // set input value (ie. payloadInput) to an empty string instead of null
  // to remove Warning: input value should not be null
  if (!payloadInput) {
    payloadInput = "";
  }
  return (
    <div className="input-container">
      <input
        id={inputId}
        className={classNames("contact-input", inputId)}
        name={inputName}
        value={payloadInput}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {validIcon != "pending" && (
        <span tabIndex="-1">
          <Icon
            iconName={iconClassName}
            height="18"
            className="form-control-feedback"
          />
        </span>
      )}
      {contactInfoError && (
        <p className="contact-error-message">{contactInfoError}</p>
      )}
    </div>
  );
}

ContactInfoInputField.propTypes = {
  payloadInput: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  validIcon: PropTypes.string,
  iconClassName: PropTypes.string,
  contactInfoError: PropTypes.string
};

ReportFormContactInfo.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string,
  setPayloadUsername: PropTypes.func,
  setPayloadUEmail: PropTypes.func,
  onClickPrev: PropTypes.func,
  submitReport: PropTypes.func
};

export default ReportFormContactInfo;
