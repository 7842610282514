import React from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";

import { useTrans } from "../hooks";
import Icon from "./Icon.js";
import TagItem from "../views/tags/TagItem";

/**
 * Displays paid story badge, number of parts, completed/ongoing pill and mature pill
 */
const StoryIconBar = ({
  numParts,
  isMature,
  isCompleted = false,
  isPaid = false,
  isPaidStory = true
}) => {
  const { trans, ngettext } = useTrans();

  const matureColor = "#9E1D42";
  const paidText = isPaid && isPaidStory ? trans("Wattpad Original") : "";
  const matureText = isMature ? trans("Mature") : "";
  const partsText = numParts
    ? sprintf(ngettext("%s part", "%s parts", numParts), numParts)
    : "";
  const completedColor = isCompleted ? "#1C6F65" : "#8D5610";
  const completedText = isCompleted ? trans("Complete") : trans("Ongoing");

  const srText = `${paidText} ${partsText} ${completedText} ${matureText}`;

  return (
    <>
      <span className="sr-only">{srText}</span>
      <div className="icon-bar" aria-hidden>
        {numParts && (
          <div className="icon-parts">
            <Icon name="parts" className="icon" />
            <span>{partsText}</span>
          </div>
        )}
        {
          <div className="completed">
            <TagItem
              name={completedText}
              bgColor={completedColor}
              color="#ffffff"
              disableClick
            />
          </div>
        }
        {isMature && (
          <div className="mature">
            <TagItem
              name={matureText}
              bgColor={matureColor}
              color="#ffffff"
              disableClick
            />
          </div>
        )}
      </div>
    </>
  );
};

StoryIconBar.propTypes = {
  numParts: PropTypes.number,
  isMature: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isPaid: PropTypes.bool,
  isPaidStory: PropTypes.bool
};

export default StoryIconBar;
