import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import {
  resizeCover,
  getReadTime,
  formatReadTime,
  getCoverSize
} from "../helpers";
import { isClient } from "../helpers/isClient";
import { useTrans } from "../hooks";
import StoryIconBar from "./StoryIconBar";
import StoryStats from "./StoryStats";
import isPaidStoryHelper from "../../helpers/is-paid-story";

export default function StoryCard({
  id,
  title,
  cover,
  description,
  completed,
  isPaywalled,
  paidModel,
  mature,
  readCount,
  voteCount,
  numParts,
  length,
  language,
  searchTerm,
  index,
  sources,
  viewedStories,
  pushViewEvent,
  isMobile: isMobileDevice
}) {
  const { trans } = useTrans();
  const isMobile = isClient() && window.innerWidth < 575;
  const isPaidStory = isPaidStoryHelper({ paidModel });

  const mobileStoryCoverSize = 100;
  const desktopStoryCoverSize = 144;

  const [hasViewed, setHasViewed] = useState(false);
  const storyCardRef = useRef();
  const viewRef = useRef(false);

  const statItemSize = isMobile ? "14" : "16";
  const statItemViewBox = "16";
  const statsItems = [
    {
      icon: "views",
      label: trans("Reads"),
      size: statItemSize,
      viewBox: statItemViewBox,
      value: readCount
    },
    {
      icon: "votesBeta",
      label: trans("Votes"),
      size: statItemSize,
      viewBox: statItemViewBox,
      value: voteCount
    },
    {
      icon: "partsBeta",
      label: trans("Parts"),
      size: statItemSize,
      viewBox: statItemViewBox,
      value: numParts
    }
  ];

  // readTime is a best guess that currently only supports english stories.
  // Gate off displaying this StatsItem for other languages.
  if (language === 1) {
    const readTime = formatReadTime(getReadTime(length));
    statsItems.push({
      icon: "readTime",
      label: trans("Time"),
      size: statItemSize,
      viewBox: statItemViewBox,
      hoursVal: readTime.hours,
      minutesVal: readTime.minutes
    });
  }

  useEffect(
    () => {
      if (
        isMobileDevice ||
        hasViewed ||
        viewedStories.includes(id) ||
        viewRef.current === true
      ) {
        return;
      }

      const scrollListener = _.throttle(() => {
        if (storyCardRef.current) {
          let rect = storyCardRef.current.getBoundingClientRect();
          let elemTop = rect.top;
          let elemBottom = rect.bottom;
          let elemVisibleHeight = rect.height * 0.5;

          let isVisible =
            storyCardRef.current.offsetParent &&
            ((elemTop >= 0 && elemBottom <= window.innerHeight) ||
              (elemTop <= 0 &&
                elemBottom > 0 &&
                elemBottom >= elemVisibleHeight) ||
              (elemBottom >= window.innerHeight &&
                elemTop < window.innerHeight &&
                window.innerHeight - elemTop >= elemVisibleHeight));

          if (isVisible) {
            setHasViewed(true);
            // hack to ensure multiple view events are not being sent for the same story.
            viewRef.current = true;
            pushViewEvent(id, index, sources);
          }
        }
      }, 300);
      // to add initial stories in viewport
      scrollListener();

      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    },
    [
      hasViewed,
      id,
      index,
      isMobileDevice,
      pushViewEvent,
      searchTerm,
      sources,
      viewedStories
    ]
  );

  const onStoryCardClick = evt => {
    const link = `/story/${id}`;
    evt.preventDefault();
    // keep old click event as it informs some algorithms
    window.te.push("event", "search", "story", null, "click", {
      storyid: id,
      search: searchTerm,
      position: index
    });
    // new click event
    window.te.push("event", "story", null, null, "click", {
      storyid: id,
      page: "search",
      position: index,
      query: [searchTerm],
      algo_source: sources
    });
    app.router.navigate(link, {
      trigger: true
    });
  };

  // Returns the common JSX for MW and DW
  const getStoryData = (includeDescription = true, coverSize) => {
    return (
      <>
        <div className="cover">
          <img src={resizeCover(cover, getCoverSize(coverSize))} alt="" />
        </div>
        <div className="story-info">
          <span className="sr-only">{title}</span>
          <div className="title" aria-hidden="true">
            {title}
          </div>
          <StoryIconBar
            isPaid={isPaywalled}
            isPaidStory={isPaidStory}
            isCompleted={completed}
            isMature={mature}
          />
          <StoryStats statsItems={statsItems} showTooltip={false} />
          {includeDescription && (
            <div className="description">{description}</div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <a
        className="story-card"
        onClick={evt => onStoryCardClick(evt)}
        href={`/story/${id}`}
        ref={storyCardRef}
      >
        <div className="story-card-data shown-xxs" data-id={id}>
          <div className="top-section">
            {getStoryData(false, mobileStoryCoverSize)}
          </div>
          <div className="description">{description}</div>
        </div>
        <div className="story-card-data hidden-xxs" data-id={id}>
          {getStoryData(true, desktopStoryCoverSize)}
        </div>
      </a>
    </>
  );
}

StoryCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  cover: PropTypes.string,
  description: PropTypes.string,
  completed: PropTypes.bool,
  isPaywalled: PropTypes.bool,
  paidModel: PropTypes.string,
  mature: PropTypes.bool,
  readCount: PropTypes.number,
  voteCount: PropTypes.number,
  numParts: PropTypes.number,
  length: PropTypes.number,
  language: PropTypes.number,
  searchTerm: PropTypes.string,
  index: PropTypes.number,
  sources: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  viewedStories: PropTypes.array,
  pushViewEvent: PropTypes.func,
  isMobile: PropTypes.bool
};
