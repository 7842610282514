import React, { useEffect, useState } from "react";
import { useTrans, useEventListener } from "../../hooks";
import PropTypes from "prop-types";
import { getStorageItem } from "../../helpers";

let window = window || global;

// This button will allow users to create covers using Canva
// https://about.canva.com/button/documentation/
// https://www.canva.com/button/documentation/js-api/

function CanvaButton({ createCanvaCover, canvaApiKey, story }) {
  const { trans } = useTrans();
  const [overrideStory, setOverrideStory] = useState(null);

  useEventListener(
    "myworks:cover:saved",
    event => {
      setOverrideStory(event.detail);
    },
    window
  );
  useEffect(() => {
    const id = "canva-script";

    if (!document.getElementById(id)) {
      let s, w;
      w = document.createElement("script");
      w.id = id;
      s = document.getElementsByTagName("script")[0];
      w.src = "https://sdk.canva.com/v2/beta/api.js";
      s.parentNode.insertBefore(w, s);
    }
  }, []);

  // Get canva id from either the server or stored in the user's cookies
  const getCanvaId = () => {
    let designId = null;
    if (story.id) {
      if (overrideStory) {
        // This override story is to refresh the updated cover_source_id when a new cover is uploaded
        if (
          overrideStory.cover_partner === "canva" &&
          overrideStory.cover_source_id
        ) {
          designId = overrideStory.cover_source_id;
        }
      } else {
        if (story.cover_partner === "canva" && story.cover_source_id) {
          designId = story.cover_source_id;
        } else {
          designId = getStorageItem({ key: `canva-cover-${story.id}` });
        }
      }
    }
    return designId;
  };

  function onDesignPublish(options) {
    // Triggered when design is published to an image.
    // Save the image to your server as the exportUrl will expire shortly.
    // options: exportUrl, designId
    createCanvaCover(options);
  }

  // Open canva editor
  async function createDesign() {
    if (!window.CanvaButton) {
      return; // Script is not loaded from above
    }

    if (!window.canvaApi) {
      window.canvaApi = await window.CanvaButton.initialize({
        apiKey: canvaApiKey
      });
    }

    window.canvaApi.createDesign({
      type: "WattpadBookCover",
      publishLabel: trans("Use Cover"),
      // onDesignOpen: (options) => {
      // Triggered when editor finishes loading and opens a new design.
      // You can save designId for future use.
      // options: designId
      // },
      onDesignPublish
    });

    const canvaId = getCanvaId();
    if (canvaId) {
      window.canvaApi.editDesign({
        designId: canvaId,
        publishLabel: trans("Update Cover"),
        onDesignPublish
      });
    }
  }
  return (
    <button className="canva-btn canva-btn-size-s" onClick={createDesign}>
      <span className="canva-btn-i" />
      {getCanvaId() ? trans("Edit Cover") : trans("Create Cover")}
    </button>
  );
}

CanvaButton.propTypes = {
  createCanvaCover: PropTypes.func,
  canvaApiKey: PropTypes.string.isRequired,
  story: PropTypes.object
};

export { CanvaButton };
