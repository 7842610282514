import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../hooks";
import { Button, ButtonSize, IconNames } from "@wattpad/web-ui-library";
import PublishModal, { PublishModalProps } from "./PublishModal";
import publishPartModal from "../../../helpers/publishing/client.publish-part-modal";

export const PublishButtonProps = {
  ...PublishModalProps,
  storyModel: PropTypes.object,
  isPreview: PropTypes.bool
};

const PublishButton = props => {
  const { trans } = useTrans();

  const handlePublish = () => {
    publishPartModal(props.storyModel, props.partModel, !!props.isPreview);
  };

  return !props.partModel ? (
    <Button size={ButtonSize.SMALL} isLoading={true} />
  ) : (
    <>
      {props.partModel?.attributes?.scheduledPublishDatetime ? (
        <Button
          onClick={handlePublish}
          size={ButtonSize.SMALL}
          leadingIcon={IconNames.WpCalendar}
          label={trans("Change schedule")}
        />
      ) : (
        <Button
          onClick={handlePublish}
          size={ButtonSize.SMALL}
          label={trans("Publish")}
        />
      )}
      <PublishModal {...props} />
    </>
  );
};

PublishButton.propTypes = PublishButtonProps;

export default PublishButton;
