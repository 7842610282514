import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ContinueReadingSlide } from "@wattpad/web-ui-library";

import {
  formatStoryDataforStorySlides,
  sizeStoryCover,
  slideSubLabel,
  storyIsInView
} from "./ContinueReadingSwimlaneUtils";
import SectionHeader from "../../components/SectionHeader";
import { useTrans } from "../../../../hooks";
import { Icon } from "../../../../shared-components";
import { toPostedDate } from "../../../comments/CommentsUtils";
import getDynamicCarouselData from "./client.get-dynamic-carousel-data";

const STANDARD_CAROUSEL_WIDTH = 1000;

const ContinueReadingSwimlane = ({
  heading,
  subheading,
  data,
  windowWidth,
  index,
  sectionType,
  setPosition,
  wasSeen,
  isMobile,
  url,
  isRTL
}) => {
  const { trans, ngettext } = useTrans();
  const el = useRef(null);
  const MAX_SLIDES_SHOWN = 6;
  let backBtnRef = useRef();

  let SCROLLING_WIDTH =
    windowWidth > STANDARD_CAROUSEL_WIDTH
      ? STANDARD_CAROUSEL_WIDTH
      : windowWidth;

  const [slidesIndex, setSlidesIndex] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [storiesSeen, setStoriesSeen] = useState([]);
  const slideRefs = useRef({});

  const [slides, setSlides] = useState([]);
  const [totalSlidesWidth, setTotalSlidesWidth] = useState([]);
  const [slideStartIndices, setSlideStartIndices] = useState([]);

  useEffect(
    () => {
      getDynamicCarouselData(url)
        .then(data => {
          let formatSlides = formatStoryDataforStorySlides(
            data,
            ngettext,
            isMobile && windowWidth <= 425
          );
          setSlides(formatSlides.slides);
          setTotalSlidesWidth(formatSlides.totalSlidesWidth);
          setSlideStartIndices(formatSlides.slideStartIndices);
          return;
        })
        .catch(err => {
          let formatSlides = formatStoryDataforStorySlides(
            data,
            ngettext,
            isMobile && windowWidth <= 425
          );
          setSlides(formatSlides.slides);
          setTotalSlidesWidth(formatSlides.totalSlidesWidth);
          setSlideStartIndices(formatSlides.slideStartIndices);

          /* eslint no-console: off */
          console.error("CONTINUE READING ERROR: ", err);
          return;
        });
    },
    [url, ngettext, data, isMobile, windowWidth]
  );

  //swipe events
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = e => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  function handleTouchEnd() {
    if (touchStart - touchEnd > 150) {
      nextButtonOnClick();
    }

    if (touchStart - touchEnd < -150 && slidesIndex > 0) {
      backButtonOnClick();
    }
  }

  const pushStoryViewEvent = (storyid, offset) => {
    window.te.push("event", "story", null, null, "view", {
      storyid,
      page: "home",
      algo_source: ["storiesRecommendedRNN"],
      position: index,
      offset,
      type: "continueReading"
    });
  };

  useEffect(
    () => {
      if (totalSlidesWidth <= slidesIndex + SCROLLING_WIDTH) {
        setShowNextButton(false);
      } else {
        setShowNextButton(true);
      }
    },
    [slidesIndex, totalSlidesWidth, SCROLLING_WIDTH]
  );

  useEffect(
    () => {
      if (showBackButton) {
        backBtnRef.current?.focus();
      }
    },
    [showBackButton]
  );

  useEffect(
    () => {
      if (!el.current) return;

      const boundingBox = el.current.getBoundingClientRect();
      setPosition(
        index,
        sectionType,
        boundingBox.top + window.scrollY,
        boundingBox.bottom + window.scrollY
      );
    },
    [index, sectionType, setPosition]
  );

  useEffect(
    () => {
      if (wasSeen) {
        const storiesToPush = [];
        slides.slice(0, MAX_SLIDES_SHOWN).map((story, i) => {
          if (
            !storiesSeen.includes(story.id) &&
            storyIsInView(slideRefs.current[story.id])
          ) {
            pushStoryViewEvent(story.id, i);
            storiesToPush.push(story.id);
          }
        });
        setStoriesSeen([...storiesSeen, ...storiesToPush]);
      }
    },
    [wasSeen, slides] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const backButtonOnClick = () => {
    let index = 0;
    //find the first slide of the last set
    for (let i = 0; i < slideStartIndices.length - 2; i++) {
      if (
        slideStartIndices[i] <= slidesIndex - SCROLLING_WIDTH &&
        slideStartIndices[i + 1] > slidesIndex - SCROLLING_WIDTH
      ) {
        index = i + 1;
      }
    }
    setSlidesIndex(slideStartIndices[index]);

    if (slidesIndex - SCROLLING_WIDTH <= 0) {
      setShowBackButton(false);
    }
    setShowNextButton(true);
  };

  const nextButtonOnClick = () => {
    setShowBackButton(true);
    //find the first slide of the next set
    if (totalSlidesWidth >= slidesIndex + SCROLLING_WIDTH) {
      let index = slideStartIndices.length - 1;
      for (let i = 0; i < slideStartIndices.length - 2; i++) {
        if (
          slideStartIndices[i] <= slidesIndex + SCROLLING_WIDTH &&
          slideStartIndices[i + 1] > slidesIndex + SCROLLING_WIDTH
        ) {
          index = i;
        }
      }
      setSlidesIndex(slideStartIndices[index]);
    }
    backBtnRef.current?.focus();
  };

  const storyOnClick = storyData => () => {
    window.te.push("event", "story", null, null, "click", {
      storyid: storyData.id,
      page: "home",
      algo_source: ["storiesRecommendedRNN"],
      position: index,
      type: "continueReading"
    });
  };

  const handleTransitionEnd = () => {
    const storiesSeenToPush = [];
    const currentSlideIndex = storiesSeen.length;

    slides
      .slice(currentSlideIndex, currentSlideIndex + MAX_SLIDES_SHOWN)
      .map((story, i) => {
        if (
          !storiesSeen.includes(story.id) &&
          storyIsInView(slideRefs.current[story.id])
        ) {
          pushStoryViewEvent(story.id, currentSlideIndex + i);
          storiesSeenToPush.push(story.id);
        }
      });
    setStoriesSeen([...storiesSeen, ...storiesSeenToPush]);
  };

  return (
    <div className="continue-reading module" ref={el}>
      <div className="module-content full-width">
        <SectionHeader heading={heading} subheading={subheading} />
        <div
          className="carousel-container"
          onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
          onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
          onTouchEnd={() => handleTouchEnd()}
        >
          <div className="btn-container">
            {showBackButton && (
              <button
                ref={backBtnRef}
                className="back-btn"
                onClick={backButtonOnClick}
                aria-label={trans("Back")}
              >
                <Icon
                  name={isRTL ? "arrowCircleRight" : "arrowCircleLeft"}
                  size="40"
                  alt="back"
                  strokeWidth="2.25"
                />
              </button>
            )}
          </div>
          <div
            className="continue-reading-carousel"
            style={{
              transform: isRTL
                ? `translateX(+${slidesIndex}px)`
                : `translateX(-${slidesIndex}px)`
            }}
            onTransitionEnd={handleTransitionEnd}
          >
            {slides.map((storyData, i) => {
              if (storyData.id && storyData.cover)
                return (
                  <div
                    className={classNames({
                      "one-slide-section": storyData.oneSlideSection
                    })}
                    key={`${storyData.id}-${i}`}
                    ref={element => (slideRefs.current[storyData.id] = element)}
                  >
                    <ContinueReadingSlide
                      firstSlide={storyData.type === "currentRead"}
                      key={`${storyData.id}-${i}`}
                      id={storyData.id}
                      storyName={storyData.title}
                      primaryLabel={storyData.primary_label}
                      secondaryLabel={storyData.secondary_label}
                      cover={sizeStoryCover(
                        windowWidth,
                        storyData.type,
                        storyData.cover
                      )}
                      totalParts={storyData.total_parts}
                      current_part_index={storyData.current_part.number}
                      current_part_id={storyData.current_part.id}
                      newParts={storyData.new_parts}
                      progressBarAccessibility={
                        storyData.progressBarAccessibility
                      }
                      lastPubDate={toPostedDate(
                        trans,
                        ngettext,
                        storyData.last_pub_date
                      )}
                      leadingDivider={storyData.leading_divider}
                      subLabel={slideSubLabel(storyData, ngettext)}
                      topMargin={storyData.type !== "currentRead"}
                      onClick={storyOnClick(storyData)}
                    />
                  </div>
                );
            })}
          </div>
          <div className="btn-container">
            {showNextButton && (
              <button
                className="next-btn"
                onClick={nextButtonOnClick}
                aria-label={trans("Next")}
              >
                <Icon
                  name={isRTL ? "arrowCircleLeft" : "arrowCircleRight"}
                  size="40"
                  alt="next"
                  strokeWidth="2.25"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueReadingSwimlane;

ContinueReadingSwimlane.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  data: PropTypes.array,
  windowWidth: PropTypes.number,
  index: PropTypes.number,
  wasSeen: PropTypes.bool,
  sectionType: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  page: PropTypes.string,
  isMobile: PropTypes.bool,
  url: PropTypes.string,
  isRTL: PropTypes.bool
};
