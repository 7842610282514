import PropTypes from "prop-types";
import React from "react";
import Icon from "../../../shared-components/Icon";

import { useTrans } from "../../../hooks";
import { ConnectImage } from "../../../shared-components";

function ContestFormHeader({ subtitle, closeModal, isStandalonePage }) {
  const { trans } = useTrans();
  return (
    <div className="form-header">
      {!isStandalonePage && (
        <button
          className="close-btn"
          onClick={closeModal}
          aria-label={trans("Close modal")}
        >
          <Icon name="close" size="24" color="wp-neutral-5" />
        </button>
      )}
      <div className="centered-text">
        <ConnectImage
          name="/contest-form/the-wattys-2024-logo.svg"
          className="wattys-logo"
        />
        <div className="subtitle bold">{subtitle}</div>
      </div>
    </div>
  );
}

ContestFormHeader.propTypes = {
  subtitle: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  isStandalonePage: PropTypes.bool.isRequired
};

export { ContestFormHeader };
