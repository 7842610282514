import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { sprintf } from "sprintf-js";
import {
  count,
  resizeAvatar,
  getReadTimeByWordCount,
  formatReadTime
} from "../helpers";
import { InfoTooltip } from "../shared-components/input/helpers/input-labels";
import { useTrans } from "../hooks";

import { BONUS_CHAPTER_TYPE } from "../constants";
import StoryStats from "./StoryStats";
import { Icon } from "../shared-components";

/**
 * Bonus chapter header.
 * For Exclusive Chapters and Writer Reveals
 * Displays part reading time and number of comments.
 */
const StoryPartBonusHeader = ({
  className,
  currentPartIsBlocked,
  group,
  part,
  title,
  draft,
  readCount,
  voteCount
  // trans
}) => {
  const { ngettext, trans } = useTrans();

  if (!part) return null;

  const {
    bonusType,
    bonusTypeName,
    authorNote,
    chapterDescription,
    wordCount,
    commentCount
  } = part;

  const formattedWordCount = formatReadTime(getReadTimeByWordCount(wordCount));

  return (
    <div className="bonus-part-header container">
      <div className="row part-header">
        <div className="col-sm-12 col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2">
          <header
            className={classNames(
              className,
              "panel panel-bonus-header text-center"
            )}
          >
            {!isNaN(bonusType) && (
              <div className="bonus-type">
                {bonusType === BONUS_CHAPTER_TYPE.EXCLUSIVE_CHAPTER && (
                  <Icon
                    className="stats-label__icon exclusive-chapter-icon"
                    name="exclusiveChapter"
                    size={22}
                    color="ds-base-2-60"
                    fillColor="ds-base-2-60"
                    viewBox={22}
                  />
                )}
                {bonusType === BONUS_CHAPTER_TYPE.WRITER_REVEAL && (
                  <Icon
                    className="stats-label__icon writer-reveal-icon"
                    name="writerReveal"
                    size={22}
                    color="ds-base-2-60"
                    viewBox={22}
                  />
                )}
                {bonusTypeName}
              </div>
            )}

            <h1 className="h2 title">
              {title}
              {draft && <span className="draft-tag"> ({trans("Draft")})</span>}
            </h1>

            {!draft &&
              chapterDescription && (
                <div className="chapter-description">{chapterDescription}</div>
              )}

            {!draft &&
              currentPartIsBlocked && (
                <div className="bonus-part-stats">
                  <StoryStats
                    statsItems={[
                      {
                        icon: "readTime",
                        label: trans("Time"),
                        size: 16,
                        viewBox: 16,
                        hoursVal: formattedWordCount.hours,
                        minutesVal: formattedWordCount.minutes
                      },
                      {
                        icon: "commentStats",
                        label: trans("Comments"),
                        size: 16,
                        viewBox: 16,
                        value: commentCount
                      }
                    ]}
                    className={"hidden-xxs"}
                    showTooltip
                  />
                </div>
              )}

            {!draft &&
              !currentPartIsBlocked && (
                <div className="story-stats">
                  <span className="stat-item reads">
                    <span className="fa fa-view fa-wp-neutral-2" />
                    {InfoTooltip({
                      value: count(readCount),
                      tooltip: sprintf(
                        ngettext(
                          "%s Read",
                          "%s Reads",
                          parseInt(readCount).toLocaleString()
                        ),
                        parseInt(readCount).toLocaleString()
                      ),
                      ariaHidden: true
                    })}
                  </span>

                  <span className="stat-item votes">
                    <span className="fa fa-vote fa-wp-neutral-2" />
                    {InfoTooltip({
                      value: count(voteCount),
                      tooltip: sprintf(
                        ngettext(
                          "%s Vote",
                          "%s Votes",
                          parseInt(voteCount).toLocaleString()
                        ),
                        parseInt(voteCount).toLocaleString()
                      ),
                      ariaHidden: true
                    })}
                  </span>

                  <span className="stat-item comments on-comments">
                    <span className="fa fa-comment fa-wp-neutral-2" />
                    <button type="button" className="btn-link">
                      {InfoTooltip({
                        value: commentCount,
                        tooltip: sprintf(
                          ngettext("%s Comment", "%s Comments", commentCount),
                          commentCount
                        ),
                        ariaHidden: true
                      })}
                    </button>
                  </span>
                </div>
              )}

            {!draft &&
              authorNote &&
              bonusType === BONUS_CHAPTER_TYPE.EXCLUSIVE_CHAPTER && (
                <div className="author-note">
                  <div className="author-header">
                    {trans("Author's Note")}
                    <div className="author">
                      <img
                        className="author-avatar on-hover"
                        data-toggle="tooltip"
                        data-placement="right"
                        title={`Writer: ${group.user.username}`}
                        alt={`Writer: ${group.user.username}`}
                        src={resizeAvatar(group.user.avatar, 16)}
                      />

                      {group.user.username}
                    </div>
                  </div>
                  <div className="note">{authorNote}</div>
                </div>
              )}
            <div className={classNames("divider", { "is-draft": draft })} />
          </header>
        </div>
      </div>
    </div>
  );
};

StoryPartBonusHeader.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  currentPartIsBlocked: PropTypes.bool,
  group: PropTypes.shape({
    user: PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    })
  }),
  part: PropTypes.shape({
    bonusType: PropTypes.number,
    bonusTypeName: PropTypes.string,
    authorNote: PropTypes.string,
    chapterDescription: PropTypes.string,
    wordCount: PropTypes.number,
    commentCount: PropTypes.number
  }),
  title: PropTypes.string,
  draft: PropTypes.bool,
  readCount: PropTypes.number,
  voteCount: PropTypes.number
};

export default StoryPartBonusHeader;
