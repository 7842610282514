import React from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";

import { useTrans } from "../../hooks";
import InfoButton from "./InfoButton";

const WriterStoryStats = ({
  story: { readCount = null, voteCount = null, numParts = null }
}) => {
  const { ngettext, trans } = useTrans();

  return (
    <ul className="stats-list">
      {readCount !== null && (
        <li className="h5 readcount stats-list__item">
          {sprintf(
            ngettext("%s Total Read", "%s Total Reads", readCount),
            readCount
          )}
          <InfoButton
            popoverContent={{
              title: trans("What is Total Reads?"),
              content: trans(
                "The total number of times your story parts have been viewed or read to any extent since they were published (all-time)."
              )
            }}
            color="wp-neutral-5"
            className="stats-list-read__info"
          />
        </li>
      )}
      {voteCount !== null && (
        <li className="h5 votecount stats-list__item">
          {sprintf(ngettext("%s Vote", "%s Votes", voteCount), voteCount)}
        </li>
      )}
      {numParts !== null && (
        <li className="h5 numparts stats-list__item">
          {sprintf(
            ngettext("%s Story Part", "%s Story Parts", numParts),
            numParts
          )}
        </li>
      )}
    </ul>
  );
};

WriterStoryStats.propTypes = {
  story: PropTypes.shape({
    readCount: PropTypes.number,
    voteCount: PropTypes.number,
    numParts: PropTypes.number
  }).isRequired
};

export default WriterStoryStats;
