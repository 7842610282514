import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ConnectImage from "./ConnectImage";

function Loader() {
  return (
    <ConnectImage
      name="/icons/spinner-black.svg"
      className="fa-spin"
      alt="Loading spinner"
      height="40"
      width="40"
    />
  );
}

/* This loader should be used for cases when a request ends too fast and showing a loader
  would look like a glitch. The spinner will start showing after a delay.
*/
function AsyncLoader({
  isLoading,
  className,
  children,
  customLoader = <Loader />,
  showLoaderAfter
}) {
  const [showLoader, setShowLoader] = useState(false);
  const timer = useRef(null);

  useEffect(
    () => {
      if (isLoading) {
        timer.current = setTimeout(() => {
          // After the wait timer, we start showing the loader
          !showLoader && setShowLoader(true);
        }, showLoaderAfter);
      } else {
        // Finished loading before timer ends. Let's clear the timer.
        if (timer.current) {
          clearTimeout(timer.current);
        }
        showLoader && setShowLoader(false);
      }
    },
    [isLoading, showLoader, showLoaderAfter]
  );

  const loader = showLoader && <div className={className}>{customLoader}</div>;
  return isLoading ? loader : children();
}

AsyncLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  customLoader: PropTypes.any,
  showLoaderAfter: PropTypes.number
};
AsyncLoader.defaultProps = {
  showLoaderAfter: 500
};

export { Loader, AsyncLoader };
