import PropTypes from "prop-types";
import React from "react";

import { sanitizeHTML } from "../../helpers";

class FormField extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.type === "checkbox") {
      this.state = {
        checked: this.props.value
      };
    }
  }

  onCheckboxChange = () => {
    let checked = !this.state.checked;
    this.setState({ checked });
    this.props.onCheckboxChange(this.props.name, checked);
  };

  render() {
    const { label, value, name, type, icon, children } = this.props;
    return (
      <div>
        <div className="col-xs-6 account-label">
          <label htmlFor={name} className="profile-label">
            {label}
          </label>
          {icon}
        </div>
        <div className="col-xs-6 account-value">
          {type === "text" && (
            <input
              type="text"
              name={name}
              id={name}
              defaultValue={sanitizeHTML(value)}
            />
          )}
          {type === "checkbox" && (
            <input
              type="checkbox"
              name={name}
              id={name}
              value={this.state.checked}
              defaultChecked={this.state.checked}
              onChange={this.onCheckboxChange}
            />
          )}
          {children}
        </div>
      </div>
    );
  }
}

FormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onCheckboxChange: PropTypes.func
};

export default FormField;
