import { useTrans } from "../../../hooks";

export const STORY_COPYRIGHT_LICENSES = () => {
  const { trans } = useTrans();

  return [
    {
      name: trans("All Rights Reserved"),
      summary: trans(
        "You do not allow your work to be used or adapted in any way without your permission."
      ),
      value: "1"
    },
    {
      name: trans("Public Domain"),
      summary: trans(
        "This allows anyone to use your story for any purpose — they could freely print, sell, or turn your story into a movie."
      ),
      value: "2"
    },
    {
      name: trans("Creative Commons (CC) Attribution"),
      summary: trans(
        "You hold some rights to your story but allow for things like translation or adaptations with credit back to you."
      ),
      value: "3"
    },
    {
      name: trans("(CC) Attrib. NonCommercial"),
      summary: trans(
        "This license lets others remix, adapt, and build upon your work non-commercially with credit back to you."
      ),
      value: "4"
    },
    {
      name: trans("(CC) Attrib. NonComm. NoDerivs"),
      value: "5",
      summary: trans(
        "This license allows others to share your unaltered work with others as long as you get credit."
      )
    },
    {
      name: trans("(CC) Attrib. NonComm. ShareAlike"),
      value: "6",
      summary: trans(
        "This lets others adapt, and build upon your work non-commercially, as long as you are credited and the same terms are applied."
      )
    },
    {
      name: trans("(CC) Attribution-ShareAlike"),
      value: "7",
      summary: trans(
        "This lets others adapt and build on your work, even for commercial purposes, as long as you are credited and the same terms are applied."
      )
    },
    {
      name: trans("(CC) Attribution-NoDerivs"),
      value: "8",
      summary: trans(
        "This lets others reuse your work for any purpose but it cannot be altered and you must be credited."
      )
    }
  ];
};

export const STORY_POPOVER_INFO = () => {
  const { trans } = useTrans();

  return {
    copyright: {
      title: trans("Who owns your story?"),
      mainContent: trans(
        "Let others know if this story is just yours, or if anyone can repost or remix it"
      ),
      link: {
        href:
          "https://support.wattpad.com/hc/en-us/articles/216192503-Copyright-FAQ",
        text: trans("Learn more")
      },
      button: {
        text: trans("OK")
      }
    }
  };
};
