import PropTypes from "prop-types";
import React, { useState } from "react";

import { ConnectImage } from "../../shared-components";
import { useTrans } from "../../hooks";
import classnames from "classnames";

const Spinner = () => {
  const { trans } = useTrans();
  return (
    <div className="col-xs-12">
      <ConnectImage
        name="/icons/spin/fa-loading.gif"
        className="fa-spin spinner"
        alt={trans("Spinner")}
        height="48"
        width="48"
      />
    </div>
  );
};

const SuccessPage = ({ isIOS, isAndroid }) => {
  const { trans } = useTrans();
  const imageUrl = "image-moderation/appeal-success.svg";

  const headerText = <p>{trans("Thank you, we've received your appeal")}</p>;

  const infoText = trans(
    "We ask that you leave your image in your story until we’ve processed your request."
  );

  // Only consider android devices for now when rendering deeplink. Changes need to be made
  // on the iOS client to allow deeplinks to work.
  // android uses deeplinking, while the iOS app makes use of url detection in the webview.
  const useDeeplink = isAndroid;
  const link = isIOS ? "/inbox/Wattpad?success=true" : "/inbox/Wattpad";
  const okLink = useDeeplink ? "wattpad://home" : link;

  const linkClass = classnames("btn btn-md btn-orange", {
    "on-navigate": useDeeplink
  });

  return (
    <div className="col-xs-12 submission-success">
      <ConnectImage name={imageUrl} className="success-image" />
      <span className="success-header">{headerText}</span>
      <span className="success-info">{infoText}</span>
      <a href={okLink} className={linkClass}>
        {trans("OK, got it")}
      </a>
    </div>
  );
};

const ImageAppealFlow = ({ imageUrl, onSubmissionError, isIOS, isAndroid }) => {
  const { trans } = useTrans();

  let [waiting, setWaiting] = useState(false);
  let [appealSubmitted, setAppealSubmitted] = useState(false);

  const handleError = serverErrorStatus => {
    let errorMessage;

    switch (serverErrorStatus) {
      case "submitted":
        errorMessage = trans(
          "This image has already been appealed. Once a decision has been made, we will notify you."
        );
        break;
      case "resolved":
        errorMessage = trans(
          "Sorry, this image has already been reviewed by our team and cannot be appealed again."
        );
        break;
      default:
        errorMessage = trans(
          "Sorry, an error has occurred and we’re unable to submit your appeal. Please try again later."
        );
        break;
    }
    onSubmissionError(errorMessage);
  };

  const handleSubmit = () => {
    setWaiting(true);

    return Promise.resolve(
      $.ajax({
        type: "POST",
        url: "/v5/image_moderation/appeal",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({ image_url: imageUrl })
      })
    )
      .then(() => {
        setWaiting(false);
        setAppealSubmitted(true);
      })
      .catch(err => {
        setWaiting(false);
        setAppealSubmitted(false);
        handleError(err.responseJSON.status);
      });
  };

  return (
    <div className="image-appeal-flow">
      <div className="container">
        <div className="row">
          {!waiting &&
            !appealSubmitted && (
              <div>
                <div className="col-xs-12">
                  <h1>{trans("Appeal form")}</h1>
                  <span
                    className="form-info"
                    dangerouslySetInnerHTML={{
                      /* prettier-ignore */ __html: trans('This image has been flagged because it appears to violate our <a href="https://policies.wattpad.com/content" target=_blank class="on-click">%s</a>. Your readers will not be able to see this image, and there will not be an image in its place.', "Content Guidelines")
                    }}
                  />
                  <span className="form-info">
                    {trans(
                      "If you think your image was mistakenly flagged, please describe why in the space below."
                    )}
                  </span>
                </div>
                <AppealReasonForm
                  handleSubmit={handleSubmit}
                  imageUrl={imageUrl}
                />
              </div>
            )}
          {!waiting &&
            appealSubmitted && (
              <SuccessPage isIOS={isIOS} isAndroid={isAndroid} />
            )}
          {waiting && <Spinner />}
        </div>
      </div>
    </div>
  );
};

const AppealReasonForm = ({ handleSubmit, imageUrl }) => {
  const { trans } = useTrans();

  let [wordCount, setWordCount] = useState(0);
  const updateWordCount = event => {
    setWordCount(event.target.value.length);
  };

  return (
    <form
      id="image-appeal"
      name="image-appeal"
      className="col-xs-12"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="row">
        <div className="form-reason">
          <textarea
            name="reason"
            id="reason"
            rows="7"
            placeholder="Tell us why"
            maxLength="435"
            onKeyUp={updateWordCount}
          />
          <span className="image-info col-xs-12">
            <div className="image-container">
              <img src={imageUrl} alt={imageUrl} />
            </div>
            <a href={imageUrl} className="image-url">
              {imageUrl}
            </a>
          </span>
        </div>
        <div className="col-xs-12">
          <span className="next-steps">
            {trans(
              "Once we’ve reviewed your appeal, we will notify you via your inbox. If your appeal is approved your image will be restored and made visible to your readers."
            )}
          </span>
        </div>
      </div>
      <div className="row submit">
        <button
          className="btn btn-md btn-orange pull-right on-send"
          disabled={wordCount === 0}
        >
          {trans("Send")}
        </button>
      </div>
    </form>
  );
};

ImageAppealFlow.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onSubmissionError: PropTypes.func.isRequired,
  isIOS: PropTypes.bool,
  isAndroid: PropTypes.bool
};

SuccessPage.propTypes = {
  isIOS: PropTypes.bool,
  isAndroid: PropTypes.bool
};

AppealReasonForm.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ImageAppealFlow;
export { AppealReasonForm, SuccessPage };
