import { useEffect } from "react";
import _ from "lodash";

const SCROLL_THROTTLE_WAIT = 300;

export const useInfiniteScroll = ({
  hasMore,
  isDisabled,
  onLoadMore,
  scrollOffset,
  scrollableRef
}) => {
  const scrollableDiv = scrollableRef?.current;

  const handleOnScroll = () => {
    const scrollToGo = scrollableDiv.scrollHeight - scrollableDiv.scrollTop;
    const scrollToTrigger = scrollableDiv.clientHeight + scrollOffset;
    const isAtBottom = scrollToGo <= scrollToTrigger;

    if (isAtBottom && hasMore && !isDisabled) {
      onLoadMore();
    }
  };

  const throttledOnScroll = _.throttle(handleOnScroll, SCROLL_THROTTLE_WAIT);

  const initializeScrollEvent = () => {
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", throttledOnScroll);
      return () =>
        scrollableDiv.removeEventListener("scroll", throttledOnScroll);
    }
  };

  useEffect(initializeScrollEvent, [hasMore, isDisabled, onLoadMore]);
};
