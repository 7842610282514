import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Icon } from "../../shared-components";

export default class TagItemWithIcon extends React.Component {
  render() {
    const iconColor =
      this.props.className === "wattys-tag" ? "wp-neutral-5" : "wp-neutral-2";

    return (
      <a
        className={classNames(
          "tag-item with-icon",
          { active: this.props.active },
          this.props.className
        )}
        onClick={evt => this.props.clickHandler(evt, this.props.name)}
        href={this.props.link}
      >
        <span>{this.props.name}</span>
        {this.props.active ? (
          this.props.noRemoveIcon ? null : (
            <Icon iconName="fa-remove" height="12" color={iconColor} />
          )
        ) : (
          <Icon iconName="fa-plus" height="12" color={iconColor} />
        )}
      </a>
    );
  }
}

TagItemWithIcon.defaultProps = {
  clickHandler: () => {}
};

TagItemWithIcon.propTypes = {
  active: PropTypes.bool,
  noRemoveIcon: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  link: PropTypes.string
};
