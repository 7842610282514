import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";

import { useTrans, useTrapKeyboardNavigation } from "../../hooks";

const ConfirmModal = ({
  title,
  triggerRef,
  contentText,
  confirmLabel,
  declineLabel,
  onConfirmClick,
  onDeclineClick
}) => {
  const { trans } = useTrans();
  const modalRef = useRef();

  useTrapKeyboardNavigation(modalRef.current, triggerRef.current);

  return (
    <div className="confirm-modal" ref={modalRef}>
      {title && <h3 className="title">{title}</h3>}
      {contentText && <p className="content-text">{contentText}</p>}
      <div className="confirm-buttons">
        <Button
          onClick={onDeclineClick}
          variant={ButtonVariant.SECONDARY}
          label={declineLabel || trans("No")}
          fullWidth={true}
        />
        <Button
          onClick={onConfirmClick}
          label={confirmLabel || trans("Yes")}
          fullWidth={true}
        />
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onDeclineClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  triggerRef: PropTypes.object,
  contentText: PropTypes.string,
  confirmLabel: PropTypes.string,
  declineLabel: PropTypes.string
};

export default ConfirmModal;
