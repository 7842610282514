import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EditorTabs } from "../constants";
import { fetchStoryNotes, postStoryNotes, updateStoryNotes } from "./actions";
import { ConnectImage } from "../../../shared-components";
import InputTextarea from "../../../shared-components/input/InputTextarea";
import DropdownField from "../../../shared-components/input/DropdownField";
import RadioButtons from "../../../shared-components/input/RadioButtons";
import CheckboxGroup from "../../../shared-components/input/CheckboxGroup";
import SliderGroup from "./SliderGroup";
import TextareaAutocomplete from "./TextareaAutocomplete";
import { useTrans } from "../../../hooks";

import {
  PROTAGONIST,
  PROTAGONIST_ATTRIBUTES,
  STORY_GOAL,
  STORY_OUTCOME,
  STORY_OPPORTUNITIES
} from "./constants";

function StoryNotes({
  isLoading,
  story,
  storyNotesData,
  fetchStoryNotes,
  postStoryNotes,
  updateStoryNotes,
  activeTab
}) {
  const [submitted, setSubmitted] = useState(false);
  const [saved, setSaved] = useState(false);
  const { trans } = useTrans();

  const PROTAGONIST_CONSTANTS = PROTAGONIST();
  const ATTRIBUTES_CONSTANTS = PROTAGONIST_ATTRIBUTES();
  const GOAL_CONSTANTS = STORY_GOAL();
  const OUTCOME_CONSTANTS = STORY_OUTCOME();
  const OPPORTUNITIES_CONSTANTS = STORY_OPPORTUNITIES();

  useEffect(
    () => {
      fetchStoryNotes(story.id);
    },
    [story, fetchStoryNotes]
  );

  function saveStoryNotes() {
    postStoryNotes(story.id, storyNotesData);
  }

  const onFormChange = event => {
    const { name, value } = event.target;
    setSubmitted(false);
    setSaved(false);
    updateStoryNotes(story.id, storyNotesData, { name: name, value: value });
  };

  const onFormIntChange = event => {
    const { name, value } = event.target;
    const intValue = parseInt(value);
    setSubmitted(false);
    setSaved(false);
    updateStoryNotes(story.id, storyNotesData, { name: name, value: intValue });
  };

  const handleAttributesChange = (name, attributes) => {
    const stringAttributes = attributes.join();
    setSubmitted(false);
    setSaved(false);
    updateStoryNotes(story.id, storyNotesData, {
      name: name,
      value: stringAttributes
    });
  };

  const handleSliderValuesChange = (event, value) => {
    const name = event.target.name;
    setSubmitted(false);
    setSaved(false);
    updateStoryNotes(story.id, storyNotesData, { name: name, value: value });
  };

  const getSliderValues = () => {
    return [
      storyNotesData.protagonist.extrovert_introvert,
      storyNotesData.protagonist.likeable,
      storyNotesData.protagonist.focus_past_future,
      storyNotesData.protagonist.thinking_feeling,
      storyNotesData.protagonist.decisive_improvise,
      storyNotesData.protagonist.laidback_serious
    ];
  };

  const getGoalSliderValues = () => {
    return [
      storyNotesData.protagonist_goal.change_themselves_surroundings,
      storyNotesData.protagonist_goal.explore_emotions_world,
      storyNotesData.protagonist_goal.navigate_familiar_unfamiliar,
      storyNotesData.protagonist_goal.recall_past,
      storyNotesData.protagonist_goal.find_meaning,
      storyNotesData.protagonist_goal.take_keep
    ];
  };

  const handleCheckedOpportunities = event => {
    let newValues = [];
    if (checkNotNull(storyNotesData.opportunity.types)) {
      newValues = storyNotesData.opportunity.types.split(",");
    }
    if (newValues.includes(event.target.value)) {
      const position = newValues.indexOf(event.target.value);
      newValues.splice(position, 1);
    } else {
      newValues.push(event.target.value);
    }
    setSubmitted(false);
    setSaved(false);
    const stringOpportunities = newValues.join();
    updateStoryNotes(story.id, storyNotesData, {
      name: event.target.name,
      value: stringOpportunities
    });
  };

  const convertToString = field => {
    if (field !== null) {
      return field.toString();
    }
    return null;
  };

  const checkNotNull = field => {
    if (field !== null) {
      if (typeof field === "string" && field !== "") {
        return true;
      } else if (Array.isArray(field) && field.length > 0) {
        return true;
      } else if (typeof field !== "string") {
        return true;
      }
    }
    return false;
  };

  const canSubmit =
    checkNotNull(storyNotesData.protagonist.name) &&
    checkNotNull(storyNotesData.protagonist.other_names) &&
    checkNotNull(storyNotesData.protagonist.pronoun) &&
    checkNotNull(storyNotesData.protagonist.attributes) &&
    checkNotNull(storyNotesData.protagonist.extrovert_introvert) &&
    checkNotNull(storyNotesData.protagonist.likeable) &&
    checkNotNull(storyNotesData.protagonist.focus_past_future) &&
    checkNotNull(storyNotesData.protagonist.thinking_feeling) &&
    checkNotNull(storyNotesData.protagonist.decisive_improvise) &&
    checkNotNull(storyNotesData.protagonist.laidback_serious) &&
    checkNotNull(storyNotesData.protagonist.personality_change) &&
    checkNotNull(storyNotesData.protagonist_goal.description) &&
    checkNotNull(storyNotesData.protagonist_goal.physical_emotional) &&
    checkNotNull(storyNotesData.protagonist_goal.type) &&
    checkNotNull(
      storyNotesData.protagonist_goal.change_themselves_surroundings
    ) &&
    checkNotNull(storyNotesData.protagonist_goal.explore_emotions_world) &&
    checkNotNull(
      storyNotesData.protagonist_goal.navigate_familiar_unfamiliar
    ) &&
    checkNotNull(storyNotesData.protagonist_goal.recall_past) &&
    checkNotNull(storyNotesData.protagonist_goal.find_meaning) &&
    checkNotNull(storyNotesData.protagonist_goal.take_keep) &&
    checkNotNull(storyNotesData.protagonist_goal.outcome) &&
    checkNotNull(storyNotesData.protagonist_goal.succeed) &&
    checkNotNull(storyNotesData.protagonist_goal.outcome_feeling) &&
    checkNotNull(storyNotesData.opportunity.interested) &&
    checkNotNull(storyNotesData.opportunity.logline) &&
    checkNotNull(storyNotesData.opportunity.story_progress) &&
    checkNotNull(storyNotesData.opportunity.types);

  if (isLoading || activeTab !== EditorTabs.NOTES) {
    return null;
  }

  return (
    <div id={EditorTabs.NOTES}>
      <React.Fragment>
        <div id={"story-planner-intro"}>
          <div className={"story-planner-image-container"}>
            <ConnectImage
              name="desktop-web/undraw-personal-notebook-sobb@2x.png"
              id={"story-planner-image"}
              height={"105px"}
              width={"120px"}
            />
          </div>
          <div className="story-planner-intro-text">
            <div className={"title"}>{trans("Story Notes")}</div>
            <div className={"subtitle"}>
              {trans("Store and track all of your story ideas in one place.")}
            </div>
          </div>
        </div>
        <div className={"section"} id={"protagonist-form"}>
          <div className={"section-title"}>{PROTAGONIST_CONSTANTS.title}</div>
          <div className={"section-description"}>
            <div>{PROTAGONIST_CONSTANTS.info}</div>
            <div className={"learn-more"}>
              {PROTAGONIST_CONSTANTS.more}{" "}
              <strong>
                <a
                  href={"/814442697-story-notes-who-is-your-protagonist"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {PROTAGONIST_CONSTANTS.learnMore}
                </a>
              </strong>
            </div>
          </div>
          <InputTextarea
            label={PROTAGONIST_CONSTANTS.section1.label}
            name={PROTAGONIST_CONSTANTS.section1.name}
            className={"top"}
            characterLimit={PROTAGONIST_CONSTANTS.section1.limit}
            placeholder={PROTAGONIST_CONSTANTS.section1.placeholder}
            textareaClassName={"short"}
            value={storyNotesData.protagonist.name}
            onChange={onFormChange}
          />
          <InputTextarea
            label={PROTAGONIST_CONSTANTS.section2.label}
            name={PROTAGONIST_CONSTANTS.section2.name}
            className={"top"}
            characterLimit={PROTAGONIST_CONSTANTS.section2.limit}
            placeholder={PROTAGONIST_CONSTANTS.section2.placeholder}
            textareaClassName={"short"}
            value={storyNotesData.protagonist.other_names}
            onChange={onFormChange}
          />
          <DropdownField
            label={PROTAGONIST_CONSTANTS.section3.label}
            dropdownOptions={PROTAGONIST_CONSTANTS.section3.options}
            name={PROTAGONIST_CONSTANTS.section3.name}
            labelClassName={"short"}
            selectClassName={"short"}
            onChange={onFormChange}
            selected={storyNotesData.protagonist.pronoun}
            placeholder={PROTAGONIST_CONSTANTS.section3.placeholder}
          />
          <TextareaAutocomplete
            name={PROTAGONIST_CONSTANTS.section4.name}
            label={PROTAGONIST_CONSTANTS.section4.label}
            info={PROTAGONIST_CONSTANTS.section4.info}
            className={"short"}
            placeholder={PROTAGONIST_CONSTANTS.section4.placeholder}
            onChange={handleAttributesChange}
            attributes={
              storyNotesData.protagonist.attributes
                ? storyNotesData.protagonist.attributes.split(",")
                : []
            }
            suggestions={ATTRIBUTES_CONSTANTS.options}
          />
          <SliderGroup
            label={PROTAGONIST_CONSTANTS.section5.label}
            options={PROTAGONIST_CONSTANTS.section5.options}
            onChange={handleSliderValuesChange}
            values={getSliderValues()}
          />
          <DropdownField
            label={PROTAGONIST_CONSTANTS.section6.label}
            dropdownOptions={PROTAGONIST_CONSTANTS.section6.options}
            labelClassName={"short"}
            selectClassName={"short"}
            onChange={onFormIntChange}
            selected={convertToString(
              storyNotesData.protagonist.personality_change
            )}
            name={PROTAGONIST_CONSTANTS.section6.name}
            placeholder={PROTAGONIST_CONSTANTS.section6.placeholder}
          />
        </div>
        <div className={"section"} id={"story-goal-form"}>
          <div className={"section-title"}>{GOAL_CONSTANTS.title}</div>
          <div className={"section-description"}>
            <div>{GOAL_CONSTANTS.info}</div>
          </div>
          <InputTextarea
            label={GOAL_CONSTANTS.section1.label}
            name={GOAL_CONSTANTS.section1.name}
            characterLimit={GOAL_CONSTANTS.section1.limit}
            placeholder={GOAL_CONSTANTS.section1.placeholder}
            value={storyNotesData.protagonist_goal.description}
            onChange={onFormChange}
            labelClassName={"long"}
            className={"long-field"}
            textareaClassName={"long"}
          />
          <RadioButtons
            label={GOAL_CONSTANTS.section2.label}
            name={GOAL_CONSTANTS.section2.name}
            options={GOAL_CONSTANTS.section2.options}
            value={storyNotesData.protagonist_goal.physical_emotional}
            onChange={onFormIntChange}
          />
          {(storyNotesData.protagonist_goal.physical_emotional === null ||
            storyNotesData.protagonist_goal.physical_emotional === 0) && (
            <div>
              <DropdownField
                label={GOAL_CONSTANTS.section3.label}
                name={GOAL_CONSTANTS.section3.name}
                dropdownOptions={GOAL_CONSTANTS.section3.options[0]}
                disabled={
                  storyNotesData.protagonist_goal.physical_emotional === null
                }
                onChange={onFormChange}
                labelClassName={"short"}
                selectClassName={"short"}
                selected={storyNotesData.protagonist_goal.type}
                placeholder={GOAL_CONSTANTS.section3.placeholder}
              />
            </div>
          )}
          {storyNotesData.protagonist_goal.physical_emotional === 1 && (
            <div>
              <DropdownField
                label={GOAL_CONSTANTS.section3.label}
                name={GOAL_CONSTANTS.section3.name}
                dropdownOptions={GOAL_CONSTANTS.section3.options[1]}
                disabled={
                  storyNotesData.protagonist_goal.physical_emotional === null
                }
                onChange={onFormChange}
                labelClassName={"short"}
                selectClassName={"short"}
                selected={storyNotesData.protagonist_goal.type}
                placeholder={GOAL_CONSTANTS.section3.placeholder}
              />
            </div>
          )}
          <SliderGroup
            label={GOAL_CONSTANTS.section4.label}
            options={GOAL_CONSTANTS.section4.options}
            onChange={handleSliderValuesChange}
            values={getGoalSliderValues()}
          />
        </div>
        <div className={"section"} id={"outcome-form"}>
          <div className={"section-title"}>{OUTCOME_CONSTANTS.title}</div>
          <div className={"section-description"}>
            <div>{OUTCOME_CONSTANTS.info}</div>
          </div>
          <InputTextarea
            label={OUTCOME_CONSTANTS.section1.label}
            name={OUTCOME_CONSTANTS.section1.name}
            characterLimit={OUTCOME_CONSTANTS.section1.limit}
            placeholder={OUTCOME_CONSTANTS.section1.placeholder}
            onChange={onFormChange}
            value={storyNotesData.protagonist_goal.outcome}
            labelClassName={"long"}
            className={"long-field"}
            textareaClassName={"long"}
          />
          <RadioButtons
            label={OUTCOME_CONSTANTS.section2.label}
            name={OUTCOME_CONSTANTS.section2.name}
            options={OUTCOME_CONSTANTS.section2.options}
            value={storyNotesData.protagonist_goal.succeed}
            onChange={onFormIntChange}
          />
          <RadioButtons
            label={OUTCOME_CONSTANTS.section3.label}
            name={OUTCOME_CONSTANTS.section3.name}
            options={OUTCOME_CONSTANTS.section3.options}
            value={storyNotesData.protagonist_goal.outcome_feeling}
            onChange={onFormIntChange}
          />
        </div>
        <div id={"story-planner-save-btn"}>
          <button
            onClick={() => {
              setSaved(true);
              saveStoryNotes();
            }}
            className={classNames("btn-primary", { disabled: saved })}
            aria-label={"Save my story notes"}
          >
            {saved
              ? trans("Saved") /* eslint-disable-line */
              : trans("Save my Story Notes")}
          </button>
        </div>
        <div className={"section"} id={"opportunities-form"}>
          <div>
            <div className={"section-title"}>
              {OPPORTUNITIES_CONSTANTS.title}
            </div>
            <div className={"section-description"}>
              <div>{OPPORTUNITIES_CONSTANTS.info}</div>
            </div>
            <RadioButtons
              label={OPPORTUNITIES_CONSTANTS.section1.label}
              name={OPPORTUNITIES_CONSTANTS.section1.name}
              options={OPPORTUNITIES_CONSTANTS.section1.options}
              value={storyNotesData.opportunity.interested}
              onChange={onFormIntChange}
            />
          </div>
          <div
            className={classNames({
              "available-opportunities":
                storyNotesData.opportunity.interested === 1,
              hidden: storyNotesData.opportunity.interested !== 1
            })}
          >
            <CheckboxGroup
              options={OPPORTUNITIES_CONSTANTS.section2.options}
              label={OPPORTUNITIES_CONSTANTS.section2.label}
              name={OPPORTUNITIES_CONSTANTS.section2.name}
              onChange={handleCheckedOpportunities}
              values={
                storyNotesData.opportunity.types
                  ? storyNotesData.opportunity.types.split(",")
                  : []
              }
            />
            <InputTextarea
              label={OPPORTUNITIES_CONSTANTS.section3.label}
              name={OPPORTUNITIES_CONSTANTS.section3.name}
              characterLimit={OPPORTUNITIES_CONSTANTS.section3.limit}
              placeholder={OPPORTUNITIES_CONSTANTS.section3.placeholder}
              about={OPPORTUNITIES_CONSTANTS.section3.about}
              onChange={onFormChange}
              value={storyNotesData.opportunity.logline}
              labelClassName={"long"}
              className={"long-field"}
              textareaClassName={"long"}
            />
            <div className={"field-description"}>
              {trans("Not sure how to write a logline?")}
              <a
                href={"/814443428-story-notes-how-do-i-write-a-logline"}
                target={"_blank"}
                rel="noreferrer"
              >
                {trans("Learn More Here")}
              </a>
            </div>
            <DropdownField
              label={OPPORTUNITIES_CONSTANTS.section4.label}
              dropdownOptions={OPPORTUNITIES_CONSTANTS.section4.options}
              name={OPPORTUNITIES_CONSTANTS.section4.name}
              onChange={onFormChange}
              labelClassName={"long"}
              selected={storyNotesData.opportunity.story_progress}
              className={"long-field"}
              selectClassName={"long"}
              placeholder={OPPORTUNITIES_CONSTANTS.section4.placeholder}
            />
            {!submitted && (
              <div id={"story-planner-submit-btn"}>
                <button
                  className={classNames("btn-primary", {
                    disabled: !canSubmit
                  })}
                  aria-label={"Submit story notes to wattpad"}
                  onClick={() => {
                    setSubmitted(true);
                    saveStoryNotes();
                  }}
                >
                  {trans("Submit my private Story Notes to Wattpad")}
                </button>
                <div
                  className={classNames("submit-btn-info", {
                    hidden: canSubmit
                  })}
                >
                  {OPPORTUNITIES_CONSTANTS.notComplete}
                </div>
              </div>
            )}
            {submitted && (
              <div id={"story-planner-submit-btn"}>
                <div
                  className={classNames("btn-primary", "disabled")}
                  aria-label={"Submitted"}
                >
                  {trans("Submitted")}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}

StoryNotes.defaultProps = {
  storyNotesData: {
    id: "",
    protagonist: {
      name: null,
      other_names: "",
      pronoun: null,
      attributes: "",
      extrovert_introvert: null,
      likeable: null,
      focus_past_future: null,
      thinking_feeling: null,
      decisive_improvise: null,
      laidback_serious: null,
      personality_change: null
    },
    protagonist_goal: {
      description: "",
      physical_emotional: null,
      type: null,
      change_themselves_surroundings: null,
      explore_emotions_world: null,
      navigate_familiar_unfamiliar: null,
      recall_past: null,
      find_meaning: null,
      take_keep: null,
      outcome: "",
      succeed: null,
      outcome_feeling: null
    },
    opportunity: {
      interested: null,
      logline: "",
      story_progress: null,
      type: null
    }
  }
};

StoryNotes.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  story: PropTypes.object.isRequired,
  fetchStoryNotes: PropTypes.func.isRequired,
  postStoryNotes: PropTypes.func.isRequired,
  updateStoryNotes: PropTypes.func.isRequired,
  storyNotesData: PropTypes.shape({
    id: PropTypes.number,
    protagonist: PropTypes.shape({
      name: PropTypes.string,
      other_names: PropTypes.string,
      pronoun: PropTypes.string,
      attributes: PropTypes.string,
      extrovert_introvert: PropTypes.number,
      likeable: PropTypes.number,
      focus_past_future: PropTypes.number,
      thinking_feeling: PropTypes.number,
      decisive_improvise: PropTypes.number,
      laidback_serious: PropTypes.number,
      personality_change: PropTypes.number
    }).isRequired,
    protagonist_goal: PropTypes.shape({
      description: PropTypes.string,
      physical_emotional: PropTypes.number,
      type: PropTypes.string,
      change_themselves_surroundings: PropTypes.number,
      explore_emotions_world: PropTypes.number,
      navigate_familiar_unfamiliar: PropTypes.number,
      recall_past: PropTypes.number,
      find_meaning: PropTypes.number,
      take_keep: PropTypes.number,
      outcome: PropTypes.string,
      succeed: PropTypes.number,
      outcome_feeling: PropTypes.number
    }).isRequired,
    opportunity: PropTypes.shape({
      interested: PropTypes.number,
      logline: PropTypes.string,
      story_progress: PropTypes.string,
      types: PropTypes.string
    }).isRequired
  })
};

const mapStateToProps = state => {
  return {
    storyNotesData: state.storyNotes.data,
    isLoading: state.storyNotes.isLoading,
    activeTab: state.worksItemDetails.worksItemDetailsActiveTab
  };
};

export default connect(
  mapStateToProps,
  { fetchStoryNotes, postStoryNotes, updateStoryNotes }
)(StoryNotes);
