import { useEffect } from "react";

function useSetDocumentTitle(title) {
  useEffect(
    () => {
      window.document.title = title;
    },
    [title]
  );
}

export { useSetDocumentTitle };
