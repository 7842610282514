import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../../hooks";
import { FormValidation } from "../../FormValidation";
import { numberWithinBounds, requiredField } from "../../helpers";
import ContestFormField from "../../ContestFormField";
import ErrorField from "../ErrorField";
import { MAX_STORIES, MIN_STORIES } from "../../constants";

const NumberOfStoriesInSeries = ({
  form,
  disableForm,
  onSeriesIdentificationChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = "";

  const subtitle = (
    <div className="subtitle">
      {trans("How many stories are currently in the series?")}
    </div>
  );
  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "numberOfStoriesInSeries" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.numberOfStoriesInSeries === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation
        validationRules={[
          requiredField(trans),
          numberWithinBounds(MIN_STORIES, MAX_STORIES, trans)
        ]}
      >
        {({ ref, errorMessage, className }) => (
          <>
            <input
              ref={ref}
              id="current-number-of-stories-input"
              className={classNames("form-element", className)}
              name="numberOfStoriesInSeries"
              value={form.numberOfStoriesInSeries}
              disabled={disableForm}
              onChange={onSeriesIdentificationChange}
              placeholder={trans("Enter your number of stories")}
              type="number"
            />
            <ErrorField
              errorMessage={errorMessage}
              fieldId={"current-number-of-stories"}
            />
            {!errorMessage && (
              <ErrorField
                fieldId={"current-number-of-stories"}
                errorCondition={errorCondition}
              />
            )}
          </>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

NumberOfStoriesInSeries.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onSeriesIdentificationChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default NumberOfStoriesInSeries;
