import { AVATAR_SIZES } from "../constants";
import { resize } from "./resize";

/**
 * Return resized avatar using the appropriately sized image
 *
 * @param {String} url image location
 * @param {Number} width desired size
 * @returns {String}  resized avatar image
 */
export const resizeAvatar = (url, width) => {
  // regular expression based on user avatar url
  var re = /(^.*\/useravatar\/[\w\-_]+\.)([0-9]{1,3})(\.?[0-9]*\.)(png|jpg|jpeg|gif)$/;
  var correctionBase = [24, 17];

  if (typeof url === "string" && url.length > 0) {
    return resize(AVATAR_SIZES, url, width, re, correctionBase);
  }

  return "";
};
