import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EditorTabs } from "./constants";
import { updateActiveTab } from "./reducers";
import { ConnectImage } from "../../shared-components";
import { isClient } from "../../helpers";
import { useTrans } from "../../hooks";

export function WorksItemsDetailsNavBarUI({
  isNew,
  isPublish,
  onSwitchTabs,
  updateActiveTab,
  activeTab
}) {
  const { trans } = useTrans();

  function onTabChange(event) {
    const newTab = event.currentTarget.getAttribute("data-section");
    updateActiveTab(newTab);
    onSwitchTabs(newTab);
  }

  useEffect(
    () => {
      updateActiveTab("toc");
    },
    [updateActiveTab]
  );

  return (
    <div className={"works-edit-select"}>
      <button
        className={classNames("on-switch-type", {
          active: activeTab === "story-detail" || isNew
        })}
        data-section={"story-detail"}
        onClick={onTabChange}
      >
        {trans("Story Details")}
      </button>
      {!isNew &&
        !isPublish && (
          <button
            className={classNames("on-switch-type", {
              active: activeTab === "toc"
            })}
            data-section={"toc"}
            onClick={onTabChange}
          >
            {trans("Table of Contents")}
          </button>
        )}
      {!isNew && (
        <StoryPlannerTab
          onTabChange={onTabChange}
          activeTab={activeTab}
          label={trans("Story Notes")}
        />
      )}
    </div>
  );
}

WorksItemsDetailsNavBarUI.defaultProps = {
  onSwitchTabs: () => {}
};

WorksItemsDetailsNavBarUI.propTypes = {
  isNew: PropTypes.bool.isRequired,
  isPublish: PropTypes.bool.isRequired,
  onSwitchTabs: PropTypes.func.isRequired,
  updateActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    activeTab: state.worksItemDetails.worksItemDetailsActiveTab
  };
};

export default connect(
  mapStateToProps,
  { updateActiveTab }
)(WorksItemsDetailsNavBarUI);

export class StoryPlannerTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: isClient() && !localStorage.getItem("seen-planner")
    };
  }

  onClickedPlannerTab = event => {
    localStorage.setItem("seen-planner", "true");
    this.setState({ isNew: false });
    this.props.onTabChange(event);
  };

  render() {
    return (
      <button
        className={classNames("on-switch-type", {
          active: this.props.activeTab === EditorTabs.NOTES
        })}
        data-section={EditorTabs.NOTES}
        onClick={this.onClickedPlannerTab}
      >
        <div id={"story-planner-tab"}>
          {this.state.isNew && (
            <div id={"story-planner-new-tag-container"}>
              <ConnectImage
                name="shared/new-tag-2x.png"
                id={"storyplanner-new-tag"}
                height={"19px"}
                width={"40px"}
              />
            </div>
          )}
          <div>{this.props.label}</div>
        </div>
      </button>
    );
  }
}

StoryPlannerTab.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string
};
