import React from "react";

import { ConnectImage } from "../../shared-components";
import { Container } from "./Container";
import { useTrans } from "../../hooks";

function WattpadBooks() {
  const { trans } = useTrans();
  function leftPart() {
    return (
      <div className="textContainer">
        <ConnectImage
          width="190"
          name="/landing/wattpadBooksLogo.svg"
          title="Wattpad Studios logo"
        />
        <h3>{trans("Your voice belongs on bookshelves")}</h3>
        <p className="constrainedText">
          {trans(
            "Wattpad Books aspires to recognize and reflect diverse voices by taking Wattpad stories to published book and onto bookshelves around the world."
          )}
        </p>
        <p>{trans("Wattpad Books works with partners such as:")}</p>
        <ConnectImage
          className="partner"
          width="110"
          name="/landing/macmillan.png"
          title="macmillan Publishers logo"
        />
        <ConnectImage
          className="partner"
          width="87"
          name="/landing/anvil.png"
          title="Anvil Publishing logo"
        />
        <ConnectImage
          className="partner"
          width="97"
          name="/landing/penguin.png"
          title="Penguin Random House UK logo"
        />
      </div>
    );
  }
  function rightPart() {
    return (
      <div className="imageContainer">
        <ConnectImage
          width="348"
          name="/landing/wattpadBooksPoster.png"
          title="wattpad books poster"
        />
      </div>
    );
  }
  return (
    <Container
      className="studiosContainer"
      rightPart={rightPart()}
      leftPart={leftPart()}
    />
  );
}

export { WattpadBooks };
