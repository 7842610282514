// Actions
export const TOGGLE_MODAL_VISIBLE = "TOGGLE_MODAL_VISIBLE";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const MODAL_TYPE = {
  BS_MODAL: "BSModal",
  DS_MODAL: "DSModal",
  WP_MODAL: "WPModal"
};

export const MODAL_ID = {
  PREMIUM_PICKS_FAQ_MODAL_ID: "premium-picks-faq-modal",
  PUBLISH_MODAL_ID: "publish-modal",
  SCHEDULE_SUCCESS_MODAL_ID: "schedule-success-modal",
  STORY_PREVIEW_MODAL: "story-preview-modal",
  BUY_COINS_MODAL: "buy-coins-modal"
};
