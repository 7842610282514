import React from "react";
import { ConnectImage } from "../../shared-components";
import { useTrans } from "../../hooks";

export default function OnboardingSuccess() {
  const { trans } = useTrans();

  const handleFinish = () => {
    app.router.navigate("/home", { trigger: true });
  };

  return (
    <>
      <div className="full-screen background-1 onboarding-bg" />
      <div className="onboardingModal modal fade in success">
        <div className="onboarding-success">
          <div className="logo-wrapper">
            <ConnectImage
              name="logos/wp-logo-orange.png"
              alt="wattpad logo"
              className="wp-logo"
              width="174"
            />
          </div>
          <div className="title">{trans("All done")}</div>
          <div className="message">
            {trans(
              "Thanks for setting up your profile! A world of stories awaits you..."
            )}
          </div>
          <div className="image">
            <img src="/img/onboarding-success/book_lover.svg" alt="" />
          </div>
          <button
            className={"start-reading-button btn-primary"}
            onClick={handleFinish}
          >
            {trans("Start reading")}
          </button>
        </div>
      </div>
    </>
  );
}
