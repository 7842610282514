// The following component is currently rolled out only to ambassadors and translation is not needed

import React from "react";
import PropTypes from "prop-types";
import Icon from "../../../Icon";

import { useTrans } from "../../../../hooks";

const CloseAccountModal = ({ toggleModal }) => {
  const { trans } = useTrans();

  return (
    <section className="profile-modal" id="userClose" aria-hidden="true">
      <header className="modal-header">
        <div className="pull-right top-right close" data-dismiss="modal">
          <button className="close-button" onClick={toggleModal}>
            <Icon iconName="fa-remove" size="16" />
          </button>
        </div>
        <h3 className="modal-title">Close User Account</h3>
      </header>
      <div className="modal-body">
        <p>Select Reason:</p>
        <select id="close-reason">
          <option value="banned_explicit">Banned - Explicit</option>
          <option value="banned_self_harm">Banned - Self Harm</option>
          <option value="copyright">Copyright Infringement</option>
          <option value="csem">CSEM found</option>
          <option value="csem_image_moderation">
            CSEM found by image moderation
          </option>
          <option value="cyberbullying_harassment">
            Cyberbullying/Harassment
          </option>
          <option value="extremism">Extremism</option>
          <option value="poaching">Poaching</option>
          <option value="previous_account_closed">
            Previous account closed
          </option>
          <option value="sexual_messaging">Sexual Messaging</option>
          <option value="spam">Spam</option>
          <option value="underage">Underage</option>
        </select>
      </div>
      <footer className="modal-footer">
        <button className="btn btn-orange on-close-user-account">
          {trans("Close Account")}
        </button>
      </footer>
    </section>
  );
};
CloseAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

export default CloseAccountModal;
