import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../../../shared-components";
import { useClickOutside } from "../../../hooks";
import {
  buildAbout,
  buildLabel
} from "../../../shared-components/input/helpers/input-labels";

function TextareaAutocomplete({
  suggestions,
  attributes,
  label,
  info,
  about,
  name,
  placeholder,
  onChange,
  labelClassName,
  className,
  maxNumAttributes,
  ...rest
}) {
  const [ref, setRef] = useState(null);
  const [activeSuggestion, setActiveSuggestion] = useState(
    attributes.length < maxNumAttributes
  );
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [userInput, setUserInput] = useState("");
  const [focused, setFocused] = useState(false);

  useClickOutside(ref, () => setFocused(false));

  const getSuggestions = value => {
    return suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1 &&
        !attributes.includes(suggestion)
    );
  };

  const reachedMaxNumAttributes = () => {
    if (attributes.length >= maxNumAttributes) {
      setUserInput("");
      setActiveSuggestion(false);
    } else {
      setActiveSuggestion(true);
    }
  };

  const addAttribute = event => {
    const selected = event.currentTarget.innerText;
    let previousAttributes = attributes;
    previousAttributes.push(selected);
    reachedMaxNumAttributes();
    setUserInput("");
    setFilteredSuggestions([]);
    setFocused(false);
    onChange(name, previousAttributes);
  };

  const removeAttribute = event => {
    const attribute = event.currentTarget.id;
    let previousAttributes = attributes;
    let index = previousAttributes.indexOf(attribute);
    if (index !== -1) {
      previousAttributes.splice(index, 1);
    }
    reachedMaxNumAttributes();
    setFilteredSuggestions([]);
    setFocused(false);
    onChange(name, previousAttributes);
  };

  const isEmptyOrWhitespace = str => {
    return str === null || str.match(/^\s*$/) !== null;
  };

  const handleChange = event => {
    let newInput = event.currentTarget.value;
    if (isEmptyOrWhitespace(newInput)) {
      newInput = "";
    }
    let newSuggestions = getSuggestions(newInput);
    while (newSuggestions.length <= 0 && newInput.length > 0) {
      newInput = newInput.substring(0, newInput.length - 1);
      newSuggestions = getSuggestions(newInput);
    }
    setFilteredSuggestions(newSuggestions);
    setUserInput(newInput);
  };

  let suggestionsComponents = filteredSuggestions.map((suggestion, index) => {
    return (
      <button
        className={classNames({
          "suggestion-active": index === activeSuggestion
        })}
        key={suggestion}
        onClick={addAttribute}
        id={index}
      >
        {suggestion}
      </button>
    );
  });

  let selectedAttributes = attributes.map((attribute, index) => {
    let id = attribute + "-" + index;
    if (!isEmptyOrWhitespace(attribute)) {
      return (
        <div className={"valid-attribute"} key={id}>
          {attribute}
          <div
            onClick={removeAttribute}
            id={attribute}
            className={"remove-attribute"}
            role={"button"}
            tabIndex={"0"}
          >
            <Icon iconName={"fa-remove"} height={"16"} color={"wp-neutral-2"} />
          </div>
        </div>
      );
    }
  });

  return (
    <div className={classNames("form-field", className)}>
      {buildLabel({ label, labelClassName, tooltip: info })}
      {buildAbout(about)}
      <div
        className={classNames("short", "autocomplete-field")}
        ref={ref => setRef(ref)}
      >
        <div className={"selected-attributes"}>{selectedAttributes}</div>
        <div className={"attribute-input"}>
          {activeSuggestion ? (
            <input
              type={"text"}
              onFocus={() => {
                setFocused(true);
                setShowSuggestions(true);
              }}
              value={userInput}
              onChange={handleChange}
              name={name}
              placeholder={placeholder}
              className={classNames({
                focus: focused
              })}
              {...rest}
            />
          ) : (
            <input
              type={"text"}
              value={""}
              placeholder={"Up to " + maxNumAttributes + " attributes"}
              disabled
              {...rest}
            />
          )}
        </div>
        <div
          className={classNames("suggestions", {
            hidden:
              !showSuggestions || filteredSuggestions.length <= 0 || !focused
          })}
        >
          {suggestionsComponents}
        </div>
      </div>
    </div>
  );
}

TextareaAutocomplete.defaultProps = {
  suggestions: [],
  attributes: [],
  onChange: () => {},
  maxNumAttributes: 10
};

TextareaAutocomplete.propTypes = {
  suggestions: PropTypes.array.isRequired,
  label: PropTypes.string,
  info: PropTypes.string,
  about: PropTypes.string,
  name: PropTypes.string,
  attributes: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  textareaClassName: PropTypes.string,
  maxNumAttributes: PropTypes.number
};

export default TextareaAutocomplete;
