import React, { useEffect, useRef, useReducer } from "react";
import PropTypes from "prop-types";

import { useTrans } from "../hooks";
import NewCheckBox from "./NewCheckbox";

const initialState = {
  complete: false,
  mature: false,
  free: false,
  paid: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "APPLY": {
      delete state["error"];
      if (action.value === "free" || action.value === "paid") {
        return {
          // cannot have both free and paid stories hidden at the same time
          ...state,
          free: false,
          paid: false,
          [action.value]: true
        };
      } else {
        return {
          ...state,
          [action.value]: true
        };
      }
    }
    case "REMOVE": {
      delete state["error"];
      return {
        ...state,
        [action.value]: false
      };
    }
    default:
      return {
        ...state,
        error: "Error in content reducer"
      };
  }
};

const ContentFilter = ({
  isMatureUser,
  selectedOptions,
  handleFilterChange
}) => {
  const { trans } = useTrans();
  const [state, dispatch] = useReducer(reducer, { ...selectedOptions });
  const firstUpdate = useRef(true);
  useEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      } else {
        handleFilterChange(state, "content");
      }
    },
    [state, handleFilterChange]
  );

  const handleChange = event => {
    dispatch({
      type: event.currentTarget.checked ? "APPLY" : "REMOVE",
      value: event.currentTarget.value
    });
  };
  return (
    <div className="filter">
      <span className="sr-only">
        {trans("Content") + " " + trans("You can select multiple options") /* prettier-ignore */}
      </span>
      <div className="filter__title" aria-hidden>
        {trans("Content")}
      </div>
      <div className="filter__description" aria-hidden>
        {trans("You can select multiple options")}
      </div>
      <div className="filter__options">
        <NewCheckBox
          checked={state.complete}
          onChange={handleChange}
          value={"complete"}
          contentId="content1"
        >
          {trans("Only show completed stories")}
        </NewCheckBox>
        {isMatureUser && (
          <NewCheckBox
            checked={state.mature}
            onChange={handleChange}
            value={"mature"}
            contentId="content2"
          >
            {trans("Hide mature stories")}
          </NewCheckBox>
        )}
        <NewCheckBox
          checked={state.free}
          onChange={handleChange}
          value={"free"}
          contentId="content3"
        >
          {trans("Hide free stories")}
        </NewCheckBox>
        <NewCheckBox
          checked={state.paid}
          onChange={handleChange}
          value={"paid"}
          contentId="content4"
        >
          {trans("Hide Wattpad Originals")}
        </NewCheckBox>
      </div>
    </div>
  );
};

ContentFilter.defaultProps = {
  isMatureUser: false,
  handleFilterChange: () => {},
  selectedOptions: initialState
};

ContentFilter.propTypes = {
  isMatureUser: PropTypes.bool,
  handleFilterChange: PropTypes.func,
  selectedOptions: PropTypes.object
};

export default ContentFilter;
