import _ from "lodash";
import { useRef, useState } from "react";

import { useEventListener } from "../../../hooks";

function FormValidation({ children, validationRules }) {
  const ref = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  useEventListener(
    "blur",
    () => {
      const text = ref.current.value;
      let currentError;
      _.each(validationRules, validationRule => {
        if (!validationRule.rule(text)) {
          currentError = validationRule.errorMessage;
          return false;
        }
      });
      if (currentError) {
        setErrorMessage(currentError);
      } else if (errorMessage) {
        setErrorMessage(null);
      }
    },
    ref.current
  );
  return children({
    ref,
    errorMessage,
    className: errorMessage ? "error-field" : ""
  });
}

export { FormValidation };
