export const NUM_PREMIUM_THEMES = 12;
export const DEFAULT_PREMIUM_THEME = 5; // corresponds to "water", default on iOS
export const DEFAULT_THEME = 6; // corresponds to wp-base-1
export const PREMIUM_COOKIE = "premium-theme";

export const THEME_ID_NAME = {
  1: "olive",
  2: "plum",
  3: "watermelon",
  4: "blue",
  5: "water",
  6: "orange",
  7: "turquoise",
  8: "grey",
  9: "red",
  10: "purple",
  11: "royal",
  12: "denim"
};

// Mocked plans
// To be replaced by an API call to the backend, once the endpoint is added
export const MOCK_PREMIUM_PLANS = [
  {
    name: "1 Month",
    price: 5.99,
    monthly_price: 5.99,
    tax: 0.59,
    period: "Monthly",
    tag: null,
    sku: "1_MONTH_WP_Premium"
  },
  {
    name: "6 Months",
    price: 29.99,
    monthly_price: 4.99,
    tax: 2.99,
    period: "Bi-annually",
    tag: null,
    sku: "wp_premium_6_months"
  },
  {
    name: "12 Months",
    price: 52.99,
    monthly_price: 4.42,
    tax: 5.29,
    period: "Yearly",
    tag: "Popular",
    sku: "wp_premium_1_year"
  }
];

// add app constants here! and import them into helpers
export const COVER_SIZES = [
  64,
  80,
  100,
  128,
  144,
  160,
  176,
  200,
  208,
  256,
  288,
  352,
  368,
  416,
  512
];

// add app constants here! and import them into helpers
export const AVATAR_SIZES = [24, 32, 42, 64, 128, 256];

export const WORD_CHAR_LENGTH = 5; // Currently based on english only
export const HUMAN_READ_SPEED = 230; // word per minute

export const ASSET_SERVER = "//static.wattpad.com";
export const MEDIA_SERVER = "//img.wattpad.com";

export const PAID_MODEL_TYPE = {
  INVALID: "",
  PAID_STORY: "paid_story",
  BONUS_CHAPTER: "paid_bonus"
};

export const BONUS_CHAPTER_TYPE = {
  EXCLUSIVE_CHAPTER: 0,
  STORY_BRANCH: 1,
  WRITER_REVEAL: 2
};

export const PREMIUM_PICKS_LIVENESS = {
  NOW: "NOW",
  UPCOMING: "UPCOMING"
};
