import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { buildLabel } from "./helpers/input-labels";

function DropdownField({
  info,
  label,
  placeholder,
  selected,
  selectClassName,
  disabled,
  dropdownOptions,
  onChange,
  onFocus,
  onBlur,
  onClickIcon,
  className,
  labelClassName,
  ariaLabel,
  ...rest
}) {
  const getValue = () => {
    let option = dropdownOptions.find(
      option => option.value.toString() === selected
    );
    if (option) {
      return option.value;
    }
    return placeholder;
  };

  const [isFocused, setIsFocused] = useState();

  const handleChange = event => {
    onChange(event);
  };

  let options = dropdownOptions.map(data => (
    <option key={data.value} value={data.value}>
      {data.name}
    </option>
  ));

  const handleFocus = () => {
    setIsFocused(true);
    onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur();
  };

  return (
    <div className={classNames("form-field", className)}>
      {buildLabel({
        label,
        labelClassName,
        tooltip: info,
        onClickIcon: onClickIcon
      })}
      <select
        value={getValue()}
        onChange={event => handleChange(event)}
        onFocus={event => handleFocus(event)}
        onBlur={event => handleBlur(event)}
        className={classNames("select-form-field", selectClassName, {
          focus: isFocused
        })}
        id={"select-form-component"}
        disabled={disabled}
        aria-label={ariaLabel}
        {...rest}
      >
        (value !== placeholder &&{" "}
        <option hidden disabled value={placeholder}>
          {placeholder}
        </option>)
        {options}
      </select>
    </div>
  );
}

DropdownField.defaultProps = {
  placeholder: "Select",
  disabled: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClickIcon: null
};

DropdownField.propTypes = {
  dropdownOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClickIcon: PropTypes.func,
  selected: PropTypes.string,
  selectClassName: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string
};

export default DropdownField;
