import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../shared-components";
import { injectTrans } from "../../helpers";
import ReactDOM from "react-dom";

class BannedImageMessaging extends React.Component {
  render() {
    const { src, trans } = this.props;

    const onClickLink = () => {
      const learnMoreUrl =
        "https://support.wattpad.com/hc/en-us/articles/360020840731-Image-Moderation-on-Wattpad";
      window.open(learnMoreUrl, "_blank", "noopener");
    };
    return (
      <div
        className="banned-image-wrapper"
        contentEditable="false"
        suppressContentEditableWarning={true}
      >
        <div className="banned-image-messaging" data-url={src}>
          <Icon
            name="warning-triangle"
            size="68"
            color="wp-warning-01"
            viewBox="24"
            className="warning-icon"
          />
          <span className="message">
            {trans(
              "This image has been deemed unsafe and will only be visible to you."
            )}
            {/* Render a button here rather than an <a> so that medium-editor doesn't show an anchor preview */}
            <button
              role="link"
              className="on-learn-more btn-no-background"
              onClick={onClickLink}
            >
              {trans("Learn more")}
            </button>
          </span>
        </div>
      </div>
    );
  }

  removeMessaging = () => {
    const { id, groupId, partId } = this.props;
    ReactDOM.unmountComponentAtNode(
      document.getElementById(
        `component-bannedimagemessaging-${id}-/myworks/${groupId}/write/${partId}`
      )
    );
  };

  componentDidMount() {
    window.app.currentView.listenTo(
      window.app,
      "works:editor:remove-banned-indicator",
      this.removeMessaging
    );
  }

  componentWillUnmount() {
    window.app.currentView.stopListening(
      window.app,
      "works:editor:remove-banned-indicator"
    );
  }

  static propTypes = {
    trans: PropTypes.func.isRequired,
    id: PropTypes.any.isRequired,
    src: PropTypes.string.isRequired,
    groupId: PropTypes.string,
    partId: PropTypes.number,
    cleanupBannedMessaging: PropTypes.func
  };
}

export default injectTrans(BannedImageMessaging);
