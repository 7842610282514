import PropTypes from "prop-types";
import React from "react";

import { ConnectImage } from "../../shared-components";
import { useTrans, useScrollDetection } from "../../hooks";

function LazyLoader({
  direction,
  threshold,
  total,
  offset,
  isLoading,
  isDisabled,
  onLoad
}) {
  const { trans } = useTrans();

  useScrollDetection({
    direction,
    threshold,
    onThreshold: () => {
      if (!isDisabled && offset !== total) {
        onLoad(offset);
      }
    }
  });
  return isLoading ? (
    <div className="load-more-container">
      <ConnectImage
        name="/icons/spinner.svg"
        className="fa-spin load-more"
        title={trans("Spinner")}
        height="24"
        width="24"
      />
    </div>
  ) : null;
}

LazyLoader.defaultProps = {
  direction: "down",
  threshold: 200
};

LazyLoader.propTypes = {
  direction: PropTypes.string.isRequired,
  threshold: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired
};

export default LazyLoader;
