import {
  FORM_POST,
  FORM_ERROR,
  FORM_UPDATE,
  FORM_FETCH,
  FORM_REQUEST,
  FORM_VISIBILITY
} from "./constants";

export function fetchStoryNotes(storyId) {
  const getNotesUrl = `/v5/stories/${storyId}/notes`;

  return async function(dispatch) {
    if (!storyId) {
      return dispatch({ type: FORM_FETCH, data: null });
    }
    dispatch({ type: FORM_REQUEST });

    try {
      const request = Promise.resolve($.get(getNotesUrl));
      const response = await request;

      dispatch({ type: FORM_FETCH, data: response });
    } catch (e) {
      return dispatch({ type: FORM_FETCH, data: null });
    }
  };
}

const formReducer = (state, newState) => {
  //update one field
  if (newState.name) {
    const { name, value } = newState;
    const [section, field] = name.split(".");
    switch (section) {
      case "protagonist": {
        return {
          ...state,
          protagonist: {
            ...state.protagonist,
            [field]: value
          }
        };
      }
      case "protagonist_goal": {
        return {
          ...state,
          protagonist_goal: {
            ...state.protagonist_goal,
            [field]: value
          }
        };
      }
      case "opportunity": {
        return {
          ...state,
          opportunity: {
            ...state.opportunity,
            [field]: value
          }
        };
      }
      default: {
        return { ...state };
      }
    }
  }
  //batch update
  return {
    ...state,
    protagonist: newState.protagonist,
    protagonist_goal: newState.protagonist_goal,
    opportunity: newState.opportunity
  };
};

export function updateStoryNotes(storyId, formData, newState) {
  formData.id = storyId;
  formData = formReducer(formData, newState);
  return function(dispatch) {
    dispatch({ type: FORM_UPDATE, data: formData });
  };
}

export function postStoryNotes(storyId, formData) {
  const postNotesUrl = `/v5/stories`;
  formData.id = parseInt(storyId);
  formData.protagonist.name =
    formData.protagonist.name && formData.protagonist.name.trim();
  formData.protagonist.other_names =
    formData.protagonist.other_names && formData.protagonist.other_names.trim();
  formData.protagonist_goal.description =
    formData.protagonist_goal.description &&
    formData.protagonist_goal.description.trim();
  formData.protagonist_goal.outcome =
    formData.protagonist_goal.outcome &&
    formData.protagonist_goal.outcome.trim();
  formData.opportunity.logline =
    formData.opportunity.logline && formData.opportunity.logline.trim();

  return async function(dispatch) {
    try {
      await Promise.resolve(
        $.ajax({
          url: postNotesUrl,
          type: "POST",
          data: JSON.stringify(formData),
          dataType: "json",
          headers: {
            "Content-Type": "application/json"
          }
        })
      );
      dispatch({ type: FORM_POST });
    } catch (err) {
      wattpad?.utils?.showToast();
      dispatch({ type: FORM_ERROR });
    }
  };
}

export function updateStoryNotesVisible() {
  return function(dispatch) {
    dispatch({ type: FORM_VISIBILITY });
  };
}
