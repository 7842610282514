export const sendWriterOnboardingEvents = ({
  user_type,
  writer_stage,
  finished_story
}) => {
  if (user_type) {
    window.te.push("event", "onboarding", "user_type", null, "complete", {
      type: user_type
    });
  }
  if (writer_stage !== undefined) {
    if (writer_stage === null) {
      window.te.push("event", "onboarding", "writer_stage", null, "skip");
    } else {
      window.te.push("event", "onboarding", "writer_stage", null, "complete", {
        type: writer_stage
      });
    }
  }
  if (writer_stage && finished_story !== undefined) {
    if (finished_story === null) {
      window.te.push("event", "onboarding", "finished_story", null, "skip");
    } else {
      window.te.push(
        "event",
        "onboarding",
        "finished_story",
        null,
        "complete",
        {
          type: finished_story
        }
      );
    }
  }
};
