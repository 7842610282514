import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../hooks";
import { ResizableTextarea } from "../../../shared-components";

const CHARACTER_LIMIT = 2000;
function MessageForm({
  activeThread,
  postMessage,
  username,
  isReply,
  isFirstTimeMessaging,
  isFollowing
}) {
  const { trans } = useTrans();

  const [message, setMessage] = useState("");

  function handleNewMessage(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (message !== "") {
      window.te.push("event", "private_messages", "message", null, "send", {
        sender_username: username,
        recipient_username: activeThread,
        is_reply: isReply,
        page: "message"
      });
      postMessage(activeThread, { body: message });
      setMessage("");
    }
  }

  function onUserType({ target: { value } }) {
    setMessage(value.substr(0, CHARACTER_LIMIT));
  }

  return (
    <form
      className="conversation-form bottom-sticky"
      onSubmit={handleNewMessage}
    >
      {isFirstTimeMessaging && (
        <div className="first-time-msgs">
          {!isFollowing && (
            <strong>
              {trans(
                "You are about to send a message to someone you don’t follow."
              )}
            </strong>
          )}
          {trans(
            " Wattpad is an inclusive space where everyone can belong. Hate speech and cyberbullying are never tolerated."
          )}
        </div>
      )}
      <ResizableTextarea
        characterLimit={CHARACTER_LIMIT}
        value={message}
        onChange={onUserType}
        autoFocus
        placeholder={trans("Write a message...")}
      />
      <div className="btn-container">
        <button
          type="submit"
          disabled={message === ""}
          className="btn btn-large btn-orange"
        >
          {trans("Send")}
        </button>
      </div>
    </form>
  );
}

MessageForm.propTypes = {
  activeThread: PropTypes.string.isRequired,
  postMessage: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  isReply: PropTypes.bool.isRequired,
  isFirstTimeMessaging: PropTypes.bool.isRequired,
  isFollowing: PropTypes.bool.isRequired
};

export default MessageForm;
