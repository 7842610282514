import React from "react";
import { useDispatch } from "react-redux";

import { useGetUser } from "./useGetUser";
import ReportModal, { ReportTypes } from "../views/report/ReportModal";
import { toggleModal } from "../shared-components/modals/actions";

export const useReportModal = () => {
  const user = useGetUser();
  const dispatch = useDispatch();
  const closeModal = () => dispatch(toggleModal());

  const createReportModal = (
    reportType,
    name,
    body,
    location,
    deepLink,
    handleClose,
    language,
    triggerRef
  ) => {
    return (
      <ReportModal
        reportType={reportType}
        triggerRef={triggerRef}
        requesterUsername={user.username}
        requesterEmail={user.email}
        reportId={{ name, body, location, deepLink }}
        hideModal={handleClose}
        storyLanguage={language}
      />
    );
  };

  const openReportModal = (
    reportType,
    name,
    body,
    location,
    deepLink,
    language,
    classes = "",
    openTriggerRef
  ) => {
    const handleClose = () => {
      closeModal();
      openTriggerRef?.current?.focus();
    };

    dispatch(
      toggleModal({
        hideClose: true,
        disableScroll: true,
        disableClose: true,
        className: `report-modal ${classes}`,
        useGlobalModalContainer: true,
        component: () =>
          createReportModal(
            reportType,
            name,
            body,
            location,
            deepLink,
            handleClose,
            language,
            openTriggerRef
          )
      })
    );
  };

  const openReportStoryModal = (
    storyId,
    storyTitle,
    author,
    language,
    partId = undefined
  ) => {
    openReportModal(
      ReportTypes.STORY,
      author,
      storyTitle,
      storyId,
      partId,
      language
    );
  };

  const openReportCommentModal = (
    reportType,
    commentAuthor,
    commentBody,
    location,
    deeplink,
    reportButtonRef
  ) => {
    openReportModal(
      reportType,
      commentAuthor,
      commentBody,
      location,
      deeplink,
      null,
      "report-comment-modal",
      reportButtonRef
    );
  };

  return {
    openReportStoryModal,
    openReportCommentModal,
    openReportModal
  };
};
