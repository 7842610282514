import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTrans } from "../../../hooks";

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf("day");
var YESTERDAY = REFERENCE.clone()
  .subtract(1, "days")
  .startOf("day");
var A_WEEK_OLD = REFERENCE.clone()
  .subtract(7, "days")
  .startOf("day");

function isToday(momentDate) {
  return momentDate.isSame(TODAY, "d");
}
function isYesterday(momentDate) {
  return momentDate.isSame(YESTERDAY, "d");
}
function isWithinAWeek(momentDate) {
  return momentDate.isAfter(A_WEEK_OLD);
}

function DateSeparator({ dateDisplay }) {
  let { trans } = useTrans();
  let dateFormat;
  if (isToday(dateDisplay)) {
    dateFormat = trans("Today at %s", dateDisplay.format("h:mm a"));
  } else if (isYesterday(dateDisplay)) {
    dateFormat = trans("Yesterday at %s", dateDisplay.format("h:mm a"));
  } else if (isWithinAWeek(dateDisplay)) {
    dateFormat = dateDisplay.format("ddd [at] h:mm a");
  } else {
    dateFormat = dateDisplay.format("MMM D, YYYY, [at] h:mm a");
  }
  return (
    <div className="date-separator">
      <div>
        <span className="date">{dateFormat}</span>
      </div>
    </div>
  );
}

DateSeparator.propTypes = {
  dateDisplay: PropTypes.object.isRequired
};

export { DateSeparator };
