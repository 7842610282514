import React from "react";

import { ConnectImage } from "../../shared-components";
import { useTrans } from "../../hooks";

function SeeYourStory() {
  const { trans } = useTrans();
  return (
    <div className="seeYourStoryContainer">
      <h3>{trans("See Your Story...")}</h3>
      <div className="itemContainer">
        <div className="item">
          <ConnectImage name="/landing/tv-icon.svg" title="tv" />
          {trans("Get produced to movie or film")}
        </div>
        <div className="item">
          <ConnectImage name="/landing/reel-icon.svg" title="reel" />
          {trans("Get adapted to a TV series")}
        </div>
        <div className="item">
          <ConnectImage name="/landing/book-icon.svg" title="book" />
          {trans("Get published")}
        </div>
      </div>
    </div>
  );
}

export { SeeYourStory };
