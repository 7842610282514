// eslint-disable-next-line no-useless-escape
const EMAIL_VALIDATION_PATTERN = `[a-z\\d!#$%&'*+/=?^_‘{|}~-]+(?:\\.[a-z\\d!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z\\d](?:[a-z\\d-]*[a-z\\d])?\\.)+[a-z\\d](?:[a-z\\d-]*[a-z\\d])?`;

export const REQUIRED_VAL = { required: true };

export const EMAIL_VALIDATIONS = {
  ...REQUIRED_VAL,
  pattern: EMAIL_VALIDATION_PATTERN
};

export const SIGNATURE_VALIDATIONS = {
  ...REQUIRED_VAL,
  minLength: 5
};

export const DmcaFormFieldIds = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  USERNAME: "username",
  EMAIL: "email",
  COMPANY: "company",
  ADDRESS: "address",
  COUNTRY: "country",
  CITY: "city",
  PROVINCE: "province",
  POSTAL_CODE: "postalCode",
  PHONE_NUMBER: "phoneNumber",
  DESC_ALLEGED: "descriptionAlleged",
  ORIGINAL_WORK: "originalWorkUrl",
  WHERE_TO_FIND_ORIGINAL: "whereToFindOriginal",
  REPORTED_URLS: "reportedUrls",
  IS_ACCURATE: "isAccurate",
  GOOD_FAITH: "goodFaith",
  OWNER: "owner",
  SIGNATURE: "signature"
};

// Keys are based on the value
export const DescriptionAllegedOptions = trans => [
  { key: "", value: trans("Select an option"), disabled: true },
  {
    key: "Word_for_word_copy_of_my_work",
    value: trans("Word for word copy of my work")
  },
  { key: "Translation_of_my_work", value: trans("Translation of my work") },
  {
    key: "Adaptation_of_my_work",
    value: trans(
      "Adaptation of my work (For example, character name changes, gender swaps, additional characters added, etc.)"
    )
  },
  {
    key: "Image_photos_illustrations_etc",
    value: trans("Image, photos, illustrations, etc.")
  },
  { key: "Piracy_spam", value: trans("Piracy/spam") },
  {
    key: "Other",
    value: trans("Other (Please specify in the description below)")
  }
];

export const REPORT_POLICY_LINK =
  "https://support.wattpad.com/hc/articles/204471770";
export const REQUEST_STATUS_LINK =
  "https://support.wattpad.com/hc/articles/360033371032";
export const HELP_CENTER_LINK =
  "https://support.wattpad.com/hc/categories/8301500664212-Copyright";

const getLinkTag = (url, label) =>
  `<a target="_blank" href="${url}">${label}</a>`;

export const getDmcaInfoOne = trans => {
  const linkTermOfService = getLinkTag("/terms", trans("Terms of Service"));
  const linkReportPolicy = getLinkTag(
    REPORT_POLICY_LINK,
    trans("Repeat Infringer Policy")
  );
  return trans("Copyright laws protect your original works. Wattpad’s %s prohibits uploading content that violates any intellectual property rights. As a service provider, we also comply with all applicable provisions of the Digital Millennium Copyright Act (“DMCA”). Our policy is to respond to valid notices of alleged copyright infringement by removing or disabling access to allegedly infringing material and terminating users, when appropriate, according to our %s.", linkTermOfService, linkReportPolicy); //prettier-ignore
};

export const getDmcaInfoTwo = trans => {
  const linkReportArticle = getLinkTag(
    REPORT_POLICY_LINK,
    trans("Reporting Copyright Infringement")
  );
  return trans(
    "If you are a copyright owner, are authorized to act on behalf of one, or are authorized to act under any exclusive right under copyright, you can report alleged infringements taking place on or through Wattpad by completing the following DMCA Notice of Alleged Infringement form. If you are not the copyright holder, or you are reporting infringements located outside of the Wattpad platform, please check out our reporting article: %s.",
    linkReportArticle
  );
};

export const getDmcaSuccessOne = trans => {
  const linkHelpCenter = getLinkTag(
    REQUEST_STATUS_LINK,
    trans("check your request status")
  );
  return trans(
    "We’ve received your request and will do our best to get back to you soon. Please note that we offer support during regular business days.  Submitting multiple requests about the same issue will delay our response to you as it can move your request to the bottom of the queue. If you have a Wattpad account, you can %s in our Help Center.",
    linkHelpCenter
  );
};

export const getDmcaSuccessTwo = trans => {
  const linkRequestStatus = getLinkTag(
    HELP_CENTER_LINK,
    trans("Help Center articles")
  );
  return trans(
    "For information about Copyright on Wattpad, check out our %s",
    linkRequestStatus
  );
};
