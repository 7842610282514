import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import ContestFormField from "../ContestFormField";
import { MAP_STORY_DECLARATION } from "../constants";

const OngoingStoryDeclarationField = ({ form, storyId }) => {
  const { trans } = useTrans();

  const title = trans("Ongoing or Complete story declaration");
  const subtitle = (
    <div
      className="subtitle"
      dangerouslySetInnerHTML={{
        __html: /* prettier-ignore */ trans('You are submitting your story to the category listed below. To change the category you are submitting to, please make any necessary edits in your <a href="%s" target="_blank" rel="noopener noreferrer">story settings</a>.', `/myworks/${storyId}`)
      }}
    />
  );

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <select
        id="story-status-declaration-select"
        value={form.storyStatus}
        name="storyStatus"
        disabled
        className="form-element"
        aria-label={form.storyStatus}
      >
        {MAP_STORY_DECLARATION(trans)
          .filter(storyType => storyType.key === form.storyStatus)
          .map(storyType => (
            <option key={storyType.key} value={storyType.value}>
              {storyType.value}
            </option>
          ))}
      </select>
    </ContestFormField>
  );
};

OngoingStoryDeclarationField.propTypes = {
  form: PropTypes.object.isRequired,
  storyId: PropTypes.string.isRequired
};

export default OngoingStoryDeclarationField;
