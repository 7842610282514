import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "../../Icon";
import { toggleModal } from "../actions";
import { useClickOutside } from "../../../hooks";
import { MODAL_TYPE } from "../constants";

function Modal({ toggleModal, modalProps, isGlobalModalContainer = false }) {
  modalProps = modalProps || {}; // need default because can't return early with an effect
  const {
    className,
    component,
    disableClose = false,
    hideClose = false,
    animate = true,
    onCloseModal = () => {},
    useGlobalModalContainer = false,
    disableScroll = false
  } = modalProps;

  const [ref, setRef] = useState(null);

  useEffect(
    () => {
      if (!disableScroll) return;
      document.body.classList.add("wp-modal-open");
      return () => document.body.classList.remove("wp-modal-open");
    },
    [disableScroll]
  );

  const handleCloseModal = useCallback(
    () => {
      toggleModal();
      onCloseModal();
    },
    [toggleModal, onCloseModal]
  );

  useClickOutside(ref, () => {
    if (!component || disableClose) return;
    handleCloseModal();
  });

  const handleKeyDown = useCallback(
    event => {
      if (disableClose) return;

      const key = event.key || event.keyCode;
      if (key === "Escape") {
        handleCloseModal();
      }
    },
    [disableClose, handleCloseModal]
  );

  useEffect(
    () => {
      // Necessary check for Travis
      if (wattpad && wattpad.utils && wattpad.utils.registerModal) {
        window.wattpad?.utils.registerModal("wpmodal", MODAL_TYPE.WP_MODAL, {
          isOpen: () => !!component,
          hide: () => {
            if (!component) return;
            handleCloseModal();
          }
        });
      }

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    },
    [component, handleCloseModal, handleKeyDown]
  );

  if (!component) {
    return null;
  }

  // Some modals are rendered to a local instance of <WPModal>. Other modals
  // need to be global, so they render to the <WPModal> instance in
  // <ReactRoot>. Pass `useGlobalModalContainer=true` in the action to render to
  // the global modal instance.
  if (isGlobalModalContainer !== useGlobalModalContainer) {
    return null;
  }

  // ContentComponent can be functional or a react class.
  // Both can be rendered using <ContentComponent>
  // E.g. if a functional component is wrapped by injectTrans, the component becomes
  // a react component class which cannot be rendered by invoking i.e. { component() }
  const ContentComponent = component;

  return (
    <div className={classNames("wp-modal", "modal-container", className)}>
      <div
        ref={ref => setRef(ref)}
        className={classNames("modal-content", { animate })}
        aria-label="Full screen modal" // TODO add translation to aria-label when copy is approved
      >
        {!disableClose &&
          !hideClose && (
            <button
              className="btn-no-style"
              onClick={() => {
                toggleModal();
                onCloseModal();
              }}
              aria-label="Close modal" // TODO add translation to aria-label when copy is approved
            >
              <Icon
                iconName="fa-remove"
                color="wp-neutral-1"
                height="22"
                className="close-btn"
              />
            </button>
          )}
        <ContentComponent toggleModal={toggleModal} />
      </div>
    </div>
  );
}

Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isGlobalModalContainer: PropTypes.bool,
  modalProps: PropTypes.shape({
    className: PropTypes.string,
    component: PropTypes.func,
    disableClose: PropTypes.bool,
    hideClose: PropTypes.bool,
    animate: PropTypes.bool,
    onCloseModal: PropTypes.func,
    useGlobalModalContainer: PropTypes.bool,
    disableScroll: PropTypes.bool
  })
};

const WPModal = connect(
  state => ({ modalProps: state.modal.modalProps }),
  { toggleModal }
)(Modal);

export default WPModal;
