import { sanitizeHTML } from "./sanitizeHTML";
import { unsanitizeHTML } from "./unsanitizeHTML";

const linkify = (text, options) => {
  const patterns = {
      mention: /((?![/.#])(?:^|\W))@([\w-]+)/gi,
      url: /(http(s)?:\/\/[A-Za-z0-9._~:/?#[\]@!$&'()*+,\-=]+)/gi, // this regex is not perfect
      tag: /((?![/.@])(?:^|\W))#([[a-zA-Z0-9\s?/]+)/gi
    },
    replacements = {
      mention: '$1<a class="on-user-mention" href="/user/$2">@$2</a>',
      url: '<a href="$1" rel="nofollow" target="_blank">$1</a>',
      tag: ' <a href="/tags/$2" class="on-navigate">#$2</a>'
    };

  options = options || {};

  if (options.hash) {
    if (options.hash.sanitization) {
      options.sanitization = options.hash.sanitization;
    }
  }
  options.sanitization = options.sanitization || "sanitize";
  options.doTags = options.doTags === false ? false : true;
  options.doUrl =
    options.doUrl === false || options.sanitization === "unsanitize"
      ? false
      : true;
  options.doMention = options.doMention === false ? false : true;

  switch (options.sanitization) {
    case "sanitize":
      text = sanitizeHTML(text);
      break;
    case "unsanitize":
      text = unsanitizeHTML(text);
      break;
    case "none":
      break;
  }
  if (options.doMention) {
    text = text.replace(patterns.mention, replacements.mention);
  }
  if (options.doUrl) {
    text = text.replace(patterns.url, replacements.url);
  }
  if (options.doTags) {
    text = text.replace(patterns.tag, replacements.tag);
  }

  return text;
};

export { linkify };
