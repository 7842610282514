import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import { Avatar, ConnectImage } from "../../../shared-components";
import { profileBackground, count } from "../../../helpers";
import { useTrans, useMuteUsers } from "../../../hooks";
import ProfileMoreOptionsButton from "../ProfileMoreOptionsButton";

/* This header does not have all of the features of the handlebars header yet,
   as it was put in place to accomodate the muted profile and profile 404 pages
*/
const ProfileHeader = props => {
  const { trans } = useTrans();
  const muteButtonRef = useRef();
  const { openUnmuteUserModal } = useMuteUsers();

  const {
    profileType,
    backgroundUrl,
    username,
    name,
    highlight_colour,
    numFollowers,
    avatar,
    numStoriesPublished,
    numLists,
    onUnmuteUser,
    onUnblock,
    className,
    isMobile,
    blockUser
  } = props;

  let headerStyle;

  if (backgroundUrl) {
    headerStyle = {
      backgroundImage: `url( '${profileBackground(backgroundUrl, 1920)}' )`
    };
  } else {
    headerStyle = { backgroundColor: highlight_colour };
  }

  return (
    <div
      id="profile-header"
      className={classNames("profile-layout profile-header", className)}
    >
      <header style={headerStyle} className="background background-lg">
        <div className="avatar avatar-profile center-block">
          {avatar ? (
            <Avatar avatar={avatar} username={username} />
          ) : (
            <ConnectImage name="avatar/empty.png" width="96" height="96" />
          )}
        </div>

        <h1
          className="profile-name h3"
          aria-label={trans("%s's profile", name || username)} // prettier-ignore
        >
          {name || username}
        </h1>

        <div className="restricted-state">
          {profileType === "mute" &&
            onUnmuteUser && (
              <button
                ref={muteButtonRef}
                className="unmute-button"
                onClick={() =>
                  openUnmuteUserModal(username, muteButtonRef, onUnmuteUser)
                }
              >
                {trans("Unmute")}
              </button>
            )}

          {profileType === "block" &&
            onUnblock && (
              <button className="unblock-button" onClick={onUnblock}>
                {trans("Unblock")}
              </button>
            )}

          {isMobile && (
            <div className="button-group inline-block relative">
              <ProfileMoreOptionsButton
                username={username}
                isMuted={profileType === "mute"}
                isBlocked={profileType === "block"}
                unblockUser={onUnblock}
                onUnmuteUser={onUnmuteUser}
                blockUser={blockUser}
                isRestrictedProfile={true}
                showModerationOptions={true}
              />
            </div>
          )}
        </div>

        <ProfileHeaderMetadata
          numStoriesPublished={numStoriesPublished}
          numFollowers={numFollowers}
          numLists={numLists}
        />
      </header>
    </div>
  );
};

const ProfileHeaderMetadata = ({
  numStoriesPublished,
  numFollowers,
  numLists
}) => {
  const { ngettext } = useTrans();

  return (
    <div className="row header-metadata">
      <div className="col-xs-4">
        <p>
          {numStoriesPublished !== undefined ? count(numStoriesPublished) : "-"}
        </p>
        <p>{ngettext("Work", "Works", numStoriesPublished || 0)}</p>
      </div>
      <div className="col-xs-4">
        <p>{numLists !== undefined ? numLists : "-"}</p>
        <p>{ngettext("Reading List", "Reading Lists", numLists || 0)}</p>
      </div>
      <div className="col-xs-4">
        <p className="followers-count">
          {numFollowers !== undefined ? count(numFollowers) : "-"}
        </p>
        <p>{ngettext("Follower", "Followers", numFollowers || 0)}</p>
      </div>
    </div>
  );
};

ProfileHeader.propTypes = {
  profileType: PropTypes.string,
  username: PropTypes.string,
  backgroundUrl: PropTypes.string,
  avatar: PropTypes.string,
  highlight_colour: PropTypes.string,
  numStoriesPublished: PropTypes.number,
  numLists: PropTypes.number,
  numFollowers: PropTypes.number,
  name: PropTypes.string,
  onUnmuteUser: PropTypes.func,
  onUnblock: PropTypes.func,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  blockUser: PropTypes.func
};

ProfileHeader.defaultProps = {
  avatar: "",
  username: ""
};

ProfileHeaderMetadata.propTypes = {
  numStoriesPublished: PropTypes.number,
  numFollowers: PropTypes.number,
  numLists: PropTypes.number
};

export default ProfileHeader;
