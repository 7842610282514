import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { count } from "../../helpers";
import { useTrans } from "../../hooks";
import { sprintf } from "sprintf-js";
import RefineByTagFilter from "../../shared-components/RefineByTagFilter";
import LastUpdatedFilter from "../../shared-components/LastUpdatedFilter";
import ContentFilter from "../../shared-components/ContentFilter";
import LengthFilter from "../../shared-components/LengthFilter";

const FiltersContainer = ({
  searchTerm,
  resultType,
  numStories,
  relatedTags,
  selectedOptionsAge,
  selectedOptionsLength,
  selectedOptionsContent,
  isMatureUser = false
}) => {
  const { ngettext } = useTrans();

  const filterSearchResults = (state, filterType) => {
    if (state?.error) {
      // eslint-disable-next-line no-console
      console.error(`Error with ${filterType} filter`, state);
    } else {
      $(".story-card-skeleton-container").removeClass("hidden");
      $(".story-card-container").addClass("hidden");

      if (filterType === "age") {
        app?.trigger?.("app:component:filters:updateAge", state);
      } else if (filterType === "length") {
        app?.trigger?.("app:component:filters:updateLength", state);
      } else if (filterType === "content") {
        app?.trigger?.("app:component:filters:updateContent", state);
      } else if (filterType === "addTag") {
        app?.trigger?.("app:component:filters:addTag", state);
      } else if (filterType === "toggleTag") {
        app?.trigger?.(
          "app:component:filters:toggleTag",
          state.evt,
          state.tag,
          searchTerm
        );
      }
    }
  };

  useEffect(
    () => {
      $(".story-card-skeleton-container").addClass("hidden");
      $(".story-card-container").removeClass("hidden");
    },
    [selectedOptionsAge, selectedOptionsContent]
  );

  let searchTermArray = searchTerm.split(" ");
  searchTermArray = searchTermArray.filter(term => !term.startsWith("#"));
  let searchTermWithoutTags = searchTermArray.join(" ");

  return (
    <div
      className={classNames("filters-container", {
        profiles: resultType !== "stories"
      })}
    >
      <div className="search-info">
        {searchTerm && (
          <>
            <span className="sr-only">{searchTermWithoutTags}</span>
            <div
              aria-hidden
              className="search-term"
            >{`"${searchTermWithoutTags}"`}</div>
          </>
        )}
        {resultType === "stories" && (
          <>
            <span className="sr-only">
              {/* prettier-ignore */ sprintf(ngettext("%s result", "%s results", numStories), parseInt(numStories).toLocaleString())}
            </span>
            <div className="stories-count" aria-hidden>
              {/* prettier-ignore */ sprintf(ngettext("%s result", "%s results", numStories), count(numStories))}
            </div>
          </>
        )}
      </div>
      {resultType === "stories" && (
        <>
          <LengthFilter
            selectedOptions={selectedOptionsLength}
            handleFilterChange={filterSearchResults}
          />
          <LastUpdatedFilter
            selectedOptions={selectedOptionsAge}
            handleFilterChange={filterSearchResults}
          />
          <ContentFilter
            isMatureUser={isMatureUser}
            selectedOptions={selectedOptionsContent}
            handleFilterChange={filterSearchResults}
          />
          <RefineByTagFilter
            searchTags={relatedTags.tags}
            searchTerm={searchTerm}
            handleFilterChange={filterSearchResults}
          />
        </>
      )}
    </div>
  );
};

FiltersContainer.defaultProps = {
  handleFilterChange: () => {}
};

FiltersContainer.propTypes = {
  searchTerm: PropTypes.string,
  resultType: PropTypes.string,
  numStories: PropTypes.number,
  relatedTags: PropTypes.object,
  selectedOptionsAge: PropTypes.object,
  selectedOptionsLength: PropTypes.object,
  selectedOptionsContent: PropTypes.object,
  isMatureUser: PropTypes.bool
};

export default FiltersContainer;
