import { resize } from "./resize";

const profileBackground = (url, width) => {
  if (!width || !isFinite(width)) {
    width = 320;
  }
  // We need to add an extra dot(.) char at the 2nd last position so that there is a location to inject the width
  url = url.replace(/(\d+\.)(png|jpg|jpeg|gif)$/, ".$1$2");
  var re = /(^.*\/userbg\/[\w\-_]+\.)()(\.?[0-9]*\.)(png|jpg|jpeg|gif)$/;
  return resize([320, 360, 640, 1920], url, width, re, false).replace(
    "userbg",
    "userbgs"
  );
};

export { profileBackground };
