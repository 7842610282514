import { COVER_SIZES } from "../constants";
import { resize } from "./resize";

const resizeCover = (
  coverUrl,
  width,
  devicePixelRatio,
  matchMedia,
  msMatchMedia
) => {
  // regular expression based on story cover url
  const re = /(^.*\/c?cover\/[0-9]+-)([0-9]{1,3})(-*[\w]+\.)(png|jpg|jpeg|gif)$/;
  const correctionBase = [20, 14];
  return resize(
    COVER_SIZES,
    coverUrl,
    width,
    re,
    correctionBase,
    devicePixelRatio,
    matchMedia,
    msMatchMedia
  );
};

export { resizeCover };
