import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import { useSelector, connect } from "react-redux";
import { initializeMutedUsers, MUTE, UNMUTE } from "../profile/reducers";

// TODO: move to a separate reducers file
// once ReportModal is refactored to use reducers
function muteUser(action, reportedUsername) {
  return { type: action, name: reportedUsername };
}

// This component only renders if user selects the "I don't like what I'm seeing"
// option in the ReportModal
function ReportThankYouForm({
  requesterUsername,
  reportedUsername,
  muteCopies, // the JSON that contains the work flow and copies for mute/unmute
  submitReport,
  hideModal,
  onModalRef,
  showToast,
  initializeMutedUsers,
  muteUser
}) {
  const { trans } = useTrans();

  // Mute/ Unmute button copies
  // using muteCopies, in case support_tree copies are changed in the future
  const muteUserCopy = muteCopies[2].title;
  const unmuteUserCopy = muteCopies[2].extras.selectedStateText;
  const title = muteCopies[0].title;
  const subtitle = muteCopies[0].subtitle;
  const muteNote = muteCopies[1].title;

  const [muteButtonText, setMuteButtonText] = useState(muteUserCopy);

  // get list of Muted Users from requesterUsername so modal knows which
  // button copy to render, Mute user or Unmute user
  const mutedUsers = useSelector(state => state.profileData.mutedUsers);

  useEffect(() => {
    Promise.all([
      Promise.resolve(submitReport()),
      Promise.resolve(initializeMutedUsers(requesterUsername))
    ]).then(() => {
      const isAlreadyMuted = mutedUsers.some(
        user => user.username == reportedUsername
      );
      setMuteButtonText(isAlreadyMuted ? unmuteUserCopy : muteUserCopy);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickMuteUser = event => {
    event.preventDefault();

    const isMute = muteButtonText == muteUserCopy;
    const urlAction = isMute ? "ignore_user" : "unignore_user";
    const action = isMute ? MUTE : UNMUTE;
    const message = isMute
      ? trans("%s is now muted. You can unmute them at any time.", reportedUsername) //prettier-ignore
      : trans("%s is now unmuted. You can mute them at any time.", reportedUsername); //prettier-ignore

    return Promise.resolve(
      $.ajax({
        url: `/apiv2/ignoreuser?id=${reportedUsername}&action=${urlAction}`,
        type: "POST"
      })
    )
      .then(() => {
        muteUser(action, reportedUsername);
        setMuteButtonText(isMute ? unmuteUserCopy : muteUserCopy);
        showToast(message);
      })
      .catch(err => {
        let errorMessage;
        if (action == MUTE) {
          if (err.responseJSON && err.responseJSON.code == 467) {
            // Cannot mute staff, admins or verified users.
            errorMessage = err.responseJSON.message;
          } else {
            errorMessage = trans("There was an error muting this user. Please try again."); //prettier-ignore
          }
        } else {
          errorMessage = trans("There was an error unmuting this user. Please try again."); //prettier-ignore
        }
        showToast(errorMessage);
        throw err;
      });
  };

  return (
    <div className="report-modal-thank-you" ref={onModalRef}>
      <header className="modal-header thank-you">
        <div className="shield-icon">
          <Icon
            name="shieldCheck"
            size="27"
            strokeWidth="0"
            boxX="0"
            boxY="0"
            viewBox="27"
          />
        </div>
        <h3 className="thank-you-title">{title}</h3>
        <button className="on-close" onClick={hideModal}>
          <Icon iconName="fa-remove" height="16" color="wp-neutral-2" />
        </button>
      </header>
      <div className="modal-body">
        {/* subtitle from muteCopies has an embedded <a> tag inside */}
        <span dangerouslySetInnerHTML={{ __html: subtitle }} />
        <div className="line-divider" />
        <p className="mute-note">{muteNote}</p>
        <button className="mute-btn btn-secondary" onClick={onClickMuteUser}>
          {muteButtonText}
        </button>
      </div>
    </div>
  );
}

ReportThankYouForm.propTypes = {
  requesterUsername: PropTypes.string.isRequired,
  reportedUsername: PropTypes.string.isRequired,
  muteCopies: PropTypes.array.isRequired,
  submitReport: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onModalRef: PropTypes.func,
  showToast: PropTypes.func,
  muteUser: PropTypes.func,
  initializeMutedUsers: PropTypes.func
};

export default connect(
  null,
  { initializeMutedUsers, muteUser }
)(ReportThankYouForm);
