import {
  FETCH_HOME_MODULES,
  RECEIVE_SECTION_POSITION,
  SECTION_VIEWED,
  RECEIVE_CONTENT_SETTINGS,
  NO_CONTENT_SETTINGS_CHANGES,
  LOADING_CONTENT_SETTINGS,
  LOADING_CONTENT_SETTINGS_DONE,
  LOADING_HOME,
  LOADING_HOME_DONE,
  ERROR,
  CLEAR_ERROR,
  SHOW_LOADER,
  HIDE_LOADER,
  RECEIVE_READING_LISTS,
  LOADING_READING_LISTS,
  RECEIVE_STORIES_COLLECTIONS,
  LOADING_STORIES_COLLECTIONS,
  ADD_STORY_TO_COLLECTION,
  REMOVE_STORY_FROM_COLLECTION
} from "./constants";

export function homeSections(
  state = { sections: [], sectionBounds: {}, error: undefined },
  action
) {
  switch (action.type) {
    case FETCH_HOME_MODULES:
      return { ...state, sections: action.sections };
    case LOADING_HOME:
      return {
        ...state,
        homeLoading: true
      };
    case LOADING_HOME_DONE:
      return {
        ...state,
        homeLoading: false
      };
    case SHOW_LOADER:
      return {
        ...state,
        showLoader: true
      };
    case HIDE_LOADER:
      return {
        ...state,
        showLoader: false
      };
    case RECEIVE_SECTION_POSITION:
      return {
        ...state,
        sectionBounds: {
          ...state.sectionBounds,
          [action.info.index]: action.info
        }
      };
    case RECEIVE_CONTENT_SETTINGS:
      return {
        ...state,
        contentSettings: action.settings
      };
    case NO_CONTENT_SETTINGS_CHANGES:
      return {
        ...state,
        message: "no change"
      };
    case LOADING_CONTENT_SETTINGS:
      return {
        ...state,
        contentSettingsLoading: true
      };
    case LOADING_CONTENT_SETTINGS_DONE:
      return {
        ...state,
        contentSettingsLoading: false
      };
    case SECTION_VIEWED:
      return {
        ...state,
        sectionBounds: {
          ...state.sectionBounds,
          [action.index]: { ...state.sectionBounds[action.index], viewed: true }
        }
      };
    case RECEIVE_READING_LISTS:
      return {
        ...state,
        readingLists: action.lists,
        readingListsLoading: false
      };
    case LOADING_READING_LISTS:
      return {
        ...state,
        readingListsLoading: true
      };
    case RECEIVE_STORIES_COLLECTIONS:
      // Update existing collections store with new collections, overriding
      // any existing data with latest values.
      return {
        ...state,
        storiesCollections: {
          ...state.storiesCollections,
          ...action.collections
        },
        storiesCollectionsLoading: false
      };
    case LOADING_STORIES_COLLECTIONS:
      return {
        ...state,
        storiesCollectionsLoading: true
      };
    case ADD_STORY_TO_COLLECTION:
      return {
        ...state,
        storiesCollections: {
          ...state.storiesCollections,
          [action.storyId]: {
            ...state.storiesCollections[action.storyId],
            [action.collectionId]: true
          }
        }
      };
    case REMOVE_STORY_FROM_COLLECTION:
      return {
        ...state,
        storiesCollections: {
          ...state.storiesCollections,
          [action.storyId]: {
            ...state.storiesCollections[action.storyId],
            [action.collectionId]: false
          }
        }
      };
    case ERROR:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
