import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPaidStoriesList } from "../reducers/paid-stories-catalog-reducer";

import PropTypes from "prop-types";
import {
  useEventListener,
  useScrollDetection,
  useSetDocumentTitle,
  useTrans
} from "../../../hooks";

let window = window || global;

import StorySwimlane from "../../home/sections/StorySwimlane";

function PaidStoriesCatalogUI({
  loadPaidStoriesList,
  paidStoriesListByTag,
  catalog
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });
  useScrollDetection({
    direction: "down",
    threshold: 200,
    onThreshold: () => {
      loadPaidStoriesList();
    }
  });
  useEffect(
    () => {
      loadPaidStoriesList();
    },
    [loadPaidStoriesList]
  );
  const { trans } = useTrans();

  const tagDisplayNameMapping = {
    ongoingstories: trans("Ongoing Stories"),
    featuredpicks: trans("Featured Picks"),
    onsale: trans("On Sale"),

    // These categories are titled slightly differently from their tags MONX-1768
    teenfiction: trans("Contemporary Teen Fiction"),
    romance: trans("Adult Romance"),
    lgbt: trans("LGBTQIAP+"),
    newadult: trans("New Adult Romance"),
    fantasy: trans("High Fantasy"),

    // New categories: sent by backend as part of MONX-1478
    featuredstories: trans("Featured Stories"),
    newreleases: trans("New Releases"),
    werewolf: trans("Werewolf"),
    diverselit: trans("Diverse Lit"),
    wattpadbooks: trans("Wattpad Books"),
    lessthan74coins: trans("Deals: Stories for 74 coins or less"),
    urbanfantasy: trans("Urban Fantasy"),
    genreyoungadult: trans("Genre Young Adult"),
    historicalfiction: trans("Historical Fiction"),
    paranormal: trans("Paranormal"),
    sciencefiction: trans("Science Fiction"),
    vampire: trans("Vampire"),
    series: trans("Series"),
    chicklit: trans("ChickLit"),
    sexyandsteamy: trans("Sexy and Steamy"),
    emotionalanduplifting: trans("Emotional and Uplifting"),
    darkanddisturbing: trans("Dark and Disturbing"),
    mystery: trans("Mystery"),
    horror: trans("Horror"),
    thriller: trans("Thriller"),
    adventure: trans("Adventure"),
    action: trans("Action"),
    humor: trans("Humor")
  };

  const documentTitle = `${trans("Catalog")} - ${
    catalog === "wattpadoriginals"
      ? trans("Wattpad Originals")
      : trans("Paid Stories")
  }`;
  useSetDocumentTitle(documentTitle);

  return (
    <div className="catalog-content">
      {Object.keys(paidStoriesListByTag).map(tag => {
        const tagName = tagDisplayNameMapping[tag] || tag;
        return (
          <StorySwimlane
            key={tag}
            windowWidth={windowWidth}
            index={0}
            setPosition={() => {}}
            page="catalog"
            wasSeen={true}
            sectionType="paid_view_all_carousel"
            title={tagName}
            heading={tagName}
            stories={paidStoriesListByTag[tag]}
            isPromoted={false}
            withDivider={false}
          />
        );
      })}
    </div>
  );
}

PaidStoriesCatalogUI.propTypes = {
  loadPaidStoriesList: PropTypes.func,
  paidStoriesListByTag: PropTypes.object,
  catalog: PropTypes.string
};

const PaidStoriesCatalog = connect(
  state => ({
    paidStoriesListByTag: state.paidStoriesCatalog.paidStoriesListByTag
  }),
  { loadPaidStoriesList }
)(PaidStoriesCatalogUI);

export { PaidStoriesCatalog };
