import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "../../../shared-components/Icon";
import { useTrans } from "../../../hooks";
import { isClient } from "../../../helpers";

const GreetingAndContentSettings = ({
  greeting,
  index,
  openContentSettings,
  sectionType,
  setPosition,
  showContentSettings
}) => {
  const { trans } = useTrans();
  const el = useRef(null);

  useEffect(
    () => {
      const boundingBox = el.current.getBoundingClientRect();
      setPosition(
        index,
        sectionType,
        boundingBox.top + window.scrollY,
        boundingBox.bottom + window.scrollY
      );
    },
    [index, sectionType, setPosition]
  );

  return (
    <div className="greeting-and-content-settings" ref={el}>
      <div className="module-content">
        <div className="greeting">
          {greeting && (
            <h2 className="greeting-text">
              {isClient() && greeting.includes("%s")
                ? greeting.replace(
                    "%s",
                    wattpad.user.name || wattpad.user.username || ""
                  )
                : greeting}
            </h2>
          )}
        </div>
        {showContentSettings && (
          <h2 className="content-settings">
            <div
              role="button"
              tabIndex={0}
              className="content-settings-label"
              onClick={openContentSettings}
            >
              <Icon name="preferences" size="17" color="wp-neutral-1" />
              <p>{trans("Content Preferences")}</p>
            </div>
          </h2>
        )}
      </div>
      <hr className="module-divider" />
    </div>
  );
};
GreetingAndContentSettings.propTypes = {
  greeting: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  openContentSettings: PropTypes.func,
  sectionType: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  showContentSettings: PropTypes.bool
};

export default GreetingAndContentSettings;
