import React, { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import { ConnectImage } from "../../shared-components";
import { useTrans, useFeatureFlags } from "../../hooks";
import Icon from "../../shared-components/Icon";

export default function OnboardingGenres({ username, genres }) {
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [showError, setShowError] = useState(false);
  const selectionLimit = 3;
  const prevShowError = usePrevious(showError);

  useEffect(
    () => {
      if (!username || !genres || genres.length === 0) {
        app.router.navigate("/start/success", { trigger: true });
      }
    },
    [featureFlags, username, genres]
  );

  useEffect(
    () => {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);
      if (!prevShowError && showError) {
        timer;
      }
      return () => {
        clearTimeout(timer);
      };
    },
    [prevShowError, showError]
  );

  const handleGenreSelect = (genre, event) => {
    if (event.type === "click" || event.keyCode === 13) {
      const selected = genre.name;
      if (selectedGenres.includes(selected)) {
        let newSelection = selectedGenres.filter(genre => genre !== selected);
        setSelectedGenres(newSelection);
        setShowError(false);
      } else {
        if (selectedGenres.length >= selectionLimit) {
          setShowError(true);
        } else {
          setSelectedGenres([...selectedGenres, selected]);
        }
      }
    }
  };

  const handleFinish = () => {
    const initializeContentSettingsURI = `/v5/users/${username}/content-settings`;
    Promise.resolve(
      $.ajax({
        url: initializeContentSettingsURI,
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          preferredTags: selectedGenres
        })
      })
    ).finally(() => {
      window.te.push("event", "onboarding", null, null, "complete");
      app.router.navigate("/start/success", { trigger: true });
    });
  };

  if (!genres || genres.length === 0) {
    return null;
  } else {
    return (
      <>
        <div className="full-screen background-1 onboarding-bg" />
        <div className="onboardingModal modal fade in genres">
          <div className="modal-content">
            <div className="onboarding-genres">
              <div className="logo-wrapper">
                <ConnectImage
                  name="logos/wp-logo-orange.png"
                  alt="wattpad logo"
                  className="wp-logo"
                  width="174"
                />
              </div>
              <div className="greeting">{trans("Hi, %s", username)}</div>
              <div
                className="prompt"
                dangerouslySetInnerHTML={{
                  __html: /* prettier-ignore */ trans("Choose <strong>up to three</strong> genres that you enjoy reading. This will help us better recommend stories you'll love.")
                }}
              />
              <></>
              <div className="genres">
                {genres.map(genre => {
                  const genreIsSelected = selectedGenres.includes(genre.name);
                  return (
                    <div
                      key={genre.name}
                      className="genre-tile"
                      tabIndex={0}
                      role="button"
                      onClick={event => {
                        handleGenreSelect(genre, event);
                      }}
                      onKeyDown={event => {
                        handleGenreSelect(genre, event);
                      }}
                    >
                      <div
                        className="genre-info"
                        style={
                          genre.image
                            ? { backgroundImage: `url(${genre.image})` }
                            : {}
                        }
                      >
                        <div className="genre-name">{genre.topic}</div>
                      </div>
                      <div className="selected-wrapper">
                        <div
                          className={classnames("overlay", {
                            active: genreIsSelected,
                            hidden: !genreIsSelected
                          })}
                        />
                        <div
                          className={classnames("selected-icon", {
                            active: genreIsSelected,
                            hidden: !genreIsSelected
                          })}
                        >
                          <Icon iconName="fa-check" color="wp-neutral-5" />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* This hidden element with extra padding is to support overscrolling in different browsers*/}
                <ul className="overflow-padding" aria-hidden />
              </div>
              {showError && (
                <div className="genre-error-toast-wrapper">
                  <div className="genre-error-toast">
                    <p>
                      {trans(
                        "Oops, you can only select three genres. Try removing one before adding another"
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="finish-button-wrapper">
              <button
                className={classnames("finish-button", "btn-primary", {
                  enabled: selectedGenres.length > 0,
                  disabled: selectedGenres.length <= 0
                })}
                onClick={handleFinish}
              >
                {trans("Finish")}
              </button>
            </div>
            <div className="transparency" />
          </div>
        </div>
      </>
    );
  }
}

OnboardingGenres.propTypes = {
  username: propTypes.string.isRequired,
  genres: propTypes.arrayOf(propTypes.object).isRequired
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
