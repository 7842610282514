import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import {
  requiredField,
  maxCharLengthField,
  minCharLengthField
} from "../helpers";
import { LOGLINE_CHARACTER_MAX, LOGLINE_CHARACTER_MIN } from "../constants";
import ContestFormField from "../ContestFormField";
import ErrorField from "./ErrorField";

const LoglineField = ({
  form,
  disableForm,
  onFormChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Log line");

  const subtitle = (
    <>
      <div
        className="subtitle"
        dangerouslySetInnerHTML={{
          __html: /* prettier-ignore */ trans("Give us a one-sentence description of your story that conveys the dramatic narrative and hook. It should follow the below format:<br>[Character Name], [Character Description], has to [Action] in order to [Result].")
        }}
      />
      <div
        className="subtitle"
        dangerouslySetInnerHTML={{
          __html: /* prettier-ignore */ trans("Ex. “Cleopatra, <strong>a</strong> zombified ruler of Egypt, <strong>has to</strong> resist the temptation of eating Mark Antony, <strong>in order to</strong> not change the course of Egypt's future.”")
        }}
      />
    </>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "logLine" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.logLine === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation
        validationRules={[
          requiredField(trans),
          maxCharLengthField(LOGLINE_CHARACTER_MAX, trans),
          minCharLengthField(LOGLINE_CHARACTER_MIN, trans)
        ]}
      >
        {({ ref, errorMessage, className }) => (
          <React.Fragment>
            <textarea
              ref={ref}
              id="logline-textarea"
              className={classNames("form-element", className)}
              name="logLine"
              value={form.logLine}
              placeholder={trans("Enter your logline")}
              disabled={disableForm}
              onChange={onFormChange}
            />
            <ErrorField
              errorCondition={errorCondition}
              errorMessage={errorMessage}
              fieldId={"logline"}
            />
          </React.Fragment>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

LoglineField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default LoglineField;
