import React, { useState } from "react";
import { ConnectImage, Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import PropTypes from "prop-types";
import { VerifyEmail } from "../verification/VerifyEmail";
import { toggleModal } from "../../shared-components/modals/actions";
import { connect } from "react-redux";
import { toggleToast } from "../../shared-components/toasts/reducers";

function EmailReverification({
  email,
  userId,
  username,
  daysBetweenReminders,
  verified,
  toggleModal,
  toggleToast
}) {
  const { trans } = useTrans();
  const [optionNum, setOptionNum] = useState("option1");

  const [showUpdateEmail, setShowUpdateEmail] = useState(false);

  const handleChange = event => {
    setOptionNum(event.target.value);
  };

  const onVerifyEmail = () => {
    const url = "api/v3/users/" + username;
    const request = Promise.resolve(
      $.ajax({
        type: "PUT",
        url: url,
        data: {
          is_email_reverified: true
        }
      })
    );

    const message = verified ? trans("Thank you for confirming your email.") : trans("Thank you, please check your email to confirm your verification.") //prettier-ignore

    request.then(() => {
      // delete cookies
      wattpad.utils.destroyCookie("dbrc_" + username);
      wattpad.utils.destroyCookie("cnsc_" + username);
      toggleModal();
      toggleToast({
        className: "email-reverification-toast",
        body: message
      });
    });
  };

  const handleOnClickConfirm = event => {
    event.preventDefault();

    if (optionNum == "option1") {
      setShowUpdateEmail(true);
    } else if (optionNum == "option2") {
      onVerifyEmail();
    }
  };

  const handleOnClickExit = () => {
    // dbrc is days between reminders cookie
    const daysBetweenCookie = window.wattpad.utils.getCookie(
      "dbrc_" + username
    );
    // cnsc is current number of skips cookie
    const currSkipsCookie = window.wattpad.utils.getCookie("cnsc_" + username);

    // if the cookie has expired, reset the expiration date to 3 more days
    if (daysBetweenCookie == null) {
      wattpad.utils.setCookie("dbrc_" + username, 1, daysBetweenReminders);
    }

    // create another cookie that will force the modal to appear if user reaches max number of skips
    if (currSkipsCookie == null) {
      wattpad.utils.setCookie("cnsc_" + username, 1);
    } else {
      const currSkips = Number(currSkipsCookie);
      wattpad.utils.setCookie("cnsc_" + username, currSkips + 1);
    }

    toggleModal();
    toggleToast({
      className: "email-reverification-toast",
      body: trans("We'll send you a reminder in 3 days") //prettier-ignore
    });

    window.te.push("event", "home", "email_reverification", "", "skip", {
      username: username
    });
  };

  // check if user is using a gmail account
  const isGmail = userEmail => {
    const gmailDomains = {
      "gmail.com": true,
      "gmail.com.br": true,
      "gmail.de": true,
      "gmail.dk": true,
      "gmail.fr": true,
      "googlemail.com": true
    };

    const emailDomain = userEmail.split("@")[1];
    return gmailDomains[emailDomain] === true;
  };

  const renderEmailReverificationNotice = () => {
    return (
      <div className="email-reverification-modal-content">
        <button
          className="btn-no-style"
          onClick={handleOnClickExit}
          aria-label="Close modal"
        >
          <Icon
            iconName="fa-remove"
            color="wp-neutral-1"
            height="22"
            className="close-btn"
          />
        </button>
        <header className="email-reverification-heading">
          <ConnectImage
            name="/logos/wp-logo-orange.png"
            title="Wattpad"
            width="137"
            className="wp-logo"
          />
          <h1 aria-label={trans("Please verify your email.")}>
            {trans("Please verify your email.")}
          </h1>
        </header>
        <div className="modal-content">
          <p className="email-notes note">
            {trans("In order to ensure continued access to your account, please check that the email below is correct and that you have access to it.") /* prettier-ignore*/}
          </p>
          <p className="email-notes question">
            {trans("Do you want to keep the email below as your recovery contact?") /* prettier-ignore */}
          </p>
          <div className="email-wrapper">
            <p className="email-notes email-text">{email}</p>
            {isGmail(email) && (
              <p className="email-notes gmail-note">
                {trans("Note: If you are using a Gmail address with a period in it, the periods may not be displayed but emails will still reach you. ") /* prettier-ignore */}
              </p>
            )}
          </div>
          <div className="radio-wrapper">
            <form>
              <div className="radio-item">
                <input
                  id="option1"
                  name="options"
                  type="radio"
                  value="option1"
                  checked={optionNum == "option1"}
                  aria-label={trans("I need to update my email")}
                  onChange={handleChange}
                />
                <label htmlFor="option1">
                  {trans("I need to update my email")}
                </label>

                <input
                  id="option2"
                  name="options"
                  type="radio"
                  value="option2"
                  checked={optionNum == "option2"}
                  aria-label={trans("This is my current email")}
                  onChange={handleChange}
                />
                <label htmlFor="option2">
                  {trans("This is my current email")}
                </label>
              </div>
              <button
                className="btn-primary btn-md btn-email-confirm"
                onClick={handleOnClickConfirm}
                aria-label={trans("Confirm")}
              >
                {trans("Confirm")}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return showUpdateEmail ? (
    <div className="change-email-modal-wrapper">
      <div className="modal-body">
        <VerifyEmail
          isChangeEmailModal={true}
          email={email}
          userId={userId}
          isSuccess={false}
          hideModal={handleOnClickExit}
          //dismissModal will close the modal when the email has been successfully updated and there is no need to set cookies anymore
          dismissModal={() => {
            // delete cookies
            wattpad.utils.destroyCookie("dbrc_" + username);
            wattpad.utils.destroyCookie("cnsc_" + username);
            toggleModal();
          }}
        />
      </div>
      <button
        className="btn-no-style back-btn"
        onClick={() => {
          setShowUpdateEmail(false);
        }}
      >
        <Icon
          iconName="fa-left"
          color="wp-neutral-1"
          className="left-arrow-back"
        />
        <p className="back-btn-label">{trans("Back")}</p>
      </button>
    </div>
  ) : (
    renderEmailReverificationNotice()
  );
}

EmailReverification.propTypes = {
  email: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  daysBetweenReminders: PropTypes.number.isRequired,
  verified: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func
};

export default connect(
  null,
  { toggleModal, toggleToast }
)(EmailReverification);
