import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useTrans } from "../hooks";
import useReducerWithDefault from "../hooks/useReducerWithDefault";
import NewCheckBox from "./NewCheckbox";

const optionsList = [
  "anyLength",
  "oneToTen",
  "tenToTwenty",
  "twentyToFifty",
  "moreThanFifty"
];
const defaultOption = "anyLength";

const LengthFilter = ({ selectedOptions, handleFilterChange }) => {
  const { trans } = useTrans();
  const [state, handleUpdate] = useReducerWithDefault(
    optionsList,
    defaultOption,
    selectedOptions
  );

  const firstUpdate = useRef(true);

  useEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      } else {
        handleFilterChange(state, "length");
      }
    },
    [state, handleFilterChange]
  );

  const handleChange = event => {
    handleUpdate(
      event.currentTarget.checked ? "APPLY" : "REMOVE",
      event.currentTarget.value
    );
  };

  return (
    <div className="filter">
      <span className="sr-only">
        {trans("Length") + " " + trans("You can select multiple options") /* prettier-ignore */}
      </span>
      <div className="filter__title" aria-hidden>
        {trans("Length")}
      </div>
      <div className="filter__description" aria-hidden>
        {trans("You can select multiple options")}
      </div>
      <div className="filter__options">
        <NewCheckBox
          value="anyLength"
          checked={state.anyLength}
          onChange={handleChange}
          contentId="length1"
        >
          {trans("Any Length")}
        </NewCheckBox>
        <NewCheckBox
          value="oneToTen"
          checked={state.oneToTen}
          onChange={handleChange}
          contentId="length2"
        >
          {trans("1 - 10 Parts")}
        </NewCheckBox>
        <NewCheckBox
          value="tenToTwenty"
          checked={state.tenToTwenty}
          onChange={handleChange}
          contentId="length3"
        >
          {trans("10 - 20 Parts")}
        </NewCheckBox>
        <NewCheckBox
          value="twentyToFifty"
          checked={state.twentyToFifty}
          onChange={handleChange}
          contentId="length4"
        >
          {trans("20 - 50 Parts")}
        </NewCheckBox>
        <NewCheckBox
          value="moreThanFifty"
          checked={state.moreThanFifty}
          onChange={handleChange}
          contentId="length5"
        >
          {trans("50 Parts or more")}
        </NewCheckBox>
      </div>
    </div>
  );
};

LengthFilter.defaultProps = {
  handleFilterChange: () => {},
  selectedOptions: {}
};

LengthFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  selectedOptions: PropTypes.object
};

export default LengthFilter;
