import React from "react";
import PropTypes from "prop-types";

import Icon from "./Icon";

const MoreOptionsButton = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      className="btn btn-white dropdown-toggle profile-more-options"
      data-toggle="dropdown"
      aria-controls="profile-more-options"
      aria-expanded="false"
      aria-label="more options"
      role="menuitem"
    >
      <Icon name="more" size="16" className="more-icon" />
    </button>
  );
});

MoreOptionsButton.displayName = "MoreOptions";

MoreOptionsButton.propTypes = {
  onClick: PropTypes.func
};

export default MoreOptionsButton;
