import PropTypes from "prop-types";

import { PAID_MODEL_TYPE, BONUS_CHAPTER_TYPE } from "../../../constants";

const namespace = "PAID_PART_CONFIG";

export const FORM_REQUEST = `${namespace}/REQUEST`;
export const FORM_FETCH = `${namespace}/FETCH`;
export const FORM_POST = `${namespace}/POST`;
export const FORM_ERROR = `${namespace}/ERROR`;
export const FORM_UPDATE = `${namespace}/UPDATE`;
export const FORM_VISIBILITY = `${namespace}/VISIBILE`;

export const PAYWALL_NOT_FOUND_CODE = 3001;

export const DEFAULT_DATA_VALUES = {
  isLoading: true,
  isFormVisible: false,
  data: {
    story_id: 0,
    part_id: 0,
    author_id: 0,
    paid_model: PAID_MODEL_TYPE.BONUS_CHAPTER,
    author_note: "",
    chapter_description: "",
    bonus_type: BONUS_CHAPTER_TYPE.EXCLUSIVE_CHAPTER,
    is_free_part: false
  }
};

export const PaidPartDataProps = PropTypes.shape({
  story_id: PropTypes.number,
  part_id: PropTypes.number,
  author_id: PropTypes.number,
  paid_model: PropTypes.string,
  author_note: PropTypes.string,
  chapter_description: PropTypes.string,
  bonus_type: PropTypes.oneOf(Object.values(BONUS_CHAPTER_TYPE)),
  is_free_part: PropTypes.bool
});

export const IncludedFields = {
  DESCRIPTION: 1,
  AUTHOR_NOTE: 2
};

export const IncludedFieldsByBonusChapterType = {
  [BONUS_CHAPTER_TYPE.EXCLUSIVE_CHAPTER]: [
    IncludedFields.DESCRIPTION,
    IncludedFields.AUTHOR_NOTE
  ],
  [BONUS_CHAPTER_TYPE.WRITER_REVEAL]: [IncludedFields.DESCRIPTION],
  [BONUS_CHAPTER_TYPE.STORY_BRANCH]: [IncludedFields.DESCRIPTION]
};

const localizationPlaceholder = str => str; // TODO: Replace localizationPlaceholder function with trans hook

export const MODAL_BUTTON_CONTENT = () => {
  // const { trans } = useTrans();
  return {
    display: {
      type: {
        exclusiveChapter: localizationPlaceholder("Exclusive Chapter"),
        writerReveal: localizationPlaceholder("Writer Reveal"),
        storyBranch: localizationPlaceholder("Story Branch")
      }
    },
    button: {
      text: localizationPlaceholder("Configure"),
      default: localizationPlaceholder("Create Paid Part")
    }
  };
};

export const MODAL_CONTENT = () => {
  // const { trans } = useTrans();
  return {
    title: localizationPlaceholder("Choose a type of Paid Part"),
    description: localizationPlaceholder(
      "What type of part are you publishing?"
    ),
    descriptionUrlText: localizationPlaceholder("View Examples"),
    partTypeSelector: {
      label: localizationPlaceholder("Part Type Selector"),
      name: "bonus_type",
      options: [
        {
          label: localizationPlaceholder("Exclusive Chapter (15 coins)"),
          value: BONUS_CHAPTER_TYPE.EXCLUSIVE_CHAPTER
        },
        {
          label: localizationPlaceholder("Writer Reveal (8 coins)"),
          value: BONUS_CHAPTER_TYPE.WRITER_REVEAL
        }
        // TODO: Enable with story branch feature
        // {
        //   label: localizationPlaceholder("Story Branch"),
        //   value: BONUS_CHAPTER_TYPE.STORY_BRANCH
        // }
      ]
    },
    errorMessages: {
      chapterDescription: localizationPlaceholder(
        "Part Description is required"
      ),
      authorNote: localizationPlaceholder("Author's Note is required")
    },
    successMessage: localizationPlaceholder("Success!"),
    removeMessage: localizationPlaceholder(
      "This action will disable the paywall on this part. Continue?"
    ),
    removeText: localizationPlaceholder("Disable paywall"),
    cancelText: localizationPlaceholder("Discard changes"),
    saveText: localizationPlaceholder("Save paid part")
  };
};

export const COMMON_FIELDS = () => {
  // const { trans } = useTrans();
  return {
    chapterDescription: {
      title: localizationPlaceholder("Part Description"),
      description: localizationPlaceholder(
        "Tell your readers a little bit more about the new part!"
      ),
      textarea: {
        placeholder: localizationPlaceholder("Enter your description..."),
        name: "chapter_description"
      }
    },
    authorNote: {
      title: localizationPlaceholder("Author's Note"),
      description: localizationPlaceholder(
        "Entice your readers to purchase this part!"
      ),
      textarea: {
        placeholder: localizationPlaceholder("Enter your author's note..."),
        name: "author_note"
      }
    }
  };
};
