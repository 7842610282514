const unsanitizeHTML = str => {
  return str && typeof str === "string"
    ? str
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
    : str;
};

export { unsanitizeHTML };
