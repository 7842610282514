import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TagPill as TagItem } from "@wattpad/web-ui-library";
import TagItemWithIcon from "./TagItemWithIcon";

// Component expects relatedTags={ tags: [ 'array', 'of', 'tags' ], withIcon: (default false) }
export default class TagGrid extends React.Component {
  tagClickHandler = (evt, tag) => {
    this.props.onTagClicked && this.props.onTagClicked(tag, evt);
    window.wattpad.utils.stopEvent(evt);
    window.app.trigger("app:component:TagGridItem:click", evt);
  };

  tagKeyDownHandler = (evt, tag) => {
    if (evt.key === " " || evt.key === "Spacebar") {
      this.props.onTagClicked && this.props.onTagClicked(tag, evt);
      window.wattpad.utils.stopEvent(evt);
    }
  };

  render() {
    const tags = this.props.tagGridTags.tags,
      withIcon = this.props.tagGridTags.withIcon || false;

    if (tags && tags.length > 0) {
      return (
        <div className={classNames("tag-grid clearfix", this.props.className)}>
          <ul className="tag-items">
            {tags.map(tag => {
              if (withIcon) {
                return (
                  <li key={tag.id}>
                    <TagItemWithIcon
                      {...tag}
                      clickHandler={this.tagClickHandler}
                    />
                  </li>
                );
              } else {
                return (
                  <li key={tag.id}>
                    <TagItem
                      {...tag}
                      clickHandler={this.tagClickHandler}
                      keyDownHandler={this.tagKeyDownHandler}
                    />
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    }
    return null;
  }
}

TagGrid.propTypes = {
  tagGridTags: PropTypes.object.isRequired,
  onTagClicked: PropTypes.func,
  className: PropTypes.string
};
