import PropTypes from "prop-types";
import React, { useState } from "react";
import SignupForm from "./SignupForm";
import LoginForm from "./LoginForm";

const FormTypes = {
  SIGNUP: "signup",
  LOGIN: "login"
};

const AuthForm = ({
  focus,
  formType,
  msg,
  nextUrl,
  notice,
  subtitle,
  title,
  login = false
}) => {
  const [formName, setFormName] = useState(
    login ? FormTypes.LOGIN : FormTypes.SIGNUP
  );

  const toggleForm = () =>
    setFormName(
      formName === FormTypes.LOGIN ? FormTypes.SIGNUP : FormTypes.LOGIN
    );

  return formName === FormTypes.SIGNUP ? (
    <SignupForm
      focus={focus}
      formType={formType}
      nextUrl={nextUrl}
      subtitle={subtitle}
      title={title}
      toggleForm={toggleForm}
    />
  ) : (
    <LoginForm
      focus={focus}
      msg={msg}
      nextUrl={nextUrl}
      notice={notice}
      toggleForm={toggleForm}
    />
  );
};

AuthForm.propTypes = {
  focus: PropTypes.bool,
  formType: PropTypes.string,
  login: PropTypes.bool,
  msg: PropTypes.string,
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  signup: PropTypes.bool,
  signupFrom: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export default AuthForm;
