import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../../hooks";
import { FormValidation } from "../../FormValidation";
import { requiredField } from "../../helpers";
import ContestFormField from "../../ContestFormField";
import ErrorField from "../ErrorField";
import SeriesTypeField from "./SeriesTypeField";
import SeriesStandaloneField from "./SeriesStandaloneField";
import NumberOfStoriesInSeries from "./NumberOfStoriesInSeries";
import TotalPlannedStoriesInSeriesField from "./TotalPlannedStoriesInSeriesField";

const SeriesIdentificationField = ({
  form,
  disableForm,
  onSeriesIdentificationChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Series identification");

  const subtitle = (
    <div className="subtitle">
      {trans("Is this story intended to be a part of a series?")}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "isSeries" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.isSeries === "";

  let dropdownValue = form.isSeries === "" ? "" : form.isSeries ? "yes" : "no";

  if (form.language === "English") {
    return (
      <>
        <ContestFormField title={title} subtitle={subtitle}>
          <FormValidation validationRules={[requiredField(trans)]}>
            {({ ref, errorMessage, className }) => (
              <>
                <select
                  id="is-series-select"
                  ref={ref}
                  value={dropdownValue}
                  name="isSeries"
                  disabled={disableForm}
                  className={classNames("form-element", className)}
                  onChange={onSeriesIdentificationChange}
                  aria-label={trans("Select an option")}
                >
                  <option value="" disabled>
                    {trans("Select an option")}
                  </option>
                  <option key={"yes"} value={"yes"}>
                    {trans("Yes")}
                  </option>
                  <option key={"no"} value={"no"}>
                    {trans("No")}
                  </option>
                </select>
                <ErrorField errorMessage={errorMessage} fieldId={"is-series"} />
                {!errorMessage && (
                  <ErrorField
                    fieldId={"is-series"}
                    errorCondition={errorCondition}
                  />
                )}
              </>
            )}
          </FormValidation>
        </ContestFormField>
        {form.isSeries && (
          <div className="series-identification">
            <SeriesTypeField
              form={form}
              disableForm={disableForm}
              onSeriesIdentificationChange={onSeriesIdentificationChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />
            <SeriesStandaloneField
              form={form}
              disableForm={disableForm}
              onSeriesIdentificationChange={onSeriesIdentificationChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />
            <NumberOfStoriesInSeries
              form={form}
              disableForm={disableForm}
              onSeriesIdentificationChange={onSeriesIdentificationChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />
            <TotalPlannedStoriesInSeriesField
              form={form}
              disableForm={disableForm}
              onSeriesIdentificationChange={onSeriesIdentificationChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />
          </div>
        )}
      </>
    );
  }
  return null;
};

SeriesIdentificationField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onSeriesIdentificationChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default SeriesIdentificationField;
