import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import { NewCheckbox } from "../../../../shared-components";
import ErrorField from "./ErrorField";

const LegalConsentField = ({
  form,
  disableForm,
  onRadioBtnChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Legal consent");

  const subtitle = (
    <>
      <div className="subtitle">
        {/* prettier-ignore */ trans("I confirm the following: ")}
        <br />
        {/* prettier-ignore */ trans("I am not an employee, representative or agent of Wattpad, its parent companies, subsidiaries, affiliates, associated companies, or advertising agencies and I am not an immediate family member of or domiciled with any of the above;")}
        <br />
        <br />
        {/* prettier-ignore */ trans("I am at least 13 years old and, if I am under 18, I have my parent/legal guardian’s permission to enter the Wattys.")}
        <br />
        <br />
        {/* prettier-ignore */ trans("My story was written by me and all materials and information in the story are original to me and do not violate anyone’s copyright, trademark, intellectual property, or other rights.")}
      </div>
    </>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "legalConsent" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    !form.legalConsent;

  return (
    <div className="legal-consent-container">
      <div className="legal-consent-title-container">
        <h4>{title}</h4>
      </div>
      <div className="checkbox-group" id="legal-consent-checkbox">
        <NewCheckbox
          disabled={disableForm}
          name="legalConsent"
          onChange={onRadioBtnChange}
          checked={form.legalConsent}
          contentId="legal-consent-checkbox-message"
        >
          {subtitle}
        </NewCheckbox>
      </div>
      <ErrorField errorCondition={errorCondition} fieldId={"legal-consent"} />
    </div>
  );
};

LegalConsentField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onRadioBtnChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default LegalConsentField;
