import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../hooks";
import { simpleShorten } from "../helpers";
import { Icon } from ".";

const charLimit = 30;

const RecentSearches = ({ suggestedSearchTerms: recentSearches = [] }) => {
  const { trans } = useTrans();

  return (
    recentSearches &&
    recentSearches.length > 0 && (
      <>
        <h2 className="recent-searches__heading">{trans("Recent Searches")}</h2>
        <ul className="recent-searches__results-list">
          {recentSearches.map((term, index) => (
            <li key={index} className="recent-searches__list-item">
              <a
                className="recent-searches__item-link on-navigate"
                href={`/search/${wattpad.utils.urlEncodeWithSpace(term)}`}
              >
                <Icon
                  className="recent-searches__item-icon"
                  name="search"
                  size="16"
                />
                <span className="sr-only">{term}</span>
                <span aria-hidden>{simpleShorten(term, charLimit, false)}</span>
              </a>
            </li>
          ))}
        </ul>
      </>
    )
  );
};

RecentSearches.propTypes = {
  suggestedSearchTerms: PropTypes.array
};

export default RecentSearches;
