import Icon from "../../../Icon";
import React from "react";
import { useTrans } from "../../../../hooks";
import PropTypes from "prop-types";

export const UpdateUsernameModalContent = props => {
  const { trans } = useTrans();

  const handleSubmit = async event => {
    event.preventDefault();
    $.ajax({
      type: "POST",
      url: `apiv2/updateusername`,
      data: $(".on-form-submit").serialize()
    })
      .done(() => {
        props.hideModal();
        const redirectUrl = "/login?nexturl=/settings";
        window.location.replace(redirectUrl).reload();
      })
      .fail(err => {
        props.hideModal();
        const message =
          trans("Something went wrong, your changes were not saved. ") +
          (err.responseJSON.message || "");
        wattpad.utils.showToast(message, { type: "dismissable" });
      });
  };

  return (
    <div>
      <p>
        {trans(
          "Your username is important, here are a few things you need to know before you change it:"
        )}
      </p>
      <ol>
        <li>
          {trans(
            "It takes up to 24 hours for all changes to take effect. Your profile and stories may be inaccessible during this time."
          )}
        </li>
        <li>
          {trans(
            "Your user profile and widget links will change and the old ones will no longer work."
          )}
        </li>
        <li>{trans("You will need to re-login using your new username.")}</li>
      </ol>
      <form className="on-form-submit" onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={props.userId} />
        <div className="input-group-vertical">
          <div className="form-group">
            <label className="control-label" htmlFor="username">
              {trans("New Username")}
            </label>
            <input
              id="username"
              className="form-control input-lg enable"
              name="username"
              type="text"
              defaultValue=""
            />
          </div>
          <div className="form-group has-feedback">
            <label className="control-label" htmlFor="password">
              {trans("Confirm Password")}
            </label>
            <input
              id="password"
              className="form-control input-lg enable"
              name="password"
              type="password"
              defaultValue=""
            />
            <span
              onClick={props.onPasswordToggle}
              onKeyDown={props.onPasswordToggle}
              role="button"
              tabIndex="-1"
            >
              <Icon
                iconName="fa-view"
                height="24"
                color="wp-neutral-2"
                className="form-control-feedback interact"
              />
            </span>
          </div>
        </div>
        <a
          className="forgot-password on-navigate"
          href="/forgot?nextUrl=settings"
        >
          {trans("Forgot your password?")}
        </a>
        <input
          type="submit"
          className="btn btn-lg btn-orange btn-block enable"
          value={trans("Save")}
        />
      </form>
    </div>
  );
};

UpdateUsernameModalContent.propTypes = {
  userId: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  onPasswordToggle: PropTypes.func.isRequired
};
