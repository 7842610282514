import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from "react";
import PropTypes from "prop-types";
import { useTrans } from "../hooks";
import getPWPolicy from "../../helpers/client.get-pw-policy";
import getPWStrength from "../../helpers/client.get-pw-strength";
import WPInput from "./input/WPInput";
import classNames from "classnames";
import { debounce } from "lodash";

// Client side validation vars
const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 20;
// Strength api debounce value
const STRENGTH_DELAY = 500;

const PasswordFields = ({
  name,
  username,
  validatePassword,
  passwordRef,
  confirmPasswordRef,
  featureFlags
}) => {
  const { trans } = useTrans();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [policy, setPolicy] = useState();
  const [displayRules, setDisplayRules] = useState(false);
  const [strengthMessage, setStrengthMessage] = useState("");
  const [displayStrength, setDisplayStrength] = useState(false);
  const [matchMessage, setMatchMessage] = useState();
  const [displayMatchMessage, setDisplayMatchMessage] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const firstCheckPassword = useRef(true);
  const firstPasswordMatch = useRef(true);

  const notMatchString = trans("Passwords do not match.");
  const matchString = trans("Great, passwords match!");
  const invalidMatch = trans(
    "Passwords match but do not meet the requirements."
  );
  const matchRequiredString = trans("Re-enter password is required.");
  const invalidMessage = trans("Your password doesn't meet the requirements.");
  const FIELD_VALID_CLASS = "valid";
  const FIELD_INVALID_CLASS = "invalid";

  // Use getPolicy helper to fetch policy only when component renders
  useEffect(() => {
    getPWPolicy().then(data => {
      setPolicy(data);
      return;
    });
  }, []);

  // Re-execute validatePassword (the callback func) when the isMatching or isValid state is updated
  useEffect(
    () => {
      validatePassword(isMatching && isPasswordValid);
    },
    [isMatching, isPasswordValid, validatePassword]
  );

  // Call update match func when passwords change
  useEffect(
    () => {
      if (!firstPasswordMatch.current) {
        updatePasswordMatch(confirmPassword, newPassword);
      } else {
        firstPasswordMatch.current = false;
      }
    },
    [isPasswordValid, updatePasswordMatch, confirmPassword, newPassword]
  );

  // Use getPWStrength helper to fetch the password strength only when this function is called
  const fetchPWStrength = (firstName, lastName, username, password) => {
    return getPWStrength(firstName, lastName, username, password).then(data => {
      return data;
    });
  };

  const checkNewPasswordStrength = () => {
    const [firstName, lastName] = name
      ? name.split(" ")
      : [undefined, undefined];

    const clientValidation =
      newPassword.length >= MIN_PASSWORD_LENGTH &&
      newPassword.length <= MAX_PASSWORD_LENGTH;

    if (!newPassword) {
      // If newPassword is empty string hide rules and set inValid
      updateValidation(false, trans("Password is required."));
      setDisplayStrength(featureFlags);
    } else {
      // Fetch strength from endpoint using call func
      fetchPWStrength(firstName, lastName, username, newPassword)
        .then(data => {
          // Password is valid as long as strength does not return invalid
          updateValidation(data.strength !== "INVALID", data.displayText);
        })
        .catch(() => {
          // If call fails run client side validation and do not pass a strength message
          updateValidation(clientValidation);
        })
        .finally(() => {
          // Display the strength only when call resolves
          setDisplayStrength(true);
        });
    }
  };

  // Helper function to set strength message and validity for empty field, client side validation, and endpoint strength check
  const updateValidation = (validation, strengthMessage) => {
    setIsPasswordValid(validation);
    setStrengthMessage(
      validation || featureFlags ? strengthMessage : invalidMessage
    );
  };

  // Update newPassword state after debounce
  const onNewPasswordChange = newValue => {
    setNewPassword(newValue);
  };

  // Debounced new password change gets called for input field onChange
  const debouncedNewPasswordChange = useMemo(
    () => debounce(onNewPasswordChange, STRENGTH_DELAY),
    []
  );

  // Call check strength helper when newPassword state or username prop is updated
  useEffect(
    () => {
      if (!firstCheckPassword.current) {
        checkNewPasswordStrength();
      } else {
        firstCheckPassword.current = false;
      }
    },
    [newPassword, username]
  );

  // Clean up debounced strength call when component unmounts
  useEffect(() => debouncedNewPasswordChange.cancel(), [
    debouncedNewPasswordChange
  ]);

  const updatePasswordMatch = useCallback(
    (confirmPassword, newPassword) => {
      const isMatch = confirmPassword === newPassword && confirmPassword != "";

      // Before new onboarding epic: Only display match message when confirm password is not empty
      // After new onboarding epic: display match message even if confirm password is empty
      setDisplayMatchMessage(confirmPassword || featureFlags);

      if (!confirmPassword) {
        setIsMatching(false);
        setMatchMessage(matchRequiredString);
      } else if (isMatch && isPasswordValid) {
        setIsMatching(true);
        setMatchMessage(matchString);
      } else {
        setIsMatching(false);
        if (isMatch && !isPasswordValid) {
          setMatchMessage(invalidMatch);
        } else {
          setMatchMessage(notMatchString);
        }
      }
    },
    [invalidMatch, matchString, notMatchString, isPasswordValid]
  );

  return (
    <div className="password-fields">
      <div className="form-group has-feedback">
        <div
          className={classNames("field-container", {
            "has-msg":
              (displayRules && policy) || (displayStrength && strengthMessage)
          })}
        >
          <label className="control-label" htmlFor={"new_password"}>
            {trans("New Password")}
          </label>
          <WPInput
            inputType="password"
            inputRef={passwordRef}
            placeholder={trans("New Password")}
            name={"new_password"}
            id={"new_password"}
            alt={`${trans("New Password")} ${
              displayStrength && strengthMessage ? strengthMessage : ""
            }`}
            onBlur={() => {
              setDisplayRules(false);
              checkNewPasswordStrength();
            }}
            onChange={event => debouncedNewPasswordChange(event.target.value)}
            onFocus={() => setDisplayRules(true)}
            showVisibilityToggle={true}
            addClassName={classNames("password-field", {
              [FIELD_VALID_CLASS]: newPassword && isPasswordValid,
              [FIELD_INVALID_CLASS]:
                (newPassword || featureFlags) &&
                !isPasswordValid &&
                strengthMessage,
              ["old-validation-padding"]: !featureFlags && newPassword, // TODO: remove after new onboarding rollout
              ["old-padding"]: !featureFlags // TODO: remove after new onboarding rollout
            })}
            isValid={!!(newPassword && isPasswordValid)}
          />
          <div aria-live="assertive">
            {displayStrength &&
              strengthMessage && (
                <div
                  className={classNames("field-message", "password-strength", {
                    ["error-msg"]:
                      featureFlags && (!newPassword || !isPasswordValid)
                  })}
                >
                  {strengthMessage}
                </div>
              )}
          </div>
          <div aria-live="polite">
            {displayRules &&
              policy && (
                <div className="field-message password-rules">
                  <div className="intro-text">{policy?.rulesIntroText}</div>
                  <ul>
                    {policy?.rules?.map((rule, i) => {
                      return <li key={i}>{rule}</li>;
                    })}
                  </ul>
                </div>
              )}
          </div>
        </div>
        <div
          className={classNames("field-container", {
            "has-msg": !!displayMatchMessage
          })}
        >
          <label className="control-label" htmlFor={"confirm_password"}>
            {featureFlags
              ? trans("Re-enter password")
              : trans("Confirm Password")}
          </label>
          <WPInput
            inputType="password"
            placeholder={
              featureFlags
                ? trans("Re-enter password")
                : trans("Confirm Password")
            }
            inputRef={confirmPasswordRef}
            name={"confirm_password"}
            id={"confirm_password"}
            alt={`${
              featureFlags
                ? trans("Re-enter password")
                : trans("Confirm Password")
            } ${displayMatchMessage && matchMessage ? matchMessage : ""}`}
            onChange={event => setConfirmPassword(event.target.value)}
            onFocus={event => setConfirmPassword(event.target.value)}
            onBlur={() => {
              updatePasswordMatch(confirmPassword, newPassword);
            }}
            showVisibilityToggle={true}
            addClassName={classNames("password-field", {
              [FIELD_VALID_CLASS]: confirmPassword && isMatching,
              [FIELD_INVALID_CLASS]:
                (confirmPassword || featureFlags) &&
                !isMatching &&
                matchMessage,
              ["old-validation-padding"]: !featureFlags && confirmPassword, // TODO: remove after new onboarding rollout
              ["old-padding"]: !featureFlags // TODO: remove after new onboarding rollout
            })}
            isValid={!!(confirmPassword && isMatching)}
          />
          <div aria-live="assertive">
            {displayMatchMessage && (
              <div
                className={classNames("field-message", "match-message", {
                  ["error-msg"]:
                    featureFlags && (!confirmPassword || !isMatching)
                })}
              >
                {matchMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordFields.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string,
  // Callback so parent form can process updates for valid password states
  validatePassword: PropTypes.func,
  passwordRef: PropTypes.object,
  confirmPasswordRef: PropTypes.object,
  featureFlags: PropTypes.bool
};

export default PasswordFields;
