import PropTypes from "prop-types";
import React from "react";

import { useTrans } from "../../../hooks";

function DisabledReplyNotice({
  isMutedByCurrentUser,
  isBlockedByCurrentUser,
  activeThreadUsername
}) {
  const { trans } = useTrans();
  let noticeText, linkText, linkProps;

  // If the current user is muting the thread user, render a special case.
  // Otherwise render an ambiguous message that covers the other cases
  if (isBlockedByCurrentUser) {
    noticeText = trans("You have blocked %s and cannot reply to this conversation.", activeThreadUsername); // prettier-ignore
    linkText = trans("View Profile");
    linkProps = {
      href: `/user/${activeThreadUsername}`,
      className: "on-navigate"
    };
  } else if (isMutedByCurrentUser) {
    noticeText = trans("You have muted %s and cannot reply to this conversation.", activeThreadUsername); // prettier-ignore
    linkText = trans("View Profile");
    linkProps = {
      href: `/user/${activeThreadUsername}`,
      className: "on-navigate"
    };
  } else {
    noticeText = trans("You can’t reply to this conversation.");
    linkText = trans("Learn more");
    linkProps = {
      target: "_blank",
      rel: "noopener noreferrer",
      href:
        "https://support.wattpad.com/hc/en-us/articles/205956030-I-Can-t-Message-Another-User-"
    };
  }

  return (
    <div className="bottom-sticky">
      <div className="disabled-reply">
        <p>
          {noticeText + " "}
          {/* eslint-disable-next-line wp-lint-rules/valid-href */}
          <a {...linkProps}>{linkText}</a>
        </p>
      </div>
    </div>
  );
}

DisabledReplyNotice.propTypes = {
  isMutedByCurrentUser: PropTypes.bool.isRequired,
  isBlockedByCurrentUser: PropTypes.bool,
  activeThreadUsername: PropTypes.string.isRequired
};

export default DisabledReplyNotice;
