import React, { useState, useCallback } from "react";
import ProfileCard from "../../shared-components/ProfileCard";
import ProfileCardContainerSkeleton from "./ProfileCardContainerSkeleton";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import { sprintf } from "sprintf-js";

const USER_REQUEST_LIMIT = 10;
const USER_REQUEST_FIELDS =
  "username,name,avatar,description,numLists,numFollowers,numStoriesPublished,badges,following";

// Only the following and numFollowers fields change across re-renders for a ProfileCard.
const isProfileCardPropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.following === nextProps.following &&
    prevProps.numFollowers === nextProps.numFollowers
  );
};

// Using React.memo to prevent other ProfileCards from being re-rendered
// when the following/numFollowers field changes for one of them.
const MemoizedProfileCard = React.memo(ProfileCard, isProfileCardPropsEqual);

const ProfileCardContainer = ({
  users: initialUsers = [],
  searchTerm,
  isMobile
}) => {
  const [allUsers, setAllUsers] = useState(initialUsers);
  const [hasMore, setHasMore] = useState(
    initialUsers.length >= (isMobile ? 10 : 20)
  );
  const [offset, setOffset] = useState(initialUsers.length);
  const [a11yIndex, seta11yIndex] = useState(-1);
  const [numProfilesFetched, setNumProfilesFetched] = useState(0);
  const { trans, ngettext } = useTrans();

  const onRefChange = useCallback(node => {
    node && node.focus();
  }, []);

  const loadMoreUsers = () => {
    const url =
      "/v4/search/users/?query=" +
      encodeURIComponent(searchTerm.trim()) +
      `&limit=${USER_REQUEST_LIMIT}&offset=${offset}&fields=${USER_REQUEST_FIELDS}`;

    Promise.resolve($.get(url))
      .then(response => {
        // ensuring that only unique users are added from response
        const newUsersFromResponse = response.filter(
          entry => !allUsers.some(el => el.username === entry.username)
        );

        seta11yIndex(allUsers.length);
        setNumProfilesFetched(response.length);
        setAllUsers(allUsers => [...allUsers, ...newUsersFromResponse]);
        setHasMore(response.length >= USER_REQUEST_LIMIT);
        setOffset(offset => offset + USER_REQUEST_LIMIT);
        app?.trigger?.("app:component:search:refreshSearchAd");
      })
      .catch(() => {
        wattpad.utils.showToast(
          trans("Something went wrong. Please try again"),
          {
            type: "dismissable"
          }
        );
      });
  };

  const clearCache = (username, isFollowing) => {
    app?.trigger?.(
      "app:component:search:profile:follow:clearCache",
      username,
      isFollowing
    );
  };

  const onProfileCardClick = (username, index) => {
    // click event
    window.te.push("event", "search", "user", null, "click", {
      userid: username, // userid has been aliased in the parser
      search: searchTerm,
      position: index
    });

    app?.trigger?.("app:component:search:profile:click:clearCache");
    app.router.navigate(`/user/${username}`, { trigger: true });
  };

  const onFollowButtonClick = async (username, index) => {
    const currentUserUsername = wattpad?.utils?.currentUser().get("username");
    const currFollowStatus = allUsers[index].following;
    const url = `/api/v3/users/${currentUserUsername}/following`;

    try {
      const result = await $.ajax({
        url: url,
        type: currFollowStatus === true ? "DELETE" : "POST",
        data: { users: username }
      });

      if (result.code === 200) {
        const currFollowerCount = allUsers[index].numFollowers;
        const newFollowerCount = currFollowStatus
          ? currFollowerCount - 1
          : currFollowerCount + 1;

        setAllUsers(previousState => [
          ...previousState.slice(0, index),
          {
            ...previousState[index],
            numFollowers: newFollowerCount,
            following: !currFollowStatus
          },
          ...previousState.slice(index + 1)
        ]);

        clearCache(username, !currFollowStatus);
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      <ProfileCardContainerSkeleton numSkeletonCards={isMobile ? 10 : 20} />
      <ul className="list-group list-container">
        {allUsers.map((user, index) => (
          <React.Fragment key={user.username}>
            {index === a11yIndex && (
              <li tabIndex={-1} ref={onRefChange} className="list-group-item">
                <span className="sr-only">
                  {/* prettier-ignore */ sprintf(ngettext("%s more profile", "%s more profiles", numProfilesFetched), numProfilesFetched)}
                </span>
              </li>
            )}
            <li className="list-group-item new-item">
              <MemoizedProfileCard
                {...user}
                searchTerm={searchTerm}
                index={index}
                onProfileCardClick={onProfileCardClick}
                onFollowButtonClick={onFollowButtonClick}
              />
            </li>
          </React.Fragment>
        ))}
      </ul>
      {hasMore && (
        <button className="btn-load-more load-more" onClick={loadMoreUsers}>
          {trans("Load more profiles")}
        </button>
      )}
    </>
  );
};

ProfileCardContainer.propTypes = {
  users: PropTypes.array,
  searchTerm: PropTypes.string,
  isMobile: PropTypes.bool
};

export default ProfileCardContainer;
