import React, { useState } from "react";
import classNames from "classnames";
import { Button } from "@wattpad/web-ui-library";

import { useGetUser, useQueryParams, useTrans } from "../../../hooks";
import ReportInputField, {
  ReportInputType
} from "./ReportInputs/ReportInputField";
import ReportCheckboxField from "./ReportInputs/ReportCheckboxField";
import ReportDropdownField from "./ReportInputs/ReportDropdownField";
import {
  createTicketRequest,
  getStoryPartUrl,
  getStoryUrl,
  handleFormSubmit,
  submitSupportTicket
} from "./HelpDmcaUtils";
import {
  DescriptionAllegedOptions,
  DmcaFormFieldIds,
  EMAIL_VALIDATIONS,
  getDmcaInfoOne,
  getDmcaInfoTwo,
  getDmcaSuccessOne,
  getDmcaSuccessTwo,
  REQUIRED_VAL,
  SIGNATURE_VALIDATIONS
} from "./HelpDmcaConstants";

const HelpDmcaPage = () => {
  const user = useGetUser();
  const { trans } = useTrans();
  const queryParams = useQueryParams();

  const [isValid, setIsValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const partId = queryParams.get("partId");
  const storyId = queryParams.get("storyId");
  const reportedUser = queryParams.get("reportedUser");
  const language = queryParams.get("language");

  const reportedUrl = partId ? getStoryPartUrl(partId) : getStoryUrl(storyId);

  const onFormChange = event => {
    const formElement = event.currentTarget;
    setIsValid(formElement.checkValidity());
  };

  const onSubmit = formState => {
    setIsSubmitted(true);
    setIsValid(formState.isValid);

    if (!formState.isValid) {
      return;
    }

    submitSupportTicket(
      createTicketRequest(
        formState.data,
        storyId,
        partId,
        reportedUser,
        language
      )
    )
      .done(() => {
        setIsSuccess(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .fail(() => {
        wattpad?.utils?.showToast(
          trans("Something went wrong. Please try again")
        );
      });
  };

  const renderDmcaForm = () => (
    <div className="form-container">
      <div className="info">
        <p dangerouslySetInnerHTML={{ __html: getDmcaInfoOne(trans) }} />
        <br />
        <p dangerouslySetInnerHTML={{ __html: getDmcaInfoTwo(trans) }} />
        <br />
        <span className="important">
          {trans(
            "IMPORTANT: There may be legal and financial consequences for submitting false claims. Abuse of this form may lead to your account being terminated."
          )}
        </span>
      </div>
      <form
        noValidate
        onChange={onFormChange}
        onSubmit={handleFormSubmit(onSubmit)}
        className={classNames("form", { submitted: isSubmitted })}
      >
        <h2>{trans("Contact Information")}</h2>

        <ReportInputField
          id={DmcaFormFieldIds.FIRST_NAME}
          label={trans("First name")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.LAST_NAME}
          label={trans("Last name")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.USERNAME}
          initialValue={user.username}
          label={trans("Wattpad username")}
        />

        <ReportInputField
          id={DmcaFormFieldIds.EMAIL}
          label={trans("Email")}
          initialValue={user.email}
          validations={EMAIL_VALIDATIONS}
        />

        <ReportInputField
          id={DmcaFormFieldIds.COMPANY}
          label={trans("Company")}
        />

        <ReportInputField
          id={DmcaFormFieldIds.ADDRESS}
          label={trans("Mailing address")}
          placeholder={trans("For example: 123 Main St. is an address")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.COUNTRY}
          label={trans("Country")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.CITY}
          label={trans("City")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.PROVINCE}
          label={trans("State/Province")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.POSTAL_CODE}
          label={trans("Postal code")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.PHONE_NUMBER}
          label={trans("Phone number")}
          validations={REQUIRED_VAL}
        />

        <h2>
          {trans("Description and Location of the Allegedly Infringing Work")}
        </h2>

        <ReportDropdownField
          id={DmcaFormFieldIds.DESC_ALLEGED}
          label={trans("Description of the alleged infringement")}
          options={DescriptionAllegedOptions(trans)}
        />

        <ReportInputField
          id={DmcaFormFieldIds.ORIGINAL_WORK}
          label={trans("URL of your original work")}
          placeholder={trans("Paste the URL")}
          validations={REQUIRED_VAL}
        />

        <ReportInputField
          id={DmcaFormFieldIds.WHERE_TO_FIND_ORIGINAL}
          label={trans(
            "Please tell us where we can find the alleged infringement within the story or account"
          )}
          placeholder={trans(
            "For example: “Half way down Chapter Three starting from the line “___.” Or: “They’re linking to a download of my work in their About section.”"
          )}
          inputType={ReportInputType.TEXT_AREA}
        />

        <ReportInputField
          id={DmcaFormFieldIds.REPORTED_URLS}
          inputType={ReportInputType.TEXT_AREA}
          label={trans(
            "URL(s) of the allegedly infringing work located on Wattpad"
          )}
          initialValue={reportedUrl}
          validations={REQUIRED_VAL}
        />

        <h2>{trans("Confirmation + Electronic Signature")}</h2>

        <ReportCheckboxField
          id={DmcaFormFieldIds.IS_ACCURATE}
          label={trans("The information in this notice is accurate.")}
        />

        <ReportCheckboxField
          id={DmcaFormFieldIds.GOOD_FAITH}
          label={trans(
            "I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law."
          )}
        />

        <ReportCheckboxField
          id={DmcaFormFieldIds.OWNER}
          label={trans(
            "I state under penalty of perjury that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."
          )}
        />

        <br />

        <ReportInputField
          id={DmcaFormFieldIds.SIGNATURE}
          label={trans(
            "Typing your full legal name in this box acts as your electronic signature"
          )}
          validations={SIGNATURE_VALIDATIONS}
        />

        {isSubmitted &&
          !isValid && (
            <div className="error-message">
              {trans("A mandatory field has been left blank.")}
              <br />
              {trans("Please ensure all necessary fields are filled in.")}
            </div>
          )}

        <Button label={trans("Submit")} />
      </form>
    </div>
  );

  const renderSubmittedMessage = () => (
    <div className="success">
      <h2>
        {trans(
          "Your report has been submitted. We appreciate your efforts in reporting alleged copyright infringement!"
        )}
      </h2>
      <br />
      <p dangerouslySetInnerHTML={{ __html: getDmcaSuccessOne(trans) }} />
      <br />
      <p dangerouslySetInnerHTML={{ __html: getDmcaSuccessTwo(trans) }} />
      <br />
      <p>{trans("We look forward to helping you!")}</p>
      <br />
      <p>{trans("The Wattpad Copyright Team")}</p>
    </div>
  );

  return (
    <div className="help-dmca-page" role="main">
      <h1 className="text-center">{trans("DMCA Takedown Notice")}</h1>
      {isSuccess ? renderSubmittedMessage() : renderDmcaForm()}
    </div>
  );
};

export default HelpDmcaPage;
