import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import InputTextarea from "../../../../shared-components/input/InputTextarea";

import { IncludedFields, COMMON_FIELDS } from "../constants";

const CommonPaidPartFields = ({
  className,
  includedFields,
  authorNote,
  chapterDescription,
  onFormChange
}) => {
  const COPY = COMMON_FIELDS();

  const handleInputChange = event => {
    onFormChange(event.target);
  };

  return (
    <div className={classnames("common-paid-part-fields", className)}>
      {includedFields.includes(IncludedFields.DESCRIPTION) && (
        <div className="section">
          <div className="section-title">{COPY.chapterDescription.title}</div>
          <div className="section-description">
            {COPY.chapterDescription.description}
          </div>

          <InputTextarea
            className={"long-field"}
            textareaClassName={"long"}
            name={COPY.chapterDescription.textarea.name}
            placeholder={COPY.chapterDescription.textarea.placeholder}
            value={chapterDescription}
            characterLimit={250}
            onChange={handleInputChange}
          />
        </div>
      )}

      {includedFields.includes(IncludedFields.AUTHOR_NOTE) && (
        <div className="section">
          <div className="section-title">{COPY.authorNote.title}</div>
          <div className="section-description">
            {COPY.authorNote.description}
          </div>

          <InputTextarea
            className={"long-field"}
            textareaClassName={"long"}
            name={COPY.authorNote.textarea.name}
            placeholder={COPY.authorNote.textarea.placeholder}
            value={authorNote}
            characterLimit={250}
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  );
};

CommonPaidPartFields.defaultProps = {
  className: "",
  includedFields: [],
  onFormChange: () => {}
};

CommonPaidPartFields.propTypes = {
  className: PropTypes.string,
  authorNote: PropTypes.string,
  chapterDescription: PropTypes.string,
  includedFields: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(IncludedFields).map(i => IncludedFields[i]))
  ),
  onFormChange: PropTypes.func.isRequired
};

export default CommonPaidPartFields;
