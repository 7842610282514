import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TagItem from "./TagItem";
import TagItemWithIcon from "./TagItemWithIcon";

// Component expects relatedTags={ tags: [ 'array', 'of', 'tags' ], withIcon: (default false) }
export default class TagCarousel extends React.Component {
  tagClickHandler = (evt, tag) => {
    window.wattpad.utils.stopEvent(evt);
    window.app.trigger(
      "app:component:TagCarouselItem:click",
      evt.currentTarget.textContent,
      evt
    );
    this.props.onTagClicked && this.props.onTagClicked(tag);
  };

  render() {
    const tags = this.props.relatedTags.tags,
      withIcon = this.props.relatedTags.withIcon || false;

    return (
      <div
        className={classNames("tag-carousel", this.props.className, {
          hidden: !(tags?.length > 0)
        })}
      >
        <div className="tag-items">
          {tags?.map(tag => {
            if (withIcon) {
              return (
                <TagItemWithIcon
                  key={tag.id}
                  {...tag}
                  clickHandler={this.tagClickHandler}
                />
              );
            } else {
              return (
                <TagItem
                  key={tag.id}
                  {...tag}
                  clickHandler={this.tagClickHandler}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}

TagCarousel.propTypes = {
  relatedTags: PropTypes.object.isRequired,
  className: PropTypes.string,
  onTagClicked: PropTypes.func
};
