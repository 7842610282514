export const MAP_WATTYS_SITE_TO_LINKS = {
  1: "https://www.wattpad.com/wattys/", //English
  2: "https://www.wattpad.com/wattys/fr/", //French
  3: "https://www.wattpad.com/wattys/it/", // Italian
  4: "https://www.wattpad.com/wattys/de/", //German
  5: "https://www.wattpad.com/wattys/es-mx/", //Spanish
  6: "https://www.wattpad.com/wattys/pt/", //Portuguese
  18: "https://www.wattpad.com/wattys/tl-ph/", //Filipino
  20: "https://www.wattpad.com/wattys/id/", //Indonesian
  23: "https://www.wattpad.com/wattys/tr/" //Turkish
};

export const DEFAULT_WATTYS_SITE_LINK = "https://www.wattpad.com/wattys/"; // default to English

export const MAP_WATTYS_ELIGIBILITY_TO_LINKS = {
  1: "https://www.wattpad.com/1444516997", //English
  5: "https://www.wattpad.com/1444521582", //Spanish
  18: "https://www.wattpad.com/1444522881" //Filipino
};

export const DEFAULT_ELIGIBILITY_LINK = "https://www.wattpad.com/1444516997"; // default to English

export const DEFAULT_IDENTITIES_LINK = "https://www.wattpad.com/1444517056"; // default to English

export const MAP_IDENTITIES_LINK = {
  1: "https://www.wattpad.com/1444517056", //English
  5: "https://www.wattpad.com/1444521717", //Spanish
  18: "https://www.wattpad.com/1444522953" //Filipino
};
export const MAP_WATTYS_GENRE_LINKS = {
  1: "https://www.wattpad.com/1444517056-the-2024-watty-awards-how-to-submit/page/2", //English
  5: "https://www.wattpad.com/1444521717-los-premios-watty-2024-c%C3%B3mo-inscribirte/page/2", //Spanish
  18: "https://www.wattpad.com/1444522953-ang-2024-watty-awards-paano-magsumite/page/2" //Filipino
};

export const DEFAULT_GENRE_LINK =
  "https://www.wattpad.com/1444517056-the-2024-watty-awards-how-to-submit/page/2"; // default to English

export const RULES_LINK =
  "https://docs.google.com/gview?embedded=true&url=https://b4qlw8tl.media.zestyio.com/Wattys-2024-Official-Rules.pdf"; // all languages

export const WATTYS_CLOSING_DATETIME = new Date(
  "7 Aug 2024 23:59:59 GMT-0400"
).getTime();
export const CURRENT_WATTYS_YEAR = new Date().getFullYear();

export const PLOT_SUMMARY_CHARACTER_MIN = 20;
export const PLOT_SUMMARY_CHARACTER_MAX = 3500;
export const LOGLINE_CHARACTER_MAX = 500;
export const LOGLINE_CHARACTER_MIN = 20;
export const EMAIL_CHARACTER_MAX = 128;
export const OTHER_MATURE_THEMES_CHARACTER_MAX = 100;
export const OTHER_IDENTITIES_CHARACTER_MAX = 100;
export const MIN_STORIES = 1;
export const MAX_STORIES = 100;
export const VALID_EMAIL_REGEX = /^[a-zA-Z0-9_.'+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const SUBMITTED_FLAG = "SUBMITTED";
export const ELIGIBLE_FLAG = "ELIGIBLE";
export const NEAR_ELIGIBLE_FLAG = "NEAR_ELIGIBLE";
export const NOT_ELIGIBLE_FLAG = "NOT_ELIGIBLE";
export const ONGOING_FLAG = "ONGOING";
export const COMPLETE_FLAG = "COMPLETE";

export const MAP_WATTYS_LANGUAGES = trans => [
  { key: "English", value: trans("English") },
  { key: "French", value: trans("French") },
  { key: "Filipino", value: trans("Filipino") },
  { key: "German", value: trans("German") },
  { key: "Indonesian", value: trans("Indonesian") },
  { key: "Italian", value: trans("Italian") },
  { key: "Portuguese", value: trans("Portuguese") },
  { key: "Spanish", value: trans("Spanish") },
  { key: "Turkish", value: trans("Turkish") }
];

export const MAP_MATURITY_RATINGS = trans => [
  { key: "EXPLICIT", value: trans("Explicit") },
  { key: "INEXPLICIT", value: trans("Inexplicit") }
];

export const MAP_MATURE_THEMES = (trans, isFrenchForm) => [
  {
    key: "NONE",
    value: trans("My story does not contain any mature themes")
  },
  {
    key: "SEX",
    value: isFrenchForm
      ? /* prettier-ignore */ trans("Sexual Content")
      : /* prettier-ignore */ trans("Sex")
  },
  { key: "EXPLICIT_LANGUAGE", value: trans("Explicit Language") },
  { key: "DRUG_USE", value: trans("Drug Use") },
  { key: "SELF_HARM", value: trans("Self-harm themes or scenes") },
  { key: "GRAPHIC_VIOLENCE", value: trans("Graphic Violence") },
  { key: "OTHER", value: trans("Other") }
];

export const MAP_STORY_DECLARATION = trans => [
  { key: "ONGOING", value: trans("Ongoing") },
  { key: "COMPLETE", value: trans("Complete") }
];

export const MAP_SERIES_TYPES = trans => [
  { key: "FIRST", value: trans("First Story") },
  { key: "SEQUEL", value: trans("Sequel") }
];

export const MAP_ANTICIPATED_STORY_LENGTH = trans => [
  { key: "LESS_100", value: trans("<100k words") },
  { key: "100_TO_150", value: trans("100k-150k words") },
  { key: "MORE_150", value: trans(">150k words") }
];

export const MAP_STORY_TYPES = trans => [
  { key: "ADULT_FICTION", value: trans("Adult Fiction") },
  { key: "TEEN_FICTION", value: trans("Teen Fiction") }
];

export const MAP_WATTYS_GENRES = trans => [
  { key: "ACTION_THRILLER", value: trans("Action & Thriller") },
  { key: "FANTASY", value: trans("Fantasy") },
  { key: "HISTORICAL", value: trans("Historical") },
  { key: "HORROR", value: trans("Horror") },
  { key: "MYSTERY_DETECTIVE", value: trans("Mystery & Detective") },
  { key: "PARANORMAL", value: trans("Paranormal") },
  { key: "ROMANCE", value: trans("Romance") },
  { key: "SCIENCE_FICTION", value: trans("Science Fiction") }
];

export const MAP_WATTYS_GENRES_NON_ENGLISH = trans => [
  { key: "ACTION_THRILLER", value: trans("Action & Thriller") },
  { key: "FANTASY", value: trans("Fantasy") },
  { key: "HISTORICAL", value: trans("Historical") },
  { key: "HORROR", value: trans("Horror") },
  { key: "MYSTERY_DETECTIVE", value: trans("Mystery & Detective") },
  { key: "PARANORMAL", value: trans("Paranormal") },
  { key: "ROMANCE", value: trans("Romance") },
  { key: "SCIENCE_FICTION", value: trans("Science Fiction") }
];

export const MAP_CHARACTER_REPRESENTATION_IDENTITIES = trans => [
  { key: "BIPOC", value: trans("BIPOC") },
  { key: "LGBTQ2", value: trans("LGBTQ2+") },
  { key: "MARGINALIZED_RELIGION", value: trans("Marginalized religion") },
  { key: "NEURODIVERGENT", value: trans("Neurodivergent") },
  { key: "DISABILITY", value: trans("Living with a disability") },
  { key: "NONE", value: trans("None") },
  { key: "OTHER", value: trans("Other") }
];
