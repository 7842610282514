import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../../shared-components";

function UserBadge({ name, size, allowedBadges }) {
  let badge;

  if (allowedBadges.indexOf(name) > -1) {
    // Prioritize badge
    if (name === "staff") {
      badge = name;
    } else if (name === "verified") {
      badge = name;
    } else if (name === "ambassador") {
      badge = name;
    }
  }
  return badge ? <Icon name={badge} size={size} viewBox={20} /> : null;
}

UserBadge.defaultProps = {
  size: "16",
  allowedBadges: ["verified", "staff", "ambassador"]
};

UserBadge.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  allowedBadges: PropTypes.array
};

export { UserBadge };
