import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { injectTrans } from "../../helpers";

function WriterAnalyticsNavBarUI({ story, section, trans }) {
  return (
    <nav className="pull-right" id="writer-analytics-nav-bar">
      <ul>
        <li
          data-section="activities"
          className={classnames({
            active: section === "activities",
            "not-active": section !== "activities"
          })}
        >
          <a className={"on-nav-item"} href={`/myworks/${story.id}/analytics/`}>
            {trans("Overview")}
          </a>
        </li>
        <li
          data-section="interactions"
          className={classnames({
            active: section === "interactions",
            "not-active": section !== "interactions"
          })}
        >
          <a
            className={"on-nav-item"}
            href={`/myworks/${story.id}/analytics/interactions`}
          >
            {trans("Engagement")}
          </a>
        </li>
        <li
          data-section="demographics"
          className={classnames({
            active: section === "demographics",
            "not-active": section !== "demographics"
          })}
        >
          <a
            className={"on-nav-item"}
            href={`/myworks/${story.id}/analytics/demographics`}
          >
            {trans("Demographics")}
          </a>
        </li>
      </ul>
    </nav>
  );
}

WriterAnalyticsNavBarUI.propTypes = {
  story: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  trans: PropTypes.func
};

export default injectTrans(WriterAnalyticsNavBarUI);
