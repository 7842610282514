import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import StoryHeroItem from "../components/StoryHeroItem";
import StoryCarousel from "../components/StoryCarousel";
import SectionHeader from "../components/SectionHeader";
//import SmallNavigation from "./SmallNavigation";

/**
 * A component containing a hero item as well as a carousel of other stories.
 */
const StorySwimlaneWithHeroItem = ({
  callTrackingUrls,
  clickTrackingUrls,
  impressionTrackingUrls,
  expandPrompt,
  heading,
  heroItem,
  index,
  isPromoted,
  multiRows,
  onStoryClick,
  page,
  sectionType,
  setPosition,
  storyItems,
  subheading,
  //navigationItem,
  wasSeen,
  windowWidth
}) => {
  const el = useRef(null);

  useEffect(
    () => {
      const boundingBox = el.current.getBoundingClientRect();
      setPosition(
        index,
        sectionType,
        boundingBox.top + window.scrollY,
        boundingBox.bottom + window.scrollY
      );
    },
    [index, sectionType, setPosition]
  );

  let hasEitherListOrHero = (storyItems && storyItems.length !== 0) || heroItem;
  return hasEitherListOrHero ? (
    <div
      className={classnames("story-swimlane-with-hero", sectionType)}
      ref={el}
    >
      <div className="module-content">
        <SectionHeader
          expandPrompt={expandPrompt}
          heading={heading}
          sectionType={sectionType}
          subheading={subheading}
          isPromoted={false}
        />
        <div className="hero-item">
          <StoryHeroItem
            {...heroItem}
            windowWidth={windowWidth}
            clickTrackingUrls={clickTrackingUrls}
            impressionTrackingUrls={impressionTrackingUrls}
            isPromoted={isPromoted}
            callTrackingUrls={callTrackingUrls}
            onStoryClick={onStoryClick}
            heroItem={heroItem}
            index={index}
            wasSeen={wasSeen}
            sectionType={sectionType}
          />
        </div>
        {multiRows ? (
          multiRows.map((row, i) => [
            <SectionHeader
              heading={row.heading}
              subheading={row.subheading}
              key={`${row.heading}-${i}`}
            />,
            <div className={"story-carousel"} key={`${row.items[0].id}-${i}`}>
              <StoryCarousel
                index={index}
                onStoryClick={onStoryClick}
                page={page}
                sectionType={sectionType}
                stories={row.items}
                wasSeen={wasSeen}
                windowWidth={windowWidth}
              />
            </div>
          ])
        ) : (
          <div className={"story-carousel"}>
            <StoryCarousel
              index={index}
              onStoryClick={onStoryClick}
              page={page}
              sectionType={sectionType}
              stories={storyItems}
              wasSeen={wasSeen}
              windowWidth={windowWidth}
            />
          </div>
        )}
        {/* {navigationItem ? (
          <SmallNavigation
            sectionType={sectionType}
            heading={navigationItem.heading}
            subheading={navigationItem.subheading}
            isSubsection={true}
          />
        ) : null} */}
      </div>
    </div>
  ) : null;
};

StorySwimlaneWithHeroItem.defaultProps = {
  onStoryClick: null
};

StorySwimlaneWithHeroItem.propTypes = {
  callTrackingUrls: PropTypes.func.isRequired,
  clickTrackingUrls: PropTypes.array,
  impressionTrackingUrls: PropTypes.array,
  expandPrompt: PropTypes.object,
  heading: PropTypes.string,
  heroItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isPromoted: PropTypes.bool,
  multiRows: PropTypes.array,
  navigationItem: PropTypes.object,
  onStoryClick: PropTypes.func,
  page: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  storyItems: PropTypes.array,
  subheading: PropTypes.string,
  wasSeen: PropTypes.bool,
  windowWidth: PropTypes.number
};

export default StorySwimlaneWithHeroItem;
