import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ResizableTextarea } from "../ResizableTextarea";
import { buildLabel, buildAbout } from "./helpers/input-labels";

function InputTextarea({
  label,
  info,
  about,
  name,
  placeholder,
  value,
  characterLimit,
  hideCharacterLimit,
  minRows,
  maxRows,
  textareaLineHeight,
  onChange,
  labelClassName,
  className,
  textareaClassName
}) {
  function onTyping(event) {
    // Form value cannot exceed character limit
    if (characterLimit) {
      event.target.value = event.target.value.substr(0, characterLimit);
    }
    onChange(event);
  }

  return (
    <div className={classNames("form-field", className)}>
      {buildLabel({ label, labelClassName, tooltip: info })}
      {buildAbout(about)}
      <ResizableTextarea
        value={value || ""}
        name={name}
        placeholder={placeholder}
        onChange={onTyping}
        characterLimit={characterLimit}
        hideCharacterLimit={hideCharacterLimit}
        minRows={minRows}
        maxRows={maxRows}
        textareaLineHeight={textareaLineHeight}
        className={classNames("planner-textarea", textareaClassName)}
      />
    </div>
  );
}

InputTextarea.defaultProps = {
  value: "",
  minRows: 1,
  maxRows: 10,
  textareaLineHeight: 26,
  onChange: () => {}
};

InputTextarea.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  about: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  characterLimit: PropTypes.number,
  hideCharacterLimit: PropTypes.bool,
  minRows: PropTypes.number.isRequired,
  maxRows: PropTypes.number.isRequired,
  textareaLineHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  textareaClassName: PropTypes.string
};

export default InputTextarea;
