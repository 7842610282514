import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// DSContainer

function OneColPage(props) {
  return (
    <div className={classNames("ds ds-one-col-page", props.variant)}>
      <div className="column">{props.children}</div>
    </div>
  );
}

OneColPage.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string
};

OneColPage.defaultProps = {
  variant: ""
};

export { OneColPage };
