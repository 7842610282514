const namespace = "WORKS_ITEM_DETAILS";
export const TAB_UPDATE = `${namespace}/UPDATE`;

export function updateActiveTab(currentTab) {
  return function(dispatch) {
    dispatch({ type: TAB_UPDATE, data: currentTab });
  };
}

const initialState = {
  worksItemDetailsActiveTab: "toc"
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TAB_UPDATE: {
      return {
        ...state,
        worksItemDetailsActiveTab:
          action.data || initialState.worksItemDetailsActiveTab
      };
    }
    default:
      return state;
  }
}
