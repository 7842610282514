import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { buildLabel } from "./helpers/input-labels";
import { useTrans } from "../../hooks";
import moment from "moment";

import Icon from "../Icon";

/**
 *  DateTime Fields
 *  Takes in UTC time, converts to local for display and passes UTC back to onChange
 *
 */
const DateTimeField = ({
  className,
  labelClassName,
  label,
  value,
  name,
  min,
  max,
  onChange,
  disabled,
  required,
  timeIncrements
}) => {
  const { trans } = useTrans();
  const optionalAttributes = {};
  min && (optionalAttributes.min = min);
  max && (optionalAttributes.max = max);
  const [selectedDate, setSelectedDate] = useState(
    moment(value)
      .locale("en")
      .format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState(generateDefaultTimeString());
  const [availableTimes, setAvailableTimes] = useState([]);

  const handleOnChange = (date, time) => {
    let splitTimes = time.split(":");
    let comboDate = moment(date)
      .locale("en")
      .set("hours", splitTimes[0])
      .set("minutes", splitTimes[1]);
    onChange(
      comboDate?.isValid && comboDate.isValid() ? comboDate.toISOString() : null
    );
  };

  const handleDateChange = e => {
    const earliestTimeSlot = moment()
      .locale("en")
      .add(timeIncrements, "m");
    const requestedTimeSlot = moment(
      `${e.target.value} ${selectedTime}`
    ).locale("en");
    setSelectedDate(e.target.value);
    // Clear the time field if outside of available time slots
    requestedTimeSlot < earliestTimeSlot && setSelectedTime("");
    handleOnChange(e.target.value, selectedTime);
  };

  const handleTimeChange = newTime => {
    setSelectedTime(newTime);
    handleOnChange(selectedDate, newTime);
  };

  function generateDefaultTimeString() {
    return moment(value).isAfter()
      ? moment(value)
          .locale("en")
          .format("HH:mm:00")
      : "";
  }

  const timeLabelString =
    parseInt(app.get("language"), 10) === 1
      ? trans("Time")
      : trans("Clock Time");

  const generateAvailableTimes = useCallback(
    () => {
      let availableTimes = [];

      let endTime = moment(selectedDate).endOf("day");
      let displayTime = moment(selectedDate).startOf("day");
      let timeValue = moment(selectedDate)
        .locale("en")
        .startOf("day");
      // If publish date is desired today don't let it be set earlier than 15 mins after the next interval from current time
      let nextAvailableSlot = moment().add(timeIncrements, "m");
      while (displayTime < endTime) {
        availableTimes.push({
          displayValue: displayTime.format("hh:mm A"),
          value: timeValue.format("HH:mm:00"),
          isDisabled: displayTime < nextAvailableSlot
        });
        displayTime.add(timeIncrements, "m");
        timeValue.add(timeIncrements, "m");
      }

      return availableTimes;
    },
    [selectedDate, timeIncrements]
  );

  useEffect(
    () => {
      setAvailableTimes(generateAvailableTimes());
    },
    [setAvailableTimes, generateAvailableTimes]
  );

  return (
    <div className={classNames("date-time-field", className)}>
      {buildLabel({
        label,
        labelClassName
      })}
      <div className="field-row">
        <input
          className="date-form-field text-body"
          type="date"
          name={name}
          {...optionalAttributes}
          disabled={disabled}
          required={required}
          onChange={handleDateChange}
          value={selectedDate}
        />
        <div className="select-container">
          <select
            className="time-form-field text-body"
            onChange={e => {
              handleTimeChange(e.target.value);
            }}
            value={selectedTime}
          >
            <option value="">{timeLabelString}</option>
            {availableTimes.map(time => {
              return (
                <option
                  key={`option-${time.value}`}
                  value={time.value}
                  disabled={time.isDisabled}
                >
                  {time.displayValue}
                </option>
              );
            })}
          </select>
          <Icon name="history" className="time-icon vcenter" />
        </div>
      </div>
    </div>
  );
};

DateTimeField.defaultProps = {
  disabled: false,
  required: false,
  onChange: () => {},
  timeIncrements: 15
};

DateTimeField.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  timeIncrements: PropTypes.number
};

export default DateTimeField;
