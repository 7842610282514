import moment from "moment";

const fromNow = (time, options = {}) => {
  var result = moment(time),
    format = "MMM DD, YYYY hh:mmA";

  if (
    moment().diff(time, "hours") < 24 ||
    (options.fuzzyTime && moment().diff(time, "days") < 7)
  ) {
    var dropSuffix = options.dropSuffix || false;
    return result.fromNow(dropSuffix);
  } else if (options.fuzzyTime) {
    //if its fuzzyTime, drop the time
    if (moment().isSame(time, "year")) {
      //if the year is same as the current year, drop the year.
      format = "MMM DD";
    } else {
      format = "MMM DD, YYYY";
    }
  }
  // i.e. January 1, 2016
  else if (options.fullDate) {
    format = "MMMM D, YYYY";
    // i.e. Wed, April 26, 2023
  } else if (options.dayFormat) {
    format = "ddd, MMM D, YYYY";
  }

  return result.format(format);
};

export { fromNow };
