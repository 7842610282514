import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import { MAP_WATTYS_GENRES, MAP_WATTYS_GENRES_NON_ENGLISH } from "../constants";
import { NewCheckbox } from "../../../../shared-components";
import ContestFormField from "../ContestFormField";

const SubsequentGenreIdentificationField = ({
  form,
  disableForm,
  onSubsequentGenreChange,
  storyLanguage
}) => {
  const { trans } = useTrans();

  const title = trans("Subsequent genre identification");

  const subtitle = (
    <div className="subtitle">
      {trans(
        "Are there any other genres, in addition to your primary choice, your story fits into? You may select up to three additional genres. This question is not required."
      )}
    </div>
  );

  let maxOptions = 3;
  let maxOptionsReached = form.genreOthers?.length >= maxOptions;

  const isEnglish =
    storyLanguage === "English" && parseInt(app.get("language"), 10) === 1;
  const genres = isEnglish ? MAP_WATTYS_GENRES : MAP_WATTYS_GENRES_NON_ENGLISH;

  if (form.genrePrimary) {
    return (
      <ContestFormField title={title} subtitle={subtitle}>
        <div id="genre-others">
          {genres(trans)
            .filter(genre => genre.key !== form.genrePrimary)
            .map(genre => (
              <div className="checkbox-group" key={genre.key}>
                <NewCheckbox
                  name={genre.key}
                  disabled={
                    disableForm ||
                    (maxOptionsReached && !form.genreOthers.includes(genre.key))
                  }
                  onChange={onSubsequentGenreChange}
                  contentId={`genre-others-${genre.key}`}
                  checked={form.genreOthers.includes(genre.key)}
                >
                  {genre.value}
                </NewCheckbox>
              </div>
            ))}
        </div>
      </ContestFormField>
    );
  }
  return null;
};

SubsequentGenreIdentificationField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onSubsequentGenreChange: PropTypes.func.isRequired,
  storyLanguage: PropTypes.string
};

export default SubsequentGenreIdentificationField;
