import React from "react";
import PropTypes from "prop-types";

import ConnectImage from "../../ConnectImage";
import { injectTrans } from "../../../helpers";

class AnnouncementModal extends React.Component {
  render() {
    const { trans } = this.props;

    return (
      <div className="announcement-modal">
        <ConnectImage name="shared/megaphone_teal.png" />

        <header>{trans("Announce an Important Post")}</header>
        <div>
          {/* prettier-ignore */ trans("By using this feature, this message will be sent to all your followers’ notification feeds. Please note that you can only post up to %s announcements per day - save these for special occasions!", 3)}
        </div>

        <div className="buttons">
          <a
            href="https://support.wattpad.com/hc/en-us/articles/201463980-Making-an-announcement"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-orange"
          >
            {trans("Learn more")}
          </a>
          <button className="btn btn-orange" data-dismiss="modal">
            {trans("Got it")}
          </button>
        </div>
      </div>
    );
  }

  static propTypes = {
    trans: PropTypes.func.isRequired
  };
}

export default injectTrans(AnnouncementModal);
