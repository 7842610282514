import React, { useState } from "react";
import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import PropTypes from "prop-types";
import VerificationFeatures from "./VerificationFeatures";
import { VerifyEmail } from "./VerifyEmail";

function PleaseVerify({ email, userId, hideModal }) {
  const { trans } = useTrans();

  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [isChangeEmailModal, setIsChangeEmailModal] = useState(false);
  const [isSentEmailSuccess, setIsSentEmailSuccess] = useState(false);

  const onClose = event => {
    wattpad.utils.stopEvent(event);
    hideModal();
  };

  const handleOnClickChangeEmail = event => {
    wattpad.utils.stopEvent(event);
    setShowVerifyEmailModal(true);
    setIsChangeEmailModal(true);
  };

  const handleOnClickResendEmail = event => {
    wattpad.utils.stopEvent(event);

    $.ajax({
      type: "POST",
      url: "/api/v3/users/validate",
      data: { activation_email: "true" },
      success: function() {
        setIsSentEmailSuccess(true);
        setShowVerifyEmailModal(true);
        setIsChangeEmailModal(false);
      },
      error: function() {
        setIsSentEmailSuccess(false);
        setShowVerifyEmailModal(true);
        setIsChangeEmailModal(false);
      }
    });
  };

  const modalMessage = trans("Please check your email %s to complete sign-up.", email) /* prettier-ignore */
    + " " + trans("After you click the link in your email, you'll be able to:") ; /* prettier-ignore */

  const renderPleaseVerify = () => {
    return (
      <div className="please-verify-modal-content">
        <button
          className="close on-close"
          onClick={onClose}
          aria-label={trans("Close")}
          tabIndex="0"
        >
          <Icon iconName="fa-remove" height="16" color="wp-neutral-2" />
        </button>
        <h1>{trans("Please verify your email.")}</h1>
        <div className="modal-content">
          <p className="modal-message">
            {modalMessage.split(email)[0]}
            <strong>{email}</strong>
            {modalMessage.split(email)[1]}
          </p>
          <VerificationFeatures />
          <div className="button-wrapper">
            <form>
              <div>
                <button
                  className="btn-primary btn-md btn-change-email"
                  onClick={handleOnClickChangeEmail}
                  aria-label={trans("Change email")}
                >
                  {trans("Change email")}
                </button>
                <button
                  className="btn-secondary btn-md btn-resend-email"
                  onClick={handleOnClickResendEmail}
                  aria-label={trans("Resend email")}
                >
                  {trans("Resend email")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const renderModal = () => {
    if (showVerifyEmailModal) {
      return (
        <VerifyEmail
          isChangeEmailModal={isChangeEmailModal}
          email={email}
          userId={userId}
          isSuccess={isSentEmailSuccess}
          hideModal={hideModal}
        />
      );
    }

    return renderPleaseVerify();
  };

  return renderModal();
}

PleaseVerify.propTypes = {
  email: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  hideModal: PropTypes.func
};

export default PleaseVerify;
