import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";
import { useTrans } from "../../../hooks";

import { UpdatePasswordModalContent } from "./userSettingsModal/UpdatePasswordModalContent";
import { UpdateUsernameModalContent } from "./userSettingsModal/UpdateUsernameModalContent";

export const ModalTypes = {
  USERNAME: "username",
  PASSWORD: "password",
  ADD_PASSWORD: "addPassword"
};

const UserSettingsModal = props => {
  const { trans } = useTrans();

  const title =
    props.type === ModalTypes.ADD_PASSWORD
      ? trans("Create Password")
      : trans("Change %s", props.type);

  const renderUserSettingsContent = () => {
    if (props.type === ModalTypes.USERNAME) {
      return (
        <UpdateUsernameModalContent
          userId={props.userId}
          hideModal={props.hideModal}
          onPasswordToggle={props.onPasswordToggle}
        />
      );
    } else {
      return (
        <UpdatePasswordModalContent
          hasPassword={props.type === ModalTypes.PASSWORD}
          userId={props.userId}
          name={props.name}
          username={props.username}
          hideModal={props.hideModal}
        />
      );
    }
  };

  return (
    <div id="user-settings-modal">
      <button
        className="close on-close"
        onClick={props.hideModal}
        aria-label={trans("Close")}
      >
        <Icon iconName="fa-remove" height="16" color="wp-neutral-2" />
      </button>
      <header className="panel-heading">
        <h3>{title}</h3>
      </header>
      <div className="panel-body">
        <div className="body-text">{renderUserSettingsContent()}</div>
      </div>
    </div>
  );
};

UserSettingsModal.propTypes = {
  onPasswordToggle: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string,
  username: PropTypes.string
};

export default UserSettingsModal;
