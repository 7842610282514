import { sprintf } from "sprintf-js";
import { resizeCover } from "../../../../helpers";

const SLIDE_WIDTHS = {
  contReadingMW: 136,
  contReadingDW: 213,
  MW: 115,
  DW: 178,
  divider: 33,
  screenOffset: 15
};

export const formatStoryDataforStorySlides = (items, ngettext, isMobile) => {
  let slides = [];
  let totalSlidesWidth = 0;
  let slideStartIndices = [];

  slides.push(
    //continue reading slide
    {
      ...items[0].data[0],
      secondary_label: items[0].heading,
      type: items[0].type,
      progressBarAccessibility: progressBarAccessibility(
        items[0].data[0],
        ngettext
      )
    }
  );

  slideStartIndices.push(SLIDE_WIDTHS.screenOffset);

  //length plus the continue reading slide
  totalSlidesWidth += isMobile
    ? SLIDE_WIDTHS.contReadingMW
    : SLIDE_WIDTHS.contReadingDW;

  items.forEach((item, itemIndex) => {
    if (itemIndex === 0) {
      return;
    }

    //add the length of the trailing dividers
    totalSlidesWidth += SLIDE_WIDTHS.divider;

    item.data.forEach((story, storyIndex) => {
      slideStartIndices.push(totalSlidesWidth + SLIDE_WIDTHS.screenOffset);
      slides.push({
        ...story,
        primary_label: storyIndex === 0 ? items[itemIndex].heading : "",
        leading_divider: storyIndex === 0 ? true : false,
        progressBarAccessibility: progressBarAccessibility(story, ngettext),
        oneSlideSection: storyIndex === 0 && item.data.length === 1
      });

      totalSlidesWidth += isMobile ? SLIDE_WIDTHS.MW : SLIDE_WIDTHS.DW;
    });
  });

  return { slides, totalSlidesWidth, slideStartIndices };
};

export const progressBarAccessibility = (story, ngettext) => {
  let accessibitlyString = sprintf(
    ngettext("%s part read", "%s parts read", story.current_part.number),
    story.current_part.number
  );

  accessibitlyString += ", ";

  if (story.new_parts > 0) {
    accessibitlyString += sprintf(
      ngettext("%s new part", "%s new parts", story.new_parts),
      story.new_parts
    );
    accessibitlyString += ", ";
  }

  accessibitlyString += sprintf(
    ngettext("%s part total", "%s parts total", story.total_parts),
    story.total_parts
  );

  return accessibitlyString;
};

export const sizeStoryCover = (windowWidth, type, cover) =>
  windowWidth <= 425
    ? type === "currentRead"
      ? resizeCover(cover, 50)
      : resizeCover(cover, 35)
    : type === "currentRead"
      ? resizeCover(cover, 95)
      : resizeCover(cover, 80);

export const slideSubLabel = (storyData, ngettext) =>
  storyData.new_parts != 0
    ? sprintf(
        ngettext("%s new part", "%s new parts", storyData.new_parts),
        storyData.new_parts
      )
    : sprintf(
        ngettext(
          "%s part left",
          "%s parts left",
          storyData.total_parts - storyData.current_part.number
        ),
        storyData.total_parts - storyData.current_part.number
      );

export const calcNumStories = (isMobile, windowWidth) => {
  if (isMobile) {
    //mobile web is less flexible for varying slide widths
    //so we show the last story first after indexing in case it was
    //cut off
    if (windowWidth > 650) {
      return { NUM_OF_STORIES_SHOWN: 4, NUM_STORIES_TO_INDEX: 3 };
    } else if (windowWidth <= 650 && windowWidth > 320) {
      return { NUM_OF_STORIES_SHOWN: 3, NUM_STORIES_TO_INDEX: 2 };
    } else {
      return { NUM_OF_STORIES_SHOWN: 2, NUM_STORIES_TO_INDEX: 1 };
    }
  } else {
    return { NUM_OF_STORIES_SHOWN: 5, NUM_STORIES_TO_INDEX: 5 };
  }
};

export const storyIsInView = element => {
  var rect = element.getBoundingClientRect();

  return rect.top >= 0 && rect.right <= window.innerWidth;
};
