/**
 * Unschedules a previously scheduled part
 *
 * @param {StoryPartCreateModel} partModel
 * @returns {String|Object} Returns an error, or a result object
 */

module.exports = partModel => {
  return new Promise((resolve, reject) => {
    const storyId = parseInt(partModel.get("groupid"), 10);
    const partId = parseInt(partModel.get("id"), 10);
    const defaultError = wattpad.utils.trans("Failed to unschedule part");

    Promise.resolve($.post(`/v4/parts/${partId}/unschedule-publish`))
      .then(result => {
        if (!result?.success) {
          return reject(defaultError);
        }

        wattpad.utils.cacheBust(
          ["StoryCreateModel", "StoryPartCreateModel"],
          [{ id: storyId }, { id: partId }],
          null,
          true
        );
        return resolve(result);
      })
      .catch(e => {
        if (!e || e?.status === 200) {
          return reject(defaultError);
        }
        if (e?.responseJSON?.message) {
          return reject(e?.responseJSON?.message);
        } else if (e?.responseJSON?.errors.length > 0) {
          return reject(e?.responseJSON?.errors[0]);
        }
      });
  });
};
