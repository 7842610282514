import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StoryExpandedCarousel from "../components/StoryExpandedCarousel";
import StoryCarousel from "../components/StoryCarousel";
import SectionHeader, { HeadingPillProps } from "../components/SectionHeader";
import RankedStorySlide from "../components/RankedStorySlide";
/**
 * A component containing a hero item as well as a carousel of other stories.
 */
const StorySwimlane = ({
  sectionType,
  expandPrompt,
  background,
  heading,
  headingPill,
  subheading,
  stories,
  isPromoted,
  index,
  windowWidth,
  wasSeen,
  setPosition,
  storiesToShow,
  mobileStoriesToShow,
  storyWidth,
  page,
  withDivider = true,
  onStoryClick,
  callTrackingUrls,
  clickTrackingUrls
}) => {
  const el = useRef(null);
  let swimlaneClassName, slideComponent;

  switch (sectionType) {
    case "rankedStoryList":
      swimlaneClassName = "ranked-story-swimlane";
      slideComponent = RankedStorySlide;
      break;
    default:
      swimlaneClassName = "story-swimlane";
  }

  useEffect(
    () => {
      // Can't track position if the element is null
      if (!el.current) return;

      const boundingBox = el.current.getBoundingClientRect();
      setPosition(
        index,
        sectionType,
        boundingBox.top + window.scrollY,
        boundingBox.bottom + window.scrollY
      );
    },
    [index, sectionType, setPosition]
  );

  if (stories === null || stories.length === 0) {
    return null;
  } else if (sectionType === "storyExpanded") {
    return (
      <div className={classNames("story-swimlane module", background)} ref={el}>
        <div className="module-content full-width">
          <SectionHeader
            heading={heading}
            headingPill={headingPill}
            expandPrompt={expandPrompt}
            sectionType={sectionType}
            subheading={subheading}
            isPromoted={isPromoted}
          />
          <StoryExpandedCarousel
            sectionType={sectionType}
            stories={stories}
            windowWidth={windowWidth}
            index={index}
            wasSeen={wasSeen}
            storiesToShow={storiesToShow}
            storyWidth={storyWidth}
            page={page}
            onStoryClick={onStoryClick}
            clickTrackingUrls={clickTrackingUrls}
            callTrackingUrls={callTrackingUrls}
          />
          {withDivider && <hr className="module-divider" />}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classNames(swimlaneClassName, "module", background)}
        ref={el}
      >
        <div className="module-content">
          <SectionHeader
            heading={heading}
            headingPill={headingPill}
            expandPrompt={expandPrompt}
            sectionType={sectionType}
            subheading={subheading}
            isPromoted={isPromoted}
          />
          <StoryCarousel
            sectionType={sectionType}
            stories={stories}
            windowWidth={windowWidth}
            index={index}
            wasSeen={wasSeen}
            storiesToShow={storiesToShow}
            mobileStoriesToShow={mobileStoriesToShow}
            storyWidth={storyWidth}
            page={page}
            onStoryClick={onStoryClick}
            clickTrackingUrls={clickTrackingUrls}
            callTrackingUrls={callTrackingUrls}
            SlideComponent={slideComponent}
          />
          {withDivider && <hr className="module-divider" />}
        </div>
      </div>
    );
  }
};

StorySwimlane.defaultProps = {
  onStoryClick: null
};

StorySwimlane.propTypes = {
  heading: PropTypes.string,
  headingPill: PropTypes.shape(HeadingPillProps),
  index: PropTypes.number.isRequired,
  expandPrompt: PropTypes.object,
  background: PropTypes.string,
  isPromoted: PropTypes.bool,
  sectionType: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  subheading: PropTypes.string,
  stories: PropTypes.array.isRequired,
  wasSeen: PropTypes.bool,
  windowWidth: PropTypes.number,
  storiesToShow: PropTypes.number,
  mobileStoriesToShow: PropTypes.number,
  storyWidth: PropTypes.number,
  page: PropTypes.string,
  withDivider: PropTypes.bool,
  onStoryClick: PropTypes.func,
  clickTrackingUrls: PropTypes.array,
  callTrackingUrls: PropTypes.func
};

export default StorySwimlane;
