import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import {
  ConnectImage,
  Icon,
  Loader,
  Modal,
  MODAL_ID
} from "../../shared-components";
import TagMeta from "../tags/TagMeta";
import LibraryPopoverButton from "./LibraryPopoverButton";
import {
  count,
  formatStoryUrl,
  fromNow,
  isClient,
  sanitizeHTML,
  simpleShorten
} from "../../../components/helpers";
import {
  fetchReadingPosition,
  clearReadingPosition
} from "./story-item-reducer";

const StoryPreviewModal = ({
  showModal,
  onClose,
  showTagRankings,
  isLoading,
  readingPosition,
  fetchReadingPosition,
  clearReadingPosition
}) => {
  const { trans } = useTrans();
  const isMobile = isClient() && window.innerWidth < 575;
  const [data, setData] = useState({});
  const [currentPart, setCurrentPart] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const modalRef = useRef(null);
  const defaultData = {
    isTracked: false
  };

  const getPage = () => {
    const page = document.baseURI || document.URL; // ie11 does not support document.baseURI
    if (page.indexOf("tags/") > -1) return "tag page";
    if (page.indexOf("premiumpicks") > -1) return "premium_picks";
    if (page.indexOf("home") > -1) return "home";
    return "browse page";
  };

  const redirectToStory = url => {
    window.location = formatStoryUrl(url);
  };

  const handleModalRef = useCallback(
    modal => {
      if (!modal?.data) return;

      // Assign defaults
      Object.keys(defaultData).forEach(prop => {
        modal.data[prop] = modal.data[prop] || defaultData[prop];
      });
      setData(modal.data);
      return modal;
    },
    [defaultData, setData]
  );

  const handleOnStoryDetails = evt => {
    evt.preventDefault();

    if (data.isTracked) {
      window.te.push("event", "modal", "story_details", "details", "click", {
        page: getPage(),
        story_id: data.id
      });
    }
    redirectToStory(data.url);
  };
  const handleOnCover = () => {
    if (data.isTracked) {
      window.te.push("event", "modal", "story_details", "cover", "click", {
        page: getPage(),
        story_id: data.id
      });
    }
  };

  const handleOnStartReading = () => {
    if (data.isTracked) {
      window.te.push("event", "modal", "story_details", null, "read", {
        page: getPage(),
        story_id: data.id
      });

      window.te.push("event", null, null, null, "reader_open_v0", {
        page: getPage(),
        storyid: data.id,
        partid: currentPart.id || data.firstPartId
      });
    }
    redirectToStory(currentPart.url || `/${data.firstPartId}`);
  };

  const handleOnRankingNavigate = evt => {
    evt.preventDefault();
    window.te.push("event", "story_details", "tag_ranking", null, "click", {
      storyid: data.id,
      tag: data.tagRankings?.[0]?.name
    });
    window.location = `${data.url}/rankings`;
  };

  const handleOnClose = () => {
    setCurrentPart({});
    setData({});
    clearReadingPosition();
    onClose && onClose();
  };

  const handleSignupTriggered = () => {
    modalRef.current.hide();
  };

  useEffect(() => {
    const authenticated = wattpad.utils.currentUser().authenticated();
    setIsAuthenticated(authenticated);
  }, []);

  useEffect(
    () => {
      // Call reading sync API
      // Data to update Read/Continue Reading button
      if (!isAuthenticated || !data.firstPartId || currentPart.id) {
        return;
      }

      if (isLoading) {
        fetchReadingPosition(data.firstPartId);
      } else {
        if (!readingPosition?.part) return;
        const partId = parseInt(readingPosition?.part, 10);
        setCurrentPart({
          id: partId,
          url: `/${partId}`
        });
      }
    },
    [
      data,
      currentPart,
      isLoading,
      fetchReadingPosition,
      readingPosition,
      setCurrentPart,
      isAuthenticated
    ]
  );

  return (
    <Modal
      id={MODAL_ID.STORY_PREVIEW_MODAL}
      {...{ showModal, onClose: handleOnClose }}
      ref={el => {
        modalRef.current = el;
        handleModalRef(el);
      }}
    >
      {data.id ? (
        <div className="story-preview-content">
          <div className="modal-content">
            <div className="modal-body clearfix">
              <a
                href={data.url}
                className="cover cover-lg pull-left"
                onClick={evt => {
                  handleOnCover(evt);
                  handleOnStoryDetails(evt);
                }}
              >
                <img src={data.cover} alt={data.title} />
              </a>
              <div className="content">
                <h3 className="modal-title">
                  <a
                    href={data.url}
                    className="modal-title"
                    onClick={handleOnStoryDetails}
                  >
                    {data.title}
                  </a>
                </h3>

                <div className="meta">
                  <span>
                    {" "}
                    <Icon
                      iconName="fa-view"
                      height="10"
                      color="wp-neutral-2"
                    />{" "}
                    {count(data.readCount)}
                  </span>
                  <span>
                    {" "}
                    <Icon
                      iconName="fa-vote"
                      height="10"
                      color="wp-neutral-2"
                    />{" "}
                    {count(data.voteCount)}{" "}
                  </span>
                  <span>
                    {" "}
                    <Icon
                      iconName="fa-list"
                      height="10"
                      color="wp-neutral-2"
                    />{" "}
                    {count(data.numParts)}
                  </span>
                </div>

                <div className="actions">
                  <button
                    className="start-reading btn btn-sm btn-inline btn-orange btn-left-align"
                    onClick={handleOnStartReading}
                  >
                    {isAuthenticated &&
                      isLoading && (
                        <div className="is-loading">
                          <Loader />
                        </div>
                      )}
                    {(!isAuthenticated || !isLoading) &&
                      (!currentPart.id || currentPart?.id == data.firstPartId
                        ? trans("Read")
                        : trans("Continue"))}
                  </button>

                  <div className="button-group relative inline-block">
                    <LibraryPopoverButton
                      storyId={parseInt(data.id)}
                      onSignupTriggered={handleSignupTriggered}
                    />
                  </div>
                </div>

                {data.description && (
                  <p className="description">
                    {simpleShorten(data.description, 220, false)}
                    <a href={data.url} onClick={handleOnStoryDetails}>
                      {" "}
                      <span className="read-more">{trans("More")}</span>
                    </a>
                  </p>
                )}

                {showTagRankings && data.tagRankings ? (
                  <div className="tag-ranking">
                    <ConnectImage
                      name="/shared/ranking-medal.png"
                      className="tag-ranking-medal"
                      height="27"
                      width="22"
                    />

                    <span>
                      <b>
                        {trans(
                          "#%1$s in %2$s",
                          data.tagRankings?.[0]?.rank,
                          sanitizeHTML(data.tagRankings?.[0]?.name)
                        )}
                      </b>
                    </span>

                    <a
                      href={`${data.url}/rankings`}
                      onClick={handleOnRankingNavigate}
                    >
                      {trans("See all rankings")}
                    </a>
                  </div>
                ) : (
                  <TagMeta
                    tagMeta={{
                      tags: data.tags,
                      storyId: data.id,
                      numShown: isMobile ? 2 : 3
                    }}
                    defaultClickEvents={true}
                  />
                )}

                <div className="info">
                  {data.completed ? (
                    <>
                      <Icon iconName="fa-check" height="12" color="wp-base-2" />{" "}
                      <span className="completed text">
                        {trans("completed")}
                      </span>
                    </>
                  ) : (
                    <>
                      <Icon iconName="fa-more" height="12" color="wp-base-1" />{" "}
                      <span className="ongoing text">{trans("ongoing")}</span>
                      {(data.modifyDate ||
                        data.lastPublishedPart.createDate) && (
                        <time
                          dateTime={fromNow(
                            data.modifyDate ||
                              data.lastPublishedPart.createDate,
                            {
                              fuzzyTime: true
                            }
                          )}
                        >
                          {trans(
                            "Updated %s",
                            fromNow(
                              data.modifyDate ||
                                data.lastPublishedPart.createDate,
                              {
                                fuzzyTime: true
                              }
                            )
                          )}
                        </time>
                      )}
                    </>
                  )}

                  {data.mature && (
                    <div className="mature">
                      <Icon
                        iconName="fa-info"
                        height="12"
                        color="wp-neutral-2"
                      />{" "}
                      {trans("Mature")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

StoryPreviewModal.defaultProps = {
  showTagRankings: false
};

StoryPreviewModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  showTagRankings: PropTypes.bool,
  isLoading: PropTypes.bool,
  readingPosition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      part: PropTypes.number,
      position: PropTypes.string
    })
  ]),
  fetchReadingPosition: PropTypes.func,
  clearReadingPosition: PropTypes.func
};

export default connect(
  state => ({
    isLoading: state.storyItem.isLoading,
    readingPosition: state.storyItem.readingPosition
  }),
  { fetchReadingPosition, clearReadingPosition }
)(StoryPreviewModal);
