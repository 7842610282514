import React from "react";
import PropTypes from "prop-types";

import ProfileCardSkeleton from "../../shared-components/ProfileCardSkeleton";

const ProfileCardContainerSkeleton = ({ numSkeletonCards }) => {
  return (
    <div className="profile-skeleton-container hidden">
      {Array.from({ length: numSkeletonCards }, (_, index) => (
        <ProfileCardSkeleton key={index} />
      ))}
    </div>
  );
};

ProfileCardContainerSkeleton.propTypes = {
  numSkeletonCards: PropTypes.number.isRequired
};

export default ProfileCardContainerSkeleton;
