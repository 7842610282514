import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../hooks";
import {
  CURRENT_WATTYS_YEAR,
  DEFAULT_ELIGIBILITY_LINK,
  MAP_WATTYS_ELIGIBILITY_TO_LINKS
} from "../ContestForm/constants";

const OngoingEligibleBanner = ({ onBtnClick }) => {
  const { trans } = useTrans();
  const wattysEligibilityLink =
    MAP_WATTYS_ELIGIBILITY_TO_LINKS[parseInt(app.get("language"), 10)] ||
    DEFAULT_ELIGIBILITY_LINK;

  return (
    <>
      <div className="text">
        <span
          dangerouslySetInnerHTML={{
            /* prettier-ignore */ __html: trans("Congratulations! Your story <strong>may</strong> be eligible for The %s Wattys!", CURRENT_WATTYS_YEAR)
          }}
        />
        <a
          className="plain-link bold"
          href={wattysEligibilityLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans("Is your ongoing story eligible?")}
        </a>
      </div>
      <button onClick={onBtnClick} className="btn btn-white">
        <div className="btn-text">{trans("Enter this story")}</div>
      </button>
    </>
  );
};

OngoingEligibleBanner.propTypes = {
  onBtnClick: PropTypes.func.isRequired
};

export default OngoingEligibleBanner;
