/*
* NOTE: This is a standalone React component, i.e. <Icon ...>
* It is NOT meant for usage with the handlebars helper, i.e. {{ reactComponent 'Icon' }}
*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import Icons from "../../assets/icons";
import Swatch from "../../assets/swatch";

function isFontFaceSuported(fontface) {
  if (process.env.IS_BROWSER) {
    return window.wattpad.utils.modernizr("fontface");
  }
  return !!parseInt(fontface, 10);
}
class Icon extends PureComponent {
  render() {
    // eslint-disable-next-line react/prop-types
    if (this.props.onClick) {
      throw new Error(
        "onClick event defined on an icon. Please wrap the icon with a button."
      );
    }
    const {
      iconName,
      name,
      height,
      color,
      className,
      ff,
      size,
      strokeWidth,
      boxX,
      boxY,
      viewBox,
      fill,
      fillColor,
      title,
      passthroughProps
    } = this.props;

    if (name) {
      // New icons
      if (Icons[name]) {
        let fillState = "none";
        if (fillColor) {
          fillState = Swatch[fillColor];
        } else if (fill) {
          fillState = Swatch[color];
        }

        // Adding role for screen reader inerpretation per pattern 7 at:
        // https://www.deque.com/blog/creating-accessible-svgs
        const role = title ? "img" : undefined;

        return (
          <svg
            width={size}
            height={size}
            viewBox={`${boxX} ${boxY} ${viewBox} ${viewBox}`}
            fill={fillState}
            stroke={Swatch[color]}
            strokeWidth={strokeWidth}
            aria-hidden={!title} //TODO: refactor Icon helper, common pattern for decorative is to present svg using img tag with blank alt.
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
            role={role} // if left undefined, is excluded from svg tag attributes
            {...passthroughProps}
          >
            {title && <title>{title}</title>}
            <g dangerouslySetInnerHTML={{ __html: Icons[name] }} />
          </svg>
        );
      } else {
        throw `Icon ${name} doesn't exist`;
      }
    }

    // Old legacy icons
    if (isFontFaceSuported(ff)) {
      return (
        <span
          className={classnames("fa", iconName, `fa-${color}`, className)}
          aria-hidden="true"
          style={{ fontSize: `${height}px` }}
          ref={ref => {
            this.elementRef = ref;
          }}
          {...passthroughProps}
        />
      );
    }
    const iconDir = `/img/icons/${color}/${iconName.substr(3)}.png`;
    return (
      <img
        className={classnames("fa", iconName, "fallback", className)}
        style={{ height: `${height}px`, width: "auto" }}
        src={iconDir}
        alt={`icon ${iconName.substr(3)}`}
        ref={ref => {
          this.elementRef = ref;
        }}
        {...passthroughProps}
      />
    );
  }
}

Icon.defaultProps = {
  className: "",
  ff: "",
  color: "wp-neutral-1",
  size: "14",
  height: "14",
  strokeWidth: "2",
  boxX: "0",
  boxY: "0",
  viewBox: "24"
};

Icon.propTypes = {
  name: PropTypes.string, // New icon
  iconName: PropTypes.oneOf([
    "fa-alert",
    "fa-archive",
    "fa-attach",
    "fa-badge-ambassador",
    "fa-badge-blank",
    "fa-badge-cc",
    "fa-badge-heart",
    "fa-badge-promoted",
    "fa-badge-rating",
    "fa-badge-verified",
    "fa-bold",
    "fa-buy",
    "fa-camera",
    "fa-caret",
    "fa-categories",
    "fa-centered",
    "fa-check",
    "fa-circle-empty",
    "fa-circle-filled",
    "fa-clock",
    "fa-code",
    "fa-comment",
    "fa-comment-count",
    "fa-copyright",
    "fa-creative-commons",
    "fa-dedicate",
    "fa-double-image",
    "fa-down",
    "fa-down-small",
    "fa-edit",
    "fa-facebook",
    "fa-facebook-official",
    "fa-facebook-square",
    "fa-flag",
    "fa-follow",
    "fa-following",
    "fa-following-request",
    "fa-format",
    "fa-group",
    "fa-home",
    "fa-ic_reading_list",
    "fa-image",
    "fa-inbox",
    "fa-info",
    "fa-instagram",
    "fa-italic",
    "fa-justified",
    "fa-left",
    "fa-left-aligned",
    "fa-left-small",
    "fa-library",
    "fa-link",
    "fa-list",
    "fa-list-add",
    "fa-loading",
    "fa-location",
    "fa-lock",
    "fa-mention",
    "fa-menu",
    "fa-minus",
    "fa-mobile",
    "fa-more",
    "fa-mute",
    "fa-pin",
    "fa-pinterest",
    "fa-pinterest-square",
    "fa-plus",
    "fa-public-domain",
    "fa-ranking",
    "fa-reading-list",
    "fa-redo",
    "fa-refresh",
    "fa-remove",
    "fa-remove-alt",
    "fa-right",
    "fa-right-aligned",
    "fa-right-small",
    "fa-search",
    "fa-settings",
    "fa-share",
    "fa-single-image",
    "fa-stats",
    "fa-story",
    "fa-trash",
    "fa-tumblr",
    "fa-tumblr-square",
    "fa-twitter",
    "fa-twitter-square",
    "fa-underline",
    "fa-undo",
    "fa-unlock",
    "fa-unmute",
    "fa-up",
    "fa-up-small",
    "fa-user",
    "fa-video",
    "fa-view",
    "fa-vote",
    "fa-vote-alt",
    "fa-warning",
    "fa-website",
    "fa-image-warning"
  ]),
  color: PropTypes.oneOf([
    "wp-neutral-1",
    "wp-neutral-2",
    "wp-base-1-dark",
    "wp-base-1-light",
    "wp-base-2-dark",
    "wp-error-1",
    "wp-warning-01",
    "wp-errorbackground",
    "wp-neutral-2",
    "wp-base-2-dark",
    "wp-neutral-3",
    "wp-lighterror",
    "wp-lighterteal",
    "wp-neutral-3",
    "wp-neutral-3",
    "wp-lightteal",
    "wp-neutral-2",
    "wp-neutral-3",
    "wp-base-1",
    "wp-neutral-2",
    "wp-success",
    "wp-base-2",
    "wp-neutral-2",
    "wp-base-1-dark",
    "wp-neutral-5",
    "ds-neutral-00-solid",
    "ds-neutral-100",
    "ds-base-4-accent",
    "ds-base-4-accent-dark",
    "ds-alert",
    "ds-neutral-80",
    "ds-base-2-60",
    "ds-success",
    "ds-neutral-60-solid",
    "ds-base-7-60"
  ]).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  ff: PropTypes.string,
  strokeWidth: PropTypes.string,
  boxX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.bool,
  fillColor: PropTypes.string,
  passthroughProps: PropTypes.object,
  title: PropTypes.string
};

const mapStateToProps = state => {
  const { ff } = state.userData;
  return { ff };
};

const wrappedIcon = connect(
  mapStateToProps,
  {}
)(Icon);

export default wrappedIcon;
