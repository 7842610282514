import moment from "moment";
import { sprintf } from "sprintf-js";
import {
  CommentsNamespaces,
  postSentiment,
  removeSentiment,
  SentimentNamespaces,
  SentimentTypes
} from "@wattpad/client-platform-comments";

import Colours from "../../../assets/swatch";
import {
  AdsInCommentsConfigs,
  CommentsLocation,
  INIT_COMMENTS_REPLIES
} from "./CommentsConstants";
import { BadgeVariant } from "@wattpad/web-ui-library";

const writerPill = trans => ({
  text: trans("Writer"),
  color: Colours["ds-neutral-00-solid"],
  backgroundColor: Colours["ds-base-1-60"]
});

export const isStoryPart = location => location === CommentsLocation.STORY_PART;

export const isReply = location => location === CommentsLocation.REPLIES;

export const toCommentResource = commentId => ({
  resourceId: commentId,
  namespace: SentimentNamespaces.COMMENTS
});

export const getUserBadges = user => {
  const badges = [];
  if (user?.isStaff) badges.push(BadgeVariant.STAFF);
  if (user?.isVerified) badges.push(BadgeVariant.VERIFIED);
  if (user?.isAmbassador) badges.push(BadgeVariant.AMBASSADORS);
  return badges;
};

export const getPills = (isStoryWriter, trans) =>
  isStoryWriter ? [writerPill(trans)] : [];

const timeUnits = ["minutes", "hours", "days", "weeks", "months", "years"];

const transTimeUnits = ngettext => [
  v => sprintf(ngettext("%sm ago", "%sm ago", v), v),
  v => sprintf(ngettext("%sh ago", "%sh ago", v), v),
  v => sprintf(ngettext("%sd ago", "%sd ago", v), v),
  v => sprintf(ngettext("%sw ago", "%sw ago", v), v),
  v => sprintf(ngettext("%smo ago", "%smo ago", v), v),
  v => sprintf(ngettext("%sy ago", "%sy ago", v), v)
];

export const toPostedDate = (
  trans,
  ngettext,
  createTime,
  currentTime = moment()
) => {
  // using moment.js library to get the duration between currentTime
  // and createTime in wanted units
  const getDurationIn = unit =>
    moment(currentTime).diff(moment(createTime), unit);

  if (getDurationIn("minutes") < 1) {
    return trans("Now");
  }

  for (let i = 1; i < timeUnits.length; i++) {
    if (getDurationIn(timeUnits[i]) < 1) {
      return transTimeUnits(ngettext)[i - 1](getDurationIn(timeUnits[i - 1]));
    }
  }

  return transTimeUnits(ngettext)[timeUnits.length - 1](getDurationIn("years"));
};

export const getLikes = sentiments => sentiments?.get(SentimentTypes.LIKE);

export const getIsLiked = sentiments => !!getLikes(sentiments)?.interacted;

export const getLikesCount = sentiments => getLikes(sentiments)?.count || 0;

export const removeLike = commentResource =>
  removeSentiment(commentResource, SentimentTypes.LIKE);

export const addLike = commentResource =>
  postSentiment(commentResource, SentimentTypes.LIKE);

export const canDeleteComment = (user, commentAuthor, storyAuthor) => {
  const isAmbassador = user.ambassador || user.isSysAdmin;
  const isStoryAuthor = storyAuthor === user.username;
  const isCommentAuthor = commentAuthor.name === user.username;

  return {
    canDelete: user && (isAmbassador || isStoryAuthor || isCommentAuthor),
    isCommentAuthor
  };
};

export const getReplyLimit = (showReplyBox, replyCount) => {
  if (showReplyBox) {
    return replyCount > 0 ? INIT_COMMENTS_REPLIES : 0;
  }
};

const shouldShowAdAtIndex = index =>
  (index + 1) %
  AdsInCommentsConfigs.NUM_COMMENTS_SET ===
  AdsInCommentsConfigs.AD_OFFSET_NUM;

export const injectAdsInComments = comments => {
  if (comments.length <= AdsInCommentsConfigs.AD_OFFSET_NUM) {
    return [...comments, { isAd: true, id: 1 }];
  } else {
    const commentsWithAds = [];
    let adId = 1;
    for (let i = 0; i < comments.length; i++) {
      commentsWithAds.push(comments[i]);
      if (shouldShowAdAtIndex(i)) {
        commentsWithAds.push({ isAd: true, id: adId });
        adId++;
      }
    }
    return commentsWithAds;
  }
};

export const isCommentInStoryPart = (
  commentDataResource,
  partId,
  paragraphId
) => {
  const { namespace, resourceId } = commentDataResource;
  if (namespace === CommentsNamespaces.PARTS) {
    return resourceId == partId;
  } else if (namespace === CommentsNamespaces.PARAGRAPHS) {
    // paragraphIds are in the format of partId_paragraphId, so we should split the string
    return resourceId.split("_")[1] == paragraphId;
  }
  return false;
};
