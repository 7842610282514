import React from "react";

import { useTrans } from "../../hooks";

export const RestrictPrivateMessagingDeprecationMessage = () => {
  const { trans } = useTrans();

  return (
    <p className="text typography-paragraph-medium">
      {trans(
        "Direct messages are no longer available. You can still receive messages from Wattpad-affiliated accounts."
      )}
      &nbsp;
      <a
        className="learn-more-link typography-label-medium"
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.wattpad.com/hc/articles/204412040-Private-messages"
      >
        {trans("Learn more")}
      </a>
    </p>
  );
};
