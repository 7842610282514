// TODO: Find less to javascript mapping solution
module.exports = {
  "wp-base-1": "#ff6122",
  "wp-base-1-light": "#ff7b3c",
  "wp-base-1-dark": "#e64809",
  "wp-base-1-60": "#A93E19",
  "wp-base-2": "#00b2b2",
  "wp-base-2-light": "#1acccc",
  "wp-base-2-dark": "#009999",
  "wp-neutral-1": "#222222",
  "wp-neutral-2": "#6f6f6f",
  "wp-neutral-3": "#eeeeee",
  "wp-neutral-4": "#fafafa",
  // TODO: neutral-new is temporary until we bring in the full new colour palette.
  // Remove/Rename when migrating the new colours.
  "wp-neutral-new-4": "rgba(18, 18, 18, 0.12)",
  "wp-neutral-5": "#ffffff",
  "wp-error-1": "#ff3f12",
  "wp-warning-01": "#ffc922",

  "facebook-1": "#3b5998",
  "twitter-1": "#55acee",
  "pinterest-1": "#bd081c",
  "tumblr-1": "#35465c",
  "google-1": "#db4437",
  "google-2": "#1e6cd6",
  "wp-ambassador-profile": "#df6400",
  "wp-lightteal": "#c3d8dc",
  "wp-lighterteal": "#eaf0f4",
  "wp-lighterror": "#faada1",
  "wp-errorbackground": "#ff6d55",
  "wp-focus-blue": "#00a0d9",
  "wp-button-red": "#e94a2c",
  "wp-button-green": "#01b05a",
  "wp-wattys-tag": "#322864",

  // Design system colors
  "ds-neutral-00-solid": "#fff",
  "ds-neutral-100": "#121212",
  "ds-base-4-accent": "#e81249",
  "ds-base-4-accent-dark": "#f54f78",
  "ds-base-2-60": "#3b19a9",
  "ds-base-1-60": "#a93e19",
  "ds-alert": "#e00000",
  "ds-success": "#00854e",
  "ds-neutral-80": "rgba(18, 18, 18, 0.64)",
  "ds-neutral-40": "rgba(18, 18, 18, 0.12)",
  "ds-base-3-60": "#1c6f65",
  "ds-base-7-60": "#3f6f0c"
};
