import React from "react";
import { useTrans } from "../../hooks";
import ConnectImage from "../ConnectImage";

export const InboxEmpty = () => {
  const { trans } = useTrans();

  return (
    <div className="inbox-empty">
      <ConnectImage name="inbox/general-writing-cyclers-light.svg" />
      <p className="typography-heading-x-small">
        {trans("Get news and updates from the Wattpad team")}
      </p>
      <p className="typography-paragraph-medium">
        {trans("You have no messages.")}
      </p>
    </div>
  );
};
