import React from "react";
import PropTypes from "prop-types";

const Option = ({ value, label }) => {
  return (
    <option value={value} key={value}>
      {label || value}
    </option>
  );
};

Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string
};

export default Option;
