import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { toggleModal } from "../../shared-components/modals/actions";
import { useTrans } from "../../hooks";

import CoinIcon from "./CoinIcon";
import CoinsModalContainer from "./CoinsModalContainer";

function CoinPurchaseConfirmationUI({
  numCoins,
  previousBalance,
  toggleModal
}) {
  const { trans } = useTrans();

  const newBalance = numCoins + previousBalance;

  return (
    <CoinsModalContainer toggleModal={toggleModal}>
      <div className="coin-purchase-confirmation">
        <h3>{trans("Wallet locked and loaded")} 💸</h3>
        <div className="wallet-calculation">
          <div className="calculation-row">
            <span>{trans("Previous balance")}</span>
            <span className="coin-amount">
              <CoinIcon height="20" width="20" /> {previousBalance}
            </span>
          </div>

          <div className="calculation-row">
            <span>{trans("Coins added")}</span>
            <span className="coin-amount">
              <CoinIcon height="20" width="20" /> {numCoins}
            </span>
          </div>

          <div className="calculation-row new-balance">
            <span>{trans("New balance")}</span>
            <span className="coin-amount">
              <CoinIcon height="20" width="20" /> {newBalance}
            </span>
          </div>
        </div>

        <button className="btn-primary" onClick={toggleModal}>
          {trans("Complete")}
        </button>
      </div>
    </CoinsModalContainer>
  );
}

CoinPurchaseConfirmationUI.propTypes = {
  numCoins: PropTypes.number.isRequired,
  previousBalance: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ previousBalance: state.wallet.amount });

const CoinPurchaseConfirmation = connect(
  mapStateToProps,
  { toggleModal }
)(CoinPurchaseConfirmationUI);

export default CoinPurchaseConfirmation;
