import PropTypes from "prop-types";
import React from "react";

export default class LargePromoBanner extends React.Component {
  render() {
    const promoName = this.props.promoName;
    const { flavourText, button } = this.props.promoOptions;
    return (
      <div className="banner-background" id={`${promoName}-banner`}>
        <div className="banner-info">
          {flavourText ? (
            <div className="flavour-text">{flavourText}</div>
          ) : null}
          {button ? (
            <a href={button.link} className="btn btn-orange banner-btn">
              {button.text}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}

LargePromoBanner.propTypes = {
  promoName: PropTypes.string.isRequired,
  promoOptions: PropTypes.object.isRequired
};
