import React from "react";
import { useReportModal, useTrans } from "../hooks";
import Icon from "./Icon";
import PropTypes from "prop-types";

const ReportStoryPart = ({ partId, storyId, storyTitle, author, language }) => {
  const { trans } = useTrans();
  const { openReportStoryModal } = useReportModal();

  const handleOnClick = () => {
    openReportStoryModal(storyId, storyTitle, author, language, partId);
  };

  return (
    <button className="btn-option" onClick={handleOnClick}>
      <Icon
        iconName="fa-warning"
        color="wp-neutral-2"
        height="20"
        className="pull-left"
      />
      {trans("Report Story")}
    </button>
  );
};

ReportStoryPart.propTypes = {
  partId: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  storyTitle: PropTypes.string,
  author: PropTypes.string,
  language: PropTypes.string
};

export default ReportStoryPart;
