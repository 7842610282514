import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import { NewCheckbox } from "../../../../shared-components";
import ErrorField from "./ErrorField";
import { CURRENT_WATTYS_YEAR } from "../constants";

const FinalConsentField = ({
  form,
  disableForm,
  onRadioBtnChange,
  fieldStatus,
  submitted,
  isStandalonePage,
  hasSubmitted,
  formIsOpen,
  onSubmit,
  rulesLink
}) => {
  const { trans } = useTrans();

  const title = trans("Final consent");

  const subtitle = (
    <div
      dangerouslySetInnerHTML={{
        __html: /* prettier-ignore */ trans('I confirm that I’ve read, understood, and agree to the <a class="bold" target="_blank" rel="noopener noreferrer" href="%s">Contest Rules and Regulations</a> and Wattpad’s Policies.', rulesLink)
      }}
    />
  );

  const smallSubtitle = (
    <div
      className="subtitle"
      dangerouslySetInnerHTML={{
        __html: /* prettier-ignore */ trans("Ready to hit submit? Please note that <strong>once you’ve submitted your story, you will be unable to resubmit</strong> - make sure your story is Wattys ready. All information collected on this form will be kept confidential and is for internal purposes only.")
      }}
    />
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "finalConsent" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    !form.finalConsent;

  return (
    <div className="final-consent-container">
      <div className="final-consent-title-container">
        <h4>{title}</h4>
      </div>
      <div className="checkbox-group" id="final-consent-checkbox">
        <NewCheckbox
          disabled={disableForm}
          name="finalConsent"
          onChange={onRadioBtnChange}
          checked={form.finalConsent}
          contentId="consent-checkbox-message"
        >
          {subtitle}
        </NewCheckbox>
      </div>
      <ErrorField errorCondition={errorCondition} fieldId={"final-consent"} />
      <div className="subtitle-small">{smallSubtitle}</div>
      {isStandalonePage && hasSubmitted ? null : (
        <div className="btn-container">
          {!hasSubmitted &&
            formIsOpen && (
              <button
                type="button"
                onClick={onSubmit}
                className="btn btn-primary submit-btn"
              >
                {trans("Enter The %s Wattys!", CURRENT_WATTYS_YEAR)}
              </button>
            )}
        </div>
      )}
    </div>
  );
};

FinalConsentField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onRadioBtnChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired,
  isStandalonePage: PropTypes.bool.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
  formIsOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rulesLink: PropTypes.string.isRequired
};

export default FinalConsentField;
