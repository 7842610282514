import { TOGGLE_MODAL_VISIBLE, OPEN_MODAL, CLOSE_MODAL } from "./constants";

const initialState = {
  modalProps: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_VISIBLE:
      return {
        ...state,
        modalProps: state.modalProps ? null : action.modalProps
      };
    case OPEN_MODAL:
      return {
        ...state,
        modalProps: action.modalProps
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalProps: null
      };
    default:
      return state;
  }
}
