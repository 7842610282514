import React from "react";
import classnames from "classnames";

/*
 * Toggle component renders a toggle button between on and off with a loading and disabled state
 * Props:
 *     toggleState <string>: "on" or "off" indicates the current state
 *     onToggle <func>: onclick handler, e.g. update the toggleState passed in
 *     loading <bool>: if true, this toggle is disabled and shows a loading state
 *     disabled <bool>: if true, this toggle is disabled and shows a greyed out disabled state
 */
export default function Toggle({ toggleState, onToggle, loading, disabled }) {
  if (loading) {
    return (
      <div
        className={classnames("toggle", toggleState)}
        role="switch"
        aria-checked={toggleState === "on" ? "true" : "false"}
      >
        <div className="block-off">
          <span className="label-off">...</span>
        </div>
      </div>
    );
  } else if (disabled) {
    return (
      <div
        className={classnames("toggle", toggleState, "disabled")}
        role="switch"
        aria-checked={toggleState === "on" ? "true" : "false"}
      >
        <div className="switch" />
        <div className="block-on">
          <span className="label-on">ON</span>
        </div>
        <div className="block-off">
          <span className="label-off">OFF</span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classnames("toggle", toggleState)}
        role="switch"
        tabIndex={0}
        aria-checked={toggleState === "on" ? "true" : "false"}
        onClick={onToggle}
      >
        <div className="switch" />
        <div className="block-on">
          <span className="label-on">ON</span>
        </div>
        <div className="block-off">
          <span className="label-off">OFF</span>
        </div>
      </div>
    );
  }
}
