import React, { Component } from "react";
import PropTypes from "prop-types";

class CommunityDropdown extends Component {
  render() {
    let { trans } = this.props;
    return (
      <div className="dropdown-menu large" aria-labelledby="community-dropdown">
        <ul aria-label="Community">
          <li>
            <a
              href="https://www.wattpad.com/awards"
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans("The Wattys")}
            </a>
          </li>
          <li>
            <a
              href="https://www.wattpad.com/story/25279524"
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans("Community Happenings")}
            </a>
          </li>
          <li>
            <a
              href="https://www.wattpad.com/823619"
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans("Wattpad Ambassadors")}
            </a>
          </li>
        </ul>
      </div>
    );
  }

  static propTypes = {
    trans: PropTypes.func.isRequired
  };
}

export default CommunityDropdown;
