import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../shared-components";
import { useReportModal, useTrans } from "../hooks";

const CONTENT_GUIDELINES_LINK =
  "https://support.wattpad.com/hc/en-us/articles/200774334-Content-Guidelines";

const ReportStoryContent = () => {
  const { trans } = useTrans();
  return (
    <>
      <Icon
        className="card__action-icon"
        width="32"
        height="32"
        size="32"
        name="reportFlag"
        viewBox="32"
        strokeWidth="1"
      />
      <div className="card__label">
        <span id="report-title" className="card__title">
          {trans("Report this story")}
        </span>
      </div>
      <Icon className="card__nav-icon" name="chevRight" size="24" />
    </>
  );
};

/**
 * The report story card.
 */
const ReportStoryCard = ({
  storyId,
  storyTitle,
  authorUsername,
  storyLanguage
}) => {
  const { trans } = useTrans();
  const { openReportStoryModal } = useReportModal();

  const handleReportClick = () => {
    openReportStoryModal(
      storyId.toString(),
      storyTitle,
      authorUsername,
      storyLanguage
    );
  };

  return (
    <div>
      <a
        className="card"
        href={CONTENT_GUIDELINES_LINK}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex="0"
      >
        <Icon
          className="card__action-icon"
          width="32"
          height="32"
          size="32"
          name="link"
          viewBox="32"
          strokeWidth="3"
        />
        <div className="card__label">
          <span id="content-title" className="card__title">
            {trans("Content Guidelines")}
          </span>
        </div>
        <Icon className="card__nav-icon" name="chevRight" size="24" />
      </a>
      <button className="card" onClick={handleReportClick}>
        <ReportStoryContent />
      </button>
    </div>
  );
};

ReportStoryCard.propTypes = {
  storyId: PropTypes.number.isRequired,
  storyTitle: PropTypes.string,
  authorUsername: PropTypes.string,
  storyLanguage: PropTypes.string
};

export default ReportStoryCard;
