import React, {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { MODAL_TYPE } from "./constants";
import { Icon } from "../../shared-components";
import { useTrapKeyboardNavigation } from "../../hooks";

const Modal = forwardRef(
  ({ id, children, showModal, onClose, onOpen }, ref) => {
    const [isOpen, setisOpen] = useState(showModal || false);
    const [data, setData] = useState({});
    const [triggerRef, setTriggerRef] = useState();
    const [modalRef, setModalRef] = useState();
    // We cannot directly leverage useRef here; the element is created inside of a portal
    const modalRefCb = useCallback(
      ref => {
        setModalRef(ref);
      },
      [setModalRef]
    );

    const show = useCallback(
      () => {
        // Close any other open modal
        if (window.wattpad.modals && !isOpen) {
          _.each(window.wattpad.modals, (val, key) => {
            const modal = window.wattpad.modals[key];
            if (modal.id != id && modal.isOpen())
              window.wattpad.modals[key].hide();
          });
        }

        document.body.classList.add("modal-open");
        setTriggerRef(document.activeElement);
        setisOpen(true);
        onOpen && onOpen();
      },
      [id, isOpen, setisOpen, setTriggerRef]
    );

    const hide = useCallback(
      () => {
        document.body.classList.remove("modal-open");
        triggerRef?.focus();
        setisOpen(false);
      },
      [triggerRef, setisOpen]
    );

    const handleClose = () => {
      setData({});
      onClose && onClose();
      hide();
    };

    const onEscapeKey = useCallback(e => e.key === "Escape" && hide(), [hide]);

    useImperativeHandle(ref, () => ({ isOpen, hide, show, setData, data }));

    useTrapKeyboardNavigation(modalRef);

    useEffect(
      () => {
        window.wattpad.utils.registerModal(id, MODAL_TYPE.DS_MODAL, {
          isOpen: () => isOpen,
          show,
          hide,
          setData,
          data
        });

        if (isOpen) {
          window.addEventListener("tap", onEscapeKey);
          window.addEventListener("keydown", onEscapeKey);

          return () => {
            window.removeEventListener("tap", onEscapeKey);
            window.removeEventListener("keydown", onEscapeKey);
          };
        }
      },
      [id, isOpen, show, hide, onEscapeKey]
    );

    return isOpen
      ? ReactDOM.createPortal(
          <>
            <div id={`${id}-overlay`} className="modal-overlay" />
            <div id={id} className="modal-wrapper" ref={modalRefCb}>
              <div className="modal-container">
                <button
                  type="button"
                  className="modal-close-button"
                  onClick={handleClose}
                >
                  <Icon
                    className="modal-close-icon"
                    name="modalClose"
                    size={14}
                    strokeWidth="0"
                    fillColor="ds-neutral-80"
                    viewBox={14}
                    aria-hidden
                  />
                </button>
                {children}
              </div>
            </div>
          </>,
          document.querySelector("#react-modal")
        )
      : null;
  }
);
Modal.displayName = "Modal";

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

export default Modal;
