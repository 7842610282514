import React from "react";
import PropTypes from "prop-types";
import ContentSettings from "../home/components/ContentSettings";

const ContentPreferences = ({ trans }) => {
  return (
    <div id="content-preferences">
      <div className="content-settings-heading">
        <h2>{trans("Content Preferences")}</h2>
        <hr />
      </div>
      <ContentSettings isSettingsPage />
    </div>
  );
};

ContentPreferences.propTypes = {
  trans: PropTypes.func.isRequired
};

export default ContentPreferences;
