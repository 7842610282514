import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ConnectImage from "../../shared-components/ConnectImage";
import { useFeatureFlags } from "../../hooks";
import classNames from "classnames";

export function GoogleAuth({ googleCta, nextUrl, source }) {
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if ($("#google-root").length === 0) {
      $.getScript("https://accounts.google.com/gsi/client");
      $("body").append("<div id='google-root'></div>");
    }
  }, []);

  const onGoogleAuth = () => {
    if (featureFlags.NEW_ONBOARDING_1 && source === "login") {
      window.te.push("event", "landing", null, null, "login", {
        platform: "google"
      });
    }
    let tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: wattpad.googleClientId,
      scope: "profile openid email", // The things we want to retrieve from Google login.
      callback: response => {
        if (response.access_token) {
          var data = {
              token: response.access_token,
              type: "google",
              redirect: true
            },
            dataStr = encodeURIComponent(JSON.stringify(data));
          window.location.href =
            "/oauth-auth?nextUrl=" +
            wattpad.utils.getNextUrl(nextUrl, window.location.href) +
            "&data=" +
            dataStr;
        }
      }
    });
    tokenClient.requestAccessToken();
  };

  const googleButtonTextCss = featureFlags.NEW_ONBOARDING_1
    ? "auth-button-text-new"
    : "auth-btn";
  const googleImageWSize = featureFlags.NEW_ONBOARDING_1 ? "18" : "24";

  return (
    <button
      onClick={onGoogleAuth}
      className={classNames("btn btn-google btn-block", {
        "auth-button-new": featureFlags.NEW_ONBOARDING_1
      })}
    >
      <ConnectImage
        name="/shared/google_login_color.png"
        title="Google Login"
        width={googleImageWSize}
        className="google-login-icon"
        aria-hidden="true"
      />
      <span>
        <span className={googleButtonTextCss}>{googleCta}</span>
      </span>
    </button>
  );
}
GoogleAuth.propTypes = {
  googleCta: PropTypes.string.isRequired,
  nextUrl: PropTypes.string,
  source: PropTypes.string
};
