import React from "react";
import PropTypes from "prop-types";

import InfoButton from "./InfoButton";
import { useTrans } from "../../hooks";

const LineChartHeader = ({ title, metric, section }) => {
  const { trans } = useTrans();
  return (
    <div className="line-chart-header">
      <h2 className="line-chart-header__title">{title}</h2>
      {section === "activities" &&
        metric === "readers" && (
          <InfoButton
            popoverContent={{
              title: trans("What is Daily Unique Readers?"),
              content: trans(
                "The number of unique daily viewers or readers of your story over the last 30 days."
              )
            }}
            color="wp-neutral-1"
            className="line-chart-header__info"
          />
        )}
    </div>
  );
};

LineChartHeader.propTypes = {
  title: PropTypes.string.isRequired,
  metric: PropTypes.string,
  section: PropTypes.string
};

export default LineChartHeader;
