import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import {
  ConnectImage,
  Countdown,
  Icon
} from "../../../components/shared-components";

const PremiumPicksBanner = ({ className }) => {
  const { trans } = useTrans();

  return (
    <div className="container">
      <a
        href="/catalog/premiumpicks"
        className={classnames(className, "premium-picks-banner", "on-navigate")}
      >
        <div className="premium-picks-image-container">
          <ConnectImage
            className="premium-picks-image"
            name={`library/premium-picks-banner-books.png`}
            title={trans("Stack of books")}
          />
        </div>

        <div className="content">
          <h2 className="heading">
            {trans("Explore this month's Premium Picks")}{" "}
            <div className="access-expires-pill">
              <Icon
                name="timeout"
                size="12"
                className="timeout"
                color="ds-neutral-100"
                strokeWidth="1"
                fill
              />
              {trans("Expiring in")}{" "}
              {
                <Countdown
                  date={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      1,
                      0,
                      0,
                      0,
                      0
                    )
                  }
                  showDays={true}
                  showHours={true}
                  updateInterval={60}
                />
              }
            </div>
          </h2>

          <div className="description">
            {trans(
              "Access 5 Wattpad Originals every month with any Premium or Premium+ plan."
            )}
          </div>
        </div>

        <Icon
          name="roundArrowRight"
          size="16"
          className="right-arrow"
          color="ds-neutral-100"
          viewBox="16"
          strokeWidth="0"
          fill
        />
      </a>
    </div>
  );
};

PremiumPicksBanner.propTypes = {
  className: PropTypes.string
};

export default PremiumPicksBanner;
