import PropTypes from "prop-types";
import React from "react";

import { ContestFormHeader } from "./ContestFormHeader";
import { useTrans } from "../../../hooks";
import { Loader } from "../../../shared-components";
import { CURRENT_WATTYS_YEAR } from "./constants";

const SubmissionSuccess = ({
  isStandalonePage = false,
  closeModal,
  formState
}) => {
  const { trans } = useTrans();

  const headerTitle = trans(
    "Congratulations! You have been entered\ninto The %s Watty Awards.",
    CURRENT_WATTYS_YEAR
  );

  if (formState.isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  const trackBannerOnClosePopup = () => {
    window.te.push("event", "wattys", "banner", null, "view", {
      storyid: parseInt(formState.data.storyId),
      type: "confirmed_submission",
      page: "story_details"
    });
    closeModal();
  };

  return (
    <div id="submission-success">
      <ContestFormHeader
        subtitle={headerTitle}
        closeModal={trackBannerOnClosePopup}
        isStandalonePage={isStandalonePage}
      />
      <div className="main-container">
        <div className="content">
          <h3 className="black bold">
            {trans("Share the exciting news with your readers!")}
          </h3>
        </div>
      </div>
      <div className="main-container-cover">
        <div className="content">
          <h5 className="bold">
            {trans("I entered my story in The %s Wattys!", CURRENT_WATTYS_YEAR)}
          </h5>
          <div className="image-container">
            <img src={formState.cover} alt={trans("story cover")} />
          </div>
          <h6
            dangerouslySetInnerHTML={{
              /* prettier-ignore */ __html: trans("<span class='bold'>%s</span><br>by @%s", formState.title, formState.user)
            }}
          />
        </div>
      </div>
      <div className="main-container">
        <div className="content">
          <div
            className="submission-info-text"
            dangerouslySetInnerHTML={{
              /* prettier-ignore */ __html: trans("Your story has been submitted for The %s Watty Awards.", CURRENT_WATTYS_YEAR)
            }}
          />
          <div
            className="submission-info-text"
            dangerouslySetInnerHTML={{
              /* prettier-ignore */ __html: trans("<span class='bold'>Good luck and happy writing!</span>")
            }}
          />
        </div>
        {!isStandalonePage && (
          <React.Fragment>
            <div className="btn-container">
              <button
                onClick={trackBannerOnClosePopup}
                className="btn btn-primary"
              >
                {trans("Done")}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

SubmissionSuccess.propTypes = {
  isStandalonePage: PropTypes.bool,
  closeModal: PropTypes.func,
  formState: PropTypes.object
};

export { SubmissionSuccess };
