"use strict";
var isServer = require("./is-server");

/**
 * FRONTEND SPECIFIC, NO NOT REUSE
 * Loaded for node environment to make the API request using the built in api HTTP util.
 *
 * @param {object} serverReq - (Required only for backend) The request object when this utility is called on the server
 * @param {object} serverRes - (Required only for backend) The response object when this utility is called on the server
 * @returns {} - a mystery prize object containing a bunch of feature flags.
 */
const serverAPICall = (url, req, res) => {
  const utilsFilePath = "../server/utils";
  const mod = module;
  const utils = mod.require(utilsFilePath);
  return utils.apiHttp
    .get(url, null, res, req)
    .then(result => {
      //apihttp does not catch 400 errors, but catches 500
      if (result?.code >= 400 && result?.code < 500) {
        throw result;
      } else {
        return result;
      }
    })
    .catch(err => {
      throw err;
    });
};

/**
 * FRONTEND SPECIFIC, NO NOT REUSE
 * Makes a client side get request using JQuery
 */
const clientAPICall = url => {
  return Promise.resolve(
    $.ajax({
      type: "GET",
      url
    })
  );
};

const logErrorCb = errorRes => {
  let errorMessage;
  //Client side
  if (errorRes.status === 400 && errorRes.responseJSON) {
    errorMessage = errorRes.responseJSON.message;
    //Server side
  } else if (errorRes.code === 400 && errorRes.message) {
    errorMessage = errorRes.message;
  } else {
    errorMessage = "No response received";
  }

  console.error(`getWattpadBackendFeatureFlags: ${errorMessage}`);
};

const apiCall = isServer() ? serverAPICall : clientAPICall;

/**
 *
 * README - When migrating to remix, don't port all of this code. It's unnecessary.
 * We can do a server API call, and remix can pass it to the client.
 * The real problem is then telling remix that the client has a set
 * of featureflags fetched within a certain time frame
 * and to not fetch new ones on every request.
 */
module.exports = function(serverReq, serverRes) {
  return new Promise(async function(resolve) {
    const url = "/api/v3/internal/features?platform=web";

    return resolve(
      await apiCall(url, serverReq, serverRes)
        .then(featureFlags => {
          return featureFlags;
        })
        .catch(err => {
          logErrorCb(err);
          return {};
        })
    );
  });
};
