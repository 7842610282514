import React from "react";

import { Popover, WPModal } from "../shared-components";

// Root component that sits above app-container, active on client
// only. Useful for React components that aren't rendered inside a
// Handlebars view. E.g. global components like a modal, or full-page
// React components that use react-router rather than Monaco routing.
export default function ReactRoot() {
  return (
    <React.Fragment>
      <Popover />
      <WPModal isGlobalModalContainer />
    </React.Fragment>
  );
}
