import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";

import { getCoverSrc, getCoverSrcSet, simpleShorten } from "../../../helpers";
import TagItem from "../../tags/TagItem";
import PromotedIndicator from "./PromotedIndicator";

const TagsBuilder = ({ tags = [], max = 1, filter = tag => tag }) => {
  const [randomTags, setRandomTags] = useState(tags);

  useEffect(
    () => {
      setRandomTags(
        _.shuffle(tags)
          .filter(filter)
          .slice(0, max)
      );
    },
    [tags, max, filter]
  );

  return (
    <div className="tags-list">
      {tags
        ? randomTags.map((tag, i) => (
            <TagItem
              key={i + tag}
              name={tag}
              clickHandler={(evt, name) => {
                evt.preventDefault();
                app.router.navigate(`/stories/${name}`, { trigger: true });
              }}
              link={`/stories/${tag}`}
            />
          ))
        : null}
    </div>
  );
};

TagsBuilder.propTypes = {
  tags: PropTypes.array,
  isPaid: PropTypes.bool,
  max: PropTypes.number,
  filter: PropTypes.func
};

const createTrackingPixel = (url, i) => {
  return <img key={`pixel-${i}`} src={url} height="1" width="1" alt="" />;
};
/**
 * A small story item, usable in a carousel or other module display.
 * Displays the title, cover, and first tag or tags of a story, as well as a paid stories badge if paywalled.
 */
const StoryHeroItem = ({
  sectionType,
  title,
  id,
  cover,
  user,
  tags,
  description,
  isPaid,
  sources,
  index,
  offset,
  windowWidth,
  wasSeen,
  clickTrackingUrls,
  impressionTrackingUrls,
  callTrackingUrls,
  isPromoted,
  onStoryClick,
  heroItem
}) => {
  // default styles, used when windowWidth > 960
  let descriptionCharLimit = 500,
    titleCharLimit = 200,
    maxTags = 3,
    showAuthor = true,
    tagCharLimit = 18;

  let hasImpressionTrackingUrls =
    impressionTrackingUrls && impressionTrackingUrls.length > 0;

  const smallScreenHeroWidth = 104;
  const largeScreenHeroWidth = 200;
  const coverSizes = getCoverSrcSet(cover);
  const defaultCover = getCoverSrc(cover, smallScreenHeroWidth);

  if (windowWidth > 575 && windowWidth <= 960) {
    descriptionCharLimit = 250;
    maxTags = 2;
  } else if (windowWidth >= 545 && windowWidth <= 575) {
    descriptionCharLimit = 250;
    maxTags = 2;
  } else if (windowWidth < 545 && windowWidth >= 400) {
    descriptionCharLimit = 100;
    titleCharLimit = 60;
    tagCharLimit = 10;
    maxTags = 2;
    showAuthor = false;
  } else if (windowWidth < 400) {
    descriptionCharLimit = 60;
    titleCharLimit = 30;
    tagCharLimit = 8;
    maxTags = 1;
    showAuthor = false;
  }

  const tagFilter = useCallback(tag => tag.length < tagCharLimit, [
    tagCharLimit
  ]);

  useEffect(
    () => {
      if (wasSeen) {
        window.te.push("event", "story", null, null, "view", {
          storyid: id,
          page: "home",
          algo_source: sources,
          position: index,
          offset,
          type: sectionType
        });
        // Promoted unit first-party impression tracker
        if (hasImpressionTrackingUrls) {
          wattpad.utils.pushEvent({
            category: "homePromotedImpression",
            action: "viewed",
            label: `storyId-${id}`
          });
        }
      }
    },
    [wasSeen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onStoryHeroClick = (evt, link) => {
    evt.preventDefault();
    window.te.push("event", "story", null, null, "click", {
      storyid: id,
      page: "home",
      algo_source: sources,
      position: index,
      offset,
      type: sectionType
    });

    // ADS-493: Click & Impression Events
    if (clickTrackingUrls) {
      callTrackingUrls(clickTrackingUrls);
    }
    if (wattpad.utils.getDeviceType() !== "mobile" && onStoryClick) {
      onStoryClick(heroItem);
    } else {
      app.router.navigate(link, { trigger: true });
    }
  };

  return (
    <div className="paid-stories-item hero">
      <div className="cover">
        <a
          href={`/story/${id}`}
          onClick={evt => onStoryHeroClick(evt, `/story/${id}`)}
        >
          <img
            srcSet={coverSizes}
            sizes={`(max-width: 575px) ${smallScreenHeroWidth}px, ${largeScreenHeroWidth}px`}
            src={defaultCover}
            alt={`${title} cover`}
          />
        </a>
      </div>
      <div className="hero-content">
        <a
          className="title"
          href={`/story/${id}`}
          onClick={evt => onStoryHeroClick(evt, `/story/${id}`)}
          aria-label={`Read ${title}`}
        >
          <p>{simpleShorten(title, titleCharLimit, false)}</p>
        </a>
        {user && showAuthor ? (
          <a className="author on-navigate" href={`/user/${user.name}`}>
            {user.name}
          </a>
        ) : null}

        <p className="description">
          {simpleShorten(description, descriptionCharLimit, false)}
        </p>
        <TagsBuilder
          tags={tags}
          isPaid={isPaid}
          max={maxTags}
          filter={tagFilter}
        />
      </div>
      {isPromoted && (
        <div className="right-section">
          <PromotedIndicator />
          {hasImpressionTrackingUrls &&
            impressionTrackingUrls.map((url, i) => {
              return createTrackingPixel(url, i);
            })}
        </div>
      )}
    </div>
  );
};

StoryHeroItem.defaultProps = {
  onStoryClick: null,
  sources: [],
  offset: 0 //default offset as there can only be one Story Hero Item (for now)
};

StoryHeroItem.propTypes = {
  cover: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  isPaid: PropTypes.bool,
  offset: PropTypes.number,
  sectionType: PropTypes.string,
  sources: PropTypes.array,
  tags: PropTypes.array,
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
  description: PropTypes.string,
  wasSeen: PropTypes.bool,
  windowWidth: PropTypes.number,
  clickTrackingUrls: PropTypes.array,
  impressionTrackingUrls: PropTypes.array,
  callTrackingUrls: PropTypes.func,
  isPromoted: PropTypes.bool,
  onStoryClick: PropTypes.func,
  heroItem: PropTypes.object
};

export default StoryHeroItem;
