import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import { maxCharLengthField, requiredField } from "../helpers";
import { OTHER_MATURE_THEMES_CHARACTER_MAX } from "../constants";
import ContestFormField from "../ContestFormField";
import ErrorField from "./ErrorField";

const OtherMatureThemesField = ({
  form,
  disableForm,
  onOtherMatureFieldTextChange
}) => {
  const { trans } = useTrans();

  const title = trans("Mature themes");

  const errorMessage =
    form.matureThemeOther === ""
      ? trans("Specify other mature themes.")
      : form.matureThemeOther?.length > OTHER_MATURE_THEMES_CHARACTER_MAX
        ? trans("This field has to contain less than 100 characters.")
        : "";

  return (
    <ContestFormField title={title}>
      <FormValidation
        validationRules={[
          requiredField(trans),
          maxCharLengthField(OTHER_MATURE_THEMES_CHARACTER_MAX, trans)
        ]}
      >
        {({ ref, className }) => (
          <React.Fragment>
            <textarea
              ref={ref}
              className={classNames("form-element", className)}
              id="other-themes-textarea"
              name="matureThemeOther"
              value={form.matureThemeOther}
              placeholder={trans("Enter your mature themes")}
              disabled={disableForm}
              onChange={onOtherMatureFieldTextChange}
            />
            <ErrorField errorMessage={errorMessage} fieldId={"other-theme"} />
          </React.Fragment>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

OtherMatureThemesField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onOtherMatureFieldTextChange: PropTypes.func.isRequired
};

export default OtherMatureThemesField;
