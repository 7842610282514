import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../../hooks";
import ConnectImage from "../../../shared-components/ConnectImage";
import classNames from "classnames";

export const REPORT_ORIGINAL_STORY_ID = "report_story_copyright_infringement";
const STORY_ID_REGEX = /.*\bwattpad.com\/story\/\b(\d+)(.*)?/;

const getStoryIdByUrl = url => {
  const regexResult = STORY_ID_REGEX.exec(url);
  if (regexResult && regexResult.length > 1) {
    return regexResult[1];
  }
  return undefined;
};

const getStoryTitleById = storyId => {
  const getTitleUrl = `/api/v3/stories/${storyId}?fields=title`;
  return $.get(getTitleUrl);
};

const sendStoryReport = (reportStoryId, originalStoryId) => {
  const sendReportUrl = `/v5/infringement/story/${reportStoryId}/copyright/report?original_story_id=${originalStoryId}`;
  return $.ajax({
    url: sendReportUrl,
    type: "POST",
    data: JSON.stringify(""),
    dataType: "json",
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const ReportOriginalStory = props => {
  const { trans } = useTrans();
  const [originalStoryId, setOriginalStoryId] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [urlError, setUrlError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const setUrlIsInvalid = errorMessage => {
    setOriginalTitle("");
    setOriginalStoryId("");
    setUrlError(errorMessage);
  };

  const validateStoryUrl = storyUrl => {
    const storyId = getStoryIdByUrl(storyUrl);
    if (storyId) {
      getStoryTitleById(storyId)
        .done(data => {
          if (data && data.title) {
            setUrlError("");
            setOriginalStoryId(storyId);
            setOriginalTitle(data.title);
          } else {
            setUrlIsInvalid(trans("Invalid story URL"));
          }
        })
        .fail(() => setUrlIsInvalid(trans("Invalid story URL")));
    } else {
      setUrlIsInvalid(trans("Invalid story URL"));
    }
  };

  const handleOriginalUrlChange = event => {
    const newUrl = event.target.value;
    if (newUrl) {
      validateStoryUrl(newUrl);
    } else {
      setUrlIsInvalid(trans("The original URL is required"));
    }
  };

  const handleAlertClick = () => {
    sendStoryReport(props.storyId, originalStoryId)
      .done(() => {
        setIsSuccess(true);
        props.onReportSubmitted();
      })
      .fail(() => {
        wattpad?.utils?.showToast(
          trans("Something went wrong. Please try again")
        );
      });
  };

  useEffect(props.onContentChanged, [isSuccess, originalTitle]);

  const renderReportBody = () => (
    <div className="modal-body">
      <h6 className="reason-note text-left">
        {trans(
          "The best way to resolve a case of copyright infringement is to alert the original copyright owner of the work to submit a DMCA report."
        )}
        <br />
        <br />
        {trans(
          "Please provide us the original story so that we can alert the original owner to file a copyright report."
        )}
      </h6>
      <div className="body-content">
        <label className="control-label" htmlFor="originalUrl">
          {trans("Please provide the URL of the original work:")}
        </label>
        <div className="reason-comment">
          <textarea
            id="originalUrl"
            className={classNames("form-control details-input", {
              error: !!urlError
            })}
            placeholder={trans("Paste the URL")}
            onChange={handleOriginalUrlChange}
          />
        </div>
        {urlError && (
          <div aria-live="assertive">
            <div className="field-message">{urlError}</div>
          </div>
        )}
      </div>
      <div className="alert-message">
        {trans(
          "By alerting the original author, you are confident that this is a case of copyright and it needs the authors attention."
        )}
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-orange"
          onClick={handleAlertClick}
          disabled={!originalTitle}
        >
          {trans("Alert")}
        </button>
      </div>
    </div>
  );

  const renderReportSuccess = () => (
    <div className="original-success">
      <ConnectImage
        name="shared/report-original-success.svg"
        title="Report original success"
        width="140"
        className="success-img"
        aria-hidden="true"
      />

      <h4 className="reason-note">
        {trans("The original owner has been notified.")}
      </h4>
      <h5 className="reason-note">
        <br />
        {trans(
          "The author of “%s” has been notified about the story “%s”. They will receive a notification to review the story and act accordingly.",
          originalTitle,
          props.storyTitle
        )}
        <br />
        <br />
        {trans(
          "You will not receive an update on the case but thank you for doing all that you can to prevent copyright infringement on Wattpad."
        )}
      </h5>
    </div>
  );

  return isSuccess ? renderReportSuccess() : renderReportBody();
};

ReportOriginalStory.propTypes = {
  storyId: PropTypes.string.isRequired,
  storyTitle: PropTypes.string.isRequired,
  onContentChanged: PropTypes.func.isRequired,
  onReportSubmitted: PropTypes.func.isRequired
};

export default ReportOriginalStory;
