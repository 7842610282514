import PropTypes from "prop-types";
import { useTrans } from "../../../../hooks";
import React, { useState } from "react";
import classNames from "classnames";
import WPInput from "../../../input/WPInput";
import PasswordFields from "../../../PasswordFields";
import { Button } from "@wattpad/web-ui-library";

export const UpdatePasswordModalContent = props => {
  const { trans } = useTrans();
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [error, setError] = useState("");

  const onCurrentPasswordChange = value => {
    setError("");
    setCurrentPassword(value);
  };

  const handleValidPasswordChange = isNewPasswordValid => {
    setIsFormValid(
      props.hasPassword
        ? isNewPasswordValid && !!currentPassword
        : isNewPasswordValid
    );
  };

  const onFormSubmit = async event => {
    setError("");
    event.preventDefault();

    $.ajax({
      type: "POST",
      url: `apiv2/updateuserpassword`,
      data: $(".on-form-submit").serialize()
    })
      .done(() => {
        props.hideModal();
        const redirectUrl = "/login?nexturl=/settings";
        window.location.replace(redirectUrl).reload();
      })
      .fail(err => {
        //if current password is incorrect
        if (err.responseJSON.code === 467) {
          setError(err.responseJSON.message);
        } else {
          props.hideModal();
          const message =
            trans("Something went wrong, your changes were not saved. ") +
            (err.responseJSON.message || "");
          wattpad.utils.showToast(message, { type: "dismissable" });
        }
      });
  };

  return (
    <form className="on-form-submit">
      <input type="hidden" name="id" value={props.userId} />
      {props.hasPassword && (
        <>
          <div className="form-group has-feedback">
            <label className="control-label" htmlFor="old_password">
              {trans("Current Password")}
            </label>
            <div className="password-container">
              <WPInput
                inputType="password"
                placeholder={trans("Current Password")}
                name={"old_password"}
                id={"old_password"}
                alt={trans("Current Password")}
                showVisibilityToggle={true}
                onChange={event => {
                  onCurrentPasswordChange(event.target.value);
                }}
                addClassName={classNames("password-field", {
                  error: error.length > 0
                })}
              />
            </div>
            {error.length > 0 && <div className="error-message">{error}</div>}
          </div>
          <div className="forgot-password-link">
            <a className="on-navigate" href="/forgot?nextUrl=settings">
              {trans("Forgot your password?")}
            </a>
          </div>
        </>
      )}
      <PasswordFields
        name={props.name}
        username={props.username}
        validatePassword={handleValidPasswordChange}
      />
      <Button
        className="save-button"
        onClick={onFormSubmit}
        fullWidth={true}
        disabled={!isFormValid}
        label={trans("Save")}
      />
    </form>
  );
};

UpdatePasswordModalContent.propTypes = {
  hasPassword: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string,
  username: PropTypes.string,
  hideModal: PropTypes.func.isRequired
};
