import _ from "lodash";

let window = window || global;

const isClient = () => {
  const utilsAreUndefined = _.isUndefined(_.get(window, "wattpad.utils"));
  return !utilsAreUndefined;
};

export { isClient };
