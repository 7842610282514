import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const CTA = ({
  sectionType,
  index,
  prompt,
  button,
  weblink,
  background,
  wasSeen
}) => {
  useEffect(
    () => {
      if (wasSeen) {
        window.te.push("event", "home", "cta", null, "view", {
          page: "home",
          position: index,
          type: sectionType
        });
      }
    },
    [wasSeen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = () => {
    window.te.push("event", "home", "cta", null, "click", {
      page: "home",
      position: index,
      type: sectionType
    });
  };

  if (prompt && weblink) {
    return (
      <div className={classNames("cta module", background)}>
        <div className="module-content">
          <a
            href={weblink}
            className={classNames("on-navigate btn-secondary", button)}
            onClick={handleClick}
          >
            {prompt}
          </a>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

CTA.propTypes = {
  wasSeen: false
};

CTA.propTypes = {
  sectionType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  prompt: PropTypes.string,
  button: PropTypes.string,
  applink: PropTypes.string,
  weblink: PropTypes.string,
  background: PropTypes.string,
  image: PropTypes.string,
  message: PropTypes.string,
  wasSeen: PropTypes.bool
};

export default CTA;
