import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { WPModal } from "../../shared-components";
import { injectTrans } from "../../helpers";
// Components
import NavBar from "../nav/NavBar";
import Hero from "./Hero";
import HowWattpadWorks from "./HowWattpadWorks";
import Carousel from "./Carousel";
import Links from "./Links";
import TakeWPWithYou from "./TakeWPWithYou";
import { WattpadBooks } from "./WattpadBooks";
import { WattpadStudios } from "./WattpadStudios";
import BrandPartnerships from "./BrandPartnershipsBanner";
import { SeeYourStory } from "./SeeYourStory";

// Action Creators
import { toggleModal } from "../../shared-components/modals/actions";

import AuthForm from "../authentication/AuthForm";

class LandingPage extends Component {
  toggleModal = ({ type = "login", source = "" }) => {
    const modalProps = {
      model: this.props.model,
      nextUrl: this.props.nextURL
    };
    switch (type) {
      case "signup":
        this.props.toggleModal({
          className: "signup-modal",
          component: () => <AuthForm {...modalProps} source={source} />
        });
        break;
      case "login":
        this.props.toggleModal({
          className: "login-modal",
          component: () => <AuthForm {...modalProps} login />
        });
        break;
    }
  };
  render() {
    return (
      <div id="landing">
        <NavBar toggleModal={this.toggleModal} />
        <Hero toggleModal={this.toggleModal} />
        <div id="Intro">
          <SeeYourStory />
          <WattpadStudios />
          <WattpadBooks />
        </div>
        <div id="FindOutMore">
          <a
            href="https://creators.wattpad.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4>
              {this.props.trans("Find out more about what we do for writers")}
              {" →"}
            </h4>
          </a>
        </div>
        <HowWattpadWorks />
        <Carousel />
        <Links />
        <BrandPartnerships />
        <TakeWPWithYou toggleModal={this.toggleModal} />
        <WPModal />
      </div>
    );
  }

  static propTypes = {
    model: PropTypes.object,
    root: PropTypes.string,
    nextURL: PropTypes.string,
    toggleModal: PropTypes.func,
    hybridLayout: PropTypes.bool,
    trans: PropTypes.func.isRequired
  };
}

const connectedLandingPage = connect(
  null,
  { toggleModal }
)(LandingPage);

export default injectTrans(connectedLandingPage);
