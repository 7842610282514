import React from "react";
import PropTypes from "prop-types";

import { Button, ButtonVariant } from "@wattpad/web-ui-library";

import { useTrans } from "../../../hooks";

function ExpandPromptButton({ expandPrompt, isPaid }) {
  const { trans } = useTrans();

  let { weblink = "", prompt = "", tracking = null } = expandPrompt || {};

  const onButtonClick = () => {
    if (!tracking) return;
    window.te.push("event", "app", "page", null, "view", {
      page: tracking.page,
      source: tracking.source
    });
  };

  if (isPaid) {
    weblink = "/catalog/wattpadoriginals";
    prompt = trans("See all");
  }

  if (!weblink || !prompt) {
    return null;
  }

  return (
    <Button
      className="expand-prompt-button"
      href={weblink}
      onClick={onButtonClick}
      variant={ButtonVariant.LINK}
      label={prompt}
    />
  );
}

ExpandPromptButton.propTypes = {
  expandPrompt: PropTypes.object,
  isPaid: PropTypes.bool
};

export { ExpandPromptButton };
