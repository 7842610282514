import React from "react";
import PropTypes from "prop-types";

import { Icon, Avatar } from "../../shared-components";
import { injectTrans } from "../../helpers";
import { MAP_EARN_COINS_LINKS, DEFAULT_EARN_COINS_LINK } from "./constants";

const StoryPaywallModal = ({ group, authorMessage, onBuyCoins, trans }) => {
  const { user: storyAuthor } = group;
  const { username, name, avatar } = storyAuthor;
  const displayLanguage = parseInt(app.get("language"), 10);
  return (
    <div className="story-paywall">
      <span data-dismiss="modal">
        <Icon iconName="fa-remove" height="20" color="wp-neutral-2" />
      </span>
      <h2>
        {/* prettier-ignore */ trans("If you are enjoying this story, please consider helping fund my writing")}
      </h2>
      <div className="avatar">
        <Avatar avatar={avatar} username={username} />
      </div>
      <a className="on-navigate profile-link" href={`/user/${username}`}>
        @{username}
      </a>
      <div className="blocked-message">
        <span className="italic">{authorMessage}</span>
        <div className="author-name">{name}</div>
      </div>
      <button
        onClick={onBuyCoins}
        className="btn btn-orange unlock unlock-part"
      >
        {trans("Get Coins to support me")}
      </button>
      <div className="more-details">
        {/* prettier-ignore */ trans("When you unlock stories with Coins you are directly supporting writers, so they can keep writing stories you love.")}{" "}
        <a
          href={
            MAP_EARN_COINS_LINKS[displayLanguage] || DEFAULT_EARN_COINS_LINK
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans("Learn how your support is empowering writers")}
        </a>
      </div>
    </div>
  );
};

StoryPaywallModal.propTypes = {
  group: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  authorMessage: PropTypes.string.isRequired,
  onBuyCoins: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired
};

export default injectTrans(StoryPaywallModal);
