import { connect } from "react-redux";
import { sprintf } from "sprintf-js";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Icon, ConnectImage } from "../../shared-components";
import { toggleModal } from "../../shared-components/modals/actions";

import { count, injectTrans } from "../../helpers";
import { WALLET_STATES } from "./constants";
import { fetchWalletBalance } from "./actions";
import { UserContext } from "../../contexts/UserContext";
import CoinIcon from "./CoinIcon";
import CoinShop from "./CoinShop";

export class WalletUI extends React.Component {
  static propTypes = {
    wallet: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      animationStep: PropTypes.string.isRequired,
      fetched: PropTypes.bool.isRequired
    }).isRequired,
    purchase: PropTypes.shape({
      amountSpent: PropTypes.number.isRequired,
      buyingPart: PropTypes.bool
    }).isRequired,
    trans: PropTypes.func.isRequired,
    ngettext: PropTypes.func.isRequired,
    onWalletClick: PropTypes.func,
    toggleModal: PropTypes.func,
    fetchWalletBalance: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = { disabled: false };

  static contextType = UserContext;

  componentDidMount() {
    const user = this.context;
    const isLoggedIn = !!user.username;
    if (isLoggedIn) {
      this.props.fetchWalletBalance(user.username);
    }
  }

  onWalletClick = () => {
    // If web purchasing is enabled, open the coin
    // shop instead of the modal explaining how to purchase coins.
    if (wattpad.testGroups.WEB_COIN_PURCHASING) {
      const { toggleModal } = this.props;
      toggleModal({
        component: ({ toggleModal }) => <CoinShop toggleModal={toggleModal} />,
        className: "coins-modal-container",
        hideClose: true
      });
      return;
    }

    this.props.onWalletClick();
  };

  render() {
    const { wallet, purchase, trans, ngettext, disabled } = this.props;
    const { amount, animationStep, fetched: walletAmountFetched } = wallet;
    const { amountSpent } = purchase;
    const user = this.context;

    let classes = classnames("wallet");
    if (animationStep === WALLET_STATES.COIN) {
      classes = classnames("coin-solo", classes);
      return (
        <button className={classes}>
          <CoinIcon />
        </button>
      );
    } else if (animationStep === WALLET_STATES.DEDUCTING) {
      classes = classnames("deducting", classes);
      return (
        <button className={classes}>
          <div className="deducting-icon">
            <ConnectImage
              name="/icons/spinner.svg"
              className="fa-spin"
              alt={trans("Spinner")}
              height="24"
              width="24"
            />
          </div>
          <span className="num-coins">{trans("Deducting")}</span>
        </button>
      );
    } else if (animationStep === WALLET_STATES.SUBTRACTING) {
      classes = classnames("subtracting", classes);
      const { buyingPart } = purchase;
      let coinsSpentString;
      if (buyingPart) {
        coinsSpentString = ngettext(
          "-%s Coin for next part",
          "-%s Coins for next part",
          amountSpent
        );
      } else {
        coinsSpentString = ngettext(
          "-%s Coin for story",
          "-%s Coins for story",
          amountSpent
        );
      }
      coinsSpentString = sprintf(coinsSpentString, amountSpent);
      return (
        <button className={classes}>
          <div className="coin-container">
            <CoinIcon />
          </div>
          <span className="num-coins">{coinsSpentString}</span>
        </button>
      );
    }

    const isLoggedIn = !!user.username;
    let displayedAmount;
    if (!isLoggedIn) {
      displayedAmount = 0;
    } else if (!walletAmountFetched) {
      displayedAmount = "-";
    } else {
      // Support full accuracy for 4 digits
      displayedAmount = amount < 10000 ? amount : count(amount);
    }

    classes = classnames("default", classes);
    return (
      <button
        onClick={this.onWalletClick}
        className={classes}
        disabled={disabled}
        title={trans("Wallet with %s Coins", amount)}
      >
        <CoinIcon />
        <span className="num-coins divider-right">{displayedAmount}</span>
        {!disabled && (
          <div className="get-coins">
            <Icon iconName="fa-plus" height="12" color="wp-neutral-1" />
          </div>
        )}
      </button>
    );
  }
}

const mapStateToProps = state => {
  const { wallet, purchase } = state;
  return { wallet, purchase };
};

export default connect(
  mapStateToProps,
  { toggleModal, fetchWalletBalance }
)(injectTrans(WalletUI));
