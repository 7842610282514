import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import { DateSeparator } from "./DateSeparator";
import Message from "./Message";

function Messages({
  messages,
  username,
  isActive,
  scrollToMessageId,
  setRef,
  isLastBatch
}) {
  // fromUserTracker: tracks which user sent the last message
  // dateTracker: tracks the previous date the message was sent
  let fromUserTracker, dateTracker;
  return (
    <div className="conversations">
      {messages.map((message, i) => {
        let differentSender = false;
        let dateDisplay = null;
        if (fromUserTracker !== message.from.name) {
          differentSender = true;
          fromUserTracker = message.from.name;
        }

        const currentMessageDate = moment(message.createDate);
        const isLastMessage = i === 0;
        const isStartOfConversation = isLastMessage && isLastBatch;
        if (isStartOfConversation) {
          dateDisplay = currentMessageDate;
        } else if (dateTracker) {
          const timeBetweenMessages = moment.duration(
            currentMessageDate.diff(dateTracker)
          );
          if (timeBetweenMessages.asHours() >= 6) {
            dateDisplay = currentMessageDate;
          }
        }
        dateTracker = currentMessageDate;
        return (
          <React.Fragment key={message.id}>
            {dateDisplay && <DateSeparator dateDisplay={dateDisplay} />}
            <Message
              setRef={setRef}
              scrollToMessageId={scrollToMessageId}
              message={message}
              username={username}
              isActive={isActive}
              differentSender={differentSender}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  username: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  scrollToMessageId: PropTypes.number.isRequired,
  setRef: PropTypes.object.isRequired,
  isLastBatch: PropTypes.bool.isRequired
};

export { Messages };
