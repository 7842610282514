import React from "react";
import PropTypes from "prop-types";

const Avatar = ({ avatar, username }) => {
  return <img src={avatar} aria-hidden="true" alt={username} />;
};

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default Avatar;
