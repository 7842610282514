import { isClient } from "./isClient";
let window = window || global;
var utils = (window.wattpad && window.wattpad.utils) || {};

const storages = {
  localStorage: {
    get: key => window.localStorage.getItem(key),
    set: (key, val) => window.localStorage.setItem(key, val)
  },
  sessionStorage: {
    get: key => window.sessionStorage.getItem(key),
    set: (key, val) => window.sessionStorage.setItem(key, val)
  },
  cookie: {
    get: key => utils.getCookie(key),
    set: (key, val) => utils.setCookie(key, val)
  }
};

const validateStorageType = type => {
  const allowedTypes = ["cookie", "localStorage", "sessionStorage"];
  if (allowedTypes.indexOf(type) === -1) {
    return false;
  }
  if (type === "cookie") {
    return utils && utils.getCookie;
  } else if (type === "localStorage") {
    return window && window.localStorage;
  } else if (type === "sessionStorage") {
    return window && window.sessionStorage;
  }
};

const getStorageItem = ({ key, type = "cookie" }) => {
  if (isClient && validateStorageType(type)) {
    try {
      const fetchedItem = storages[type].get(key);
      return fetchedItem ? JSON.parse(fetchedItem) : null;
    } catch (err) {
      return null;
    }
  }
  return null;
};

const setStorageItem = ({ key, value, type = "cookie" }) => {
  if (isClient && validateStorageType(type)) {
    storages[type].set(key, JSON.stringify(value));
  }
};

export { getStorageItem, setStorageItem };
