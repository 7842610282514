const sanitizeHTML = str => {
  return str && typeof str === "string"
    ? str
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
    : str;
};

export { sanitizeHTML };
