import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../../shared-components/";
// Alert

function Alert({ variant, messageNode }) {
  return (
    <div className={classNames("ds-alert", variant)}>
      <Icon name="warning" size="24" color="ds-alert" />
      {messageNode}
    </div>
  );
}

Alert.propTypes = {
  messageNode: PropTypes.node.isRequired,
  variant: PropTypes.string
};

Alert.defaultProps = {
  variant: ""
};

export { Alert };
