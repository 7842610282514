import NukaCarousel from "nuka-carousel";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Icon } from "../../../shared-components";
import StorySlide from "./StorySlide";
import rtlLanguage from "../../../../helpers/handlebars/rtl-language";
import { useGetUser, useTrans } from "../../../hooks";
import { pixelRatio } from "../../../helpers/pixelRatio";
/**
 * A carousel containing story items.
 */
const StoryCarousel = ({
  sectionType,
  stories,
  index,
  windowWidth,
  wasSeen,
  storiesToShow = 5,
  mobileStoriesToShow,
  storyWidth = 88,
  page,
  onStoryClick,
  clickTrackingUrls,
  callTrackingUrls,
  SlideComponent
}) => {
  let frameOverflowVisible = false;
  const isSmallScreen = windowWidth < 575;

  if (windowWidth <= 960) {
    storiesToShow = mobileStoriesToShow || 2.5;
    frameOverflowVisible = true;

    if (isSmallScreen) {
      if (pixelRatio(2.5)) {
        storyWidth = Math.floor(105 / 3);
      } else if (pixelRatio(1.5)) {
        storyWidth = Math.floor(105 / 2);
      }
    }
  }

  const isRTL = rtlLanguage(parseInt(useGetUser().language, 10));
  const { trans } = useTrans();

  const [storiesSeen, setStoriesSeen] = useState([]);

  useEffect(
    () => {
      if (wasSeen) {
        const numStoriesShown = Math.min(
          Math.round(storiesToShow),
          stories.length
        );
        stories.slice(0, numStoriesShown).map((story, i) => {
          if (page === "story_details") {
            // Similar stories event
            window.te.push("event", "story", null, null, "view", {
              storyid: story.id,
              page: "story_details",
              type: "similar_stories",
              source: "story_details"
            });
          } else {
            pushStoryViewEvent(story.id, story.sources, index, i, sectionType);
          }
        });
        setStoriesSeen([
          ...storiesSeen,
          ...stories.slice(0, numStoriesShown).map((_, i) => i)
        ]);
      }
    },
    [wasSeen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const afterSlide = storyIndex => {
    const numStoriesShown = Math.min(
      storyIndex + Math.round(storiesToShow),
      stories.length
    );
    stories.slice(storyIndex, numStoriesShown).map((story, i) => {
      if (storiesSeen.includes(storyIndex + i)) return;
      if (page === "story_details") {
        // Similar stories event
        window.te.push("event", "story", null, null, "view", {
          storyid: story.id,
          page: "story_details",
          type: "similar_stories",
          source: "story_details"
        });
      } else {
        pushStoryViewEvent(
          story.id,
          story.sources,
          index,
          storyIndex + i,
          sectionType
        );
      }
    });
    setStoriesSeen([
      ...storiesSeen,
      ...stories
        .slice(storyIndex, numStoriesShown)
        .map((_, i) => storyIndex + i)
    ]);
  };

  const pushStoryViewEvent = (
    storyid,
    algo_source = [],
    position,
    offset,
    type
  ) => {
    window.te.push("event", "story", null, null, "view", {
      storyid,
      page: page,
      algo_source,
      position,
      offset,
      type
    });
  };

  const renderNextBtn = (currentSlide, nextSlide, slideCount) =>
    currentSlide !== slideCount - storiesToShow || isRTL ? (
      <button
        className="next-btn"
        onClick={nextSlide}
        aria-label={trans("Next")}
      >
        <Icon name="arrowCircleRight" size="40" alt="next" strokeWidth="2.25" />
      </button>
    ) : null;

  const renderPrevBtn = (currentSlide, previousSlide) =>
    currentSlide !== 0 || isRTL ? (
      <button
        className="prev-btn"
        onClick={previousSlide}
        aria-label={trans("Previous")}
      >
        <Icon
          name="arrowCircleLeft"
          size="40"
          alt="previous"
          strokeWidth="2.25"
        />
      </button>
    ) : null;

  return stories !== null && stories.length !== 0 ? (
    <div className="story-carousel">
      {/* If page is rtl, enforce carousel to be LTR to prevent NukaCarousel from breaking */}
      <div
        {...(isRTL
          ? { dir: "ltr", className: "carousel-container rtl-adjust" }
          : { className: "carousel-container" })}
      >
        {/*
        dragging: mouse interaction with the carousel
        swiping: touch interaction with the carousel
        Both are enabled by default
        */}
        <NukaCarousel
          dragging={false}
          afterSlide={wasSeen ? afterSlide : () => {}}
          enableKeyboardControls
          frameOverflow={frameOverflowVisible ? "visible" : "hidden"}
          heightMode="max"
          slidesToShow={storiesToShow}
          slidesToScroll="auto"
          initialHeight="1"
          renderCenterRightControls={
            storiesToShow === 5 && stories.length > 5
              ? ({ currentSlide, nextSlide, slideCount }) =>
                  renderNextBtn(currentSlide, nextSlide, slideCount)
              : null
          }
          renderCenterLeftControls={
            storiesToShow === 5 && stories.length > 5
              ? ({ currentSlide, previousSlide }) =>
                  renderPrevBtn(currentSlide, previousSlide)
              : null
          }
          renderBottomCenterControls={null}
          wrapAround={isRTL}
        >
          {stories.map((storyData, i) => {
            if (storyData.id && storyData.cover)
              return (
                <SlideComponent
                  {...storyData}
                  sectionType={sectionType}
                  index={index}
                  offset={i}
                  width={storyWidth}
                  key={`${storyData.id}-${i}`}
                  page={page}
                  onStoryClick={onStoryClick}
                  storyData={storyData}
                  clickTrackingUrls={clickTrackingUrls}
                  callTrackingUrls={callTrackingUrls}
                />
              );
          })}
        </NukaCarousel>
      </div>
    </div>
  ) : null;
};

StoryCarousel.defaultProps = {
  onStoryClick: null,
  SlideComponent: StorySlide
};

StoryCarousel.propTypes = {
  sectionType: PropTypes.string.isRequired,
  stories: PropTypes.array.isRequired,
  index: PropTypes.number,
  wasSeen: PropTypes.bool,
  windowWidth: PropTypes.number,
  storiesToShow: PropTypes.number,
  mobileStoriesToShow: PropTypes.number,
  storyWidth: PropTypes.number,
  page: PropTypes.string,
  onStoryClick: PropTypes.func,
  clickTrackingUrls: PropTypes.array,
  callTrackingUrls: PropTypes.func,
  SlideComponent: PropTypes.func
};

export default StoryCarousel;
