import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import { requiredField } from "../helpers";
import ContestFormField from "../ContestFormField";
import { MAP_ANTICIPATED_STORY_LENGTH } from "../constants";
import ErrorField from "./ErrorField";

const AnticipatedStoryLengthField = ({
  form,
  disableForm,
  onFormChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Ongoing story length");

  const subtitle = (
    <div className="subtitle">
      {trans(
        "How long do you plan on making this story (if it is a series, then how long do you plan to make the entire series)?"
      )}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "anticipatedStoryLength" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.anticipatedStoryLength === "";

  if (form.storyStatus === "ONGOING") {
    return (
      <ContestFormField title={title} subtitle={subtitle}>
        <FormValidation validationRules={[requiredField(trans)]}>
          {({ ref, errorMessage, className }) => (
            <>
              <select
                id="anticipated-story-length-select"
                ref={ref}
                value={form.anticipatedStoryLength}
                name="anticipatedStoryLength"
                disabled={disableForm}
                className={classNames("form-element", className)}
                onChange={onFormChange}
                aria-label={trans("Select a length")}
              >
                <option value="" disabled>
                  {trans("Select a length")}
                </option>
                {MAP_ANTICIPATED_STORY_LENGTH(trans).map(length => (
                  <option key={length.key} value={length.key}>
                    {length.value}
                  </option>
                ))}
              </select>
              <ErrorField
                errorMessage={errorMessage}
                fieldId={"anticipated-story-length"}
              />
              {!errorMessage && (
                <ErrorField
                  fieldId={"anticipated-story-length"}
                  errorCondition={errorCondition}
                />
              )}
            </>
          )}
        </FormValidation>
      </ContestFormField>
    );
  }
  return null;
};

AnticipatedStoryLengthField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default AnticipatedStoryLengthField;
