import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import { MAP_WATTYS_LANGUAGES } from "../constants";
import ContestFormField from "../ContestFormField";

const LanguageField = ({ form, storyId }) => {
  const { trans } = useTrans();

  const title = trans("Award language");

  const subtitle = (
    <div
      className="subtitle"
      dangerouslySetInnerHTML={{
        __html: /* prettier-ignore */ trans('You are submitting your story to the award language listed below. To change the language your story is written in, please make any necessary edits in your <a href="%s" target="_blank" rel="noopener noreferrer">story settings</a>.', `/myworks/${storyId}`)
      }}
    />
  );

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <select
        id="language-select"
        value={form.language}
        name="language"
        disabled
        className="form-element"
        aria-label={form.language}
      >
        {MAP_WATTYS_LANGUAGES(trans)
          .filter(lang => lang.key === form.language)
          .map(lang => (
            <option key={lang.key} value={lang.key}>
              {lang.value}
            </option>
          ))}
      </select>
    </ContestFormField>
  );
};

LanguageField.propTypes = {
  form: PropTypes.object.isRequired,
  storyId: PropTypes.string.isRequired
};

export default LanguageField;
