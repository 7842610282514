import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextBox } from "@wattpad/web-ui-library";
import { postComment } from "@wattpad/client-platform-comments";

import { useTrans, useUserVerification } from "../../../hooks";
import { isReply } from "../CommentsUtils";

const PostNewComment = ({
  resource,
  isSticky,
  autofocus,
  placeholder,
  replyToUsername,
  onCommentPosted
}) => {
  const { trans } = useTrans();
  const { userNeedsVerification } = useUserVerification();

  const [hasError, setHasError] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [comment, setComment] = useState(
    replyToUsername && `@${replyToUsername} `
  );

  const placeholderText = placeholder || trans("Write a comment...");

  const handleCommentPosted = result => {
    result.isNewComment = true;
    setHasError(false);
    setIsPosting(false);
    setComment("");
    onCommentPosted(result);
  };

  const handleOnSendClick = () => {
    if (userNeedsVerification()) return;
    setIsPosting(true);
    setHasError(false);
    postComment(resource, comment)
      .then(handleCommentPosted)
      .catch(() => {
        setHasError(true);
        setIsPosting(false);
      });
  };

  return (
    <div
      className={classNames("new-comment-field", {
        sticky: isSticky,
        replyTo: !!replyToUsername,
        reply: isReply(resource.namespace)
      })}
    >
      {replyToUsername && (
        <div className="reply-to-user">
          {trans("Replying to %s", replyToUsername)}
        </div>
      )}
      <TextBox
        text={comment}
        isLoading={isPosting}
        autoFocus={autofocus}
        onTextChange={setComment}
        placeholder={placeholderText}
        actionSend={handleOnSendClick}
        ariaLabel={trans("Post new comment")}
        errorMessage={hasError ? trans("Comment failed to post") : undefined}
      />
    </div>
  );
};

PostNewComment.propTypes = {
  resource: PropTypes.object.isRequired,
  isSticky: PropTypes.bool,
  autofocus: PropTypes.bool,
  placeholder: PropTypes.string,
  replyToUsername: PropTypes.string,
  onCommentPosted: PropTypes.func.isRequired
};

export default PostNewComment;
