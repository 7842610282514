import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import classnames from "classnames";

import { Icon } from "../../shared-components";
import { loadFeaturedList } from "./reducers/paid-stories-catalog-reducer";
import { openPopover } from "../../shared-components/popover/reducers";
import { useTrans } from "../../hooks";

function CatalogHeaderUI({
  title,
  subtitle,
  loadFeaturedList,
  featuredList,
  openPopover,
  readingList,
  isMobile,
  catalog
}) {
  const triggerRef = useRef(null);
  const { trans } = useTrans();

  subtitle =
    subtitle ||
    trans("Check out the latest and greatest stories hand-picked by our team");

  useEffect(
    () => {
      loadFeaturedList();
    },
    [loadFeaturedList]
  );

  const onTitleClick = () => {
    if (featuredList.length > 0) {
      let component = () => (
        <div className="react-popover-list">
          <a
            className={classnames("on-navigate react-popover-item", {
              selected: catalog === "wattpadoriginals"
            })}
            href={`/catalog/wattpadoriginals`}
          >
            {trans("Wattpad Originals")}
          </a>
          {featuredList.map(featured => (
            <a
              key={featured.id}
              className={classnames("react-popover-item", {
                selected: readingList && featured.id === readingList.id
              })}
              href={`/featured/${featured.id}`}
            >
              {featured.name}
            </a>
          ))}
          <a
            className={classnames("on-navigate react-popover-item")}
            href={`/catalog/premiumpicks`}
          >
            {trans("Premium Picks")}
          </a>
        </div>
      );
      openPopover({
        component,
        triggerRef
      });
    }
  };

  if (!title) {
    return null;
  }

  return (
    <header className="catalog-header right-icon">
      {isMobile ? (
        <h1 className="catalog-title">{title}</h1>
      ) : (
        <button
          disabled={featuredList.length === 0}
          className="btn-no-style list-title"
          onClick={onTitleClick}
          aria-label={trans("Show featured list")}
          ref={triggerRef}
        >
          <h1>{title}</h1>
          <Icon name="chevDown" size={24} />
        </button>
      )}
      <h2 className="subtitle">{subtitle}</h2>
    </header>
  );
}

CatalogHeaderUI.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loadFeaturedList: PropTypes.func,
  featuredList: PropTypes.array,
  openPopover: PropTypes.func,
  readingList: PropTypes.object,
  isMobile: PropTypes.bool,
  isPremium: PropTypes.bool,
  catalog: PropTypes.string
};

const CatalogHeader = connect(
  state => ({ featuredList: state.paidStoriesCatalog.featuredList }),
  { loadFeaturedList, openPopover }
)(CatalogHeaderUI);

export { CatalogHeader };
