import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TagItem from "./TagItem";
import classnames from "classnames";

import { injectTrans } from "../../helpers";

class TagMeta extends React.Component {
  tagClickHandler(evt) {
    window.wattpad.utils.stopEvent(evt);
    window.app.trigger("app:component:TagMetaItem:click", evt);
  }

  numNotShownClickHandler(evt, defaultClickEvents, storyId) {
    if (evt.keyCode && evt.keyCode != 13) return;
    if (defaultClickEvents) return (window.location = `/story/${storyId}`);
    window.wattpad.utils.stopEvent(evt);
    window.app.trigger("app:component:TagMetaNumNotShown:click", evt);
  }

  // Output: { tags: [ { id: tag, name: tag },... ], numNotShown: <int indicating total number of tags not shown> }
  // numShown is defaulted to 3
  formatTags(originalTags = null, numShown = 3) {
    if (_.isEmpty(originalTags)) {
      return {};
    }

    let tags = _.clone(originalTags),
      newTags = { tags: [], numNotShown: Math.max(tags.length - numShown, 0) },
      randomlySelectedTag = null;

    if (tags.length <= numShown) {
      _.each(tags, function(tag) {
        newTags.tags.push({ id: tag, name: tag, link: `/stories/${tag}` });
      });
    } else {
      while (newTags.tags.length < numShown) {
        randomlySelectedTag = tags.splice(
          Math.floor(Math.random() * tags.length),
          1
        )[0];
        newTags.tags.push({
          id: randomlySelectedTag,
          name: randomlySelectedTag,
          link: `/stories/${randomlySelectedTag}`
        });
      }
    }
    return newTags;
  }

  // Component expects tagMeta={ tags: [ 'array', 'of', 'tags',... ] }
  // See TagItem for more properties
  render() {
    const self = this;

    const { trans, tagMeta, defaultClickEvents } = this.props;

    const formattedTagsObject = this.formatTags(tagMeta.tags, tagMeta.numShown),
      storyId = tagMeta.storyId || null; // Needed for the browse-story-preview modal
    let numNotShown = null;

    if (formattedTagsObject.numNotShown) {
      numNotShown = (
        <span
          className={classnames("num-not-shown", {
            "on-story-preview": storyId
          })}
          data-story-id={storyId}
          onClick={evt =>
            self.numNotShownClickHandler(evt, defaultClickEvents, storyId)
          }
          onKeyDown={evt =>
            self.numNotShownClickHandler(evt, defaultClickEvents, storyId)
          }
          role="button"
          tabIndex="0"
        >
          {trans("+%s more", formattedTagsObject.numNotShown)}
        </span>
      );
    }

    if (formattedTagsObject.tags && formattedTagsObject.tags.length > 0) {
      return (
        <div className="tag-meta clearfix">
          <ul className="tag-items">
            {formattedTagsObject.tags.map(tag => {
              return (
                <li key={tag.id}>
                  <TagItem
                    {...tag}
                    clickHandler={
                      !defaultClickEvents ? self.tagClickHandler : undefined
                    }
                  />
                </li>
              );
            })}
          </ul>

          {numNotShown}
        </div>
      );
    }
    return null;
  }
}

TagMeta.propTypes = {
  tagMeta: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    storyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numShown: PropTypes.number
  }).isRequired,
  trans: PropTypes.func.isRequired,
  defaultClickEvents: PropTypes.bool
};

export default injectTrans(TagMeta);
