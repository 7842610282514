import React from "react";
import PropTypes from "prop-types";

export default function EducationalBanner({ bannerMessages = [] }) {
  return (
    bannerMessages.length > 0 && (
      <div className="educational-banner">
        {bannerMessages.map((message, index) => (
          <div
            className="educational-banner__message"
            key={index}
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        ))}
      </div>
    )
  );
}

EducationalBanner.propTypes = {
  bannerMessages: PropTypes.arrayOf(PropTypes.string)
};
