import React from "react";
import PropTypes from "prop-types";

import { Icon } from ".";
import { useTrans } from "../hooks";
import { PREMIUM_PICKS_LIVENESS } from "../constants";

export const PremiumPicksProps = {
  liveness: PropTypes.oneOf(Object.values(PREMIUM_PICKS_LIVENESS)),
  endsAt: PropTypes.object,
  startsAt: PropTypes.object
};

/**
 * The premium picks card.
 */
const PremiumPicksCard = ({ premiumPicks }) => {
  const { trans } = useTrans();

  if (!premiumPicks) return null;

  const isCurrentPick = premiumPicks.liveness == PREMIUM_PICKS_LIVENESS.NOW;
  const cardDate = isCurrentPick ? premiumPicks.endsAt : premiumPicks.startsAt;

  const onCardClick = () => {
    window.te.push("event", "app", "page", null, "view", {
      page: "premium_picks",
      source: "story_details"
    });
  };

  return (
    <div>
      {/* eslint-disable-next-line wp-lint-rules/valid-href */}
      <a
        className="card"
        href="/catalog/premiumpicks"
        tabIndex="0"
        onClick={onCardClick}
      >
        <div className="card__action-icon-premium-content">
          <Icon
            name="zap"
            size="12"
            className="card__action-icon"
            color={"ds-neutral-00-solid"}
            fill
          />
        </div>
        <div className="card__label column">
          <span id="content-title" className="card__title">
            {trans("Premium Picks")}
          </span>
          <div className="card__description md">
            {isCurrentPick
              ? trans("Free for subscribers until")
              : trans("Free for subscribers on")}{" "}
            {cardDate.toLocaleString("default", {
              month: "short",
              day: "numeric"
            })}
          </div>
        </div>
        <Icon className="card__nav-icon" name="chevRight" size="24" />
      </a>
    </div>
  );
};

PremiumPicksCard.propTypes = {
  premiumPicks: PropTypes.shape(PremiumPicksProps)
};

export default PremiumPicksCard;
