import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProfileHeader from "./components/ProfileHeader";
import UnblockModal from "../../shared-components/modals/components/user-safety-modals/UnblockModal";
import CloseAccountModal from "../../shared-components/modals/components/user-safety-modals/CloseAccountModal";
import TimeoutModal from "../../shared-components/modals/components/user-safety-modals/TimeoutModal";

import { WPModal } from "../../shared-components";

import { toggleModal } from "../../shared-components/modals/actions";
import { toggleToast } from "../../shared-components/toasts/reducers";
import { Toast } from "../../shared-components/toasts/Toast";
import { useTrans } from "../../hooks";
import ProfileMoreOptionsButton from "./ProfileMoreOptionsButton";

const RestrictedProfile = props => {
  const {
    profileType,
    username,
    avatar,
    name,
    backgroundUrl,
    highlight_colour,
    numFollowers,
    numStoriesPublished,
    numLists,
    toggleModal,
    toggleToast,
    onUnmuteUser,
    onUnblock,
    onBlock,
    recentlyMuted,
    recentlyBlocked,
    isMobile,
    isMuted,
    safety,
    isAmbassador
  } = props;

  const { trans } = useTrans();
  const [profileState, setProfileState] = useState(profileType);
  const [showBlockedToast, setShowBlockedToast] = useState(recentlyBlocked);
  const [showUnblockedToast, setShowUnblockedToast] = useState(false);

  useEffect(
    () => {
      if (showUnblockedToast) {
        toggleToast({
          className: "unblock",
          body: trans("%s is now unblocked. You can block them at any time.", username) //prettier-ignore
        });
      } else if (recentlyBlocked) {
        toggleToast({
          className: "block",
          body: trans("%s is now blocked. You can unblock them at any time.", username) //prettier-ignore
        });
      }
      return () => {
        toggleToast();
      };
    },
    [
      recentlyMuted,
      trans,
      username,
      toggleToast,
      profileState,
      recentlyBlocked,
      showUnblockedToast
    ]
  );

  const onClickUnblock = buttonRef => {
    const modalProps = {
      username: username,
      toggleModal,
      onUnblock: unblockCheckPoint,
      buttonRef: buttonRef.current
    };

    const component = () => <UnblockModal {...modalProps} />;
    component.displayName = "UnblockModal";

    toggleModal({
      type: "unblock",
      className: "unblock-modal",
      hideClose: true,
      component
    });
  };
  const onClickCloseAccount = () => {
    const modalProps = {
      toggleModal
    };

    const component = () => <CloseAccountModal {...modalProps} />;
    component.displayName = "CloseAccountModal";

    toggleModal({
      type: "closeAccountModal",
      className: "close-account-modal",
      hideClose: true,
      component
    });
  };

  const onClickTimeout = () => {
    const modalProps = {
      toggleModal
    };
    const component = () => <TimeoutModal {...modalProps} />;
    component.displayName = "TimeoutModal";

    toggleModal({
      type: "timeoutModal",
      className: "timeout-modal",
      hideClose: true,
      component
    });
  };

  // The following functions are used for mute and block interactions
  // BlockCheckPoint/UnblockCheckpoint ensure the user views the restricted profile when they have previously been muted
  const blockCheckPoint = username => {
    if (safety.isMuted) {
      setProfileState("block");
      setShowBlockedToast(true);
      setShowUnblockedToast(false);
    }
    onBlock(username);
  };

  const unblockCheckPoint = username => {
    if (safety.isMuted) {
      setProfileState("mute");
      setShowUnblockedToast(true);
    }
    onUnblock(username);
  };

  return (
    <div id="restricted-profile" className="profile-layout">
      <ProfileHeader
        username={username}
        avatar={avatar}
        name={name}
        backgroundUrl={backgroundUrl}
        highlight_colour={highlight_colour}
        numFollowers={numFollowers}
        numStoriesPublished={numStoriesPublished}
        numLists={numLists}
        onUnmuteUser={onUnmuteUser}
        profileType={profileState}
        blockUser={blockCheckPoint}
        onUnblock={onClickUnblock}
        isMobile={isMobile}
        className="restricted-profile-header"
      />
      <div id="page-navigation" className="sub-navigation">
        <div className="container">
          <div className="navigation-content">
            <nav className="navigation">
              <ul className="nav-list">
                <li className="nav-item" data-section="about">
                  <button className="section btn-link">{trans("About")}</button>
                </li>
                <li className="nav-item" data-section="conversations">
                  <button className="section btn-link">
                    {trans("Conversations")}
                  </button>
                </li>
                {!isMobile && (
                  <li className="nav-item" data-section="following">
                    <button className="section btn-link">
                      {trans("Following")}
                    </button>
                  </li>
                )}
              </ul>
            </nav>
            {!isMobile && (
              <div className="actions">
                <div className="button-group inline-block relative">
                  <ProfileMoreOptionsButton
                    username={username}
                    isMuted={
                      (profileState === "mute" || isMuted) &&
                      profileState !== "block"
                    }
                    isAmbassador={isAmbassador}
                    isBlocked={profileState === "block"}
                    onCloseAccount={onClickCloseAccount}
                    onTimeout={onClickTimeout}
                    onUnmuteUser={onUnmuteUser}
                    unblockUser={onClickUnblock}
                    blockUser={blockCheckPoint}
                    isRestrictedProfile={true}
                    showModerationOptions={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {(recentlyBlocked || showBlockedToast) && <Toast />}
      {showUnblockedToast && <Toast />}
      <WPModal />
    </div>
  );
};

RestrictedProfile.propTypes = {
  profileType: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  backgroundUrl: PropTypes.string,
  avatar: PropTypes.string,
  highlight_colour: PropTypes.string,
  numStoriesPublished: PropTypes.number,
  numLists: PropTypes.number,
  numFollowers: PropTypes.number,
  name: PropTypes.string,
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  onUnmuteUser: PropTypes.func,
  onUnblock: PropTypes.func,
  onBlock: PropTypes.func,
  recentlyMuted: PropTypes.bool,
  recentlyBlocked: PropTypes.bool,
  isMobile: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isMuted: PropTypes.bool,
  isAmbassador: PropTypes.bool,
  safety: PropTypes.object
};

export default connect(
  null,
  { toggleModal, toggleToast }
)(RestrictedProfile);
