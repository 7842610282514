import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import StoryExpandedCarousel from "./../../components/views/home/components/StoryExpandedCarousel";
import StoryCarousel from "./../../components/views/home/components/StoryCarousel";
import { StoryItem } from "../../components/views/story-item/StoryItem";
import { isClient } from "../../components/helpers/isClient";
import rtlLanguage from "../../helpers/handlebars/rtl-language";
import { useTrans } from "../hooks";

let window = window || global;
/**
 * The story list.
 * Displays similar stories.
 */
const StoryList = ({ stories, userLang, page }) => {
  const [hasViewed, setHasViewed] = useState(false);
  const storyCarouselRef = useRef();
  const isMobile = isClient() && window.innerWidth < 575;
  const { trans } = useTrans();
  const isRTL = rtlLanguage(userLang);

  useEffect(
    () => {
      if (!hasViewed && isMobile) {
        // TODO: In the future, we want to move away from underscore dependency
        // Throttle scroll listener so it doesn't run too often.
        const scrollListener = _.throttle(() => {
          if (storyCarouselRef.current) {
            var rect = storyCarouselRef.current.getBoundingClientRect();
            var elemTop = rect.top;
            var elemBottom = rect.bottom;
            var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;

            if (isVisible) {
              setHasViewed(true);
            }
          }
        }, 300);

        window.addEventListener("scroll", scrollListener);
        return () => {
          window.removeEventListener("scroll", scrollListener);
        };
      }
    },
    [hasViewed, setHasViewed, storyCarouselRef, isMobile]
  );
  return (
    <div className="story-list">
      {/* DW Story List. Hidden on MW. */}
      <div className="story-list__container">
        <div className="story-list__DW_title">{trans("You may also like")}</div>
        {stories &&
          stories.map((storyData, i) => {
            return (
              <div key={i} className="story-list__item">
                <StoryItem
                  id={storyData.id}
                  cover={storyData.cover}
                  title={storyData.title}
                  user={storyData.user}
                  description={storyData.description}
                  url={storyData.url}
                  descCharLimit={100}
                  isCompleted={storyData.completed}
                  isMature={storyData.mature}
                  numParts={storyData.numParts}
                  // TODO: remove this once new Story Details is rolled out
                  // Used for replacing views, votes, comment count with completed badge and parts
                  // Also for changing how isPaywalled looks
                  isFeatureFlagged={true}
                  isMobile={isMobile}
                />
              </div>
            );
          })}
      </div>
      {/* MW StoryExpanded. Hidden on DW. */}
      <div ref={storyCarouselRef} className="story-list__MW_title">
        {trans("You may also like")}
      </div>
      {/* Display StoryCarousel on RTL as StoryExpandedCarousel is not currently supported for that. */}
      {isRTL ? (
        <StoryCarousel
          stories={stories}
          sectionType={"similar_stories"}
          windowWidth={window.innerWidth}
          wasSeen={hasViewed && isMobile}
          page={page}
        />
      ) : (
        <StoryExpandedCarousel
          stories={stories}
          sectionType={"similar_stories"}
          windowWidth={window.innerWidth}
          userLang={userLang}
          page={page}
          wasSeen={hasViewed && isMobile}
        />
      )}
    </div>
  );
};
StoryList.propTypes = {
  stories: PropTypes.array,
  userLang: PropTypes.number,
  page: PropTypes.string
};
export default StoryList;
