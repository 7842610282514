import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose
} from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./rootReducer";

export default function createStore(initialState) {
  return reduxCreateStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
}

var window = window || global;
