import React, { useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { useTrans } from "../../hooks";
import { ConnectImage, Icon, Modal, MODAL_ID } from "../../shared-components";
import { Button, ButtonVariant, Typography } from "@wattpad/web-ui-library";

const PremiumPicksFAQModal = ({ showModal, onClose }) => {
  const modalRef = useRef();
  const { trans } = useTrans();

  const handleClose = () => {
    onClose();
    modalRef.current.hide();
  };

  const checkIcon = (
    <div className="check-icon-container">
      <Icon
        name="check"
        size="24"
        viewBox="24"
        color="ds-neutral-100"
        className="check-icon"
        fill={true}
        strokeWidth="0"
      />
    </div>
  );

  return (
    <Modal
      id={MODAL_ID.PREMIUM_PICKS_FAQ_MODAL_ID}
      {...{ showModal, onClose }}
      ref={modalRef}
    >
      <div className="premium-picks-faq-content">
        <div className="premium-picks-faq-header">
          <h2 className={classnames("modal-title", Typography.HEADING.XSMALL)}>
            {trans("Premium Picks")}
          </h2>
          {
            <ConnectImage
              className="premium-picks-image"
              name={`library/premium-picks-fresh-books.png`}
              title={trans("Stack of freshly cooked books")}
            />
          }
        </div>

        <ul
          className={classnames(
            "premium-picks-faq-features",
            Typography.PARAGRAPH.MEDIUM
          )}
        >
          <li>
            {checkIcon}

            {trans(
              "Wattpad Premium and Premium+ subscribers get a free monthly bundle of 5+ Wattpad Originals."
            )}
          </li>
          <li>
            {checkIcon}
            {trans(
              "Premium Picks refresh on the first day of each month. Read for free all month without paywalls."
            )}
          </li>
          <li>
            {checkIcon}
            {trans(
              "Explore new genres, writers, and quality reads every month."
            )}
          </li>
        </ul>
      </div>
      <div className="premium-picks-faq-footer">
        <Button onClick={handleClose} label={trans("Got it")} />

        <Button
          href="https://support.wattpad.com/hc/en-us/articles/18722782071956-Premium-Picks-"
          variant={ButtonVariant.TERTIARY}
          label={trans("Premium Picks FAQ")}
        />
      </div>
    </Modal>
  );
};

PremiumPicksFAQModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default PremiumPicksFAQModal;
