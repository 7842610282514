export const TOGGLE_TOAST_VISIBLE = "TOGGLE_TOAST_VISIBLE";

const initialState = {
  toastProps: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOAST_VISIBLE:
      return {
        ...state,
        toastProps: state.toastProps ? null : action.toastProps
      };

    default:
      return state;
  }
}

//Action Creators
export function toggleToast(toastProps) {
  return {
    type: TOGGLE_TOAST_VISIBLE,
    toastProps
  };
}
