/**
 * 2022 Password Policy Rules
 * @returns {rules: Array} - Returns the currently in-effect password strength policies
 */

const clientAPICall = url => {
  return Promise.resolve(
    $.ajax({
      type: "GET",
      url: url
    })
  );
};

module.exports = function() {
  return new Promise(async function(resolve, reject) {
    const url = "https://www.wattpad.com/v5/password-strength/policies";

    await clientAPICall(url)
      .then(pwPolicy => {
        return resolve(pwPolicy);
      })
      .catch(err => {
        return reject(err);
      });
  });
};
