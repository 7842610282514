import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "./";
import classnames from "classnames";

function Checkbox({ onChange, defaultValue, ...rest }) {
  const [checked, setChecked] = useState(defaultValue || false);

  const onChangeHandler = e => {
    e.stopPropagation();
    e.preventDefault();

    onChange(!checked);
    setChecked(!checked);
  };

  return (
    <button
      className={classnames("react-checkbox btn-no-style", { checked })}
      onClick={onChangeHandler}
      {...rest}
    >
      {checked && <Icon name="check" color="wp-neutral-5" size={16} />}
    </button>
  );
}

Checkbox.defaultProps = {
  onChange: () => {},
  defaultValue: false
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool
};

export { Checkbox };
