import PropTypes from "prop-types";
import { Button, ButtonSize } from "@wattpad/web-ui-library";
import React from "react";

import { useTrans } from "../../../hooks";
import classNames from "classnames";

function OnboardingTooltip(props) {
  const { trans } = useTrans();
  return (
    <div className={classNames(props.className, "onboarding-tooltip")}>
      <div className="tooltip-overlay" />
      <div className="tooltip-content">
        <h6 className="tooltip-title">{props.title}</h6>
        <p className="tooltip-text">{props.text}</p>
        <Button
          label={trans("Got it")}
          fullWidth={false}
          size={ButtonSize.SMALL}
          className="confirm-button"
        />
        <div className="arrow" />
      </div>
    </div>
  );
}

OnboardingTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default OnboardingTooltip;
