// Action Types
const name = "CATALOG";
const LOAD_FEATURED_LIST = `${name}/LOAD_FEATURED_LIST`;
const LOAD_PAID_STORIES_TAGS = `${name}/LOAD_PAID_STORIES_TAGS`;
const LOAD_PAID_STORIES_LIST = `${name}/LOAD_PAID_STORIES_LIST`;
const IS_LOADING_PAID_STORIES = `${name}/IS_LOADING_PAID_STORIES`;

// Reducer
const initialState = {
  featuredList: [],
  paidStoriesTags: [],
  paidStoriesListByTag: {},
  paidStoriesListOffset: 0,
  isLoadingPaidStories: false,
  hasTagsLoaded: false
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_FEATURED_LIST:
      return {
        ...state,
        featuredList: action.featuredList
      };
    case LOAD_PAID_STORIES_TAGS:
      return {
        ...state,
        paidStoriesTags: action.tags,
        hasTagsLoaded: true
      };
    case LOAD_PAID_STORIES_LIST:
      return {
        ...state,
        paidStoriesListByTag:
          action.paidStoriesListByTag || state.paidStoriesListOffset,
        isLoadingPaidStories: false,
        paidStoriesListOffset:
          action.paidStoriesListOffset || state.paidStoriesListOffset
      };
    case IS_LOADING_PAID_STORIES:
      return {
        ...state,
        isLoadingPaidStories: true
      };
    default:
      return state;
  }
}

// Action Creators
export function loadFeaturedList() {
  const storyLanguageId = parseInt(wattpad?.user?.language, 10) || 1;
  const storyLanguage = wattpad.supportedLangs.find(
    lang => lang.id === storyLanguageId
  );
  let languageCode;
  if (storyLanguage) {
    languageCode = storyLanguage.code;
  }

  let curationProfile = null;
  JSON.parse(wattpad.newCurationProfiles).forEach(pair => {
    if (pair.code === languageCode) {
      curationProfile = pair.username;
    }
  });

  const featuredListUrl = `/api/v3/users/${curationProfile}/lists`;

  return async function(dispatch) {
    try {
      const data = await Promise.resolve($.get(featuredListUrl));
      dispatch({
        type: LOAD_FEATURED_LIST,
        featuredList: data.lists
      });
    } catch (err) {
      dispatch({ type: LOAD_FEATURED_LIST, featuredList: [] });
    }
  };
}

export function loadPaidStoriesList() {
  const tagsRecommendationUrl = `/v4/paid_stories/tags`;
  const paidStoriesByTagUrl = `/v4/paid_stories/tags/:tag?limit=-1&fields=stories(id,cover,title,tags,isPaywalled),tag`;

  const nLoadTags = 4;
  return async function(dispatch, getState) {
    const catalogState = getState().paidStoriesCatalog;
    const currentOffset = catalogState.paidStoriesListOffset;
    const isLoadingPaidStories = catalogState.isLoadingPaidStories;
    const paidStoriesListByTag = catalogState.paidStoriesListByTag;
    const hasTagsLoaded = catalogState.hasTagsLoaded;

    let tags = catalogState.paidStoriesTags;
    const hasLodedAllTags = hasTagsLoaded && currentOffset === tags.length;
    if (isLoadingPaidStories || hasLodedAllTags) {
      return;
    }
    dispatch({
      type: IS_LOADING_PAID_STORIES
    });

    // Load all tag recommendations if not loaded before.
    if (!hasTagsLoaded) {
      try {
        const data = await Promise.resolve($.get(tagsRecommendationUrl));
        tags = data.tags;
        dispatch({
          type: LOAD_PAID_STORIES_TAGS,
          tags
        });
      } catch (err) {
        dispatch({
          type: LOAD_PAID_STORIES_TAGS,
          tags: []
        });
      }
    }

    // Load paid stories by tag
    const requests = [];
    const limit = Math.min(currentOffset + nLoadTags, tags.length);

    if (tags && tags.length > 0) {
      for (let i = currentOffset; i < limit; i++) {
        if (tags[i]) {
          requests.push(
            Promise.resolve($.get(paidStoriesByTagUrl.replace(":tag", tags[i])))
          );
        }
      }
    }

    try {
      const data = await Promise.all(requests);
      data.forEach(list => {
        paidStoriesListByTag[list.tag] = list.stories;
      });
      dispatch({
        type: LOAD_PAID_STORIES_LIST,
        paidStoriesListByTag: { ...paidStoriesListByTag },
        paidStoriesListOffset: currentOffset + nLoadTags
      });
    } catch (err) {
      dispatch({
        type: LOAD_PAID_STORIES_LIST
      });
    }
  };
}
