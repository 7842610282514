import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { resizeCover } from "../../../helpers";

/**
 * A small story item, usable in a carousel or other module display.
 * Displays the title, cover, and ranking of a story within its category.
 */

const RankedStorySlide = ({
  title,
  id,
  cover,
  sources,
  offset,
  shiftCarousel,
  sectionType,
  index,
  width = 200,
  page,
  clickTrackingUrls,
  callTrackingUrls,
  isActiveStory = false
}) => {
  const rank = offset + 1;

  const onClickStory = (evt, link) => {
    evt.preventDefault();
    window.te.push("event", "story", null, null, "click", {
      storyid: id,
      page: page,
      algo_source: sources,
      position: index,
      offset: offset,
      type: sectionType
    });
    // ADS-493: Click & Impression Events
    if (clickTrackingUrls) {
      callTrackingUrls(clickTrackingUrls);
    }
    if (shiftCarousel && !isActiveStory) {
      shiftCarousel();
    } else {
      app.router.navigate(link, { trigger: true });
    }
  };

  return (
    <div className={classnames("story-item", `story-${offset}`)}>
      <div className="story-item-wrapper">
        <div className="cover">
          <a
            href={"/story/" + id}
            onClick={evt => onClickStory(evt, `/story/${id}`)}
          >
            <img src={resizeCover(cover, width)} alt={`${title} cover`} />
          </a>
        </div>
        <a
          href={"/story/" + id}
          onClick={evt => onClickStory(evt, `/story/${id}`)}
          className="footer"
        >
          <div className="rank">
            <span
              className={classnames("number", { "double-digit": rank > 9 })}
            >
              {rank}
            </span>
          </div>
          <div className="title">{title}</div>
        </a>
      </div>
    </div>
  );
};

RankedStorySlide.defaultProps = {
  onStoryClick: null,
  sources: []
};

RankedStorySlide.propTypes = {
  cover: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number,
  offset: PropTypes.number,
  sectionType: PropTypes.string,
  sources: PropTypes.array,
  shiftCarousel: PropTypes.func,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  page: PropTypes.string,
  clickTrackingUrls: PropTypes.array,
  callTrackingUrls: PropTypes.func,
  isActiveStory: PropTypes.bool
};

export default RankedStorySlide;
