import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../shared-components";
import classNames from "classnames";

import { closePopover } from "../../../shared-components/popover/reducers";

import {
  useClickOutside,
  useTrans,
  useTrapKeyboardNavigation
} from "../../../hooks";

import {
  getReadingLists,
  getCollectionsForStoryIds,
  updateCollection,
  removeStoriesFromCollection
} from "../actions";

import { useDispatch } from "react-redux";

const LibraryPopover = ({
  readingLists,
  storyId,
  storiesCollections,
  className,
  fromStoryDetails,
  setShowPopover
}) => {
  const { trans } = useTrans();
  const dispatch = useDispatch();
  const [collections, setCollections] = useState(storiesCollections || {});
  const [lists, setReadingLists] = useState(readingLists || []);
  const [listName, setListName] = useState("");
  const [libraryError, setLibraryError] = useState(null);

  const listRef = useRef({});
  const listEndRef = useRef(null);
  const modalRef = useRef(null);
  const beforeModalRef = useRef(null);

  const getPage = () => {
    if (fromStoryDetails) {
      return "story_details";
    }
    const page = document.baseURI || document.URL; // ie11 does not support document.baseURI
    if (page.indexOf("premiumpicks") > -1) return "premium_picks";

    return "home";
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      // Store past checkpoint before modal opened.
      // activeElement is the DOM element that called the modal.
      // We hold onto this to return keyboard focus to it's originating location when the user is finished interacting with the modal.
      beforeModalRef.current = document.activeElement;
    }
  }, []);

  useClickOutside(modalRef.current, () => {
    setShowPopover && setShowPopover(false);
  });

  const handleClosePopover = () => {
    if (setShowPopover) {
      setShowPopover(false);
    } else {
      dispatch(closePopover());
    }
    beforeModalRef.current?.focus();
  };

  useTrapKeyboardNavigation(
    modalRef.current,
    beforeModalRef.current,
    handleClosePopover
  );

  const sendLibraryAddEvent = storyId => {
    window.te.push("event", "app", "story", "library", "add", {
      storyid: storyId,
      page: getPage()
    });
  };

  const sendLibraryRemoveEvent = storyId => {
    window.te.push("event", "app", "story", "library", "remove", {
      storyid: storyId,
      page: getPage()
    });
  };

  const addStoryToReadingList = readingListId => {
    sendLibraryAddEvent(storyId);
    dispatch(updateCollection(readingListId, storyId));
    setCollections({
      ...collections,
      [readingListId]: true
    });
  };

  const handleListItemClick = readingListId => {
    setLibraryError(null);
    if (collections[readingListId]) {
      sendLibraryRemoveEvent(storyId);
      dispatch(removeStoriesFromCollection(readingListId, storyId));
      // workaround until we can figure out how to refresh Popover.js
      setCollections({
        ...collections,
        [readingListId]: false
      });
    } else {
      addStoryToReadingList(readingListId);
    }
  };

  const handleAddNewListClick = () => {
    setLibraryError(null);

    if (!listName) {
      return;
    }

    const newListName = listName;
    setListName("");

    // Intent was to add story. Add story to list if it already exists.
    const existingReadingList = lists.find(
      list => list.name.toLowerCase() === newListName.toLowerCase()
    );

    // Story already exists in existing list. Scroll to reading list li and exit flow.
    if (existingReadingList && collections[existingReadingList.id]) {
      listRef.current[existingReadingList.id].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
      return;
    }

    if (existingReadingList) {
      // Add story to existing list
      addStoryToReadingList(existingReadingList.id);
      listRef.current[existingReadingList.id].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    } else {
      // Create reading list and add story
      const url = "/api/v3/lists/";
      const payload = {
        id: null,
        name: newListName,
        numStories: 0,
        cover: 0,
        tags: [],
        featured: false,
        stories: storyId
      };
      sendLibraryAddEvent(storyId);

      Promise.resolve($.post(url, payload))
        .then(response => {
          setReadingLists([...lists, { id: response.id, name: response.name }]);
          setCollections({
            ...collections,
            [response.id]: true
          });

          // refresh out of date redux states for when user leaves LibraryPopover and returns.
          dispatch(getReadingLists());
          dispatch(getCollectionsForStoryIds(storyId));
          listEndRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        })
        .catch(error => {
          const message = error.responseText
            ? error.responseText
            : error.statusText;
          console.log(message || error); // eslint-disable-line no-console

          const libraryError = new Error(
            trans(
              "We are unable to add this story to your reading list at this time"
            )
          );
          setLibraryError(libraryError);
        });
    }
  };

  return (
    <div
      aria-modal="true"
      ref={modalRef}
      className={classNames("react-popover-container--small", className)}
    >
      <div className="react-popover-header">
        {/* Label to indicate the modal is opened  */}
        <span className="sr-only">{trans("Add to modal open")}</span>
        <div aria-hidden="true" className="react-popover-title">
          {trans("Add to")}
        </div>
        <button onClick={handleClosePopover} className="react-popover-done-btn">
          {trans("Done")}
        </button>
      </div>
      <ul className="react-popover-list--small">
        <li key={"library"} className="react-popover-item">
          <button
            onClick={() => {
              handleListItemClick("library");
            }}
            tabIndex="0"
            className="react-popover-item__btn"
            ref={el => (listRef.current["library"] = el)}
          >
            <Icon iconName="fa-library" height="18" color="wp-neutral-1" />
            <span className="item-name">{trans("My Library (Private)")}</span>
            {collections.library && (
              <Icon
                name="check"
                size="24"
                color="wp-base-1-light"
                fill={true}
                strokeWidth="1"
              />
            )}
          </button>
        </li>
        {lists &&
          lists.map(list => {
            return (
              <li key={list.id} className={"react-popover-item"}>
                <button
                  onClick={() => {
                    handleListItemClick(list.id);
                  }}
                  tabIndex="0"
                  className="react-popover-item__btn"
                  ref={el => (listRef.current[list.id] = el)}
                >
                  <Icon
                    iconName="fa-reading-list"
                    height="18"
                    color="wp-neutral-1"
                  />
                  <span className="item-name">{list.name}</span>
                  {collections[list.id] && (
                    <Icon
                      name="check"
                      size="24"
                      color="wp-base-1-light"
                      fill={true}
                      strokeWidth="1"
                    />
                  )}
                </button>
              </li>
            );
          })}
        <div ref={listEndRef} />
        {/* static anchor for scroll to bottom of list */}
      </ul>
      {/* alt text for button and text input? */}
      <form>
        <div className="react-popover-input-row">
          <input
            type="text"
            className="form-control input-text"
            placeholder={trans("Add new reading list...")}
            onChange={event => setListName(event.target.value)}
            value={listName}
          />
          <button
            aria-label={trans("Add new reading list...")}
            className="btn-icon"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              handleAddNewListClick();
            }}
          >
            <Icon
              name="add"
              height="12"
              color="wp-neutral-1"
              strokeWidth="2.5"
            />
          </button>
        </div>
      </form>
      {libraryError && (
        <div className="react-popover-error">{libraryError.message}</div>
      )}
    </div>
  );
};

LibraryPopover.propTypes = {
  readingLists: PropTypes.array.isRequired,
  storyId: PropTypes.number,
  storiesCollections: PropTypes.object.isRequired,
  className: PropTypes.string,
  fromStoryDetails: PropTypes.bool,
  setShowPopover: PropTypes.func
};

export default LibraryPopover;
