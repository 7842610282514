import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { isClient, injectTrans } from "../../helpers";
import AccountSettings from "./AccountSettings";
import MuteList from "./MuteList";
import BlockList from "./BlockList";
import NotificationSettings from "./NotificationSettings";
import { initializeMutedUsers, setBlockedUsers } from "../profile/reducers";
import WPModal from "../../shared-components/modals/components/WPModal";
import { Toast } from "../../shared-components/toasts/Toast";
import ContentSettings from "../home/components/ContentSettings";

class UserSettingsUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "account"
    };
  }

  componentDidMount() {
    this.props.renderTooltips();
    this.props.initializeMutedUsers(this.props.userObj.id);
    this.props.setBlockedUsers(this.props.userObj.username);
  }

  onTabSelect(content, e) {
    if (e.key !== "Tab" && !e.shiftKey) {
      this.setState({
        selectedTab: content
      });
    }
  }

  onSubmitChanges = (event, userData) => {
    this.props.onSaveAccountChanges(event, userData);
  };

  // In order to pass down the updated user attributes when submitting the account settings form,
  // a re-render is performed on the backbone dummy react view. Therefore the component needs to be
  // unmounted as it will be re-mounted after the attributes are updated.
  onSaveAvatarChanges = file => {
    this.props.saveAvatarChanges(file);
    ReactDOM.unmountComponentAtNode(
      document.getElementById("component-UserSettings-user-settings-/settings")
    );
  };

  onSaveBackgroundChanges = file => {
    this.props.saveBackgroundChanges(file);
    ReactDOM.unmountComponentAtNode(
      document.getElementById("component-UserSettings-user-settings-/settings")
    );
  };

  render() {
    const { userObj, app, trans, testGroups } = this.props;
    const { selectedTab } = this.state;

    const accountTab = trans("Account"),
      notificationsTab = trans("Notifications"),
      contentPreferencesTab = trans("Content Preferences"),
      mutedListTab = trans("Muted Accounts"),
      blockedListTab = trans("Blocked Accounts");

    const showAccountSettingsTab = classNames({
      hide: selectedTab !== "account"
    });

    const showNotificationsTab = classNames({
      hide: selectedTab !== "notifications"
    });

    const showContentPreferencesTab = classNames({
      hide: selectedTab !== "content"
    });

    const showMutedUsersTab = classNames({
      hide: selectedTab !== "mute"
    });
    const showBlockedUsersTab = classNames({
      hide: selectedTab !== "block"
    });

    const accountTabClasses = classNames("info-tabs", {
      selected: selectedTab === "account"
    });

    const notifTabClasses = classNames("info-tabs", {
      selected: selectedTab === "notifications"
    });

    const contentPreferencesTabClasses = classNames("info-tabs", {
      selected: selectedTab === "content"
    });

    const muteTabClasses = classNames("info-tabs", {
      selected: selectedTab === "mute"
    });
    const blockTabClasses = classNames("info-tabs", {
      selected: selectedTab === "block"
    });

    return (
      <div id="content_block" className="user-settings">
        <WPModal />
        <Toast />
        <div className="container">
          <ol role="tablist">
            <li
              id="select_account"
              className={accountTabClasses}
              onClick={this.onTabSelect.bind(this, "account")}
              onKeyDown={this.onTabSelect.bind(this, "account")}
              role="tab"
              tabIndex={0}
            >
              <span>{accountTab}</span>
            </li>
            <li
              id="select_notifications"
              className={notifTabClasses}
              onClick={this.onTabSelect.bind(this, "notifications")}
              onKeyDown={this.onTabSelect.bind(this, "notifications")}
              role="tab"
              tabIndex={0}
            >
              <span>{notificationsTab}</span>
            </li>
            {testGroups.HOME_IMPROVEMENTS && (
              <li
                id="select_content"
                className={contentPreferencesTabClasses}
                onClick={this.onTabSelect.bind(this, "content")}
                onKeyDown={this.onTabSelect.bind(this, "content")}
                role="tab"
                tabIndex={0}
              >
                <span>{contentPreferencesTab}</span>
              </li>
            )}
            <li
              id="select_ignore_list"
              className={muteTabClasses}
              onClick={this.onTabSelect.bind(this, "mute")}
              onKeyDown={this.onTabSelect.bind(this, "mute")}
              role="tab"
              tabIndex={0}
            >
              <span>{mutedListTab}</span>
            </li>
            <li
              id="select_block_list"
              className={blockTabClasses}
              onClick={this.onTabSelect.bind(this, "block")}
              onKeyDown={this.onTabSelect.bind(this, "block")}
              role="tab"
              tabIndex={0}
            >
              <span>{blockedListTab}</span>
            </li>
          </ol>
          <div className={showAccountSettingsTab}>
            <AccountSettings
              {...userObj}
              app={app}
              onSubmit={this.onSubmitChanges}
              openEditModal={this.props.openUserSettingsModal}
            />
          </div>
          <div className={showNotificationsTab}>
            <NotificationSettings
              {...userObj.notificationSettings}
              userId={userObj.id}
              updateNotifications={this.props.updateNotifications}
            />
          </div>
          {testGroups.HOME_IMPROVEMENTS && (
            <div className={showContentPreferencesTab}>
              <ContentSettings isSettingsPage />
            </div>
          )}
          <div className={showMutedUsersTab}>
            <MuteList />
          </div>
          <div className={showBlockedUsersTab}>
            <BlockList currentUser={userObj.username} />
          </div>
        </div>
        <div className="mute-accounts-options-modal" />
      </div>
    );
  }
}

UserSettingsUI.propTypes = {
  userObj: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  trans: PropTypes.func.isRequired,
  mutedUsers: PropTypes.array.isRequired,
  onSaveAccountChanges: PropTypes.func,
  openUserSettingsModal: PropTypes.func,
  saveAvatarChanges: PropTypes.func,
  saveBackgroundChanges: PropTypes.func,
  renderTooltips: PropTypes.func,
  updateNotifications: PropTypes.func,
  initializeMutedUsers: PropTypes.func,
  setBlockedUsers: PropTypes.func,
  testGroups: PropTypes.object
};

if (isClient()) {
  _.extend(UserSettingsUI.propTypes, {
    saveAvatarChanges: PropTypes.func.isRequired,
    saveBackgroundChanges: PropTypes.func.isRequired,
    onSaveAccountChanges: PropTypes.func.isRequired,
    openUserSettingsModal: PropTypes.func.isRequired,
    renderTooltips: PropTypes.func.isRequired,
    updateNotifications: PropTypes.func.isRequired
  });
}
const UserSettings = connect(
  null,
  { initializeMutedUsers, setBlockedUsers }
)(UserSettingsUI);

export default injectTrans(UserSettings);
