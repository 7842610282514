import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import ContestFormField from "../ContestFormField";
import {
  requiredField,
  maxCharLengthField,
  minCharLengthField
} from "../helpers";
import {
  PLOT_SUMMARY_CHARACTER_MAX,
  PLOT_SUMMARY_CHARACTER_MIN
} from "../constants";
import ErrorField from "./ErrorField";

const PlotSummaryField = ({
  form,
  disableForm,
  onFormChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Plot Summary");

  const subtitle = (
    <div className="subtitle">
      {/* prettier-ignore */ trans("Your summary should be around 500 words, introduce the main character(s) and tell us everything that happens in the story.")}
      <br />
      <br />
      <strong>{trans("Who, what, where?")}</strong>
      <br />
      {/* prettier-ignore */ trans("Introduce us to your hero, the conflict, and the world. Tell us about your villain and the most important secondary characters.")}
      <br />
      <br />
      <strong>{trans("What happens next?")}</strong>
      <br />
      {/* prettier-ignore */ trans("Tell us all of the events of the story - this can be in point-form and does not have to describe the actions of each individual chapter.")}
      <br />
      <br />
      <strong>{trans("How does it end?")}</strong>
      <br />
      {/* prettier-ignore */ trans("Tell us how the story resolves and what happens to each of the central characters.")}
      <br />
      {/* prettier-ignore */ trans("The entire summary should consist of the actions and decisions a character makes to get closer to their goal.")}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "plotSummary" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.plotSummary === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation
        validationRules={[
          requiredField(trans),
          minCharLengthField(PLOT_SUMMARY_CHARACTER_MIN, trans),
          maxCharLengthField(PLOT_SUMMARY_CHARACTER_MAX, trans)
        ]}
      >
        {({ ref, errorMessage, className }) => (
          <React.Fragment>
            <textarea
              ref={ref}
              id="summary-textarea"
              className={classNames("form-element", className)}
              name="plotSummary"
              value={form.plotSummary}
              disabled={disableForm}
              onChange={onFormChange}
              placeholder={trans("Enter your plot summary")}
            />
            <ErrorField
              errorCondition={errorCondition}
              errorMessage={errorMessage}
              fieldId={"plot-summary"}
            />
          </React.Fragment>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

PlotSummaryField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default PlotSummaryField;
