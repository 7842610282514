import { connect } from "react-redux";
import { Popper } from "react-popper";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import React, { useState } from "react";

import { useClickOutside, useTrapKeyboardNavigation } from "../../hooks";
import { closePopover } from "./reducers";

function PopoverUI({ popoverProps, closePopover }) {
  const {
    triggerRef,
    component,
    passthroughProps = {},
    contentClass
  } = popoverProps;
  const [popoverRef, setPopoverRef] = useState();

  useTrapKeyboardNavigation(popoverRef, triggerRef?.current, closePopover);

  useClickOutside(popoverRef, targetNode => {
    if (!triggerRef) {
      return;
    }
    if (
      targetNode !== triggerRef.current &&
      !triggerRef.current.contains(targetNode)
    ) {
      closePopover();
    }
  });

  if (!triggerRef || !component) {
    return null;
  }

  return ReactDOM.createPortal(
    <Popper
      innerRef={ref => setPopoverRef(ref)}
      referenceElement={triggerRef.current}
      strategy="fixed"
      {...passthroughProps}
    >
      {({ placement, ref, style, arrowProps }) => (
        <div
          className={classNames("react-popover-content", contentClass)}
          ref={ref}
          style={style}
          data-placement={placement}
        >
          {component &&
            component({
              closePopover
            })}
          {passthroughProps.showArrow && (
            <div
              className="react-popover-arrow"
              ref={arrowProps.ref}
              style={arrowProps.style}
            />
          )}
        </div>
      )}
    </Popper>,
    document.querySelector("#react-popover")
  );
}

PopoverUI.propTypes = {
  popoverProps: PropTypes.shape({
    triggerRef: PropTypes.object,
    component: PropTypes.func,
    passthroughProps: PropTypes.object
  }).isRequired
};

const Popover = connect(
  state => ({ popoverProps: { ...state.popover.popoverProps } }),
  { closePopover }
)(PopoverUI);

export { Popover, PopoverUI };
