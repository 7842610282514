import React, { Component } from "react";
import PropTypes from "prop-types";

import { ConnectImage } from "../../shared-components";
import { injectTrans } from "../../helpers";

class TakeWPWithYou extends Component {
  render() {
    let { trans } = this.props;
    return (
      <div id="TakeWPWithYou">
        <div className="container content">
          <h3>{trans("Take Wattpad With You")}</h3>
          <p>{trans("Read and write anywhere, even offline.")}</p>
          <div className="buttons">
            <button
              className="btn btn-md btn-orange"
              onClick={() => {
                this.props.toggleModal({
                  type: "signup",
                  source: "reader_bot"
                });
              }}
            >
              {trans("Start Reading")}
            </button>
            <button
              className="btn btn-md btn-orange"
              onClick={() => {
                this.props.toggleModal({
                  type: "login"
                });
              }}
            >
              {trans("Start Writing")}
            </button>
          </div>
          <ConnectImage
            className="devices"
            name="landing/footer-devices.png"
            title="devices"
          />
        </div>
      </div>
    );
  }

  static propTypes = {
    toggleModal: PropTypes.func,
    trans: PropTypes.func.isRequired
  };
}

export default injectTrans(TakeWPWithYou);
