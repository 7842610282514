export const FETCH_HOME_MODULES = "FETCH_HOME_MODULES";
export const RECEIVE_SECTION_POSITION = "RECEIVE_SECTION_POSITION";
export const SECTION_VIEWED = "SECTION_VIEWED";
export const ERROR = "ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CALL_TRACKING_URLS = "CALL_TRACKING_URLS";
export const RECEIVE_CONTENT_SETTINGS = "RECEIVE_CONTENT_SETTINGS";
export const NO_CONTENT_SETTINGS_CHANGES = "NO_CONTENT_SETTING_CHANGES";
export const LOADING_CONTENT_SETTINGS = "LOADING_CONTENT_SETTINGS";
export const LOADING_CONTENT_SETTINGS_DONE = "LOADING_CONTENT_SETTINGS_DONE";
export const LOADING_HOME = "LOADING_HOME";
export const LOADING_HOME_DONE = "LOADING_HOME_DONE";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const RECEIVE_READING_LISTS = "RECEIVE_READING_LISTS";
export const LOADING_READING_LISTS = "LOADING_READING_LISTS";
export const RECEIVE_STORIES_COLLECTIONS = "RECEIVE_STORIES_COLLECTIONS";
export const LOADING_STORIES_COLLECTIONS = "LOADING_STORIES_COLLECTIONS";
export const ADD_STORY_TO_COLLECTION = "ADD_STORY_TO_COLLECTION";
export const REMOVE_STORY_FROM_COLLECTION = "REMOVE_STORY_FROM_COLLECTION";
export const errorTypes = {
  fetchFailed: "FETCH_FAILED"
};
