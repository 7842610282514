import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../../shared-components";
import {
  useTrans,
  useReportModal,
  useMuteUsers,
  useConfirmModal
} from "../../../hooks";
import UserSafetyLinks from "../../../shared-components/UserSafetyLinks";
import { ReportTypes } from "../../report/ReportModal";

const CommentOptions = ({
  canMute,
  canDeleteData,
  commentAuthor,
  commentBody = "",
  deeplink,
  onDeleteComment,
  canLink = true,
  reportType = ReportTypes.COMMENT
}) => {
  const { trans } = useTrans();
  const { openReportCommentModal } = useReportModal();
  const { openMuteUserModal } = useMuteUsers();
  const { openConfirmModal } = useConfirmModal();

  let deleteButtonRef = useRef();
  let muteButtonRef = useRef();
  let reportButtonRef = useRef();

  const handleReportCommentClick = () => {
    openReportCommentModal(
      reportType,
      commentAuthor,
      commentBody,
      window?.location.href,
      deeplink,
      reportButtonRef
    );
  };

  const handleDeleteClick = () => {
    const contentText = canDeleteData?.isCommentAuthor
      ? trans("Are you sure you want to delete your comment?")
      : trans(
          "Are you sure you want to delete this comment from @%s?",
          commentAuthor
        );

    openConfirmModal({
      contentText,
      title: trans("Delete Comment"),
      onConfirmClick: onDeleteComment,
      openTriggerRef: deleteButtonRef
    });
  };

  return (
    <div className="comment-options">
      {canMute && (
        <button
          ref={muteButtonRef}
          id="mute-user"
          className="option btn-no-background"
          onClick={() => openMuteUserModal(commentAuthor, muteButtonRef)}
        >
          <Icon name="mute" size="14" color="wp-neutral-1" />
          <span>{trans("Mute %s", commentAuthor)}</span>
        </button>
      )}
      <button
        ref={reportButtonRef}
        id="report-comment"
        className="option btn-no-background"
        onClick={handleReportCommentClick}
      >
        <Icon name="report" />
        <span>{trans("Report Comment")}</span>
      </button>
      {canDeleteData?.canDelete && (
        <button
          ref={deleteButtonRef}
          id="delete-comment"
          className="option btn-no-background"
          onClick={handleDeleteClick}
        >
          <Icon name="trash" size="14" color="wp-neutral-1" />
          <span>{trans("Delete Comment")}</span>
        </button>
      )}
      {canLink && (
        <>
          <a
            tabIndex="0"
            href={deeplink}
            id="deeplink-comment"
            className="option btn-no-background"
          >
            <Icon name="share" size="14" color="wp-neutral-1" />
            <span>{trans("Link to Comment")}</span>
          </a>
        </>
      )}
      <UserSafetyLinks />
    </div>
  );
};

CommentOptions.propTypes = {
  canMute: PropTypes.bool,
  canDeleteData: PropTypes.shape({
    canDelete: PropTypes.bool,
    isCommentAuthor: PropTypes.bool
  }),
  commentAuthor: PropTypes.string,
  commentBody: PropTypes.string,
  deeplink: PropTypes.string,
  onDeleteComment: PropTypes.func,
  canLink: PropTypes.bool,
  reportType: PropTypes.oneOf(Object.values(ReportTypes))
};

export default CommentOptions;
