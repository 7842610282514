import {
  FORM_POST,
  FORM_ERROR,
  FORM_UPDATE,
  FORM_FETCH,
  FORM_REQUEST
} from "./constants";

export const fetchPaidPartConfig = (storyId, partId) => {
  const getPaidPartConfigUrl = `/v5/bonus-content/paywall/${storyId}/free-parts?parts=${partId}`;

  return async function(dispatch) {
    if (!storyId || !partId) return dispatch({ type: FORM_FETCH, data: null });

    dispatch({ type: FORM_REQUEST });

    try {
      const request = Promise.resolve($.get(getPaidPartConfigUrl));
      const response = await request;

      if (!response) return dispatch({ type: FORM_FETCH, data: {} });

      dispatch({
        type: FORM_FETCH,
        data: response?.[0]
      });
    } catch (e) {
      return dispatch({ type: FORM_FETCH, data: null });
    }
  };
};

export const updatePaidPartConfig = (
  storyId,
  partId,
  authorId,
  formData,
  newState
) => {
  const data = {
    ...formData,
    story_id: parseInt(storyId),
    part_id: partId,
    author_id: authorId
  };

  if (newState) {
    const { name, value } = newState;
    if (newState.name) data[name] = value;
  }

  return function(dispatch) {
    dispatch({ type: FORM_UPDATE, data });
  };
};

export const postPaidPartConfig = (storyId, partId, authorId, formData) => {
  const postPaidPartConfigUrl = `/v5/bonus-content/paywall`;
  formData.story_id = parseInt(storyId);
  formData.part_id = parseInt(partId);
  formData.author_id = parseInt(authorId);

  return async function(dispatch) {
    try {
      await Promise.resolve(
        $.ajax({
          url: postPaidPartConfigUrl,
          type: "POST",
          data: JSON.stringify(formData),
          dataType: "json",
          headers: {
            "Content-Type": "application/json"
          }
        })
      );
      dispatch({ type: FORM_POST });
    } catch (err) {
      wattpad?.utils?.showToast();
      dispatch({ type: FORM_ERROR });
    }
  };
};
