import PropTypes from "prop-types";
import React, { useState } from "react";
import EmailSignup from "./EmailSignup";
import classNames from "classnames";
import { useTrans, useFeatureFlags } from "../../hooks";
import ThirdPartySignup from "./ThirdPartySignup";
import { Button, ButtonVariant, IconNames } from "@wattpad/web-ui-library";

const SignupForm = ({
  focus = true,
  formType,
  nextUrl = "/home",
  subtitle,
  title,
  toggleForm
}) => {
  const featureFlags = useFeatureFlags();
  const { trans } = useTrans();
  const [isEmailFormView, setIsEmailFormView] = useState(false);

  const toggleFormView = () => setIsEmailFormView(!isEmailFormView);
  const signupTitle = featureFlags.NEW_ONBOARDING_1
    ? trans("Sign up to join the largest storytelling community")
    : trans("Join Wattpad");

  return (
    //NOTE: This is a temporary div, we can add CSS to signup-form after rollout
    <div
      className={classNames({
        "auth-form-padding": featureFlags.NEW_ONBOARDING_1
      })}
    >
      <div id="authentication" className="signup-form">
        {featureFlags.NEW_ONBOARDING_1
          ? !isEmailFormView && (
              <h1
                className="title"
                dangerouslySetInnerHTML={{
                  __html: title ? title : signupTitle
                }}
              />
            )
          : formType !== "sidebar" && (
              <h1 className="title">{title ? title : signupTitle}</h1>
            )}
        {isEmailFormView &&
          featureFlags.NEW_ONBOARDING_1 && (
            <Button
              className="back-button"
              variant={ButtonVariant.TERTIARY}
              onClick={toggleFormView}
              leadingIcon={IconNames.WpChevronLeft}
              label={trans("Back to all signup options")}
              fullWidth
            />
          )}

        {!featureFlags.NEW_ONBOARDING_1 &&
          (subtitle ? (
            <h2
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: subtitle
              }}
            />
          ) : (
            <p className="subtitle">
              {trans(
                "Be part of a global community of readers and writers, all connected through the power of story."
              )}
            </p>
          ))}

        <div className="panel-body">
          {isEmailFormView ? (
            <EmailSignup
              formType={formType}
              nextUrl={nextUrl}
              toggleFormView={toggleFormView}
            />
          ) : (
            <ThirdPartySignup
              focus={focus}
              nextUrl={nextUrl}
              toggleForm={toggleForm}
              toggleFormView={toggleFormView}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SignupForm.propTypes = {
  focus: PropTypes.bool,
  formType: PropTypes.string,
  nextUrl: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  toggleForm: PropTypes.func.isRequired
};

export default SignupForm;
