import { linkify } from "./linkify";

const simpleShorten = (text, charLimit, shouldLinkify, options) => {
  let processed;

  options = options || {};

  if (!text) {
    return "";
  }

  processed = text.replace(/(<([^>]+)>)/gi, "");
  // WEB-6928: Remove empty lines within the text
  processed = processed.replace(/^\s*\n/gm, "");

  if (processed.length > charLimit) {
    processed =
      processed.substr(0, charLimit - 1).replace(/[.,\- /!&?]+$/, "") + "...";
  }
  return shouldLinkify === true ? linkify(processed, options) : processed;
};

export { simpleShorten };
