import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { muteUser, unmuteUser } from "../views/profile/reducers";
import { useConfirmModal, useTrans } from "../hooks";

export const useMuteUsers = onUserMuted => {
  const { trans } = useTrans();
  const dispatch = useDispatch();
  const { openConfirmModal } = useConfirmModal();
  const mutedUsers = useSelector(state => state.profileData.mutedUsers);

  const isInitialMount = useRef(true);

  const triggerUserMuted = () => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (onUserMuted && mutedUsers && mutedUsers.length > 0) {
        const username = mutedUsers[mutedUsers.length - 1].username;
        onUserMuted(username);
      }
    }
  };

  useEffect(triggerUserMuted, [mutedUsers]);

  const openMuteUserModal = (username, muteButtonRef, onUserMuted) => {
    openConfirmModal({
      contentText: trans(
        "This account will not be able to follow you, send you messages, post on your profile or comment on your stories. This action can take 30 minutes to take effect."
      ),
      title: trans("Mute %s", username),
      confirmLabel: trans("Mute"),
      declineLabel: trans("Cancel"),
      onConfirmClick: () => dispatch(muteUser(username, onUserMuted)),
      openTriggerRef: muteButtonRef
    });
  };
  const openUnmuteUserModal = (username, unmuteButtonRef, onUserUnmuted) => {
    openConfirmModal({
      contentText: trans(
        "This account will now be able to follow you, send you messages, post on your profile and comment on your stories. This action can take 30 minutes to take effect."
      ),
      title: trans("Unmute %s", username),
      confirmLabel: trans("Unmute"),
      declineLabel: trans("Cancel"),
      onConfirmClick: () => dispatch(unmuteUser(username, onUserUnmuted)),
      openTriggerRef: unmuteButtonRef
    });
  };

  return { openMuteUserModal, openUnmuteUserModal };
};
