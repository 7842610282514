import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";

const ErrorField = ({ errorMessage, fieldId, errorCondition }) => {
  const { trans } = useTrans();

  const shouldShowError = !!(errorMessage || errorCondition);
  const defaultErrorString = trans("This field is required.");
  const errorString = errorMessage || defaultErrorString;

  return (
    shouldShowError && (
      <div className="error-text" id={`${fieldId}-field-error`}>
        {errorString}
      </div>
    )
  );
};

ErrorField.propTypes = {
  errorMessage: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  errorCondition: PropTypes.bool
};

export default ErrorField;
