import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { ConnectImage, Icon } from "../../../shared-components";
import { UserBadge } from "../../user-badges/UserBadge";
import { ThreadControl } from "../ThreadControl";

const useMountEffect = func => useEffect(func, []);

function Thread({ thread, username, privateMessagePermissions }) {
  const senderUsername = thread.user.name;
  const { isActive } = thread.user;

  useMountEffect(() => {
    window.te.push("event", "private_messages", null, null, "view", {
      sender_username: senderUsername,
      recipient_username: username
    });
  });

  const badge = thread.user.badges.length ? thread.user.badges[0] : "";
  return (
    <div className={"thread-row"}>
      <div className={classNames("unread-dot", { show: thread.unread })}>
        {thread.unread === 1 && (
          <Icon iconName="fa-circle-filled" height="6" color="wp-base-1" />
        )}
      </div>

      <a
        style={{ marginRight: 0 }}
        className={"thread-column on-navigate"}
        href={`/user/${senderUsername}`}
      >
        {isActive ? (
          <img
            src={thread.user.avatar}
            alt={`${senderUsername}'s avatar`}
            className="thread-column avatar avatar-sm3"
          />
        ) : (
          <ConnectImage
            name="avatar/empty.png"
            className="thread-column avatar avatar-sm3"
          />
        )}
      </a>

      <a
        className={"thread-column on-navigate thread-meta"}
        href={`/inbox/${senderUsername}`}
      >
        <div className="thread-user right-icon">
          {senderUsername}
          <UserBadge name={badge} allowedBadges={["staff", "verified"]} />
        </div>
        <div
          className={classNames("thread-message", { unread: thread.unread })}
        >
          {thread.recentMessage.body}
        </div>
      </a>
      <div className="thread-column thread-date">
        {moment(thread.recentMessage.createDate).format("MMM DD, YYYY [at] LT")}
      </div>

      <div className="button-group inline-block relative ">
        <button
          className="btn btn-white dropdown-toggle inbox-more-options"
          data-toggle="dropdown"
          aria-controls="inbox-more-options"
          aria-expanded="false"
          aria-label="more options"
        >
          <Icon name="more" size="24" />
        </button>
        <div className="triangle" />
        <div className="dropdown-menu align-right">
          <ThreadControl
            activeThread={senderUsername}
            isLoading={false}
            isMutedByCurrentUser={thread.user.isMutedByCurrentUser}
            isBlockedByCurrentUser={thread.user.isBlockedByCurrentUser}
            privateMessagePermissions={privateMessagePermissions}
          />
        </div>
      </div>
    </div>
  );
}

Thread.propTypes = {
  privateMessagePermissions: PropTypes.object,
  thread: PropTypes.object,
  username: PropTypes.string
};

Thread.defaultProps = {
  thread: {}
};

export default Thread;
