import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import { requiredField } from "../helpers";
import { MAP_WATTYS_GENRES, MAP_WATTYS_GENRES_NON_ENGLISH } from "../constants";
import ContestFormField from "../ContestFormField";
import ErrorField from "./ErrorField";

const PrimaryGenreIdentificationField = ({
  form,
  disableForm,
  onFormChange,
  fieldStatus,
  submitted,
  genreLink,
  storyLanguage
}) => {
  const { trans } = useTrans();

  const title = trans("Primary genre identification");

  const subtitle = (
    <div className="subtitle">
      <div
        dangerouslySetInnerHTML={{
          __html: /* prettier-ignore */ trans('Select the <a href="%s" target="_blank" rel="noopener noreferrer">genre</a> you feel best describes your story. Genre declaration helps us learn more about your story and will not influence winner selection. If you believe your story contains multiple genres, please select your primary choice here and select subsequent genres in the following question.', genreLink)
        }}
      />
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "genrePrimary" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.genrePrimary === "";

  const isEnglish =
    storyLanguage === "English" && parseInt(app.get("language"), 10) === 1;
  const genres = isEnglish ? MAP_WATTYS_GENRES : MAP_WATTYS_GENRES_NON_ENGLISH;

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation validationRules={[requiredField(trans)]}>
        {({ ref, errorMessage, className }) => (
          <React.Fragment>
            <select
              ref={ref}
              id="primary-genre-identification-select"
              value={form.genrePrimary}
              name="genrePrimary"
              disabled={disableForm}
              className={classNames("form-element", className)}
              onChange={onFormChange}
              aria-label={trans("Select a category")}
            >
              <option value="" disabled>
                {trans("Select an option")}
              </option>
              {genres(trans).map(category => (
                <option key={category.key} value={category.key}>
                  {category.value}
                </option>
              ))}
            </select>
            <ErrorField
              errorMessage={errorMessage}
              fieldId={"primary-genre-identification"}
            />
            {!errorMessage && (
              <ErrorField
                fieldId={"primary-genre-identification"}
                errorCondition={errorCondition}
              />
            )}
          </React.Fragment>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

PrimaryGenreIdentificationField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired,
  genreLink: PropTypes.string.isRequired,
  storyLanguage: PropTypes.string
};

export default PrimaryGenreIdentificationField;
