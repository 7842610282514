import {
  CURRENCY_ID,
  SET_WALLET_BALANCE,
  WALLET_ANIMATION_STEP,
  WALLET_STATES,
  BUYING_CONTENT,
  BOUGHT_CONTENT
} from "./constants";

// Shape: {amount: number, animationStep: str}
function wallet(
  state = {
    amount: 0,
    animationStep: WALLET_STATES.DEFAULT,
    id: CURRENCY_ID,
    fetched: false
  },
  action
) {
  switch (action.type) {
    case SET_WALLET_BALANCE:
      return { ...state, amount: action.amount, fetched: true };
    case WALLET_ANIMATION_STEP:
      return { ...state, animationStep: action.step };
    case BOUGHT_CONTENT:
      return { ...state, animationStep: WALLET_STATES.DEFAULT };
    default:
      return state;
  }
}

// State of an in-progress paid content purchase
// Shape: {isPurchasing: bool, amountSpent: number, buyingPart: bool}
function purchase(
  state = {
    isPurchasing: false,
    amountSpent: 0,
    buyingPart: false
  },
  action
) {
  switch (action.type) {
    case BUYING_CONTENT:
      return {
        ...state,
        amountSpent: action.price,
        isPurchasing: true,
        buyingPart: !!action.partId
      };
    case BOUGHT_CONTENT:
      return { ...state, isPurchasing: false, amountSpent: 0 };
    default:
      return state;
  }
}

export { wallet, purchase };
