import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import classnames from "classnames";

import { resizeCover } from "../../../helpers";
import TagItem from "../../tags/TagItem";

/**
 * A small story item, usable in a carousel or other module display.
 * Displays the title, cover, and first tag or tags of a story, as well as a paid stories badge if paywalled.
 */

const StorySlide = ({
  title,
  id,
  cover,
  tags,
  sources,
  offset,
  shiftCarousel,
  sectionType,
  index,
  width = 200,
  page,
  onStoryClick,
  storyData,
  clickTrackingUrls,
  callTrackingUrls,
  isActiveStory = false
}) => {
  tags = tags ? tags : [];
  const [randomTag, setRandomTag] = useState(tags[0]);

  useEffect(
    () => {
      setRandomTag(
        _.shuffle(tags).find(
          tag => (width < 200 ? tag.length < 10 : tag.length < 18)
        )
      );
    },
    [tags, width]
  );

  const onClickStory = (evt, link) => {
    evt.preventDefault();
    window.te.push("event", "story", null, null, "click", {
      storyid: id,
      page: page,
      algo_source: sources,
      position: index,
      offset: offset,
      type: sectionType
    });
    // ADS-493: Click & Impression Events
    if (clickTrackingUrls) {
      callTrackingUrls(clickTrackingUrls);
    }
    if (wattpad.utils.getDeviceType() !== "mobile" && onStoryClick) {
      onStoryClick(storyData);
    } else if (shiftCarousel && !isActiveStory) {
      shiftCarousel();
    } else {
      app.router.navigate(link, { trigger: true });
    }
  };

  return (
    <div className={classnames("story-item", `story-${offset}`)}>
      <div className="cover">
        <a
          href={"/story/" + id}
          onClick={evt => onClickStory(evt, `/story/${id}`)}
        >
          <img src={resizeCover(cover, width)} alt={`${title} cover`} />
        </a>
      </div>
      <div className="tag-and-badge">
        {randomTag ? (
          <TagItem
            name={randomTag}
            clickHandler={(evt, name) => {
              evt.preventDefault();
              app.router.navigate(`/stories/${name}`, { trigger: true });
            }}
            link={`/stories/${randomTag}`}
          />
        ) : null}
      </div>
    </div>
  );
};

StorySlide.defaultProps = {
  onStoryClick: null,
  sources: []
};

StorySlide.propTypes = {
  cover: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number,
  offset: PropTypes.number,
  sectionType: PropTypes.string,
  sources: PropTypes.array,
  tags: PropTypes.array,
  shiftCarousel: PropTypes.func,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  page: PropTypes.string,
  onStoryClick: PropTypes.func,
  storyData: PropTypes.object,
  clickTrackingUrls: PropTypes.array,
  callTrackingUrls: PropTypes.func,
  isActiveStory: PropTypes.bool
};

export default StorySlide;
