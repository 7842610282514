import React, { useState } from "react";
import { Icon } from ".";
import { useTrans } from "../hooks";
import classNames from "classnames";
import PropTypes from "prop-types";
import { count } from "../helpers";
/**
 * The author content section.
 */
const AuthorContent = ({
  user: { username, avatar, age, country } = {},
  currentUserRoles
}) => {
  const { trans } = useTrans();

  return (
    <div className="author-content">
      <img src={avatar} alt="" />
      <div className="author-content__details">
        <div className="author-details__username">{username}</div>
        {currentUserRoles.isSysAdmin && (
          <div className="author-details__info">
            {age && (
              <div className="author-info__age">{trans("%s yrs old", age)}</div>
            )}
            {country && <div className="author-info__country">{country}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

AuthorContent.defaultProps = {
  currentUserRoles: {
    isSysAdmin: false,
    isAmbassador: false
  }
};

AuthorContent.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    avatar: PropTypes.string.isRequired,
    age: PropTypes.number,
    country: PropTypes.string
  }),
  currentUserRoles: PropTypes.shape({
    isSysAdmin: PropTypes.bool,
    isAmbassador: PropTypes.bool
  })
};
/**
 * The admin panel content section.
 */
const AdminPanelContent = ({
  currentUserRoles,
  user,
  storyId,
  storyTranslateUrl,
  storyLanguage,
  languages,
  onLanguageChange,
  categories,
  storyCategories,
  onCategoryChange,
  onFileSelected,
  onCoverRemove
}) => {
  const { trans } = useTrans();

  const [errorMsg, setErrorMsg] = useState("");
  // false for error, true for success, null for nothing.
  const [coverStatus, setCoverStatus] = useState(null);
  const [langStatus, setLangStatus] = useState(null);
  const [categoryStatus, setCategoryStatus] = useState(null);
  // default dropdown select option
  const [defaultLanguage, setDefaultLanguage] = useState(storyLanguage);
  const [defaultCategory, setDefaultCategory] = useState(
    storyCategories && storyCategories[0]
  );

  const responseFeedback = (setResponseStatus, errMsg) => {
    if (errMsg) {
      setErrorMsg(errMsg);
      setResponseStatus(false);
    } else {
      setResponseStatus(true);
    }

    window.setTimeout(function() {
      setResponseStatus(null);
      setErrorMsg("");
    }, 3000);
  };

  const handleLanguageChange = e => {
    var newLang = e.target.value;
    onLanguageChange(
      setLangStatus,
      newLang,
      setDefaultLanguage,
      responseFeedback
    );
  };

  const handleCategoryChange = e => {
    var newCategory = e.target.value;
    onCategoryChange(
      setCategoryStatus,
      newCategory,
      setDefaultCategory,
      responseFeedback
    );
  };

  const handleCoverRemove = () => {
    onCoverRemove(setCoverStatus, responseFeedback);
  };

  const handlefileSelected = () => {
    onFileSelected(setCoverStatus, responseFeedback);
  };
  return (
    <div className="admin-panel__content">
      <AuthorContent user={user} currentUserRoles={currentUserRoles} />

      <ul className="admin-panel-content-grid">
        <li className="story-admin-dropdown-row error-row">
          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
        </li>
        <li className="admin-panel-content-row review-row">
          <strong className="row-name">{trans("Review:")}</strong>
          <span className="row-controls">
            <ul className="option-items">
              <li>
                <a href={`/contentreview?groupid=${storyId}`}>
                  {trans("Story")}
                </a>
              </li>
            </ul>
          </span>
        </li>
        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row options-row">
            <strong className="row-name">{trans("Options:")}</strong>
            <span className="row-controls">
              <ul className="option-items">
                <li>
                  <a href={`/myworks/${storyId}`}>
                    {trans("Edit Story Details")}
                  </a>
                </li>
                <li>
                  <a href={storyTranslateUrl}>{trans("Translate")}</a>
                </li>
              </ul>
            </span>
          </li>
        )}
        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row paid-story-row">
            <strong className="row-name">{trans("Paid Story")}:</strong>
            <span className="row-controls">
              <ul className="option-items">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/admin/paid_stories_manager?storyId=${storyId}`}
                  >
                    {trans("Manage")}
                  </a>
                </li>
              </ul>
            </span>
          </li>
        )}
        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row download-row">
            <strong className="row-name">{trans("Download:")}</strong>
            <span className="row-controls">
              <ul className="option-items">
                <li>
                  <a
                    href={`/apiv2/storytext?group_id=${storyId}&no_format=false&include_deleted=true&include_drafts=true&include_private=true&output=zip`}
                  >
                    {trans("Story Group")}
                  </a>
                </li>
              </ul>
            </span>
          </li>
        )}
        <li className="admin-panel-content-row language-row">
          <strong className="row-name">{trans("Language:")}</strong>
          <span className="row-controls">
            <select
              defaultValue={defaultLanguage}
              onChange={handleLanguageChange}
              id="story-language-select"
              className="form-control"
            >
              {languages &&
                languages.map((language, i) => {
                  return (
                    <option key={i} value={language.id}>
                      {language.name}
                    </option>
                  );
                })}
            </select>
          </span>
          <span className="response-status pull-right">
            {langStatus && <Icon name="check" size="18" color="ds-success" />}
            {langStatus === false && (
              <Icon iconName="fa-warning" size="18" color="wp-error-1" />
            )}
          </span>
        </li>
        <li className="admin-panel-content-row category-row">
          <strong className="row-name">{trans("Category:")}</strong>
          <span className="row-controls">
            <select
              defaultValue={defaultCategory}
              onChange={handleCategoryChange}
              id="story-category-select"
              className="form-control"
            >
              <option value="-1">{trans("Select a category")}</option>
              {categories &&
                categories.map((category, i) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </select>
          </span>
          <span className="response-status pull-right" />
          <span className="response-status pull-right">
            {categoryStatus && (
              <Icon name="check" size="18" color="ds-success" />
            )}
            {categoryStatus === false && (
              <Icon iconName="fa-warning" size="18" color="wp-error-1" />
            )}
          </span>
        </li>
        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row cover-row">
            <strong className="row-name">{trans("Cover:")}</strong>
            <span className="row-controls">
              <ul className="option-items">
                <li>
                  {/* file-upload and file-drop classes are for event handlers in the mixin */}
                  <div className="cover-upload-btn file-upload file-drop">
                    <button className="file-select">{trans("Upload")}</button>
                    <input
                      onChange={handlefileSelected}
                      accept="image/jpeg, image/png, image/gif"
                      type="file"
                      name="file"
                    />
                  </div>
                </li>
                <li>
                  <button onClick={handleCoverRemove}>{trans("Remove")}</button>
                </li>
                <li>
                  <a href={`/banner?id=${storyId}`}>{trans("Banner")}</a>
                </li>
              </ul>
            </span>
            <span className="response-status pull-right">
              {coverStatus && (
                <Icon name="check" size="18" color="ds-success" />
              )}
              {coverStatus === false && (
                <Icon iconName="fa-warning" size="18" color="wp-error-1" />
              )}
            </span>
          </li>
        )}
        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row rating-row">
            <strong className="row-name">{trans("Rating:")}</strong>
            <span className="row-controls">
              <ul className="option-items">
                <li>
                  <a
                    href={`/admin/community_reports?model_type=2&model_id=${storyId}`}
                  >
                    {trans("Reports")}
                  </a>
                </li>
                <li>
                  <a
                    href={`/admin/story_rating_history.php?story_id=${storyId}`}
                  >
                    {trans("History")}
                  </a>
                </li>
              </ul>
            </span>
          </li>
        )}
        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row total-reads-row">
            <strong className="row-name">
              {trans("Total Reads By Author:")}
            </strong>
            <span className="row-controls">
              <ul className="option-items">
                {user.totalReads && (
                  <li>{`${user.totalReads} (${count(user.totalReads)})`}</li>
                )}
              </ul>
            </span>
          </li>
        )}

        {currentUserRoles.isSysAdmin && (
          <li className="admin-panel-content-row">
            <strong className="row-name">{trans("Admin Notes:")}</strong>
            <pre className="notes">{user.notes}</pre>
            <a
              href={`https://admin.wattpad.com/admin/manage_user.php?&username=${
                user.username
              }`}
            >
              {trans("Add/Delete notes")}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

AdminPanelContent.defaultProps = {
  onLanguageChange: () => {},
  onCategoryChange: () => {},
  onFileSelected: () => {},
  onCoverRemove: () => {},
  currentUserRoles: {
    isSysAdmin: false,
    isAmbassador: false
  }
};

AdminPanelContent.propTypes = {
  user: PropTypes.object,
  storyId: PropTypes.number,
  storyTranslateUrl: PropTypes.string,
  languages: PropTypes.array,
  storyLanguage: PropTypes.number,
  categories: PropTypes.array,
  storyCategories: PropTypes.array,
  onLanguageChange: PropTypes.func,
  onCategoryChange: PropTypes.func,
  onFileSelected: PropTypes.func,
  onCoverRemove: PropTypes.func,
  currentUserRoles: PropTypes.shape({
    isSysAdmin: PropTypes.bool,
    isAmbassador: PropTypes.bool
  })
};
/**
 * The admin panel.
 */
const AdminPanel = ({
  currentUserRoles,
  user,
  storyId,
  storyTranslateUrl,
  storyLanguage,
  languages,
  onLanguageChange,
  categories,
  storyCategories,
  onCategoryChange,
  onFileSelected,
  onCoverRemove
}) => {
  const { trans } = useTrans();
  const [adminPanelVisible, setAdminPanelVisible] = useState(false);
  const handleAdminPanelClick = () => {
    setAdminPanelVisible(!adminPanelVisible);
  };
  return (
    <div className="admin-panel">
      <button
        onClick={handleAdminPanelClick}
        className="admin-panel__header-btn"
      >
        <Icon name="lock" size="24" color="ds-neutral-100" />
        <div className="admin-header__title">{trans("Story lock")}</div>
        <Icon
          className={classNames("admin-header__chevron", {
            "admin-header__chevron--rotated": adminPanelVisible
          })}
          name="chevRight"
          size="24"
          color="ds-neutral-100"
        />
      </button>

      {(adminPanelVisible || typeof window === "undefined") && (
        <AdminPanelContent
          currentUserRoles={currentUserRoles}
          user={user}
          storyId={storyId}
          storyTranslateUrl={storyTranslateUrl}
          languages={languages}
          storyLanguage={storyLanguage}
          onLanguageChange={onLanguageChange}
          categories={categories}
          storyCategories={storyCategories}
          onCategoryChange={onCategoryChange}
          onFileSelected={onFileSelected}
          onCoverRemove={onCoverRemove}
        />
      )}
    </div>
  );
};

AdminPanel.defaultProps = {
  onLanguageChange: () => {},
  onCategoryChange: () => {},
  onFileSelected: () => {},
  onCoverRemove: () => {},
  currentUserRoles: {
    isSysAdmin: false,
    isAmbassador: false
  }
};

AdminPanel.propTypes = {
  user: PropTypes.object,
  storyId: PropTypes.number,
  storyTranslateUrl: PropTypes.string,
  storyLanguage: PropTypes.number,
  languages: PropTypes.array,
  categories: PropTypes.array,
  storyCategories: PropTypes.array,
  onLanguageChange: PropTypes.func,
  onCategoryChange: PropTypes.func,
  onFileSelected: PropTypes.func,
  onCoverRemove: PropTypes.func,
  currentUserRoles: PropTypes.shape({
    isSysAdmin: PropTypes.bool,
    isAmbassador: PropTypes.bool
  })
};

export default AdminPanel;
