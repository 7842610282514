import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { isClient } from "../helpers";
import { I18nContext, UserContext, FeatureFlagsContext } from "../contexts";
import { AppProvider } from "@wattpad/web-ui-library";

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

const withRedux = (WrappedComponent, store) =>
  class extends Component {
    static displayName = `${getDisplayName(WrappedComponent)}WithRedux`;

    static propTypes = {
      resLocals: PropTypes.shape({
        trans: PropTypes.func,
        ngettext: PropTypes.func,
        currentUser: PropTypes.object,
        testGroups: PropTypes.object
      })
    };

    render() {
      let trans, ngettext, user, featureFlags;
      if (isClient()) {
        const currentUser = wattpad.utils.currentUser();
        trans = wattpad.utils.trans;
        ngettext = wattpad.utils.ngettext;
        user = currentUser ? currentUser.attributes : {};
        featureFlags = wattpad.testGroups;
      } else {
        trans = this.props.resLocals.trans;
        ngettext = this.props.resLocals.ngettext;
        user = this.props.resLocals.currentUser || {};
        featureFlags = this.props.resLocals.testGroups;
      }

      return (
        <Provider store={store}>
          <AppProvider i18n={{ translate: trans, ngettext }}>
            <UserContext.Provider value={user}>
              <I18nContext.Provider value={{ trans, ngettext }}>
                <FeatureFlagsContext.Provider value={featureFlags}>
                  <WrappedComponent {...this.props} />
                </FeatureFlagsContext.Provider>
              </I18nContext.Provider>
            </UserContext.Provider>
          </AppProvider>
        </Provider>
      );
    }
  };

export default withRedux;
