import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  simpleShorten,
  count,
  formatStoryUrl,
  sanitizeHTML,
  resizeCover,
  injectTrans,
  getCoverSize
} from "../../helpers";
import { Icon, MODAL_ID } from "../../shared-components";
import TagMeta from "../tags/TagMeta";

export const BrowseStoryItemProps = {
  completed: PropTypes.bool,
  cover: PropTypes.string,
  description: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  mature: PropTypes.bool,
  numParts: PropTypes.number.isRequired,
  readCount: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired // validation for this object will happen at the TagMeta component level
};

class BrowseStoryItem extends Component {
  render() {
    const {
      title,
      user,
      url,
      readCount,
      numParts,
      voteCount,
      cover,
      description,
      mature,
      completed,
      tags,
      rank,
      id,
      app,
      trans,
      useReactModal
    } = this.props;

    const tagMeta = {
      tags,
      storyId: id,
      numShown: app.deviceType === "desktop" ? 3 : 2
    };

    const titleLink = simpleShorten(title, 35, false);
    const username = user.fullname || user.name;
    const shortUsername = simpleShorten(username, 30, false);
    const userNode = trans("by %s", shortUsername);
    const storyUrl = formatStoryUrl(url);
    const userLink = "/user/" + user.name;

    const readCountText = count(readCount).toString();
    const numPartsText = count(numParts).toString();
    const voteCountText = count(voteCount).toString();

    const mobileCoverSize = 300;
    const desktopCoverSize = 600;

    let coverImageUrl,
      descriptionText = sanitizeHTML(description);

    if (app.deviceType === "desktop") {
      coverImageUrl = resizeCover(
        cover,
        getCoverSize(desktopCoverSize),
        app.devicePixelRatio,
        app.matchMedia,
        app.msMatchMedia
      );
      descriptionText = simpleShorten(descriptionText, 170, false);
    } else {
      coverImageUrl = resizeCover(
        cover,
        getCoverSize(mobileCoverSize),
        app.devicePixelRatio,
        app.matchMedia,
        app.msMatchMedia
      );
      descriptionText = simpleShorten(descriptionText, 170, false);
    }
    const coverAlt = trans("%1$s by %2$s", title, user.name);

    const handleStoryPreviewModal = evt => {
      if (!useReactModal) return;

      evt.preventDefault();
      const storyPreviewModal = window?.wattpad?.utils.getModal(
        MODAL_ID.STORY_PREVIEW_MODAL
      );
      storyPreviewModal.setData({ ...this.props, isTracked: true });
      storyPreviewModal.show();
    };

    // The description is sanitized on the backend
    var innerDescription = { __html: descriptionText };
    const descriptionNode = (
      <div className="description" dangerouslySetInnerHTML={innerDescription} />
    );
    const coverImage = <img src={coverImageUrl} alt={coverAlt} />;

    let storyRankNode;

    // rank is for tags, a formulated data attribute added in the view
    // If parent view requests the tag via showStoryRank, then rank will be the index of the story in the collection
    if (rank) {
      storyRankNode = (
        <div className="story-rank">
          <span>#{rank}</span>
        </div>
      );
    }

    return (
      <div className="item">
        <a
          className="on-story-preview cover cover-lg"
          data-story-id={id}
          href={storyUrl}
          onClick={handleStoryPreviewModal}
        >
          <div className="fixed-ratio fixed-ratio-cover">
            {coverImage}
            {storyRankNode}
          </div>
        </a>
        <div className="content">
          <a
            className={"title meta on-story-preview"}
            data-story-id={id}
            href={storyUrl}
            onClick={handleStoryPreviewModal}
          >
            {titleLink}
          </a>
          <a className="username meta on-navigate" href={userLink}>
            {userNode}
          </a>
          <div className="meta social-meta">
            <span className="read-count">
              <Icon iconName="fa-view" height="12" color="wp-neutral-2" />
              {readCountText}
            </span>
            <span className="vote-count">
              <Icon iconName="fa-vote" height="12" color="wp-neutral-2" />
              {voteCountText}
            </span>
            <span className="part-count">
              <Icon iconName="fa-list" height="12" color="wp-neutral-2" />
              {numPartsText}
            </span>
          </div>
          {descriptionNode}
          <div className="bottom-content clearfix">
            <div className="story-status">
              {completed ? (
                <span className="label label-info">{trans("Completed")}</span>
              ) : null}
              {mature ? (
                <span className="label label-danger">{trans("Mature")}</span>
              ) : null}
            </div>
            <TagMeta tagMeta={tagMeta} defaultClickEvents={useReactModal} />
          </div>
        </div>
      </div>
    );
  }
}

BrowseStoryItem.propTypes = {
  ...BrowseStoryItemProps,
  rank: PropTypes.number,
  trans: PropTypes.func.isRequired,
  app: PropTypes.object,
  useReactModal: PropTypes.bool
};

export default injectTrans(BrowseStoryItem);
