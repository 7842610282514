import React, { useState } from "react";
import classNames from "classnames";
import { Redirect } from "react-router-dom";

import { ConnectImage } from "../../shared-components";
import { READER_OR_WRITER_OPTIONS, SECTION_INTROS } from "./constants";
import { sendWriterOnboardingEvents } from "./helpers";
import { useTrans } from "../../hooks";

const ReaderOrWriter = () => {
  const { trans } = useTrans();

  const [selected, setSelected] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const pressNext = () => {
    if (selected === "reader") {
      sendWriterOnboardingEvents({ user_type: selected });
      app.router.navigate("/start", { trigger: true });
    } else {
      setRedirect(true);
    }
  };

  if (redirect) {
    return (
      <Redirect
        push
        to={{
          pathname: "/motivation",
          state: { user_type: selected }
        }}
      />
    );
  }

  return (
    <>
      <p>{SECTION_INTROS(trans)[0]}</p>
      <div id="ReaderOrWriter" className="options">
        {Object.entries(READER_OR_WRITER_OPTIONS(trans)).map(
          ([key, { message, icon }]) => {
            let isSelected = selected === key;
            let iconName = `${icon}${isSelected ? "_selected" : ""}`;

            return (
              <div
                key={key}
                className={classNames("option", {
                  selected: isSelected
                })}
                onClick={() => {
                  setSelected(key);
                }}
                onKeyDown={() => {
                  setSelected(key);
                }}
                role="option"
                tabIndex="0"
                aria-selected={isSelected}
              >
                <ConnectImage name={`shared/${iconName}.svg`} alt="icon" />
                <div>{message}</div>
              </div>
            );
          }
        )}
      </div>
      <button
        className={classNames("btn btn-md btn-orange", {
          disabled: selected === null
        })}
        onClick={pressNext}
      >
        {trans("Next")}
      </button>
    </>
  );
};

export default ReaderOrWriter;
