import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { loadConversation, postMessage } from "./reducers";
import { Messages } from "./Messages";
import { ThreadControl } from "../ThreadControl";
import { UserBadge } from "../../user-badges/UserBadge";
import { useTrans, useSetDocumentTitle } from "../../../hooks";
import { WPModal } from "../../../shared-components";
import { Toast } from "../../../shared-components/toasts/Toast";
import { InfoBar } from "../../../shared-components/info-bar/InfoBar";
import DisabledReplyNotice from "./DisabledReplyNotice";
import LazyLoader from "../LazyLoader";
import MessageForm from "./MessageForm";
import { RestrictPrivateMessagingDeprecationMessage } from "../RestrictPrivateMessagingDeprecationMessage";

const useMountEffect = func => useEffect(func, []);
const useCallbackEffect = (func, dep) => useEffect(func, dep);

function ConversationUI({
  privateMessagePermissions,
  username,
  messages,
  badges,
  isActive,
  isLoading,
  isLoadingMore,
  isDeleting,
  isPosting,
  total,
  offset,
  isEmailVerified,
  isMutingCurrentUser,
  isMutedByCurrentUser,
  isBlockedByCurrentUser,
  isFollowing,
  scrollToMessageId,
  postMessage,
  loadConversation
}) {
  const { trans } = useTrans();
  const scrollToMessageRef = useRef();
  const headerRef = useRef();
  const { activeThread } = useParams();

  useSetDocumentTitle(`${trans("Messages between you and %s", activeThread)} - Wattpad`); // prettier-ignore
  useMountEffect(() => {
    window.te.push("event", "private_messages", "conversation", null, "view", {
      sender_username: activeThread,
      recipient_username: username
    });
    if (typeof window.__atha !== "undefined") {
      window.__atha.sendPageView("inbox-conversation");
    }
    loadConversation(activeThread);
  });
  useCallbackEffect(
    () => {
      if (scrollToMessageRef.current && headerRef.current) {
        const paddingTop = 10;
        // We subtract the conversation header's height from the top.
        window.scrollTo(
          0,
          scrollToMessageRef.current.offsetTop -
            headerRef.current.getBoundingClientRect().bottom -
            paddingTop
        );
      }
    },
    [scrollToMessageId]
  );

  const hasSentByOther = messages.find(
    message => message.from.name === activeThread
  );
  const isLastBatch = offset === total;
  const onLoadMore = offset => {
    loadConversation(activeThread, offset);
  };
  const badge = badges.length ? badges[0] : "";
  // User is not muted and account is still active

  const isFirstTimeMessaging = messages.length === 0 && !isLoading;

  const canReply =
    !isMutingCurrentUser &&
    !isMutedByCurrentUser &&
    !isBlockedByCurrentUser &&
    isActive &&
    isEmailVerified;

  return (
    <div id="inbox">
      <div className="conversation-container">
        <div className="column" />
        <div className="column main">
          <div className="conversation-header" ref={headerRef}>
            <h2>{activeThread}</h2>
            <UserBadge
              name={badge}
              size="20"
              allowedBadges={["staff", "verified"]}
            />
          </div>

          <LazyLoader
            direction="up"
            threshold={0}
            isLoading={isLoadingMore}
            isDisabled={isLoadingMore || isDeleting || isPosting}
            total={total}
            offset={offset}
            onLoad={onLoadMore}
          />

          <Messages
            scrollToMessageId={scrollToMessageId}
            setRef={scrollToMessageRef}
            messages={messages}
            username={username}
            isActive={isActive}
            isLastBatch={isLastBatch}
          />

          {(!privateMessagePermissions ||
            privateMessagePermissions?.canSendPrivateMessage) &&
            !isLoading &&
            (canReply ? (
              <MessageForm
                activeThread={activeThread}
                isReply={Boolean(hasSentByOther)}
                postMessage={postMessage}
                isActive={isActive}
                username={username}
                isFirstTimeMessaging={isFirstTimeMessaging}
                isFollowing={isFollowing}
              />
            ) : (
              <DisabledReplyNotice
                isMutedByCurrentUser={isMutedByCurrentUser}
                isBlockedByCurrentUser={isBlockedByCurrentUser}
                activeThreadUsername={activeThread}
              />
            ))}

          {privateMessagePermissions &&
            !privateMessagePermissions?.canSendPrivateMessage && (
              <InfoBar>
                <RestrictPrivateMessagingDeprecationMessage />
              </InfoBar>
            )}
        </div>

        <div className="column">
          <div className="column-content">
            <ThreadControl
              activeThread={activeThread}
              isLoading={isLoading}
              isMutedByCurrentUser={isMutedByCurrentUser}
              isBlockedByCurrentUser={isBlockedByCurrentUser}
              privateMessagePermissions={privateMessagePermissions}
            />
            <div className="extra-content-container">
              <a
                href="https://creators.wattpad.com/programs-and-opportunities/wattpad-creators-program/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="/img/desktop-web/2019_Wattpad-Stars_300x250.jpg"
                  alt="Wattpad Stars"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <WPModal />
      <Toast />
    </div>
  );
}

ConversationUI.propTypes = {
  privateMessagePermissions: PropTypes.shape({
    canSendPrivateMessage: PropTypes.bool
  }).isRequired,
  username: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  postMessage: PropTypes.func.isRequired,
  loadConversation: PropTypes.func.isRequired,
  scrollToMessageId: PropTypes.number.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  isMutingCurrentUser: PropTypes.bool.isRequired,
  isMutedByCurrentUser: PropTypes.bool.isRequired,
  isBlockedByCurrentUser: PropTypes.bool,
  isFollowing: PropTypes.bool.isRequired
};

const Conversation = connect(
  state => ({
    messages: state.conversation.messages,
    badges: state.conversation.badges,
    isActive: state.conversation.isActive,
    isLoading: state.conversation.isLoading,
    isLoadingMore: state.conversation.isLoadingMore,
    isDeleting: state.conversation.isDeleting,
    isPosting: state.conversation.isPosting,
    total: state.conversation.total,
    offset: state.conversation.offset,
    isEmailVerified: state.conversation.isEmailVerified,
    isMutingCurrentUser: state.conversation.isMutingCurrentUser,
    isMutedByCurrentUser: state.conversation.isMutedByCurrentUser,
    isBlockedByCurrentUser: state.conversation.isBlockedByCurrentUser,
    scrollToMessageId: state.conversation.scrollToMessageId,
    isFollowing: state.conversation.isFollowing
  }),
  {
    loadConversation,
    postMessage
  }
)(ConversationUI);

export { Conversation, ConversationUI };
