import moment from "moment";
import React from "react";

import Option from "../../input/Option";

export const generateDropdownItems = type => {
  var pickerValues = [],
    min,
    max,
    top = moment(),
    past = moment().subtract(100, "years");

  switch (type) {
    case "day":
      min = 1;
      max = 31;
      pickerValues.push(
        <option value="" key="-1" disabled>
          Day
        </option>
      );
      break;
    case "month":
      min = 0;
      max = 11;
      pickerValues.push(
        <option value="" key="-1" disabled>
          Month
        </option>
      );
      break;
    case "year":
      min = past.year();
      max = top.year();
      pickerValues.push(
        <option value="" key="-1" disabled>
          Year
        </option>
      );
      break;
    default:
      break;
  }

  if (type === "year") {
    for (let i = max; i > min; i--) {
      pickerValues.push(Option({ value: i, label: i }));
    }
  } else {
    for (let i = min; i <= max; i++) {
      // Moment.js months are indexed from 0, aka June is month 5, so in order for the right
      // values and text to display for months, we need to add 1. Otherwise, for day, it remains the same.
      //   numFormatMax : In order for days and months smaller than 10 to have their value formatted with
      //      double digits (option value would be "09" instead of "9"), we add a 0 in front of the value itself
      //      Since months are zero-indexed, the max value for months is 9, since 9 is October
      //   curr : the current value of the option inside the picker
      //   text : the accompanying text corresponding to curr
      //
      //  We make these changes because platform expects birthdate to be formatted as "MM-DD-YYYY"
      //  so both day and month need to be 2 digits regardless of the value ("09" instead of "9")
      var numFormatMax = type === "month" ? 9 : 10,
        curr = type === "month" ? i + 1 : i,
        text =
          type === "month"
            ? moment()
                .month(i)
                .format("MMM")
            : i;

      if (i < numFormatMax) {
        curr = "0" + curr;
      } else {
        curr = "" + curr;
      }

      pickerValues.push(Option({ value: curr, label: text }));
    }
  }

  return pickerValues;
};

export const generateDefaultPronouns = trans => [
  { key: "", value: trans("Pronouns (optional)") },
  {
    key: "M",
    value: trans("He/Him")
  },
  {
    key: "F",
    value: trans("She/Her")
  },
  {
    key: "O",
    value: trans("They/Them")
  },
  {
    key: "X",
    value: trans("Not listed")
  },
  {
    key: "null",
    value: trans("Prefer not to say")
  }
];
