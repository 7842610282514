import React, { useState } from "react";
import PropTypes from "prop-types";

import { resizeCover, simpleShorten } from "../helpers";
import { useEventListener, useTrans } from "../hooks";
import { Icon } from "./";
import StoryStats from "./StoryStats";
import StoryActions from "./StoryActions";
import AuthorName from "./AuthorName";

let window = window || global;
/**
 * The story header.
 * Displays the title, cover, author and the story's stats.
 */
const StoryHeader = ({
  user: { username, avatar } = {},
  cover,
  draft,
  isLoggedInUser,
  isPaid,
  isPaidPreview,
  isPaidStory,
  firstPartId,
  statsItems,
  title = "Placeholder title TBD",
  storyId,
  readingLists,
  storiesCollections,
  sources,
  writerPreviewHasBannedCover
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [storyWidth, setStoryWidth] = useState(0.3 * windowWidth);
  const { trans } = useTrans();
  let titleCharLimit = 36;
  let authorCharLimit = 36;

  // TODO: This is possibly causing a re-render on every screen re-size.
  // Check for a better way to handle this.
  useEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
    // Cap windowWidth at 650 so that the resultant cover does not overshoot the width.
    // Note: storyWidth defaults to 256 on SSR as we can't predict screensize.
    if (windowWidth > 650) {
      setStoryWidth(0.3 * 650);
    } else {
      setStoryWidth(0.3 * windowWidth);
    }
  });

  if (windowWidth <= 575) {
    titleCharLimit = 22;
    authorCharLimit = 16;
  }
  const resizedCover = resizeCover(cover, storyWidth);

  return (
    <div className="story-header">
      <div className="story-cover">
        {resizedCover ? (
          <img src={resizedCover} alt="" />
        ) : (
          <img src={cover} alt="" />
        )}
        {writerPreviewHasBannedCover && (
          <div className="banned-overlay">
            <Icon
              name="warning-triangle"
              size="68"
              color="wp-warning-01"
              viewBox="24"
              className="warning-icon"
            />
          </div>
        )}
      </div>
      <div className="story-info">
        {isPaid &&
          isPaidStory && (
            <div className="paid-indicator">
              <span>{trans("Wattpad Original")}</span>
            </div>
          )}
        <span className="sr-only">{title}</span>
        <div aria-hidden="true" className="story-info__title">
          {simpleShorten(title, titleCharLimit, false)}
        </div>
        {username && (
          <AuthorName
            username={username}
            avatar={avatar}
            authorCharLimit={authorCharLimit}
          />
        )}
        <StoryStats
          statsItems={statsItems}
          className={"hidden-xxs"}
          showTooltip
        />
        <StoryActions
          className={"hidden-xxs"}
          draft={draft}
          isLoggedInUser={isLoggedInUser}
          isPaid={isPaid}
          isPaidPreview={isPaidPreview}
          isPaidStory={isPaidStory}
          firstPartId={firstPartId}
          storyId={storyId}
          readingLists={readingLists}
          sources={sources}
          storiesCollections={storiesCollections}
        />
      </div>
    </div>
  );
};

StoryHeader.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string, // TODO: add placeholder author, and trans tag if we chose to take this path.
    avatar: PropTypes.string.isRequired
  }),
  cover: PropTypes.string.isRequired,
  draft: PropTypes.bool,
  firstPartId: PropTypes.number,
  isLoggedInUser: PropTypes.bool,
  isPaid: PropTypes.bool,
  isPaidPreview: PropTypes.bool,
  isPaidStory: PropTypes.bool,
  statsItems: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string, // TODO: add placeholder title, and trans tag.
  storyId: PropTypes.number,
  readingLists: PropTypes.array,
  storiesCollections: PropTypes.object,
  sources: PropTypes.array,
  writerPreviewHasBannedCover: PropTypes.bool
};

export default StoryHeader;
