import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";

import { ContestFormHeader } from "./ContestFormHeader";

import { useTrans } from "../../../hooks";
import { Loader } from "../../../shared-components";

import LanguageField from "./WattysFormFields/LanguageField";
import OngoingStoryDeclarationField from "./WattysFormFields/OngoingStoryDeclarationField";
import SeriesIdentificationField from "./WattysFormFields/SeriesIdentification/SeriesIdentificationField";
import MatureThemesField from "./WattysFormFields/MatureThemesField";
import LoglineField from "./WattysFormFields/LoglineField";
import PlotSummaryField from "./WattysFormFields/PlotSummaryField";
import EmailField from "./WattysFormFields/EmailField";
import AnticipatedStoryLengthField from "./WattysFormFields/AnticipatedStoryLengthField";
import CharacterRepresentationField from "./WattysFormFields/CharacterRepresentationField";
import StoryTypeField from "./WattysFormFields/StoryTypeField";
import PrimaryGenreIdentificationField from "./WattysFormFields/PrimaryGenreIdentificationField";
import SubsequentGenreIdentificationField from "./WattysFormFields/SubsequentGenreIdentificationField";
import FinalConsentField from "./WattysFormFields/FinalConsentField";

import { getLength, formToSubmissionBody } from "./helpers";
import {
  EMAIL_CHARACTER_MAX,
  LOGLINE_CHARACTER_MAX,
  LOGLINE_CHARACTER_MIN,
  PLOT_SUMMARY_CHARACTER_MAX,
  PLOT_SUMMARY_CHARACTER_MIN,
  OTHER_MATURE_THEMES_CHARACTER_MAX,
  WATTYS_CLOSING_DATETIME,
  MIN_STORIES,
  MAX_STORIES,
  VALID_EMAIL_REGEX,
  CURRENT_WATTYS_YEAR,
  RULES_LINK,
  MAP_WATTYS_ELIGIBILITY_TO_LINKS,
  DEFAULT_ELIGIBILITY_LINK,
  MAP_WATTYS_GENRE_LINKS,
  DEFAULT_GENRE_LINK,
  DEFAULT_IDENTITIES_LINK,
  MAP_IDENTITIES_LINK
} from "./constants";
import LegalConsentField from "./WattysFormFields/LegalConsentField";

let submitted = false;

export default function WattysForm({
  submitForm,
  closeModal,
  isStandalonePage,
  storyId,
  setContestForm,
  formState,
  formIsOpen
}) {
  // Default values from server
  const { data: initialData } = formState;

  let { hasSubmitted, isLoading } = formState;
  let disableForm = hasSubmitted || isLoading || !formIsOpen;

  // States
  const [form, setForm] = useState(initialData);
  const [emailConfirmField, setEmailConfirmField] = useState("");
  const [eligibilityCriteriaLink, setEligibilityCriteriaLink] = useState("");
  const [genreLink, setGenreLink] = useState("");
  const [identitiesLink, setIdentitiesLink] = useState("");
  const [isFrenchForm, setIsFrenchForm] = useState(false);
  const [fieldStatus, setStatus] = useState({
    fieldName: "",
    fieldInvalid: false
  });
  const [requiredMatureThemesError, setRequiredMatureThemesError] = useState(
    false
  );

  // Update form state when redux store finishes fetching
  useEffect(
    () => {
      setForm(initialData);
      const displayLanguage = parseInt(app.get("language"), 10);
      const eligibilityCriteriaLink =
        MAP_WATTYS_ELIGIBILITY_TO_LINKS[displayLanguage] ||
        DEFAULT_ELIGIBILITY_LINK;
      setEligibilityCriteriaLink(eligibilityCriteriaLink);
      const genreLink =
        MAP_WATTYS_GENRE_LINKS[displayLanguage] || DEFAULT_GENRE_LINK;
      setGenreLink(genreLink);
      const identitiesLink =
        MAP_IDENTITIES_LINK[displayLanguage] || DEFAULT_IDENTITIES_LINK;
      setIdentitiesLink(identitiesLink);
      if (displayLanguage === 2) {
        setIsFrenchForm(true);
      }
    },
    [initialData]
  );

  const { trans } = useTrans();

  const isFieldInvalid = form => {
    return (
      form.matureThemes.length === 0 ||
      (form.matureThemes.includes("OTHER") && form.matureThemeOther === "") ||
      (form.matureThemes.includes("SEX") && form.storyMaturity === "") ||
      form.plotSummary === "" ||
      form.logLine === "" ||
      form.writerEmail === "" ||
      form.writerEmail.match(VALID_EMAIL_REGEX) === null ||
      emailConfirmField === "" ||
      emailConfirmField.match(VALID_EMAIL_REGEX) === null ||
      emailConfirmField !== form.writerEmail ||
      !form.legalConsent ||
      !form.finalConsent ||
      form.storyType === "" ||
      form.genrePrimary === "" ||
      form.genreOthers.length > 3 ||
      (form.diversity.includes("OTHER") && form.diversityOther === "") ||
      (form.language === "English" && // english storyLanguage only
        (form.isSeries === "" || // field is empty
          (form.isSeries && // story is part of a series but the subsequent fields are empty
            (form.seriesType === "" ||
              form.isSeriesStandalone === "" ||
              form.numberOfStoriesInSeries === "" ||
              form.totalPlannedStoriesInSeries === "" ||
              form.numberOfStoriesInSeries >
                form.totalPlannedStoriesInSeries)))) ||
      (form.storyStatus === "ONGOING" && form.anticipatedStoryLength === "")
    );
  };

  function onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    // Build data to send over.
    submitted = true;

    if (isFieldInvalid(form)) {
      setStatus({
        fieldInvalid: true
      });
    }

    const body = formToSubmissionBody(parseInt(storyId), form);

    const numberOfStoriesInSeriesIsValid =
      form.numberOfStoriesInSeries !== "" &&
      typeof form.numberOfStoriesInSeries === "number" &&
      Number.isInteger(form.numberOfStoriesInSeries) &&
      form.numberOfStoriesInSeries >= MIN_STORIES &&
      form.numberOfStoriesInSeries <= MAX_STORIES;

    const totalPlannedStoriesInSeriesIsValid =
      form.totalPlannedStoriesInSeries !== "" &&
      typeof form.totalPlannedStoriesInSeries === "number" &&
      Number.isInteger(form.totalPlannedStoriesInSeries) &&
      form.totalPlannedStoriesInSeries >= MIN_STORIES &&
      form.totalPlannedStoriesInSeries <= MAX_STORIES;

    const characterRepresentationIsValid =
      !form.diversity.includes("OTHER") || form.diversityOther !== "";

    const seriesIdentificationIsValid =
      form.language !== "English" ||
      (form.isSeries !== "" && // field is not empty
        (!form.isSeries || // story is not part of a series
          (form.isSeries && // story is part of a series and subsequent fields are valid
            form.seriesType !== "" &&
            form.isSeriesStandalone !== "" &&
            numberOfStoriesInSeriesIsValid &&
            totalPlannedStoriesInSeriesIsValid &&
            form.numberOfStoriesInSeries <= form.totalPlannedStoriesInSeries)));

    const canSubmit =
      !disableForm &&
      (form.logLine &&
        getLength(form.logLine) <= LOGLINE_CHARACTER_MAX &&
        getLength(form.logLine) >= LOGLINE_CHARACTER_MIN &&
        (form.matureThemes.includes("SEX") ? form.storyMaturity : true) &&
        form.plotSummary &&
        getLength(form.plotSummary) >= PLOT_SUMMARY_CHARACTER_MIN &&
        getLength(form.plotSummary) <= PLOT_SUMMARY_CHARACTER_MAX) &&
      form.writerEmail &&
      getLength(form.writerEmail) <= EMAIL_CHARACTER_MAX &&
      form.writerEmail.match(VALID_EMAIL_REGEX) !== null &&
      emailConfirmField &&
      getLength(emailConfirmField) <= EMAIL_CHARACTER_MAX &&
      emailConfirmField.match(VALID_EMAIL_REGEX) !== null &&
      form.writerEmail === emailConfirmField &&
      form.matureThemes.length !== 0 &&
      !requiredMatureThemesError &&
      (form.matureThemes.includes("OTHER")
        ? form.matureThemeOther !== ""
        : true) &&
      getLength(form.matureThemeOther) <= OTHER_MATURE_THEMES_CHARACTER_MAX &&
      form.finalConsent &&
      form.legalConsent &&
      form.storyType &&
      form.genrePrimary &&
      form.genreOthers.length <= 3 &&
      characterRepresentationIsValid &&
      (form.storyStatus === "COMPLETE" ||
        (form.storyStatus === "ONGOING" &&
          form.anticipatedStoryLength !== "")) &&
      seriesIdentificationIsValid;

    if (canSubmit) {
      setContestForm(storyId, body).then(hasAddedWattysTag => {
        submitForm(hasAddedWattysTag);
      });
    }
  }

  const onMatureThemesChange = useCallback(
    event => {
      let newMatureThemes;
      const { name, checked } = event.target;
      setStatus({ fieldName: "matureThemes", fieldInvalid: false });
      if (checked) {
        // Add mature theme
        if (form.matureThemes.includes("NONE")) {
          newMatureThemes = form.matureThemes.filter(
            matureTheme => matureTheme !== "NONE"
          );
          newMatureThemes = [...newMatureThemes, name];
        } else if (name === "NONE") {
          newMatureThemes = ["NONE"];
        } else {
          newMatureThemes = [...form.matureThemes, name];
        }
      } else {
        // Remove mature theme
        newMatureThemes = form.matureThemes.filter(
          matureTheme => matureTheme !== name
        );
      }
      setRequiredMatureThemesError(newMatureThemes.length === 0);

      if (name === "OTHER" || (name === "NONE" && checked === true)) {
        setForm({
          ...form,
          matureThemes: newMatureThemes,
          matureThemeOther: ""
        });
      } else {
        setForm({
          ...form,
          matureThemes: newMatureThemes
        });
      }
    },
    [form]
  );

  function onOtherMatureFieldTextChange(event) {
    const { name, value } = event.target;
    setStatus({ fieldName: name, fieldInvalid: false });

    setForm({
      ...form,
      matureThemeOther: value
    });
  }

  const onRadioBtnChange = event => {
    const { name, value } = event.target;

    let boolVal = value !== 0;
    if (name == "finalConsent" || name == "legalConsent") {
      boolVal = event.target.checked;
    }

    setForm({ ...form, [name]: boolVal });
  };

  function onFormChange(event) {
    const { name, value } = event.target;
    if (name === "genrePrimary") {
      setForm({
        ...form,
        [name]: value,
        genreOthers: []
      });
    } else {
      setStatus({ fieldName: name, fieldInvalid: false });
      setForm({
        ...form,

        [name]: value
      });
    }
  }

  const onSeriesIdentificationChange = event => {
    const { name, value } = event.target;
    setStatus({ fieldName: name, fieldInvalid: false });
    if (name === "isSeries" || name === "isSeriesStandalone") {
      setForm({
        ...form,
        [name]: value === "yes"
      });
    } else if (
      name === "numberOfStoriesInSeries" ||
      name === "totalPlannedStoriesInSeries"
    ) {
      const valueAsNumber = isNaN(event.target.valueAsNumber)
        ? ""
        : event.target.valueAsNumber;
      setForm({
        ...form,
        [name]: valueAsNumber
      });
    } else {
      setForm({
        ...form,
        [name]: value
      });
    }
  };

  const onSubsequentGenreChange = event => {
    let newGenreOthers;
    const { name, checked } = event.target;
    setStatus({ fieldName: name, fieldInvalid: false });
    if (checked) {
      // add genre
      newGenreOthers = [...form.genreOthers, name];
    } else {
      // remove genre
      newGenreOthers = form.genreOthers.filter(genres => genres !== name);
    }
    setForm({ ...form, genreOthers: newGenreOthers });
  };

  const onCharacterRepresentationChange = event => {
    let newCharacterRepresentation;
    const { name, checked } = event.target;
    setStatus({ fieldName: name, fieldInvalid: false });
    if (checked) {
      if (form.diversity.includes("NONE")) {
        newCharacterRepresentation = form.diversity.filter(
          identity => identity !== "NONE"
        );
        newCharacterRepresentation = [...newCharacterRepresentation, name];
      } else if (name === "NONE") {
        newCharacterRepresentation = ["NONE"];
      } else {
        newCharacterRepresentation = [...form.diversity, name];
      }
    } else {
      // Remove identity
      newCharacterRepresentation = form.diversity.filter(
        identity => identity !== name
      );
    }
    if (name === "OTHER" || (name === "NONE" && checked === true)) {
      setForm({
        ...form,
        diversity: newCharacterRepresentation,
        diversityOther: ""
      });
    } else {
      setForm({
        ...form,
        diversity: newCharacterRepresentation
      });
    }
  };

  if (isLoading && hasSubmitted) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }
  return (
    <div id="contest-form">
      <form>
        <div
          className={classNames("overflow-container", {
            disabled: isLoading
          })}
        >
          <ContestFormHeader
            subtitle={
              /* prettier-ignore */ trans("Thank you for your interest in The %s Watty Awards. In order to be considered for this year's awards, complete the following submission form.", CURRENT_WATTYS_YEAR)
            }
            closeModal={closeModal}
            isStandalonePage={isStandalonePage}
          />
          <div className="form-deadline">
            <div
              className="deadline-text"
              dangerouslySetInnerHTML={{
                /* prettier-ignore */ __html: trans("Submissions close August 6th, 2024 at 11:59:59 PM EDT. For full eligibility criteria, refer to the <a class='watty-regulation-link' href=%s target=\"_blank\" rel=\"noopener noreferrer\">Rules and Regulations</a>.", RULES_LINK)
              }}
            />
          </div>
          <div className="form-content">
            {!formIsOpen && (
              <div
                className="form-message-box"
                dangerouslySetInnerHTML={{
                  __html: /* prettier-ignore */ trans("The %s Watty Awards are now closed. Thanks for your participation!", CURRENT_WATTYS_YEAR)
                }}
              />
            )}
            {hasSubmitted && (
              <div
                className="form-message-box"
                dangerouslySetInnerHTML={{
                  __html: /* prettier-ignore */ trans("You are viewing your submission in The %s Watty Awards. Changes cannot be made.", CURRENT_WATTYS_YEAR)
                }}
              />
            )}
            {!hasSubmitted &&
              formIsOpen && (
                <>
                  <div
                    className="eligbility-requirements-title"
                    id="eligbility-requirements-title"
                    dangerouslySetInnerHTML={{
                      /* prettier-ignore */ __html: trans("Please confirm that you meet the <a href=%s target=\"_blank\" rel=\"noopener noreferrer\">eligibility requirements</a>. Stories that do not meet the eligibility requirements will be disqualified.", eligibilityCriteriaLink)
                    }}
                  />
                  <div
                    className="form-title"
                    id="form-title"
                    dangerouslySetInnerHTML={{
                      /* prettier-ignore */ __html: trans("We need a few more details about the story you are entering:<br><span class='bold'>“%s”</span>", formState.title)
                    }}
                  />
                </>
              )}

            <LanguageField form={form} storyId={storyId} />

            <OngoingStoryDeclarationField form={form} storyId={storyId} />

            <SeriesIdentificationField
              form={form}
              disableForm={disableForm}
              onSeriesIdentificationChange={onSeriesIdentificationChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />

            <AnticipatedStoryLengthField
              form={form}
              disableForm={disableForm}
              onFormChange={onFormChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />

            <CharacterRepresentationField
              form={form}
              disableForm={disableForm}
              fieldStatus={fieldStatus}
              submitted={submitted}
              onCharacterRepresentationChange={onCharacterRepresentationChange}
              onFormChange={onFormChange}
              identitiesLink={identitiesLink}
            />

            <StoryTypeField
              form={form}
              disableForm={disableForm}
              onFormChange={onFormChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />

            <PrimaryGenreIdentificationField
              form={form}
              disableForm={disableForm}
              onFormChange={onFormChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
              genreLink={genreLink}
              storyLanguage={form.language}
            />

            <SubsequentGenreIdentificationField
              form={form}
              disableForm={disableForm}
              onSubsequentGenreChange={onSubsequentGenreChange}
              storyLanguage={form.language}
            />

            <MatureThemesField
              form={form}
              disableForm={disableForm}
              isFrenchForm={isFrenchForm}
              onMatureThemesChange={onMatureThemesChange}
              requiredMatureThemesError={requiredMatureThemesError}
              fieldStatus={fieldStatus}
              submitted={submitted}
              onOtherMatureFieldTextChange={onOtherMatureFieldTextChange}
              onFormChange={onFormChange}
            />

            <LoglineField
              form={form}
              disableForm={disableForm}
              onFormChange={onFormChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />

            <PlotSummaryField
              form={form}
              disableForm={disableForm}
              onFormChange={onFormChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />

            <EmailField
              form={form}
              disableForm={disableForm}
              onPreferredEmailChange={onFormChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
              hasSubmitted={hasSubmitted}
              emailConfirmField={emailConfirmField}
              setEmailConfirmField={setEmailConfirmField}
            />

            <LegalConsentField
              form={form}
              disableForm={disableForm}
              onRadioBtnChange={onRadioBtnChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
            />
          </div>
          <div className="footer-section-submission">
            <div className="left-padding" />
            <FinalConsentField
              form={form}
              disableForm={disableForm}
              onRadioBtnChange={onRadioBtnChange}
              fieldStatus={fieldStatus}
              submitted={submitted}
              isStandalonePage={isStandalonePage}
              hasSubmitted={hasSubmitted}
              formIsOpen={formIsOpen}
              onSubmit={onSubmit}
              rulesLink={RULES_LINK}
            />
            <div className="left-padding" />
          </div>
        </div>
      </form>
    </div>
  );
}

WattysForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isStandalonePage: PropTypes.bool.isRequired,
  fetchContestForm: PropTypes.func,
  setContestForm: PropTypes.func,
  storyId: PropTypes.string,
  formIsOpen: PropTypes.bool,
  formState: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    hasSubmitted: PropTypes.bool.isRequired,
    cover: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    title: PropTypes.string,
    data: PropTypes.shape({
      storyId: PropTypes.number,
      contest: PropTypes.string,
      language: PropTypes.string.isRequired,
      storyStatus: PropTypes.string,
      isSeries: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      seriesType: PropTypes.string,
      isSeriesStandalone: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
      ]),
      numberOfStoriesInSeries: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      totalPlannedStoriesInSeries: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      anticipatedStoryLength: PropTypes.string,
      diversity: PropTypes.arrayOf(PropTypes.string).isRequired,
      diversityOther: PropTypes.string,
      storyType: PropTypes.string.isRequired,
      genrePrimary: PropTypes.string.isRequired,
      genreOthers: PropTypes.arrayOf(PropTypes.string),
      matureThemes: PropTypes.arrayOf(PropTypes.string).isRequired,
      matureThemeOther: PropTypes.string,
      storyMaturity: PropTypes.string,
      logLine: PropTypes.string.isRequired,
      plotSummary: PropTypes.string.isRequired,
      legalConsent: PropTypes.bool.isRequired,
      writerEmail: PropTypes.string,
      finalConsent: PropTypes.bool
    })
  })
};

WattysForm.defaultProps = {
  formIsOpen: new Date().getTime() < WATTYS_CLOSING_DATETIME
};
