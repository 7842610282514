import PropTypes from "prop-types";
import React from "react";
import { FormField, Icon } from "../../shared-components";
import { useTrans, useFeatureFlags } from "../../hooks";
import { Button, ButtonVariant, IconNames } from "@wattpad/web-ui-library";

// Composed components

const EmailLogin = ({ msg, nextUrl = "/home", notice, toggleFormView }) => {
  const actionURL = `/login?nextUrl=${nextUrl}`;
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  return (
    <>
      {featureFlags.NEW_ONBOARDING_1 && (
        <Button
          variant={ButtonVariant.TERTIARY}
          label={trans("Back to all login options")}
          onClick={toggleFormView}
          fullWidth
          className="back-link"
          leadingIcon={IconNames.WpChevronLeft}
        />
      )}
      <form id="login-form" action={actionURL} method="POST">
        {featureFlags.NEW_ONBOARDING_1 ? (
          <>
            <div className="input-group-vertical">
              <FormField
                name="username"
                label={trans("Email or username")}
                title={trans("Email or username")}
                form="login"
                inputType="text"
                showLabel={true}
                autofocus
              />
              <FormField
                name="password"
                label={trans("Password")}
                title={trans("Password")}
                form="login"
                inputType="password"
                showVisibilityToggle={true}
                showLabel={true}
              />
            </div>
            <Button
              className={"footer-button-margin"}
              label={trans("Log in")}
              fullWidth
            />
          </>
        ) : (
          <>
            <div className="input-group-vertical">
              <FormField
                name="username"
                label={trans("Username or Email")}
                form="login"
                inputType="text"
                showLabel={true}
                autofocus
              />
              <FormField
                name="password"
                label={trans("Password")}
                form="login"
                inputType="password"
                showVisibilityToggle={true}
                showLabel={true}
              />
            </div>
            <input
              type="submit"
              className="btn btn-lg btn-primary btn-block enable submit-btn"
              value={trans("Log in")}
            />
          </>
        )}
      </form>
      {featureFlags.NEW_ONBOARDING_1 ? (
        <Button
          variant={ButtonVariant.TERTIARY}
          label={trans("Forgot password?")}
          onClick={() => (window.location.href = `/forgot?nextUrl=${nextUrl}`)}
          fullWidth
        />
      ) : (
        <div className="password-forgot">
          <a href={`/forgot?nextUrl=${nextUrl}`}>{trans("Forgot password?")}</a>
        </div>
      )}

      {msg && (
        <div className="alert alert-danger" role="alert">
          <Icon iconName="fa-info" height="16" color="wp-lighterror" />
          {msg}
        </div>
      )}
      {notice && (
        <div className="alert alert-info" role="alert">
          <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
          {notice}
        </div>
      )}
      {!featureFlags.NEW_ONBOARDING_1 && (
        <div className="back-link-container">
          <button className="back-link" onClick={toggleFormView}>
            <span className="fa fa-left fa-wp-neutral-1 back-icon" />
            {trans("Back to all login options")}
          </button>
        </div>
      )}
    </>
  );
};

EmailLogin.propTypes = {
  msg: PropTypes.string,
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  toggleFormView: PropTypes.func
};

export default EmailLogin;
