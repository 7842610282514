import _ from "lodash";

import { pixelRatio } from "./pixelRatio";

const resize = (
  collection,
  url,
  width,
  re,
  correctionBase,
  devicePixelRatio,
  matchMedia,
  msMatchMedia
) => {
  let newWidth,
    correction = correctionBase ? correctionBase[0] : false;

  if (
    !collection ||
    !url ||
    typeof url !== "string" ||
    !re ||
    !isFinite(String(width))
  ) {
    return "";
  }

  if (pixelRatio(2.5, devicePixelRatio, matchMedia, msMatchMedia)) {
    width *= 3;
    correction = correctionBase ? correctionBase[2] : false;
    correction = correctionBase ? correctionBase[1] : correctionBase;
  } else if (pixelRatio(1.5, devicePixelRatio, matchMedia, msMatchMedia)) {
    width *= 2;
    correction = correctionBase ? correctionBase[1] : false;
  }

  newWidth = _.find(collection, function(size) {
    return (
      size + (correction !== false ? Math.floor(size / correction) : 0) >= width
    );
  });

  if (!newWidth) {
    newWidth = _.reduce(collection, function(prev, current) {
      return Math.abs(current - width) < Math.abs(prev - width)
        ? current
        : prev;
    });
  }

  return url.replace(re, "$1" + newWidth + "$3$4");
};

export { resize };
