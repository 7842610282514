import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { formatStoryUrl, fromNow } from "../helpers";
import { useTrans } from "../hooks";
import { Icon } from ".";

const StoryParts = ({
  storyId,
  sources,
  parts,
  isPaywalled,
  readingPosition,
  className
}) => {
  const onStoryPartClick = (evt, partId, partLink) => {
    evt.preventDefault();
    // Story click event
    window.te.push("event", "story", null, null, "click", {
      storyid: storyId,
      page: "story_details",
      type: "primary",
      source: "story_details",
      partid: partId,
      algo_source: sources || ""
    });
    app.router.navigate(partLink, {
      trigger: true
    });
  };
  const { trans } = useTrans();

  return (
    <div className={classNames("story-parts", className)}>
      <ul>
        {parts &&
          parts.map(part => {
            const isActivelyReadingPart =
              readingPosition && readingPosition.part === part.id;

            return (
              <li
                key={part.id}
                className={classNames({
                  "is-bonus-part": part.isBonusPart,
                  "is-scheduled": part.isScheduledPart
                })}
              >
                <a
                  href={part.url && formatStoryUrl(part.url)}
                  className={classNames("story-parts__part", {
                    "currently-reading": isActivelyReadingPart,
                    "new-part": part.new && !part.isBonusPart,
                    "bonus-part": part.isBonusPart,
                    "draft-part": part.draft,
                    "deleted-part": part.deleted
                  })}
                  onClick={evt =>
                    onStoryPartClick(evt, part.id, formatStoryUrl(part.url))
                  }
                >
                  <div className="left-container">
                    {part.isBonusPart && (
                      <div className="part__bonus-type">
                        {part.bonusTypeName}
                      </div>
                    )}
                    <div className="part__label">
                      <div className="icon-container">
                        {part.new && (
                          <Icon
                            iconName="fa-circle-filled"
                            height="8"
                            color="ds-success"
                          />
                        )}
                      </div>
                      <div className="part-title">{part.title}</div>
                    </div>
                    <div className="part-meta">
                      {part.draft &&
                        (part.isScheduledPart ? (
                          <span>
                            {trans("Scheduled")} -{" "}
                            <span className="scheduled-date">
                              {part.formattedScheduledPublishDatetime}
                            </span>
                          </span>
                        ) : (
                          <span className="draft-tag">{trans("Draft")}</span>
                        ))}
                      {part.deleted && (
                        <span className="deleted-tag">{trans("Deleted")}</span>
                      )}
                    </div>
                    {isActivelyReadingPart && (
                      <div className="part__reading-progress">
                        {trans(
                          "%s%% Complete",
                          (readingPosition.position * 100).toFixed(0)
                        )}
                      </div>
                    )}
                  </div>
                  <div className="right-label">
                    {fromNow(part.createDate, {
                      dayFormat: true
                    })}
                    {/* Render icon for all parts, but set visibility:none for parts that are not blocked, so that all story parts maintain the same alignment */}
                    {!isActivelyReadingPart &&
                      isPaywalled &&
                      part.isBlocked && (
                        <Icon
                          className="part__icon"
                          name="lock"
                          size="24"
                          color={
                            !part.isBonusPart
                              ? "ds-neutral-100"
                              : "ds-base-2-60"
                          }
                          title={part.isBlocked ? trans("Locked") : null}
                        />
                      )}
                  </div>
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

StoryParts.propTypes = {
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string
    })
  ).isRequired,
  storyId: PropTypes.number,
  sources: PropTypes.array,
  isPaywalled: PropTypes.bool.isRequired,
  readingPosition: PropTypes.shape({
    part: PropTypes.number,
    position: PropTypes.oneOfType([
      // API returns 0 or "n"
      PropTypes.string,
      PropTypes.number
    ])
  }),
  className: PropTypes.string
};

export default StoryParts;
