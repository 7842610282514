import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";
import { CommentCard } from "@wattpad/web-ui-library";

import {
  useTrans,
  usePopover,
  useGetUser,
  useUserVerification
} from "../../../hooks";
import CommentOptions from "../CommentOptions/CommentOptions";
import { count, linkify } from "../../../helpers";
import {
  addLike,
  getIsLiked,
  getLikesCount,
  getPills,
  isStoryPart,
  removeLike,
  toPostedDate,
  canDeleteComment,
  getReplyLimit,
  getUserBadges
} from "../CommentsUtils";
import CommentsList from "../CommentsList/CommentsList";
import {
  CommentItemDropdownProps,
  CommentsLocation,
  ScrollSmoothCenter
} from "../CommentsConstants";
import PostNewComment from "../PostNewComment/PostNewComment";

const CommentContainer = ({
  comment,
  mainLocation,
  storyAuthor,
  onReplyPosted,
  onDeleteComment,
  sentimentEventData,
  onUpdateCount
}) => {
  const {
    user: author,
    isReply,
    isOffensive,
    commentId: commentResource,
    sentiments
  } = comment;

  const user = useGetUser();
  const { trans, ngettext } = useTrans();
  const { handleOpenPopover, handleClosePopover } = usePopover();
  const { userNeedsVerification } = useUserVerification();

  const triggerRef = useRef();
  const autofocusRef = useRef();
  const addNewCommentRef = useRef();

  const [isLiked, setIsLiked] = useState(getIsLiked(sentiments));
  const [likesCount, setLikesCount] = useState(getLikesCount(sentiments));
  const [replyCount, setReplyCount] = useState(comment.replyCount);
  const [showReplies, setShowReplies] = useState(!!comment.replyData);
  const [showCommentTextBox, setShowCommentTextBox] = useState(false);

  const commentId = commentResource.resourceId;
  const isStoryAuthor = storyAuthor === author.name;
  const canMute = user.username && user.username !== author.name;
  const offensiveText = isOffensive && trans("This comment may be offensive.");
  const initialReply = comment.replyData && { commentData: comment.replyData };

  const replyResource = {
    commentId,
    namespace: CommentsLocation.REPLIES
  };

  const pushSentimentEvent = action => {
    const eventData = { ...sentimentEventData, commentId: commentId };

    window.te.push("event", "sentiment", null, null, action, eventData);
  };

  const triggerAutoFocus = () => {
    const shouldFocus = comment.isNewComment || comment.isDeeplink;

    if (shouldFocus && autofocusRef.current) {
      autofocusRef.current.scrollIntoView(ScrollSmoothCenter);
    }
  };

  useEffect(triggerAutoFocus, []);

  const updateIsLiked = liked => {
    if (liked) {
      setIsLiked(true);
      setLikesCount(count => count + 1);
    } else {
      setIsLiked(false);
      setLikesCount(count => count - 1);
    }
  };

  const handleLikeClick = () => {
    if (userNeedsVerification()) return;

    if (isLiked) {
      updateIsLiked(false);
      removeLike(commentResource)
        .then(() => sentimentEventData && pushSentimentEvent("remove"))
        .catch(() => updateIsLiked(true));
    } else {
      updateIsLiked(true);
      addLike(commentResource)
        .then(() => sentimentEventData && pushSentimentEvent("add"))
        .catch(() => updateIsLiked(false));
    }
  };

  const handleUpdateCount = difference => {
    onUpdateCount?.(difference);
    setReplyCount(count => count + difference);
  };

  const handleDeleteComment = () => {
    handleClosePopover(triggerRef);
    onDeleteComment(commentId, replyCount);
  };

  const handleOpenCommentItemOptions = event => {
    event.preventDefault();

    const component = () => {
      return (
        <CommentOptions
          canMute={canMute}
          commentBody={comment.text}
          deeplink={comment.deeplink}
          commentAuthor={author.name}
          canDeleteData={canDeleteComment(user, author, storyAuthor)}
          onDeleteComment={handleDeleteComment}
        />
      );
    };

    handleOpenPopover(
      component,
      triggerRef,
      `${CommentItemDropdownProps.CLASS} ${
        isStoryPart(mainLocation) ? "sp" : "pa"
      }`,
      CommentItemDropdownProps.PROPS
    );
  };

  const handleOnCommentPosted = newComment => {
    if (isReply && onReplyPosted) {
      // When posting a reply of a reply, we let the parent list know that a new comment was added
      onReplyPosted(newComment);
    } else {
      // When posting a reply, we should add a new comment in the replies list
      addNewCommentRef.current(newComment);
    }
    setShowCommentTextBox(false);
  };

  const actionLike = {
    ariaLabel: isLiked
      ? trans("Unlike this comment")
      : trans("Like this comment"),
    onClick: handleLikeClick,
    displayText: likesCount > 0 && count(likesCount)
  };

  const actionDropdown = {
    ariaLabel: trans("More options"),
    outsideRef: triggerRef,
    onClick: handleOpenCommentItemOptions
  };

  const actionReply = {
    onClick: () => {
      setShowReplies(true);
      setShowCommentTextBox(true);
    },
    ariaLabel: "Reply to comment",
    displayText: trans("Reply")
  };

  const actionViewMoreReplies = replyCount > 0 &&
    !showReplies && {
      onClick: () => setShowReplies(true),
      ariaLabel: "View replies",
      displayText: sprintf(
        ngettext("View %s Reply", "View %s Replies", replyCount),
        replyCount
      )
    };

  return (
    <div className="comment-card-container">
      <div ref={autofocusRef}>
        <CommentCard
          isLiked={isLiked}
          isReplyComment={isReply}
          offensiveText={offensiveText}
          content={linkify(comment.text, { sanitization: "sanitize" })}
          isDeeplink={comment.isDeeplink}
          commentAuthorName={author.name}
          commentAuthorAvatar={author.avatar}
          isNewComment={comment.isNewComment}
          badges={getUserBadges(author)}
          isStoryAuthor={isStoryAuthor}
          pills={getPills(isStoryAuthor, trans)}
          readMoreText={trans("Read more")}
          postedDate={toPostedDate(trans, ngettext, comment.created)}
          actionLike={actionLike}
          actionReply={actionReply}
          actionDropdown={actionDropdown}
          actionViewMoreReplies={actionViewMoreReplies}
        />
      </div>
      {showCommentTextBox && (
        <PostNewComment
          autofocus
          resource={replyResource}
          onCommentPosted={handleOnCommentPosted}
          replyToUsername={isReply ? author.name : undefined}
          placeholder={trans("Write a reply...")}
        />
      )}
      {showReplies && (
        <CommentsList
          location={CommentsLocation.REPLIES}
          parentLocation={mainLocation}
          commentId={commentId}
          storyAuthor={storyAuthor}
          sentimentEventData={sentimentEventData}
          addNewCommentRef={addNewCommentRef}
          onUpdateCount={handleUpdateCount}
          initialComment={initialReply}
          initialLimit={getReplyLimit(showCommentTextBox, replyCount)}
        />
      )}
    </div>
  );
};

CommentContainer.propTypes = {
  comment: PropTypes.object.isRequired,
  mainLocation: PropTypes.oneOf(Object.values(CommentsLocation)),
  storyAuthor: PropTypes.string,
  sentimentEventData: PropTypes.object,
  onReplyPosted: PropTypes.func,
  onDeleteComment: PropTypes.func.isRequired,
  onUpdateCount: PropTypes.func
};

export default CommentContainer;
