/**
 * Schedules a part to be published at a specified time on a specified date
 *
 * @param {StoryPartCreateModel} partModel
 * @param {String} publishDate
 * @returns {String[]|Object} Returns an array of errors, or a result object
 */

module.exports = (partModel, publishDate) => {
  return new Promise((resolve, reject) => {
    if (!publishDate) {
      return reject([wattpad.utils.trans("Publish date is required")]);
    }

    const storyId = parseInt(partModel.get("groupid"), 10);
    const partId = parseInt(partModel.get("id"), 10);
    const defaultError = wattpad.utils.trans("Failed to schedule part");

    Promise.resolve(partModel.save())
      .then(result => {
        if (result?.error_count > 0) {
          if (result?.error) {
            return reject(result.error);
          }
          if (result?.errors.length > 0) {
            return reject(result.errors[0]);
          }
          return reject(defaultError);
        }

        return Promise.resolve(
          $.post(`/v4/parts/${partId}/schedule-publish`, {
            datetime: publishDate
          })
        );
      })
      .then(result => {
        if (!result?.part_id || !result?.scheduled_datetime) {
          return reject(defaultError);
        }

        const currentUsername = wattpad.utils.currentUser().get("username");
        wattpad.utils.cacheBust(
          ["StoryCreateModel", "StoryPartCreateModel"],
          [
            {
              id: storyId
            },
            { id: partId }
          ],
          null,
          true
        );
        wattpad.utils.cacheBust(
          ["Works", "Works"],
          [
            { username: currentUsername },
            { username: currentUsername, published: true }
          ]
        );

        return resolve(result);
      })
      .catch(e => {
        if (!e || e?.status === 200) {
          return reject(defaultError);
        }
        if (e?.responseJSON?.message) {
          return reject(e?.responseJSON?.message);
        } else if (e?.responseJSON?.errors.length > 0) {
          return reject(e?.responseJSON?.errors[0]);
        }
      });
  });
};
