import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class TagItem extends React.Component {
  render() {
    let style =
      this.props.bgColor || this.props.color
        ? {
            style: {
              backgroundColor: this.props.bgColor,
              color: this.props.color
            }
          }
        : {};

    if (this.props.active && this.props.bgColor) {
      style = {
        style: {
          color: this.props.bgColor,
          backgroundColor: "#FFFFFF",
          borderColor: this.props.bgColor
        }
      };
    }

    return this.props.disableClick ? (
      <div className="tag-item" {...style}>
        {this.props.name}
      </div>
    ) : (
      <a
        className={classnames("tag-item", { active: this.props.active })}
        {...style}
        onClick={evt => this.props.clickHandler(evt, this.props.name)}
        href={this.props.link || `/stories/${this.props.name}`}
      >
        {this.props.name}
      </a>
    );
  }
}

TagItem.defaultProps = {
  clickHandler: () => {}
};

TagItem.propTypes = {
  clickHandler: PropTypes.func,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  disableClick: PropTypes.bool
};
