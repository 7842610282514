import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { linkClicked } from "./helpers";
import { LINKS_SECTIONS } from "./constants";
import { ConnectImage } from "../../shared-components";
import { injectTrans } from "../../helpers";

class Links extends Component {
  render() {
    let { trans } = this.props;
    return (
      <div id="Links">
        {LINKS_SECTIONS(trans).map(section => {
          return (
            <div id={section.id} key={section.id}>
              <div className="container">
                <h3>{section.title}</h3>
                <div className="links">
                  {section.links.map(meta => {
                    return <Link {...meta} key={meta.id} />;
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  static propTypes = {
    mobile: PropTypes.bool,
    trans: PropTypes.func.isRequired
  };
}
export default injectTrans(Links);

const Link = ({ name, description, img, url, id, className }) => {
  return (
    <div
      className={classNames("link", className)}
      onClick={() => {
        linkClicked(id, url);
      }}
      onKeyDown={() => {
        linkClicked(id, url);
      }}
      role="link"
      tabIndex="0"
    >
      <div className="image">
        <ConnectImage name={img} title={name} />
      </div>
      <div className="info">
        <h4>{name}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

Link.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string
};
