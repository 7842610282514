import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, ButtonVariant, IconNames } from "@wattpad/web-ui-library";
import {
  fetchComment,
  fetchComments,
  deleteComment,
  CommentsNamespaces
} from "@wattpad/client-platform-comments";

import {
  CommentsLocation,
  InitialCommentPropType,
  AdsInCommentContextPropType
} from "../CommentsConstants";
import {
  isReply,
  isStoryPart,
  injectAdsInComments,
  isCommentInStoryPart
} from "../CommentsUtils";
import CommentContainer from "../CommentContainer/CommentContainer";
import PostNewComment from "../PostNewComment/PostNewComment";
import { ConnectImage, Icon } from "../../../shared-components";
import AdContainer from "../../../shared-components/AdContainer";
import CommentsEmptyStage, {
  CommentsStageType
} from "../EmptyStage/CommentsEmptyStage";
import { useMuteUsers, useInfiniteScroll, useTrans } from "../../../hooks";

const CommentsList = ({
  location,
  parentLocation,
  partId,
  paragraphId,
  commentId,
  storyAuthor,
  initialLimit,
  initialComment,
  scrollableRef,
  addNewCommentRef,
  sentimentEventData,
  onUpdateCount,
  onCommentsChanged,
  adData
}) => {
  const { trans } = useTrans();
  const isReplyList = isReply(location);

  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(initialLimit !== 0);
  const [afterComment, setAfterComment] = useState(undefined);
  const [showGoBack, setShowGoBack] = useState(
    !isReplyList && !!initialComment
  );

  const isEmptyComments = comments.length === 0;
  const isPostCommentVisible = !isReplyList && !hasError && !showGoBack;

  const mainResource = {
    namespace: location,
    partId: partId?.toString(),
    paragraphId: paragraphId,
    commentId: commentId
  };

  const getNextComments = (limit, after) => {
    setIsLoading(true);
    fetchComments(mainResource, after, limit)
      .then(data => {
        setComments(cc => [...cc, ...data.comments]);
        setHasMore(data.hasMore);
        setAfterComment(data.after);
        setHasError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
        setIsNotFound(false);
      });
  };

  const handleDeleteComment = (commentId, replyCount) => {
    deleteComment(commentId)
      .then(() => {
        onUpdateCount?.(-(replyCount + 1));
        setComments(comments.filter(c => c.commentId.resourceId !== commentId));
      })
      .catch(() => {
        wattpad.utils.showToast(
          trans("Sorry, something went wrong. Please try again."),
          { type: "dismissable" }
        );
      });
  };

  const handleOnCommentPosted = newComment => {
    onUpdateCount?.(1);
    setComments(currentComments => [newComment, ...currentComments]);
  };

  const loadSingleComment = (comment, parentComment) => {
    const newComment = parentComment || comment;
    if (!isCommentInStoryPart(newComment.resource, partId, paragraphId)) {
      handleLoadSingleCommentError();
      return;
    }
    if (parentComment) {
      newComment.replyData = comment;
      newComment.replyData.isDeeplink = true;
    } else {
      newComment.isDeeplink = true;
    }
    setComments([newComment]);
    getNextComments(initialLimit, newComment.commentId);
  };

  const handleLoadSingleCommentError = () => {
    setHasError(false);
    setIsLoading(false);
    setIsNotFound(true);
  };

  const getSingleComment = () => {
    setIsLoading(true);
    fetchComment(initialComment)
      .then(comment => {
        // if there is a parent comment to fetch
        if (comment.resource.namespace === CommentsNamespaces.COMMENTS) {
          fetchComment(comment.resource)
            .then(parentComment => loadSingleComment(comment, parentComment))
            .catch(handleLoadSingleCommentError);
        } else {
          loadSingleComment(comment);
        }
      })
      .catch(handleLoadSingleCommentError);
  };

  const initializeList = () => {
    if (initialComment?.commentData) {
      setComments([initialComment.commentData]);
      getNextComments(2, initialComment.commentData.commentId);
    } else if (initialComment?.resourceId) {
      getSingleComment(initialComment);
    } else if (initialLimit !== 0) {
      /* Only load comments when the initial limit undefined or higher than 0 */
      getNextComments(initialLimit);
    }

    /* This ref allows to add new comments in the List from outside */
    if (addNewCommentRef) {
      addNewCommentRef.current = handleOnCommentPosted;
    }
  };

  const filterOutMutedUser = username => {
    setComments(comments.filter(c => c.user.name !== username));
  };

  const handleShowMoreComments = () => {
    getNextComments(undefined, afterComment);
  };

  const handleGoBackToAllComments = () => {
    setShowGoBack(false);
    setComments([]);
    setHasMore(false);
    setAfterComment(undefined);
    getNextComments();
  };

  const notifyCommentsChanged = () => onCommentsChanged?.();

  useMuteUsers(filterOutMutedUser);
  useEffect(initializeList, []);
  useEffect(notifyCommentsChanged, [comments]);

  useInfiniteScroll({
    hasMore,
    scrollableRef,
    scrollOffset: 100,
    isDisabled: hasError || isLoading,
    onLoadMore: useCallback(handleShowMoreComments, [
      comments,
      hasMore,
      afterComment
    ])
  });

  const showMoreOrLoading = showMoreContent => {
    if (isLoading || (hasError && !isEmptyComments)) {
      return (
        <div className="loader-animation-wrapper">
          <ConnectImage name="icons/loader-light.svg" id="loader-light" />
        </div>
      );
    } else if (hasMore && !scrollableRef) {
      return showMoreContent;
    }
  };

  const showCommentsEmptyStage = () => {
    if (!isReplyList && !isLoading && isEmptyComments && !hasMore) {
      let stageType = CommentsStageType.EMPTY;

      if (hasError) {
        stageType = CommentsStageType.ERROR;
      } else if (isNotFound) {
        stageType = CommentsStageType.NOT_FOUND;
      }

      return (
        <div>
          {stageType === CommentsStageType.EMPTY &&
            !isStoryPart(location) && (
              <AdContainer
                placement={adData.placement}
                adData={adData}
                wrapperClassName="advertisement-wrapper"
                className="comment-ad"
              />
            )}
          <CommentsEmptyStage
            stageType={stageType}
            onRetryFetch={getNextComments}
          />
        </div>
      );
    }
  };

  const getComments = () => {
    return (isReplyList ? [...comments].reverse() : comments).map(c => ({
      isAd: false,
      comment: c
    }));
  };

  const getCommentsWithAds = () => {
    if (isEmptyComments) {
      return comments;
    } else if (adData) {
      return injectAdsInComments(getComments());
    } else {
      return getComments();
    }
  };

  const commentsToRender = useMemo(getCommentsWithAds, [comments]);

  return (
    <div className="comments-list">
      {showGoBack && (
        <div className="go-back">
          <div className="info">
            <Icon iconName="fa-info" height="13" color="wp-neutral-1" />
            {trans("You are viewing a single comment thread.")}
          </div>
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={handleGoBackToAllComments}
            leadingIcon={IconNames.WpChevronLeft}
            label={trans("Return to all comments")}
          />
        </div>
      )}
      {isPostCommentVisible && (
        <PostNewComment
          resource={mainResource}
          isSticky={!isStoryPart(location)}
          onCommentPosted={handleOnCommentPosted}
        />
      )}
      {isReplyList &&
        showMoreOrLoading(
          <span className="show-more-replies-wrapper">
            <hr className="show-more-replies-hr" />
            <button
              className="show-more-replies-btn"
              onClick={handleShowMoreComments}
            >
              {trans("View more replies")}
            </button>
            <hr className="show-more-replies-hr" />
          </span>
        )}
      {commentsToRender.map(
        item =>
          item.isAd ? (
            <div className="" key={`${location}-comment-ad-${item.id}`}>
              <AdContainer
                placement={adData.placement}
                adData={adData}
                wrapperClassName="advertisement-wrapper"
                className="comment-ad"
              />
            </div>
          ) : (
            <CommentContainer
              key={`${location}-${item.comment.commentId.resourceId}`}
              comment={item.comment}
              mainLocation={parentLocation || location}
              storyAuthor={storyAuthor}
              onDeleteComment={handleDeleteComment}
              onReplyPosted={handleOnCommentPosted}
              sentimentEventData={sentimentEventData}
              onUpdateCount={onUpdateCount}
            />
          )
      )}
      {!isReplyList &&
        showMoreOrLoading(
          <div className="show-more-btn">
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={handleShowMoreComments}
              fullWidth={true}
              label={trans("Show more")}
            />
          </div>
        )}
      {showCommentsEmptyStage()}
    </div>
  );
};

CommentsList.propTypes = {
  location: PropTypes.oneOf(Object.values(CommentsLocation)).isRequired,
  partId: PropTypes.number,
  commentId: PropTypes.string,
  paragraphId: PropTypes.string,
  storyAuthor: PropTypes.string,
  parentLocation: PropTypes.oneOf(Object.values(CommentsLocation)),
  sentimentEventData: PropTypes.object,
  initialLimit: PropTypes.number,
  initialComment: InitialCommentPropType,
  scrollableRef: PropTypes.object,
  addNewCommentRef: PropTypes.object,
  onUpdateCount: PropTypes.func,
  onCommentsChanged: PropTypes.func,
  adData: AdsInCommentContextPropType
};

export default CommentsList;
