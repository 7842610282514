import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../hooks";

import CoinIcon from "./CoinIcon";

export function PurchaseConfirmationModal({
  onPurchase,
  onCancelPurchase,
  toggleModal,
  currentPartIsBlocked,
  price,
  isPurchasingStory
}) {
  const { trans } = useTrans();

  const onCancel = () => {
    onCancelPurchase();
    toggleModal();
  };

  const onUnlock = () => {
    toggleModal();
    onPurchase();
  };

  let unlockCopy, ctaCopy;

  if (isPurchasingStory) {
    unlockCopy = trans("Unlock whole story");
    ctaCopy = trans("You are about to use %s Coins to unlock this story", price); // prettier-ignore
  } else {
    if (currentPartIsBlocked) {
      unlockCopy = trans("Unlock this part");
      ctaCopy = trans("You are about to use %s Coins to unlock this part", price); // prettier-ignore
    } else {
      unlockCopy = trans("Unlock next part");
      ctaCopy = trans("You are about to use %s Coins to unlock the next part", price); // prettier-ignore
    }
  }

  return (
    <React.Fragment>
      <h3>{ctaCopy}</h3>
      <div className="purchase-options">
        <button onClick={onCancel} className="btn btn-grey cancel">
          {trans("Cancel")}
        </button>
        <button onClick={onUnlock} className="btn btn-orange confirm-unlock">
          <CoinIcon width="24px" height="24px" />
          <span>{unlockCopy}</span>
        </button>
      </div>
    </React.Fragment>
  );
}

PurchaseConfirmationModal.propTypes = {
  onPurchase: PropTypes.func.isRequired,
  onCancelPurchase: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  currentPartIsBlocked: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  isPurchasingStory: PropTypes.bool.isRequired
};
