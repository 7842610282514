import _ from "lodash";

const count = value => {
  // if readcount isn't available (server issue), render a dash instead of an incorrect number
  if (value == -1) {
    return "-";
  }
  if (!value || !_.isNumber(value)) {
    return 0;
  }
  if (value >= 99999999) {
    return Math.floor(value / 1000000) + "M";
  } else if (value >= 1000000) {
    return Math.floor(value / 100000) / 10 + "M";
  } else if (value >= 99999) {
    return Math.floor(value / 1000) + "K";
  } else if (value >= 1000) {
    return Math.floor(value / 100) / 10 + "K";
  } else {
    return value;
  }
};

export { count };
