import React, { isValidElement } from "react";
import PropTypes from "prop-types";

const ContestFormField = ({ title, subtitle, children }) => {
  return (
    <div className="inline-row">
      {isValidElement(title) ? title : <h4>{title}</h4>}
      {subtitle}
      {children}
    </div>
  );
};

ContestFormField.defaultProps = {
  customTitle: null
};

ContestFormField.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.object,
  children: PropTypes.node
};

export default ContestFormField;
