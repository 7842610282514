import React, { Component } from "react";
import { Icon } from "../../shared-components";
import PropTypes from "prop-types";

export default class Search extends Component {
  render() {
    let { trans } = this.props;
    return (
      <form
        id="header-item-search"
        name="search"
        role="search"
        action="/search/"
        method="GET"
        autoComplete="off"
      >
        <button type="submit">
          <Icon iconName="fa-search" color="wp-neutral-1" height="16" />
        </button>
        <input
          id="search-query"
          name="q"
          type="text"
          spellCheck="false"
          placeholder={trans("Search")}
          aria-label="Search"
        />
        <input type="hidden" name="ref" value="1" />
      </form>
    );
  }

  static propTypes = {
    trans: PropTypes.func.isRequired
  };
}
