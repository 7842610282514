import React from "react";

import { useTrans } from "../../../hooks";
import OnboardingTooltip from "./OnboardingTooltip";

function WriteTooltip() {
  const { trans } = useTrans();
  return (
    <OnboardingTooltip
      title={trans("Interested in writing?")}
      text={trans(
        "Write your first story or edit your published stories here."
      )}
      className="write-tooltip"
    />
  );
}

export default WriteTooltip;
