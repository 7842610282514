import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePopper } from "react-popper";
import LibraryPopover from "../../components/views/home/components/LibraryPopover";
import { Icon } from "./";
import { useTrans } from "../hooks";

import { useDispatch } from "react-redux";
import { toggleModal } from "./modals/actions";
import ReadingListModal from "./ReadingListModal";

/**
 * The story actions button.
 * Displays read and add to library prompt.
 */
const StoryActions = ({
  className,
  draft = false,
  isLoggedInUser,
  isPaid,
  isPaidPreview,
  isPaidStory,
  firstPartId,
  storyId,
  readingLists,
  storiesCollections,
  sources,
  isMobile
}) => {
  const dispatch = useDispatch();
  const showReadingListModal = () => {
    dispatch(
      toggleModal({
        // buttonRef is the past checkpoint before modal opened.
        // We hold onto this to return keyboard focus to its original location when the user is finished interacting with the modal.

        // eslint-disable-next-line react/display-name
        component: () => (
          <ReadingListModal
            beforeModalRef={buttonRef.current}
            storyId={storyId}
          />
        ),
        className: "reading-list-modal-container",
        useGlobalModalContainer: true
      })
    );
  };

  const [showPopover, setShowPopover] = useState(false);
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);
  const { trans } = useTrans();

  const onClickLibraryBtn = () => {
    if (isMobile) {
      showReadingListModal();
    } else {
      setShowPopover(!showPopover);
      showLibraryPopover();
    }
  };

  const onStoryClick = evt => {
    evt.preventDefault();
    // Story click event
    window.te.push("event", "story", null, null, "click", {
      storyid: storyId,
      page: "story_details",
      type: "primary",
      source: "story_details",
      algo_source: sources || ""
    });
    app.router.navigate("/" + firstPartId, {
      trigger: true
    });
  };

  const showLibraryPopover = () => {
    return (
      <LibraryPopover
        readingLists={readingLists}
        storiesCollections={storiesCollections[storyId]}
        storyId={parseInt(storyId)}
        className={"story-details-add-to-library"}
        // TODO: Remove when new story details is merged
        fromStoryDetails={true}
        setShowPopover={setShowPopover}
      />
    );
  };

  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      modifiers: [
        {
          name: "arrow",
          options: {
            element: arrowRef
          }
        },
        {
          name: "offset",
          options: {
            offset: [0, 10]
          }
        }
      ]
    }
  );

  const ReadButton = ({ partId, children }) => {
    return (
      <a
        className="btn-primary read-btn"
        href={"/" + partId}
        onClick={evt => onStoryClick(evt)}
      >
        {children}
      </a>
    );
  };

  return (
    <div className={classNames("story-actions", className)}>
      {showPopover &&
        typeof window !== "undefined" &&
        ReactDOM.createPortal(
          <div ref={popperRef} style={styles.popper} {...attributes.popper}>
            {showLibraryPopover()}
            <div ref={setArrowRef} style={styles.arrow} />
          </div>,
          document.querySelector("#react-popover")
        )}
      <ReadButton partId={firstPartId}>
        {!isPaid &&
          !isPaidPreview &&
          !isPaidStory && (
            <Icon name="bookOpenBeta" size="16" color="ds-neutral-00-solid" />
          )}
        {trans("Start reading")}
      </ReadButton>
      <button
        ref={buttonRef}
        className="btn-primary add-to-library-btn"
        onClick={onClickLibraryBtn}
        disabled={!isLoggedInUser || draft}
        title={trans("Add story to...")}
        aria-label={trans("Add story to...")}
      >
        <Icon name="add" size="24" />
      </button>
    </div>
  );
};
StoryActions.propTypes = {
  className: PropTypes.string,
  draft: PropTypes.bool,
  isLoggedInUser: PropTypes.bool,
  isPaid: PropTypes.bool,
  isPaidPreview: PropTypes.bool,
  isPaidStory: PropTypes.bool,
  firstPartId: PropTypes.number,
  storyId: PropTypes.number,
  readingLists: PropTypes.array,
  storiesCollections: PropTypes.object,
  isMobile: PropTypes.bool,
  partId: PropTypes.number,
  children: PropTypes.node,
  sources: PropTypes.array
};

export default StoryActions;
