import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { useTrans, useFeatureFlags } from "../../hooks";

import { ConnectImage, Icon, Modal, MODAL_ID } from "../../shared-components";
import { Button, ButtonVariant, IconNames } from "@wattpad/web-ui-library";
import formatScheduledPartDate from "../../../helpers/format-scheduled-part-date";

const ScheduleSuccessModal = ({
  storyId,
  partId,
  title,
  scheduledPublishDatetime,
  showModal,
  onClose
}) => {
  const modalRef = useRef();
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  const isPublishingToday = moment().isSame(
    moment(scheduledPublishDatetime),
    "day"
  );

  const handleChangeSchedule = () => {
    modalRef.current.hide();

    window?.wattpad?.utils.redirectWithParams(
      `/myworks/${storyId}/write/${partId}`,
      { triggerPublishingScheduler: true }
    );
  };

  const handleContinue = () => {
    modalRef.current.hide();
  };

  return (
    <Modal
      id={MODAL_ID.SCHEDULE_SUCCESS_MODAL_ID}
      {...{ showModal, onClose }}
      ref={modalRef}
    >
      <header
        className={classNames("schedule-success-modal-header", {
          "publishing-scheduler-update":
            featureFlags.PUBLISHING_SCHEDULER_UPDATE
        })}
      >
        <ConnectImage
          name={`writer/schedule-part-confirmation-success@3x.png`}
        />
        <h2 className="modal-title">{trans("Your part is scheduled!")}</h2>
        {featureFlags.PUBLISHING_SCHEDULER_UPDATE ? (
          <p className="text-body">
            {trans("Your part will be published to readers automatically.")}
          </p>
        ) : (
          ""
        )}
      </header>

      <div className="schedule-success-modal-content">
        <ul className="schedule-success-steps">
          <li className="active-step">
            <div className="active-check-icon">
              {<Icon name="check" color="wp-neutral-5" size={18} />}
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: trans(
                  "You’ve scheduled %s to publish later",
                  `<span class="story-title">${title}</span>`
                )
              }}
            />
          </li>
          <li
            className={classNames({
              bold: featureFlags.PUBLISHING_SCHEDULER_UPDATE
            })}
          >
            {trans("Let your readers know your next part is coming")}
          </li>
          {featureFlags.PUBLISHING_SCHEDULER_UPDATE ? (
            <>
              {!isPublishingToday && (
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: trans(
                        "We'll send you a <strong>reminder 24 hours before</strong> we publish your part."
                      )
                    }}
                  />
                </li>
              )}
              <li className="publish-date-step">
                <span
                  dangerouslySetInnerHTML={{
                    __html: trans(
                      "<strong>We'll notify you</strong> when it is visible to your readers: %s",
                      `<span class="scheduled-date">${formatScheduledPartDate(
                        scheduledPublishDatetime
                      )}</span>`
                    )
                  }}
                />
              </li>
            </>
          ) : (
            <li className="publish-date-step">
              <span
                dangerouslySetInnerHTML={{
                  __html: trans(
                    "We’ll publish your part automatically on %s",
                    `<span class="scheduled-date">${formatScheduledPartDate(
                      scheduledPublishDatetime
                    )}</span>`
                  )
                }}
              />
            </li>
          )}
        </ul>

        <div className="schedule-success-footer">
          <Button
            onClick={handleChangeSchedule}
            variant={ButtonVariant.SECONDARY}
            leadingIcon={IconNames.WpCalendar}
            label={trans("Change schedule")}
          />

          <Button onClick={handleContinue} label={trans("Continue")} />
        </div>
      </div>
    </Modal>
  );
};

ScheduleSuccessModal.propTypes = {
  storyId: PropTypes.string,
  partId: PropTypes.number,
  title: PropTypes.string,
  scheduledPublishDatetime: PropTypes.string,
  showModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default ScheduleSuccessModal;
