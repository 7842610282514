import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";

import CoinsModalContainer from "./CoinsModalContainer";
import CoinPurchaseConfirmation from "./CoinPurchaseConfirmation";
import {
  HARDCODED_NONPREMIUM_COIN_PACKS as nonPremiumCoinPacks,
  HARDCODED_PREMIUM_COIN_PACKS as premiumCoinPacks
} from "./constants";

export default function CoinShop({ toggleModal }) {
  const { trans } = useTrans();

  const isPremium = wattpad.utils.getCurrentUserAttr("isPremium");
  const coinPacks = isPremium ? premiumCoinPacks : nonPremiumCoinPacks;

  // TODO: direct user to payment. For now, just pretend the
  // purchase succeeded after a short delay.
  const onSelectPack = numCoins => {
    toggleModal();

    setTimeout(() => {
      toggleModal({
        // eslint-disable-next-line react/display-name
        component: () => <CoinPurchaseConfirmation numCoins={numCoins} />,
        className: "coins-modal-container",
        hideClose: true
      });
    }, 500);
  };

  return (
    <CoinsModalContainer toggleModal={toggleModal}>
      <div className="coin-shop">
        <div className="shop-explanation">
          <h3>{trans("Coin Shop")}</h3>
          <div className="coin-package-purchase-details">
            <p>{trans("Select a Coin package below.")}</p>
            <p>{trans("Taxes will be added at checkout.")}</p>
          </div>
          {!isPremium && (
            <p className="premium-benefit">
              <Icon
                name="zap"
                className="zap-icon"
                size="16"
                color="wp-neutral-1"
                fill
              />{" "}
              {trans("Premium members get up to 66%% more Coins")}
            </p>
          )}
        </div>
        {Object.values(coinPacks).map(coinPack => (
          <CoinPack
            key={coinPack.value}
            isPremium={isPremium}
            value={coinPack.value}
            premiumValue={coinPack.premium_value}
            nonPremiumValue={coinPack.non_premium_value}
            price={coinPack.price}
            illustrationUrl={coinPack.illustration_url}
            featured={coinPack.featured}
            onSelectPack={onSelectPack}
          />
        ))}
      </div>
    </CoinsModalContainer>
  );
}

CoinShop.propTypes = { toggleModal: PropTypes.func.isRequired };

function CoinPack({
  isPremium,
  value,
  premiumValue,
  nonPremiumValue,
  price,
  illustrationUrl,
  featured,
  onSelectPack
}) {
  const { trans } = useTrans();
  const bonusWithPremium = premiumValue - value;

  return (
    <button
      className="coin-pack btn-no-style"
      onClick={() => onSelectPack(value)}
    >
      <img
        src={illustrationUrl}
        alt={trans("%s Coin Pack", value)}
        height="75"
        width="75"
        className="pack-image"
      />
      <div className="pack-body">
        <div>
          {featured && (
            <span className="most-popular">{trans("Most Popular")}</span>
          )}

          <div className="coins-in-pack">
            <h4>{trans("%s Coins", value)}</h4>
            {isPremium && (
              <span className="non-premium-value">
                {trans("%s Coins", nonPremiumValue)}
              </span>
            )}
          </div>

          <div className="premium-status">
            {isPremium
              ? trans("Premium")
              : trans("+ %s with Premium", bonusWithPremium)}{" "}
            <Icon
              name="zap"
              className="zap-icon"
              size="10"
              color="wp-neutral-2"
              fill
            />
          </div>
        </div>
        <div className="pack-price">${price}</div>
      </div>
    </button>
  );
}

CoinPack.propTypes = {
  isPremium: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  premiumValue: PropTypes.number,
  nonPremiumValue: PropTypes.number,
  price: PropTypes.string.isRequired,
  illustrationUrl: PropTypes.string.isRequired,
  featured: PropTypes.bool,
  onSelectPack: PropTypes.func.isRequired
};
