/**
 * Returns an adjusted cover width based on pixel ratio
 * to account for the resizing done in the resizeCover helper
 *
 * @param {Number} coverSize - width of cover
 *
 * @returns {Number} - adjusted width
 */

import { pixelRatio } from "./pixelRatio";

const getCoverSize = coverSize => {
  if (pixelRatio(2.5)) {
    return Math.floor(coverSize / 3);
  } else if (pixelRatio(1.5)) {
    return Math.floor(coverSize / 2);
  } else {
    return coverSize;
  }
};

export { getCoverSize };
