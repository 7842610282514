import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import Icon from "../../shared-components/Icon";
import PasswordFields from "../../shared-components/PasswordFields";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";

const MAP_ERROR_TO_MSG = trans => ({
  error_weak_password: trans(
    "Please choose a different password. For increased security, a strong password should have lowercase, uppercase, and special characters."
  ),
  error_invalid_password_length: trans(
    "Choose a password with %s to %s characters.",
    10,
    50
  )
});

export const ResetPassword = ({
  authToken,
  isFromPushNotification,
  username,
  name
}) => {
  const { trans } = useTrans();
  const [errorMsg, setErrorMsg] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [resetConfirmed, setResetConfirmed] = useState(false);

  const onValidPassword = valid => {
    setIsFormValid(valid);
  };

  const onCancel = e => {
    e.preventDefault();
    window.location.assign("/login");
  };

  const onReset = async event => {
    event.preventDefault();
    setErrorMsg("");

    await $.ajax({
      type: "POST",
      url: `apiv2/updateuserpassword`,
      data: $(".reset-password").serialize()
    })
      .done(() => {
        if (isFromPushNotification) {
          setResetConfirmed(true);
        } else {
          const redirectUrl = "/login?nexturl=home";
          window.location.replace(redirectUrl).reload();
        }
      })
      .fail(err => {
        //if there is an error
        if (err.responseJSON?.code === 467) {
          const error_code = err.responseJSON?.error_code;

          if (Object.keys(MAP_ERROR_TO_MSG(trans)).includes(error_code)) {
            setErrorMsg(MAP_ERROR_TO_MSG(trans)[error_code]);
          } else {
            const redirectUrl =
              "/forgot?nextUrl=settings&msg=" + encodeURIComponent(error_code);
            window.location.replace(redirectUrl).reload();
          }
        } else {
          const message =
            trans("Something went wrong, your changes were not saved. ") +
            (err.responseJSON.message || "");
          wattpad.utils.showToast(message, { type: "dismissable" });
        }
      });
  };

  return (
    <div className="panel-body">
      {!resetConfirmed ? (
        <>
          <small
            dangerouslySetInnerHTML={{
              /* prettier-ignore */ __html: trans('Visit <a href="https://support.wattpad.com/hc/en-us/articles/211235843-Resetting-or-changing-your-password">Wattpad Support</a> for more information')
            }}
          />
          <hr />
          <form className="reset-password">
            <input name="at" value={authToken} type="hidden" />
            {isFromPushNotification && (
              <input name="isFromPushNotification" value="true" type="hidden" />
            )}
            <PasswordFields
              validatePassword={onValidPassword}
              username={username}
              name={name}
            />

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                <Icon iconName="fa-info" height="16" color="wp-lighterror" />{" "}
                {errorMsg}
              </div>
            )}

            <div className="buttons">
              <Button
                onClick={onReset}
                fullWidth={true}
                disabled={!isFormValid}
                label={trans("Reset password")}
                ariaLabel={trans("Reset password")}
              />
              <Button
                className="cancel-btn"
                onClick={onCancel}
                variant={ButtonVariant.SECONDARY}
                fullWidth={true}
                label={trans("Cancel")}
                ariaLabel={trans("Cancel")}
              />
            </div>
          </form>
        </>
      ) : (
        <div className="form-group">
          <h3>{trans("Password successfully reset")}</h3>
          <p>
            {trans(
              "To continue, you need to log out of the Wattpad app on your mobile device and log in with your new password."
            )}
          </p>
        </div>
      )}
    </div>
  );
};

ResetPassword.propTypes = {
  authToken: PropTypes.string,
  isFromPushNotification: PropTypes.bool,
  username: PropTypes.string,
  name: PropTypes.string
};
