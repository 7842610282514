import React, { Fragment, useRef, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";

import {
  simpleShorten,
  getCoverSrc,
  formatStoryUrl,
  count
} from "../../helpers";
import { useTrans } from "../../hooks";
import { Icon } from "../../shared-components";
import StoryIconBar from "../../shared-components/StoryIconBar";

function StoryItem({
  promoted,
  url,
  id,
  cover,
  title,
  user,
  categories,
  readCount,
  voteCount,
  commentCount,
  description,
  sponsorName,
  clickUrl,
  impressionUrl,
  isCompleted,
  isMature,
  numParts,
  isMobile,
  isFeatureFlagged,
  descCharLimit = 170
}) {
  const { trans } = useTrans();
  const [hasViewed, setHasViewed] = useState(false);
  const storyItemRef = useRef();

  const defaultCover = getCoverSrc(cover, 67);

  useEffect(
    () => {
      if (!hasViewed && !isMobile) {
        // TODO: In the future, we want to move away from underscore dependency
        // Throttle scroll listener so it doesn't run too often.
        const scrollListener = _.throttle(() => {
          if (storyItemRef.current) {
            var rect = storyItemRef.current.getBoundingClientRect();
            var elemTop = rect.top;
            var elemBottom = rect.bottom;
            var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;

            if (isVisible) {
              // Send the event and mark the story item as viewed.
              window.te.push("event", "story", null, null, "view", {
                storyid: id,
                page: "story_details",
                type: "similar_stories",
                source: "story_details"
              });
              setHasViewed(true);
            }
          }
        }, 300);

        window.addEventListener("scroll", scrollListener);
        return () => {
          window.removeEventListener("scroll", scrollListener);
        };
      }
    },
    [hasViewed, setHasViewed, storyItemRef, id, isMobile]
  );

  const onStoryClick = (evt, link) => {
    evt.preventDefault();
    // Story click event
    window.te.push("event", "story", null, null, "click", {
      storyid: id,
      page: "story_details",
      type: "similar_stories",
      source: "story_details"
    });
    app.router.navigate(link, {
      trigger: true
    });
  };

  return (
    <a
      className={classnames("clearfix on-story-preview", { promoted })}
      href={formatStoryUrl(url)}
      onClick={evt => onStoryClick(evt, formatStoryUrl(url))}
      data-story-id={id}
      ref={storyItemRef}
      data-impression-url={impressionUrl}
      data-click-url={clickUrl}
      data-author-name={user ? user.name : null}
    >
      <div className="send-story-cover-event cover cover-sm" data-story-id={id}>
        <img
          srcSet={`${getCoverSrc(cover, 80)} 1x, ${getCoverSrc(
            cover,
            100
          )} 1.5x, ${getCoverSrc(cover, 144)} 2x, ${getCoverSrc(
            cover,
            176
          )} 2.5x, ${getCoverSrc(cover, 200)} 3x`}
          src={defaultCover}
          alt={trans("%1$s by %2$s", title, user ? user.name : null)}
        />
      </div>
      <div className="left-container">
        <div className="story-item-title-and-badge">
          <div className="title" aria-hidden>
            {simpleShorten(title, 45, false)}
          </div>
        </div>
        {isFeatureFlagged ? (
          <StoryIconBar
            numParts={numParts}
            isCompleted={isCompleted}
            isMature={isMature}
          />
        ) : (
          <>
            {promoted ? (
              <small className="promoted left-icon">
                <Icon
                  iconName="fa-badge-promoted"
                  height="13"
                  color="wp-neutral-1"
                />
                <span
                  dangerouslySetInnerHTML={{
                    /* prettier-ignore */ __html: trans("Promoted by %s", sprintf("<b>%s</b>", sponsorName))
                  }}
                />
              </small>
            ) : (
              <Fragment>
                <div className="meta">
                  <span className="reads small left-icon">
                    <Icon iconName="fa-view" height="12" color="wp-neutral-2" />
                    {count(readCount)}
                  </span>
                  <span className="votes small left-icon">
                    <Icon name="votes" size="12" color="wp-neutral-2" fill />
                    {count(voteCount)}
                  </span>
                  {commentCount && (
                    <span className="comments small left-icon">
                      <Icon
                        name="commentMeta"
                        size="14"
                        color="wp-neutral-2"
                        fill
                      />
                      {count(commentCount)}
                    </span>
                  )}
                </div>
                <div className="categories">
                  {categories &&
                    categories.map(category => (
                      <button
                        key={category}
                        className="btn btn-link btn-xxs btn-category"
                      >
                        <span>{category}</span>
                      </button>
                    ))}
                </div>
              </Fragment>
            )}
          </>
        )}
        {description && (
          <div className="small story-item-description">
            {simpleShorten(description, descCharLimit, false, {
              sanitization: "unsanitize"
            })}
          </div>
        )}
      </div>
    </a>
  );
}

StoryItem.propTypes = {
  promoted: PropTypes.bool,
  url: PropTypes.string,
  id: PropTypes.any,
  cover: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
  categories: PropTypes.array,
  readCount: PropTypes.any,
  voteCount: PropTypes.any,
  commentCount: PropTypes.any,
  description: PropTypes.string,
  sponsorName: PropTypes.string,
  clickUrl: PropTypes.string,
  impressionUrl: PropTypes.string,
  isCompleted: PropTypes.bool,
  isMature: PropTypes.bool,
  numParts: PropTypes.number,
  // TODO: remove this once new Story Details is rolled out
  isFeatureFlagged: PropTypes.bool,
  descCharLimit: PropTypes.any,
  isMobile: PropTypes.bool
};

export { StoryItem };
