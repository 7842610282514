import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import WPInput from "./WPInput";
import ValidationElements from "./ValidationElements";

export default class FormField extends Component {
  render = () => {
    let {
      autofocus,
      className,
      errorMessage,
      form,
      hasValidation,
      inputType,
      isValid,
      label,
      name,
      title,
      alt_msg,
      inputRef,
      onBlur,
      onChange,
      showLabel,
      showVisibilityToggle,
      featureFlags
    } = this.props;
    let id = `${form}-${name}`;
    // Classname for container for form-group

    let groupClasses = classNames("form-group", "has-feedback", `${name}`, {
      [className]: !!className,
      valid: isValid === true,
      invalid: isValid === false,
      ["old-validation-padding"]:
        !featureFlags && (isValid == true || isValid == false), // TODO: remove after new onboarding rollout
      ["old-padding"]: !featureFlags // TODO: remove after new onboarding rollout
    });

    let validated_alt_txt;
    if (name == "month" || name == "year" || name == "day") {
      validated_alt_txt = alt_msg;
    } else {
      validated_alt_txt = title && !isValid ? errorMessage : title;
    }
    return (
      <div className={groupClasses} data-attr={name} key={name}>
        {showLabel && (
          <span className="form-label" aria-hidden="true">
            {label}
          </span>
        )}
        <label
          className="control-label sr-only"
          htmlFor={id}
          aria-hidden="true"
        >
          {label}
        </label>
        <WPInput
          inputType={inputType}
          name={name}
          id={id}
          alt={validated_alt_txt}
          label={label}
          inputRef={inputRef}
          placeholder={title}
          onBlur={onBlur}
          onChange={onChange}
          autofocus={autofocus}
          showVisibilityToggle={showVisibilityToggle}
          isValid={isValid}
        />
        {hasValidation && (
          <ValidationElements
            isValid={isValid}
            errorMessage={errorMessage}
            name={name}
          />
        )}
      </div>
    );
  };

  static propTypes = {
    autofocus: PropTypes.bool,
    className: PropTypes.string,
    form: PropTypes.string,
    hasValidation: PropTypes.bool,
    inputType: PropTypes.string,
    isValid: PropTypes.bool,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    showVisibilityToggle: PropTypes.bool,
    title: PropTypes.string,
    alt_msg: PropTypes.string,
    inputRef: PropTypes.object,
    featureFlags: PropTypes.bool
  };
}
