import React from "react";
import { Route, Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTrans } from "../../hooks";

import {
  WRITER_MOTIVATION_OPTIONS,
  FINISHED_NOVEL_OPTIONS,
  SECTION_INTROS
} from "./constants";

import { sendWriterOnboardingEvents } from "./helpers";

const WriterMotivation = () => {
  const { trans } = useTrans();
  const { hash, state } = useLocation();
  let selected = hash ? hash.substring(1) : null;

  const advance = (motivation, finished) => {
    sendWriterOnboardingEvents({
      user_type: state.user_type,
      writer_stage: motivation,
      finished_story: finished
    });
    app.router.navigate("/start", { trigger: true });
  };

  const FinishedNovelOptions = () => {
    return (
      <div className="finished-novel">
        <p>{trans("Have you finished writing a novel?")}</p>
        <div className="novel-options">
          {Object.entries(FINISHED_NOVEL_OPTIONS(trans)).map(
            ([key, option]) => {
              return (
                // eslint-disable-next-line wp-lint-rules/valid-href
                <a
                  className="option"
                  onClick={e => {
                    e.preventDefault();
                    advance(selected, key);
                  }}
                  href="/start"
                  key={key}
                >
                  {option}
                </a>
              );
            }
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <p>{SECTION_INTROS(trans)[1]}</p>
      <div
        id="WriterMotivation"
        className={classNames("options", {
          "has-selection": selected !== null
        })}
      >
        {Object.entries(WRITER_MOTIVATION_OPTIONS(trans)).map(
          ([key, option]) => {
            if (selected !== null && selected !== key) return null;
            if (key === "none") {
              return (
                // eslint-disable-next-line wp-lint-rules/valid-href
                <a
                  key={key}
                  href="/start"
                  onClick={e => {
                    e.preventDefault();
                    advance(key);
                  }}
                  className={classNames("option", {
                    selected: selected === key
                  })}
                >
                  <div>{option}</div>
                </a>
              );
            } else {
              return (
                <Link
                  key={key}
                  to={{
                    pathname:
                      selected === null ? "/motivation/novel" : "/motivation",
                    hash: selected === null ? `#${key}` : "",
                    state: state
                  }}
                  className={classNames("option", {
                    selected: selected === key
                  })}
                >
                  <div>{option}</div>
                </Link>
              );
            }
          }
        )}
      </div>
      <Route path="/motivation/novel">
        <FinishedNovelOptions />
      </Route>
      {
        // eslint-disable-next-line wp-lint-rules/valid-href
        <a
          href="/start"
          className="skip"
          onClick={e => {
            e.preventDefault();
            advance(selected, null);
          }}
        >
          {trans("Skip")}
        </a>
      }
    </React.Fragment>
  );
};

export default WriterMotivation;
