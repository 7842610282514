import React from "react";

import { useTrans } from "../../../hooks";
import {
  CURRENT_WATTYS_YEAR,
  DEFAULT_ELIGIBILITY_LINK,
  DEFAULT_WATTYS_SITE_LINK,
  MAP_WATTYS_ELIGIBILITY_TO_LINKS,
  MAP_WATTYS_SITE_TO_LINKS
} from "../ContestForm/constants";

const CompleteNearEligibleBanner = () => {
  const { trans } = useTrans();
  const eligibilityCriteriaLink =
    MAP_WATTYS_ELIGIBILITY_TO_LINKS[parseInt(app.get("language"), 10)] ||
    DEFAULT_ELIGIBILITY_LINK;
  const wattysSiteLink =
    MAP_WATTYS_SITE_TO_LINKS[parseInt(app.get("language"), 10)] ||
    DEFAULT_WATTYS_SITE_LINK;

  return (
    <>
      <div className="text">
        <span
          dangerouslySetInnerHTML={{
            /* prettier-ignore */ __html: trans("Your story is <strong>so close</strong> to being eligible for The %s Wattys!", CURRENT_WATTYS_YEAR)
          }}
        />
        <a
          className="plain-link bold"
          href={eligibilityCriteriaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans("What is the eligibility criteria?")}
        </a>
      </div>
      <a
        href={wattysSiteLink}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-white"
      >
        <div className="btn-text">
          {trans("%s Wattys", CURRENT_WATTYS_YEAR)}
        </div>
      </a>
    </>
  );
};

export default CompleteNearEligibleBanner;
