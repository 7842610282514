import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../shared-components";
import { useTrans, useTrapKeyboardNavigation } from "../../hooks";
import ReportFormContactInfo from "./ReportContactInfoForm";
import ReportThankYouForm from "./ReportThankYouForm";
import ReportFormUserOption from "./ReportFormUserOption";
import ReportOriginalStory, {
  REPORT_ORIGINAL_STORY_ID
} from "./pages/ReportOriginalStory";
import { buildUserOptionUrl } from "./ReportModalUtils";

export const ReportItemTypes = {
  TEXT: "text",
  EDIT_TEXT: "editText",
  URL: "url",
  PAGE: "page"
};

export const ReportTypes = {
  USER: "user",
  PRIVATE_MESSAGE: "private-message",
  COMMENT: "comment",
  STORY: "story",
  PUBLIC_MESSAGE: "public-message",
  MEDIA: "media"
};

const flowIdByReportType = {
  [ReportTypes.USER]: 1,
  [ReportTypes.PRIVATE_MESSAGE]: 2,
  [ReportTypes.COMMENT]: 3,
  [ReportTypes.STORY]: 4,
  [ReportTypes.PUBLIC_MESSAGE]: 5,
  [ReportTypes.MEDIA]: 193
};

const REPORT_TYPE_ID = 21523158;
const CONDUCT_TYPE_ID = 360041074311;

// React component for generic report modal
// Can be used for reporting private messages
// TODO: implementation for public messages, users, comments, etc.
const ReportModal = ({
  reportType,
  requesterUsername, // requester is the user making the report
  requesterEmail,
  reportId, // id is the information requester is reporting on (ex. another user, a comment, story, etc.)
  hideModal,
  triggerRef,
  showErrorToast, // TODO: delete this prop when new error designs. Need this function as prop so backbone can generate errorToast
  storyLanguage
}) => {
  const { trans } = useTrans();
  const closeBtnRef = useRef();

  // TODO: use useReducer if modal complexity grows
  const [mainTitle, setMainTitle] = useState("");
  const [currentStep, setCurrentStep] = useState(null);
  const [ticketFormId, setTicketFormId] = useState(null);
  const [ticketSubject, setTicketSubject] = useState(null);
  const [reportDetails, setReportDetails] = useState(""); //string from the user explaining why they're making a report
  const [zendeskField, setZendeskField] = useState(-1);
  const [conductTypeRequired, setConductTypeRequired] = useState(false);
  const [zendeskReason, setZendeskReason] = useState("");
  const [zendeskReportType, setZendeskReportType] = useState("");
  const [selectedItemHistory, setSelectedItemHistory] = useState([]);
  const [textDescription, setTextDescription] = useState("");
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [showReasonTitle, setShowReasonTitle] = useState(false);
  const [modalElement, setModalElement] = useState();

  // state variables to help with the Contact Information form (if user is not signed in)
  const [showContactInfoForm, setShowContactInfoForm] = useState(false);
  const [payloadUsername, setPayloadUsername] = useState(requesterUsername);
  const [payloadEmail, setPayloadEmail] = useState(requesterEmail);

  const [showThankYouForm, setShowThankYouForm] = useState(false);

  const errorMessage = trans("An error has occurred. We're sorry about that!"); //prettier-ignore

  const initializeReport = () => {
    const language = parseInt(window.app?.get("language"), 10);
    const treeUrl = `/v4/support/tree?lang=${language}`;
    Promise.resolve($.ajax({ type: "GET", url: treeUrl }))
      .then(tree => {
        const reportTree = tree.flows.find(
          flow => flow.id === flowIdByReportType[reportType]
        );
        setMainTitle(reportTree.title);
        setCurrentStep(reportTree);
        setTicketFormId(reportTree.ticketFormId);
        setTicketSubject(reportTree.ticketSubject);
      })
      .catch(() => {
        hideModal();
        showErrorToast(errorMessage);
      });
  };

  const { updateTabStops } = useTrapKeyboardNavigation(
    modalElement,
    triggerRef?.current,
    hideModal
  );

  useEffect(initializeReport, [reportType]);
  useEffect(updateTabStops, [
    currentStep,
    payloadEmail,
    payloadUsername,
    showContactInfoForm
  ]);

  const handleModalRef = useCallback(ele => setModalElement(ele), []);

  const onClickNext = event => {
    event.preventDefault();
    if (isSubmittable) {
      //if user is not signed in, get contact info before submitting report
      if (!requesterUsername) {
        getUserContactInfo();
      } else {
        submitReport();
        setShowPrevButton(false);
      }
    } else {
      setShowPrevButton(true);
      setSelectedItemHistory([...selectedItemHistory, currentStep]);

      const selectedItemObject = currentStep.items.find(
        item => item.title === event.target.value
      );

      if (selectedItemObject && selectedItemObject.zendeskString) {
        setZendeskField(selectedItemObject.zendeskFields[0]);
        setZendeskReason(selectedItemObject.zendeskString);

        // verify if the item option require conduct ID
        setConductTypeRequired(
          selectedItemObject.zendeskFields.includes(CONDUCT_TYPE_ID)
        );
      }

      if (
        selectedItemObject?.zendeskFields &&
        selectedItemObject.zendeskFields.includes(REPORT_TYPE_ID)
      ) {
        setZendeskReportType(selectedItemObject.zendeskString);
      }

      // if user selected "I don't like what I'm seeing" option
      // bypass sending report and show 'Thank You' Form
      if (selectedItemObject.id === 177) {
        getThankYouForm(selectedItemObject.next.items);
      } else if (selectedItemObject.type === ReportItemTypes.PAGE) {
        // when the selected item type is a page, the item became the current step
        setCurrentStep(selectedItemObject);
      } else {
        const nextStep = selectedItemObject.next;

        // Private message step 2 (text form) header in tree is different
        // web header should be "Reason: <strong>INSERT REASON HERE</strong>"
        if (selectedItemObject.type === "text") {
          // Update the instructions/ description for the text form
          if (reportType === ReportTypes.STORY) {
            setShowReasonTitle(false);
            setTextDescription("");
          } else {
            setShowReasonTitle(true);
            setTextDescription(selectedItemObject.next?.header || "");
            nextStep.header = selectedItemObject.title;
          }
        }
        // report can be submitted if currentStep.items has a 'submittable' key set to true
        // need to check that items exists and not an empty array, and that submittable key is true
        setIsSubmittable(
          nextStep.items && nextStep.items[0] && !!nextStep.items[0].submittable
        );
        setCurrentStep(nextStep);
      }
    }
  };

  const onClickPrev = () => {
    if (showContactInfoForm) {
      setShowContactInfoForm(false);
      return;
    }

    if (selectedItemHistory.length === 1) {
      setShowPrevButton(false);
    }

    let selectedItemHistoryCopy = [...selectedItemHistory];
    setCurrentStep(selectedItemHistoryCopy.pop());
    setSelectedItemHistory(selectedItemHistoryCopy);
    setIsSubmittable(false);
    setShowReasonTitle(false);
    setReportDetails("");
    setZendeskReason("");
  };

  const onChangeReportComment = event => {
    setReportDetails(event.target.value);
    updateTabStops();
  };

  const getUserContactInfo = () => {
    setShowReasonTitle(false);
    setShowContactInfoForm(true);
  };

  const getThankYouForm = items => {
    setCurrentStep(items);
    setShowThankYouForm(true);
  };

  const submitReport = () => {
    if (showContactInfoForm) {
      setShowContactInfoForm(false);
      setShowPrevButton(false);
    }

    let comment = "";
    let payloadReportType = "";
    let conductType = undefined;
    const baseUrl = window.location.origin;

    switch (reportType) {
      case ReportTypes.USER:
        comment = `${reportDetails}\n\nReported User's Profile: https://www.wattpad.com/user/${
          reportId.name
        }\n\n`;
        payloadReportType = "user";
        conductType = "user_account_conduct";
        break;
      case ReportTypes.PRIVATE_MESSAGE:
        comment = `${reportDetails}\n\nReported User's Profile: https://www.wattpad.com/user/${
          reportId.name
        }\n\n`;
        payloadReportType = "private_message";
        conductType = "private_message_conduct";
        break;
      case ReportTypes.COMMENT:
        comment =
          `${reportDetails}\n` +
          `\n---\n` +
          `Comment Author: ${reportId.name}\n` +
          `Comment Location: ${reportId.location}\n` +
          `Comment Body: ${reportId.body}\n` +
          `Comment DeepLink: ${reportId.deepLink}`;
        payloadReportType = "comment";
        conductType = "story_comment_conduct";
        break;
      case ReportTypes.PUBLIC_MESSAGE:
        comment =
          `${reportDetails}\n` +
          `\n---\n` +
          `Message Author: ${reportId.name}\n` +
          `Message Location: ${reportId.location}\n` +
          `Message Body: ${reportId.body}\n`;
        payloadReportType = "public_message";
        conductType = "public_message_conduct";
        break;
      case ReportTypes.STORY:
        comment =
          `${reportDetails}\n` +
          `\n---\n` +
          `Type of Content: Story\n` +
          (reportId.deepLink
            ? `Reported Story Part: ${baseUrl}/${reportId.deepLink}\n`
            : "") +
          `Reported Story: ${baseUrl}/story/${reportId.location}\n` +
          `Reported User's Profile: ${baseUrl}/user/${reportId.name}\n` +
          `Reported Group ID: ${reportId.location}\n`;
        payloadReportType = "story";
    }

    const userLanguage = window.app
      .get("supported-languages")
      .where({ locale: wattpad.utils.getCookie("locale") })[0]
      .toJSON().nameEnglish;

    const customFields = [
      {
        id: 22708584,
        value: storyLanguage
      },
      {
        id: 22592474,
        value: userLanguage
      },
      {
        id: 21519886,
        value: wattpad.utils.getDeviceType() === "mobile" ? "mobile_web" : "web"
      }
    ];

    const payload = {
      requester: {
        name: payloadUsername,
        email: payloadEmail
      },
      comment: comment,
      ticket_form_id: ticketFormId,
      username: reportId.name,
      reportType: payloadReportType,
      reason: zendeskReason,
      subject: ticketSubject || mainTitle,
      custom_fields: customFields
    };

    if (reportType === ReportTypes.STORY) {
      // for stories, we need to send the storyID,
      payload.id = reportId.location;
    }

    if (conductTypeRequired && conductType) {
      payload.custom_fields.push({
        id: CONDUCT_TYPE_ID,
        value: conductType
      });
    }

    if (zendeskField && zendeskReason) {
      payload.custom_fields.push({
        id: zendeskField,
        value: zendeskReason
      });
    }

    if (zendeskReportType !== zendeskReason) {
      payload.custom_fields.push({
        id: REPORT_TYPE_ID,
        value: zendeskReportType
      });
    }

    Promise.resolve(
      $.ajax({
        type: "POST",
        url: "/v4/support/tickets/",
        data: payload
      })
    )
      .then(() => {
        // make sure that the next step is final
        // last step is just the 'Thank you' card, can't exit modal yet
        if (!showThankYouForm) {
          setCurrentStep(currentStep.items[0].next);
          setShowReasonTitle(false);
          closeBtnRef.current?.focus();
        }
      })
      .catch(() => {
        hideModal();
        showErrorToast(errorMessage);
      });
  };

  const renderBodyPage = () => {
    switch (currentStep.extras?.page) {
      case REPORT_ORIGINAL_STORY_ID:
        return (
          <ReportOriginalStory
            storyId={reportId.location}
            storyTitle={reportId.body}
            onContentChanged={updateTabStops}
            onReportSubmitted={() => {
              setShowPrevButton(false);
              closeBtnRef.current?.focus();
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderOptionItems = () => (
    <>
      <div className="modal-body">
        {/* TODO: If 2nd page header titles for Private Messages
                changes, won't need showReasonTitle state variable anymore  */}
        {showReasonTitle ? (
          <h6 className="reason-title">
            {trans("Reason:") + " "}
            {/* the header only has to be bolded when it's the 2nd page of private message flow */}
            <strong>{currentStep.header}</strong>
          </h6>
        ) : (
          // header should be normal any other time
          <h6 className="reason-note">{currentStep.header}</h6>
        )}
        <form>
          <div className="modal-body-content">
            {!currentStep.isFinal && // TODO: add functionality for the mute button here
              currentStep.items.map(item => {
                return (
                  <ReportFormUserOption
                    key={item.id}
                    item={item}
                    textDescription={textDescription}
                    onChangeReportComment={onChangeReportComment}
                    reportDetails={reportDetails}
                    onClickNext={onClickNext}
                    buildUrl={buildUserOptionUrl(reportId, storyLanguage)}
                  />
                );
              })}
          </div>
        </form>
      </div>
      {isSubmittable &&
        !currentStep.isFinal && (
          <div className="modal-footer">
            <button
              className="btn btn-orange"
              onClick={onClickNext}
              disabled={!reportDetails}
            >
              {trans("Submit Report")}
            </button>
          </div>
        )}
    </>
  );

  const renderModalBody = () => {
    if (showContactInfoForm) {
      return (
        <ReportFormContactInfo
          username={payloadUsername}
          email={payloadEmail}
          setPayloadUsername={setPayloadUsername}
          setPayloadEmail={setPayloadEmail}
          onClickPrev={onClickPrev}
          submitReport={submitReport}
        />
      );
    } else if (currentStep.type === ReportItemTypes.PAGE) {
      return renderBodyPage();
    } else {
      return renderOptionItems();
    }
  };

  const renderReportModal = () => {
    return (
      <div className="report-modal" ref={handleModalRef}>
        <header className="modal-header">
          <h3 className="modal-title">{mainTitle}</h3>
          {showPrevButton && (
            <button className="prev-btn" onClick={onClickPrev}>
              <Icon iconName="fa-left" height="16" color="wp-neutral-2" />
            </button>
          )}
          <button className="on-close" onClick={hideModal} ref={closeBtnRef}>
            <Icon iconName="fa-remove" height="16" color="wp-neutral-2" />
          </button>
        </header>
        {renderModalBody()}
      </div>
    );
  };

  if (showThankYouForm) {
    return (
      <ReportThankYouForm
        requesterUsername={requesterUsername}
        reportedUsername={reportId.name}
        muteCopies={currentStep}
        submitReport={submitReport}
        hideModal={hideModal}
        onModalRef={handleModalRef}
        showToast={showErrorToast}
      />
    );
  } else if (currentStep) {
    return renderReportModal();
  } else {
    return null;
  }
};

ReportModal.propTypes = {
  reportType: PropTypes.string.isRequired,
  requesterUsername: PropTypes.string,
  requesterEmail: PropTypes.string,
  reportId: PropTypes.shape({
    name: PropTypes.string.isRequired,
    location: PropTypes.string,
    body: PropTypes.string,
    deepLink: PropTypes.string
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  triggerRef: PropTypes.object,
  showErrorToast: PropTypes.func,
  storyLanguage: PropTypes.string
};

export default ReportModal;
