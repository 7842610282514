import { isClient } from "../../helpers";
import _ from "lodash";
import { toggleModal } from "../../shared-components/modals/actions";
import ContentSettings from "./components/ContentSettings";
import ThanksForTheInput from "./components/ThanksForTheInputModal";
import StoryPreview from "./components/StoryPreview";
import EmailReverification from "../email-reverification/EmailReverification";
import { fetchPremiumPicks } from "../catalog/reducers/premium-picks-catalog-reducer";

import {
  FETCH_HOME_MODULES,
  RECEIVE_SECTION_POSITION,
  SECTION_VIEWED,
  RECEIVE_CONTENT_SETTINGS,
  NO_CONTENT_SETTINGS_CHANGES,
  LOADING_CONTENT_SETTINGS,
  LOADING_CONTENT_SETTINGS_DONE,
  LOADING_HOME,
  LOADING_HOME_DONE,
  SHOW_LOADER,
  HIDE_LOADER,
  ERROR,
  CLEAR_ERROR,
  errorTypes,
  CALL_TRACKING_URLS,
  RECEIVE_READING_LISTS,
  LOADING_READING_LISTS,
  RECEIVE_STORIES_COLLECTIONS,
  LOADING_STORIES_COLLECTIONS,
  ADD_STORY_TO_COLLECTION,
  REMOVE_STORY_FROM_COLLECTION
} from "./constants";

import { TOGGLE_MODAL_VISIBLE } from "../../shared-components/modals/constants";

export function fetchHomeSections(api, res, req) {
  const homeUrl = "/v5/home";

  return function(dispatch) {
    dispatch({ type: LOADING_HOME });
    let request;
    if (isClient()) {
      // CD-1669: hc for home cache
      let hcValue = window.wattpad.utils.getCookie("hc");
      if (!hcValue) {
        hcValue = new Date().toISOString();
        wattpad.utils.setCookie("hc", hcValue);
      }

      const getParams = {
        url: homeUrl,
        data: { cache: hcValue }
      };
      request = Promise.resolve($.get(getParams));
    } else {
      request = api.get(homeUrl, res, req);
    }

    request
      .then(response => {
        dispatch({
          type: FETCH_HOME_MODULES,
          sections: response.sections
        });
        dispatch(clearError());
      })
      .catch(() => {
        dispatch({
          type: ERROR,
          error: {
            errorType: errorTypes.fetchFailed,
            errorMessage: "fetching home modules failed"
          }
        });
      })
      .finally(() => {
        dispatch({ type: LOADING_HOME_DONE });
        dispatch({ type: SHOW_LOADER });
      });
  };
}

export function receiveSectionPosition(index, type, top, bottom) {
  return {
    type: RECEIVE_SECTION_POSITION,
    info: {
      index,
      type,
      top,
      bottom
    }
  };
}

export function sectionViewed(index) {
  return {
    type: SECTION_VIEWED,
    index
  };
}

export function clearError() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ERROR
    });
  };
}

export function callTrackingUrls(urls) {
  return function(dispatch) {
    _.each(urls, url => {
      $.get(url);
    });
    dispatch({
      type: CALL_TRACKING_URLS
    });
  };
}

export function openContentSettings() {
  return function(dispatch) {
    dispatch(
      toggleModal({
        // eslint-disable-next-line
        component: () => <ContentSettings />,
        className: "modal-contest",
        disableClose: true,
        disableScroll: true
      })
    );
  };
}

export function openStoryDetails(story) {
  return function(dispatch) {
    dispatch(
      toggleModal({
        // eslint-disable-next-line
        component: () => <StoryPreview story={story} />,
        className: "modal-story-preview",
        disableClose: true, // Added as workaround to modal closing when interacting with popover.
        hideClose: true,
        disableScroll: true
      })
    );
  };
}

// Fetch content settings data. This action dispatches a loading, and a loading_done
// state before and after the promise is resoved.
export function getContentSettings() {
  const username = wattpad.utils.currentUser().get("username");
  const contentSettingsEndpoint = `/v5/users/${username}/content-settings`;
  return function(dispatch) {
    dispatch({ type: LOADING_CONTENT_SETTINGS });
    Promise.resolve($.get(contentSettingsEndpoint))
      .then(response => {
        dispatch({
          type: RECEIVE_CONTENT_SETTINGS,
          settings: response
        });
        dispatch({ type: LOADING_CONTENT_SETTINGS_DONE });
      })
      .catch(() => {
        dispatch({
          type: ERROR,
          error: {
            errorType: errorTypes.fetchError,
            errorMessage: "error fetching content settings"
          }
        });
      });
  };
}

// Update content settings data. This action dispatches a loading state and the
// getContentSettings action before and after the promise is resolved.
// If no changes are made, dispatch a "no change" message. The modal
// is closed after the "Apply Changes" button is clicked regardless
// if a change was made.
export function saveContentSettings(updates) {
  if (Object.keys(updates).length === 0) {
    return function(dispatch) {
      dispatch({
        type: NO_CONTENT_SETTINGS_CHANGES
      });
      dispatch({ type: TOGGLE_MODAL_VISIBLE });
    };
  }
  const username = wattpad.utils.currentUser().get("username");
  const contentSettingsEndpoint = `/v5/users/${username}/content-settings`;
  const matureURI = `${contentSettingsEndpoint}/include-mature`;
  const matureKey = "includeMature";
  const blockedTagsURI = `${contentSettingsEndpoint}/blocked-tags`;
  const blockedTagsKey = "blockedTags";

  let maturePromise, blockedTagsPromise;
  let promiseObjects = [];

  if (updates.hasOwnProperty(matureKey)) {
    maturePromise = Promise.resolve(
      $.ajax({
        url: matureURI,
        type: "PUT",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ [matureKey]: updates[matureKey] })
      })
    );
    promiseObjects.push({ [matureKey]: maturePromise });
  }

  if (updates.hasOwnProperty(blockedTagsKey)) {
    blockedTagsPromise = Promise.resolve(
      $.ajax({
        url: blockedTagsURI,
        type: "PUT",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          [blockedTagsKey]: updates[blockedTagsKey]
        })
      })
    );
    promiseObjects.push({ [blockedTagsKey]: blockedTagsPromise });
  }

  // returns a successful promise so that Promise.all always resolves
  function reflect(promiseObject) {
    // object key is the name of the setting, object value is a promise
    return Object.values(promiseObject)[0].then(
      v => {
        return {
          setting: Object.keys(promiseObject)[0],
          promise: v,
          status: "resolved"
        };
      },
      e => {
        return {
          setting: Object.keys(promiseObject)[0],
          promise: e,
          status: "rejected"
        };
      }
    );
  }

  return function(dispatch) {
    dispatch({ type: LOADING_CONTENT_SETTINGS });
    Promise.all(promiseObjects.map(reflect)).then(results => {
      results.forEach(obj => {
        if (obj.setting === matureKey && obj.status === "resolved") {
          window.te.push("event", "content_settings", null, null, "update", {
            setting: "mature"
          });
        }
      });
      wattpad.utils.destroyCookie("hc");
      dispatch({ type: HIDE_LOADER });
      dispatch(getContentSettings());
      dispatch(fetchHomeSections());
      dispatch(fetchPremiumPicks(true));
      dispatch({ type: TOGGLE_MODAL_VISIBLE });
    });
  };
}

export function openConfirmation() {
  return function(dispatch) {
    dispatch(
      toggleModal({
        /* eslint-disable */
        component: () => (
          <ThanksForTheInput
            handleConfirmation={() => {
              dispatch({ type: TOGGLE_MODAL_VISIBLE });
            }}
          />
        ),
        /* eslint-disable */
        className: "modal-confirmation",
        hideClose: true
      })
    );
  };
}

/**
 * Returns information about all of the users created reading lists / collections
 *
 * Example API response:
 * {
 *   "lists":[
 *     {
 *       "id":912071012,
 *       "name":"my 1000 new library",
 *       "user":{"name":"StevenWasHere"},
 *       "numStories":3
 *     },
 *       ...
 *   ],
 *   "total":15
 * }
 */
export function getReadingLists() {
  const username = wattpad.utils.currentUser().get("username");
  const readingListsEndpoint = `/api/v3/users/${username}/lists`;
  const readingListParams = `?offset=0&limit=200&fields=lists(id%2Cname%2Cuser(name)%2CnumStories%2Cfeatured)%2Ctotal`;
  const readingListsUrl = readingListsEndpoint + readingListParams;

  return function(dispatch) {
    dispatch({ type: LOADING_READING_LISTS });
    Promise.resolve($.get(readingListsUrl))
      .then(response => {
        dispatch({
          type: RECEIVE_READING_LISTS,
          lists: response.lists
        });
      })
      .catch(() => {
        wattpad.utils.showToast("Error: fetching reading lists failed", {
          type: "dismissable"
        });
      });
  };
}

// Add stories to a collection
// stories can be a single storyID or a string of comma separated
// storyIds
export function updateCollection(collectionId, stories) {
  const username = wattpad.utils.currentUser().get("username");
  const collectionsEndpoint = `/api/v3/lists/${collectionId}/stories`;
  const libraryEndpoint = `/api/v3/users/${username}/library?fields=stories(id)`;

  const collectionsUrl =
    collectionId === "library" ? libraryEndpoint : collectionsEndpoint;

  return function(dispatch) {
    Promise.resolve($.post(collectionsUrl, { stories }))
      .then(() => {
        // Only handles one story for now. Will need a different dispatch when
        // multiple stories are included
        dispatch({
          type: ADD_STORY_TO_COLLECTION,
          collectionId: collectionId,
          storyId: stories
        });
      })
      .catch(() => {
        wattpad.utils.showToast("Error: collection update failed", {
          type: "dismissable"
        });
      });
  };
}

/**
 *
 * Removes the provided stories from the
 * provided collection and updates the redux
 * collection state.
 *
 * @param {Number} collectionId
 * @param {String} stories
 */
export function removeStoriesFromCollection(collectionId, stories) {
  const username = wattpad.utils.currentUser().get("username");

  const collectionsEndpoint = `/api/v3/lists/${collectionId}/stories/${stories}`;
  const libraryEndpoint = `/api/v3/users/${username}/library/${stories}`;

  const collectionsUrl =
    collectionId === "library" ? libraryEndpoint : collectionsEndpoint;

  return function(dispatch) {
    Promise.resolve(
      $.ajax({
        url: collectionsUrl,
        type: "DELETE"
      })
    )
      .then(() => {
        // Only handles one story for now. Will need a different dispatch when
        // multiple stories are included
        dispatch({
          type: REMOVE_STORY_FROM_COLLECTION,
          collectionId: collectionId,
          storyId: stories
        });
      })
      .catch(() => {
        wattpad.utils.showToast(
          "Error: removing stories from collection failed",
          {
            type: "dismissable"
          }
        );
      });
  };
}

/**
 * Gets an inventory of all Reading lists a user has
 * created + default lists and an indication of whether
 * targeted story/stories belong to those collections. 
 * It then refreshes the redux collections state.
 * 
 * @param {String} storyIds
 *
 * example collections API response:
 * 
    collections: {
      "storyId": {
        library: false,
        archive: false,
        "collectionId": false,
        "collectionId": true,
      }
    }

 */
export function getCollectionsForStoryIds(storyIds) {
  const collectionsEndpoint = "/v4/stories/collections";
  const collectionsParams = `?storyIds=${storyIds}`;

  const collectionsUrl = collectionsEndpoint + collectionsParams;
  return function(dispatch) {
    dispatch({ type: LOADING_STORIES_COLLECTIONS });
    Promise.resolve($.get(collectionsUrl))
      .then(response => {
        dispatch({
          type: RECEIVE_STORIES_COLLECTIONS,
          collections: response.collections
        });
      })
      .catch(() => {
        wattpad.utils.showToast("Error: fetching stories collections failed", {
          type: "dismissable"
        });
      });
  };
}

export function openEmailReverification(
  maxSkips,
  daysBetweenReminders,
  verified
) {
  return function(dispatch) {
    const user = wattpad.user;

    // if the cookie has expired OR the max number of skips has been reached, show the modal
    // dbrc is days between reminders cookie
    // cnsc is current number of skips cookie
    const daysBetweenCookie = window.wattpad.utils.getCookie(
      "dbrc_" + user.username
    );
    const currSkipsCookie = Number(
      window.wattpad.utils.getCookie("cnsc_" + user.username)
    );

    if (daysBetweenCookie == null || currSkipsCookie > maxSkips) {
      dispatch(
        toggleModal({
          // eslint-disable-next-line
          component: () => (
            <EmailReverification
              email={user.email}
              userId={user.id}
              username={user.username}
              daysBetweenReminders={daysBetweenReminders}
              verified={verified}
            />
          ),
          className: "email-reverification-modal",
          hideClose: true
        })
      );
    }
  };
}

export function fetchEmailReverificationStatus() {
  return function(dispatch) {
    const url =
      "/api/v3/internal/current_user?fields=email_reverification_status,verified_email";

    const request = Promise.resolve($.get({ url: url }));

    request.then(response => {
      const {
        is_email_reverified,
        max_skips,
        days_between_reminders
      } = response.email_reverification_status;
      const verified = response.verified_email;
      if (!is_email_reverified) {
        dispatch(
          openEmailReverification(max_skips, days_between_reminders, verified)
        );
      }
    });
  };
}
