import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { buildLabel } from "./helpers/input-labels";

function RadioButtons({
  info,
  label,
  name,
  options,
  value,
  onChange,
  className,
  labelClassName,
  position,
  disabled,
  ...rest
}) {
  const getId = label => {
    return name + "-" + label;
  };

  const [selected, setSelected] = useState("");
  const [isFocused, setIsFocused] = useState();

  useEffect(
    () => {
      let option = options.find(option => option.value === value);
      if (option) {
        setSelected(name + "-" + option.label);
      } else {
        setSelected("");
      }
    },
    [value, setSelected, options, name]
  );

  const handleChange = event => {
    setSelected(event.target.id);
    onChange(event);
  };

  let button_options = options.map(function(data) {
    return (
      <div key={data.value} className={classNames(className, "radio-button")}>
        <input
          type={"radio"}
          id={getId(data.label)}
          value={data.value}
          checked={selected === getId(data.label)}
          onChange={handleChange}
          className={classNames({ focus: isFocused })}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          disabled={disabled}
          {...rest}
        />
        <label className={"radio-label"} htmlFor={getId(data.label)}>
          {data.label}
        </label>
      </div>
    );
  });

  return (
    <div className={"form-field radio-button-container"}>
      {buildLabel({ label, labelClassName, tooltip: info })}
      <div className={"radio-button-group"}>
        {position === "horizontal" ? (
          <div className="horizontal-group">{button_options}</div>
        ) : (
          <fieldset id={name}>{button_options}</fieldset>
        )}
      </div>
    </div>
  );
}

RadioButtons.defaultProps = {
  onChange: () => {},
  name: ""
};

RadioButtons.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  position: PropTypes.string,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

export default RadioButtons;
