import _ from "lodash";

import { promiseTimeout } from "../../helpers";

import {
  CURRENCY_ID,
  SET_WALLET_BALANCE,
  WALLET_ANIMATION_STEP,
  DEDUCTING_ANIMATION_DELAY,
  SUBTRACTING_ANIMATION_DELAY,
  COIN_TRANSITION_DELAY,
  WALLET_STATES,
  BUYING_CONTENT,
  BOUGHT_CONTENT
} from "./constants";

function fetchWalletBalance(username, forceRefetch = false) {
  const walletUrl = `/v5/users/${username}/wallet`;

  return function(dispatch, getState) {
    const { wallet } = getState();
    const alreadyFetched = wallet.fetched;
    if (alreadyFetched && !forceRefetch) {
      return Promise.resolve();
    }

    const request = Promise.resolve($.get(walletUrl));

    return request
      .then(wallets => {
        // Endpoint returns array with balances in different currencies
        // We only use one currency now, so just use a harcoded ID
        const currentWallet = _.find(wallets, { id: CURRENCY_ID });

        if (!currentWallet) {
          // Missing wallet means no balance
          return;
        }

        dispatch({ type: SET_WALLET_BALANCE, amount: currentWallet.amount });
      })
      .catch(err => {
        console.log("Failed to fetch wallet balance", err); // eslint-disable-line no-console
      });
  };
}

function startPaidContentPurchase(price, partId) {
  return function(dispatch) {
    dispatch({ type: BUYING_CONTENT, price, partId });
    dispatch({ type: WALLET_ANIMATION_STEP, step: WALLET_STATES.COIN });
    setTimeout(() => {
      dispatch({ type: WALLET_ANIMATION_STEP, step: WALLET_STATES.DEDUCTING });
    }, COIN_TRANSITION_DELAY);

    // Allow enough time for animation to complete
    return promiseTimeout(DEDUCTING_ANIMATION_DELAY);
  };
}

function markPaidContentPurchaseSuccessful() {
  // The purchase is successful, but not finished because success animations must be played
  return function(dispatch) {
    dispatch({ type: WALLET_ANIMATION_STEP, step: WALLET_STATES.SUBTRACTING });

    return promiseTimeout(SUBTRACTING_ANIMATION_DELAY);
  };
}

function finishPaidContentPurchase() {
  return function(dispatch) {
    dispatch({ type: WALLET_ANIMATION_STEP, step: WALLET_STATES.COIN });

    setTimeout(() => {
      dispatch({ type: BOUGHT_CONTENT });
    }, COIN_TRANSITION_DELAY);

    return promiseTimeout(COIN_TRANSITION_DELAY);
  };
}

export {
  fetchWalletBalance,
  startPaidContentPurchase,
  markPaidContentPurchaseSuccessful,
  finishPaidContentPurchase
};
