import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import { maxCharLengthField, requiredField, validEmail } from "../helpers";
import { EMAIL_CHARACTER_MAX, VALID_EMAIL_REGEX } from "../constants";
import ContestFormField from "../ContestFormField";
import ErrorField from "./ErrorField";

const EmailField = ({
  form,
  disableForm,
  onPreferredEmailChange,
  fieldStatus,
  submitted,
  hasSubmitted,
  emailConfirmField,
  setEmailConfirmField
}) => {
  const { trans } = useTrans();

  const title = trans("Email");

  const subtitle = (
    <div className="subtitle">
      <span>
        {/* prettier-ignore */ trans("What is the best email address for us to contact you about your entry?")}
      </span>
      <span>
        {
          /* prettier-ignore */ <strong> {trans("Ensure you enter an email address you check frequently to avoid missing email communications from our team.")}</strong>
        }
      </span>
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "writerEmail" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.writerEmail === "";

  const confirmEmailErrorCondition =
    (fieldStatus.fieldInvalid || submitted) && emailConfirmField === "";

  const mismatchedEmailErrorMessage =
    form.writerEmail !== emailConfirmField
      ? trans("These two fields must match.")
      : "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <>
        <FormValidation
          validationRules={[
            requiredField(trans),
            maxCharLengthField(EMAIL_CHARACTER_MAX, trans),
            validEmail(VALID_EMAIL_REGEX, trans)
          ]}
        >
          {({ ref, errorMessage, className }) => (
            <React.Fragment>
              <input
                type="email"
                ref={ref}
                id="writer-email-input"
                className={classNames("form-element", className, "half-width")}
                name="writerEmail"
                value={form.writerEmail}
                placeholder={trans("Email address")}
                disabled={disableForm}
                onChange={onPreferredEmailChange}
              />
              <ErrorField
                errorCondition={errorCondition}
                errorMessage={errorMessage}
                fieldId={"writer-email"}
              />
            </React.Fragment>
          )}
        </FormValidation>
        {!hasSubmitted && (
          <div className="secondary-email">
            <FormValidation
              validationRules={[
                requiredField(trans),
                maxCharLengthField(EMAIL_CHARACTER_MAX, trans),
                validEmail(VALID_EMAIL_REGEX, trans)
              ]}
            >
              {({ ref, errorMessage, className }) => (
                <React.Fragment>
                  <input
                    type="email"
                    ref={ref}
                    id="confirm-email-input"
                    className={classNames(
                      "form-element",
                      className,
                      "half-width"
                    )}
                    name="email_confirm_field"
                    value={emailConfirmField}
                    placeholder={trans("Confirm email address")}
                    disabled={disableForm}
                    onChange={({ target: { value } }) =>
                      setEmailConfirmField(value)
                    }
                  />

                  <ErrorField
                    errorMessage={errorMessage}
                    errorCondition={confirmEmailErrorCondition}
                    fieldId={"confirm-email"}
                  />

                  <ErrorField
                    errorMessage={mismatchedEmailErrorMessage}
                    fieldId={"matching-email"}
                  />
                </React.Fragment>
              )}
            </FormValidation>
          </div>
        )}
      </>
    </ContestFormField>
  );
};

EmailField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onPreferredEmailChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
  emailConfirmField: PropTypes.string,
  setEmailConfirmField: PropTypes.func
};

export default EmailField;
