import React from "react";
import PropTypes from "prop-types";
import Skeleton from "./Skeleton";

const ProfileCardSkeleton = () => {
  return (
    <div className="profile-card-skeleton">
      <Skeleton className="profile-avatar" animation circle />
      <div className="card-content-region">
        <Skeleton height="24px" className="title-skeleton" animation />
        <Skeleton height="16px" className="subtitle-skeleton" animation />
        <div className="card-meta-region">
          <Skeleton width="60px" height="16px" animation />
          <Skeleton width="60px" height="16px" animation />
          <Skeleton width="60px" height="16px" animation />
        </div>
      </div>
    </div>
  );
};

ProfileCardSkeleton.propTypes = {
  coverColor: PropTypes.string
};

export default ProfileCardSkeleton;
