import React, { Component } from "react";
import PropTypes from "prop-types";

import { ConnectImage } from "../../shared-components";
import { injectTrans } from "../../helpers";
import { WATTPAD_USER_COUNT } from "./constants";

class Hero extends Component {
  render() {
    let { trans } = this.props;
    return (
      <div id="Hero">
        <div className="container content">
          <div>
            <h2>{trans("Hi, we're Wattpad.")}</h2>
            <h3>
              {trans("The world's most-loved social storytelling platform")}
            </h3>
            <h4>
              {/* prettier-ignore */ trans( "Wattpad connects a global community of %s million readers and writers through the power of story.", WATTPAD_USER_COUNT)}
            </h4>
            <div className="buttons">
              <button
                className="btn btn-md btn-orange"
                onClick={() => {
                  this.props.toggleModal({
                    type: "signup",
                    source: "reader_top"
                  });
                }}
              >
                {trans("Start Reading")}
              </button>
              <button
                className="btn btn-md btn-orange"
                onClick={() => {
                  this.props.toggleModal({
                    type: "signup",
                    source: "writer_top"
                  });
                }}
              >
                {trans("Start Writing")}
              </button>
            </div>
          </div>
          <div className="imageContainer">
            <ConnectImage
              name="landing/hero-devices.png"
              title="hero-devices"
            />
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    toggleModal: PropTypes.func,
    trans: PropTypes.func.isRequired
  };
}

export default injectTrans(Hero);
