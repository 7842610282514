(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("moment"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define(["moment", "_"], factory);
	else if(typeof exports === 'object')
		exports["components"] = factory(require("moment"), require("_"));
	else
		root["app"] = root["app"] || {}, root["app"]["components"] = factory(root["moment"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__14__) {
return 