import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../hooks";

const mapLanguageToSupportArticleLinks = {
  1: "https://support.wattpad.com/hc/en-us/articles/4437003467924", // English
  2: "https://support.wattpad.com/hc/fr/articles/4437003467924", //French
  3: "https://support.wattpad.com/hc/it/articles/4437003467924", // Italian
  4: "https://support.wattpad.com/hc/de/articles/4437003467924", // German
  5: "https://support.wattpad.com/hc/es/articles/4437003467924", // Spanish
  6: "https://support.wattpad.com/hc/pt/articles/4437003467924", // Portuguese
  18: "https://support.wattpad.com/hc/fil/articles/4437003467924", // Filipino
  20: "https://support.wattpad.com/hc/id/articles/4437003467924", // Indonesian
  23: "https://support.wattpad.com/hc/tr/articles/4437003467924" // Turkish
};

const InfoPopover = ({ title, content, closePopover }) => {
  const { trans } = useTrans();
  const [supportArticleUrl, setSupportArticleUrl] = useState(
    mapLanguageToSupportArticleLinks[1]
  );

  useEffect(() => {
    const langId = parseInt(app.get("language"), 10);
    if (mapLanguageToSupportArticleLinks[langId] === undefined) {
      return;
    }
    setSupportArticleUrl(mapLanguageToSupportArticleLinks[langId]);
  }, []);

  return (
    <div
      className="react-popover-tooltip"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="popover-title"
      aria-describedby="popover-content"
    >
      <h3 className="popover-title" id="popover-title">
        {title}
      </h3>
      <div className="popover-content" id="popover-content">
        <span>{content}</span>
      </div>
      <hr aria-hidden />
      <div className="tooltip-footer">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={supportArticleUrl}
          className="tooltip-footer__link"
        >
          <span>{trans("Learn more")}</span>
        </a>
        <button
          className="btn btn-purple tooltip-footer__btn"
          onClick={closePopover}
        >
          {trans("Ok")}
        </button>
      </div>
    </div>
  );
};

InfoPopover.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  closePopover: PropTypes.func.isRequired
};

export default InfoPopover;
