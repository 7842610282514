const getPrivateMessageFeatureFlags = ({
  private_message: privateMessage
} = {}) => {
  if (!privateMessage) return {};

  const mappedPrivateMessageFeatureFlags = {
    canSendPrivateMessage: privateMessage?.can_send_private_message || false,
    displayDeprecationNotice:
      privateMessage?.display_deprecation_notice || false,
    displayWarningNotice: privateMessage?.display_warning_notice || false
  };

  return mappedPrivateMessageFeatureFlags;
};

module.exports = { getPrivateMessageFeatureFlags };
