let window = window || global;

const pixelRatio = (
  decimal,
  devicePixelRatio,
  winMatchMedia,
  msWinMatchMedia
) => {
  const dpr = window ? window.devicePixelRatio : devicePixelRatio;

  if (!decimal) {
    return (
      dpr ||
      (pixelRatio(3)
        ? 3
        : pixelRatio(2)
          ? 2
          : pixelRatio(1.5)
            ? 1.5
            : pixelRatio(1)
              ? 1
              : 0)
    );
  }

  if (dpr && dpr > 0) {
    return dpr >= decimal;
  }

  let matchMedia =
    (window ? window.matchMedia : winMatchMedia) ||
    (window ? window.msMatchMedia : msWinMatchMedia);

  if (!matchMedia) {
    return false;
  }

  decimal = "only all and (min--moz-device-pixel-ratio:" + decimal + ")";
  if (matchMedia(decimal).matches) {
    return true;
  }
  return !!matchMedia(decimal.replace("-moz-", "")).matches;
};

export { pixelRatio };
