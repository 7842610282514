import React from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";

import AuthorName from "./AuthorName";
import { useTrans } from "../hooks";
import ReactTooltip from "react-tooltip";
import { fromNow, canUseDom } from "../helpers";

import { Pill, PillVariant, Accent } from "@wattpad/web-ui-library";

/**
 * The story badges.
 * Displays the author and whether the story is paid or completed or has a new part
 */
const Badges = ({
  isCompleted,
  isMature,
  user: { username, avatar },
  publishDate,
  numNewParts,
  numNewBonusParts
}) => {
  const { trans, ngettext } = useTrans();

  let completedText = null;
  let completedAccent = null;
  if (isCompleted) {
    completedText = trans("Complete");
    completedAccent = Accent.BASE_3;
  } else if (isCompleted === false) {
    completedText = trans("Ongoing");
    completedAccent = Accent.BASE_5;
  }
  const matureText = trans("Mature");

  // format first published date
  const date = fromNow(publishDate, {
    fuzzyTime: true
  });

  // prevent tooltip from overflowing on the left
  const overridePos = ({ left, top }, _e, _t, node) => {
    return {
      top,
      left: typeof node === "string" ? left : Math.max(left, 0)
    };
  };

  const newPartText = sprintf(
    ngettext("%s new part", "%s new parts", numNewParts),
    numNewParts
  );

  const newBonusPartText = sprintf(
    ngettext("%s new Extra", "%s new Extras", numNewBonusParts),
    numNewBonusParts
  );

  return (
    <div className="xxs-container-padding badges">
      {username && <AuthorName username={username} avatar={avatar} />}
      <div className="story-badges">
        {completedText &&
          completedAccent && (
            <div
              className="icon completed"
              data-tip
              data-for="publish-date"
              aria-describedby="publish-date"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              aria-hidden
            >
              <Pill
                label={completedText}
                variant={PillVariant.SOLID}
                accent={completedAccent}
              />
            </div>
          )}
        <span className="sr-only">
          {trans("%s, First published %s", completedText, date)}
        </span>
        {canUseDom ? (
          <ReactTooltip
            id="publish-date"
            effect="solid"
            role="tooltip"
            backgroundColor="#000000"
            overridePosition={overridePos}
            aria-hidden
          >
            {trans("First published")}
            {<strong> {trans("%s", date)}</strong>}
          </ReactTooltip>
        ) : null}
        {isMature && (
          <div className="icon mature">
            <Pill
              label={matureText}
              variant={PillVariant.SOLID}
              accent={Accent.BASE_4}
            />
          </div>
        )}
        {numNewParts > 0 && (
          <Pill
            label={newPartText}
            variant={PillVariant.LIGHT}
            accent={Accent.BASE_7}
            leadingIndicator={true}
          />
        )}
        {numNewBonusParts > 0 && (
          <Pill
            label={newBonusPartText}
            variant={PillVariant.LIGHT}
            accent={Accent.BASE_2}
            leadingIndicator={true}
          />
        )}
      </div>
    </div>
  );
};

Badges.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string, // TODO: add placeholder author, and trans tag.
    avatar: PropTypes.string.isRequired
  }),
  isCompleted: PropTypes.bool,
  isMature: PropTypes.bool,
  publishDate: PropTypes.string,
  numNewParts: PropTypes.number,
  numNewBonusParts: PropTypes.number
};

export default Badges;
