import { useRef, useEffect } from "react";

// This effect is used to set the default values once when
// isLoading is set to false.
function SetDefaultValueOnce({ isLoading, onSetDefault }) {
  const checkedDefaultValue = useRef(false);
  const savedCallback = useRef();
  useEffect(
    () => {
      savedCallback.current = onSetDefault;
    },
    [onSetDefault]
  );
  useEffect(
    () => {
      if (!checkedDefaultValue.current && !isLoading) {
        savedCallback.current();
        checkedDefaultValue.current = true;
      }

      if (isLoading) {
        checkedDefaultValue.current = false;
      }
    },
    [isLoading, checkedDefaultValue]
  );
}

export default SetDefaultValueOnce;
