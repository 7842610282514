import React from "react";
import { useTrans } from "../../hooks";

const WATTPAD_BRAND_SITE_URL = "https://brands.wattpad.com";

const BrandPartnerships = () => {
  const { trans } = useTrans();

  return (
    <div id="BrandPartnershipsBanner">
      <img
        className="company-logo"
        alt=""
        src="/img/landing/company-logo.svg"
      />
      <div className="content">
        <img
          className="brand-partnerships-logo"
          alt=""
          src="/img/landing/brand-partnerships-logo.svg"
        />
        <h2 className="title">
          {trans(
            "The world’s most positive platform for brands to engage Gen Z."
          )}
        </h2>
        <p className="subtitle">
          {trans("If you’re a business, click below to learn more.")}
        </p>
        <a
          className="button"
          href={WATTPAD_BRAND_SITE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans("Learn more")}
        </a>
      </div>
    </div>
  );
};

export default BrandPartnerships;
