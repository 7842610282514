import React from "react";
import { Icon } from ".";

import { useTrans } from "../hooks";

const UserSafetyLinks = () => {
  const { trans } = useTrans();
  return (
    <>
      <a
        className="conduct-link user-safety-operation btn-no-background btn-left-icon option"
        href="https://support.wattpad.com/hc/en-us/articles/200774234-Code-of-Conduct"
        target="_blank"
        rel="noreferrer noopener"
        tabIndex="0"
      >
        <Icon name="link" size="14" color="wp-neutral-1" />
        <span className="conduct-link">{trans("Code of Conduct")}</span>
      </a>

      <a
        className="safety-portal user-safety-operation btn-no-background btn-left-icon option"
        href="https://safetyportal.wattpad.com/"
        target="_blank"
        rel="noreferrer noopener"
        tabIndex="0"
      >
        <Icon name="info" size="14" color="wp-neutral-1" />
        <span>{trans("Wattpad Safety Portal")}</span>
      </a>
    </>
  );
};

export default UserSafetyLinks;
