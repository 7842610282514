import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { loadThreads } from "./reducers";
import { NewMessage } from "../NewMessage";
import { toggleModal } from "../../../shared-components/modals/actions";
import { useTrans, useSetDocumentTitle } from "../../../hooks";
import { WPModal } from "../../../shared-components";
import LazyLoader from "../LazyLoader";
import Thread from "./Thread";
import ThreadFilter from "./ThreadFilter";

import { Toast } from "../../../shared-components/toasts/Toast";
import PleaseVerify from "../../verification/PleaseVerify";
import { InfoBar } from "../../../shared-components/info-bar/InfoBar";
import { RestrictPrivateMessagingDeprecationMessage } from "../RestrictPrivateMessagingDeprecationMessage";
import { InboxEmpty } from "../../../shared-components/inbox-empty/InboxEmpty";

const useMountEffect = func => useEffect(func, []);

const RestrictPrivateMessagingWarningMessage = () => {
  const { trans } = useTrans();

  return (
    <p className="typography-paragraph-medium text ">
      {trans(
        "On May 6, 2024 0:00:00 UTC, user-to-user private messaging will no longer be a feature on Wattpad and existing conversations will be deleted. You will still be able to receive messages from Wattpad-affiliated accounts."
      )}
      &nbsp;
      <a
        className="typography-label-medium learn-more-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.wattpad.com/hc/articles/204412040-Private-messages"
      >
        {trans("Learn more")}
      </a>
    </p>
  );
};

const InboxNotices = ({ privateMessagePermissions }) => {
  let notices = [];

  privateMessagePermissions?.displayWarningNotice &&
    notices.push(
      <InfoBar key={notices.length}>
        <RestrictPrivateMessagingWarningMessage />
      </InfoBar>
    );
  privateMessagePermissions?.displayDeprecationNotice &&
    notices.push(
      <InfoBar key={notices.length}>
        <RestrictPrivateMessagingDeprecationMessage />
      </InfoBar>
    );

  if (notices.length > 0) {
    return <div className="threads-message">{notices}</div>;
  }

  return null;
};

const ThreadsUI = ({
  loadThreads,
  isLoading,
  isLoadingMore,
  isEmailVerified,
  threads,
  username,
  total,
  offset,
  filter,
  toggleModal,
  privateMessagePermissions
}) => {
  const { trans } = useTrans();

  useSetDocumentTitle(`${trans("Inbox")} - Wattpad`);
  useMountEffect(() => {
    if (typeof window.__atha !== "undefined") {
      window.__atha.sendPageView("inbox");
    }

    loadThreads({});
  });

  const onLoadMore = offset => {
    loadThreads({ offset, filter });
  };

  const onClickNewMessage = () => {
    if (!isEmailVerified) {
      const component = () => (
        <PleaseVerify
          email={wattpad.utils.currentUser().get("email")}
          userId={wattpad.utils.currentUser().get("id")}
          hideModal={toggleModal}
        />
      );
      component.displayName = "PleaseVerify";
      toggleModal({
        className: "please-verify-modal",
        hideClose: true,
        component
      });
    } else {
      const component = () => <NewMessage toggleModal={toggleModal} />;
      component.displayName = "NewMessage";

      toggleModal({
        className: "new-message-modal",
        component
      });
    }
  };
  return (
    <div id="inbox">
      <div className="threads-container">
        <div className="threads-header">
          <div className="flex-part">
            <h2>{trans("My Messages")}</h2>
          </div>
          <ThreadFilter filter={filter} loadThreads={loadThreads} />
          {privateMessagePermissions &&
            privateMessagePermissions?.canSendPrivateMessage && (
              <button className="new-msg-btn" onClick={onClickNewMessage}>
                <span> {trans("+ New Message")} </span>
              </button>
            )}
          {!privateMessagePermissions && (
            <button className="new-msg-btn" onClick={onClickNewMessage}>
              <span> {trans("+ New Message")} </span>
            </button>
          )}
          <WPModal />
        </div>
        {privateMessagePermissions && (
          <InboxNotices privateMessagePermissions={privateMessagePermissions} />
        )}
        <div className="threads-list">
          {threads.length === 0 && !isLoading ? (
            <InboxEmpty />
          ) : (
            threads.map(thread => (
              <Thread
                key={thread.user.name}
                thread={thread}
                username={username}
                privateMessagePermissions={privateMessagePermissions}
              />
            ))
          )}
        </div>
        <LazyLoader
          direction="down"
          threshold={0}
          isLoading={isLoadingMore}
          isDisabled={isLoadingMore}
          total={total}
          offset={offset}
          onLoad={onLoadMore}
        />
      </div>
      <Toast />
    </div>
  );
};

ThreadsUI.propTypes = {
  privateMessagePermissions: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  threads: PropTypes.array.isRequired,
  username: PropTypes.string.isRequired,
  loadThreads: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  toggleModal: PropTypes.func
};

InboxNotices.propTypes = {
  privateMessagePermissions: PropTypes.object
};

const Threads = connect(
  state => ({ ...state.threads }),
  { loadThreads, toggleModal }
)(ThreadsUI);

export { Threads, ThreadsUI };
