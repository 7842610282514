import React, { useState, useRef, useEffect } from "react";
import { useTrans, useFeatureFlags } from "../../hooks";
import PropTypes from "prop-types";
import PasswordFields from "../../shared-components/PasswordFields";
import {
  withValidation,
  FormField,
  NewCheckbox
} from "../../shared-components";
import { Button } from "@wattpad/web-ui-library";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../components";
import BirthdayFields from "../authentication/BirthdayFields";

const ValidatedFormField = withValidation(FormField);

const RequestAccountDetails = ({ errorMsg, model, preservedVal = {} }) => {
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();

  const { email, token, type, username } = preservedVal;

  const [usernameValue, setUsernameValue] = useState();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isBirthdayValid, setIsBirthdayValid] = useState(false);
  const [isPolicyConsentValid, setIsPolicyConsentValid] = useState(false);
  const [isMarketingConsentValid, setIsMarketingConsentValid] = useState(false);
  const [isInvalidated, setIsInvalidated] = useState(false);
  const [policy, setIsPolicy] = useState("");

  const emailRef = useRef();
  const usernameRef = useRef();
  const birthdayInput = useRef();
  const pronounInput = useRef();

  const policy_agreement_old = trans("I have read and agree to Wattpad's <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy</a>"); // prettier-ignore
  const policy_agreement = trans("Yes, I have read and agree to Wattpad's <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy</a>."); // prettier-ignore
  const marketing_agreement_email = trans(
    "Yes, I'd like to receive marketing emails from Wattpad. (optional)"
  );
  const marketing_agreement_email_subtext = trans(
    "Get story updates, announcements, offers, and more via email. Unsubscribe anytime."
  );

  const isValidated =
    isEmailValid &&
    isUsernameValid &&
    isPasswordValid &&
    isBirthdayValid &&
    isPolicyConsentValid;

  const validateSignupForm = (isValid, key) => {
    if (key.toLowerCase().includes("username")) {
      setIsUsernameValid(isValid);
    } else if (key.toLowerCase().includes("email")) {
      setIsEmailValid(isValid);
    } else if (key.toLowerCase().includes("birthday")) {
      setIsBirthdayValid(isValid);
    }
  };

  const initializeFormValues = () => {
    if (!featureFlags.NEW_ONBOARDING_1) {
      if (email) {
        emailRef.current.value = email;
        emailRef.current.focus();
        emailRef.current.blur();
      }
      if (username) {
        usernameRef.current.value = username;
        usernameRef.current.focus();
        usernameRef.current.blur();
      } else {
        usernameRef.current.focus();
      }
    }
  };

  const onSubmitErrorMessage =
    errorMsg === trans("Please fix any errors to continue.");

  useEffect(initializeFormValues, [username, email]);

  const onBlurHandler = e => {
    const valid = e.target.checkValidity();
    setIsPolicy(valid);
  };

  const onChangeHandler = () => {
    setIsPolicyConsentValid(!isPolicyConsentValid);
    setIsPolicy(!isPolicyConsentValid);
  };

  const renderLabelWithHint = (label, ariaLabel, tooltip) => (
    <div className="field-label">
      <span className="field-label-text">{label}</span>
      <div aria-label={ariaLabel} data-tip={tooltip}>
        <Icon
          height="16"
          iconName="fa-info"
          color="wp-neutral-1"
          className="tooltip-icon"
        />
      </div>
    </div>
  );

  const onSubmitHandler = () => {
    birthdayInput.current.focus();
    birthdayInput.current.blur();

    usernameRef.current.focus();
    usernameRef.current.blur();

    setIsPolicy(isPolicyConsentValid);
    if (!isBirthdayValid || !isPolicyConsentValid || !isUsernameValid)
      setIsInvalidated(true);
    else setIsInvalidated(false);
  };

  return (
    <>
      {featureFlags.NEW_ONBOARDING_1 ? (
        <div className="container-new">
          <div className="form-wrapper">
            <div className="form-new">
              <form
                id="signupForm"
                action=""
                method="POST"
                onSubmit={() => {
                  if (!isPolicyConsentValid) {
                    return false;
                  }
                }}
              >
                <ReactTooltip className="tool-tip" effect="solid" />
                {renderLabelWithHint(
                  trans("Username"),
                  "username hint",
                  trans(
                    "You do not have to use your real name. You can choose to use another name to protect your privacy."
                  )
                )}
                <ValidatedFormField
                  name="username"
                  form="signup"
                  inputType="text"
                  title={trans("Enter username")}
                  inputRef={usernameRef}
                  validationModel={model}
                  showLabel={true}
                  onValid={key => validateSignupForm(true, key)}
                  onInvalid={key => validateSignupForm(false, key)}
                  onBlur={() => setUsernameValue(usernameRef.current?.value)}
                  featureFlags={featureFlags.NEW_ONBOARDING_1}
                />

                {renderLabelWithHint(
                  trans("Birthday"),
                  "why we ask for your birthday",
                  trans(
                    "You need to enter the date you were born. This information will only be visible to you and Wattpad’s Support teams."
                  )
                )}
                <BirthdayFields
                  inputRef={birthdayInput}
                  validationModel={model}
                  onValid={() => validateSignupForm(true, "birthday")}
                  onInvalid={() => validateSignupForm(false, "birthday")}
                  errorMessageOnSubmit={
                    onSubmitErrorMessage ? trans("Birthday is required.") : ""
                  }
                />
                {renderLabelWithHint(
                  trans("Pronouns (optional)"),
                  trans("Why we ask for your pronouns"),
                  trans(
                    "Your pronouns are only visible to you and Wattpad's support team. Update them anytime in your profile."
                  )
                )}
                <FormField
                  inputType="pronouns"
                  id="pronouns"
                  name="pronouns"
                  defaultValue=""
                  aria-label="Select Pronoun"
                  ref={pronounInput}
                />
                <div className="marketing-policy-agreement-container">
                  <NewCheckbox
                    contentId="marketing-agreement"
                    checked={isMarketingConsentValid}
                    onChange={() =>
                      setIsMarketingConsentValid(!isMarketingConsentValid)
                    }
                    color="ds-base-2-60"
                    name="marketing"
                    value={isMarketingConsentValid}
                  />
                  <div>
                    <span
                      className="marketing-agreement"
                      dangerouslySetInnerHTML={{
                        __html: marketing_agreement_email
                      }}
                    />
                    <br />
                    <span
                      className={"marketing-agreement-subtext"}
                      dangerouslySetInnerHTML={{
                        __html: marketing_agreement_email_subtext
                      }}
                    />
                  </div>
                </div>
                <div className="email-policy-agreement-container">
                  <NewCheckbox
                    contentId="policy-consent"
                    required
                    checked={isPolicyConsentValid}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    color="ds-base-2-60"
                    classname={
                      policy === false || onSubmitErrorMessage ? "invalid" : ""
                    }
                    name="policy"
                    value={isPolicyConsentValid}
                  />
                  <div>
                    <span
                      className="policy-agreement"
                      dangerouslySetInnerHTML={{
                        __html: policy_agreement
                      }}
                    />
                    <br />
                    {(policy === false || onSubmitErrorMessage) && (
                      <span
                        aria-live="assertive"
                        className="error-msg-new"
                        role="alert"
                      >
                        {trans("Checking this box is required.")}
                      </span>
                    )}
                  </div>
                </div>
                {token && (
                  <input
                    className="token hidden"
                    readOnly
                    value={token}
                    name="token"
                  />
                )}
                {type && (
                  <input
                    className="account-type hidden"
                    readOnly
                    value={type}
                    name="type"
                  />
                )}
                <input
                  className="hidden"
                  readOnly
                  value="1"
                  name="newOnboarding"
                />
                {errorMsg &&
                  !onSubmitErrorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsg}
                    </div>
                  )}
                <Button
                  fullWidth={true}
                  label={trans("Sign up")}
                  ariaLabel={trans("Sign up")}
                  className="signup-button"
                  onClick={onSubmitHandler}
                />
                {errorMsg &&
                  onSubmitErrorMessage && (
                    <span
                      className="error-msg-new text-align-center"
                      role="alert"
                    >
                      {errorMsg}
                    </span>
                  )}
                {isInvalidated && (
                  <span
                    className="error-msg-new text-align-center"
                    role="alert"
                  >
                    {trans("Please fix any errors to continue.")}
                  </span>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div
            className="onboardingModal request-account-details modal fade in"
            data-backdrop="static"
            data-keyboard="false"
            style={{ display: "block" }}
          >
            <div className="v-align-wrap">
              <div className="modal-dialog v-align">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title text-center">
                      {trans("You're almost there.")}
                    </h1>
                  </div>
                  <div className="modal-body">
                    <h2 className="text-center">
                      {trans(
                        "We need some details to keep in contact with you."
                      )}
                    </h2>
                    <form
                      id="signupForm"
                      action=""
                      method="POST"
                      onSubmit={e => {
                        if (!isEmailValid) {
                          e.preventDefault();
                          emailRef.current.focus();
                        }
                        if (!isUsernameValid) {
                          e.preventDefault();
                          usernameRef.current.focus();
                        }
                        if (!isPolicyConsentValid) {
                          return false;
                        }
                      }}
                    >
                      <ReactTooltip className="tool-tip" effect="solid" />

                      {renderLabelWithHint(
                        trans("Username"),
                        "username hint",
                        trans(
                          "You do not have to use your real name. You can choose to use another name to protect your privacy."
                        )
                      )}
                      <ValidatedFormField
                        name="username"
                        form="signup"
                        inputType="text"
                        title={trans("Enter username")}
                        inputRef={usernameRef}
                        validationModel={model}
                        showLabel={true}
                        onValid={key => validateSignupForm(true, key)}
                        onInvalid={key => validateSignupForm(false, key)}
                        onBlur={() =>
                          setUsernameValue(usernameRef.current?.value)
                        }
                        featureFlags={featureFlags.NEW_ONBOARDING_1}
                      />
                      <ValidatedFormField
                        name="email"
                        label={trans("Email")}
                        form="signup"
                        inputType="text"
                        title={trans("Enter email")}
                        inputRef={emailRef}
                        validationModel={model}
                        showLabel={true}
                        onValid={key => validateSignupForm(true, key)}
                        onInvalid={key => validateSignupForm(false, key)}
                        featureFlags={featureFlags.NEW_ONBOARDING_1}
                      />
                      <PasswordFields
                        username={usernameValue}
                        validatePassword={setIsPasswordValid}
                        featureFlags={featureFlags.NEW_ONBOARDING_1}
                      />
                      {renderLabelWithHint(
                        trans("Birthday"),
                        "why we ask for your birthday",
                        trans(
                          "You need to enter the date you were born. This information will only be visible to you and Wattpad’s Support teams."
                        )
                      )}
                      <BirthdayFields
                        inputRef={birthdayInput}
                        validationModel={model}
                        onValid={() => validateSignupForm(true, "birthday")}
                        onInvalid={() => validateSignupForm(false, "birthday")}
                      />

                      {errorMsg && (
                        <div className="alert alert-danger" role="alert">
                          {errorMsg}
                        </div>
                      )}
                      <div className="email-policy-agreement-container">
                        <NewCheckbox
                          contentId="policy-consent"
                          required
                          checked={isPolicyConsentValid}
                          onChange={() =>
                            setIsPolicyConsentValid(!isPolicyConsentValid)
                          }
                          color="ds-base-2-60"
                        />
                        <span
                          className="policy-agreement"
                          dangerouslySetInnerHTML={{
                            __html: policy_agreement_old
                          }}
                        />
                      </div>
                      {token && (
                        <input
                          className="token hidden"
                          readOnly
                          value={token}
                          name="token"
                        />
                      )}
                      {type && (
                        <input
                          className="account-type hidden"
                          readOnly
                          value={type}
                          name="type"
                        />
                      )}
                      <Button
                        disabled={!isValidated}
                        fullWidth={true}
                        label={trans("Continue")}
                        ariaLabel={trans("Continue")}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RequestAccountDetails.propTypes = {
  errorMsg: PropTypes.string,
  model: PropTypes.object,
  preservedVal: PropTypes.shape({
    email: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string,
    username: PropTypes.string
  })
};

export default RequestAccountDetails;
