import React from "react";
import propTypes from "prop-types";
import { useTrans } from "../../../hooks";

export default function FailToLoadHome({ onRefresh }) {
  const { trans } = useTrans();
  return (
    <div className="load-home-failed">
      <div className="image">
        <img src="/img/homepages/homeIllustration.svg" alt="" />
      </div>
      <div className="text">
        <div className="title">{trans("Let’s find your way back home")}</div>
        <div className="subtitle">
          {trans("Something went wrong, try refreshing.")}
        </div>
      </div>
      <button className="refresh btn-primary" onClick={onRefresh}>
        {trans("Refresh")}
      </button>
    </div>
  );
}

FailToLoadHome.propTypes = {
  onRefresh: propTypes.func
};

FailToLoadHome.defaultProps = {
  onRefresh: () => {}
};
