import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import {
  MAP_CHARACTER_REPRESENTATION_IDENTITIES,
  OTHER_IDENTITIES_CHARACTER_MAX
} from "../constants";
import ContestFormField from "../ContestFormField";
import { NewCheckbox } from "../../../../shared-components";
import ErrorField from "./ErrorField";

const CharacterRepresentationField = ({
  form,
  disableForm,
  fieldStatus,
  submitted,
  onCharacterRepresentationChange,
  onFormChange,
  identitiesLink
}) => {
  const { trans } = useTrans();

  const title = trans("Character representation");

  const subtitle = (
    <div className="subtitle">
      <div
        dangerouslySetInnerHTML={{
          __html: /* prettier-ignore */ trans('Are any of the <a href="%s" target="_blank" rel="noopener noreferrer">following identities</a> represented by the main character(s) in this story?', identitiesLink)
        }}
      />
      <br />
      {/* prettier-ignore */ trans("This question is entirely optional and does not impact your ability to win a Watty. We are always looking for stories with great representation — answering these questions will help us better identify stories with great representation for future opportunities outside The Watty Awards. Please only answer based on the identities of the main characters in your story. We do not collect information about writer identity.")}
    </div>
  );

  const diversityOtherErrorMessage = trans(
    "This field has to contain less than 100 characters."
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "diversity" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.diversity.length === 0;

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <div id="character-representation">
        {MAP_CHARACTER_REPRESENTATION_IDENTITIES(trans).map(identity => (
          <div className="checkbox-group" key={identity.key}>
            <NewCheckbox
              name={identity.key}
              disabled={disableForm}
              onChange={onCharacterRepresentationChange}
              contentId={`character-representation-${identity.key}`}
              checked={form.diversity.includes(identity.key)}
            >
              {identity.value}
            </NewCheckbox>
          </div>
        ))}
        <ErrorField fieldId={"diversity"} errorCondition={errorCondition} />
      </div>
      {form.diversity.includes("OTHER") && (
        <>
          <textarea
            className="form-element"
            id="other-character-representation-textarea"
            name="diversityOther"
            value={form.diversityOther}
            placeholder={trans("You can add more details here")}
            disabled={disableForm}
            onChange={onFormChange}
          />
          <ErrorField
            fieldId={"diversity-other"}
            errorCondition={form.diversityOther === ""}
          />
          {form.diversityOther?.length > OTHER_IDENTITIES_CHARACTER_MAX && (
            <ErrorField
              fieldId={"diversity-other"}
              errorMessage={diversityOtherErrorMessage}
            />
          )}
        </>
      )}
    </ContestFormField>
  );
};

CharacterRepresentationField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired,
  onCharacterRepresentationChange: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  storyLanguage: PropTypes.string,
  identitiesLink: PropTypes.string.isRequired
};

export default CharacterRepresentationField;
