import _ from "lodash";
import { formatPaidContentMetadata } from "../../../helpers/format-paid-content-metadata";

const namespace = "PAID_CONTENT_METADATA";

const SET_PAID_CONTENT_METADATA = `${namespace}/SET`;
const CLEAR_PAID_CONTENT_METADATA = `${namespace}/CLEAR`;

export function fetchPaidContentMetadata(storyId, parts) {
  return function(dispatch, getState) {
    // To prevent extra fetches, cache the metadata here. The cache is
    // cleared on purchase, which is almost always why the metadata
    // would change.  If the user purchases on another platform, they
    // will have to refresh to clear the cache manually.
    const { paidMetadata } = getState();
    if (paidMetadata[storyId] !== undefined) {
      return Promise.resolve();
    }

    const commaSeparatedParts = _.pluck(parts, "id").join(",");

    return Promise.resolve(
      $.get(`/v5/story/${storyId}/paid-content/metadata`, {
        parts: commaSeparatedParts
      })
    ).then(metadata => {
      dispatch({
        type: SET_PAID_CONTENT_METADATA,
        storyId,
        metadata: formatPaidContentMetadata(
          parts,
          metadata,
          window?.wattpad?.utils?.trans
        )
      });
    });
  };
}

export function cacheBustPaidContentMetadata(storyId, partIds) {
  return { type: CLEAR_PAID_CONTENT_METADATA, storyId, partIds };
}

export default function paidMetadata(state = {}, action) {
  switch (action.type) {
    case SET_PAID_CONTENT_METADATA: {
      const { metadata, storyId } = action;
      const { story, parts } = metadata;

      return {
        ...state,
        [storyId]: {
          story,
          parts
        }
      };
    }
    case CLEAR_PAID_CONTENT_METADATA: {
      const { storyId } = action;
      return _.omit(state, storyId);
    }
    default:
      return state;
  }
}
