import React from "react";
import PropTypes from "prop-types";
import { Button } from "@wattpad/web-ui-library";

import { useTrans } from "../../../hooks";
import { fromNow } from "../../../helpers";

const StoryPartNavigation = ({ group, modifyDate, nextPart }) => {
  const { trans } = useTrans();
  const { completed, title } = group;

  const lastUpdated = trans("Last updated: %s", fromNow(modifyDate, { fuzzyTime: true })); //prettier-ignore
  const finishedReading = trans("You've finished reading %s", `<span class="title">${title}</span>`) // prettier-ignore

  return (
    <div id="story-part-navigation">
      {!nextPart &&
        (!completed ? (
          <div className="message">
            <div className="top-message">
              {trans("You've reached the end of published parts.")}
            </div>

            <p className="last-updated-text">{`⏰ ${lastUpdated} ⏰`}</p>
            <p className="bottom-message">
              {trans(
                "Add this story to your Library to get notified about new parts!"
              )}
            </p>
          </div>
        ) : (
          <div className="message">
            <div
              className="top-message"
              dangerouslySetInnerHTML={{
                __html: `🎉 ${finishedReading} 🎉`
              }}
            />
          </div>
        ))}

      {(nextPart || !completed) && (
        <Button
          href={nextPart?.url}
          fullWidth={true}
          disabled={!nextPart}
          label={trans("Continue to next part")}
        />
      )}
    </div>
  );
};

StoryPartNavigation.propTypes = {
  group: PropTypes.object,
  modifyDate: PropTypes.string,
  nextPart: PropTypes.object
};

export default StoryPartNavigation;
