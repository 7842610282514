import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../../hooks";
import { FormValidation } from "../../FormValidation";
import { requiredField } from "../../helpers";
import ContestFormField from "../../ContestFormField";
import { MAP_SERIES_TYPES } from "../../constants";
import ErrorField from "../ErrorField";

const SeriesTypeField = ({
  form,
  disableForm,
  onSeriesIdentificationChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = "";

  const subtitle = (
    <div className="subtitle">
      {trans("Is this story the first story in the series or a sequel?")}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "seriesType" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.seriesType === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation validationRules={[requiredField(trans)]}>
        {({ ref, errorMessage, className }) => (
          <>
            <select
              id="series-type-select"
              ref={ref}
              value={form.seriesType}
              name="seriesType"
              disabled={disableForm}
              className={classNames("form-element", className)}
              onChange={onSeriesIdentificationChange}
              aria-label={trans("Select an option")}
            >
              <option value="" disabled>
                {trans("Select an option")}
              </option>
              {MAP_SERIES_TYPES(trans).map(type => (
                <option key={type.key} value={type.key}>
                  {type.value}
                </option>
              ))}
            </select>
            <ErrorField errorMessage={errorMessage} fieldId={"series-type"} />
            {!errorMessage && (
              <ErrorField
                fieldId={"series-type"}
                errorCondition={errorCondition}
              />
            )}
          </>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

SeriesTypeField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onSeriesIdentificationChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default SeriesTypeField;
