import { TOGGLE_TOAST_VISIBLE } from "../../shared-components/toasts/reducers";
import { MEDIA_SERVER } from "../../constants";

const name = "USER";

const SET_MUTED_USERS = `${name}/SET_MUTED_USERS`;
const SET_BLOCKED_USERS = `${name}/SET_BLOCKED_USERS`;
export const MUTE = `${name}/MUTE`;
export const UNMUTE = `${name}/UNMUTE`;
export const BLOCK = `${name}/BLOCK`;
export const UNBLOCK = `${name}/UNBLOCK`;
export const ERROR = `${name}/ERROR`;

const initialState = {
  mutedUsers: [],
  blockedUsers: [],
  muteListLoaded: false,
  blockListLoaded: false
};

export default function profileData(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MUTED_USERS:
      return { ...state, mutedUsers: action.mutedUsers, muteListLoaded: true };
    case SET_BLOCKED_USERS:
      return {
        ...state,
        blockedUsers: [...action.blockedUsers],
        blockListLoaded: true
      };
    case MUTE:
      return {
        ...state,
        muteListLoaded: false,
        mutedUsers: state.mutedUsers.concat([{ username: action.name }])
      };
    case UNMUTE:
      return {
        ...state,
        muteListLoaded: false,
        mutedUsers: state.mutedUsers.filter(
          user => user.username != action.name
        )
      };
    case BLOCK:
      return {
        ...state,
        blockListLoaded: false,
        blockedUsers: state.blockedUsers.concat([{ username: action.name }])
      };
    case UNBLOCK:
      return {
        ...state,
        blockListLoaded: false,
        blockedUsers: state.blockedUsers.filter(
          user => user.username != action.name
        )
      };
    default:
      return state;
  }
}

// Action Creators
export function muteUser(username, onUserMuted) {
  const muteUserUrl = `/apiv2/ignoreuser?id=${username}&action=ignore_user`;
  return function(dispatch) {
    return Promise.resolve(
      $.ajax({
        url: muteUserUrl,
        type: "POST"
      })
    )
      .then(() => {
        dispatch({ type: MUTE, name: username });
        wattpad.utils.clearCommentLocalStorage();
        wattpad.utils.showToast(
          wattpad.utils.trans("%s is now muted. You can unmute them at any time.", username), //prettier-ignore
          { type: "dismissable" }
        );
        onUserMuted?.();
      })
      .catch(err => {
        let errorMessage;
        try {
          const errorCode = err.responseJSON.code;
          if (errorCode === 467) {
            // Cannot mute staff, admins or verified users.
            errorMessage = err.responseJSON.message;
          }
        } catch (err) {
          errorMessage = wattpad.utils.trans("There was an error muting this user. Please try again."); //prettier-ignore
        }
        wattpad.utils.showToast(errorMessage, {
          type: "dismissable"
        });
        dispatch({ type: ERROR });
        throw err;
      });
  };
}

export function unmuteUser(username, onUserUnmuted) {
  const unmuteUserUrl = `/apiv2/ignoreuser?id=${username}&action=unignore_user`;

  return function(dispatch) {
    const request = Promise.resolve(
      $.ajax({
        url: unmuteUserUrl,
        type: "POST"
      })
    );

    return request
      .then(() => {
        dispatch({ type: UNMUTE, name: username });
        dispatch({
          type: TOGGLE_TOAST_VISIBLE,
          toastProps: {
            className: "unmute",
            body: wattpad.utils.trans("%s is now unmuted. You can mute them at any time.", username) //prettier-ignore
          }
        });
        wattpad.utils.clearCommentLocalStorage();
        onUserUnmuted?.();
      })
      .catch(err => {
        let errorMessage;
        try {
          errorMessage = err.responseJSON.message;
        } catch (err) {
          errorMessage = wattpad.utils.trans("There was an error unmuting this user. Please try again."); //prettier-ignore
        }
        wattpad.utils.showToast(errorMessage, {
          type: "dismissable"
        });

        dispatch({ type: ERROR });
        throw err;
      });
  };
}

export function unblockUser(username) {
  return function(dispatch) {
    const request = Promise.resolve(
      $.ajax({
        type: "DELETE",
        url: `/v4/users/${username}/blocks`
      })
    );
    return request
      .then(() => {
        dispatch({ type: UNBLOCK, name: username });
        dispatch({
          type: TOGGLE_TOAST_VISIBLE,
          toastProps: {
            className: "unblock",
            body: wattpad.utils.trans("%s is now unblocked. You can block them at any time.", username) //prettier-ignore
          }
        });
        wattpad.utils.clearCommentLocalStorage();
      })
      .catch(err => {
        let errorMessage;
        try {
          errorMessage = err.responseJSON.message;
        } catch (err) {
          errorMessage = wattpad.utils.trans("There was an error unblocking this user. Please try again."); //prettier-ignore
        }
        wattpad.utils.showToast(errorMessage, {
          type: "dismissable"
        });

        dispatch({ type: ERROR });
        throw err;
      });
  };
}

export function initializeMutedUsers(userId) {
  const url = `/apiv2/ignoreuser?id=${userId}&action=get_ignored_web`;

  return function(dispatch, getState) {
    if (getState().profileData.muteListLoaded) {
      return;
    }

    const request = Promise.resolve(
      $.ajax({
        url: url,
        type: "POST"
      })
    );

    return request
      .then(response => {
        dispatch({
          type: SET_MUTED_USERS,
          mutedUsers: response.list.map(function(user) {
            return {
              username: user["NAME"],
              avatar: `${MEDIA_SERVER}/useravatar/${user["NAME"]}.jpg`
            };
          })
        });
      })
      .catch(err => {
        const errorMessage = err.responseJSON.message;
        wattpad.utils.showToast(errorMessage, {
          type: "dismissable"
        });

        dispatch({ type: ERROR });
        throw err;
      });
  };
}

export function setBlockedUsers(username) {
  const url = `v4/users/${username}/blocks?get_all=true`;

  return function(dispatch, getState) {
    if (getState().profileData.blockListLoaded) {
      return;
    }

    const request = Promise.resolve(
      $.ajax({
        url: url,
        type: "GET"
      })
    );

    return request
      .then(response => {
        dispatch({
          type: SET_BLOCKED_USERS,
          blockedUsers: response.users.map(function(user) {
            return {
              username: user.username,
              avatar: user.avatar
            };
          })
        });
      })
      .catch(err => {
        let errorMessage = err?.responseJSON?.message
          ? err?.responseJSON?.message
          : wattpad.utils.trans("Something went wrong. Please try again"); //prettier-ignore;

        wattpad.utils.showToast(errorMessage, {
          type: "dismissable"
        });

        dispatch({ type: ERROR });
        throw err;
      });
  };
}
// Because we're currently using both Redux and Backbone as data sources
// for muted and blocked users, we need a function to keep their local state sync'd.

export function setMutedUsersFromBackBone(username, action) {
  if (action === "mute") {
    return function(dispatch) {
      dispatch({ type: MUTE, name: username });
    };
  } else {
    return function(dispatch) {
      dispatch({ type: UNMUTE, name: username });
    };
  }
}

export function setBlockedUsersFromBackBone(username, action) {
  if (action === "block") {
    return function(dispatch) {
      dispatch({ type: BLOCK, name: username });
    };
  } else {
    return function(dispatch) {
      dispatch({ type: UNBLOCK, name: username });
    };
  }
}
