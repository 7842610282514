import React from "react";
import { useDispatch } from "react-redux";

import AuthForm from "../views/authentication/AuthForm";
import { toggleModal } from "../shared-components/modals/actions";
import { useTrans } from "./useTrans";
import { useFeatureFlags } from "./useFeatureFlags";

export const useSignupModal = () => {
  const dispatch = useDispatch();
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  const signupTitle = trans(
    "Sign up to comment and interact with the largest storytelling community"
  );

  const createAuthForm = () => {
    return featureFlags.NEW_ONBOARDING_1 ? (
      <AuthForm nextUrl={window.location.href} title={signupTitle} />
    ) : (
      <AuthForm nextUrl={window.location.href} />
    );
  };

  const openSignupModal = (classes = "") => {
    dispatch(
      toggleModal({
        className: `signup-modal ${classes}`,
        disableScroll: true,
        useGlobalModalContainer: true,
        component: () => createAuthForm()
      })
    );
  };

  return { openSignupModal };
};
