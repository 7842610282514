import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../hooks";
import { simpleShorten } from "../helpers";

const AuthorName = ({
  username,
  avatar = "/img/avatar/empty.png",
  authorCharLimit = 0,
  shouldLinkify = false
}) => {
  const { trans } = useTrans();
  return (
    <div className="author-info">
      <img
        src={avatar ? avatar : "/img/avatar/empty.png"}
        alt=""
        className="author-info__badge"
      />
      <div className="author-info__username">
        <a
          href={"/user/" + username}
          className="on-navigate"
          aria-label={trans(
            "by %s. Tap to go to the author's profile page.",
            username
          )}
        >
          {authorCharLimit
            ? simpleShorten(username, authorCharLimit, shouldLinkify)
            : username}
        </a>
      </div>
    </div>
  );
};

AuthorName.propTypes = {
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  authorCharLimit: PropTypes.number,
  shouldLinkify: PropTypes.bool
};

export default AuthorName;
