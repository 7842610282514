import React from "react";
import PropTypes from "prop-types";

// React version of ConnectImage helper
const ConnectImage = ({
  name,
  title,
  className,
  id,
  height,
  width,
  ...rest
}) => {
  return (
    <img
      src={`/img/${name}`}
      alt={title}
      className={className}
      id={id}
      height={height}
      width={width}
      {...rest}
    />
  );
};

ConnectImage.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

export default ConnectImage;
