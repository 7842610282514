import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "@wattpad/web-ui-library";

import { ConnectImage } from "../../../shared-components";
import { useTrans } from "../../../hooks";
import { ScrollSmoothCenter } from "../CommentsConstants";

export const CommentsStageType = {
  ERROR: "error",
  EMPTY: "empty",
  NOT_FOUND: "notFound"
};

const StagePropsByType = trans => ({
  [CommentsStageType.EMPTY]: {
    imageId: "no-comments-graphics",
    imageName: "comments/no-comments.svg",
    message: `${trans("Be the first to comment")} 💬`
  },
  [CommentsStageType.ERROR]: {
    showRetry: true,
    imageId: "failed-comment-fetch",
    imageName: "comments/failed-fetch.svg",
    message: trans("There was an issue retrieving comments.")
  },
  [CommentsStageType.NOT_FOUND]: {
    autofocus: true,
    imageId: "comment-not-found",
    imageName: "comments/failed-fetch.svg",
    message: trans("The comment cannot be found.")
  }
});

const CommentsEmptyStage = ({ stageType, onRetryFetch }) => {
  const { trans } = useTrans();
  const autofocusRef = useRef();
  const stageProps = StagePropsByType(trans)[stageType];

  const initializeEmptyStage = () => {
    if (stageProps.autofocus) {
      autofocusRef.current?.scrollIntoView(ScrollSmoothCenter);
    }
  };

  useEffect(initializeEmptyStage, []);

  return (
    <div className="comments-empty-stage" ref={autofocusRef}>
      <ConnectImage name={stageProps.imageName} id={stageProps.imageId} />
      <p className="message">{stageProps.message}</p>
      {stageProps.showRetry && (
        <Button onClick={onRetryFetch} label={trans("Retry")} />
      )}
    </div>
  );
};

CommentsEmptyStage.propTypes = {
  stageType: PropTypes.oneOf(Object.values(CommentsStageType)).isRequired,
  onRetryFetch: PropTypes.func
};

export default CommentsEmptyStage;
