import { DmcaFormFieldIds } from "./HelpDmcaConstants";

const ReportStoryDmca = {
  TYPE: "story",
  TICKET_ID: 48864,
  ZENDESK_REASON: "reported_copyright",
  SUBJECT: "Story Report",
  DMCA_FIELD_ID: 25248746,
  DMCA_REASON: "dmca"
};

const REPORT_TYPE_ID = 21523158;

const appendFieldToObject = formElements => (object, fieldId) => ({
  ...object,
  [fieldId]: formElements[fieldId].value
});

const formElementToData = formElements =>
  Object.values(DmcaFormFieldIds).reduce(appendFieldToObject(formElements), {});

export const handleFormSubmit = handleSubmit => event => {
  event.preventDefault();

  const formElement = event.target;
  const isValid = formElement.checkValidity();

  const firstInvalidField = formElement.querySelector(":invalid");
  firstInvalidField?.focus();

  const formState = {
    isValid,
    data: formElementToData(formElement)
  };

  handleSubmit(formState);
};

export const getStoryUrl = storyId =>
  storyId ? `${window.location.origin}/story/${storyId}` : "";

export const getStoryPartUrl = partId =>
  partId ? `${window.location.origin}/${partId}` : "";

const getUserUrl = username =>
  username ? `${window.location.origin}/user/${username}` : "";

const createComment = (formData, storyId, partId, reportedUser) =>
  "-------------------------------------\n" +
  "Form Information\n" +
  "-------------------------------------\n" +
  `First name: ${formData.firstName}\n` +
  `Last name: ${formData.lastName}\n` +
  `Wattpad username: ${formData.username}\n` +
  `Email: ${formData.email}\n` +
  `Company: ${formData.company}\n` +
  `Mailing address: ${formData.address}\n` +
  `Country: ${formData.country}\n` +
  `City: ${formData.city}\n` +
  `State/Province: ${formData.province}\n` +
  `Postal code: ${formData.postalCode}\n` +
  `Phone number: ${formData.phoneNumber}\n` +
  `Description of the alleged infringement: ${formData.descriptionAlleged}\n` +
  `URL of your original work: ${formData.originalWorkUrl}\n` +
  `Please tell us where we can find the alleged infringement within the story or account: ${
    formData.whereToFindOriginal
  }\n` +
  `URL(s) of the allegedly infringing work located on Wattpad: ${
    formData.reportedUrls
  }\n` +
  `The information in this notice is accurate: ${formData.isAccurate}\n` +
  `I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law: ${
    formData.goodFaith
  }\n` +
  `I state under penalty of perjury that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed: ${
    formData.owner
  }\n` +
  `Electronic signature: ${formData.signature}` +
  "\n-------------------------------------\n" +
  "Type of Content: Story\n" +
  "Type of Report: DMCA\n" +
  (partId ? `Reported Story Part: ${getStoryPartUrl(partId)}\n` : "") +
  `Reported Story: ${getStoryUrl(storyId)}\n` +
  `Reported User's Profile: ${getUserUrl(reportedUser)}\n` +
  `Reported Group ID: ${storyId ? storyId : ""}\n`;

export const createTicketRequest = (
  formData,
  storyId,
  partId,
  reportedUser,
  language
) => ({
  requester: {
    name: `${formData.firstName} ${formData.lastName}`,
    email: formData.email
  },
  id: storyId,
  comment: createComment(formData, storyId, partId, reportedUser),
  ticket_form_id: ReportStoryDmca.TICKET_ID,
  username: reportedUser,
  reportType: ReportStoryDmca.TYPE,
  reason: ReportStoryDmca.ZENDESK_REASON,
  subject: ReportStoryDmca.SUBJECT,
  custom_fields: [
    {
      id: 22592474,
      value: language || "English"
    },
    {
      id: 21519886,
      value: wattpad.utils.getDeviceType() === "mobile" ? "mobile_web" : "web"
    },
    {
      id: REPORT_TYPE_ID,
      value: ReportStoryDmca.ZENDESK_REASON
    },
    {
      id: ReportStoryDmca.DMCA_FIELD_ID,
      value: ReportStoryDmca.DMCA_REASON
    }
  ]
});

export const submitSupportTicket = ticketData => {
  return $.ajax({
    type: "POST",
    url: "/v4/support/tickets/",
    data: ticketData
  });
};
