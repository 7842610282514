import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";

import SuccessBanner from "./SuccessBanner";
import CompleteEligibleBanner from "./CompleteEligibleBanner";
import CompleteNearEligibleBanner from "./CompleteNearEligibleBanner";
import { WPModal } from "../../../shared-components";
import ConnectImage from "../../../shared-components/ConnectImage";

import {
  openContestForm,
  fetchSubmittedFormBannerInfo
} from "../ContestForm/reducers";
import { injectTrans } from "../../../helpers";
import { COMPLETE_FLAG, ONGOING_FLAG } from "../ContestForm/constants";
import OngoingEligibleBanner from "./OngoingEligibleBanner";

class ContestBannerUI extends Component {
  componentDidMount() {
    if (this.props.storyId) {
      this.props.hasSubmitted &&
        this.props.fetchSubmittedFormBannerInfo(this.props.storyId);

      window.te.push("event", "wattys", "banner", null, "view", {
        storyid: parseInt(this.props.storyId),
        type: this.props.hasSubmitted
          ? "confirmed_submission"
          : this.props.isNearEligible
            ? "near_eligible"
            : "eligible",
        page: "story_details"
      });
    }
  }

  onOpenModal = () => {
    this.props.openContestForm(this.props.storyId);
    window.te.push("event", "wattys", "banner", null, "click", {
      storyid: parseInt(this.props.storyId),
      type: "eligible",
      page: "story_details"
    });
  };

  renderLoadingBanner = () => (
    <div className="text">
      <div className="loading-spinner-banner">
        <ConnectImage
          name="/icons/spinner.svg"
          className="fa-spin"
          alt="Loading spinner"
          height="40"
          width="40"
        />
      </div>
    </div>
  );

  renderProperBanner = () => {
    if (this.props.hasSubmitted) {
      return <SuccessBanner />;
    } else if (
      this.props.isNearEligible &&
      this.props.storyStatus === COMPLETE_FLAG
    ) {
      return <CompleteNearEligibleBanner />;
    } else if (this.props.storyStatus === COMPLETE_FLAG) {
      return <CompleteEligibleBanner onBtnClick={this.onOpenModal} />;
    } else if (this.props.storyStatus === ONGOING_FLAG) {
      return <OngoingEligibleBanner onBtnClick={this.onOpenModal} />;
    }
  };

  render() {
    const { hasSubmitted, isLoading, hasCheckedSubmission } = this.props;

    const coverImage = classNames({
      "cover-submitted-success": hasSubmitted,
      "cover-eligibility": !hasSubmitted
    });

    if (!hasCheckedSubmission) {
      return null;
    }

    return (
      <div id="contest-banner" className={coverImage}>
        {isLoading ? this.renderLoadingBanner() : this.renderProperBanner()}
        <WPModal />
      </div>
    );
  }
}

ContestBannerUI.propTypes = {
  openContestForm: PropTypes.func,
  storyId: PropTypes.string.isRequired,
  fetchSubmittedFormBannerInfo: PropTypes.func.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNearEligible: PropTypes.bool.isRequired,
  hasCheckedSubmission: PropTypes.bool.isRequired,
  storyStatus: PropTypes.any
};

const ContestBanner = connect(
  state => ({
    hasSubmitted: state.contestForm.hasSubmitted,
    isLoading: state.contestForm.isLoading,
    isNearEligible: state.contestForm.isNearEligible,
    hasCheckedSubmission: state.contestForm.hasCheckedSubmission,
    storyStatus: state.contestForm.data.storyStatus
  }),
  {
    openContestForm,
    fetchSubmittedFormBannerInfo
  }
)(injectTrans(ContestBannerUI));

export { ContestBanner, ContestBannerUI };
