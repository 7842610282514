import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../../hooks";
import { MAP_MATURE_THEMES } from "../constants";
import { NewCheckbox } from "../../../../shared-components";
import ContestFormField from "../ContestFormField";
import OtherMatureThemesField from "./OtherMatureThemesField";
import StoryMaturityField from "./StoryMaturityField";
import ErrorField from "./ErrorField";

const MatureThemesField = ({
  form,
  disableForm,
  isFrenchForm,
  onMatureThemesChange,
  requiredMatureThemesError,
  fieldStatus,
  submitted,
  onOtherMatureFieldTextChange,
  onFormChange
}) => {
  const { trans } = useTrans();

  const title = trans("Mature themes");

  const subtitle = (
    <div className="subtitle">
      {trans("Select the theme(s) that your story contains.")}
    </div>
  );

  const errorMessage =
    requiredMatureThemesError && !disableForm
      ? trans("You must pick at least 1")
      : "";

  const errorCondition =
    !requiredMatureThemesError &&
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "matureThemes" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.matureThemes.length === 0;

  return (
    <>
      <ContestFormField title={title} subtitle={subtitle}>
        <div id="mature-themes">
          {MAP_MATURE_THEMES(trans, isFrenchForm).map(theme => (
            <div className="checkbox-group" key={theme.key}>
              <NewCheckbox
                name={theme.key}
                disabled={disableForm}
                onChange={onMatureThemesChange}
                contentId={`mature-themes-${theme.key}`}
                checked={form.matureThemes.includes(theme.key)}
              >
                {theme.value}
              </NewCheckbox>
            </div>
          ))}
        </div>

        <ErrorField
          errorMessage={errorMessage}
          errorCondition={errorCondition}
          fieldId={"mature-themes"}
        />

        {form.matureThemes.includes("OTHER") && (
          <OtherMatureThemesField
            form={form}
            disableForm={disableForm}
            onOtherMatureFieldTextChange={onOtherMatureFieldTextChange}
          />
        )}
      </ContestFormField>

      {form.matureThemes.includes("SEX") && (
        <StoryMaturityField
          form={form}
          disableForm={disableForm}
          onFormChange={onFormChange}
          fieldStatus={fieldStatus}
          submitted={submitted}
        />
      )}
    </>
  );
};

MatureThemesField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  isFrenchForm: PropTypes.bool.isRequired,
  onMatureThemesChange: PropTypes.func.isRequired,
  requiredMatureThemesError: PropTypes.bool.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired,
  onOtherMatureFieldTextChange: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired
};

export default MatureThemesField;
