import React, { useEffect, useState } from "react";
import { Icon } from "../../shared-components";

const CookieManager = () => {
  const [cookieList, setCookieList] = useState([]);
  const [newCookieName, setNewCookieName] = useState("");
  const [newCookieValue, setNewCookieValue] = useState("");

  useEffect(
    () => {
      let cookies = document.cookie.split("; ");
      cookies.sort(function(a, b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        if (a == b) return 0;
        return a < b ? -1 : 1;
      });
      cookies = cookies.map(cookie => {
        const cookieParts = cookie.split("=");
        return {
          name: cookieParts[0],
          value: cookieParts[1]
        };
      });
      setCookieList(cookies);
    },
    [setCookieList]
  );

  const setCookie = (name, value, daysUntilExpiry = 365) => {
    const date = new Date();
    date.setTime(date.getTime() + daysUntilExpiry * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${date.toUTCString()}; path=/; SameSite=Lax; Secure;`;
  };

  const addNewCookie = () => {
    if (!newCookieName) return;

    setCookie(newCookieName, newCookieValue);
    let newcookieList = [...cookieList];

    const existingCookieIndex = newcookieList.findIndex(
      cookie => cookie.name == newCookieName
    );
    if (existingCookieIndex > -1) newcookieList.splice(existingCookieIndex, 1);

    newcookieList.push({
      name: newCookieName,
      value: newCookieValue
    });

    newcookieList.sort(
      (a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : 0
    );
    setCookieList(newcookieList);
    setNewCookieName("");
    setNewCookieValue("");
  };

  const deleteCookie = name => {
    if (!confirm(`Permanently delete cookie '${name}'?`)) return;

    setCookie(name, "", -365);
    const cookieIndex = cookieList.findIndex(cookie => cookie.name == name);
    cookieList.splice(cookieIndex, 1);
    setCookieList([...cookieList]);
  };

  return (
    <div id="cookie-manager">
      <div className="col-12 col-md-2" />
      <div className="col-12 col-md-8">
        <h4>Manage your Wattpad Cookies</h4>

        <div className="new-cookie-row">
          <input
            type="text"
            name="cookie-name"
            placeholder="NAME"
            value={newCookieName}
            onChange={e => setNewCookieName(e.target.value)}
          />
          <input
            type="text"
            name="cookie-value"
            placeholder="VALUE"
            value={newCookieValue}
            onChange={e => setNewCookieValue(e.target.value)}
          />
          <button onClick={addNewCookie}>Add Cookie</button>
        </div>

        <ul className="cookie-list">
          <li className="cookie-header-row">
            <div>Name</div>
            <div>Value</div>
          </li>
          {cookieList.map((cookie, i) => (
            <li className="cookie-row" key={`cookie-${i}`}>
              <div>{cookie.name}</div>
              <div>{cookie.value}</div>
              <button
                onClick={() => {
                  deleteCookie(cookie.name);
                }}
              >
                <Icon name="trash" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CookieManager;
