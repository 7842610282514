import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

export default class MenuModal extends React.Component {
  render() {
    let headerName = this.props.headerName;
    let options = this.props.options;

    return (
      <div className="menu-modal">
        <div className="header">
          {headerName}
          <span data-dismiss="modal">
            <Icon iconName="fa-remove" height="14" color="wp-neutral-2" />
          </span>
        </div>

        <ul className="menu-items">
          {options.map(option => {
            const categoryUrl =
              option.browseURL || wattpad.utils.categoryNameToUrl(option.key);
            return (
              <li key={option.key}>
                {option.url ? (
                  headerName === "Browse" ? (
                    <a href={categoryUrl} className="on-navigate on-topic">
                      {option.name}
                    </a>
                  ) : (
                    <a href={categoryUrl} className="on-navigate">
                      {option.name}
                    </a>
                  )
                ) : (
                  <span className="selected">{option.name}</span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

MenuModal.propTypes = {
  headerName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};
