import React from "react";
import PropTypes from "prop-types";

const CopyrightToolTip = ({
  title,
  mainContent,
  secondaryContent,
  link,
  button,
  closePopover,
  handleHelpLink
}) => {
  return (
    <div className="react-popover-tooltip">
      <h3 className="popover-title">{title}</h3>
      <div className="popover-content">
        {mainContent && <div className="main-content">{mainContent}</div>}

        {secondaryContent && (
          <div className="secondary-content">{secondaryContent}</div>
        )}

        <div className="tooltip-footer">
          {link && (
            <a target="blank" href={link.href} onClick={handleHelpLink}>
              {link.text}
            </a>
          )}
          {button && (
            <button
              className="btn on-okay btn-orange"
              onClick={() => closePopover()}
            >
              {button.text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

CopyrightToolTip.defaultProps = {
  closePopover: e => e.preventDefault(),
  handleHelpLink: () => {}
};

CopyrightToolTip.propTypes = {
  title: PropTypes.string,
  mainContent: PropTypes.string,
  secondaryContent: PropTypes.string,
  link: PropTypes.object,
  button: PropTypes.object,
  closePopover: PropTypes.func,
  handleHelpLink: PropTypes.func
};

export default CopyrightToolTip;
