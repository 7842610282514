// Polyfills required by React for IE11 support
// These must be at the top of the file
import "@babel/polyfill";
import raf from "raf/polyfill"; // eslint-disable-line no-unused-vars

// Redux Store
import createStore from "@wattpad-hq/root-setup/createStore";
import withRedux from "@wattpad-hq/root-setup/withRedux";
import ReactRoot from "@wattpad-hq/root-setup/ReactRoot";

// Container Components
import { Inbox } from "@wattpad-hq/views/inbox/inbox";
import Home from "@wattpad-hq/views/home/Home";
import StoryDetails from "@wattpad-hq/views/story-details/StoryDetails";

// Presentational Components
import AnnouncementModal from "@wattpad-hq/shared-components/modals/components/AnnouncementModal";
import MenuModal from "@wattpad-hq/shared-components/modals/components/MenuModal";
import UserSettingsModal from "@wattpad-hq/shared-components/modals/components/UserSettingsModal";

import UserSettings from "@wattpad-hq/views/user-settings/UserSettings";
import PleaseVerify from "@wattpad-hq/views/verification/PleaseVerify";
import { VerifyEmail } from "@wattpad-hq/views/verification/VerifyEmail";
import AccountSocialMedia from "@wattpad-hq/views/user-settings/AccountSocialMedia";
import ContentPreferences from "@wattpad-hq/views/user-settings/ContentPreferences";
import CookieManager from "@wattpad-hq/views/user-settings/CookieManager";

import ReportModal from "@wattpad-hq/views/report/ReportModal";
import BlockModal from "@wattpad-hq/shared-components/modals/components/user-safety-modals/BlockModal";
import UnblockModal from "@wattpad-hq/shared-components/modals/components/user-safety-modals/UnblockModal";

import {
  MenuOptions,
  Icon,
  SocialSharing,
  Avatar,
  ReportStoryPart,
  StoryPartBonusHeader,
  WPModal
} from "@wattpad-hq/shared-components";

import BrowseStoryItem from "@wattpad-hq/views/story-item/BrowseStoryItem";
import { StoryItem } from "@wattpad-hq/views/story-item/StoryItem";
import TagCarousel from "@wattpad-hq/views/tags/TagCarousel";
import { TagCloud as TagGrid, AppProvider } from "@wattpad/web-ui-library";
import TagMeta from "@wattpad-hq/views/tags/TagMeta";
import TagItem from "@wattpad-hq/views/tags/TagItem";
import TagItemWithIcon from "@wattpad-hq/views/tags/TagItemWithIcon";

import LargePromoBanner from "@wattpad-hq/views/banners/LargePromoBanner";
import { CanvaButton } from "@wattpad-hq/shared-components/integration/CanvaButton";

import ThemeSettings from "@wattpad-hq/views/profile/components/ThemeSettings";
import ProfileHeader from "@wattpad-hq/views/profile/components/ProfileHeader";
import RestrictedProfile from "@wattpad-hq/views/profile/RestrictedProfile";
import { Toast } from "@wattpad-hq/shared-components/toasts/Toast";
import ProfileMoreOptionsButton from "@wattpad-hq/views/profile/ProfileMoreOptionsButton";
import UserError404 from "@wattpad-hq/views/error-404/UserError404";
import StoryError404 from "@wattpad-hq/views/error-404/StoryError404";
import MerchMessage from "@wattpad-hq/views/merch/MerchMessage";

import StoryPartPaywall from "@wattpad-hq/views/paid-content/StoryPartPaywall";
import StoryPaywallModal from "@wattpad-hq/views/paid-content/StoryPaywallModal";
import Wallet from "@wattpad-hq/views/paid-content/Wallet";
import WalletWithOnboarding from "@wattpad-hq/views/paid-content/WalletWithOnboarding";
import { showPaidOnboardingModal } from "@wattpad-hq/views/paid-content/PaidOnboardingModal";

import BuyCoinsReactModal from "./views/paid-content/BuyCoinsReactModal";

import LandingPage from "@wattpad-hq/views/landing/LandingPage";

import { ContestBanner } from "@wattpad-hq/views/contests/ContestBanner";
import { ContestForm } from "@wattpad-hq/views/contests/ContestForm";

import AdContainer from "@wattpad-hq/shared-components/AdContainer";
import { StoryTags } from "@wattpad-hq/views/story-details/StoryTags/StoryTags";

import RequestAccountDetails from "@wattpad-hq/views/onboarding/RequestAccountDetails";
import OnboardingWriterJourney from "@wattpad-hq/views/onboarding/OnboardingWriterJourney";
import OnboardingGenres from "@wattpad-hq/views/onboarding/OnboardingGenres";
import OnboardingSuccess from "@wattpad-hq/views/onboarding/OnboardingSuccess";
import OnboardingDetails from "@wattpad-hq/views/onboarding/OnboardingDetails";

import BannedImageMessaging from "@wattpad-hq/views/writer/BannedImageMessaging";

import ConfigurePaidPartModalButton from "@wattpad-hq/views/writer/configure-paid-part-modal/ConfigurePaidPartModalButton";

import PublishButton from "@wattpad-hq/views/writer/PublishButton";
import ScheduleSuccessModal from "@wattpad-hq/views/writer/ScheduleSuccessModal";

import ImageAppealFlow from "@wattpad-hq/views/image-appeal-flow/ImageAppealFlow";

import { PrivacySettings } from "@wattpad-hq/views/privacy-settings/PrivacySettings";
import {
  Header,
  DSContainer,
  Button
} from "@wattpad-hq/design-system/elements";
import { OneColPage } from "@wattpad-hq/design-system/layouts";

import EditWorksButtons from "./shared-components/EditWorksButtons";

import WorksItemsDetailsNavBar from "@wattpad-hq/views/works-item-details/WorksItemsDetailsNavBar";
import StoryNotes from "@wattpad-hq/views/works-item-details/story-notes/StoryNotes";
import Acknowledge from "@wattpad-hq/views/works-item-details/Acknowledge";

import WriterAnalyticsNavBar from "@wattpad-hq/views/writer-analytics/WriterAnalyticsNavBar";
import WriterStoryStats from "./views/writer-analytics/WriterStoryStats";
import QURLabel from "./views/writer-analytics/QURLabel";

import LineChartHeader from "./views/writer-analytics/LineChartHeader";

import AuthForm from "@wattpad-hq/views/authentication/AuthForm";
import SignupForm from "@wattpad-hq/views/authentication/SignupForm";
import LoginForm from "@wattpad-hq/views/authentication/LoginForm";
import { ForgotPassword } from "@wattpad-hq/views/authentication/ForgotPassword";
import { ResetPassword } from "@wattpad-hq/views/authentication/ResetPassword";
import EmailSignup from "@wattpad-hq/views/authentication/EmailSignup";

import { Quests } from "@wattpad-hq/views/quests/Quests";
import { EmbeddedQuests } from "@wattpad-hq/views/quests/EmbeddedQuests";

import PremiumPicksBanner from "./views/library/PremiumPicksBanner";

import Copyright from "./views/works-item-details/story-details/Copyright";
import HelpDmcaPage from "./views/help/dmca/HelpDmcaPage";
import StoryPartNavigation from "./views/story-reading/StoryPartNavigation/StoryPartNavigation";
import StoryPartComments from "./views/story-reading/StoryPartComments/StoryPartComments";
import ParagraphCommentsCount from "./views/story-reading/ParagraphCommentsCount/ParagraphCommentsCount";
import CommentOptions from "@wattpad-hq/views/comments/CommentOptions/CommentOptions";

// Actions
import { fetchWalletBalance } from "@wattpad-hq/views/paid-content/actions";
import {
  fetchPaidContentMetadata,
  cacheBustPaidContentMetadata
} from "@wattpad-hq/views/paid-content/paidMetadata";
import {
  openContestForm,
  setContestFormSubmissionStatus,
  setContestFormNearEligibleStatus,
  setContestFormStoryStatus
} from "@wattpad-hq/views/contests/ContestForm/reducers";

import {
  fetchHomeSections,
  clearError,
  callTrackingUrls
} from "@wattpad-hq/views/home/actions";
import { setStoryTags } from "@wattpad-hq/views/story-details/StoryTags/reducers";
import { resetConversation } from "@wattpad-hq/views/inbox/Conversation/reducers";
import { resetThreads } from "@wattpad-hq/views/inbox/Threads/reducers";
import {
  initializeMutedUsers,
  setMutedUsersFromBackBone,
  setBlockedUsersFromBackBone
} from "@wattpad-hq/views/profile/reducers";

import { toggleToast } from "@wattpad-hq/shared-components/toasts/reducers";
import { postStoryNotes } from "@wattpad-hq/views/works-item-details/story-notes/actions";
import { updateActiveTab } from "@wattpad-hq/views/works-item-details/reducers";

import ExternalCoverUpload from "@wattpad-hq/views/myworks/ExternalCoverUpload";

import TagpagePaidStoriesContainer from "@wattpad-hq/views/tags/TagpagePaidStoriesContainer";

import { Catalog, CatalogHeader } from "@wattpad-hq/views/catalog";

import TryPremium from "./views/premium/TryPremium";
import WriteTooltip from "./views/home/components/WriteTooltip";

import ValidationSuccess from "@wattpad-hq/views/validation-success/ValidationSuccess";

/**
 * New Search components
 */

import SearchBar from "@wattpad-hq/shared-components/SearchBar";
import StoryCard from "@wattpad-hq/shared-components/StoryCard";
import RecentSearches from "@wattpad-hq/shared-components/RecentSearches";
import EducationalBanner from "@wattpad-hq/shared-components/EducationalBanner";
import LastUpdatedFilter from "@wattpad-hq/shared-components/LastUpdatedFilter";
import ProfileCardContainer from "@wattpad-hq/views/search/ProfileCardContainer";
import StoryCardContainer from "@wattpad-hq/views/search/StoryCardContainer";
import BrowseCategories from "@wattpad-hq/shared-components/BrowseCategories";
import SearchTabs from "@wattpad-hq/shared-components/SearchTabs";
import SearchNoResults from "@wattpad-hq/shared-components/SearchNoResults";
import FiltersContainer from "@wattpad-hq/views/search/FiltersContainer";
import SearchHeader from "@wattpad-hq/views/search/SearchHeader";
import BlankSearch from "@wattpad-hq/views/search/BlankSearch";
import DateTimeField from "@wattpad-hq/shared-components/input/DateTimeField";
import { GoogleOneTapContainer } from "@wattpad-hq/views/google/GoogleOneTap";

const actions = {
  fetchWalletBalance,
  fetchPaidContentMetadata,
  cacheBustPaidContentMetadata,
  fetchHomeSections,
  clearError,
  openContestForm,
  setStoryTags,
  resetConversation,
  resetThreads,
  initializeMutedUsers,
  toggleToast,
  callTrackingUrls,
  postStoryNotes,
  setMutedUsersFromBackBone,
  setBlockedUsersFromBackBone,
  updateActiveTab,
  setContestFormSubmissionStatus,
  setContestFormNearEligibleStatus,
  setContestFormStoryStatus
};

// Export components used by Handlebars reactComponent helper to `window.app.components`
// Only React root components used by Handlebars directly need to be exported, not child components
export {
  AppProvider,
  ReactRoot,
  AnnouncementModal,
  ExternalCoverUpload,
  MenuModal,
  MenuOptions,
  Avatar,
  ReportStoryPart,
  TagCarousel,
  TagGrid,
  TagMeta,
  TagItem,
  TagItemWithIcon,
  LargePromoBanner,
  ThemeSettings,
  StoryPartPaywall,
  StoryPaywallModal,
  Wallet,
  WalletWithOnboarding,
  SocialSharing,
  Icon,
  BrowseStoryItem,
  StoryItem,
  UserSettings,
  UserSettingsModal,
  ContentPreferences,
  CookieManager,
  AccountSocialMedia,
  withRedux,
  Inbox,
  LandingPage,
  ContestBanner,
  ContestForm,
  StoryTags,
  RequestAccountDetails,
  OnboardingWriterJourney,
  OnboardingGenres,
  OnboardingSuccess,
  OnboardingDetails,
  BannedImageMessaging,
  showPaidOnboardingModal,
  BuyCoinsReactModal,
  ImageAppealFlow,
  WPModal,
  actions,
  createStore,
  PrivacySettings,
  Header,
  DSContainer,
  Button,
  OneColPage,
  WriterAnalyticsNavBar,
  WriterStoryStats,
  QURLabel,
  LineChartHeader,
  WorksItemsDetailsNavBar,
  Home,
  ProfileHeader,
  RestrictedProfile,
  Toast,
  UserError404,
  StoryError404,
  MerchMessage,
  ProfileMoreOptionsButton,
  CanvaButton,
  AuthForm,
  SignupForm,
  LoginForm,
  EmailSignup,
  ForgotPassword,
  ResetPassword,
  TagpagePaidStoriesContainer,
  AdContainer,
  Catalog,
  CatalogHeader,
  Quests,
  EmbeddedQuests,
  StoryNotes,
  Acknowledge,
  Copyright,
  HelpDmcaPage,
  StoryPartNavigation,
  StoryPartComments,
  ParagraphCommentsCount,
  StoryPartBonusHeader,
  TryPremium,
  WriteTooltip,
  StoryDetails,
  ValidationSuccess,
  PleaseVerify,
  VerifyEmail,
  ReportModal,
  BlockModal,
  UnblockModal,
  SearchBar,
  RecentSearches,
  StoryCard,
  ProfileCardContainer,
  BrowseCategories,
  EducationalBanner,
  StoryCardContainer,
  SearchTabs,
  SearchNoResults,
  FiltersContainer,
  LastUpdatedFilter,
  SearchHeader,
  BlankSearch,
  ConfigurePaidPartModalButton,
  PublishButton,
  ScheduleSuccessModal,
  CommentOptions,
  EditWorksButtons,
  DateTimeField,
  PremiumPicksBanner,
  GoogleOneTapContainer
};

// Exported to window (used by Monaco code)
import React from "react";
import ReactDOM from "react-dom";
const window = window || global;
window.React = React;
window.ReactDOM = ReactDOM;
