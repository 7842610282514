import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  clearError,
  callTrackingUrls,
  fetchHomeSections,
  openContentSettings,
  openStoryDetails,
  receiveSectionPosition,
  sectionViewed,
  getReadingLists,
  getCollectionsForStoryIds,
  updateCollection,
  removeStoriesFromCollection,
  openEmailReverification,
  fetchEmailReverificationStatus
} from "./actions";
import { errorTypes } from "./constants";
import SectionFactory from "./SectionFactory";
import { WPModal } from "../../shared-components";
import { Loader } from "../../shared-components/Loader";
import FailToLoadHome from "./components/FailToLoadHome";
import TryPremium from "../premium/TryPremium";
import { openPopover } from "../../shared-components/popover/reducers";
import LibraryPopover from "./components/LibraryPopover";
import { Toast } from "../../shared-components/toasts/Toast";

export class HomeUI extends React.Component {
  static propTypes = {
    callTrackingUrls: PropTypes.func,
    clearError: PropTypes.func,
    error: PropTypes.object,
    fetchHomeSections: PropTypes.func,
    showLoader: PropTypes.bool,
    homeLoading: PropTypes.bool,
    openContentSettings: PropTypes.func,
    openStoryDetails: PropTypes.func,
    scrollIndex: PropTypes.number,
    sectionBounds: PropTypes.object,
    sections: PropTypes.array,
    sectionViewed: PropTypes.func,
    receiveSectionPosition: PropTypes.func,
    getReadingLists: PropTypes.func,
    readingLists: PropTypes.array,
    readingListsLoading: PropTypes.bool,
    getCollectionsForStoryIds: PropTypes.func,
    storiesCollections: PropTypes.object,
    storiesCollectionsLoading: PropTypes.bool,
    updateCollection: PropTypes.func,
    removeStoriesFromCollection: PropTypes.func,
    openPopover: PropTypes.func,
    openEmailReverification: PropTypes.func,
    fetchEmailReverificationStatus: PropTypes.func
  };

  state = {
    windowWidth: null,
    lastSectionLoadedIndex: 4,
    lastScrollY: 0,
    showContinueReading: true
  };

  sectionWrapperRef = React.createRef();
  sectionLimitPerPage = 5;

  componentDidMount() {
    if (this.props.fetchEmailReverificationStatus) {
      this.props.fetchEmailReverificationStatus();
    }

    // reading lists are currently only used for
    // add to library functionality. This is not
    // supported on mobile currently.

    if (
      wattpad.utils.getDeviceType() !== "mobile" &&
      this.props.readingLists === undefined &&
      !this.props.readingListsLoading
    ) {
      this.props.getReadingLists();
    }

    if (this.props.sections === undefined || this.props.sections.length === 0) {
      this.props.fetchHomeSections();
    }

    this.setState({ windowWidth: window.innerWidth });
    window.addEventListener("resize", () =>
      this.setState({ windowWidth: window.innerWidth })
    );

    const savedLastSection = window.wattpad.homeSectionsIndex;
    if (savedLastSection) {
      this.setState({ lastSectionLoadedIndex: savedLastSection });
    }

    window.addEventListener(
      "scroll",
      _.debounce(this.handleInfScroll, 300, {
        leading: false,
        trailing: true
      })
    );

    window.addEventListener("scroll", this.checkIntersections);

    window.addEventListener("scroll", this.showContinueReadingThrottled);

    window.te.push("event", "app", "page", null, "view", {
      page: "home"
    });
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.sectionBounds).length === 0 &&
      Object.keys(this.props.sectionBounds).length !== 0
    ) {
      this.checkIntersections();
    }
    if (prevProps.error !== this.props.error) {
      if (this.props.error) {
        // eslint-disable-next-line no-console
        console.error(
          `An error has occurred:\n${this.props.error.errorMessage}`
        );
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () =>
      this.setState({ windowWidth: window.innerWidth })
    );
    window.removeEventListener(
      "scroll",
      _.debounce(this.handleInfScroll, 300, {
        leading: false,
        trailing: true
      })
    );
    window.removeEventListener("scroll", this.checkIntersections);
    window.removeEventListener("scroll", this.showContinueReadingThrottled);
  }

  showContinueReading = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY <= 0 || currentScrollY < this.state.lastScrollY) {
      this.setState({ showContinueReading: true });
    } else {
      this.setState({ showContinueReading: false });
    }
    this.setState({ lastScrollY: currentScrollY });
  };

  showContinueReadingThrottled = _.throttle(this.showContinueReading, 300, {
    leading: true,
    trailing: false
  });

  handleInfScroll = () => {
    let lastSectionIndex;
    if (this.state.lastSectionLoadedIndex === this.props.sections.length - 1) {
      window.removeEventListener(
        "scroll",
        _.debounce(this.handleInfScroll, 300, {
          leading: false,
          trailing: true
        })
      );
      return;
    }
    if (this.isAtBottom(this.sectionWrapperRef.current)) {
      if (
        this.state.lastSectionLoadedIndex >
        this.props.sections.length - 1 - this.sectionLimitPerPage
      ) {
        lastSectionIndex = this.props.sections.length - 1;
      } else {
        lastSectionIndex =
          this.state.lastSectionLoadedIndex + this.sectionLimitPerPage;
      }

      this.setState({ lastSectionLoadedIndex: lastSectionIndex });
      window.wattpad.homeSectionsIndex = lastSectionIndex;
    }
  };
  handleOpenContentSettings = () => {
    this.props.openContentSettings();
    window.te.push("event", "app", "page", null, "view", {
      page: "content_settings",
      source: "home"
    });
  };
  isAtBottom = el => {
    return el && el.getBoundingClientRect().bottom - 200 <= window.innerHeight;
  };

  handleOpenStoryDetails = story => {
    this.props.openStoryDetails(story);
  };

  checkIntersections = () => {
    const viewportTop = window.scrollY;
    const viewportBottom = viewportTop + window.innerHeight;

    Object.keys(this.props.sectionBounds).map(index => {
      if (this.props.sectionBounds[index].viewed) return;
      const section = this.props.sectionBounds[index];
      const height = section.bottom - section.top;
      const offscreenTop =
        section.top < viewportTop ? viewportTop - section.top : 0;
      const offscreenBottom =
        section.bottom > viewportBottom ? section.bottom - viewportBottom : 0;

      const yIntersection = (height - offscreenTop - offscreenBottom) / height;

      const inView = yIntersection >= 0.5;

      if (inView) {
        window.te.push("event", "home", "section", null, "view", {
          type: section.type,
          position: section.index
        });
        this.props.sectionViewed(index);
      }
    });
  };

  handleLibraryClick = (buttonRef, storyId) => {
    const component = () => (
      <LibraryPopover
        readingLists={this.props.readingLists}
        storyId={storyId}
        updateCollection={this.props.updateCollection}
        removeStoriesFromCollection={this.props.removeStoriesFromCollection}
        storiesCollections={this.props.storiesCollections[storyId]}
        getReadingLists={this.props.getReadingLists}
        getCollectionsForStoryIds={this.props.getCollectionsForStoryIds}
      />
    );

    this.props.openPopover({
      component,
      triggerRef: buttonRef,
      passthroughProps: {
        showArrow: "true"
      }
    });
  };

  refresh = () => {
    this.props.clearError();
    this.props.fetchHomeSections();
  };

  render() {
    if (this.props.homeLoading && this.props.showLoader) {
      return (
        <div className="home-sections-wrapper loading-spinner">
          <Loader />
        </div>
      );
    }
    if (
      this.props.error &&
      this.props.error.errorType === errorTypes.fetchError
    ) {
      return <FailToLoadHome onRefresh={this.refresh} />;
    }
    if (this.props.sections === undefined || this.props.sections.length === 0) {
      return null;
    }
    return (
      <div className="home-sections-wrapper" ref={this.sectionWrapperRef}>
        <React.Fragment>
          {this.state.windowWidth < 575 && (
            <div className="try-premium-container">
              <TryPremium ctaType="home_header" />
            </div>
          )}
          {this.props.sections
            .slice(0, this.state.lastSectionLoadedIndex + 1)
            .map((moduleData, index) => {
              const wasSeen = !!(
                this.props.sectionBounds[index] &&
                this.props.sectionBounds[index].viewed
              );
              return (
                <SectionFactory
                  {...moduleData}
                  openContentSettings={this.handleOpenContentSettings}
                  openStoryDetails={this.handleOpenStoryDetails}
                  onLibraryClick={this.handleLibraryClick}
                  getCollectionsForStoryIds={
                    this.props.getCollectionsForStoryIds
                  }
                  storiesCollections={this.props.storiesCollections}
                  storiesCollectionsLoading={
                    this.props.storiesCollectionsLoading
                  }
                  readingLists={this.props.readingLists}
                  index={index}
                  key={`${moduleData.type}-${index}`}
                  setPosition={this.props.receiveSectionPosition}
                  wasSeen={wasSeen}
                  windowWidth={this.state.windowWidth}
                  callTrackingUrls={this.props.callTrackingUrls}
                />
              );
            })}
        </React.Fragment>
        <WPModal />
        <Toast />
      </div>
    );
  }
}

HomeUI.defaultProps = {
  homeLoading: true,
  showLoader: true
};

const mapDispatchToProps = {
  fetchHomeSections,
  receiveSectionPosition,
  sectionViewed,
  clearError,
  callTrackingUrls,
  openContentSettings,
  openStoryDetails,
  getReadingLists,
  getCollectionsForStoryIds,
  updateCollection,
  removeStoriesFromCollection,
  openPopover,
  openEmailReverification,
  fetchEmailReverificationStatus
};

const mapStateToProps = state => {
  const {
    homeLoading,
    showLoader,
    sections,
    sectionBounds,
    error,
    readingLists,
    readingListsLoading,
    storiesCollections,
    storiesCollectionsLoading
  } = state.homeSections;
  return {
    homeLoading,
    showLoader,
    sections,
    sectionBounds,
    error,
    readingLists,
    readingListsLoading,
    storiesCollections,
    storiesCollectionsLoading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeUI);
