import PropTypes from "prop-types";
import React from "react";

import { isClient, injectTrans } from "../../helpers";
import NotificationCheckbox from "./NotificationCheckbox";

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);

    const { alerts, alertssub, subscribe } = props;
    this.state = {
      alerts: parseInt(alerts, 10) === 1,
      alertssub,
      subscribe: parseInt(subscribe, 10) === 1
    };
  }

  handleSubmit = event => {
    this.props.updateNotifications(event);
  };

  onCheckboxChange = (input, value) => {
    let changes = {};
    changes[input] = value;
    this.setState(changes);
  };

  render() {
    const { trans, userId } = this.props;
    const { alerts, alertssub, subscribe } = this.state;
    return (
      <div id="notification">
        <h2 className="subheading">
          {trans("Receive email notifications for these events")}
        </h2>
        <form
          id="notifications_form"
          name="notifications_form"
          className="row"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="id" value={userId} />

          <NotificationCheckbox
            name={"alerts"}
            value={alerts}
            onCheckboxChange={this.onCheckboxChange}
          >
            <h4>{trans("Messages")}</h4>
            <ul>
              <li>{trans("Someone writes on your message board")}</li>
              <li>{trans("Someone starts following you")}</li>
              <li>{trans("Someone comments on your story")}</li>
              <li>{trans("Someone mentions you in a comment")}</li>
              <li>{trans("You receive a message")}</li>
            </ul>
          </NotificationCheckbox>

          <NotificationCheckbox
            name={"alertssub"}
            value={alertssub}
            onCheckboxChange={this.onCheckboxChange}
          >
            <h4>{trans("Users you follow upload a story")}</h4>
          </NotificationCheckbox>

          <NotificationCheckbox
            name={"subscribe"}
            value={subscribe}
            onCheckboxChange={this.onCheckboxChange}
          >
            <h4>{trans("Updates")}</h4>
            <ul>
              <li>{trans("Important announcements from Wattpad")}</li>
            </ul>
          </NotificationCheckbox>
          <div className="col-xs-12 submit">
            <input
              type="submit"
              className="btn btn-md btn-orange"
              value={trans("Save")}
            />
          </div>
          <input type="hidden" name="alertssubmask" value="0" />
        </form>
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  trans: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  alerts: PropTypes.any.isRequired,
  alertssub: PropTypes.bool.isRequired,
  subscribe: PropTypes.any.isRequired,
  updateNotifications: PropTypes.func
};

if (isClient()) {
  _.extend(NotificationSettings.propTypes, {
    updateNotifications: PropTypes.func.isRequired
  });
}

export default injectTrans(NotificationSettings);
