import React from "react";

import { ConnectImage } from "../../shared-components";
import { Container } from "./Container";
import { useTrans } from "../../hooks";

function WattpadStudios() {
  const { trans } = useTrans();
  function leftPart() {
    return (
      <div className="imageContainer">
        <ConnectImage
          width="414"
          name="/landing/wattpadStudiosPoster.png"
          title={trans("Wattpad Studios TV and film projects collage")}
          alt={trans("Wattpad Studios TV and film projects collage")}
        />
      </div>
    );
  }
  function rightPart() {
    return (
      <div className="textContainer">
        <ConnectImage
          width="203"
          name="/landing/wattpadStudiosLogo.svg"
          title="Wattpad Studios logo"
        />
        <h3>{trans("Your original story could be the next big hit")}</h3>
        <p className="constrainedText">
          {trans(
            "Wattpad Studios discovers untapped, unsigned, and talented writers on Wattpad and connects them to global multi-media entertainment companies."
          )}
        </p>
        <p>{trans("Wattpad Studios works with partners such as:")}</p>
        <ConnectImage
          className="partner"
          width="31"
          name="/landing/sony.svg"
          title="Sony logo"
        />
        <ConnectImage
          className="partner"
          width="61"
          name="/landing/hulu.svg"
          title="Hulu logo"
        />
        <ConnectImage
          className="partner"
          width="81"
          name="/landing/syfy.svg"
          title="SYFY logo"
        />
      </div>
    );
  }
  return <Container rightPart={rightPart()} leftPart={leftPart()} />;
}

export { WattpadStudios };
