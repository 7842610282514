import React from "react";

import { addStoryTag } from "../../story-details/StoryTags/reducers";
import { ContestForm } from "./ContestForm";
import { toggleModal } from "../../../shared-components/modals/actions";
import { SUBMITTED_FLAG } from "./constants";

const namespace = "CONTEST_FORM";

const FORM_REQUEST = `${namespace}/REQUEST`;
const FORM_FETCH = `${namespace}/FETCH`;
const FORM_SET = `${namespace}/SET`;
const FORM_ERROR = `${namespace}/ERROR`;
const FORM_SUBMITTED_SET = `${namespace}/SUBMITTED_SET`;
const FORM_NEAR_ELIGIBLE_SET = `${namespace}/NEAR_ELIGIBLE_SET`;
const FORM_STORY_STATUS_SET = `${namespace}/FORM_STORY_STATUS_SET`;

const ADDED_WATTYS_TAG = `${namespace}/ADDED_WATTYS_TAG`;

const initialState = {
  isLoading: true,
  hasCheckedSubmission: false,
  hasSubmitted: false,
  isNearEligible: false,
  hasAddedWattysTag: false,
  user: "",
  cover: "",
  title: "",

  data: {
    storyId: null,
    contest: "",
    wattys_submitted_time: 0,
    language: "",
    storyStatus: "",
    isSeries: "",
    seriesType: "",
    isSeriesStandalone: "",
    numberOfStoriesInSeries: "",
    totalPlannedStoriesInSeries: "",
    anticipatedStoryLength: "",
    diversity: [],
    diversityOther: "",
    storyType: "",
    genrePrimary: "",
    genreOthers: [],
    matureThemes: [],
    matureThemeOther: "",
    storyMaturity: "",
    logLine: "",
    plotSummary: "",
    writerEmail: "",
    legalConsent: false,
    finalConsent: false
  }
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FORM_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FORM_FETCH: {
      //Parse contest from server json to match our reducer data structure
      return {
        ...initialState,
        isLoading: false,
        hasCheckedSubmission: true,
        hasSubmitted:
          action?.eligibilityData?.eligibility === SUBMITTED_FLAG ||
          initialState.hasSubmitted,
        // Merge the new form data into the default ones
        data: {
          storyId: action.data.storyId || initialState.data.storyId,
          language: action.data.language || initialState.data.language,
          storyStatus: action.data.storyStatus || initialState.data.storyStatus,
          isSeries: action.data.isSeries || initialState.data.isSeries,
          seriesType: action.data.seriesType || initialState.data.seriesType,
          isSeriesStandalone:
            action.data.isSeriesStandalone ||
            initialState.data.isSeriesStandalone,
          numberOfStoriesInSeries:
            action.data.numberOfStoriesInSeries ||
            initialState.data.numberOfStoriesInSeries,
          totalPlannedStoriesInSeries:
            action.data.totalPlannedStoriesInSeries ||
            initialState.data.totalPlannedStoriesInSeries,
          anticipatedStoryLength:
            action.data.anticipatedStoryLength ||
            initialState.data.anticipatedStoryLength,
          diversity: action.data.diversity || initialState.data.diversity,
          diversityOther:
            action.data.diversityOther || initialState.data.diversityOther,
          storyType: action.data.storyType || initialState.data.storyType,
          genrePrimary:
            action.data.genrePrimary || initialState.data.genrePrimary,
          genreOthers: action.data.genreOthers || initialState.data.genreOthers,
          matureThemes:
            action.data.matureThemes || initialState.data.matureThemes,
          matureThemeOther:
            action.data.matureThemeOther || initialState.data.matureThemeOther,
          storyMaturity:
            action.data.storyMaturity || initialState.data.storyMaturity,
          logLine: action.data.logLine || initialState.data.logLine,
          plotSummary: action.data.plotSummary || initialState.data.plotSummary,

          writerEmail: action.data.writerEmail || initialState.data.writerEmail,
          legalConsent:
            action.data.legalConsent || initialState.data.legalConsent,
          finalConsent:
            action.data.finalConsent || initialState.data.finalConsent
        },
        cover: action.eligibilityData.storyCover,
        title: action.eligibilityData.storyTitle,
        user: action.eligibilityData.writerUsername
      };
    }
    case FORM_SET: {
      return {
        ...state,
        data: {
          ...state.data,
          storyId: action.storyId
        },
        isLoading: false,
        hasCheckedSubmission: true
      };
    }
    case FORM_SUBMITTED_SET: {
      return {
        ...state,
        isLoading: false,
        hasCheckedSubmission: true,
        hasSubmitted: action.status
      };
    }
    case FORM_NEAR_ELIGIBLE_SET: {
      return {
        ...state,
        isLoading: false,
        isNearEligible: action.isNearEligible
      };
    }
    case FORM_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ADDED_WATTYS_TAG: {
      return {
        ...state,
        hasAddedWattysTag: true
      };
    }
    case FORM_STORY_STATUS_SET: {
      return {
        ...state,
        data: {
          ...state.data,
          storyStatus: action.storyStatus
        }
      };
    }
    default:
      return state;
  }
}

export function fetchContestForm(storyId) {
  return async function(dispatch, getState) {
    const wattysEligibilityUrl = `/v5/contests/wattys/story/${storyId}/eligibility`; // information on story eligibility
    const wattysSubmittedUrl = `/v5/contests/wattys/story/${storyId}/submission`; // submitted Watty's form
    const hasSubmitted = getState().contestForm.hasSubmitted;

    if (hasSubmitted) {
      if (!getState().contestForm.isLoading) {
        dispatch({ type: FORM_REQUEST });
      }
      return Promise.all([
        Promise.resolve($.get(wattysEligibilityUrl)),
        Promise.resolve($.get(wattysSubmittedUrl))
      ]).then(([wattysEligibilityData, submittedWattysForm]) => {
        dispatch({
          type: FORM_FETCH,
          data: submittedWattysForm,
          eligibilityData: wattysEligibilityData
        });
      });
    } else {
      window.te.push("event", "wattys", "submission_form", null, "view", {
        storyid: parseInt(storyId)
      });
      return Promise.resolve($.get(wattysEligibilityUrl)).then(
        wattysEligibilityData => {
          dispatch({
            type: FORM_FETCH,
            data: {
              ...initialState.data,
              language: wattysEligibilityData.storyLanguage,
              storyStatus: wattysEligibilityData.storyStatus
            },
            eligibilityData: wattysEligibilityData
          });
        }
      );
    }
  };
}

export function fetchSubmittedFormBannerInfo(storyId) {
  return async function(dispatch, getState) {
    const wattysEligibilityUrl = `/v5/contests/wattys/story/${storyId}/eligibility`; // information on story eligibility

    if (!getState().contestForm.isLoading) {
      dispatch({ type: FORM_REQUEST });
    }
    return Promise.all([Promise.resolve($.get(wattysEligibilityUrl))]).then(
      ([wattysEligibilityData]) => {
        dispatch({
          type: FORM_FETCH,
          data: {},
          eligibilityData: wattysEligibilityData
        });
      }
    );
  };
}

export function setContestForm(storyId, body) {
  return async function(dispatch) {
    const wattysSubmissionUrl = `/v5/contests/wattys/story/${storyId}/submission`;
    const storyTagsUrl = "/apiv2/editstorytags";
    const storyLandingUrl = `/api/v3/stories/${storyId}?fields=tags,parts`;
    dispatch({ type: FORM_REQUEST });
    //Submit wattys contest form
    try {
      await Promise.resolve(
        $.ajax({
          url: wattysSubmissionUrl,
          type: "POST",
          data: JSON.stringify(body),
          dataType: "json",
          headers: {
            "Content-Type": "application/json"
          }
        })
      );
      window.te.push("event", "wattys", "submission_form", null, "submit", {
        storyid: parseInt(storyId)
      });
    } catch (err) {
      let message = wattpad.utils.trans(
        "Sorry, something went wrong. Please try again."
      );
      try {
        if (err.responseJSON.code === 401) {
          message += wattpad.utils.trans(
            "You do not have permission to modify this story"
          );
        }
      } catch (err) {
        // Do nothing if fails.
      }
      const toast = new window.app.views.ErrorToast(
        { message },
        { type: "dismissable" }
      );
      toast.render();
      dispatch({ type: FORM_ERROR });
      throw err;
    }

    dispatch({
      type: FORM_SET,
      storyId: body.storyId,
      data: {
        writerEmail: body.writerEmail,
        finalConsent: body.finalConsent
      }
    });
    // Fetch tags to see if wattys exist & create wattys tag
    const { tags, parts } = await Promise.resolve($.get(storyLandingUrl));
    const wattysTag = window.wattpad.wattysActiveKey;
    let hasAddedWattysTag = false;
    if (tags.indexOf(wattysTag) === -1 && parts && parts.length > 0) {
      // Wattys tag doesn't exist. We create it here.
      try {
        await Promise.resolve(
          $.post(storyTagsUrl, {
            request_type: "add",
            tag_value: wattysTag,
            story_id: parts[0].id
          })
        );
        dispatch(
          addStoryTag({
            id: wattysTag,
            name: wattysTag,
            active: true,
            className: "wattys-tag"
          })
        );
        dispatch({ type: ADDED_WATTYS_TAG });
        hasAddedWattysTag = true;
      } catch (err) {
        // Allow continuation if adding tag fails since it's only cosmetic
      }
    }
    dispatch(setContestFormSubmissionStatus(true));
    return hasAddedWattysTag;
  };
}

export function openContestForm(storyId) {
  return function(dispatch) {
    const ContestFormOpener = () => (
      <ContestForm
        closeModal={() => dispatch(toggleModal())}
        storyId={storyId}
      />
    );
    dispatch(
      toggleModal({
        component: ContestFormOpener,
        disableClose: true,
        animate: false,
        className: "modal-contest",
        disableScroll: true
      })
    );
  };
}

export function setContestFormSubmissionStatus(status) {
  // Set whether the contest has been submitted or not
  return function(dispatch) {
    dispatch({ type: FORM_SUBMITTED_SET, status });
  };
}

export function setContestFormNearEligibleStatus(isNearEligible) {
  // Set whether the contest form is near eligible
  return function(dispatch) {
    dispatch({ type: FORM_NEAR_ELIGIBLE_SET, isNearEligible });
  };
}

export function setContestFormStoryStatus(storyStatus) {
  // Set whether the contest form is near eligible
  return function(dispatch) {
    dispatch({ type: FORM_STORY_STATUS_SET, storyStatus });
  };
}
