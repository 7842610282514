import React from "react";
import PropTypes from "prop-types";
import StoryCardSkeleton from "../../shared-components/StoryCardSkeleton";

export default function StoryCardContainerSkeleton({ numSkeletonCards }) {
  const coverSkeletonColor = "@ds-neutral-60-solid";

  return (
    <div className="story-card-skeleton-container hidden">
      <ul className="list-group new-list-group">
        {Array.from({ length: numSkeletonCards }, (_, index) => (
          <li key={index} className="list-group-item">
            <StoryCardSkeleton coverColor={coverSkeletonColor} />
          </li>
        ))}
      </ul>
    </div>
  );
}

StoryCardContainerSkeleton.propTypes = {
  numSkeletonCards: PropTypes.number
};
