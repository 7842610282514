import React, { useState, useCallback } from "react";

import { ConnectImage, MODAL_ID, Modal } from "../../shared-components";
import { useTrans } from "../../hooks";
import { DEFAULT_BONUS_COINS_LINK } from "./constants";

const BuyCoinsReactModal = () => {
  const [data, setData] = useState({});
  const defaultData = {
    thankForInterest: false,
    device: "desktop",
    appUrl: ""
  };
  const handleModalRef = useCallback(
    modal => {
      if (!modal?.data) return;

      Object.keys(defaultData).forEach(prop => {
        modal.data[prop] = modal.data[prop] || defaultData[prop];
      });

      setData(modal.data);
    },
    [setData, defaultData]
  );

  const { trans } = useTrans();
  const learnMoreLink = DEFAULT_BONUS_COINS_LINK;

  const handleClose = () => {
    const walletBalence = window.store.getState().wallet.amount;
    window.te.push("event", "coins", "packages", null, "dismiss", {
      page: data?.page,
      storyid: data?.storyId,
      partid: data?.blockedPartId,
      starting_balance: walletBalence
    });
  };

  const handleOpen = () => {
    const walletBalence = window.store.getState().wallet.amount;
    window.te.push("event", "coins", "packages", null, "view", {
      page: data?.page,
      storyid: data?.storyId,
      partid: data?.blockedPartId,
      starting_balance: walletBalence
    });
  };

  const EARN_COINS_STEPS = [
    trans(
      "Open the Wattpad app on your mobile device and tap the Coin wallet on your profile."
    ),
    trans(
      "Buy Coins: Select and purchase a Coin pack of your choice or subscribe to Premium+ for monthly Bonus Coins."
    ),
    trans("Earn Coins: Redeem offers or watch ads to earn Bonus Coins.")
  ];

  return (
    <Modal
      id={MODAL_ID.BUY_COINS_MODAL}
      onClose={handleClose}
      onOpen={handleOpen}
      ref={handleModalRef}
    >
      <div className="coins-cta background">
        <h3>
          {data.thankForInterest
            ? trans(
                "Thank you for your interest! You can purchase Coins on the app."
              )
            : trans("Buy or earn Coins on the Wattpad app")}
        </h3>
        <p>
          {/* prettier-ignore */ trans("You can use Coins to unlock Wattpad Originals anywhere you use Wattpad, whether you’re on the app or web.")}
        </p>
      </div>
      <div className="how-to-buy">
        {EARN_COINS_STEPS.map((step, index) => (
          <div key={index} className="step">
            <div className="step-circle">
              <div>{index + 1}</div>
            </div>
            <div className="instruction">
              <p className="step-instruction">{step}</p>
              {data.device?.is?.desktop && (
                <ConnectImage
                  className="instruction-image"
                  name={`paid-content/buy-coins-step-${index + 1}.png`}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="links">
        {data.device?.is?.mobile && (
          <a className="store-link" href={data.appUrl}>
            {data.device?.is?.ios && (
              <ConnectImage name={`homepages/appstore_dark_borderless.png`} />
            )}
            {data.device?.is?.android && (
              <ConnectImage name={`homepages/googleplay_dark_borderless.png`} />
            )}
          </a>
        )}
        <a
          className="faq-link"
          href={learnMoreLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* prettier-ignore */ trans('Earning Coins FAQ')}
        </a>
      </div>
    </Modal>
  );
};

BuyCoinsReactModal.propTypes = {};

export default BuyCoinsReactModal;
