const {
  CommentPlacement,
  ReaderPlacement,
  StoryDetailsPlacement,
  LibraryPlacement,
  SearchPlacement,
  BrowsePlacement
} = require("@wattpad/client-platform-web-ads");

const SKIP_REASONS_TO_ELIGIBILITY_FACTORS = {
  skipped_paid_story: "story_paywalled",
  skipped_ad_exempt_story: "story_ad_exempt",
  images_under_moderation: "image_moderation_flag",
  skipped_premium_user: "premium_subscription",
  skipped_exempt: "special_ad_exempt_country"
};

module.exports = function({ adData, skipReason }) {
  const adEligibility = adData.adEligibility;
  if (!adEligibility) {
    console.error("missing adEligibility");
    return;
  }
  const placementName = adEligibility.placement.name;
  const eligible =
    skipReason === "passes_all_rules" ||
    skipReason === "skipped_default" ||
    skipReason === "skipped_brandsafe";

  const eventDetails = {
    reference_id: adEligibility.placement.reference_id,
    eligible,
    eligibility_factor: SKIP_REASONS_TO_ELIGIBILITY_FACTORS[skipReason],
    country_code: adData.userGeo
  };

  // placements that need story and part information
  const storyAndPartPlacements = [
    ReaderPlacement.ReaderTop,
    ReaderPlacement.ReaderMid,
    ReaderPlacement.ReaderBottom,
    ReaderPlacement.ReaderVideo,
    CommentPlacement.Comments,
    CommentPlacement.CommentsInline
  ];

  // placements that need story information
  const storyPlacements = [StoryDetailsPlacement.StoryDetails];

  // placements that don't need any additional information
  const otherPlacments = [
    LibraryPlacement.LibraryReadingLists,
    SearchPlacement.Search,
    BrowsePlacement.TagSearch
  ];

  if (storyAndPartPlacements.includes(placementName)) {
    eventDetails[placementName] = {
      storyid: adData.context.story.id,
      partid: adData.context.storyPart.id
    };
    if (eligible) {
      eventDetails.is_mature = window.wattpad.utils.isMatureStory(
        adData.context.story
      );
    }
  } else if (storyPlacements.includes(placementName)) {
    eventDetails[placementName] = {
      storyid: adData.context.story.id
    };
    if (eligible) {
      eventDetails.is_mature = window.wattpad.utils.isMatureStory(
        adData.context.story
      );
    }
  } else if (otherPlacments.includes(placementName)) {
    eventDetails[placementName] = {};
  } else {
    console.error("invalid placement: ", placementName);
    return;
  }

  window.te.push(
    "event",
    null,
    null,
    null,
    "ad_eligibility_decision_v0",
    eventDetails
  );
};
