// Smoothly scroll to the given ref, plus or minus an offset above or
// below the top of the ref.
export function scrollToRef(ref, offsetFromRef = 0) {
  const refRect = ref.getBoundingClientRect();
  const distanceFromTopToSection = window.scrollY + refRect.y;

  // A negative offset will scroll above the ref; a positive offset
  // will scroll below. E.g. `-25` means scroll 25 pixels above the
  // ref.
  const yCoordToScrollTo = distanceFromTopToSection + offsetFromRef;

  window.scrollTo({
    top: yCoordToScrollTo,
    behavior: "smooth"
  });
}
