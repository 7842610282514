import { CommentsNamespaces } from "@wattpad/client-platform-comments";
import PropTypes from "prop-types";

export const INIT_COMMENTS_REPLIES = 2;
export const INIT_COMMENTS_STORY_PART = 2;
export const DEEPLINK_OPENED_EVENT = "app:newComments:deeplinkOpened";
export const DEEPLINK_ORPHAN_EVENT = "app:newComments:deeplinkOrphan";

export const CommentsLocation = {
  STORY_PART: CommentsNamespaces.PARTS,
  REPLIES: CommentsNamespaces.COMMENTS,
  PARAGRAPH: CommentsNamespaces.PARAGRAPHS
};

export const AdsInCommentsConfigs = {
  AD_OFFSET_NUM: 2,
  NUM_COMMENTS_SET: 10
};

export const AdsInCommentContextPropType = PropTypes.shape({
  userGeo: PropTypes.string,
  testGroups: PropTypes.any,
  deviceType: PropTypes.string,
  context: PropTypes.shape({
    user: PropTypes.object,
    story: PropTypes.shape({
      isAdExempt: PropTypes.bool,
      isBrandSafe: PropTypes.bool,
      isPaywalled: PropTypes.bool,
      rating: PropTypes.number,
      language: PropTypes.number
    }),
    storyPart: PropTypes.shape({
      id: PropTypes.number,
      brandSafetyLevel: PropTypes.number,
      brandSafetySource: PropTypes.string,
      rank: PropTypes.number,
      rating: PropTypes.number,
      totalViewCount: PropTypes.number
    })
  }),
  placement: PropTypes.string
});

export const CommentItemDropdownProps = {
  CLASS: "comment-item-options-dropdown",
  PROPS: {
    placement: "bottom",
    showArrow: "true",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-50, 0]
        }
      },
      {
        name: "preventOverflow",
        options: {
          padding: { left: 16, right: 16 }
        }
      }
    ]
  }
};

export const ScrollSmoothCenter = {
  block: "center",
  behavior: "smooth"
};

export const InitialCommentPropType = PropTypes.shape({
  commentId: PropTypes.string,
  commentData: PropTypes.object
});
