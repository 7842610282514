const namespace = "STORY_TAGS";

const SET = `${namespace}/SET`;
const ADD = `${namespace}/ADD`;
const REMOVE = `${namespace}/REMOVE`;

const initialState = {
  tags: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET: {
      return {
        ...state,
        ...action.tags
      };
    }
    case ADD: {
      return {
        ...state,
        tags: [...state.tags, action.tag]
      };
    }
    case REMOVE: {
      const foundIndex = state.tags.findIndex(tag => tag.name === action.tag);
      return {
        ...state,
        tags: [
          ...state.tags.slice(0, foundIndex),
          ...state.tags.slice(foundIndex + 1)
        ]
      };
    }
    default:
      return state;
  }
}

export function addStoryTag(tag) {
  return function(dispatch) {
    dispatch({ type: ADD, tag });
  };
}

export function setStoryTags(tags) {
  return function(dispatch) {
    dispatch({ type: SET, tags });
  };
}

export function removeStoryTag(tag) {
  return function(dispatch) {
    dispatch({ type: REMOVE, tag });
  };
}
