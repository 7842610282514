import React, { useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  MoreOptionsButton,
  UserSafetyOperations,
  WPModal
} from "../../shared-components";

import { toggleModal } from "../../shared-components/modals/actions";
import BlockModal from "../../shared-components/modals/components/user-safety-modals/BlockModal";
import { useTrans } from "../../hooks";
import Icon from "../../../components/shared-components/Icon";

const ProfileMoreOptionsButtonUI = ({
  username,
  onUserUnmuted,
  onUserMuted,
  unblockUser,
  blockUser,
  toggleModal,
  isMuted,
  isBlocked,
  isRestrictedProfile,
  isAmbassador,
  isSysAdmin,
  onCloseAccount,
  showModerationOptions,
  onTimeout
}) => {
  const buttonRef = useRef(null);
  const { trans } = useTrans();

  const onBlockUser = () => {
    let modalProps = {
      username,
      toggleModal,
      buttonRef: buttonRef.current
    };

    if (blockUser) {
      modalProps.blockUser = blockUser;
    }

    const component = () => <BlockModal {...modalProps} />;
    component.displayName = "BlockModal";

    toggleModal({
      type: "block",
      className: "block-modal",
      hideClose: true,
      component
    });
  };

  const closeAccount = () => {
    if (onCloseAccount) {
      onCloseAccount();
    }
  };

  const timeoutAccount = () => {
    if (onTimeout) {
      onTimeout();
    }
  };
  return (
    <div>
      <MoreOptionsButton ref={buttonRef} />
      <div className="triangle" />
      <div className="dropdown-menu align-right">
        <UserSafetyOperations
          showModerationOptions={showModerationOptions}
          username={username}
          isLoading={false}
          isMutedByCurrentUser={isMuted}
          isBlockedByCurrentUser={isBlocked}
          onUserMuted={onUserMuted}
          onUserUnmuted={onUserUnmuted}
          onBlockUser={onBlockUser}
          onUnblockUser={unblockUser}
          onReportUser={() => {}} // noop just to render the button since the ReportManager mixin handles this for now
          showBlockOption
        />

        <div className="user-safety-operations moderation-options">
          <a
            className="user-safety-operation btn-no-background btn-left-icon"
            href="https://policies.wattpad.com/"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex="0"
          >
            <Icon name="link" />
            <span className="option">{trans("Wattpad Policies")}</span>
          </a>
        </div>

        {(isAmbassador || isSysAdmin) && (
          <div className="user-safety-operations moderation-options">
            <button
              onClick={closeAccount}
              data-toggle="modal"
              data-target="#userClose"
              data-gtm="profile|close_account"
              className="user-safety-operation btn-no-background btn-left-icon "
            >
              <Icon name="closeAccount" strokeWidth="0" viewBox="16" />
              <span className="option">{trans("Close Account")} </span>
            </button>

            <button
              onClick={timeoutAccount}
              data-toggle="modal"
              data-target="#userTimeout"
              data-gtm="profile|time_out"
              className="user-safety-operation btn-no-background btn-left-icon "
            >
              <Icon name="timeout" strokeWidth="0" />
              <span className="option">{trans("Time-out")}</span>
            </button>
          </div>
        )}
      </div>
      {!isRestrictedProfile && <WPModal />}
    </div>
  );
};

ProfileMoreOptionsButtonUI.propTypes = {
  username: PropTypes.string.isRequired,
  toggleModal: PropTypes.func,
  isMuted: PropTypes.bool,
  isBlocked: PropTypes.bool,
  mutedUsers: PropTypes.array,
  onUserUnmuted: PropTypes.func,
  onUserMuted: PropTypes.func,
  blockUser: PropTypes.func,
  unblockUser: PropTypes.func,
  isRestrictedProfile: PropTypes.bool,
  isAmbassador: PropTypes.bool,
  isSysAdmin: PropTypes.bool,
  onCloseAccount: PropTypes.func,
  onTimeout: PropTypes.func,
  showModerationOptions: PropTypes.bool
};

const ProfileMoreOptionsButton = connect(
  null,
  { toggleModal }
)(ProfileMoreOptionsButtonUI);

export default ProfileMoreOptionsButton;
