import React from "react";
import PropTypes from "prop-types";
/**
 * Inject this at the top of the page. its an inivisble container for google OneTap (https://accounts.google.com/gsi/client)
 * to hook into. Google & the browser controls the floating signin modal
 */
export const GoogleOneTapContainer = ({
  siteRoot,
  googleClientId,
  testGroups: { GOOGLE_FEDCM_MIGRATION }
}) => {
  return (
    <>
      {GOOGLE_FEDCM_MIGRATION ? (
        <div
          id="g_id_onload"
          data-client_id={googleClientId}
          data-login_uri={`${siteRoot}/oauth-auth`}
          data-is_one_tap="true"
          data-cancel_on_tap_outside="false"
          data-use_fedcm_for_prompt="true"
        />
      ) : (
        <div
          id="g_id_onload"
          data-client_id={googleClientId}
          data-login_uri="/oauth-auth"
          data-is_one_tap="true"
          data-prompt_parent_id="g_id_onload"
          data-cancel_on_tap_outside="false"
          style={{
            position: "fixed",
            top: "7%",
            right: "25px",
            zIndex: 1021
          }}
        />
      )}
    </>
  );
};

GoogleOneTapContainer.propTypes = {
  siteRoot: PropTypes.string.isRequired,
  googleClientId: PropTypes.string.isRequired,
  testGroups: PropTypes.objectOf(PropTypes.bool)
};
