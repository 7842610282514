import { useSignupModal, useGetUser } from ".";

export const useUserVerification = () => {
  const user = useGetUser();
  const { openSignupModal } = useSignupModal();

  const userNeedsVerification = () => {
    if (!user.username) {
      openSignupModal("comments-signup-modal");
      return true;
    } else if (!user.verified_email) {
      wattpad.utils.showPleaseVerifyModal();
      return true;
    } else {
      return false;
    }
  };

  return { userNeedsVerification };
};
