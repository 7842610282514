import React from "react";
import PropTypes from "prop-types";
import Icon from "../../shared-components/Icon";
import { useTrans } from "../../hooks";

export function ForgotPassword({ msg, notice, nextUrl }) {
  const { trans } = useTrans();
  const actionURL = `/forgot?nextUrl=${nextUrl}`;

  return (
    <div id="forgot-password">
      <header className="forgot-password-header">
        <h2>{trans("Lost your password?")}</h2>
        <span className="instruction">
          {trans(
            "Type your username or email below and we'll send you instructions on how to reset it."
          )}
        </span>
      </header>

      <div className="forgot-password-body panel-body">
        <div
          className="more-info"
          dangerouslySetInnerHTML={{
            __html: /* prettier-ignore */ trans(
                'Visit <a href="https://support.wattpad.com/hc/en-us/articles/211235843-Resetting-or-changing-your-password">Wattpad Support</a> for more information'
              )
          }}
        />
        <hr />
        <form action={actionURL} method="post">
          <div className="form-group has-feedback" data-attr="forgotEmail">
            <input
              id="forgot-email"
              className="form-control input-lg enable"
              name="email"
              type="text"
              placeholder={trans("Username or Email")}
            />
          </div>

          {msg && (
            <div className="alert alert-danger" role="alert">
              <Icon iconName="fa-info" height="16" color="wp-lighterror" />{" "}
              {msg}
            </div>
          )}

          {notice && (
            <div className="alert alert-info" role="alert">
              <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
              {notice}
            </div>
          )}

          <div className="row">
            <input
              type="submit"
              className="btn btn-primary enable submit"
              value={trans("Send instructions")}
            />
            <a
              className="btn btn-secondary cancel"
              rel="nofollow"
              href={`/login?nextUrl=${nextUrl}`}
              data-form="login"
            >
              {trans("Cancel")}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  msg: PropTypes.string
};
