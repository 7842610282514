import { TOGGLE_MODAL_VISIBLE, OPEN_MODAL, CLOSE_MODAL } from "./constants";

export function toggleModal(modalProps) {
  return {
    type: TOGGLE_MODAL_VISIBLE,
    modalProps
  };
}

export function openModal(modalProps) {
  return {
    type: OPEN_MODAL,
    modalProps
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}
