import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "../../../shared-components";
import TagItemWithIcon from "../../tags/TagItemWithIcon.js";
import SuggestedTags from "./SuggestedTags.js";
import { injectTrans } from "../../../helpers";

/*
 * EditBlockedTagsModal component contains a view to add/remove blocked tags
 * Props:
 *      removeOnTagClick <bool>: if true, clicking anywhere on tag will remove it
 *      onReturn <func>: return handler
 *      tagLimit <number>: maximum number of blockable tags
 *      tags <array>: array of user's blocked tags
 *      toggleView <func>: changes view between content settings page and this component
 *      trans <func>: translation
 *      unblockableTags: <array>: array of tag names that cannot be blocked
 *      visibleState <bool>: if true, show the modal
 */

class EditBlockedTagsModal extends React.Component {
  static propTypes = {
    removeOnTagClick: PropTypes.bool,
    onReturn: PropTypes.func,
    tagLimit: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleView: PropTypes.func,
    trans: PropTypes.func.isRequired,
    unblockableTags: PropTypes.arrayOf(PropTypes.string),
    visibleState: PropTypes.bool,
    isSettingsPage: PropTypes.bool
  };

  state = {
    tags: this.props.tags,
    newTagName: "",
    warningText: "",
    disableInput: false
  };

  editRef = React.createRef();
  unblockableTagsWarning = this.props.trans("Oops! This tag can't be blocked.");
  tagLimitWarning = this.props.trans(
    "You have reached your blocked tags limit."
  );

  componentDidMount() {
    if (this.props.tagLimit && this.props.tags.length >= this.props.tagLimit) {
      this.setState({
        ...this.state,
        warningText: this.tagLimitWarning,
        disableInput: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.tags !== prevProps.tags) {
      this.handleTagListUpdates(this.props.tags);
    }
    if (this.editRef && this.props.visibleState !== prevProps.visibleState) {
      this.editRef.current.focus();
    }
  }

  handleTagListUpdates = tags => {
    if (!isNaN(this.props.tagLimit)) {
      if (tags.length >= this.props.tagLimit) {
        this.setState({
          ...this.state,
          warningText: this.tagLimitWarning,
          disableInput: true
        });
      } else {
        this.setState({
          ...this.state,
          warningText: "",
          disableInput: false
        });
      }
    }
    this.setState({ tags });
  };

  addTag = (tagName, tagList) => {
    if (
      this.props.unblockableTags &&
      this.props.unblockableTags.includes(this.state.newTagName)
    ) {
      // show warning that this is an unblockable tag
      this.setState({
        warningText: this.unblockableTagsWarning
      });
    } else {
      // check to see if the tag to be added is already in the list of blocked tags
      if (!tagList.some(tag => tag.name === tagName) && tagName) {
        const newTag = {
          id: tagName,
          name: tagName,
          active: true
        };
        this.handleTagListUpdates([...tagList, newTag]);
      }
      this.setState({ newTagName: "" });
    }
  };

  handleChanges = event => {
    if (
      event.keyCode === 13 ||
      event.nativeEvent.data === " " ||
      event.keyCode === 32
    ) {
      this.addTag(this.state.newTagName.toLowerCase(), this.state.tags);
    }
    // prevent arrow keys from refetching suggested tags
    else if (
      event.keyCode !== 37 &&
      event.keyCode !== 38 &&
      event.keyCode !== 39 &&
      event.keyCode !== 40
    ) {
      let newTagName =
        event.target.value === " " ? "" : event.target.value.toLowerCase();
      this.setState({
        ...this.state,
        newTagName,
        warningText: ""
      });
    }
  };

  removeTag = (event, tag) => {
    // only allow clicking on the X (fa fa-remove fa-wp-neutral-2)
    // to delete the tag unless prop overrides it. Also allow enter
    // key to delete tags
    const remove =
      this.props.removeOnTagClick ||
      event.target.className.includes("remove") ||
      event.keyCode === 13;
    if (remove) {
      // prevent tab and shift keys from removing tags
      if (event.keyCode !== 9 && event.keyCode !== 16) {
        const name = tag;
        const tags = this.state.tags.filter(tag => tag.name !== name);
        this.handleTagListUpdates(tags);
      }
    }
  };

  render() {
    const { trans, isSettingsPage } = this.props;
    return (
      <div
        className={classnames("content-settings", "edit-blocked-tags", {
          active: this.props.visibleState,
          hidden: !this.props.visibleState,
          "is-settings-page": isSettingsPage
        })}
      >
        <div className="heading">
          <button
            className="back"
            onClick={() => this.props.onReturn(this.state.tags)}
          >
            <Icon name="arrowLeft" size="22" />
          </button>
          <h2>{trans("Blocked Tags")}</h2>
        </div>
        <hr />
        <div className="instruction">
          <h3>{trans("Blocked Tags")}</h3>
          <p>
            {trans(
              "We will remove all stories that have the following tags from your Home recommendations."
            )}
          </p>
        </div>
        <div className="blocked-tags-list">
          <div className="tags">
            {this.state.tags.map(tag => (
              <TagItemWithIcon
                tabIndex="-1"
                key={tag.id}
                {...tag}
                clickHandler={(event, tag) => this.removeTag(event, tag)}
              />
            ))}
          </div>
        </div>
        <input
          className="input-field"
          type="text"
          ref={this.editRef}
          onKeyDown={this.handleChanges}
          onChange={this.handleChanges}
          value={this.state.newTagName}
          placeholder={trans("Separate tags with a space")}
          disabled={this.state.disableInput}
        />
        {this.state.warningText && (
          <div className="warning">
            <Icon name="warning-triangle" size="22" />
            <p>{this.state.warningText}</p>
          </div>
        )}
        {this.state.newTagName && (
          <SuggestedTags
            handleClick={tag => {
              this.addTag(tag, this.state.tags);
            }}
            inputTerm={this.state.newTagName}
            excludedSuggestions={this.state.tags.map(tag => tag.name)}
          />
        )}
      </div>
    );
  }
}

export default injectTrans(EditBlockedTagsModal);
