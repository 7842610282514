import React from "react";
import { useTrans } from "../../hooks";
import { ConnectImage } from "../../shared-components";

const MerchMessage = () => {
  const { trans } = useTrans();

  return (
    <div className="merch-message-wrapper">
      <ConnectImage
        name={"merch/merch_message.svg"}
        className="merch-message-image"
      />
      <h3>
        {trans(
          "Sorry this product is in beta and only available on iOS at the moment."
        )}
      </h3>
      <p>
        {trans(
          "We hope to bring you exciting new merch experiences down the road."
        )}
      </p>
    </div>
  );
};

export default MerchMessage;
