import { Icon } from "../../shared-components";
import PropTypes from "prop-types";
import React from "react";
import { REPORT_ORIGINAL_STORY_ID } from "./pages/ReportOriginalStory";
import { ReportItemTypes } from "./ReportModal";
import { useGetUser } from "../../hooks";

const ReportFormUserOption = ({
  item,
  textDescription,
  onChangeReportComment,
  reportDetails,
  onClickNext,
  buildUrl
}) => {
  const user = useGetUser();

  const renderReason = item => (
    <div className="report-reason" key={item.id}>
      <label className="reason-label" htmlFor={`reason-${item.id}`}>
        <div className="reason-text-container">
          <input
            className="reason-title"
            type="button"
            id={`reason-${item.id}`}
            name="report-reason-option"
            value={item.title}
            onClick={onClickNext}
          />
          <p className="reason-subtitle">{item.subtitle}</p>
        </div>
        <span className="reason-right-icon" tabIndex="-1">
          <Icon iconName="fa-right" height="13" />
        </span>
      </label>
    </div>
  );

  const renderTextArea = description => (
    <div className="reason-comment">
      <textarea
        className="form-control details-input"
        placeholder={description} //TODO: replace with item.header when headers get updated
        value={reportDetails}
        onChange={onChangeReportComment}
      />
    </div>
  );

  const renderLink = item => (
    <a key={item.id} className="report-reason" href={buildUrl(item.extras.url)}>
      <label className="reason-label" htmlFor={`reason-${item.id}`}>
        <div className="reason-text-container">
          <div className="reason-title">{item.title}</div>
          <p className="reason-subtitle">{item.subtitle}</p>
        </div>
        <span className="reason-right-icon" tabIndex="-1">
          <Icon iconName="fa-right" height="13" />
        </span>
      </label>
    </a>
  );

  switch (item.type) {
    case ReportItemTypes.TEXT:
      return renderReason(item);
    case ReportItemTypes.EDIT_TEXT:
      return renderTextArea(textDescription);
    case ReportItemTypes.URL:
      return renderLink(item);
    case ReportItemTypes.PAGE:
      if (user?.username && item.extras?.page === REPORT_ORIGINAL_STORY_ID) {
        return renderReason(item);
      }
      return null;
    default:
      return null;
  }
};

ReportFormUserOption.propTypes = {
  item: PropTypes.object,
  textDescription: PropTypes.string,
  onChangeReportComment: PropTypes.func.isRequired,
  reportDetails: PropTypes.string,
  onClickNext: PropTypes.func.isRequired,
  buildUrl: PropTypes.func.isRequired
};

export default ReportFormUserOption;
