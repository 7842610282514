"use strict";

const isServer = require("./is-server");
const PAID_MODEL_TYPE = {
  INVALID: "",
  PAID_STORY: "paid_story",
  BONUS_CHAPTER: "paid_bonus"
};

/**
 * Detemine if a story is a paid story or a free story with bonus content
 *
 * @param {Story} story story object
 * @returns {Boolean} a story is a paid story
 */
module.exports = function(story) {
  // Check if we are calling this function from the server or from a backbone model
  if (isServer() || !story?.get) {
    return story.paidModel === PAID_MODEL_TYPE.PAID_STORY;
  } else if (story?.get) {
    return story.get("paidModel") === PAID_MODEL_TYPE.PAID_STORY;
  }
  return false;
};
