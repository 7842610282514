import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { UserSafetyOperations } from "../../shared-components";
import { deleteConversation } from "./Conversation/reducers";
import { toggleModal } from "../../shared-components/modals/actions";
import { toggleToast } from "../../shared-components/toasts/reducers";
import { useTrans } from "../../hooks";
import ReportModal from "../report/ReportModal";

function ThreadControlUI({
  isLoading,
  activeThread,
  isMutedByCurrentUser,
  isBlockedByCurrentUser,
  deleteConversation,
  toggleModal,
  toggleToast,
  privateMessagePermissions
}) {
  const { trans } = useTrans();

  function reportConversation() {
    // Temporary error toast
    // TODO: Delete this function when new error designs are given
    const showErrorToast = errorMessage => {
      toggleToast({
        className: "report-private-message-toast",
        body: errorMessage
      });
    };

    const component = () => (
      <ReportModal
        title={trans("Report a Private Message")}
        reportType="private-message"
        requesterUsername={wattpad.utils.currentUser().get("username")}
        requesterEmail={wattpad.utils.currentUser().get("email")}
        reportId={{ name: activeThread }}
        hideModal={toggleModal}
        showErrorToast={showErrorToast}
      />
    );
    component.displayName = "ReportModal";

    toggleModal({
      className: "report-modal",
      hideClose: true,
      component
    });
  }

  function onDeleteConversation() {
    if (
      confirm(trans("Delete all messages between you and %s?", activeThread))
    ) {
      deleteConversation(activeThread).then(() => {
        if (window.location.pathname === "/inbox") {
          window.location.reload();
        } else {
          window.app.router.navigate("/inbox", { trigger: true });
        }
      });
    }
  }

  return (
    <div>
      {!isLoading && (
        <UserSafetyOperations
          username={activeThread}
          isMutedByCurrentUser={isMutedByCurrentUser}
          isBlockedByCurrentUser={isBlockedByCurrentUser}
          onDeleteConversation={onDeleteConversation}
          onReportUser={
            typeof privateMessagePermissions?.canSendPrivateMessage !==
              "boolean" || privateMessagePermissions?.canSendPrivateMessage
              ? reportConversation
              : null
          }
          showModerationOptions={
            typeof privateMessagePermissions?.canSendPrivateMessage !==
              "boolean" || privateMessagePermissions?.canSendPrivateMessage
          }
        />
      )}
    </div>
  );
}

ThreadControlUI.propTypes = {
  activeThread: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  deleteConversation: PropTypes.func.isRequired,
  isMutedByCurrentUser: PropTypes.bool.isRequired,
  isBlockedByCurrentUser: PropTypes.bool,
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  privateMessagePermissions: PropTypes.object
};

const ThreadControl = connect(
  null,
  {
    deleteConversation,
    toggleModal,
    toggleToast
  }
)(ThreadControlUI);

export { ThreadControl, ThreadControlUI };
