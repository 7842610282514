import React from "react";
import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import PropTypes from "prop-types";
import VerificationFeatures from "./VerificationFeatures";

function SentEmail({
  email,
  isSuccess,
  hideModal,
  onClickResendEmail,
  onClickChangeEmail
}) {
  const { trans } = useTrans();

  const onClose = event => {
    wattpad.utils.stopEvent(event);
    hideModal();
  };

  const modalMessage =
  trans("Please check your email %s to complete sign-up.", email) + " " + /* prettier-ignore */
  ( isSuccess ?
    trans("After you click the link in your email, you'll be able to:") : /* prettier-ignore */
    trans("Please try again or change your email.")); /* prettier-ignore */

  return (
    <div className="sent-email-modal-content">
      <button
        className="close on-close"
        onClick={onClose}
        aria-label={trans("Close")}
        tabIndex="0"
      >
        <Icon iconName="fa-remove" height="16" color="wp-neutral-2" />
      </button>
      {isSuccess ? (
        <h1>{trans("Email sent")}</h1>
      ) : (
        <h1>{trans("Problem reaching email :(")}</h1>
      )}
      <div className="modal-content">
        <p>
          {modalMessage.split(email)[0]}
          <strong>{email}</strong>
          {modalMessage.split(email)[1]}
        </p>
        {isSuccess && <VerificationFeatures />}
        <div className="button-wrapper">
          <form>
            {isSuccess ? (
              <button
                className="btn-secondary btn-md btn-dismiss"
                onClick={onClose}
                aria-label={trans("Dismiss")}
              >
                {trans("Dismiss")}
              </button>
            ) : (
              <div>
                <button
                  className="btn-primary btn-md btn-change-email"
                  onClick={onClickChangeEmail}
                  aria-label={trans("Change email")}
                >
                  {trans("Change email")}
                </button>
                <button
                  className="btn-secondary btn-md btn-resend-email"
                  onClick={onClickResendEmail}
                  aria-label={trans("Resend email")}
                >
                  {trans("Resend email")}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

SentEmail.propTypes = {
  email: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  hideModal: PropTypes.func,
  onClickResendEmail: PropTypes.func.isRequired,
  onClickChangeEmail: PropTypes.func.isRequired
};

export { SentEmail };
