import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../hooks";
import { openPopover } from "../../../shared-components/popover/reducers";
import { connect } from "react-redux";

import DropdownField from "../../../shared-components/input/DropdownField";
import Tooltip from "./CopyrightTooltip";

import { STORY_COPYRIGHT_LICENSES, STORY_POPOVER_INFO } from "./constants";

const CopyrightSectionUI = ({ story, openPopover, handleUpdateCopyright }) => {
  const { trans } = useTrans();
  const triggerRef = useRef();

  const STORY_POPOVER_INFO_CONSTANTS = STORY_POPOVER_INFO();
  const STORY_COPYRIGHT_LICENSES_CONSTANTS = STORY_COPYRIGHT_LICENSES();
  const initialCopyright =
    story && story.copyright ? story.copyright + "" : "1";
  const [copyright, setCopyright] = useState(initialCopyright);

  const sendCopyrightEvent = (element, action, details = {}) => {
    window.te.push("event", "copyright", "story", element, action, details);
  };

  // event handlers from child components
  const handleChange = event => {
    const { value } = event.target;
    const licenseId = parseInt(value, 10);

    setCopyright(value);

    sendCopyrightEvent("license", "select", {
      storyid: story.id,
      license_id: licenseId
    });

    handleUpdateCopyright(licenseId);
  };

  const handleFocus = () => {
    sendCopyrightEvent(null, "open", {
      storyid: story.id
    });
  };

  const handleBlur = () => {
    sendCopyrightEvent(null, "close", {
      storyid: story.id
    });
  };

  const handleHelpLink = () => {
    sendCopyrightEvent(null, "learn_more", {
      storyid: story.id,
      target: "hc_copyright_faq"
    });
  };

  const handlePopover = event => {
    event.preventDefault();

    if (!triggerRef.current) {
      triggerRef.current = event.currentTarget;
    }

    const component = ({ closePopover }) => (
      <Tooltip
        {...STORY_POPOVER_INFO_CONSTANTS.copyright}
        closePopover={closePopover}
        handleHelpLink={handleHelpLink}
      />
    );

    openPopover({
      component,
      triggerRef,
      passthroughProps: {
        placement: "top",
        showArrow: "true"
      }
    });
  };

  return (
    <>
      <DropdownField
        label={trans("Copyright")}
        dropdownOptions={STORY_COPYRIGHT_LICENSES_CONSTANTS}
        name="copyright"
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        selected={copyright}
        onClickIcon={handlePopover}
        ariaLabel={trans("Copyright options")}
      />

      <Description
        copyrightId={copyright}
        copyrightLicenses={STORY_COPYRIGHT_LICENSES_CONSTANTS}
      />
    </>
  );
};

const Description = ({ copyrightId, copyrightLicenses }) => {
  let license = copyrightLicenses.find(
    license => license.value.toString() === copyrightId
  );

  return <p className="copyright-description">{license.summary}</p>;
};

Description.propTypes = {
  copyrightId: PropTypes.string,
  copyrightLicenses: PropTypes.array
};

CopyrightSectionUI.defaultProps = {
  story: {}
};

CopyrightSectionUI.propTypes = {
  openPopover: PropTypes.func.isRequired,
  story: PropTypes.object,
  handleUpdateCopyright: PropTypes.func,
  closePopover: PropTypes.func
};

const CopyrightSection = connect(
  null,
  { openPopover }
)(CopyrightSectionUI);

export default CopyrightSection;
