export const getLength = text => {
  // https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript
  return encodeURI(text).split(/%..|./).length - 1;
};

export const requiredField = trans => ({
  rule: value => value.length > 0,
  errorMessage: () => trans("This field is required.")
});

export const maxCharLengthField = (maxLength, trans) => ({
  rule: value => getLength(value) <= maxLength,
  errorMessage: () =>
    trans("This field has to contain less than %s characters.", maxLength)
});

export const minCharLengthField = (minLength, trans) => ({
  rule: value => getLength(value) >= minLength,
  errorMessage: () =>
    trans("This field has to contain more than %s characters.", minLength)
});

export const numberWithinBounds = (min, max, trans) => ({
  rule: value =>
    typeof Number(value) === "number" &&
    Number.isInteger(Number(value)) &&
    Number(value) >= min &&
    Number(value) <= max,
  errorMessage: () =>
    trans("Please enter a number between %s and %s.", min, max)
});

export const validEmail = (validEmailRegex, trans) => ({
  rule: value => value.match(validEmailRegex) !== null,
  errorMessage: () => trans("Please enter a valid email address.")
});

export const formToSubmissionBody = (storyId, formData) => {
  const submissionBody = {
    storyId: storyId,
    storyType: formData.storyType,
    genrePrimary: formData.genrePrimary,
    genreOthers: formData.genreOthers,
    matureThemes: formData.matureThemes,
    logLine: formData.logLine,
    plotSummary: formData.plotSummary,
    writerEmail: formData.writerEmail,
    finalConsent: formData.finalConsent,
    legalConsent: formData.legalConsent
  };

  if (formData.matureThemes.includes("SEX")) {
    submissionBody.storyMaturity = formData.storyMaturity;
  }
  if (formData.matureThemes.includes("OTHER")) {
    submissionBody.matureThemeOther = formData.matureThemeOther;
  }

  if (formData.storyStatus === "ONGOING") {
    submissionBody.anticipatedStoryLength = formData.anticipatedStoryLength;
  }

  if (formData.language === "English") {
    submissionBody.isSeries = formData.isSeries;
    if (formData.isSeries) {
      submissionBody.seriesType = formData.seriesType;
      submissionBody.isSeriesStandalone = formData.isSeriesStandalone;
      submissionBody.numberOfStoriesInSeries = formData.numberOfStoriesInSeries;
      submissionBody.totalPlannedStoriesInSeries =
        formData.totalPlannedStoriesInSeries;
    }
  }

  submissionBody.diversity = formData.diversity;
  if (formData.diversity.includes("OTHER")) {
    submissionBody.diversityOther = formData.diversityOther;
  }

  return submissionBody;
};
