import React from "react";
import PropTypes from "prop-types";

const variants = {
  full: {
    url: "/img/premium-branding/premium-logo-full.png",
    aspectRatio: 0.1678
  },
  full_orange: {
    url: "/img/premium-branding/premium-logo-full-orange.png",
    aspectRatio: 0.1656
  },
  short: {
    url: "/img/premium-branding/premium-logo-short.png",
    aspectRatio: 0.4722
  },
  short_grey: {
    url: "/img/premium-branding/premium-logo-short-grey.png",
    aspectRatio: 0.4722
  },
  short_white: {
    url: "/img/premium-branding/premium-logo-short-white.png",
    aspectRatio: 0.4722
  },
  letter_grey: {
    url: "/img/premium-branding/premium-logo-letter-grey.png",
    aspectRatio: 0.8667
  }
};

const PremiumLogo = ({ width, variant }) => {
  const logoDetails = variants[variant];
  if (!logoDetails) {
    throw new Error(`Variant ${variant} unknown`);
  }

  let { url, aspectRatio } = logoDetails;
  const height = aspectRatio * width;
  return (
    <img
      style={{ height: `${height}px`, width: `${width}px` }}
      src={url}
      alt="wattpad premium logo"
    />
  );
};

PremiumLogo.propTypes = {
  width: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

export default PremiumLogo;
