import React from "react";

import { getDisplayName } from "../helpers";
import { I18nContext } from "../contexts";

export const injectTrans = WrappedComponent =>
  class extends React.Component {
    static displayName = `${getDisplayName(WrappedComponent)}WithTrans`;

    render() {
      return (
        <I18nContext.Consumer>
          {({ trans, ngettext }) => (
            <WrappedComponent
              {...this.props}
              trans={trans}
              ngettext={ngettext}
            />
          )}
        </I18nContext.Consumer>
      );
    }
  };
