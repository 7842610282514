import React, { useState, useEffect, useCallback, useRef } from "react";
import NukaCarousel from "nuka-carousel";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Icon } from "../../../shared-components";
import rtlLanguage from "../../../../helpers/handlebars/rtl-language";
import { useFeatureFlags, useTrans } from "../../../hooks";
/**
 * A large carousel with slides consisting of an image and link.
 */
const FeaturedCarousel = ({
  sectionType,
  slides = [],
  index,
  setPosition,
  windowWidth,
  wasSeen,
  wrapAround
}) => {
  const isRTL = rtlLanguage(parseInt(app.get("language"), 10));
  const el = useRef(null);
  const [slidesSeen, setSlidesSeen] = useState([]);
  const [carouselTop, setCarouselTop] = useState();
  const [carouselBottom, setCarouselBottom] = useState();

  const featureflags = useFeatureFlags();

  const [pauseAutoplay, setPauseAutoplay] = useState(false);

  const handleCarouselSlideInteraction = ({ pauseAutoplay = false }) => {
    if (featureflags.HFC_LIMIT_INCREASE) {
      setPauseAutoplay(pauseAutoplay);
    }
  };

  useEffect(
    () => {
      if (wasSeen) {
        memoAfterSlide(0);
      } else {
        const boundingBox = el.current.getBoundingClientRect();
        setCarouselTop(boundingBox.top);
        setCarouselBottom(boundingBox.bottom);
        setPosition(
          index,
          sectionType,
          boundingBox.top + window.scrollY,
          boundingBox.bottom + window.scrollY
        );
      }
    },
    [wasSeen, index, sectionType, setPosition, memoAfterSlide]
  );

  const isSmallScreen = windowWidth < 575;
  const triggerAutoplay = !isSmallScreen && wasSeen && !pauseAutoplay;

  const autoplayInterval = featureflags.HFC_LIMIT_INCREASE ? 5000 : 5500;

  const carouselProps = isSmallScreen
    ? {
        autoplay: triggerAutoplay,
        autoplayInterval,
        animation: "zoom",
        cellSpacing: 8,
        frameOverflow: "visible",
        initialSlideHeight: 168,
        initialSlideWidth: 282,
        slideOffset: 25,
        slidesToShow: 1.1,
        swiping: true, // touch interaction with the carousel. Enabled by default.
        withoutControls: true,
        wrapAround: true
      }
    : {
        autoplay: triggerAutoplay,
        autoplayInterval,
        dragging: false, //mouse interaction with the carousel. Enabled by default
        slidesToShow: 1,
        initialSlideHeight: 400,
        renderCenterRightControls: ({ currentSlide, nextSlide, slideCount }) =>
          renderNextBtn(currentSlide, nextSlide, slideCount),
        renderCenterLeftControls: ({ currentSlide, previousSlide }) =>
          renderPrevBtn(currentSlide, previousSlide),
        /**
         * featureflags.HFC_LIMIT_INCREASE corrects the use of the bottom controls and applies appropriate styling
         */
        renderBottomRightControls: featureflags.HFC_LIMIT_INCREASE
          ? null
          : ({ currentSlide, slideCount, goToSlide }) =>
              renderPagingDots(currentSlide, slideCount, goToSlide),
        renderBottomCenterControls: featureflags.HFC_LIMIT_INCREASE
          ? ({ currentSlide, slideCount, goToSlide }) =>
              renderPagingDots(currentSlide, slideCount, goToSlide)
          : null,
        wrapAround: true
      };
  const slideHeight = isSmallScreen ? 157 : 267;

  const announceSlide = ({ currentSlide, slideCount }) =>
    `Slide ${currentSlide} out of ${slideCount}`;

  const renderNextBtn = (currentSlide, nextSlide, slideCount) => {
    if (currentSlide !== slideCount - 1 || wrapAround) {
      return (
        <button className="next-btn" onClick={nextSlide}>
          <Icon name="arrowRight" size="22" alt="next" strokeWidth="4" />
        </button>
      );
    }
    return null;
  };

  const renderPrevBtn = (currentSlide, previousSlide) => {
    if (currentSlide !== 0 || wrapAround) {
      return (
        <button className="prev-btn" onClick={previousSlide}>
          <Icon name="arrowLeft" size="22" alt="previous" strokeWidth="4" />
        </button>
      );
    }
    return null;
  };

  const renderPagingDots = (currentSlide, slideCount, goToSlide) => {
    return (
      <div className="controls">
        {[...Array(slideCount)].map((s, i) => {
          let classes = classNames("control", {
            active: i === currentSlide
          });
          return (
            <div
              className={classes}
              key={i}
              onClick={() => {
                goToSlide(i);
              }}
              onKeyDown={() => {
                goToSlide(i);
              }}
              /**
               * We should only suface one control to keyboard/screen reader
               * users. Hiding this additional control for both as keyboard tabs
               * through the images and screenreader moves through headings and images.
               */
              aria-hidden
              tabIndex="-1"
            />
          );
        })}
      </div>
    );
  };

  const carouselOutOfView = () => {
    if (!el.current) return true;
    // first condition is if top of carousel to top of viewport is too negative (so carousel high up)
    // second is if top of carousel is too positive (so carousel too far down, in case carousel is not first item)
    return (
      el.current.getBoundingClientRect().top <
        (carouselTop - carouselBottom) / 2 ||
      el.current.getBoundingClientRect().top >
        window.innerHeight - (carouselBottom - carouselTop) / 2
    );
  };

  const afterSlide = slideIndex => {
    // don't trigger events when out of view
    if (carouselOutOfView()) {
      return;
    }
    if (slidesSeen.includes(slideIndex)) {
      return;
    }
    window.te.push("event", "home", "featured", null, "view", {
      itemid: slides[slideIndex].id,
      offset: slideIndex
    });
    setSlidesSeen([...slidesSeen, slideIndex]);
  };

  const memoAfterSlide = useCallback(afterSlide);

  return slides !== null && slides.length !== 0 ? (
    <div
      ref={el}
      {...(isRTL
        ? { dir: "ltr", className: "featured-carousel rtl-adjust" }
        : { className: "featured-carousel" })}
    >
      {/* If page is rtl, enforce carousel to be LTR to prevent NukaCarousel from breaking */}
      <NukaCarousel
        {...carouselProps}
        afterSlide={afterSlide}
        enableKeyboardControls
        heightMode="max"
        renderAnnounceSlideMessage={announceSlide}
      >
        {slides.map((slide, index) => {
          const isLastSlide = index === slides.length - 1;
          return (
            <FeatureSlide
              {...slide}
              height={slideHeight}
              topHeader={isSmallScreen}
              ind={index}
              isLastSlide={isLastSlide}
              offset={index}
              key={index}
              handleCarouselSlideInteraction={handleCarouselSlideInteraction}
            />
          );
        })}
      </NukaCarousel>
      <hr className="module-divider" />
    </div>
  ) : null;
};

FeaturedCarousel.propTypes = {
  index: PropTypes.number.isRequired,
  sectionType: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  slides: PropTypes.array.isRequired,
  wasSeen: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number,
  wrapAround: PropTypes.bool.isRequired
};

/**
 * A slide with image links to be used in a carousel, not specific to any type of content (stories, users, etc).
 * May optionally have text placed on the image, such as a heading or subheading.
 */
const FeatureSlide = ({
  id,
  image,
  heading,
  subheading,
  weblink,
  ind,
  offset,
  isLastSlide,
  height,
  handleCarouselSlideInteraction,
  ariaLabel
}) => {
  const { trans } = useTrans();
  const imageAriaLabel = ariaLabel || trans("Promotional banner image");

  const onClickFeatured = () => {
    window.te.push("event", "home", "featured", null, "click", {
      itemid: id,
      offset: offset
    });
  };
  return (
    <div
      className={classNames("featured-slide", `slide-${ind}`, {
        "last-slide": isLastSlide,
        "not-last-slide": !isLastSlide
      })}
    >
      <a
        href={weblink}
        onClick={onClickFeatured}
        onMouseEnter={() => {
          handleCarouselSlideInteraction({ pauseAutoplay: true });
        }}
        onMouseLeave={() => {
          handleCarouselSlideInteraction({ pauseAutoplay: false });
        }}
        onFocus={() => {
          handleCarouselSlideInteraction({ pauseAutoplay: true });
        }}
        onBlur={() => {
          handleCarouselSlideInteraction({ pauseAutoplay: false });
        }}
      >
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${image})`,
            height: `${height}px`,
            backgroundSize: "cover"
          }}
          alt="featured slide"
          loading="lazy"
          role="img"
          aria-label={imageAriaLabel}
        />
      </a>
      <div className="featured-text">
        <h2>{heading}</h2>
        <p>{subheading}</p>
      </div>
    </div>
  );
};

FeatureSlide.propTypes = {
  heading: PropTypes.string,
  height: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  image: PropTypes.string.isRequired,
  ind: PropTypes.number.isRequired,
  isLastSlide: PropTypes.bool,
  offset: PropTypes.number.isRequired,
  subheading: PropTypes.string,
  topHeader: PropTypes.bool,
  weblink: PropTypes.string,
  handleCarouselSlideInteraction: PropTypes.func,
  ariaLabel: PropTypes.string
};

export default FeaturedCarousel;
