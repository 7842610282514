import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon, UserSafetyOperations } from "../../shared-components";
import classNames from "classnames";
import { useTrans } from "../../hooks/useTrans";

import { toggleModal } from "../../shared-components/modals/actions";
import UnblockModal from "../../shared-components/modals/components/user-safety-modals/UnblockModal";
import { unblockUser } from "../profile/reducers";

const BlockedUsersList = ({ blockedUsers, toggleModal, unblockUser }) => {
  const firstChildRef = useRef(null);
  const onUnblockUser = username => {
    const component = () => (
      <UnblockModal
        username={username}
        toggleModal={toggleModal}
        onUnblock={unblockUser}
      />
    );
    component.displayName = "UnblockModal";

    toggleModal({
      className: "unblock-modal",
      hideClose: true,
      component
    });
  };
  useEffect(
    () => {
      if (firstChildRef.current) {
        firstChildRef.current.focus();
      }
    },
    [blockedUsers]
  );

  return blockedUsers.map((blockedUser, index) => {
    const { username, avatar } = blockedUser;
    let firstUserRef;
    if (index == 0) {
      firstUserRef = firstChildRef;
    }
    return (
      <li key={username} className="blocked-user">
        <img src={avatar} alt={username} align="top" width="64" height="64" />
        <div className="username-wrapper">
          <a
            href={`/user/${username}`}
            className="user-profile on-navigate"
            title={username}
            ref={firstUserRef}
          >
            {username}
          </a>
        </div>
        <div className="button-group inline-block relative ">
          <button
            className="btn btn-white dropdown-toggle more-options unblock-button"
            data-toggle="dropdown"
            aria-controls="more-options"
            aria-expanded="false"
            aria-label="more options"
          >
            <Icon name="more" size="24" />
          </button>
          <div className="triangle" />
          <div className="dropdown-menu align-right">
            <UserSafetyOperations
              username={username}
              showModerationOptions={true}
              showBlockOption={true}
              isLoading={false}
              isBlockedByCurrentUser={true}
              onUnblockUser={onUnblockUser}
              onReportUser={() => {}} // noop just to render the button since the ReportManager mixin handles this for now
            />
          </div>
        </div>
      </li>
    );
  });
};

function BlockListUI({ toggleModal, unblockUser, blockedUsers }) {
  const { trans } = useTrans();
  const [currentPage, setPage] = useState(1);

  const pageLimit = 8;

  const moveBackward = e => {
    e.preventDefault();
    if (e.key !== "Tab" && !e.shiftKey) {
      const pageNum = currentPage;
      if (pageNum > 1) {
        setPage(pageNum - 1);
      }
    }
  };

  const moveForward = e => {
    e.preventDefault();
    if (e.key !== "Tab" && !e.shiftKey) {
      const pageNum = currentPage;
      setPage(pageNum + 1);
    }
  };
  const setTotalPages = (usersLength, pageLimit) => {
    return Math.ceil(usersLength / pageLimit);
  };

  const totalPages = setTotalPages(blockedUsers.length, pageLimit);
  const navigateBackClasses = classNames("", {
    "wp-base-1": currentPage !== 1,
    "wp-neutral-2": currentPage === 1
  });
  const navigateForwardClasses = classNames("", {
    "wp-base-1":
      blockedUsers.length > pageLimit * currentPage &&
      currentPage !== totalPages,
    "wp-neutral-2": currentPage >= totalPages
  });

  const offset = (currentPage - 1) * pageLimit;
  const currentUsers = blockedUsers.slice(offset, offset + pageLimit);

  return (
    <div id="block-list">
      <h5 className="block-subheading">
        {trans(
          "When you block someone, that person won’t be able to follow you, send you messages, post on your profile or read and comment on your stories."
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="learn-more"
          href="https://wattpad.zendesk.com/knowledge/articles/4408465238164/en-us?brand_id=119734"
        >
          {trans("Learn more")}
        </a>
      </h5>
      <div id="blocked-users">
        <ul className="user-list">
          <BlockedUsersList
            blockedUsers={currentUsers}
            toggleModal={toggleModal}
            unblockUser={unblockUser}
          />
        </ul>
        <div className="controls">
          <button
            className="back-btn"
            onClick={moveBackward}
            onKeyPress={moveBackward}
            disabled={currentPage === 1}
          >
            <Icon iconName="fa-left" height="18" color={navigateBackClasses} />
          </button>
          <span className="current-page">{currentPage}</span>
          <button
            className="forward-btn"
            onClick={moveForward}
            onKeyPress={moveForward}
            disabled={
              currentPage >= totalPages || currentUsers.length < pageLimit
            }
          >
            <Icon
              iconName="fa-right"
              height="18"
              color={navigateForwardClasses}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

BlockListUI.propTypes = {
  blockedUsers: PropTypes.array,
  toggleModal: PropTypes.func,
  unblockUser: PropTypes.func.isRequired,
  currentUser: PropTypes.string
};

const BlockList = connect(
  state => ({
    blockedUsers: state.profileData.blockedUsers
  }),
  { unblockUser, toggleModal }
)(BlockListUI);

export default BlockList;
