import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { isClient } from "../../helpers";
import { useTrans } from "../../hooks";
import Wallet from "./Wallet";

function shouldShowWalletOnboarding() {
  return (
    isClient() &&
    !parseInt(window.wattpad.utils.getCookie("seen-wallet-onboard"))
  );
}

function WalletWithOnboarding(props) {
  const [showOnboarding, setShowOnboarding] = useState(
    shouldShowWalletOnboarding()
  );

  if (!showOnboarding) {
    return <Wallet {...props} />;
  }

  const { isDesktop } = props;

  return (
    <div className={classnames("wallet-onboarding", { desktop: isDesktop })}>
      <div className="wallet-overlay" />
      <div className="wallet-bubble">
        <TooltipContent
          onOnboardingFinish={() => {
            window.wattpad.utils.setCookie("seen-wallet-onboard", 1);
            setShowOnboarding(false);
          }}
        />
        <Wallet {...props} />
      </div>
    </div>
  );
}

WalletWithOnboarding.propTypes = { isDesktop: PropTypes.bool.isRequired };

function TooltipContent({ onOnboardingFinish }) {
  const { trans } = useTrans();

  return (
    <div className="tooltip-content">
      <h6>{trans("Keep track of your Coin balance")}</h6>
      <p>
        {trans(
          "Visit the Coin Shop on the Wattpad mobile app to purchase Coins for Wattpad Originals"
        )}
      </p>
      <button onClick={onOnboardingFinish} className="btn ack-tooltip">
        {trans("Got it")}
      </button>
      <div className="arrow" />
    </div>
  );
}
TooltipContent.propTypes = {
  onOnboardingFinish: PropTypes.func.isRequired
};

const ConnectedWalletWithOnboarding = connect(state => ({
  isDesktop: state.deviceData.isDesktop
}))(WalletWithOnboarding);

export default ConnectedWalletWithOnboarding;
