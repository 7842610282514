import PropTypes from "prop-types";
import React from "react";
import { useTrans } from "../../hooks";
import { ConnectImage } from "../../shared-components";
import {
  BrowserRouter,
  StaticRouter,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { isClient } from "../../helpers";
import ReaderOrWriter from "./ReaderOrWriter";
import WriterMotivation from "./WriterMotivation";

const OnboardingWriterJourney = ({ url, username }) => {
  const { trans } = useTrans();
  const Router = isClient() ? BrowserRouter : StaticRouter;
  return (
    <Router basename="/start/writerjourney" location={url} context={{}}>
      <React.Fragment>
        <div className="full-screen background-1 onboarding-bg" />
        <div className="container">
          <div className="onboardingModal modal fade in writerJourney">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="onboarding-writer-journey">
                    <ConnectImage
                      name="logos/wp-logo-orange.png"
                      alt="wattpad logo"
                      className="wp-logo"
                      width="174"
                    />
                    <h4>{trans("Hi @%s", username)}</h4>
                    <Switch>
                      <Route exact path="/">
                        <ReaderOrWriter />
                      </Route>
                      <Route path="/motivation">
                        <WriterMotivation />
                      </Route>
                      <Route render={() => <Redirect to="/" />} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Router>
  );
};

OnboardingWriterJourney.propTypes = {
  username: PropTypes.string,
  url: PropTypes.string
};

export default OnboardingWriterJourney;
