import { WORD_CHAR_LENGTH, HUMAN_READ_SPEED } from "../constants";

const getWords = length => length / WORD_CHAR_LENGTH;

/**
 *
 * Calculates an estimated read time (in minutes), based on the character length of a story or story part.
 *
 * @param {Number} length
 * @returns {Number} the estimated read time in minutes
 */
export const getReadTime = length => {
  if (Math.sign(length) !== 1) {
    return 0;
  }

  return Math.round(getWords(length) / HUMAN_READ_SPEED);
};

/**
 *
 * Calculates an estimated read time (in minutes), based on the cnumber of words in a story or story part.
 *
 * @param {Number} length
 * @returns {Number} the estimated read time in minutes
 */
export const getReadTimeByWordCount = length => {
  if (Math.sign(length) !== 1) {
    return 0;
  }

  return Math.round(length / HUMAN_READ_SPEED);
};

/**
 * Divides the number of minutes provided, into hours and remaining minutes
 *
 * @param {Number} minutes
 * @returns {Object}  Contains the 'hours' and 'minutes'
 */
export const formatReadTime = minutes => {
  const hours = Math.floor(minutes / 60);

  return {
    hours,
    minutes: Math.floor(minutes - hours * 60)
  };
};
