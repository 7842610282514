import React from "react";
import PropTypes from "prop-types";

import { useTrans, useTrapKeyboardNavigation } from "../../../../hooks";

const UnblockModal = ({ username, onUnblock, toggleModal }) => {
  const { trans } = useTrans();
  const unblockInfo = trans(
    "This account will now be able to follow you, send you messages, post on your profile, and read and comment on your stories. This action can take 30 minutes to take effect."
  );

  let modalRef;
  let buttonRef;

  // Check window in case document is not planted
  if (typeof window !== undefined) {
    modalRef = document.querySelector(".unblock-modal");
    buttonRef = document.querySelector(".unblock-button");
  }

  useTrapKeyboardNavigation(modalRef, buttonRef, toggleModal);

  return (
    <div className="block-modal-content">
      <h3 className="block-modal-title">{trans("Unblock %s", username)}</h3>
      <p>{unblockInfo}</p>
      <div className="confirm-buttons">
        <button className="cancel-block" onClick={toggleModal}>
          {trans("Cancel")}
        </button>
        <button
          className="btn-primary btn-md"
          onClick={() => {
            toggleModal();
            if (onUnblock) {
              onUnblock(username);
            }
          }}
        >
          {trans("Unblock")}
        </button>
      </div>
    </div>
  );
};

UnblockModal.defaultProps = {
  onUnblock: () => {},
  toggleModal: () => {}
};

UnblockModal.propTypes = {
  username: PropTypes.string.isRequired,
  trans: PropTypes.func,
  onUnblock: PropTypes.func,
  toggleModal: PropTypes.func
};

export default UnblockModal;
