import React from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../hooks";
import { Icon } from "../../shared-components";

import Wallet from "./Wallet";

// Wrapper component for modals that are part of the coin purchase
// flow, to standardize styling and behavior of buttons at the top.
export default function CoinsModalContainer({ children, toggleModal }) {
  const { trans } = useTrans();
  return (
    <React.Fragment>
      <div className="top-buttons-container">
        <Wallet allowOpenCoinShop={false} disabled />
        <button
          className="btn-no-style"
          onClick={toggleModal}
          title={trans("Close Coin Shop")}
        >
          <Icon
            iconName="fa-remove"
            color="wp-neutral-1"
            height="22"
            className="close-btn"
          />
        </button>
      </div>
      <div className="coins-modal-body">{children}</div>
    </React.Fragment>
  );
}

CoinsModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  toggleModal: PropTypes.func.isRequired
};
