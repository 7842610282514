import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../";

const LeadingIcon = () => {
  return (
    <Icon name="info" size="24" color="wp-neutral-1" className="leading-icon" />
  );
};

export const InfoBar = ({ children }) => {
  return (
    <div className="info-bar">
      <LeadingIcon />
      {children}
    </div>
  );
};

InfoBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
