import React from "react";
import PropTypes from "prop-types";
import { formatStoryUrl } from "../helpers";
import { useTrans } from "../hooks";

const BrowseCategories = ({ browseTopics = [] }) => {
  const { trans } = useTrans();

  const handleCategoryClick = (e, name, categoryUrl) => {
    e.preventDefault();

    // Click event tracking
    window.te.push("event", "browse", "item", null, "click", {
      page: "search",
      target: name
    });

    app.router.navigate(categoryUrl, { trigger: true });
  };

  return (
    <>
      <h1 className="browse-title">{trans("Browse Categories")}</h1>
      <div className="topics-container">
        {browseTopics.map(item => {
          let { name, topic, browseURL, image } = item;
          let categoryUrl = browseURL
            ? formatStoryUrl(browseURL)
            : `/stories/${name}`;

          return (
            <a
              key={name}
              href={categoryUrl}
              className="browse-topic-tile"
              style={image ? { backgroundImage: `url(${image})` } : {}}
              onClick={e => handleCategoryClick(e, name, categoryUrl)}
            >
              {/* The topics have already been translated so we don't need to pass it through trans */}
              {topic}
            </a>
          );
        })}
      </div>
    </>
  );
};

BrowseCategories.propTypes = {
  browseTopics: PropTypes.array
};

export default BrowseCategories;
