import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ConnectImage } from "../../shared-components";
import { injectTrans } from "../../helpers";
import WorksStoryItem from "./WorksStoryItem";

class ExternalCoverUpload extends React.Component {
  state = {
    done: false,
    selectedStory: this.props.selectedStoryId,
    uploading: false
  };

  selectStory = story => {
    this.setState({
      selectedStory: story.id
    });
  };

  componentDidMount() {
    const { username } = window.wattpad.utils.currentUser().toJSON();

    this.setState({
      newCover: document.getElementById("newCover").getAttribute("src")
    });
    $.get(`/api/v3/users/${username}/stories`).then(response => {
      // This setState looks intense because if we already have a state.selectedStory at this point
      // then it means it came in via the deeplink URL, and therefore that specific story should be
      // bubbled to the top of the list of stories.
      this.setState({
        works: response.stories.sort((storyA, storyB) => {
          if (
            this.state.selectedStory &&
            storyA.id == this.state.selectedStory
          ) {
            return -1;
          } else if (
            this.state.selectedStory &&
            storyB.id == this.state.selectedStory
          ) {
            return 1;
          } else {
            return 0;
          }
        })
      });
    });
  }

  detectCanvasSupport() {
    var canvasEl = document.createElement("canvas"); //create the canvas object

    //if the method is not supported, i.e canvas is not supported
    if (!canvasEl.getContext) {
      return false;
    }
    return true;
  }

  captureCover(imageSrc) {
    if (this.detectCanvasSupport()) {
      return new Promise(resolve => {
        var canvasSource = new Image();
        canvasSource.crossOrigin = "Anonymous";
        canvasSource.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = 512;
          canvas.height = 800;
          var canvasContext = canvas.getContext("2d");
          canvasContext.drawImage(canvasSource, 0, 0, 512, 800);
          resolve(canvas.toDataURL());
        };
        canvasSource.src = imageSrc;
      });
    }
  }

  uploadCover(storyId) {
    this.captureCover(this.state.newCover).then(data => {
      if (data) {
        this.setState({
          uploading: true
        });
        return Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/apiv2/updatestorycover",
            data: {
              id: storyId,
              image: data.match(/;base64,(.+)$/)[1]
            }
          })
        ).then(() => {
          this.setState({
            done: true
          });
          window.te.push("event", "writer", "cover", null, "add", {
            storyid: storyId,
            source: "canva"
          });
        });
      }
    });
  }

  render() {
    const { trans } = this.props;
    const stories = this.state.works
      ? this.state.works
      : this.props.works
        ? this.props.works
        : [];
    const { newCover } = this.state.newCover ? this.state : this.props;

    const { done, uploading } = this.state;

    return (
      <div id="creation-works-listing" className="container">
        {done && (
          <div className={classNames("cover-finished")}>
            <span
              className="success-message"
              dangerouslySetInnerHTML={{
                __html: trans(
                  "Cover uploaded to <strong>'%s'</strong>",
                  this.state.works.find(
                    story => story.id == this.state.selectedStory
                  ).title
                )
              }}
            />
            <a
              href={`/myworks/${this.state.selectedStory}`}
              className="btn btn-md btn-orange"
            >
              {trans("Go to story")}
            </a>
          </div>
        )}
        <div className="import-cover">
          {!done && (
            <div className="new-cover-block">
              <img width="256" id="newCover" src={newCover} alt="new cover" />
            </div>
          )}
          {!done && (
            <div className={"user-story-list"}>
              <h3>{trans("Add cover to selected story")}</h3>
              {uploading && (
                <ConnectImage
                  name="/icons/spinner-black.svg"
                  className="progress-spinner fa-spin"
                  alt={trans("Spinner")}
                  height="48"
                  width="48"
                />
              )}
              {!uploading &&
                stories.map(story => {
                  let selectedStory = false;
                  if (
                    this.state.selectedStory &&
                    this.state.selectedStory == story.id
                  ) {
                    selectedStory = true;
                  }

                  return (
                    <WorksStoryItem
                      key={story.id}
                      story={story}
                      className={classNames({ selected: selectedStory })}
                      onSelectStory={this.selectStory}
                    />
                  );
                })}
            </div>
          )}
        </div>
        {!done && (
          <div className={classNames("continue-nav", { hidden: done })}>
            <button
              onClick={this.uploadCover.bind(this, this.state.selectedStory)}
              className={classNames(`btn btn-md btn-orange`, {
                disabled: !this.state.selectedStory
              })}
            >
              {trans("Next")}
            </button>
          </div>
        )}
      </div>
    );
  }

  static propTypes = {
    works: PropTypes.array,
    coverUri: PropTypes.string,
    selectedStoryId: PropTypes.string,
    trans: PropTypes.func.isRequired
  };
}

export default injectTrans(ExternalCoverUpload);
