import React, { useRef } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

import { useTrans, useMuteUsers } from "../hooks";
import UserSafetyLinks from "./UserSafetyLinks";

function UserSafetyOperations({
  username,
  isMutedByCurrentUser,
  isBlockedByCurrentUser,
  onUserMuted,
  onUserUnmuted,
  onBlockUser,
  onUnblockUser,
  onDeleteConversation,
  onReportUser,
  showBlockOption,
  showModerationOptions
}) {
  const { trans } = useTrans();
  const showMuteOption = isMutedByCurrentUser != undefined;

  return (
    <div className="user-safety-operations">
      {showModerationOptions &&
        showMuteOption &&
        !isBlockedByCurrentUser && (
          <MuteButton
            username={username}
            isMutedByCurrentUser={isMutedByCurrentUser}
            onUserMuted={onUserMuted}
            onUserUnmuted={onUserUnmuted}
          />
        )}
      {showModerationOptions &&
        showBlockOption &&
        isBlockedByCurrentUser !== undefined && (
          <BlockButton
            username={username}
            isBlockedByCurrentUser={isBlockedByCurrentUser}
            onBlockUser={() => onBlockUser(username)}
            onUnblockUser={() => {
              onUnblockUser(username);
            }}
          />
        )}
      {onReportUser && (
        <button
          onClick={onReportUser}
          className="on-report user-safety-operation btn-no-background btn-left-icon"
          data-username={username}
        >
          <Icon name="report" />
          {trans("Report")}
        </button>
      )}
      {onDeleteConversation && (
        <button
          onClick={onDeleteConversation}
          className="user-safety-operation btn-no-background btn-left-icon delete"
        >
          <Icon name="trash" />
          {trans("Delete Conversation")}
        </button>
      )}
      <UserSafetyLinks />
    </div>
  );
}

function MuteButton({
  isMutedByCurrentUser,
  username,
  onUserUnmuted,
  onUserMuted
}) {
  const { trans } = useTrans();
  const muteButtonRef = useRef();
  const { openMuteUserModal, openUnmuteUserModal } = useMuteUsers();

  return (
    <React.Fragment>
      {!isMutedByCurrentUser ? (
        <button
          ref={muteButtonRef}
          onClick={() =>
            openMuteUserModal(username, muteButtonRef, onUserMuted)
          }
          className="user-safety-operation btn-no-background btn-left-icon mute"
        >
          <Icon name="mute" />
          {trans("Mute %s", username)}
        </button>
      ) : (
        <button
          onClick={() =>
            openUnmuteUserModal(username, muteButtonRef, onUserUnmuted)
          }
          className="user-safety-operation btn-no-background btn-left-icon unmute"
        >
          <Icon name="unmute" />
          {trans("Unmute %s", username)}
        </button>
      )}
    </React.Fragment>
  );
}

function BlockButton({
  isBlockedByCurrentUser,
  onBlockUser,
  onUnblockUser,
  username
}) {
  const { trans } = useTrans();
  return (
    <React.Fragment>
      {!isBlockedByCurrentUser ? (
        <button
          onClick={onBlockUser}
          className="user-safety-operation btn-no-background btn-left-icon block"
        >
          <Icon name="block" strokeWidth="0" viewBox="16" />
          {trans("Block %s", username)}
        </button>
      ) : (
        <button
          onClick={onUnblockUser}
          className="user-safety-operation btn-no-background btn-left-icon unblock"
        >
          <Icon name="block" strokeWidth="0" viewBox="16" />
          {trans("Unblock %s", username)}
        </button>
      )}
    </React.Fragment>
  );
}

UserSafetyOperations.propTypes = {
  username: PropTypes.string.isRequired,
  onDeleteConversation: PropTypes.func,
  isMutedByCurrentUser: PropTypes.bool,
  isBlockedByCurrentUser: PropTypes.bool,
  onUserMuted: PropTypes.func,
  onUserUnmuted: PropTypes.func,
  onBlockUser: PropTypes.func,
  onUnblockUser: PropTypes.func,
  onReportUser: PropTypes.func,
  showBlockOption: PropTypes.any,
  showModerationOptions: PropTypes.bool
};

MuteButton.propTypes = {
  username: PropTypes.string.isRequired,
  isMutedByCurrentUser: PropTypes.bool.isRequired,
  onUserMuted: PropTypes.func,
  onUserUnmuted: PropTypes.func
};

BlockButton.propTypes = {
  username: PropTypes.string.isRequired,
  isBlockedByCurrentUser: PropTypes.bool.isRequired,
  onBlockUser: PropTypes.func.isRequired,
  onUnblockUser: PropTypes.func.isRequired
};

export default UserSafetyOperations;
