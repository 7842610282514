import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTrans } from "../../../hooks";
import { getCoverSrc, getCoverSrcSet, simpleShorten } from "../../../helpers";
import { Icon } from "../../../shared-components";
import StoryIconBar from "../../../shared-components/StoryIconBar";

const StorySpotlight = ({
  completed: isCompleted,
  cover,
  description,
  getCollectionsForStoryIds,
  id,
  index,
  isPaid,
  numParts,
  offset,
  onLibraryClick,
  readingLists,
  sectionType,
  setPosition,
  sources,
  storiesCollections,
  storiesCollectionsLoading = false,
  title,
  wasSeen,
  windowWidth,
  mature: isMature = false
}) => {
  const storyDetailsRef = useRef(null);
  const { trans } = useTrans();

  const [firstStoryPartId, setFirstStoryPartId] = useState();

  useEffect(
    () => {
      const boundingBox = storyDetailsRef.current.getBoundingClientRect();
      setPosition(
        index,
        sectionType,
        boundingBox.top + window.scrollY,
        boundingBox.bottom + window.scrollY
      );
    },
    [index, sectionType, setPosition]
  );

  useEffect(
    () => {
      if (wasSeen) {
        window.te.push("event", "story", null, null, "view", {
          storyid: id,
          page: "home",
          algo_source: sources,
          position: index,
          offset,
          type: sectionType
        });
      }
    },
    [wasSeen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      Promise.resolve($.get(`/api/v3/stories/${id}?fields=firstPartId`))
        .then(story => {
          setFirstStoryPartId(story.firstPartId);
        })
        .catch(error => {
          const message = error.responseText
            ? error.responseText
            : error.statusText;
          console.log(message || error); // eslint-disable-line no-console
        });
    },
    [id]
  );

  // If adding more than one StorySpotlight or more than
  // one section on Home that needs related collections,
  // extract storyIds from /home response and make a
  // batch call to collections endpoint.
  // Or consider including related collection data in Home response.
  useEffect(
    () => {
      if (
        wattpad.utils.getDeviceType() !== "mobile" &&
        !storiesCollectionsLoading &&
        (!storiesCollections || !storiesCollections[id])
      ) {
        getCollectionsForStoryIds(id);
      }
    },
    [
      getCollectionsForStoryIds,
      id,
      storiesCollections,
      storiesCollectionsLoading
    ]
  );

  const sendStoryClickEvent = () => {
    window.te.push("event", "story", null, null, "click", {
      storyid: id,
      page: "home",
      algo_source: sources,
      position: index,
      offset,
      type: sectionType
    });
  };

  const handleFreePreviewClick = event => {
    event.preventDefault();
    sendStoryClickEvent();

    const storyReadLink = firstStoryPartId
      ? `/${firstStoryPartId}`
      : `/story/${id}`;
    app.router.navigate(storyReadLink, { trigger: true });
  };

  const handleStoryDetailsClick = event => {
    event.preventDefault();
    sendStoryClickEvent();

    app.router.navigate(`/story/${id}`, { trigger: true });
  };

  const coverSizes = getCoverSrcSet(cover);
  const defaultCover = getCoverSrc(cover, 238);

  const buttonRef = useRef();
  const handleLibraryClick = () => {
    onLibraryClick(buttonRef, id);
  };

  const StorySpotlightDescription = ({
    id,
    description,
    characterDisplayLimit,
    handleStoryDetailsClick
  }) => {
    return (
      <div className="description">
        <p className="description-text">
          {simpleShorten(description, characterDisplayLimit, false)}
        </p>
        <a
          className="link"
          onClick={handleStoryDetailsClick}
          href={`/story/${id}`}
        >
          {trans("More details")}
          <Icon name="chevRight" size="24" />
        </a>
      </div>
    );
  };

  return (
    <div className="module">
      <div className="module-content full-width">
        <div className="story-spotlight-details" ref={storyDetailsRef}>
          <div className="cover-wrapper">
            <a href={`/story/${id}`} onClick={handleStoryDetailsClick}>
              <img
                className="story-spotlight-cover"
                srcSet={coverSizes}
                sizes="(max-width: 574px) 151px, 238px"
                src={defaultCover}
                alt={`${title} cover`}
              />
            </a>

            <div className="story-info">
              <div className="new-flag">{trans("New")}</div>
              <div className="title">{title}</div>
              <StoryIconBar
                numParts={numParts}
                isMature={isMature}
                isCompleted={isCompleted}
                isPaid={isPaid}
              />
              <div className="buttons-wrapper">
                <a
                  className="read btn-primary"
                  onClick={handleFreePreviewClick}
                  href={`/story/${id}`}
                >
                  {trans("Read now")}
                </a>
                {readingLists && (
                  <button
                    className="btn-icon"
                    title={trans("Add story to...")}
                    aria-label={trans("Add story to...")}
                    ref={buttonRef}
                    onClick={handleLibraryClick}
                  >
                    <Icon
                      name="add"
                      size="24"
                      color="wp-neutral-1"
                      strokeWidth="2.5"
                    />
                  </button>
                )}
              </div>
              {/* For large views that allow the screen space, Story Spotlight Description should be displayed adjacent
            to the story cover */}
              {windowWidth > 575 && (
                <StorySpotlightDescription
                  id={id}
                  handleStoryDetailsClick={handleStoryDetailsClick}
                  description={description}
                  characterDisplayLimit={290}
                />
              )}
            </div>
          </div>

          {/* For mobile devices and small screens, Story Spotlight Description should be displayed under
          the story cover. This is also the fallback scenario for if windowWidth is returned as null for whatever reason */}
          {(!windowWidth || windowWidth <= 575) && (
            <StorySpotlightDescription
              id={id}
              handleStoryDetailsClick={handleStoryDetailsClick}
              description={description}
              characterDisplayLimit={290}
            />
          )}
        </div>
      </div>
    </div>
  );
};

StorySpotlight.propTypes = {
  characterDisplayLimit: PropTypes.number,
  completed: PropTypes.bool,
  cover: PropTypes.string,
  description: PropTypes.string,
  getCollectionsForStoryIds: PropTypes.func,
  handleStoryDetailsClick: PropTypes.func,
  id: PropTypes.number,
  index: PropTypes.number,
  isPaid: PropTypes.bool,
  numParts: PropTypes.number,
  offset: PropTypes.number,
  onLibraryClick: PropTypes.func,
  readingLists: PropTypes.array,
  sectionType: PropTypes.string,
  setPosition: PropTypes.func,
  sources: PropTypes.array,
  storiesCollections: PropTypes.object,
  storiesCollectionsLoading: PropTypes.bool,
  title: PropTypes.string,
  wasSeen: PropTypes.bool,
  windowWidth: PropTypes.number,
  mature: PropTypes.bool
};

export default StorySpotlight;
