import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { sprintf } from "sprintf-js";

import { useTrans } from "../hooks";
import { Icon } from "./";
import StoryParts from "./StoryParts";

const CollapsingStoryParts = ({
  numParts,
  className,
  isPaywalled,
  readingPosition,
  parts
}) => {
  const { trans, ngettext } = useTrans();
  const [isSeeAll, setIsSeeAll] = useState(false);

  const toggleSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <div className={classNames("parts", className)}>
      <div className="parts-header">
        <div className="parts-title">
          <Icon
            className="card__action-icon"
            name="partsBeta"
            size="32"
            fill={true}
            viewBox="16"
            strokeWidth="1"
          />
          <p className="parts-num">
            {sprintf(ngettext("%s part", "%s parts", numParts), numParts)}
          </p>
        </div>
        <button className="see-all" onClick={() => toggleSeeAll()}>
          {isSeeAll ? trans("Hide") : trans("See all")}
        </button>
      </div>
      {!isSeeAll && (
        <StoryParts
          parts={parts.slice(-3)}
          isPaywalled={isPaywalled}
          className={"shown-xxs parts-wrapper"}
          readingPosition={readingPosition}
        />
      )}
      {isSeeAll && (
        <StoryParts
          parts={parts}
          isPaywalled={isPaywalled}
          className={"shown-xxs parts-wrapper"}
          readingPosition={readingPosition}
        />
      )}
    </div>
  );
};

CollapsingStoryParts.propTypes = {
  className: PropTypes.string,
  numParts: PropTypes.number,
  lastUpdated: PropTypes.string,
  isPaywalled: PropTypes.bool.isRequired,
  readingPosition: PropTypes.shape({
    part: PropTypes.number,
    position: PropTypes.oneOfType([
      // API returns 0 or "n"
      PropTypes.string,
      PropTypes.number
    ])
  }),
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string
    })
  ).isRequired
};

export default CollapsingStoryParts;
