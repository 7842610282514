import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import { Icon } from "../../shared-components";

function VerificationFeatures() {
  const { trans } = useTrans();
  return (
    <div className="checked-features">
      <CheckAndFeature
        featureStr={trans("Vote on stories that you like")} /* prettier-ignore */
      />
      <CheckAndFeature
        featureStr={trans("Comment on stories and profiles")} /* prettier-ignore */
      />
      <CheckAndFeature
        featureStr={trans("Update your profile")} /* prettier-ignore */
      />
      <CheckAndFeature
        featureStr={trans("Create your stories, and more")} /* prettier-ignore */
      />
    </div>
  );
}

function CheckAndFeature({ featureStr }) {
  return (
    <div className="check-and-feature">
      <Icon iconName="fa-check" height="22" className="check-icon" />
      <p>
        <strong>{featureStr.split(" ")[0]}</strong>
        {" " + featureStr.substring(featureStr.indexOf(" ") + 1)}
      </p>
    </div>
  );
}

CheckAndFeature.propTypes = {
  featureStr: PropTypes.string
};

export default VerificationFeatures;
