import React, { useState } from "react";
import { Icon } from "../shared-components";
import PropTypes from "prop-types";
import { useTrans } from "../hooks";
//import { TagCloud } from "@wattpad/web-ui-library";
import TagGrid from "../views/tags/TagGrid";
import classNames from "classnames";

const RefineByTagFilter = ({ searchTags, searchTerm, handleFilterChange }) => {
  const { trans } = useTrans();
  let formattedSearchTags = {};

  const [addTagText, setAddTagText] = useState("");
  const [disabled, setDisable] = useState(false);
  const [emptyTag, setEmptyTag] = useState(false);

  const black = "#121212"; // ds-neutral-100
  const lightGrey = "ds-neutral-40";
  const white = "#ffffff"; // ds-neutral-00-solid

  const toggleRefineByTag = (tag, evt) => {
    handleFilterChange({ evt, tag }, "toggleTag");
  };

  if (searchTags) {
    formattedSearchTags.tags = searchTags.map(tag => {
      return {
        ...tag,
        bgColor: lightGrey,
        color: black,
        activeBgColor: black,
        activeColor: white,
        className: "refine-by-tag",
        role: "button",
        ariaLabel: trans("filter by %s", tag.name),
        ariaDescribedBy: "screen-reader-description"
      };
    });
  }

  const handleChange = event => {
    setAddTagText(event.target.value);

    if (event.target.value.trim() || event.target.value === "") {
      setEmptyTag(false);
    }

    if (event.target.value.trim().indexOf(" ") < 0) {
      setDisable(false);
    }
  };

  const handleAddTagButton = () => {
    //check if tag contans spaces
    if (addTagText.trim().indexOf(" ") >= 0) {
      setDisable(true);
      return;
    } else {
      setDisable(false);
    }

    //check if tag is empty
    if (addTagText.trim()) {
      setEmptyTag(false);
    } else {
      setEmptyTag(true);
      return;
    }

    const regex = /[#\\]/g;
    const tagText = addTagText.trim()?.replace(regex, "");

    if (tagText && !searchTerm.includes(`#${tagText}`)) {
      handleFilterChange(`${searchTerm} #${tagText}`, "addTag");
    } else {
      handleDuplicateTag();
    }
  };

  const handleDuplicateTag = () => {
    //clear input bar
    setAddTagText("");

    //go to top of the page
    wattpad.utils.scrollToTop();

    //show loading skeleton
    $(".story-card-skeleton-container").removeClass("hidden");
    $(".story-card-container").addClass("hidden");

    //timeout for loading time 1 sec
    setTimeout(() => {
      //hide loading skeleton
      $(".story-card-skeleton-container").addClass("hidden");
      $(".story-card-container").removeClass("hidden");
    }, 1000);
  };

  const handleInputKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleAddTagButton();
    }
  };

  return (
    <div className="tag-filter-container">
      <div className="refine-by-tag-header">{trans("Refine by tag")}</div>
      <TagGrid
        tagGridTags={formattedSearchTags}
        onTagClicked={toggleRefineByTag}
        className={"tag-cloud-container"}
      />
      <div className="add-tag">
        <div className="tag-text-box">
          <input
            className="tag-text-input"
            type="text"
            value={addTagText}
            placeholder={trans("Add a tag to refine by")}
            onChange={handleChange}
            onKeyUp={handleInputKeypress}
          />
        </div>
        <button
          className={classNames("add-tag-btn", {
            "disabled-btn": disabled || emptyTag
          })}
          onClick={handleAddTagButton}
          aria-disabled={disabled || emptyTag}
          disabled={disabled || emptyTag}
          title={trans("Add tag")}
        >
          <Icon
            className="add-icon"
            name="add"
            size="24"
            color="ds-neutral-100"
          />
        </button>
        <p className="add-tag-error-message" aria-live="assertive">
          {disabled && trans("Tags cannot contain spaces")}
          {emptyTag && trans("Tags cannot be empty")}
        </p>
      </div>
      <div id="screen-reader-description" className="hidden">
        {trans("Press enter to toggle tag")}
      </div>
    </div>
  );
};

RefineByTagFilter.propTypes = {
  searchTags: PropTypes.array,
  searchTerm: PropTypes.string,
  handleFilterChange: PropTypes.func
};

export default RefineByTagFilter;
