// Action Types
const name = "CONVERSATION";

const POST_MESSAGE_REQUESTED = `${name}/POST_MESSAGE_REQUESTED`;
const POST_MESSAGE = `${name}/POST_MESSAGE`;
const LOAD_REQUESTED = `${name}/LOAD_REQUESTED`;
const LOAD_MORE_REQUESTED = `${name}/LOAD_MORE_REQUESTED`;
const LOAD = `${name}/LOAD`;
const RESET = `${name}/RESET`;
const DELETE_REQUESTED = `${name}/DELETE_REQUESTED`;
const DELETE = `${name}/DELETE`;
import { MUTE, UNMUTE, ERROR } from "../../profile/reducers";

// Reducer
const initialState = {
  isActive: false,
  isLoading: true,
  isLoadingMore: false,
  isDeleting: false,
  isPosting: false,
  messages: [],
  badges: [],
  scrollToMessageId: -1,
  total: 0,
  offset: 0,
  isEmailVerified: false,
  isMutingCurrentUser: false,
  isMutedByCurrentUser: false,
  isBlockedByCurrentUser: false,
  isFollowing: false
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_REQUESTED:
      return {
        ...state,
        badges: [],
        messages: [],
        offset: 0,
        total: 0,
        scrollToMessageId: -1,
        isActive: false,
        isLoading: true,
        isMutingCurrentUser: false,
        isMutedByCurrentUser: false,
        isBlockedByCurrentUser: false,
        isFollowing: false
      };
    case LOAD_MORE_REQUESTED:
      return {
        ...state,
        isLoadingMore: true
      };
    case LOAD: {
      const newMessages = [
        ...action.messages.slice().reverse(),
        ...state.messages
      ];

      // This is the id of the message we want to focus the scroll on
      // once the messages get loaded and previous scroll is lost.
      let scrollToMessageId;
      if (action.isLoadMore) {
        scrollToMessageId = state.messages[0].id;
      } else {
        scrollToMessageId =
          newMessages.length > 0 ? newMessages[newMessages.length - 1].id : -1;
      }
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        isActive: action.isActive,
        scrollToMessageId,
        messages: newMessages,
        badges: action.badges || [],
        offset: state.offset + action.messages.length,
        total: action.total,
        isEmailVerified: wattpad.utils.currentUser().get("verified_email"),
        isMutingCurrentUser: action.isMutingCurrentUser,
        isMutedByCurrentUser: action.isMutedByCurrentUser,
        isBlockedByCurrentUser: action.isBlockedByCurrentUser,
        isFollowing: action.isFollowing
      };
    }
    case DELETE:
    case RESET:
      return {
        ...initialState
      };
    case DELETE_REQUESTED:
      return {
        ...state,
        isDeleting: true
      };
    case POST_MESSAGE_REQUESTED:
      return {
        ...state,
        isPosting: true
      };
    case POST_MESSAGE:
      return {
        ...state,
        isPosting: false,
        messages: [...state.messages, action.message],
        scrollToMessageId: action.message ? action.message.id : -1,
        offset: state.offset + 1,
        total: state.total + 1
      };

    case MUTE:
      return {
        ...state,
        isMutedByCurrentUser: true
      };
    case UNMUTE:
      return {
        ...state,
        isMutedByCurrentUser: false
      };
    case ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
}

const getUsername = () => wattpad.utils.currentUser().get("username");

// Action Creators
export function loadConversation(threadName, offset = 0) {
  const conversationUrl = `/api/v3/users/${getUsername()}/inbox/${threadName}?offset=${offset}`;

  return function(dispatch) {
    const request = Promise.resolve($.get(conversationUrl));

    const isLoadMore = offset > 0;
    if (isLoadMore) {
      dispatch({ type: LOAD_MORE_REQUESTED });
    } else {
      dispatch({ type: LOAD_REQUESTED });
    }
    return request
      .then(data => {
        dispatch({
          type: LOAD,
          messages: data.messages,
          badges: data.badges,
          isActive: data.isActive,
          total: data.total,
          isLoadMore,
          //The following field is set to block from PLAT but it's referring to mute
          isMutingCurrentUser: data.isBlockingCurrentUser,
          isMutedByCurrentUser: data.safety.isMuted,
          isBlockedByCurrentUser: data.safety.isBlocked,
          isFollowing: data.isFollowing
        });
      })
      .catch(() => dispatch({ type: LOAD, messages: [], total: 0 }));
  };
}

export function postMessage(threadName, message) {
  const username = getUsername();
  const postMessageUrl = `/api/v3/users/${username}/inbox/${threadName}`;

  return function(dispatch) {
    const body = {
      sender: username,
      recipient: threadName,
      body: message.body
    };

    const request = Promise.resolve($.post(postMessageUrl, body));

    dispatch({ type: POST_MESSAGE_REQUESTED });
    return request
      .then(data => dispatch({ type: POST_MESSAGE, message: data }))
      .catch(() => {
        const toast = new window.app.views.ErrorToast(
          {
            message: wattpad.utils.trans(
              "Sorry, something went wrong. Please try again."
            )
          },
          { type: "dismissable" }
        );
        toast.render();
      });
  };
}

const getConvoMessageId = messages => {
  if (messages.length !== 0) {
    return messages[0].id;
  }

  return null;
};

const getThreadMessageId = (threads, threadName) => {
  const convo = threads.find(function(thread) {
    return thread.user.name === threadName;
  });

  if (convo == undefined || convo.recentMessage == undefined) {
    return null;
  }

  return convo.recentMessage.id;
};

const getMessageId = (getState, threadName) => {
  // first check if user is inside a Conversation
  if (getState().conversation.isActive) {
    return getConvoMessageId(getState().conversation.messages);
  }
  // otherwise user is in their general inbox with all the threads
  return getThreadMessageId(getState().threads.threads, threadName);
};

export function deleteConversation(threadName) {
  const username = getUsername();

  return function(dispatch, getState) {
    const messageId = getMessageId(getState, threadName);
    // identify purged user and remove the :purged: from it to hit the endpoint to delete PM conversation
    if (threadName.substring(0, 8) == ":purged:") {
      threadName = threadName.replace(":purged:", "");
    }
    // messageId will only be null if there are 0 messages between username and threadName
    const deleteConversationUrl =
      `/api/v3/users/${username}/inbox/${threadName}` +
      (messageId !== null ? `?message_id=${messageId}` : "");

    const request = Promise.resolve(
      $.ajax({
        url: deleteConversationUrl,
        type: "DELETE"
      })
    );

    dispatch({ type: DELETE_REQUESTED });
    return request.finally(() => dispatch({ type: DELETE }));
  };
}

export function resetConversation() {
  return function(dispatch) {
    dispatch({ type: RESET });
  };
}
