import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { toggleModal } from "../../../shared-components/modals/actions";
import classnames from "classnames";

import RadioButtons from "../../../shared-components/input/RadioButtons";

import CommonPaidPartFields from "./components/CommonPaidPartFields";

import { updatePaidPartConfig, postPaidPartConfig } from "./actions";

import {
  DEFAULT_DATA_VALUES,
  MODAL_CONTENT,
  PaidPartDataProps,
  IncludedFields,
  IncludedFieldsByBonusChapterType
} from "./constants";

const ConfigurePaidPartModal = ({
  isLoading,
  story,
  partId,
  authorId,
  paidPartData,
  updatePaidPartConfig,
  postPaidPartConfig
}) => {
  const dispatch = useDispatch();

  const COPY = MODAL_CONTENT();

  const [storedState, setStoredState] = useState(null);
  const [includedFields, setIncludedFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const hideModal = () => dispatch(toggleModal());

  const initBackButtonListener = () => {
    window.addEventListener("popstate", hideModal);
    return () => window.removeEventListener("popstate", hideModal);
  };

  useEffect(initBackButtonListener, []);

  useEffect(
    () => {
      if (!isLoading && !storedState)
        setStoredState(Object.assign({}, paidPartData));
      else
        setIncludedFields(
          IncludedFieldsByBonusChapterType[paidPartData.bonus_type]
        );
    },
    [isLoading, storedState, setStoredState, paidPartData, setIncludedFields]
  );

  const onFormChange = ({ name, value }) => {
    updatePaidPartConfig(story.id, partId, authorId, paidPartData, {
      name,
      value
    });
  };

  const handleTypeSelect = event => {
    const { name, value } = event.target;
    const selectedType = parseInt(value);
    setIncludedFields(IncludedFieldsByBonusChapterType[selectedType]);
    onFormChange({ name, value: selectedType });
  };

  const validateForm = () => {
    const errors = [];

    includedFields.forEach(field => {
      switch (field) {
        case IncludedFields.DESCRIPTION:
          if (!paidPartData.chapter_description)
            errors.push(COPY.errorMessages.chapterDescription);
          break;
        case IncludedFields.AUTHOR_NOTE:
          if (!paidPartData.author_note)
            errors.push(COPY.errorMessages.authorNote);
          break;
      }
    });

    if (!errors.length) return true;

    setErrorMessages(errors);

    return false;
  };

  const showSuccessToast = () => {
    const successIcon = wattpad.utils.iconify("fa-check", 16, "wp-neutral-5");

    wattpad.utils.showToast(
      `<span class="success-icon">${successIcon}</span> ${COPY.successMessage}`,
      {
        className: "configure-paid-part-modal-success-toast error-toast",
        dismissAfterDuration: 2000
      }
    );
  };

  const handleSave = () => {
    if (!validateForm()) return;

    setStoredState(Object.assign({}, paidPartData));
    updatePaidPartConfig(story.id, partId, authorId, paidPartData, {
      name: "is_free_part",
      value: false
    });
    postPaidPartConfig(story.id, partId, authorId, {
      ...paidPartData,
      is_free_part: false
    });
    hideModal();
    showSuccessToast();
  };

  const handleRemove = () => {
    if (!window.confirm(COPY.removeMessage)) return;
    if (!validateForm()) return;

    updatePaidPartConfig(story.id, partId, authorId, paidPartData, {
      name: "is_free_part",
      value: true
    });
    postPaidPartConfig(story.id, partId, authorId, {
      ...paidPartData,
      is_free_part: true
    });
    hideModal();
    showSuccessToast();
  };

  const handleDiscard = () => {
    updatePaidPartConfig(story.id, partId, authorId, storedState);
    hideModal();
  };

  return (
    <div id="configure-paid-part-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-body">
            <div className="section">
              <div className="section-title">{COPY.title}</div>
              <div className="section-description">{COPY.description}</div>
              <div className="part-type-selector">
                <RadioButtons
                  name={COPY.partTypeSelector.name}
                  options={COPY.partTypeSelector.options}
                  value={paidPartData.bonus_type}
                  onChange={handleTypeSelect}
                />
              </div>
            </div>

            <div className="form-container">
              <CommonPaidPartFields
                authorNote={paidPartData.author_note}
                chapterDescription={paidPartData.chapter_description}
                {...{ isLoading, includedFields, onFormChange }}
              />
            </div>

            <div className="error-container">
              {errorMessages.map((error, i) => (
                <div className="error" key={`error-${i}`}>
                  {error}
                </div>
              ))}
            </div>
          </div>

          <div className="modal-footer">
            <div className="action-buttons">
              <button
                className={classnames("btn", "btn-purple", { disabled: false })}
                data-dismiss="modal"
                onClick={handleSave}
              >
                {COPY.saveText}
              </button>

              {!paidPartData.is_free_part && (
                <button
                  className="btn btn-grey remove-button"
                  onClick={handleRemove}
                >
                  {COPY.removeText}
                </button>
              )}

              <button
                className="btn btn-grey discard-button"
                onClick={handleDiscard}
              >
                {COPY.cancelText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfigurePaidPartModal.defaultProps = { paidPartData: DEFAULT_DATA_VALUES };

ConfigurePaidPartModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  story: PropTypes.object.isRequired,
  partId: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  paidPartData: PaidPartDataProps,
  updatePaidPartConfig: PropTypes.func.isRequired,
  postPaidPartConfig: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    paidPartData: state.paidPartConfig.data,
    isLoading: state.paidPartConfig.isLoading
  };
};

export default connect(
  mapStateToProps,
  { updatePaidPartConfig, postPaidPartConfig }
)(ConfigurePaidPartModal);
