import React from "react";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";

import { useTrans } from "../../hooks";
import { Icon } from "../../shared-components";
import InfoButton from "./InfoButton";

const QURLabel = ({ story: { qualifiedUniqueReaders } }) => {
  const { ngettext, trans } = useTrans();

  return (
    <div className="qur-label">
      <Icon
        className="qur-label__book"
        name="bookOpenBeta"
        size="16"
        strokeWidth="3"
        color="ds-neutral-00-solid"
      />
      <span className="h5 qur-label__text">
        {/* prettier-ignore */ sprintf(ngettext("%s Engaged Reader", "%s Engaged Readers", qualifiedUniqueReaders), qualifiedUniqueReaders)}
      </span>
      <InfoButton
        color="wp-neutral-5"
        popoverContent={{
          title: trans("What is Engaged Readers?"),
          content: trans(
            "Unique readers with 5+ minutes of time spent reading your story in the last 365 days. Of your total story viewers during the same period, these readers were most interested in exploring your content."
          )
        }}
      />
    </div>
  );
};

QURLabel.propTypes = {
  story: PropTypes.shape({
    qualifiedUniqueReaders: PropTypes.number.isRequired
  }).isRequired,
  handleOpenPopover: PropTypes.func,
  handleClosePopover: PropTypes.func
};

export default QURLabel;
