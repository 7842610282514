import React, { useEffect, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 *  Countdown
 *   Takes in a UTC Date, optional timezone and update interval (seconds) and renders the remaining time formatted
 *
 */
const Countdown = ({
  className,
  date,
  timezone,
  showDays,
  showHours,
  showMinutes,
  showSeconds,
  updateInterval,
  onComplete
}) => {
  const getDateTimeMilliseconds = UTCDate => {
    const dateObj = new Date(UTCDate || Date.now());
    const localeDateString = dateObj.toLocaleString("en-US", {
      timeZone: timezone
    });
    const localeDate = new Date(localeDateString);
    return localeDate.getTime();
  };

  const getFormattedDate = useCallback(
    (duration = 0) => {
      duration = duration > 0 ? duration : 0;
      const secondLength = 1000;
      const minuteLength = secondLength * 60;
      const hourLength = minuteLength * 60;
      const dayLength = hourLength * 24;

      let formattedDate = "";
      if (showDays) formattedDate += ` ${Math.floor(duration / dayLength)}d`;
      if (showHours)
        formattedDate += ` ${Math.floor((duration % dayLength) / hourLength)}h`;
      if (showMinutes)
        formattedDate += ` ${Math.floor(
          (duration % hourLength) / minuteLength
        )}m`;
      if (showSeconds)
        formattedDate += ` ${Math.floor(
          (duration % minuteLength) / secondLength
        )}s`;
      return formattedDate.slice(1);
    },
    [showDays, showHours, showMinutes, showSeconds]
  );

  const currentDate = getDateTimeMilliseconds();
  const endDate = getDateTimeMilliseconds(date);

  const [remainingTime, setRemainingTime] = useState(endDate - currentDate);
  const [displayDate, setDisplayDate] = useState(getFormattedDate());

  const countdownComplete = useRef(false);
  const timer = useRef(null);

  const updateFormattedDate = useCallback(
    () => {
      const lastRender = remainingTime <= 0;
      setDisplayDate(getFormattedDate(lastRender ? 0 : remainingTime));

      timer.current = setTimeout(() => {
        if (!lastRender) {
          setRemainingTime(remainingTime - updateInterval * 1000);
        } else {
          if (!countdownComplete.current) {
            countdownComplete.current = true;
            onComplete && onComplete();
          }
        }
      }, updateInterval * 1000);
    },
    [
      updateInterval,
      onComplete,
      remainingTime,
      countdownComplete,
      getFormattedDate,
      setDisplayDate,
      setRemainingTime
    ]
  );

  useEffect(
    () => {
      updateFormattedDate();

      return () => {
        clearTimeout(timer.current);
      };
    },
    [updateFormattedDate]
  );

  return (
    <span className={classNames("countdown", className)}>{displayDate}</span>
  );
};

Countdown.defaultProps = {
  timezone: "America/New_York",
  showDays: false,
  showHours: false,
  showMinutes: false,
  showSeconds: false,
  updateInterval: 1,
  onComplete: () => {}
};

Countdown.propTypes = {
  className: PropTypes.string,
  date: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  showDays: PropTypes.bool,
  showHours: PropTypes.bool,
  showMinutes: PropTypes.bool,
  showSeconds: PropTypes.bool,
  updateInterval: PropTypes.number,
  onComplete: PropTypes.func
};

export default Countdown;
