import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function Container({ leftPart, rightPart, className }) {
  return (
    <div className={classnames("container content", className)}>
      {leftPart}
      {rightPart}
    </div>
  );
}
Container.propTypes = {
  leftPart: PropTypes.object.isRequired,
  rightPart: PropTypes.object.isRequired,
  className: PropTypes.string
};

export { Container };
