import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { toggleModal } from "../../shared-components/modals/actions";
import { useTrans } from "../../hooks";
import { WPModal } from "../../shared-components";
import ProfileHeader from "../profile/components/ProfileHeader";

const mapLanguagetoLearnMoreLinks = {
  1: "https://support.wattpad.com/hc/en-us/articles/360029365812", //English
  2: "https://support.wattpad.com/hc/fr/articles/360029365812", //French
  3: "https://support.wattpad.com/hc/it/articles/360029365812", // Italian
  4: "https://support.wattpad.com/hc/de/articles/360029365812", //German
  5: "https://support.wattpad.com/hc/es/articles/360029365812", //Spanish
  6: "https://support.wattpad.com/hc/pt/articles/360029365812", //Portuguese
  20: "https://support.wattpad.com/hc/id/articles/360029365812", //Indonesian
  23: "https://support.wattpad.com/hc/tr/articles/360029365812", //Turkish
  18: "https://support.wattpad.com/hc/fil/articles/360029365812", //Fillipino
};

const defaultLearnMoreLink = "https://support.wattpad.com/hc/articles/360029365812";

function UserError404({ toggleModal }) {
  useEffect(
    () => {
      toggleModal({
        className: "user404",
        disableClose: true,
        component: function UserModal() {
          return <User404Modal />;
        }
      });
      return () => {
        toggleModal();
      };
    },
    [toggleModal]
  );

  return (
    <div id="userError-404">
      <ProfileHeader />
      <WPModal />
    </div>
  );
}

function User404Modal() {
  const { trans } = useTrans();

  function redirectTo() {
    window.history.back();
  }
  const learnMoreLink = mapLanguagetoLearnMoreLinks[
    parseInt(app.get("language"), 10)
  ]
    ? mapLanguagetoLearnMoreLinks[parseInt(app.get("language"), 10)]
    : defaultLearnMoreLink;

  return (
    <div className="user-not-found">
      <h3>{trans("User not found")}</h3>
      <p>
        {trans(
          "This user no longer exists, or is only visible to a selected audience."
        )}
      </p>
      <div className="modal-btns">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-sm btn-block learn-more"
          href={learnMoreLink}
        >
          {trans("Learn more")}
        </a>

        <button className="btn btn-sm btn-block go-back" onClick={redirectTo}>
          <span> {trans("Go back")} </span>
        </button>
      </div>
    </div>
  );
}

UserError404.propTypes = {
  toggleModal: PropTypes.func.isRequired
};
const ConnectedUserError404 = connect(
  null,
  { toggleModal }
)(UserError404);

export default ConnectedUserError404;
