import { connect } from "react-redux";
import Carousel from "nuka-carousel";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { ConnectImage, Icon } from "../../shared-components";
import { isClient } from "../../helpers";
import { WO_ONBOARDING_SLIDES } from "./constants";
import { toggleModal } from "../../shared-components/modals/actions";
import { useTrans } from "../../hooks";

function setOnboardingViewed() {
  if (!isClient()) {
    throw new Error("Can only be run on client");
  }

  const onboardingCookie = "seen-wo-onboard";
  // NOTE: set to 0 for easier testing
  window.wattpad.utils.setCookie(onboardingCookie, 1);
}

export function OnboardingContent({
  onOnboardingFinish = () => {},
  toggleModal
}) {
  const { trans } = useTrans();
  const SLIDES = WO_ONBOARDING_SLIDES;

  const ref = useRef(null);
  function onImageLoad() {
    // Carousel can have weird sizing because of delay on image load,
    // so always resize after an image loads.
    ref.current.onResize();
  }

  const [seenLastSlide, setSeenLastSlide] = useState(false);

  const onFinish = () => {
    setOnboardingViewed();
    toggleModal();
    onOnboardingFinish();
  };

  let slides = SLIDES(trans);

  return (
    <div className={"onboarding-modal"}>
      {seenLastSlide && (
        <button className="btn-no-style" onClick={onFinish}>
          <Icon
            iconName="fa-remove"
            color="wp-neutral-1"
            height="22"
            className="close-btn"
          />
        </button>
      )}
      <Carousel
        dragging={true}
        slidesToShow={1}
        ref={ref}
        afterSlide={currentSlide => {
          const onLastSlide = currentSlide === slides.length - 1;

          if (onLastSlide) {
            setSeenLastSlide(true);
          }
        }}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={({
          nextSlide,
          goToSlide,
          currentSlide,
          previousSlide,
          slideCount
        }) => {
          const onLastSlide = currentSlide === slideCount - 1;
          const onFirstSlide = currentSlide === 0;

          const onClick = onLastSlide ? onFinish : nextSlide;

          const buttonCopy = onLastSlide ? trans("Got it") : trans("Next");

          return (
            <div className="carousel-controls">
              <PagingButtons
                slides={slides}
                goToSlide={goToSlide}
                currentSlide={currentSlide}
              />
              <button
                className={classnames(
                  "btn onboarding-nav nav-next btn-primary"
                )}
                onClick={onClick}
              >
                {buttonCopy}
              </button>
              <button
                className={classnames(
                  "btn btn-no-background onboarding-nav nav-back",
                  {
                    invisible: onFirstSlide
                  }
                )}
                onClick={previousSlide}
              >
                {trans("Back")}
              </button>
            </div>
          );
        }}
      >
        {slides.map(({ title, content, imageUrl, learnMoreLink }) => (
          <Slide
            key={title}
            onImageLoad={onImageLoad}
            title={title}
            content={content}
            imageUrl={imageUrl}
            learnMoreLink={learnMoreLink}
          />
        ))}
      </Carousel>
    </div>
  );
}

OnboardingContent.propTypes = {
  onOnboardingFinish: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
  premium: PropTypes.bool
};

function PagingButtons({ slides, goToSlide, currentSlide }) {
  return (
    <div className="paging-buttons">
      {slides.map((s, i) => {
        const active = i === currentSlide;
        const iconColor = active ? "ds-neutral-100" : "ds-neutral-60-solid";

        return (
          <button
            className="btn btn-no-background"
            key={i}
            onClick={() => goToSlide(i)}
          >
            <Icon iconName="fa-circle-filled" height="10" color={iconColor} />
          </button>
        );
      })}
    </div>
  );
}

PagingButtons.propTypes = {
  slides: PropTypes.array.isRequired,
  goToSlide: PropTypes.func.isRequired,
  currentSlide: PropTypes.number.isRequired
};

const Slide = ({ title, content, imageUrl, onImageLoad, learnMoreLink }) => {
  const { trans } = useTrans();
  return (
    <div className="slide">
      <div className="slide-pane slide-image">
        <ConnectImage width={"256"} onLoad={onImageLoad} name={imageUrl} />
      </div>
      <div className="slide-pane slide-copy">
        <h4 className={"title-lg"}>{title}</h4>
        <span className={classnames("slide-copy text-body")}>{content}</span>
        {learnMoreLink && (
          <React.Fragment>
            {". "}
            <a
              className="learn-more"
              href={learnMoreLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans("Learn more")}
            </a>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

Slide.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  learnMoreLink: PropTypes.string,
  onImageLoad: PropTypes.func
};

const mapDispatchToProps = { toggleModal };

const PaidOnboardingModal = connect(
  null,
  mapDispatchToProps
)(OnboardingContent);

// The modal is triggered by Backbone code that can't use JSX or
// Redux, so export a wrapper that will display the modal
export const showPaidOnboardingModal = props => {
  window.store.dispatch(
    toggleModal({
      component: function PaidOnboardingModalWrapper() {
        return <PaidOnboardingModal {...props} />;
      },
      disableClose: true,
      useGlobalModalContainer: true
    })
  );
};
