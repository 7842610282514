import React, { useEffect, useState, useRef } from "react";
import { useTrans } from "../../hooks";
import PropTypes from "prop-types";
import {
  withValidation,
  FormField,
  NewCheckbox
} from "../../shared-components";
import { Button } from "@wattpad/web-ui-library";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../components";
import BirthdayFields from "../authentication/BirthdayFields";

const ValidatedFormField = withValidation(FormField);

const OnboardingDetails = ({ errorMsg, model, preservedVal }) => {
  const { trans } = useTrans();
  const { email, isEmailAvailable, isBirthdayAvailable } = preservedVal;
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isBirthdayValid, setIsBirthdayValid] = useState(false);
  const [isPolicyConsentValid, setIsPolicyConsentValid] = useState(false);
  const [isMarketingConsentValid, setIsMarketingConsentValid] = useState(false);
  const [policy, setIsPolicy] = useState("");
  const [formTypeValidation, setFormTypeValidation] = useState([""]);
  const [isInvalidated, setIsInvalidated] = useState(false);

  const emailRef = useRef();
  const birthdayInput = useRef();
  const pronounInput = useRef();

  const policy_agreement_new = trans("Yes, I have read and agree to Wattpad's <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy</a>."); // prettier-ignore
  const marketing_agreement_email = trans(
    "Yes, I'd like to receive marketing emails from Wattpad. (optional)"
  );
  const marketing_agreement_email_subtext = trans(
    "Get story updates, announcements, offers, and more via email. Unsubscribe anytime."
  );

  const onSubmitErrorMessage =
    errorMsg === trans("Please fix any errors to continue.");

  const validateSignupForm = (isValid, key) => {
    if (key.toLowerCase().includes("email")) {
      setIsEmailValid(isValid);
    } else if (key.toLowerCase().includes("birthday")) {
      setIsBirthdayValid(isValid);
    }
  };

  const initializeFormValues = () => {
    if (email !== "" && isEmailAvailable) {
      setIsEmailValid(true);
    }
  };

  useEffect(initializeFormValues, [email]);

  useEffect(() => {
    if (isEmailAvailable && isBirthdayAvailable) {
      setFormTypeValidation(["isEmailAvailable", "isBirthdayAvailable"]);
    } else if (isEmailAvailable) {
      setFormTypeValidation(["isEmailAvailable"]);
    } else if (isBirthdayAvailable) {
      setFormTypeValidation(["isBirthdayAvailable"]);
    } else {
      setFormTypeValidation(["none"]);
    }
  }, []);

  const onBlurHandler = e => {
    const valid = e.target.checkValidity();
    setIsPolicy(valid);
  };

  const onChangeHandler = key => {
    if (key === "marketing") {
      setIsMarketingConsentValid(!isMarketingConsentValid);
    }
    if (key === "policy") {
      setIsPolicyConsentValid(!isPolicyConsentValid);
      setIsPolicy(!isPolicyConsentValid);
    }
  };

  const renderLabelWithHint = (label, ariaLabel, tooltip) => (
    <div className="field-label">
      <span className="field-label-text">{label}</span>
      <div aria-label={ariaLabel} data-tip={tooltip}>
        <Icon
          height="16"
          iconName="fa-info"
          color="wp-neutral-1"
          className="tooltip-icon"
        />
      </div>
    </div>
  );

  const onSignupButtonClick = () => {
    if (!isBirthdayAvailable) {
      birthdayInput.current.focus();
      birthdayInput.current.blur();
    }

    if (!isEmailAvailable || email === "") {
      emailRef.current.focus();
      emailRef.current.blur();
    }

    setIsPolicy(isPolicyConsentValid);
    if (
      !isPolicyConsentValid ||
      (!isBirthdayAvailable && !isBirthdayValid) ||
      ((!isEmailAvailable || email === "") && !isEmailValid)
    ) {
      setIsInvalidated(true);
    } else {
      setIsInvalidated(false);
    }
  };

  return (
    <div className="container-new">
      <div className="form-wrapper">
        <div className="form-new">
          <form id="signupForm" method="POST">
            <ReactTooltip className="tool-tip" effect="solid" />
            {isEmailAvailable &&
              email !== "" && (
                <input className="hidden" readOnly value={email} name="email" />
              )}
            {(!isEmailAvailable || email === "") && (
              <ValidatedFormField
                name="email"
                label={trans("Email")}
                form="signup"
                inputType="text"
                title={trans("Enter email")}
                inputRef={emailRef}
                validationModel={model}
                showLabel={true}
                onValid={key => validateSignupForm(true, key)}
                onInvalid={key => validateSignupForm(false, key)}
              />
            )}

            {!isBirthdayAvailable && (
              <>
                {renderLabelWithHint(
                  trans("Birthday"),
                  trans("why we ask for your birthday"),
                  trans(
                    "You need to enter the date you were born. This information will only be visible to you and Wattpad’s Support teams."
                  )
                )}
                <BirthdayFields
                  inputRef={birthdayInput}
                  validationModel={model}
                  onValid={() => validateSignupForm(true, "birthday")}
                  onInvalid={() => validateSignupForm(false, "birthday")}
                  errorMessageOnSubmit={
                    onSubmitErrorMessage ? trans("Birthday is required.") : ""
                  }
                />
              </>
            )}

            {renderLabelWithHint(
              trans("Pronouns (optional)"),
              trans("Why we ask for your pronouns"),
              trans(
                "Your pronouns are only visible to you and Wattpad's support team. Update them anytime in your profile."
              )
            )}
            <FormField
              inputType="pronouns"
              id="pronouns"
              name="pronouns"
              defaultValue=""
              aria-label="Select Pronoun"
              ref={pronounInput}
            />
            <div className="marketing-policy-agreement-container">
              <NewCheckbox
                contentId="marketing-agreement"
                checked={isMarketingConsentValid}
                onChange={() => onChangeHandler("marketing")}
                color="ds-base-2-60"
                name="marketing"
                value={isMarketingConsentValid}
              />
              <div>
                <span className="marketing-agreement">
                  {marketing_agreement_email}
                </span>
                <span className={"marketing-agreement-subtext"}>
                  {marketing_agreement_email_subtext}
                </span>
              </div>
            </div>
            <div className="email-policy-agreement-container">
              <NewCheckbox
                contentId="policy-consent"
                required
                checked={isPolicyConsentValid}
                onChange={() => onChangeHandler("policy")}
                onBlur={onBlurHandler}
                color="ds-base-2-60"
                classname={
                  policy === false || onSubmitErrorMessage ? "invalid" : ""
                }
                name="policy"
                value={isPolicyConsentValid}
              />
              <div>
                <span
                  className="policy-agreement"
                  dangerouslySetInnerHTML={{
                    __html: policy_agreement_new
                  }}
                />
                {(policy === false || onSubmitErrorMessage) && (
                  <span
                    aria-live="assertive"
                    className="error-msg-new"
                    role="alert"
                  >
                    {trans("Checking this box is required.")}
                  </span>
                )}
              </div>
            </div>
            <input className="hidden" readOnly value="1" name="newOnboarding" />
            <input
              className="hidden"
              readOnly
              value={formTypeValidation}
              name="formTypeValidation"
            />
            {errorMsg &&
              !onSubmitErrorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              )}
            <Button
              fullWidth={true}
              label={trans("Sign up")}
              ariaLabel={trans("Sign up")}
              className="signup-button"
              onClick={onSignupButtonClick}
            />
            {errorMsg &&
              onSubmitErrorMessage && (
                <span className="error-msg-new text-align-center" role="alert">
                  {errorMsg}
                </span>
              )}
            {isInvalidated && (
              <span className="error-msg-new text-align-center" role="alert">
                {trans("Please fix any errors to continue.")}
              </span>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

OnboardingDetails.propTypes = {
  errorMsg: PropTypes.string,
  model: PropTypes.object,
  preservedVal: PropTypes.shape({
    email: PropTypes.string,
    isEmailAvailable: PropTypes.boolean,
    isBirthdayAvailable: PropTypes.boolean
  })
};

export default OnboardingDetails;
