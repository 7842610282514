import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../hooks";
import { FormValidation } from "../FormValidation";
import { requiredField } from "../helpers";
import ContestFormField from "../ContestFormField";
import { MAP_STORY_TYPES } from "../constants";
import ErrorField from "./ErrorField";

const StoryTypeField = ({
  form,
  disableForm,
  onFormChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = trans("Story type");

  const subtitle = (
    <div className="subtitle">
      {trans(
        "Please indicate if your story is Adult Fiction or Teen Fiction. Adult Fiction is a story geared toward an adult audience, Teen Fiction is a story geared toward a teen audience."
      )}
    </div>
  );

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "storyType" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.storyType === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation validationRules={[requiredField(trans)]}>
        {({ ref, errorMessage, className }) => (
          <>
            <select
              id="story-type-select"
              ref={ref}
              value={form.storyType}
              name="storyType"
              disabled={disableForm}
              className={classNames("form-element", className)}
              onChange={onFormChange}
              aria-label={trans("Select an option")}
            >
              <option value="" disabled>
                {trans("Select an option")}
              </option>
              {MAP_STORY_TYPES(trans).map(length => (
                <option key={length.key} value={length.key}>
                  {length.value}
                </option>
              ))}
            </select>
            <ErrorField errorMessage={errorMessage} fieldId={"story-type"} />
            {!errorMessage && (
              <ErrorField
                fieldId={"story-type"}
                errorCondition={errorCondition}
              />
            )}
          </>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

StoryTypeField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default StoryTypeField;
