import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getCoverSrc, simpleShorten } from "../../../helpers";
import { Icon } from "../../../shared-components";
import { toggleModal } from "./../../../shared-components/modals/actions";
import {
  getReadingLists,
  updateCollection,
  removeStoriesFromCollection,
  getCollectionsForStoryIds
} from "../actions";
import StoryIconBar from "../../../shared-components/StoryIconBar";
import LibraryPopover from "./LibraryPopover";
import { focusableElementsString } from "../../../hooks/useTrapKeyboardNavigation";

import { useTrans, usePopover } from "../../../hooks";

export const StoryPreviewUI = ({
  story,
  readingLists,
  getReadingLists,
  readingListsLoading,
  updateCollection,
  removeStoriesFromCollection,
  getCollectionsForStoryIds,
  storiesCollections,
  toggleModal
}) => {
  const [isLibraryPopoverOpen, setIsLibraryPopoverOpen] = useState(false);
  const [firstTabFocus, setFirstTabFocus] = useState(null);
  const [lastTabFocus, setLastTabFocus] = useState(null);
  const [focusableElements, setFocusableElements] = useState([]);

  const { handleOpenPopover, handleClosePopover } = usePopover();
  const { trans } = useTrans();

  const libraryButtonRef = useRef();
  const modalRef = useRef();
  const beforeModalRef = useRef();

  const defaultCover = getCoverSrc(story.cover, 268);

  const handleKeyPress = useCallback(
    e => {
      // Check for TAB key press
      if (e.key === "Tab" && firstTabFocus && lastTabFocus) {
        // SHIFT + TAB: previous element
        if (e.shiftKey) {
          if (document.activeElement === firstTabFocus) {
            e.preventDefault();
            lastTabFocus.focus();
          }
          // TAB: next element
        } else {
          if (document.activeElement === lastTabFocus) {
            e.preventDefault();
            firstTabFocus.focus();
          }
        }
      }
      // handling ESC key press
      if (e.key === "Escape") {
        // close the library popover if it's open
        if (isLibraryPopoverOpen) {
          handleClosePopover(libraryButtonRef);
          setIsLibraryPopoverOpen(false);
        } else {
          beforeModalRef && beforeModalRef.current.focus();
          toggleModal();
        }
      }
    },
    [
      handleClosePopover,
      firstTabFocus,
      lastTabFocus,
      isLibraryPopoverOpen,
      toggleModal
    ]
  );

  useEffect(
    () => {
      beforeModalRef.current = document.activeElement;
      // Second Chance™
      // Make a second attempt at getting the reading lists
      // if the first attempt from Home failed.
      if (!readingLists && !readingListsLoading) {
        getReadingLists();
      }

      getCollectionsForStoryIds(story.id);
    },
    [
      getCollectionsForStoryIds,
      getReadingLists,
      readingLists,
      readingListsLoading,
      story.id
    ]
  );

  useEffect(
    () => {
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    },
    [handleKeyPress]
  );

  useEffect(
    () => {
      let prevFocusableElements = focusableElements ? focusableElements : [];
      let currFocusableElements = modalRef.current
        ? modalRef.current.querySelectorAll(focusableElementsString)
        : null;

      currFocusableElements = currFocusableElements
        ? Array.prototype.slice.call(currFocusableElements)
        : null; // Feel like can be refactored

      const isSame = (prevFocusableElements.length =
        currFocusableElements.length &&
        currFocusableElements.every(
          (node, index) => node === prevFocusableElements[index]
        ));
      // only update state if list of FocusableElements has changed
      if (!isSame && currFocusableElements.length !== 0) {
        setFirstTabFocus(currFocusableElements[0]);
        setLastTabFocus(
          currFocusableElements[currFocusableElements.length - 1]
        );
        setFocusableElements(currFocusableElements);
        currFocusableElements[0].focus();
      }
    },
    [focusableElements]
  );

  const onRead = () => {
    const storyReadLink = story.firstPartId
      ? `/${story.firstPartId}`
      : `/story/${story.id}`;
    app.router.navigate(storyReadLink, { trigger: true });
    toggleModal();
  };

  const handleCloseClick = () => {
    toggleModal();
    beforeModalRef && beforeModalRef.current.focus();
  };

  const onMoreDetails = () => {
    const storyDetailsLink = `/story/${story.id}`;
    app.router.navigate(storyDetailsLink, { trigger: true });
    toggleModal();
  };

  const handleLibraryClick = e => {
    e.preventDefault();
    setIsLibraryPopoverOpen(true);

    const component = () => {
      return (
        <LibraryPopover
          readingLists={readingLists}
          storyId={story.id}
          updateCollection={updateCollection}
          removeStoriesFromCollection={removeStoriesFromCollection}
          storiesCollections={storiesCollections[story.id]}
          getReadingLists={getReadingLists}
          getCollectionsForStoryIds={getCollectionsForStoryIds}
        />
      );
    };

    handleOpenPopover(
      component,
      libraryButtonRef,
      "react-popover-content--above-modal",
      {
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: modalRef.current
            }
          }
        ],
        showArrow: "true"
      }
    );
  };

  return (
    <div className="story-preview-wrapper" ref={modalRef}>
      <div className="cover">
        <img
          srcSet={`${getCoverSrc(
            story.cover,
            288 //CAN THESE HARDCODED VALUES BE REMOVED?????
          )} 1x, ${getCoverSrc(story.cover, 416)} 1.5x, ${getCoverSrc(
            story.cover,
            512
          )} 2x`}
          src={defaultCover}
          alt={`${story.title} cover`}
        />
      </div>
      <button className="close" onClick={handleCloseClick}>
        <Icon name="close" size="24" />
      </button>
      <div className="story-info">
        <div className="meta-header">
          <div className="title-wrapper">
            <div className="title">{story.title}</div>
          </div>
          <StoryIconBar
            numParts={story.numParts}
            isMature={story.mature}
            isCompleted={story.completed}
          />
        </div>
        <div className="buttons-wrapper">
          <button className="read btn-primary" onClick={onRead}>
            <Icon
              className="open-book"
              name="bookOpenBeta"
              size="16"
              color="ds-neutral-00-solid"
            />
            <span className="read-btn-text">
              {story.isPaid ? trans("Read now") : trans("Start reading")}
            </span>
          </button>
          {readingLists && (
            <button
              className="btn-primary add-to-library-btn"
              title={trans("Add story to...")}
              aria-label={trans("Add story to...")}
              ref={libraryButtonRef}
              onClick={handleLibraryClick}
            >
              <Icon
                name="add"
                size="24"
                color="wp-neutral-1"
                strokeWidth="2.5"
              />
            </button>
          )}
        </div>
        <div className="description">
          {simpleShorten(story.description, 400, false)}
        </div>
        <div className="more-details-wrapper">
          <button className="more-details" onClick={onMoreDetails}>
            {trans("More details")}
            <Icon name="chevRight" size="24" />
          </button>
        </div>
      </div>
    </div>
  );
};

StoryPreviewUI.propTypes = {
  story: PropTypes.object,
  readingLists: PropTypes.array,
  getReadingLists: PropTypes.func,
  readingListsLoading: PropTypes.bool,
  updateCollection: PropTypes.func,
  removeStoriesFromCollection: PropTypes.func,
  getCollectionsForStoryIds: PropTypes.func,
  storiesCollections: PropTypes.object,
  toggleModal: PropTypes.func,
  openPopover: PropTypes.func,
  closePopover: PropTypes.func
};

const mapDispatchToProps = {
  getReadingLists,
  updateCollection,
  removeStoriesFromCollection,
  getCollectionsForStoryIds,
  toggleModal
};

const mapStateToProps = state => {
  const {
    readingLists,
    readingListsLoading,
    removeStoriesFromCollection,
    storiesCollections
  } = state.homeSections;
  return {
    readingLists,
    readingListsLoading,
    removeStoriesFromCollection,
    storiesCollections
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryPreviewUI);
