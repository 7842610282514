import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks/useTrans";
import classNames from "classnames";
import { connect } from "react-redux";
import { toggleToast } from "./reducers";

const ToastUI = ({ toggleToast, toastProps }) => {
  toastProps = toastProps || {};
  const { trans } = useTrans();
  const { className, body } = toastProps;
  if (body) {
    const wrapperClass = classNames("toast", className);
    return (
      <div className={wrapperClass}>
        <div className="toast-content">
          <p>{body}</p>
          <button className="btn-no-background" onClick={toggleToast}>
            {trans("OK")}
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

ToastUI.propTypes = {
  toastProps: PropTypes.shape({
    body: PropTypes.string,
    className: PropTypes.string
  }),
  toggleToast: PropTypes.func
};
const Toast = connect(
  state => ({ toastProps: state.toast.toastProps }),
  { toggleToast }
)(ToastUI);

export { Toast, ToastUI };
