import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTrans } from "../hooks";
import useReducerWithDefault from "../hooks/useReducerWithDefault";
import NewCheckBox from "./NewCheckbox";

const optionsList = ["anytime", "day", "week", "month", "year"];
const defaultOption = "anytime";

const LastUpdatedFilter = ({ selectedOptions, handleFilterChange }) => {
  const { trans } = useTrans();
  const [state, handleUpdate] = useReducerWithDefault(
    optionsList,
    defaultOption,
    selectedOptions
  );

  const firstUpdate = useRef(true);

  useEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      } else {
        handleFilterChange(state, "age");
      }
    },
    [state, handleFilterChange]
  );

  const handleChange = event => {
    handleUpdate(
      event.currentTarget.checked ? "APPLY" : "REMOVE",
      event.currentTarget.value
    );
  };

  return (
    <div className="filter">
      <span className="sr-only">
        {trans("Last updated") + " " + trans("You can select multiple options") /* prettier-ignore */}
      </span>
      <div className="filter__title" aria-hidden>
        {trans("Last updated")}
      </div>
      <div className="filter__description" aria-hidden>
        {trans("You can select multiple options")}
      </div>

      <div className="filter__options">
        <NewCheckBox
          value={"anytime"}
          checked={state.anytime}
          onChange={handleChange}
          contentId="lastUpdated1"
        >
          {trans("Anytime")}
        </NewCheckBox>
        <NewCheckBox
          value={"day"}
          checked={state.day}
          onChange={handleChange}
          contentId="lastUpdated2"
        >
          {trans("Today")}
        </NewCheckBox>
        <NewCheckBox
          value={"week"}
          checked={state.week}
          onChange={handleChange}
          contentId="lastUpdated3"
        >
          {trans("This week")}
        </NewCheckBox>

        <NewCheckBox
          value={"month"}
          checked={state.month}
          onChange={handleChange}
          contentId="lastUpdated4"
        >
          {trans("This month")}
        </NewCheckBox>

        <NewCheckBox
          value={"year"}
          checked={state.year}
          onChange={handleChange}
          contentId="lastUpdated5"
        >
          {trans("This year")}
        </NewCheckBox>
      </div>
    </div>
  );
};

LastUpdatedFilter.defaultProps = {
  handleFilterChange: () => {},
  selectedOptions: {}
};

LastUpdatedFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  selectedOptions: PropTypes.object
};

export default LastUpdatedFilter;
