import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  getAd,
  createUniqueAdId,
  includeAdMarkup
} from "@wattpad/client-platform-web-ads";
import sendAdReconciliationEvent from "../../helpers/ads/send-ad-reconciliation-event";

const AdContainer = ({
  placement,
  adData,
  className,
  wrapperClassName,
  refresh
}) => {
  const [uniquePlacement] = useState(createUniqueAdId(placement));
  const renderAd = includeAdMarkup(
    placement,
    adData.testGroups,
    adData.context
  );

  const athaSkipReasons = [
    "images_under_moderation",
    "skipped_brandsafe",
    "skipped_default"
  ];

  let refreshAdFunction;

  const initializeAd = () => {
    if (adData.testGroups.AD_ELIGIBILITY && adData.adEligibility) {
      sendAdReconciliationEvent({
        adData,
        skipReason: renderAd.reason
      });
    }

    if (renderAd.result) {
      // GetAd returns a function if its a refresh ad
      refreshAdFunction = getAd(
        {
          ...adData,
          placement: uniquePlacement
        },
        refresh
      );
    } else {
      if (
        typeof __atha !== "undefined" &&
        (renderAd.reason.includes("skipped_special_placement_") ||
          athaSkipReasons.includes(renderAd.reason))
      ) {
        // this would ideally live in the ads package but due to some rendering issues includeAdMarkup gets called
        // multiple times.
        // eslint-disable-next-line no-undef
        __atha.sendSkip(
          placement,
          renderAd.reason,
          adData?.context?.story,
          adData?.context?.storyPart
        );
      }
    }
  };

  const adComponent = () => {
    return <div className={className} id={uniquePlacement} />;
  };

  const ConditionalAdWrapper = () => {
    return wrapperClassName ? (
      <div className={wrapperClassName}>{adComponent()}</div>
    ) : (
      adComponent()
    );
  };

  useEffect(initializeAd, [renderAd.result]);

  useEffect(() => {
    if (
      refresh &&
      typeof refreshAdFunction === "function" &&
      window &&
      window.addEventListener
    ) {
      const refreshEvent = `refresh-${placement}`;
      window.addEventListener(refreshEvent, refreshAdFunction);
      return () => {
        window.removeEventListener(refreshEvent, refreshAdFunction);
      };
    }
  });

  return renderAd.result ? ConditionalAdWrapper() : null;
};

const StoryGroupProps = {
  isAdExempt: PropTypes.bool.isRequired,
  isBrandSafe: PropTypes.bool.isRequired,
  isPaywalled: PropTypes.bool.isRequired,
  rating: PropTypes.number.isRequired,
  imagesUnderModeration: PropTypes.bool,
  language: PropTypes.number
};

const StoryPartProps = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  brandSafetyLevel: PropTypes.number.isRequired,
  brandSafetySource: PropTypes.string.isRequired,
  rank: PropTypes.number, // Doesn't always exist
  rating: PropTypes.number.isRequired,
  totalViewCount: PropTypes.number.isRequired,
  imagesUnderModeration: PropTypes.bool
};

const AdContextProps = {
  user: PropTypes.shape({
    isPremium: PropTypes.bool.required
  }),
  story: PropTypes.shape(StoryGroupProps),
  storyPart: PropTypes.shape(StoryPartProps)
};

AdContainer.propTypes = {
  placement: PropTypes.string.isRequired,
  adData: PropTypes.shape({
    userGeo: PropTypes.string.isRequired,
    testGroups: PropTypes.objectOf(PropTypes.bool).isRequired,
    deviceType: PropTypes.string.isRequired,
    context: PropTypes.shape(AdContextProps)
  }).isRequired,
  className: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  refresh: PropTypes.bool
};

export default AdContainer;
