import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useClickOutside } from "../../hooks";

const Dropdown = props => {
  const [visible, setVisible] = useState(false);
  const [ref, setRef] = useState(null);
  useClickOutside(ref, () => setVisible(false));

  function toggleVisibility(e) {
    e.preventDefault();
    setVisible(!visible);
  }

  return (
    <div
      id={props.id}
      ref={ref => setRef(ref)}
      className={classNames("dropdown", { visible })}
    >
      {/* eslint-disable-next-line wp-lint-rules/valid-href */}
      <a href="#" onClick={toggleVisibility}>
        {props.label}
        <span className="caret" />
      </a>
      {visible ? props.children : null}
    </div>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.object
};

export default Dropdown;
