import { combineReducers } from "redux";

import modal from "../shared-components/modals/reducer";
import popover from "../shared-components/popover/reducers";

import { wallet, purchase } from "../views/paid-content/reducers";
import paidMetadata from "../views/paid-content/paidMetadata";
import contestForm from "../views/contests/ContestForm/reducers";

import paidStoriesCatalog from "../views/catalog/reducers/paid-stories-catalog-reducer";
import premiumPicksCatalog from "../views/catalog/reducers/premium-picks-catalog-reducer";

import storyItem from "../views/story-item/story-item-reducer";

import threads from "../views/inbox/Threads/reducers";
import conversation from "../views/inbox/Conversation/reducers";

import storyTags from "../views/story-details/StoryTags/reducers";

import { homeSections } from "../views/home/reducers";

import profileData from "../views/profile/reducers";
import toast from "../shared-components/toasts/reducers";

import quests from "../views/quests/reducers";

import storyNotes from "../views/works-item-details/story-notes/reducers";

import worksItemDetails from "../views/works-item-details/reducers";

import paidPartConfig from "../views/writer/configure-paid-part-modal/reducers";

const rootReducer = combineReducers({
  userData,
  appData,
  deviceData,
  wallet,
  purchase,
  threads,
  conversation,
  paidMetadata,
  modal,
  popover,
  contestForm,
  storyTags,
  homeSections,
  profileData,
  toast,
  paidStoriesCatalog,
  premiumPicksCatalog,
  storyItem,
  quests,
  storyNotes,
  worksItemDetails,
  paidPartConfig
});

function userData(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function appData(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function deviceData(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default rootReducer;
