import React, { useState, useCallback } from "react";
import StoryCard from "../../shared-components/StoryCard";
import StoryCardContainerSkeleton from "./StoryCardContainerSkeleton";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import { sprintf } from "sprintf-js";
import { count } from "../../helpers";

export default function StoryCardContainer({
  stories: initialStories = [],
  searchTerm,
  nextUrl: initialNextUrl,
  isMobile,
  numStories,
  viewedStories = []
}) {
  // Anywhere from 0-3 paid stories are injected into the response, this results in an inaccurate count of story results.
  // The number of results is set to the number of stories queried even though the number of stories returned might be different.
  const mobileFetchCount = 3;
  const desktopFetchCount = 15;
  const fetchedStoriesCount = isMobile ? mobileFetchCount : desktopFetchCount;
  const [allStories, setAllStories] = useState(initialStories);
  const initalStoriesCount =
    numStories < fetchedStoriesCount ? allStories.length : fetchedStoriesCount;
  const [storiesCurrentCount, setStoriesCurrentCount] = useState(
    initalStoriesCount
  );

  const numSkeletonCards = 15;

  const [nextUrl, setNextUrl] = useState(initialNextUrl);
  const [a11yIndex, seta11yIndex] = useState(-1);
  // accurate count of stories for screen reader
  const [
    accurateFetchedStoriesCount,
    setAccurateFetchedStoriesCount
  ] = useState(initialStories.length);
  const { trans, ngettext } = useTrans();

  const onRefChange = useCallback(node => {
    node && node.focus();
  }, []);

  const hasMore = !!nextUrl;

  const loadMoreStories = () => {
    let url = nextUrl;
    Promise.resolve($.get(url))
      .then(response => {
        // ensuring that only unique stories are added from response and
        // parse language to match parsing done on the server
        const newStories = response.stories
          .filter(story => !allStories.some(ele => ele.id === story.id))
          .map(story => {
            return { ...story, language: story.language.id };
          });

        setAccurateFetchedStoriesCount(newStories.length);
        setAllStories(allStories => [...allStories, ...newStories]);
        const updatedTotal =
          storiesCurrentCount + fetchedStoriesCount > numStories
            ? numStories
            : storiesCurrentCount + fetchedStoriesCount;
        setStoriesCurrentCount(updatedTotal);
        setNextUrl(response.nextUrl);
        seta11yIndex(allStories.length);
        app?.trigger?.("app:component:search:refreshSearchAd");
      })
      .catch(() => {
        wattpad.utils.showToast(
          trans("Something went wrong. Please try again"),
          {
            type: "dismissable"
          }
        );
      });
  };

  const pushViewEvent = (id, index, sources) => {
    if (viewedStories.includes(id)) {
      return;
    }

    window.te.push("event", "story", null, null, "view", {
      storyid: id,
      page: "search",
      position: index,
      query: [searchTerm],
      algo_source: sources
    });

    app?.trigger?.("app:component:stories:view", id);
  };

  return (
    <div className="feed-item-new panel new-container">
      <div className="story-card-container">
        <ul className="list-group new-list-group">
          {allStories.map((story, index) => (
            <React.Fragment key={story.id}>
              {index === a11yIndex && (
                <li tabIndex={-1} ref={onRefChange} className="more-stories">
                  <span className="sr-only">
                    {/* prettier-ignore */ sprintf(ngettext("%s more story", "%s more stories", accurateFetchedStoriesCount), accurateFetchedStoriesCount)}
                  </span>
                </li>
              )}
              <li className="list-group-item">
                <StoryCard
                  {...story}
                  searchTerm={searchTerm}
                  index={index}
                  viewedStories={viewedStories}
                  pushViewEvent={pushViewEvent}
                />
              </li>
            </React.Fragment>
          ))}
        </ul>
        {hasMore && (
          <button className="btn-load-more load-more" onClick={loadMoreStories}>
            {trans("Load more stories")}
          </button>
        )}
        <div
          className="results"
          dangerouslySetInnerHTML={{
            __html: /* prettier-ignore */ trans("You are seeing %s of %s results", `<strong>${storiesCurrentCount}</strong>`, `<strong>${count(Math.max(numStories, storiesCurrentCount))}</strong>`)
          }}
        />
      </div>
      {!isMobile && (
        <StoryCardContainerSkeleton numSkeletonCards={numSkeletonCards} />
      )}
    </div>
  );
}

StoryCardContainer.propTypes = {
  stories: PropTypes.array,
  searchTerm: PropTypes.string,
  nextUrl: PropTypes.string,
  showMore: PropTypes.bool,
  isMobile: PropTypes.bool,
  numStories: PropTypes.number,
  viewedStories: PropTypes.array
};
