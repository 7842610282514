import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useTrans } from "../../../../../hooks";
import { FormValidation } from "../../FormValidation";
import { numberWithinBounds, requiredField } from "../../helpers";
import ContestFormField from "../../ContestFormField";
import { MAX_STORIES, MIN_STORIES } from "../../constants";
import ErrorField from "../ErrorField";

const TotalPlannedStoriesInSeriesField = ({
  form,
  disableForm,
  onSeriesIdentificationChange,
  fieldStatus,
  submitted
}) => {
  const { trans } = useTrans();

  const title = "";

  const subtitle = (
    <div className="subtitle">
      {/* prettier-ignore */ trans("How many stories are you planning for the series to have in total?")}
    </div>
  );

  const customErrorMessage =
    form.numberOfStoriesInSeries > form.totalPlannedStoriesInSeries
      ? /* prettier-ignore */ trans("The total number of stories should be equal or greater than the current number of stories.")
      : "";

  const errorCondition =
    (fieldStatus.fieldInvalid ||
      (fieldStatus.fieldName !== "totalPlannedStoriesInSeries" &&
        fieldStatus.fieldName !== "" &&
        submitted)) &&
    form.totalPlannedStoriesInSeries === "";

  return (
    <ContestFormField title={title} subtitle={subtitle}>
      <FormValidation
        validationRules={[
          requiredField(trans),
          numberWithinBounds(MIN_STORIES, MAX_STORIES, trans)
        ]}
      >
        {({ ref, errorMessage, className }) => (
          <>
            <input
              ref={ref}
              id="total-planned-stories-input"
              className={classNames("form-element", className)}
              name="totalPlannedStoriesInSeries"
              value={form.totalPlannedStoriesInSeries}
              disabled={disableForm}
              onChange={onSeriesIdentificationChange}
              placeholder={trans("Enter your number of stories")}
              type="number"
            />
            <ErrorField
              fieldId={"total-planned-stories-input"}
              errorCondition={errorCondition}
              errorMessage={errorMessage || customErrorMessage}
            />
          </>
        )}
      </FormValidation>
    </ContestFormField>
  );
};

TotalPlannedStoriesInSeriesField.propTypes = {
  form: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  onSeriesIdentificationChange: PropTypes.func.isRequired,
  fieldStatus: PropTypes.shape({
    fieldInvalid: PropTypes.bool,
    fieldName: PropTypes.string
  }).isRequired,
  submitted: PropTypes.bool.isRequired
};

export default TotalPlannedStoriesInSeriesField;
