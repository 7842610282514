import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// DSContainer

function DSContainer(props) {
  return (
    <div className={classNames("dscontainer", props.variant)}>
      {props.children}
    </div>
  );
}

DSContainer.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string
};

DSContainer.defaultProps = {
  variant: ""
};

export { DSContainer };
